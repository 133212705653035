import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-8 md:gap-6" }
const _hoisted_2 = {
  key: 1,
  class: "text-racq-dark-grey text-xl-bold"
}
const _hoisted_3 = {
  key: 2,
  class: "text-racq-med-grey richtext text-block"
}
const _hoisted_4 = {
  key: 3,
  class: "text-racq-med-grey text-m text-block"
}
const _hoisted_5 = {
  key: 4,
  class: "text-racq-med-grey text-sm text-block"
}

import { computed } from 'vue';
import { Text, RichText, Field } from '@sitecore-jss/sitecore-jss-vue';
import { QuotePremiumDetails } from '@/interfaces/entities/premium-details.interface';
import { TermPaymentDetails } from '@/interfaces/entities/term-payment-details.interface';
import { formatCurrency } from '@/utils/text-transforms';
import { format as formatDate } from 'date-fns';
import { AlertTypes } from '@/enums/alert-types-enum';
import Alert from '@/components/Alert/Alert.vue';

interface ViewModel {
  mdText: Field<any>;
  smallText?: Field<any>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PremiumDetailsConfirmation',
  props: {
    fields: {},
    panelTitle: {},
    confirmationDetailsText: {},
    premiumDetails: {},
    renewalTermPaymentDetails: {},
    policyInRenewal: { type: Boolean },
    renewalTermStart: {},
    renewalTermEnd: {},
    paymentReceiptNumber: {}
  },
  setup(__props: any) {

const props = __props;

const viewModel = computed<ViewModel>(() => {
  if (props.policyInRenewal && props.renewalTermStart && props.renewalTermEnd) {
    if (props.premiumDetails.monthlyNonResponsivePremium) {
      return {
        mdText: {
          value: props.fields.inRenewalMonthlyNonResponsivePremiumChangeText.value
            .replace('{nextPayment}', formatCurrency(props.premiumDetails.monthlyNonResponsiveDeposit, 2))
            .replace('{regularMonthlyPayment}', formatCurrency(props.premiumDetails.monthlyNonResponsivePremium, 2))
            .replace(
              '{nextDeductionDate}',
              props.premiumDetails.nonResponsiveNextInvoiceDue
                ? formatDate(props.premiumDetails.nonResponsiveNextInvoiceDue, 'dd MMMM yyyy')
                : ''
            ),
        },
        smallText: {
          value: props.premiumDetails.annualNonResponsivePremium
            ? props.fields.inRenewalMonthlyNonResponsivePremiumChangeSmallText.value.replace(
                '{totalAnnualPremium}',
                formatCurrency(props.premiumDetails.annualNonResponsivePremium, 2)
              )
            : '',
        },
      };
    } else if (props.premiumDetails.annualNonResponsivePremium) {
      return {
        mdText: {
          value: props.fields.inRenewalAnnualNonResponsivePremiumChangeText.value.replace(
            '{totalDue}',
            formatCurrency(props.premiumDetails.annualNonResponsivePremium, 2)
          ),
        },
        smallText: {
          value: props.renewalTermPaymentDetails?.nextInvoiceDueDate
            ? props.fields.inRenewalAnnualNonResponsivePremiumChangeSmallText.value.replace(
                '{nextDeductionDate}',
                formatDate(props.renewalTermPaymentDetails.nextInvoiceDueDate, 'dd MMMM yyyy')
              )
            : '',
        },
      };
    } else if (props.premiumDetails.annualResponsiveAmountDue) {
      return {
        mdText: {
          value: props.fields.inRenewalResponsivePremiumChangeText.value.replace(
            '{amount}',
            formatCurrency(props.premiumDetails.annualResponsiveAmountDue, 2)
          ),
        },
        smallText: {
          value: props.fields.inRenewalResponsivePremiumChangeSmallText.value.replace(
            '{receiptNumber}',
            props.paymentReceiptNumber
          ),
        },
      };
    }
  } else {
    if (!props.premiumDetails.premiumChanged) {
      return {
        mdText: props.fields.premiumNotChangedText,
      };
    } else if (props.premiumDetails.monthlyNonResponsivePremium) {
      return {
        mdText: {
          value: props.fields.monthlyNonResponsivePremiumChangeText.value
            .replace('{nextPayment}', formatCurrency(props.premiumDetails.monthlyNonResponsiveDeposit, 2))
            .replace('{regularMonthlyPayment}', formatCurrency(props.premiumDetails.monthlyNonResponsivePremium, 2))
            .replace(
              '{nextDeductionDate}',
              props.premiumDetails.nonResponsiveNextInvoiceDue
                ? formatDate(props.premiumDetails.nonResponsiveNextInvoiceDue, 'dd MMMM yyyy')
                : ''
            ),
        },
        smallText: props.fields.monthlyNonResponsivePremiumChangeSmallText,
      };
    } else if (props.premiumDetails.annualRefund) {
      return {
        mdText: {
          value: props.fields.annualRefundText.value.replace(
            '{amount}',
            formatCurrency(props.premiumDetails.annualRefund, 2)
          ),
        },
        smallText: props.fields.annualRefundSmallText,
      };
    } else if (props.premiumDetails.annualNonResponsiveAmountDue) {
      return {
        mdText: {
          value: props.fields.annualNonResponsivePremiumChangeText.value.replace(
            '{amount}',
            formatCurrency(props.premiumDetails.annualNonResponsiveAmountDue, 2)
          ),
        },
        smallText: props.fields.annualNonResponsivePremiumChangeSmallText,
      };
    } else if (props.premiumDetails.annualResponsiveAmountDue) {
      return {
        mdText: {
          value: props.fields.annualResponsivePremiumChangeText.value.replace(
            '{amount}',
            formatCurrency(props.premiumDetails.annualResponsiveAmountDue, 2)
          ),
        },
        smallText: {
          value: props.fields.annualResponsivePremiumChangeSmallText.value.replace(
            '{receiptNumber}',
            props.paymentReceiptNumber
          ),
        },
      };
    } else if (!props.premiumDetails.monthlyNonResponsivePremium && props.premiumDetails.monthlyNonResponsiveDeposit) {
      return {
        mdText: {
          value: props.fields.monthlyNonResponsiveDepositText.value
            .replace('{nextPayment}', formatCurrency(props.premiumDetails.monthlyNonResponsiveDeposit, 2))
            .replace(
              '{nextDeductionDate}',
              props.premiumDetails.nonResponsiveNextInvoiceDue
                ? formatDate(props.premiumDetails.nonResponsiveNextInvoiceDue, 'dd MMMM yyyy')
                : ''
            ),
        },
        smallText: props.fields.monthlyNonResponsiveDepositSmallText,
      };
    }
  }
  throw new Error('State not mapped');
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.fields.detailsUpdatedText?.value)
      ? (_openBlock(), _createBlock(Alert, {
          key: 0,
          alert: {
        showIcon: true,
        alertType: _unref(AlertTypes).success,
        text: _ctx.fields.detailsUpdatedText.value,
      }
        }, null, 8, ["alert"]))
      : _createCommentVNode("", true),
    (_ctx.panelTitle?.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_unref(Text), { field: _ctx.panelTitle }, null, 8, ["field"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.confirmationDetailsText?.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_unref(RichText), { field: _ctx.confirmationDetailsText }, null, 8, ["field"])
        ]))
      : _createCommentVNode("", true),
    (viewModel.value.mdText?.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_unref(RichText), {
            field: viewModel.value.mdText
          }, null, 8, ["field"])
        ]))
      : _createCommentVNode("", true),
    (viewModel.value.smallText?.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_unref(RichText), {
            field: viewModel.value.smallText
          }, null, 8, ["field"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})