import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpandableContent',
  props: {
    toggleButtonExpandText: {},
    toggleButtonCollapseText: {}
  },
  setup(__props: any) {



const expanded = ref<boolean>(false);

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "introContent"),
    _createVNode(_component_FormKit, {
      type: "button",
      label: expanded.value ? `${_ctx.toggleButtonCollapseText}` : `${_ctx.toggleButtonExpandText}`,
      inputClass: "text-racq-navy mb-2",
      suffixIcon: expanded.value ? 'up' : 'down',
      onClick: _cache[0] || (_cache[0] = ($event: any) => (expanded.value = !expanded.value))
    }, null, 8, ["label", "suffixIcon"]),
    (expanded.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "conditionalContent")
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})