import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "toggle-container"
}
const _hoisted_2 = { class: "toggle-border" }
const _hoisted_3 = ["value", "onClick"]

import { IDropdownItem } from '@/interfaces';
import { computed, onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Toggle',
  props: {
    context: {}
  },
  setup(__props: any) {

const props = __props;

const options = computed(() => (props?.context?.options as IDropdownItem[]) ?? []);

function emitChange(value: string) {
  props.context?.node.input(value);
}

onMounted(() => {
  emitChange(props?.context?.defaultOption ?? options.value[0]?.value);
});

return (_ctx: any,_cache: any) => {
  return (options.value?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, (option, index) => {
            return (_openBlock(), _createElementBlock("button", {
              key: index,
              class: _normalizeClass(["toggle-btn min-w-24 w-full", props.context?.node?.value == option.value ? 'toggle-btn-active' : '']),
              type: "button",
              value: option.value,
              onClick: () => emitChange(option.value)
            }, _toDisplayString(option.label), 11, _hoisted_3))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})