<template>
  <div v-if="isStepperVisible" class="flex mobile-stepper items-center w-full lg:px-0">
    <span
      class="flex-initial w-fit whitespace-nowrap font-sans font-normal text-racq-navy leading-normal text-base ms-auto me-4"
    >
      {{ currentActiveStepNumber }} of {{ formStepperSteps.length }}
    </span>
    <div class="me-auto flex py-0 h-2 w-full overflow-hidden bg-white rounded">
      <div
        v-for="(step, index) in formStepperSteps"
        :key="index"
        class="h-2 flex-1"
        :class="currentActiveStepNumber >= index + 1 ? 'bg-racq-blue' : 'bg-racq-anti-flash-white'"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IFormStep } from '@/interfaces';
import { computed, onMounted } from 'vue';
import { useFormStore } from '@/store';
import { uniqBy } from 'lodash-es';

// Initialise Composables
// -------------------------- //
const formStore = useFormStore();

// Initialise Props
// -------------------------- //
const props = defineProps<{
  steps?: IFormStep[];
  isMock?: boolean;
}>();

// Lifecycle Hooks
// -------------------------- //
onMounted(() => {
  if (props.steps && props.isMock) {
    formStore.setSteps(props.steps);
  }
});

// Computed Properties
// -------------------------- //
const isStepperVisible = computed(
  () => formStore.isStepperVisible && formStepperSteps.value.length > 1 && formStore.allPrelimStepsComplete
);
const currentActiveStepNumber = computed(() => {
  const activeStep = formStore.inScopeSteps.find((step) => step.isActive);
  return (
    formStepperSteps.value.findIndex(
      (step) =>
        (activeStep?.stepGroupName && step.stepGroupName == activeStep.stepGroupName) ||
        step.stepTitle == activeStep?.stepTitle
    ) + 1
  );
});

const formStepperSteps = computed(() => {
  const visibleSteps = formStore.inScopeSteps.filter((step) => !step.hideStepper);
  return uniqBy(visibleSteps, (step) => step.stepGroupName || step.stepTitle);
});
</script>
