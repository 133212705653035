import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative w-full h-full" }

import { ref, onMounted, watch } from 'vue';
import lottie from 'lottie-web';


export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleMapWithPinStatic',
  props: {
  mapId: {
    type: String,
    required: true,
    default: 'f100d5ac9d15000f',
  },
  mapApiKey: {
    type: String,
    required: true,
    default: 'AIzaSyBFMsEMG4Qdu9fVvpVbCKI-nW5ur8IzFn4',
  },
  zoomLevel: {
    type: Number,
    required: false,
    default: 15,
  },
  showTip: {
    type: Boolean,
    required: false,
    default: true,
  },
  latitude: {
    type: Number,
    required: true,
    default: -27.586272700000002,
  },
  longitude: {
    type: Number,
    required: true,
    default: 153.1008109,
  },
},
  setup(__props) {

// Add this line to reference the Google Maps types
/// <reference types="@types/google.maps" />

const mapInstance = ref<google.maps.Map | null>(null);
const markerInstance = ref<google.maps.marker.AdvancedMarkerElement | null>(null);

let myMap: google.maps.Map;

const props = __props;

async function initMap() {
  try {
    const mapElement = document.getElementById('static-map');
    if (mapElement) {
      const latLng = new google.maps.LatLng(props.latitude, props.longitude);
      const map = new google.maps.Map(mapElement, {
        center: latLng,
        zoom: 15,
        disableDefaultUI: true,
        zoomControl: false,
        mapId: props.mapId,
        draggable: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        gestureHandling: 'none',
        clickableIcons: false,
      });

      const animationContainer = document.createElement('div');
      animationContainer.style.width = '50px';
      animationContainer.style.height = '50px';
      animationContainer.classList.add('element-with-shadow');

      lottie.loadAnimation({
        container: animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require('../../assets/animated-icons/Location_marker.json'), // if the path doesn't work in staging should move it to typescript
      });

      const racqmarker = new google.maps.marker.AdvancedMarkerElement({
        map: map,
        position: { lat: props.latitude, lng: props.longitude },
        content: animationContainer as HTMLElement,
        title: 'RACQ Marker.',
        gmpClickable: false,
      });

      markerInstance.value = racqmarker;
      mapInstance.value = map;
      myMap = map;
    } else {
      console.warn('Map element not found');
    }
  } catch (error) {
    console.warn('Error loading Google Maps API:', error);
  }
}

async function waitForGoogleMaps(): Promise<void> {
  return new Promise((resolve) => {
    const checkInterval = setInterval(() => {
      if (typeof google !== 'undefined' && google.maps) {
        clearInterval(checkInterval);
        resolve();
      }
    }, 1000); // Check every 100ms
  });
}

onMounted(async () => {
  try {
    await waitForGoogleMaps();
    await initMap();
  } catch (error) {
    console.warn('Error loading Google Maps script:', error);
  }
});

// Watch for changes in latitude and longitude
watch(
  () => [props.latitude, props.longitude],
  ([newLat, newLng]) => {
    if (mapInstance.value && markerInstance.value) {
      // Update marker position
      const newPosition = new google.maps.LatLng(newLat, newLng);
      markerInstance.value.position = newPosition;

      // Update map center
      mapInstance.value.setCenter(newPosition);
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", {
      id: "static-map",
      class: "w-full h-full border border-solid border-racq-pale-blue-border rounded"
    }, null, -1)
  ])))
}
}

})