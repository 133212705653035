import { defineStore } from 'pinia';
import { RoadsideAssistanceService as _roadsideAssistanceService } from '@/services/store/roadside-assistance.service';
import { RoadsideAssistance, TriageAnswer } from '@/interfaces/entities/roadside-assistance.interface';
import { ref } from 'vue';

export const useRoadsideAssistance = defineStore('RoadsideAssistance', () => {
  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //

  const breakdownCauses = ref<TriageAnswer[]>([]);

  async function getServiceAbility(latitude: number, longitude: number): Promise<RoadsideAssistance> {
    const response = await _roadsideAssistanceService.getServiceability(latitude, longitude);
    // read response's triagequestion filter by id TriageQuestion and set breakdownCauses
    const triageQuestion = filterQuestionsById(response.triageQuestions, 'BREAKDOWN_REASON');
    if (triageQuestion !== null) {
      breakdownCauses.value = triageQuestion.answers;
    }
    return response;
  }

  function filterQuestionsById(questions: any[], id: string) {
    return questions.find((question) => question.id === id);
  }

  return {
    getServiceAbility,
    breakdownCauses,
  };
});
