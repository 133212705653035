import * as i18n from '@formkit/i18n';
import * as icons from '@formkit/icons';
import * as themes from '@formkit/themes';

import { TailwindRACQTheme } from './tailwind.config';
import { createProPlugin, inputs } from '@formkit/pro';

import formkitSpinnerPlugin from '@/plugins/spinner/formkitSpinnerPlugin';
import formkitTooltipPlugin from '@/plugins/tooltip/formkitTooltipPlugin';
import formkitScrollToErrorsPlugin from '@/plugins/formkitScrollToErrorsPlugin';
import formkitDefaultPropsPlugin from '@/plugins/formkitDefaultPropsPlugin';
import formkitHelpPlugin from '@/plugins/formkitHelpPlugin';
import { routerLinkButton, linkButton } from '@/plugins/formkitLinkButton/formkitLinkButton';
import { createInput } from '@formkit/vue';
import NativeRadio from '@/components/NativeRadio/NativeRadio.vue';
import Toggle from '@/components/Toggle/Toggle.vue';
import RadioButtonCardGroup from '@/components/RadioButtonCardGroup/RadioButtonCardGroup.vue';
import RACQDropdown from '@/components/RACQDropdown/RACQDropdown.vue';
import { findCarInput, findCarPlugin } from '@/plugins/findCar/findCarPlugin';
import formkitPreContentPlugin from './plugins/pre-post-contents/preContentPlugin';
import RadioButtonCardList from '@/components/RadioButtonCardList/RadioButtonCardList.vue';

// Create a FormKit Pro licence key at https://pro.formkit.com/
const pro = createProPlugin('fk-847cc712eb', inputs);

const config = {
  plugins: [
    pro,
    formkitScrollToErrorsPlugin,
    formkitSpinnerPlugin,
    formkitTooltipPlugin,
    formkitHelpPlugin,
    formkitDefaultPropsPlugin,
    findCarPlugin,
    formkitPreContentPlugin,
  ],
  locales: { en: i18n.en },
  locale: 'en',
  icons: icons.genesisIcons,
  config: {
    // Generate FormKit themes and classes
    // Ref: https://formkit.com/essentials/styling#using-the-web-ui#using-generateclasses-from-formkitthemes
    classes: themes.generateClasses(TailwindRACQTheme),
  },
  messages: {
    en: {
      validation: {
        required({ name }: any) {
          return `Please enter your ${name.toLowerCase()}`;
        },
      },
    },
  },
  inputs: {
    nativeRadio: createInput(NativeRadio, {
      props: ['options'],
    }),
    toggle: createInput(Toggle, {
      props: ['defaultOption', 'options'],
    }),
    radiobuttoncardgroup: createInput(RadioButtonCardGroup, {
      props: [
        'options',
        'initialValue',
        'validationMessage',
        'radioGroupClass',
        'showValidationError',
        'bindings',
        'leftTitleClass',
        'initialOptionsCountToShow',
      ],
    }),
    findCar: findCarInput,
    routerLinkButton: routerLinkButton,
    linkButton: linkButton,
    racqDropdown: createInput(RACQDropdown, {
      props: ['options'],
    }),
    radioButtonCardList: createInput(RadioButtonCardList, {
      props: ['options'],
    }),
  },
};

export default config;
