import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "field-link" }

import { ComponentFields, ComponentRendering, Link as ScLink } from '@sitecore-jss/sitecore-jss-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LinkList',
  props: {
    fields: {},
    rendering: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields.items as any, (item) => {
      return (_openBlock(), _createElementBlock("li", {
        key: item.id,
        class: "mx-0 md:mb-0"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_unref(ScLink), {
            field: item.fields.link
          }, null, 8, ["field"])
        ])
      ]))
    }), 128))
  ]))
}
}

})