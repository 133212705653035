import {
  getAssistanceProduct,
  submitAutoGlassFormDetails,
  getSelfServiceRoadsideAssistancePageUrl,
} from '@/constants/forms-api.const';
import { RequestServices as _http } from '@/services/common/request.services';
import { AutoGlassFormRequest } from '@/interfaces/pages/auto-glass-form.interface';

export const AutoGlassFormService = {
  /**
   * Submit autoglass form
   * @param payload
   */
  async submitAutoGlassFormDetails(payload: AutoGlassFormRequest): Promise<any> {
    return await _http.HttpPost(submitAutoGlassFormDetails, payload);
  },

  /**
   * Fetch assistance product policy
   * @param policyId
   * @returns
   */
  async getAssistanceProduct(policyId: string) {
    return await _http.HttpGet(`${getAssistanceProduct.replace('{0}', policyId)}`);
  },

  /**
   * Get roadside assistance URL
   * @returns
   */
  getSelfServiceRoadsideAssistancePage(): Promise<string> {
    return _http.HttpGet(`${getSelfServiceRoadsideAssistancePageUrl}`);
  },
} as const;
