<template>
  <div v-for="(table, key) in tableData.tableSections" :key="key">
    <div class="p-3 text-base my-6 bg-racq-anti-flash-white border border-racq-blue-background">
      <RichText :field="table.heading" />
    </div>
    <div
      class="grid grid-flow-col gap-x-4"
      :style="
        getGridTemplate(
          table.tableColumn.length,
          table.tableColumn[0].rows.length,
          toBool(table.showMoreButton?.value),
          toNumber(table.initialNumberOfItemsToShow?.value)
        )
      "
    >
      <div v-for="(tableColumn, index) in table.tableColumn" :key="index" class="contents">
        <div
          v-for="(tableRow, row) in toBool(table.showMoreButton?.value)
            ? isExpanded
              ? tableColumn.rows
              : tableColumn.rows.slice(0, toNumber(table.initialNumberOfItemsToShow?.value))
            : tableColumn.rows"
          :key="row"
          class="p-4 border border-racq-blue-background"
          :class="[
            tableColumn.highlighted ? 'bg-racq-anti-flash-white' : '',
            getBorderClass(
              row,
              tableColumn,
              toBool(table.showMoreButton?.value),
              toNumber(table.initialNumberOfItemsToShow?.value)
            ),
          ]"
        >
          <div class="flex flex-col gap-1">
            <div class="text-m-bold text-racq-dark-grey">
              <RichText :field="tableRow.heading" />
            </div>
            <p class="text-m text-dark-grey-text">
              <RichText :field="tableRow.description" />
            </p>
            <PercentageBar v-if="tableRow.percentage?.value" :percentage="tableRow.percentage.value" />
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="toBool(table.showMoreButton?.value)"
      type="button"
      class="toggle-button my-6 flex items-center bg-none border-[none] text-base-bold text-racq-blue w-fit"
      @click.stop.prevent="toggleContent"
    >
      {{ isExpanded ? table.showLessButtonText?.value : table.showMoreButtonText?.value }}
      <img
        :src="chevronDown"
        alt="chevron icon"
        class="w-[12px] h-[7px] ml-2 [transition:transform_0.5s_ease]"
        :class="isExpanded ? 'transform rotate-180' : ''"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { ITableGroup, ITableGroupColumn } from '@/interfaces/entities/table-group.interface';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import PercentageBar from '@/components/PercentageBar/PercentageBar.vue';
import chevronDown from '@/assets/icons/blue-chevron-down.svg';
import { toBool, toNumber } from '@/utils/convert';

// Define props and emitters
// ------------------------------------------------------------- //
defineProps<{
  tableData: ITableGroup;
}>();

// Define Functions
// ------------------------------------------------------------- //
const getGridTemplate = (numberOfColumns: number, numberOfRow: number, showMore?: boolean, numberOfItems?: number) => {
  return {
    gridTemplateRows: `${showMore && !isExpanded.value ? `repeat(${numberOfItems}, minmax(0, auto))` : `repeat(${numberOfRow}, minmax(0, auto))`}`,
    gridTemplateColumns: `repeat(${numberOfColumns}, minmax(0, 1fr))`,
  };
};
const getBorderClass = (
  index: number,
  tableColumn: ITableGroupColumn,
  showMore?: boolean,
  initialNumberOfItemsToShow?: number
) => {
  if (showMore) {
    return !isExpanded.value && index == initialNumberOfItemsToShow! - 1
      ? ''
      : index == tableColumn.rows.length - 1
        ? ''
        : 'border-b-0 border-b-0';
  } else {
    return index == tableColumn.rows.length - 1 ? '' : 'border-b-0 border-b-0';
  }
};

const isExpanded = ref(false);
const toggleContent = () => {
  isExpanded.value = !isExpanded.value;
};
</script>
<style>
.table-item-grid {
  grid-template-rows: auto;
}
</style>
