import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "bg-racq-anti-flash-white flex flex-col grow" }
const _hoisted_2 = { class: "px-4 pt-6 pb-5 bg-white lg:hidden" }
const _hoisted_3 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(`md:hidden text-racq-navy m-0 text-xl2-bold`)
}
const _hoisted_4 = {
  id: "base-form-container",
  class: "mx-0 md:mx-auto mb-12 md:mb-14 xl:max-w-[784px] lg:max-w-[992px] md:max-w-[784px] w-full sm:mt-0 md:mt-8 lg:mt-14"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["id", "dataStepName"]
const _hoisted_8 = { class: "mx-0 md:mx-auto -mt-5 px-6 md:px-0 max-w-[784px] w-full" }

import { computed, onMounted, onUnmounted, ref, getCurrentInstance } from 'vue';
import { ComponentFields, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { FormRenderingData } from '@/interfaces/pages/sitecore-props.interface';

import FormStepper from '@/components/FormStepper/FormStepper.vue';
import FormStepperBar from '@/components/FormStepperBar/FormStepperBar.vue';
import FormStep from '@/components/FormStep/FormStep.vue';
import JeopardyV2 from '@/components/JeopardyV2/JeopardyV2.vue';
import ModalDialog from '@/components/ModalDialog/ModalDialog.vue';
import FormStepDisclaimers from '@/components/FormStepDisclaimers/FormStepDisclaimers.vue';

import { IFormStep } from '@/interfaces';
import { useJeopardyStore, useFormStore } from '@/store';

// Define props and state
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseFormContainer',
  props: {
    rendering: {},
    fields: {},
    steps: {},
    floatBoxMounted: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const bodyHeightObserver = ref<ResizeObserver>();
const floatBoxTranslate = ref<{ x: number; y: number }>({ x: 0, y: 0 });
const showTooltipModal = ref(false);
const tooltipModalTitle = ref('');
const tooltipModalContent = ref('');

// Initialise stores
// ------------------------------------------------------------- //
const jpStore = useJeopardyStore();
const formStore = useFormStore();

// Computed Properties
// ------------------------------------------------------------- //
const showJeopardy = computed(() => jpStore.showJeopardy);
const prelimSteps = computed(() => formStore.prelimSteps);
const prelimStepIndex = computed(() => formStore.currentPrelimStepIndex);
const allPrelimStepsComplete = computed(() => formStore.allPrelimStepsComplete);
const formName = computed(() => props.rendering?.fields?.data?.datasource?.name || '');
const pageTitle = computed(() => formStore.pageTitle);
const isStepperVisible = computed(() => formStore.isStepperVisible.value);
const activeStep = computed(() =>
  formStore.allPrelimStepsComplete ? formStore.currentStep : formStore.currentPrelimStep
);

const app = getCurrentInstance();
const jpFields = app?.appContext.config.globalProperties.$jss.routeData().placeholders['jss-jeopardy'][0].fields;

onMounted(() => {
  bodyHeightObserver.value = new ResizeObserver(updateFloatBox);
  bodyHeightObserver.value.observe(document.getElementById('base-form-container')!);

  // Set the default Jeopardy to display for this form
  jpStore.setDefaultJeopardyPanel(jpFields);

  formStore.setFormName(formName.value);
});

onUnmounted(() => {
  bodyHeightObserver.value?.disconnect();
});

/**
 * Calculate Float Box Transform Values
 */
const updateFloatBox = () => {
  if (formStore.currentStep?.id) {
    const timer = setInterval(() => {
      const currentStepContainer = document.querySelector(
        `#${formStore.currentStep!.id! + formStore.currentStepIndex.toString()} > div`
      );
      if (currentStepContainer) {
        if (currentStepContainer?.clientHeight) clearInterval(timer);

        floatBoxTranslate.value.x = currentStepContainer.clientWidth + 30;
        floatBoxTranslate.value.y = currentStepContainer.clientHeight + 20;
      }
    }, 100);
  }
};

/**
 * Add as global function to be able to access by tooltip plugin
 */
(window as any).showRACQTooltipModal = (title: string, content: string) => {
  showTooltipModal.value = true;
  tooltipModalTitle.value = title;
  tooltipModalContent.value = content;
};

(window as any).hideRACQTooltipModal = () => {
  showTooltipModal.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (pageTitle.value)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(pageTitle.value), 1))
          : _createCommentVNode("", true),
        (isStepperVisible.value)
          ? (_openBlock(), _createBlock(FormStepperBar, {
              key: 1,
              class: "mt-2 h-6 w-full justify-stretch items-center"
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (isStepperVisible.value && allPrelimStepsComplete.value)
          ? (_openBlock(), _createBlock(FormStepper, {
              key: 0,
              steps: _ctx.steps,
              currentStepIndex: _unref(formStore).currentStepIndex
            }, null, 8, ["steps", "currentStepIndex"]))
          : _createCommentVNode("", true),
        _createVNode(_Transition, {
          name: "component-fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (!allPrelimStepsComplete.value && !showJeopardy.value)
              ? (_openBlock(), _createElementBlock("div", { key: prelimStepIndex.value }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(prelimSteps.value, (prelimStep, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: prelimStep.id
                    }, [
                      (index === prelimStepIndex.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(FormStep, { step: prelimStep }, {
                              default: _withCtx(() => [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(prelimStep.component), { step: prelimStep }, null, 8, ["step"]))
                              ]),
                              _: 2
                            }, 1032, ["step"])
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ]))
              : (allPrelimStepsComplete.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
                      return (_openBlock(), _createElementBlock("section", {
                        id: step.id + index.toString(),
                        key: index,
                        class: _normalizeClass([
              'relative js-scrollspy-form-step xl:w-full',
              {
                '!hidden md:!flex': index != _unref(formStore).currentStepIndex && step.isVisible,
                '!hidden': !step.isVisible,
              },
              _ctx.floatBoxMounted ? 'lg:racq-step-width' : 'lg:racq-w-xl5 lg:mx-auto',
              step.isActive ? 'z-[1]' : 'z-[0]',
            ]),
                        dataStepName: step.stepGroupName || step.stepTitle
                      }, [
                        _createVNode(FormStep, {
                          step: step,
                          stepIndex: index + 1
                        }, {
                          default: _withCtx(() => [
                            (step.isVisible)
                              ? (_openBlock(), _createBlock(_resolveDynamicComponent(step.component), {
                                  key: 0,
                                  step: step
                                }, null, 8, ["step"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["step", "stepIndex"]),
                        (step.isActive)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(_ctx.floatBoxMounted ? 'w-full lg:w-64 lg:absolute lg-down:!transform-none' : 'hidden'),
                              style: _normalizeStyle({
                transform: `translate(${floatBoxTranslate.value.x}px, -${floatBoxTranslate.value.y}px)`,
              })
                            }, [
                              _renderSlot(_ctx.$slots, "floatBox")
                            ], 6))
                          : _createCommentVNode("", true)
                      ], 10, _hoisted_7))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
          ]),
          _: 3
        }),
        _renderSlot(_ctx.$slots, "bottomBox"),
        _createVNode(JeopardyV2, { fields: _unref(jpFields) }, null, 8, ["fields"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(FormStepDisclaimers, {
          fields: {
          defaultDisclaimer: activeStep.value?.stepDisclaimer,
          stepTitle: activeStep.value?.stepTitle,
        }
        }, null, 8, ["fields"])
      ])
    ]),
    _createVNode(ModalDialog, {
      modelValue: showTooltipModal.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showTooltipModal).value = $event)),
      closeOnEsc: true,
      showXCloseButton: true,
      dialogClass: "tooltip-dialog"
    }, {
      default: _withCtx(() => [
        (tooltipModalTitle.value)
          ? (_openBlock(), _createBlock(_unref(RichText), {
              key: 0,
              field: { value: tooltipModalTitle.value },
              class: "tooltip-title"
            }, null, 8, ["field"]))
          : _createCommentVNode("", true),
        _createVNode(_unref(RichText), {
          field: { value: tooltipModalContent.value },
          class: "tooltip-body richtext"
        }, null, 8, ["field"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})