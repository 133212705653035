import {
  changePaymentDetailsApplyRulesAndSave,
  changePaymentDetailsFetchPaymentDetails,
} from '@/constants/forms-api.const';
import { RequestServices as _http } from '../common/request.services';

export const ChangePaymentDetailsService = {
  /**
   * Fetch payment details
   *
   * @param quoteSessionId
   */
  fetchPaymentDetails(quoteSessionId: string) {
    return _http.HttpPost(changePaymentDetailsFetchPaymentDetails.replace('{0}', quoteSessionId));
  },

  /**
   * Save to session
   *
   * @param payload
   */
  applyRulesAndSave(payload: Record<string, any>) {
    return _http.HttpPost(changePaymentDetailsApplyRulesAndSave, payload);
  },
};
