import { ref } from 'vue';
import { defineStore } from 'pinia';
import { FeatureTogglesServices } from '@/services/store/feature-toggles.service';
import { IFeatureToggle } from '@/interfaces/entities/feature-toggle';

enum RefDataTypes {
  FEATURE_TOGGLES = 'FeatureToggles',
}

export const useFeatureTogglesStore = defineStore('FeatureToggles', () => {
  const refData = ref<Partial<Record<RefDataTypes, IFeatureToggle[]>>>({});

  async function getFeatureToggles(): Promise<IFeatureToggle[]> {
    if (refData.value?.FeatureToggles?.length) return refData.value.FeatureToggles;
    try {
      const res = await FeatureTogglesServices.getFeatureToggles();
      refData.value.FeatureToggles = res || [];
    } catch (error) {
      console.warn('Unable to fetch feature toggles: ', error);
    }
    return refData.value.FeatureToggles || [];
  }
  async function isFeatureToggleEnabled(featureToggleName: string): Promise<boolean> {
    if (!refData.value?.FeatureToggles?.length) {
      await getFeatureToggles();
    }
    const currentToggle = refData?.value?.FeatureToggles?.filter(
      (x) => x.name.toLocaleLowerCase() == featureToggleName.toLowerCase()
    );
    if (currentToggle?.length) {
      return currentToggle[0].enabled ?? false;
    }
    return false;
  }

  return {
    getFeatureToggles,
    isFeatureToggleEnabled,
  };
});
