<template>
  <FormKit
    v-slot="{ state: { valid } }"
    v-model="formData"
    type="form"
    :actions="false"
    :incompleteMessage="false"
    @submit="onSubmit"
  >
    <div class="text-paragraph-color">
      <FormStepInputs :step="step" />

      <div v-if="valid" class="inline-block input-spacing">
        <InformationBox :description="note.value" :type="InformationBoxTypes.SUCCESS" />
      </div>

      <FormStepFooter :step="step" :btnNextText="step.btnNext" :btnPrevText="step.btnPrev" />
    </div>
  </FormKit>
</template>

<script lang="ts">
export const emitSubmitAccountPreferences = 'emitSubmitAccountPreferences';
export default {};
</script>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import { useFormStore } from '@/store';
import { IFormStep, IPolicyHolderDetails, IQuote } from '@/interfaces';
import FormStepFooter from '@/components/FormStepFooter/FormStepFooter.vue';
import FormStepInputs from '@/components/FormStepInputs/FormStepInputs.vue';
import InformationBox from '@/components/InformationBox/InformationBox.vue';
import { InformationBoxTypes } from '@/interfaces/forms/information-box.interface';
import { AccountPreferences } from '@/enums/account-preferences.enum';
import { joinAddressComponentsForPolicyholder } from '@/mappers';

const emit = defineEmits([emitSubmitAccountPreferences]);

// Define props and emitters
// ------------------------------------------------------------- //
const props = defineProps<{
  step: IFormStep;
  quote: Partial<IQuote>;
}>();

const note = computed(() => {
  const raw = props.step.formInputs?.note?.label?.value ?? '';
  if (!raw) return { value: '' };

  const policyholderName = `${props.quote.primaryPolicyHolderDetails?.firstname?.value ?? ''} ${props.quote.primaryPolicyHolderDetails?.surname?.value ?? ''}`;

  // Get the name from the AccountPreferences enum
  const communicationPreference = AccountPreferences[formData.value.preference]?.toLowerCase() ?? '';

  const contactInfo =
    formData.value.preference === String(AccountPreferences.Email)
      ? (props.quote.primaryPolicyHolderDetails?.emailAddress?.value ?? '')
      : (joinAddressComponentsForPolicyholder(props.quote.primaryPolicyHolderDetails as IPolicyHolderDetails) ?? '');

  const updatedRaw = raw
    .replace('{{policyholder}}', policyholderName)
    .replace('{{communication}}', communicationPreference)
    .replace('{{contact}}', contactInfo);

  return { value: updatedRaw };
});

onMounted(() => {
  formData.value.preference = formData.value.preference = String(
    props.quote.primaryPolicyHolderDetails?.communicationPreference?.value ?? ''
  );
});

const formData = ref<Record<string, any>>({});
const formStore = useFormStore();

const onSubmit = async () => {
  try {
    emit(emitSubmitAccountPreferences, formData.value.preference);
  } catch (jpCode: any) {
    formStore.showLoadingForCurrentStep(false);
  }
};
</script>
