export function replaceBookmark(bookmark: string, content: string, replaceValue: string): string {
  const bookMarkToken = `{{${bookmark}}}`;
  return content.replaceAll(bookMarkToken, replaceValue);
}

export function replaceBookmarks<T>(object: T, replaceObject: object): T {
  let raw = JSON.stringify(object);
  for (const [key, value] of Object.entries(replaceObject)) {
    raw = replaceBookmark(key, raw, value);
  }
  return JSON.parse(raw);
}
