import FindCar from '@/components/FindCar/FindCar.vue';
import { createInput } from '@formkit/vue';

export const findCarInput = createInput(FindCar, {
  props: [
    'topMakes',
    'regoResultsStepTitle',
    'regoResultsStepContent',
    'makeAndModelResultsStepTitle',
    'makeAndModelResultsStepContent',
    'findCarBtn',
    'regoNotFoundMessage',
    'yearLabel',
    'makeLabel',
    'modelLabel',
    'seriesLabel',
    'bodyLabel',
    'colourLabel',
    'regoInputLabel',
    'showColour',
    'expandMakeAndModelLabel',
    'makeAndModelTitleMessage',
    'icon',
    'title',
    'subTitle',
    'isRoadside',
    'roadsideRegoHelpText',
    'formName',
  ],
});

export const findCarPlugin = (node: any) => {
  if (node.props.type == 'findCar') {
    node.props.help = '';
  }
};
