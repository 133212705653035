import { getFeatureToggles } from '@/constants/forms-api.const';
import { RequestServices as _http } from '../common/request.services';
import { INameValue } from '@/interfaces/entities/name-value.interface';

export const FeatureTogglesServices = {
  /**
   * Get Feature Toggles from sitecore 10
   * @returns
   */
  getFeatureToggles() {
    return _http.HttpGet(`${getFeatureToggles}`);
  },
} as const;
