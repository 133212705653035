/**
 * Custom plugin to make the help field accept HTML
 *
 */
function formkitHelpPlugin(node: any) {
  node.on('created', () => {
    const renderSchema = node?.props?.definition?.schema;

    if (!renderSchema) return;

    node.props.definition.schema = (extensions: Record<string, any>) => {
      extensions.help = {
        children: '',
        attrs: {
          innerHTML: '$help',
        },
      };

      return renderSchema(extensions);
    };
  });
}

export default formkitHelpPlugin;
