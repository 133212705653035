import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "grow shrink basis-0 text-base-bold" }

import { computed } from 'vue';
import { AlertTypes } from '@/enums/alert-types-enum';
import { IAlert } from '@/interfaces/forms/alert-interface';


export default /*@__PURE__*/_defineComponent({
  __name: 'Alert',
  props: {
    alert: {}
  },
  setup(__props: any) {

const props = __props;

const classes = computed(() => getTypeClasses());
const icon = computed(() => getIcon());

const getTypeClasses = () => {
  switch (props.alert.alertType) {
    case AlertTypes.info:
      return 'text-white bg-racq-alert-info';
    case AlertTypes.success:
      return 'text-white bg-racq-alert-success';
    case AlertTypes.warning:
      return 'text-racq-dark-grey bg-racq-alert-warning';
    case AlertTypes.danger:
      return 'text-white bg-racq-alert-danger';
  }
  return '';
};

const getIcon = () => {
  let icon = null;
  switch (props.alert.alertType) {
    case AlertTypes.info:
      icon = { fileName: require(`@/assets/icons/info-icon-white.svg`), alt: 'Information icon' };
      break;
    case AlertTypes.success:
      icon = { fileName: require(`@/assets/icons/success-icon-white.svg`), alt: 'Tick icon' };
      break;
    case AlertTypes.warning:
      icon = { fileName: require(`@/assets/icons/warning-icon-black.svg`), alt: 'Warning icon' };
      break;
    case AlertTypes.danger:
      icon = { fileName: require(`@/assets/icons/danger-icon-white.svg`), alt: 'Danger icon' };
      break;
  }

  return icon;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([classes.value, "px-5 py-4 rounded justify-start items-center gap-4 flex"])
  }, [
    (_ctx.alert.showIcon && icon.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.alert.customIcon?.fileName ? _ctx.alert.customIcon.fileName : icon.value.fileName,
          alt: icon.value?.alt,
          role: "img",
          class: "w-5 h-5 relative"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.alert.text), 1)
  ], 2))
}
}

})