import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { defineAsyncComponent, computed } from 'vue';
import type { FormProps } from '@/interfaces/forms/form-props.interface';
import { pascalCaseToKebabCase } from '@/utils/data-transforms';


export default /*@__PURE__*/_defineComponent({
  __name: 'FormBuilder',
  props: {
    componentDir: {},
    errorComponent: {},
    rendering: {},
    fields: {},
    params: {}
  },
  setup(__props: any) {

/**
 * This is the root level component, picked up by Sitecore via SSR.
 *
 * Inside the props is a field called "componentName".
 * This is the name of the form component to render.
 *
 */
const props = __props;
const componentName = computed(() => (props?.rendering?.fields as any).data?.datasource.componentName.value);
const folderPath = computed(() => (props?.rendering?.fields as any).data?.datasource.folderPath?.value ?? '');
const componentDir = pascalCaseToKebabCase(componentName.value);
const formComponent = defineAsyncComponent(
  () => import(`@/forms/${folderPath.value ? folderPath.value + '/' : ''}${componentDir}/${componentName.value}.vue`)
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(formComponent)), _mergeProps(_ctx.$props, { componentDir: _unref(componentDir) }), null, 16, ["componentDir"]))
}
}

})