import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {ref, computed } from 'vue';
import { FormKit } from '@formkit/vue';
import { CLEAR_ICON } from '@/constants/svg-images.const';


export default /*@__PURE__*/_defineComponent({
  __name: 'PhoneNumberInput',
  props: {
    bindings: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;
const phoneNumber = ref<string>('');
const showClearIcon = computed(() => props.bindings.showClearIcon as boolean)

function clearField() {
  phoneNumber.value = '';
  emit('update:modelValue', phoneNumber.value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(FormKit), _mergeProps(_ctx.bindings, {
    modelValue: phoneNumber.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((phoneNumber).value = $event)),
    oninput: "this.value=this.value.replace(/[^0-9]/g, '')",
    maxlength: "10",
    inputClass: "input-remove-box-shadow",
    suffixIcon: showClearIcon.value === true && phoneNumber.value.length > 0 ? _unref(CLEAR_ICON) : '',
    suffixIconClass: "cursor-pointer h-fit !self-auto pr-3",
    onSuffixIconClick: _cache[1] || (_cache[1] = ($event: any) => (clearField()))
  }), null, 16, ["modelValue", "suffixIcon"]))
}
}

})