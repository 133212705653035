import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-3 px-5 md:px-8 pt-6 pb-8 border-2 rounded border-solid border-racq-pale-blue-border" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-between"
}
const _hoisted_3 = { class: "text-racq-dark-grey" }

import BaseIcon from '../BaseIcon/BaseIcon.vue';
interface SectionFrameProps {
  sectionTitle?: string | undefined;
  titleClasses?: string | undefined;
  titleIconClass?: string | undefined;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SectionFrame',
  props: {
    sectionTitle: { default: undefined },
    titleClasses: { default: 'text-lg-bold' },
    titleIconClass: { default: undefined }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.sectionTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.titleIconClass)
              ? (_openBlock(), _createBlock(BaseIcon, {
                  key: 0,
                  iconClass: _ctx.titleIconClass
                }, null, 8, ["iconClass"]))
              : _createCommentVNode("", true),
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.titleClasses)
            }, _toDisplayString(_ctx.sectionTitle), 3)
          ]),
          (_ctx.$slots.buttonAction)
            ? _renderSlot(_ctx.$slots, "buttonAction", { key: 0 })
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots.sectionFrameContent)
      ? _renderSlot(_ctx.$slots, "sectionFrameContent", { key: 1 }, () => [
          _cache[0] || (_cache[0] = _createTextVNode(" [Named slot for section frame content (sectionFrameContent)] "))
        ])
      : _createCommentVNode("", true)
  ]))
}
}

})