import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "my-4" }
const _hoisted_2 = {
  key: 1,
  class: "flex gap-4 mb-3.5 p-5 bg-racq-alert-success"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex gap-4 items-center" }

import { ref } from 'vue';
import { IAction, IProposition } from '@/interfaces/decision-intelligence/decision-intelligence-response';
import { FormKit } from '@formkit/vue';
import { useDecisionIntelligenceStore } from '@/store/decision-intelligence/decision-intelligence.state';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { IDropdownItem, IQuote } from '@/interfaces';
import { Outcome } from '@/interfaces/decision-intelligence/decision-intelligence-request';
import { ICreateLeadResponseModel } from '@/interfaces/lead/lead-model';
import { mapCreateLeadRequest, mapDecisionOfferAcknowledge } from '@/mappers/decision-intelligence-mapper';
import { IPaymentReferenceDetails } from '@/interfaces/make-a-payment/make-a-payment.interface';

// Define props and emitters
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'DecisionIntelligenceLeadForm',
  props: {
    quote: {},
    paymentReferenceDetails: {},
    isEmail: { type: Boolean },
    currentAction: {},
    proposition: {},
    decisionId: {}
  },
  setup(__props: any) {

const props = __props;

// Define Component state
// ------------------------------------------------------------- //
const showSuccess = ref(false);
const showError = ref(false);
const isSubmitting = ref(false);
const DecisionIntelligenceStore = useDecisionIntelligenceStore();

const iconMap = {
  successIcon: require('@/assets/img/icons/racq-success-icon.svg'),
};

// Define Functions
// ------------------------------------------------------------- //
const getEmailOptions = (): IDropdownItem[] => {
  return DecisionIntelligenceStore.getEmailOptionsDropDown();
};
const getContactOptions = (): IDropdownItem[] => {
  return DecisionIntelligenceStore.getContactOptionsDropDown();
};
const handleSubmit = async (value: any) => {
  try {
    isSubmitting.value = true;
    const leadRequest = mapCreateLeadRequest(props.currentAction, value.option);
    const response = (await DecisionIntelligenceStore.createLead(
      props.quote
        ? (props.quote.primaryPolicyHolderDetails?.partyId.value ?? '')
        : (props.paymentReferenceDetails?.partyReferences?.d365Id ?? ''),
      leadRequest
    )) as ICreateLeadResponseModel;
    if (props.decisionId) {
      if (response?.leadId) {
        const ackRequest = mapDecisionOfferAcknowledge(
          Outcome.LeadCaptured,
          props.quote,
          props.paymentReferenceDetails,
          props.proposition,
          props.currentAction,
          response.leadId
        );
        await DecisionIntelligenceStore.acknowlegeDecisionOffer(props.decisionId, ackRequest);
      } else {
        const ackRequest = mapDecisionOfferAcknowledge(
          Outcome.LeadFailed,
          props.quote,
          props.paymentReferenceDetails,
          props.proposition,
          props.currentAction
        );
        await DecisionIntelligenceStore.acknowlegeDecisionOffer(props.decisionId, ackRequest);
      }
    }
    isSubmitting.value = false;
    showSuccess.value = true;
    showError.value = false;
  } catch (error) {
    console.error('error creating lead', error);
    showError.value = true;
    showSuccess.value = false;
    isSubmitting.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(FormKit), {
    type: "form",
    actions: false,
    onSubmit: handleSubmit
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", null, [
        _createVNode(_unref(RichText), {
          field: 
          _ctx.isEmail
            ? _unref(DecisionIntelligenceStore).contentFields?.emailInstructionText
            : _unref(DecisionIntelligenceStore).contentFields?.contactInstructionText
        
        }, null, 8, ["field"])
      ]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(FormKit), {
          class: "my-4",
          type: "dropdown",
          name: "option",
          "data-testid": "selecttime",
          innerClass: "input-md",
          label: "Time for email",
          placeholder: "Select a time",
          options: _ctx.isEmail ? getEmailOptions() : getContactOptions(),
          value: null,
          validation: "required",
          popover: ""
        }, null, 8, ["options"])
      ]),
      (showError.value)
        ? (_openBlock(), _createBlock(_unref(RichText), {
            key: 0,
            class: "text-racq-red my-2",
            field: _unref(DecisionIntelligenceStore).contentFields?.emailErrorText
          }, null, 8, ["field"]))
        : _createCommentVNode("", true),
      (showSuccess.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              src: iconMap.successIcon,
              alt: "success icon"
            }, null, 8, _hoisted_3),
            _createVNode(_unref(RichText), {
              class: "text-racq-true-white",
              field: _unref(DecisionIntelligenceStore).contentFields?.emailSuccessText
            }, null, 8, ["field"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_unref(FormKit), {
          type: "submit",
          loading: isSubmitting.value,
          "data-testid": "submit-lead",
          inputClass: "btn btn-primary"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Submit ")
          ])),
          _: 1
        }, 8, ["loading"])
      ])
    ]),
    _: 1
  }))
}
}

})