<template>
  <ModalDialog v-model="modalVisible" :showXCloseButton="false" :closeOnEsc="false">
    <!-- Completion Step -->
    <div v-if="isCompletionStepVisible">
      <div class="completion-message flex flex-col justify-start items-center gap-2.5">
        <Image :media="completionStepIcon" />
        <RichText :field="completionStepText" class="text-lg-bold racq-dark-grey my-2.5 mx-0" />
      </div>
    </div>
    <!-- Show Progress Steps -->
    <div v-else-if="showProgressBar" class="step flex flex-col gap-6">
      <div class="self-stretch flex-col justify-start items-center flex">
        <div
          v-if="currentStep?.image?.value?.src"
          class="w-16 h-16 bg-racq-anti-flash-white rounded-[100px] flex-col justify-center items-center flex"
        >
          <Image :media="currentStep.image" class="max-w-8" />
        </div>
        <div v-if="currentStep?.headerText?.value" class="self-stretch pt-3 justify-center items-center inline-flex">
          <RichText :field="currentStep.headerText" class="text-center text-racq-dark-grey text-lg-bold leading-7" />
        </div>
      </div>
      <!-- Progress Bar -->
      <div
        v-if="targetedProgress"
        class="progress-bar h-2 flex self-start rounded-lg bg-racq-pale-blue mb-1 w-full relative overflow-hidden"
      >
        <div class="progress h-full rounded-lg bg-racq-blue w-0" :style="{ width: animatedProgress + '%' }"></div>
      </div>
      <div class="self-stretch flex flex-col justify-start items-center gap-7">
        <slot name="progressBarSlot">
          <!-- Information Box -->
          <InformationBox
            v-show="showInfoBox"
            :type="currentStep?.type?.value ?? InformationBoxTypes.INFO"
            :description="currentStep?.description?.value"
          />
        </slot>
        <RichText
          v-if="currentStep?.additionalText?.value"
          :field="currentStep.additionalText"
          class="self-stretch text-center text-racq-med-grey text-sm leading-tight"
        />
      </div>
    </div>
  </ModalDialog>
</template>

<script setup lang="ts">
import { ref, computed, defineExpose, watch } from 'vue';
import ModalDialog from '@/components/ModalDialog/ModalDialog.vue';
import InformationBox from '@/components/InformationBox/InformationBox.vue';
import { IProgressTrackerStep, IProgressTracker } from '@/interfaces/entities/progress-tracker.interface';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-vue';
import { InformationBoxTypes } from '@/interfaces/forms/information-box.interface';

const props = defineProps<IProgressTracker>();

const currentIndex = ref(0);
const lastSuccessfulStepIndex = ref(0);
const progress = ref(0); // Actual progress value (increments step-by-step)
const targetedProgress = ref(0);
const animatedProgress = ref(0); // Smoothly animate the progress bar
const showInfoBox = ref(false);
const isCompletionStepVisible = ref(false);
const showProgressBar = ref(true);
const currentStep = computed(() => props?.fields[currentIndex.value] as IProgressTrackerStep);
const modalVisible = ref(false);
const animationId = ref<number>();

// watch 'progress' and smoothly animate the progress bar based on step delay
watch(targetedProgress, (newProgress) => {
  const startTime = performance.now();
  const startProgress = animatedProgress.value;

  const animate = () => {
    const elapsed = performance.now() - startTime;
    const delta = Math.min(elapsed / currentStep.value?.stepDelay.value, 1); //Progress within duration

    // cancel animation if step finished early
    if (animatedProgress.value == newProgress && animationId.value) {
      return cancelAnimationFrame(animationId.value);
    }

    animatedProgress.value = startProgress + delta * (newProgress - startProgress);

    if (delta < 1) {
      requestAnimationFrame(animate);
    }
  };

  // Prevent animation if progress hasn't changed
  if (newProgress !== animatedProgress.value) {
    animationId.value = requestAnimationFrame(animate);
  }
});

const reset = () => {
  currentIndex.value = 0;
  lastSuccessfulStepIndex.value = 0;
  progress.value = 0;
  animatedProgress.value = 0;
  targetedProgress.value = 0;
  showInfoBox.value = false;
  isCompletionStepVisible.value = false;
  showProgressBar.value = true;
};

const getDefinedStepsProgress = (): number => {
  if (props?.fields) {
    let progressTotal = props.fields.reduce(function (sum, step) {
      return sum + (step?.progressPercentage?.value ?? 0);
    }, 0);

    return progressTotal;
  }

  return 0;
};

const getProgressLength = (stepIndex: number): number => {
  const stepCount = props?.fields?.length ?? 0; // Get total number of steps

  if (stepCount > 0 && stepIndex < stepCount) {
    const totalDefinedStepsProgress = getDefinedStepsProgress(); // Get the total of the progressPercentage for all the steps that have it
    if (totalDefinedStepsProgress > 100) {
      console.error('Total progress percentage cannot exceed 100'); // The overall total can't be more than 100. If so equally distribute the progress percentages across all the steps
      return 100 / stepCount; //Equal progress for each step
    }

    const stepProgress = props.fields[stepIndex]?.progressPercentage?.value; // If a step progress percentage is defined, just return it
    if (stepProgress && stepProgress >= 0) {
      return stepProgress;
    }

    const countOfStepsWithProgressPercentage = props.fields.filter(
      (step) => (step?.progressPercentage?.value ?? 0) > 0
    ).length; // If a step progress is not defined, calculate the percentage based on the values for the other steps
    return (100 - totalDefinedStepsProgress) / (stepCount - countOfStepsWithProgressPercentage);
  }

  return 0;
};

const complete = async () => {
  showProgressBar.value = false;
  isCompletionStepVisible.value = true;

  if (props.showCompletionStep.value) {
    await new Promise((resolve) => setTimeout(resolve, props.completionStepDelay.value));
  }

  modalVisible.value = false;
};

const start = () => {
  startAnimation();
  modalVisible.value = true;
};

const startAnimation = () => {
  animatedProgress.value = progress.value;
  const progressIncrement = getProgressLength(currentIndex.value);
  targetedProgress.value = progress.value + progressIncrement;
};

const waitForAnimation = (): Promise<void> => {
  return new Promise((resolve) => {
    let progressAttempt = 0;
    const timer = setInterval(() => {
      if (targetedProgress.value === animatedProgress.value) {
        clearInterval(timer);
        resolve();
      }
      progressAttempt++;
      // If it hasn't entered the previous if condition within the span of 100 attempts, clear the interval
      if (progressAttempt === 100) {
        clearInterval(timer);
        resolve();
      }
    }, 100);
  });
};

const hide = () => {
  modalVisible.value = false;
};

const next = () => {
  progress.value = targetedProgress.value; // Update actual progress
  animatedProgress.value = progress.value;
  currentIndex.value++;
  lastSuccessfulStepIndex.value = currentIndex.value;

  if (currentIndex.value === props?.fields?.length) {
    complete();
  } else {
    startAnimation();
  }
};

const resetLastStepProgress = () => {
  const progressIncrement = getProgressLength(lastSuccessfulStepIndex.value);
  targetedProgress.value -= progressIncrement;
  animatedProgress.value -= progressIncrement;
  currentIndex.value = lastSuccessfulStepIndex.value;
};

// Expose the function to the parent
defineExpose({
  start,
  hide,
  next,
  complete,
  reset,
  waitForAnimation,
  resetLastStepProgress,
  showInfoBox,
});
</script>
<!-- external styles defined under progress-tracker.css -->
