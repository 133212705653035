<template>
  <div v-if="addressLookupOptionsStore">
    <RichText v-if="bindings.preContent" :class="bindings.preContentClass" :field="{ value: bindings.preContent }" />

    <div class="flex flex-col gap-8 md:gap-6">
      <FormKit
        v-if="!addressLookupOptionsStore.isOverseasSelection"
        ref="addressPicker"
        v-bind="bindings"
        v-model="displayAddressText"
        :options="lookup"
        type="autocomplete"
        autocomplete="off"
        :data-testid="`${bindings.name}_auto_lookup`"
        :name="bindings.name + '_auto'"
        :innerClass="innerClass || 'input-xl'"
        outerClass=""
        :labelClass="labelClass"
        listboxButtonClass="$reset hidden"
        @input="handleSelection"
      />

      <RACQFormKit
        v-if="addressLookupOptionsStore?.config?.showPostalSameAsInsuredAddressSelection"
        v-model="addressLookupOptionsStore.postalSameAsInsuredAddressSelection"
        :name="`${bindings.name}_sameasinsured`"
        :data-testid="`${bindings.name}_sameasinsured`"
        :bindings="
          {
            label: postalSameAsInsuredAddressLabel,
            name: `${bindings.name}__sameasinsured`,
            type: 'checkbox',
            placeholder: '',
            classesOptions: '',
          } as VueFormInputProps
        "
        @change="postalSameAsInsuredAddressChanged"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { AddressLookupService } from '@/services/store';
import type { IDropdownItem, IExperianSuggestion, IExperianResults, IExperianFormatResult } from '@/interfaces';
import { hideAllPoppersAndPaddings } from '@/plugins/tooltip/renderTooltip';
import RACQFormKit from '../RACQFormKit/RACQFormKit.vue';
import { VueFormInputProps } from '@/composables/useFormInputData';
import { addressLookupEmits, emitAddressSelected, useFormStore, useMultiInstanceAddressOptionsStore } from '@/store';
import { isPostalAddress } from '@/utils/regex-helpers';
import { RACQAddressResult } from '@/interfaces';

const emit = defineEmits(addressLookupEmits);

const props = defineProps<{
  bindings: VueFormInputProps;
  innerClass?: string;
  labelClass?: string;
  defaultIsOverseasOption?: boolean;
  showManualEntries?: boolean;
  streetNoRequired: string;
  streetRequired: string;
  suburbRequired: string;
  stateRequired: string;
  postcodeRequired: string;
  countryRequired: string;
  checkAddressRequired: string;
  selectSuburbRequired: string;
  postcodeInvalid: string;
  invalidInsuranceRiskSuburbMessage: string;
  postalSameAsInsuredAddressLabel?: string;
  instanceIdentifier: string;
}>();

const addressPicker = ref(null);
const displayAddressText = ref<string>();

const formStore = useFormStore();
const multiInstanceAddressStore = useMultiInstanceAddressOptionsStore();
const addressLookupOptionsStore = multiInstanceAddressStore.getInstance(props.instanceIdentifier);

watch(
  () => addressLookupOptionsStore?.isOverseasSelection,
  (newValue) => {
    if (!newValue) {
      addressLookupOptionsStore.isManualEntries = false;
    }
  }
);

// To watch changes from other components / steps
watch(
  () => addressLookupOptionsStore?.autoAddressComponents,
  (newValue) => {
    const newAddress = addressLookupOptionsStore.buildAddressLine(newValue);

    // Do nothing if the component is in manual mode, keep the display text as No - I will enter manually
    if (newAddress != displayAddressText.value && !addressLookupOptionsStore.isManualEntries) {
      displayAddressText.value = newAddress;
    }
  }
);

function postalSameAsInsuredAddressChanged(event: any) {
  event.preventDefault();

  // Do nothing if the component is in manual mode, keep the display text as No - I will enter manually
  if (addressLookupOptionsStore.isManualEntries) return;

  const useAddress = addressLookupOptionsStore.onSameAsPostalAddressChanged();

  //If the postal address has previously been saved as same as insured address and the same as insured address checkbox is unticked
  //we don't want the same address showing so we clear it.
  if (
    !addressLookupOptionsStore.postalSameAsInsuredAddressSelection &&
    addressLookupOptionsStore.checkPostalIsSameAsInsured(addressLookupOptionsStore.postalAddress as RACQAddressResult)
  ) {
    emit(emitAddressSelected, {});
    displayAddressText.value = '';
  } else {
    displayAddressText.value = addressLookupOptionsStore.buildAddressLine(useAddress!);
    emit(emitAddressSelected, useAddress);
  }
}

async function lookup({ search }: { search: string }): Promise<IDropdownItem[]> {
  try {
    if (!search || search.length < 3) return [];

    const data: IExperianResults = await AddressLookupService.getAddressLookup(search);

    if (addressLookupOptionsStore?.config?.allowPhysicalAddressesOnly) {
      data.results = data.results.filter((result) => {
        return !isPostalAddress(result.suggestion);
      });
    }

    data.results.push({
      matched: [],
      suggestion: props.bindings.postContent,
      format: '',
      triggerManualEntry: true,
    });

    return (
      data.results?.map((item: IExperianSuggestion) => ({
        label: item.suggestion,
        value: item,
      })) || []
    );
  } catch (error) {
    console.error('Unable to lookup: ', error);
    return [];
  }
}

async function checkAddressInsuranceRiskSuburb() {
  formStore.showLoadingForCurrentStep();

  const results = await addressLookupOptionsStore.checkAddressInsuranceRiskSuburb(false);

  if (addressLookupOptionsStore?.config?.allowValidInsuranceRiskSuburbsOnly) {
    if (addressPicker.value) {
      (addressPicker.value as any).node.setErrors(results?.match ? '' : props.invalidInsuranceRiskSuburbMessage);
    }
  }

  formStore.showLoadingForCurrentStep(false);
}

async function handleSelection(address: IExperianSuggestion) {
  if (typeof address == 'string') {
    return;
  }

  if (!address) {
    // hide manual entry fields if user cleared address lookup field
    addressLookupOptionsStore.resetManualAddress();
    return;
  } else if (address.triggerManualEntry && !addressLookupOptionsStore.isManualEntries) {
    addressLookupOptionsStore.onEnableManualEntrySelected();

    setTimeout(() => {
      hideAllPoppersAndPaddings();
    }, 1000);
    return;
  }

  // Format Address
  if (address.format) {
    const data: IExperianFormatResult = await AddressLookupService.getAddressLookupFormat(address.format.split('?')[1]);

    addressLookupOptionsStore.autoAddressComponents = {
      postalDeliveryType: data.components?.find((comp) => comp.deliveryService1)?.deliveryService1 || '',
      addressLine: data.address
        .filter((comp) => comp.addressLine1 || comp.addressLine2 || comp.addressLine3)
        .map((item) => Object.values(item).toString())
        .join(' '),
      unitNumber: data.components?.find((comp) => comp.subBuilding1)?.subBuilding1 || '',
      buildingName: data.components?.find((comp) => comp.building1)?.building1 || '',
      streetNumber: data.components?.find((comp) => comp.streetNumber1)?.streetNumber1 || '',
      street: data.components?.find((comp) => comp.street1)?.street1 || '',
      suburb: data.components?.find((comp) => comp.locality1)?.locality1 || '',
      postcode: data.components?.find((comp) => comp.postalCode1)?.postalCode1 || '',
      state: data.address?.find((comp) => comp.province)?.province || '',
      country: data.components?.find((comp) => comp.country1)?.country1 || '',
      countryISO: data.components?.find((comp) => comp.countryISO1)?.countryISO1 || '',
      dpid: data.components?.find((comp) => comp.deliveryPointId1)?.deliveryPointId1 || '',
    };
    if (addressLookupOptionsStore?.config?.allowValidInsuranceRiskSuburbsOnly) {
      await checkAddressInsuranceRiskSuburb();
    }

    //Clear street before emitting the address.
    if (
      addressLookupOptionsStore.config?.allowPhysicalAddressesOnly &&
      isPostalAddress(addressLookupOptionsStore.autoAddressComponents.street ?? '')
    ) {
      addressLookupOptionsStore.autoAddressComponents.street = '';
    }

    addressLookupOptionsStore.onAutoAddressSelected(addressLookupOptionsStore.autoAddressComponents);

    emit(emitAddressSelected, addressLookupOptionsStore.autoAddressComponents);
  }
}
</script>
