import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col space-y-3 md:space-y-4"
}
const _hoisted_2 = {
  key: 1,
  class: "tags pl-0 flex flex-row list-none flex-wrap"
}

import Tag from '@/components/Tag/Tag.vue';
import { TagOrientation, TagTypes } from '@/enums/tag-enums';


export default /*@__PURE__*/_defineComponent({
  __name: 'TagList',
  props: {
    tags: {},
    tagType: {},
    tagOrientation: {},
    innerClass: {}
  },
  setup(__props: any) {

const props = __props;

const tagOrientation = props.tagOrientation ?? TagOrientation.horizontal;

return (_ctx: any,_cache: any) => {
  return (_unref(tagOrientation) == _unref(TagOrientation).vertical)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tagText, index) => {
          return (_openBlock(), _createBlock(Tag, {
            key: index,
            tagType: _unref(TagTypes).discount,
            tagText: tagText,
            outerClass: _ctx.innerClass
          }, null, 8, ["tagType", "tagText", "outerClass"]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tagText, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: "mr-2"
          }, [
            _createVNode(Tag, {
              tagType: _ctx.tagType,
              tagText: tagText
            }, null, 8, ["tagType", "tagText"])
          ]))
        }), 128))
      ]))
}
}

})