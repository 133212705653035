//API Suffix required for running jss locally
export const ApiPrefix =
  process.env.NODE_ENV === 'production' ? '/formsapi/v1' : 'https://racqformscm.localhost/formsapi/v1';

export const FormUrlBase =
  process.env.NODE_ENV === 'production'
    ? 'https://#{racq.website.cd.form.hostname}/insurance/quote'
    : '/insurance/quote';
//export const ApiSuffix = "?sc_apikey=" + config.sitecoreApiKey;

//login url's
export const authenticationUrl = `${ApiPrefix}/login/authenticate`;
export const getLoginUrl = `${ApiPrefix}/login/GetLoginUrl?currentUrl={0}`;
export const isAuthenticatedUrl = `${ApiPrefix}/login/isauthenticated`;
export const logoutUrl = `${ApiPrefix}/login/logout`;

// api's for assistance product
export const getAssistanceProduct = `${ApiPrefix}/assistance/getassistanceproduct?policyId={0}`;
export const setAssistanceAutoRenewFlag = `${ApiPrefix}/assistance/setautoRenewflag?policyId={0}`;
export const isEligibilityForCancellation = `${ApiPrefix}/assistance/iseligibilityforcancellation?policyId={0}`;

// api's for insurance products
export const getInsuranceProduct = `${ApiPrefix}/insurance/getinsuranceproduct?policyNumber={0}`;
export const isInsuranceEligibleForCancellation = `${ApiPrefix}/insurance/IsEligibleForCancellation?policyNumber={0}`;
export const isCancellationDateEligible = `${ApiPrefix}/insurance/iscancellationdateeligible`;
export const cancelInsurance = `${ApiPrefix}/insurance/cancelinsurance`;

// api's for roadside assistance products
export const cancelRoadsideAssistanceJob = `${ApiPrefix}/roadsideassistance/CancelJob`;
export const getServiceabilityRoadsideAssistanceJob = `${ApiPrefix}/roadsideassistance/GetServiceAbility?latitude={0}&longitude={1}`;
export const matchPartyRoadsideAssistanceJob = `${ApiPrefix}/roadsideassistance/MatchParty`;
export const postCreateJobJoinOnRoad = `${ApiPrefix}/roadsideassistance/CreateJobJoinOnRoad`;

// api's for insurance quote session
export const getQuoteSessionId = `${ApiPrefix}/quotesessionv2/getquotesession?policyNumber={0}&quoteSessionType={1}`;
export const getQuoteSessionIdByCoverage = `${ApiPrefix}/quotesessionv2/getquotesessionbycoverage?policyNumber={0}&quoteSessionType={1}&coverageId={2}`;
export const bindQuoteSessionUrl = `${ApiPrefix}/quotesessionv2/BindQuoteSession?quoteSessionId={0}`;
export const quoteSessionProcessPaymentUrl = `${ApiPrefix}/quotesessionv2/ProcessPayment?policyNumber={0}&quoteSessionId={1}`;
export const withdrawQuoteSessionUrl = `${ApiPrefix}/quotesessionv2/WithdrawQuoteSession?quoteSessionId={0}`;

// api's for profile data
export const getIdentityDetails = `${ApiPrefix}/identity/getidentitydetails`;
export const checkUpdatePostalAddressRequired = `${ApiPrefix}/identity/CheckUpdatePostalAddressRequired?partyId={0}&dpid={1}`;
export const getQueryIdentity = `${ApiPrefix}/identity/getqueryidentity`;
export const getLoyaltyType = `${ApiPrefix}/identity/GetLoyaltyType`;
export const getResidentialAddress = `${ApiPrefix}/identity/GetResidentialAddress`;

// api's for reference data
export const getNumOfStoreys = `${ApiPrefix}/refdata/getnumofstoreys?buildingType={0}`;
export const getLowestOccupiedFloor = `${ApiPrefix}/refdata/getlowestoccupiedfloor?buildingType={0}`;
export const getPrimaryConstructionMaterials = `${ApiPrefix}/refdata/getprimaryconstructionmaterials?quoteType={0}`;
export const getRoofTypeMaterials = `${ApiPrefix}/refdata/getrooftypematerials?quoteType={0}`;
export const getBuildingTypes = `${ApiPrefix}/refdata/getbuildingtypes`;
export const getPersonTitles = `${ApiPrefix}/refdata/getPersonTitles`;
export const getGenders = `${ApiPrefix}/refdata/getGenders`;
export const getPetTypes = `${ApiPrefix}/refdata/getPetTypes?quoteType={0}`;
export const getPetBreeds = `${ApiPrefix}/refdata/getPetBreeds?quoteType={0}&petType={1}`;
export const fetchHomeClaimTypes = `${ApiPrefix}/refdata/getHomeClaimTypes?quoteType={0}`;
export const fetchHomeLossTypes = `${ApiPrefix}/refdata/getHomeLossTypes?quoteType={0}`;
export const fetchHomeLossValues = `${ApiPrefix}/refdata/getHomeLossValues?quoteType={0}`;
export const getHomeAdvancedCover = `${ApiPrefix}/refdata/getHomeAdvancedOptions?quoteType={0}&occupancyType={1}`;
export const getHomeOccupancyTypes = `${ApiPrefix}/refdata/getHomeOccupancyTypes`;
export const getHomeFloorOccupancy = `${ApiPrefix}/refdata/getHomeFloorOccupancy?buildingType={0}`;
export const getHomeGroupCoverPriceBands = `${ApiPrefix}/refdata/getGroupCoverPriceBands?quoteType={0}`;
export const getPortableItemCategories = `${ApiPrefix}/refdata/getPortableItemCategories?quoteType={0}`;
export const getSmallBusinessInsuranceLimitValue = `${ApiPrefix}/refdata/getSmallBusinessInsuranceLimitValue?quoteType={0}`;
export const getEathquakeExcesses = `${ApiPrefix}/refdata/getEathquakeExcesses?quoteType={0}`;
export const getPetExcesses = `${ApiPrefix}/refdata/getPetExcesses?quoteType={0}`;
export const getPortableExcesses = `${ApiPrefix}/refdata/getPortableExcesses?quoteType={0}`;
export const getPortableMobileExcesses = `${ApiPrefix}/refdata/getPortableMobileExcesses?quoteType={0}`;
export const getMotorExcessesUrl = `${ApiPrefix}/refdata/GetExcess?quoteType={0}&effectiveDate={1}`;
export const getDriverLicenseIssueTypes = `${ApiPrefix}/refdata/getDriverLicenseIssueTypes`;
export const getAtFaultClaimValues = `${ApiPrefix}/refdata/GetAtFaultClaimValues`;
export const getAtFaultClaimTypes = `${ApiPrefix}/refdata/GetAtFaultClaimTypes`;
export const getFrameMaterials = `${ApiPrefix}/refdata/getFrameMaterials?quoteType={0}`;
export const getFloorMaterials = `${ApiPrefix}/refdata/getFloorMaterials?quoteType={0}`;
export const getMonitoredAlarmTypes = `${ApiPrefix}/refdata/getMonitoredAlarmTypes?quoteType={0}`;
export const getHomeAndContentProducts = `${ApiPrefix}/refdata/GetHomeAndContentProducts?occupancyType={0}&buildingType={1}`;
export const getHomeAndContentBuildingPropertyFeatures = `${ApiPrefix}/refdata/getBuildingPropertyFeatures`;
export const getInsuranceProducts = `${ApiPrefix}/refdata/getInsuranceProducts`;
export const getMembershipLevels = `${ApiPrefix}/refdata/getMembershipLevels?quoteType={0}`;
export const getQuotingFeesPayByMonth = `${ApiPrefix}/refdata/getQuotingFeesByMonth?effectiveDate={0}`;
export const getParkingLocationsUrl = `${ApiPrefix}/refdata/getparkinglocations`;
export const getVehicleBusinessUseTypesListUrl = `${ApiPrefix}/refdata/getVehicleBusinessUseTypes`;
export const getFinanceTypesUrl = `${ApiPrefix}/refdata/getfinancetypes`;
export const getDriverLicenseYearsHeldRangesUrl = `${ApiPrefix}/refdata/GetDriverLicenseYearsHeldRanges`;

//common services
export const getFinancialInstitutionFromBsb = `${ApiPrefix}/payment/getfinancialinstitutionfrombsb?bsb={0}`;
export const getPaymentConfiguration = `${ApiPrefix}/paymentgateway/getpaymentconfiguration`;

// api's for renewal offer data
export const getRenewalPolicyDetails = `${ApiPrefix}/insurance/getRenewalPolicyDetails?policyId={0}`;
export const setRenewalOfferPreference = `${ApiPrefix}/insurance/setRenewalOffer`;
export const sendRenewalReceiptEmail = `${ApiPrefix}/insurance/sendRenewalReceiptEmail`;
export const reinstatePolicy = `${ApiPrefix}/insurance/reinstatePolicy?policyNumber={0}`;

// api's for add to cover form
export const addToCoverGetQuoteSessionId = `${ApiPrefix}/addtocover/GetQuoteSessionId?policyNumber={0}`;
export const addToCoverUpdateCoveragePricing = `${ApiPrefix}/addtocover/UpdateCoveragePricing?quoteSessionId={0}`;
export const addToCoverGetPolicyRenewal = `${ApiPrefix}/addtocover/GetPolicyRenewal?policyNumber={0}`;
export const addToCoverSubmitChanges = `${ApiPrefix}/addtocover/SubmitChanges`;

// api's for update cyclone form
export const updateFloodCycloneDetails = `${ApiPrefix}/homeinsurance/updatefloodcyclonedetails?quoteSessionId={0}`;

// callback form api
export const submitCallBackDetails = `${ApiPrefix}/callback/submitcallbackform?policyid={0}`;

// autoglass form api
export const submitAutoGlassFormDetails = `${ApiPrefix}/autoglass/submitautoglassform`;

export const isProduction = process.env.NODE_ENV === 'production';
export const localURL = 'http://localhost:3001';

export const getLiveChatSessionData = `${ApiPrefix}/livechatapi/getsessiondata`;

export const getSelfServiceAccountOverviewUrl = `${ApiPrefix}/configuration/getselfserviceaccountoveriewpage`;
export const getSelfServiceChangeVehicleUrl = `${ApiPrefix}/configuration/getselfservicechangevehiclepage?isinsuranceproduct={0}`;
export const getSelfServiceMakePaymentUrl = `${ApiPrefix}/configuration/getselfservicemakepaymentpage`;
export const getSelfServiceRoadsideAssistancePageUrl = `${ApiPrefix}/configuration/getselfserviceroadsideassistancepage`;

export const getFeatureToggles = `${ApiPrefix}/commonconfiguration/getFeatureToggles`;
export const getIQConfigSettings = `${ApiPrefix}/insurancequoteconfig/getIQConfigSettings`;
export const getCommonConfigSettings = `${ApiPrefix}/configuration/getcommonconfigurationsettings`;

// api's for vehicle lookup
export const getVehicleByRego = `${ApiPrefix}/vehicle/getvehiclebyrego`;
export const getVehiclesByMake = `${ApiPrefix}/vehicle/getmakes`;
export const getVehicleModels = `${ApiPrefix}/vehicle/getmodels`;
export const getVehicleVariants = `${ApiPrefix}/vehicle/getvariants`;
export const getVehicleBodies = `${ApiPrefix}/vehicle/getbodies`;
export const getVehicleDetails = `${ApiPrefix}/vehicle/getvehicledetails`;
export const getVehiclesByNVIC = `${ApiPrefix}/vehicle/getvehiclebynvic`;
export const getVehicleYears = `${ApiPrefix}/vehicle/getvehicleyears`;
export const getCombinedAccessoriesList = `${ApiPrefix}/refdata/getcombinedaccessorieslist`;
export const getVehicleEligibility = `${ApiPrefix}/vehicle/getvehicleeligibility`;

// api for address lookup
export const getAddressLookupSuburbs = `${ApiPrefix}/refdata/getinsurancerisksuburbs?query={0}`;
export const getAddressLookupAutocomplete = `${ApiPrefix}/addresslookup/autocomplete?query={0}`;
export const getCountryLookupAutocomplete = `${ApiPrefix}/addresslookup/country?query={0}&allCountries={1}`;
export const getAddressLookupFormat = `${ApiPrefix}/addresslookup/format`;
export const getIsCycloneCovered = `${ApiPrefix}/addresslookup/getIsCycloneCovered`;
export const formatRiskAddressUrl = `${ApiPrefix}/addresslookup/format-risk-address`;

// api for get insurance quote
export const getInsuranceQuoteById = `${ApiPrefix}/Quote/GetQuote?quoteId={0}`;
export const postInsuranceQuoteCreateQuote = `${ApiPrefix}/Quote/CreateQuote`;
export const postInsuranceQuoteApplyRules = `${ApiPrefix}/Quote/ApplyRules?quoteId={0}`;
export const postInsuranceQuoteUpdateQuote = `${ApiPrefix}/Quote/UpdateQuote?quoteId={0}`;
export const postInsuranceQuoteCalculatePrice = `${ApiPrefix}/Quote/CalculatePremium?quoteId={0}`;
export const postInsuranceSubmitQuote = `${ApiPrefix}/Quote/SubmitQuote?quoteId={0}`;

// api for lifestyle form
export const getJoinLifestyleFormInitialContent = `${ApiPrefix}/Lifestyle/GetJoinLifestyleFormInitialContent`;
export const checkLifestyleIsEligible = `${ApiPrefix}/Lifestyle/IsEligible`;
export const postLifestyleSubmitPurchase = `${ApiPrefix}/Lifestyle/SubmitPurchase`;

// api for change payment details form
export const changePaymentDetailsFetchPaymentDetails = `${ApiPrefix}/ChangePaymentDetails/FetchPaymentDetails?quoteSessionId={0}`;
export const changePaymentDetailsApplyRulesAndSave = `${ApiPrefix}/ChangePaymentDetails/ApplyRulesAndSave`;

// api for change vehicle location form
export const changeVehicleLocationGetPolicy = `${ApiPrefix}/ChangeVehicleLocation/GetPolicy?quoteSessionId={0}`;
export const changeVehicleLocationApplyBusinessRules = `${ApiPrefix}/ChangeVehicleLocation/ApplyBusinessRules`;

// api for decision intelligence
export const postDecisionIntelligenceCreateOffer = `${ApiPrefix}/DecisionIntelligence/CreateOffer`;
export const getDecisionIntelligenceOffer = `${ApiPrefix}/DecisionIntelligence/GetDecisionOffer?decisionOfferId={0}`;
export const acknowlegeDecisionOffer = `${ApiPrefix}/DecisionIntelligence/AcknowledgeOffer?decisionId={0}`;
export const acknowlegeRenewalDecisionOffer = `${ApiPrefix}/DecisionIntelligence/acknowledgerenewaloffer`;

export const createLead = `${ApiPrefix}/DecisionIntelligence/CreateLead?d365Id={0}`;
export const createRsaPrepayOffer = `${ApiPrefix}/DecisionIntelligence/createroadsideprepayrenewaloffer`;
export const getRsaPrepayOffer = `${ApiPrefix}/DecisionIntelligence/getrsaprepayoffer?decisionId={0}`;
export const acknowledgePrepayOffer = `${ApiPrefix}/DecisionIntelligence/acknowledgersaprepayoffer?prn={0}&apttusQuoteId={1}&apttusId={2}`;

//api's for google maps
export const getJORGoogleMapsApiKey = `${ApiPrefix}/JORGoogleMaps/getApiKey`;
export const getJORGoogleMapsMapId = `${ApiPrefix}/JORGoogleMaps/getMapId`;
export const getJORGoogleMapsDefaultZoomLevel = `${ApiPrefix}/JORGoogleMaps/getDefaultZoomLevel`;
export const getJORGoogleMapsTipText = `${ApiPrefix}/JORGoogleMaps/getTipText`;

//api for join on road (JOR) form
export const getJORProducts = `${ApiPrefix}/joinonroad/getproducts`;
export const postJORCreateParty = `${ApiPrefix}/joinonroad/CreateParty`;
export const postJORCreateQuote = `${ApiPrefix}/joinonroad/CreateQuote`;
export const postJORFinaliseQuote = `${ApiPrefix}/joinonroad/FinaliseQuote?apttusQuoteId={0}&apttusCartId={1}`;
export const postJorSubmitPurchase = `${ApiPrefix}/joinonroad/submitpurchase`;

// api for payment reference number
export const getPrnDetails = `${ApiPrefix}/payment/getprndetails?prn={0}`;
export const processPrnPayment = `${ApiPrefix}/payment/processpayment`;
export const sendReceiptEmail = `${ApiPrefix}/payment/sendreceiptemail`;
export const applyDiscountToQuote = `${ApiPrefix}/payment/applydiscounttoquote`;
export const addDirectDebitToPrn = `${ApiPrefix}/payment/adddirectdebittoprn?apttusId={0}`;
