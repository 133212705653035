import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }

import { computed } from 'vue';
import { toCurrency } from '@/utils/convert';


export default /*@__PURE__*/_defineComponent({
  __name: 'DollarAmount',
  props: {
    amount: {},
    showStarSign: { type: Boolean },
    innerClass: {},
    outerClass: {}
  },
  setup(__props: any) {

const props = __props;

const formattedAmount = computed(() => {
  try {
    if (!props.amount) return { amount: '', decimal: '' };
    const val = toCurrency(props.amount);
    return {
      amount: val.slice(1, val.lastIndexOf('.')),
      decimal: val.slice(val.lastIndexOf('.') + 1),
    };
  } catch (error) {
    return { amount: '', decimal: '' };
  }
});

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.outerClass ?? 'font-sans'])
  }, [
    _createElementVNode("h1", {
      class: _normalizeClass([_ctx.innerClass ? `${_ctx.innerClass} mb-1` : 'text-racq-dark-grey text-xl3 !font-medium mb-1'])
    }, [
      _createElementVNode("span", null, "$" + _toDisplayString(formattedAmount.value.amount) + "." + _toDisplayString(formattedAmount.value.decimal), 1),
      (_ctx.showStarSign)
        ? (_openBlock(), _createElementBlock("sup", _hoisted_1, "*"))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2)), [
    [_vShow, formattedAmount.value.amount && formattedAmount.value.decimal]
  ])
}
}

})