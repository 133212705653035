<template>
  <SectionFrame :sectionTitle="highlightsTitle" titleClasses="text-xl-bold">
    <template #sectionFrameContent>
      <div class="grid grid-cols-1 w-full">
        <div
          v-for="(item, itemIndex) in highlights"
          :key="itemIndex"
          :class="`${getCols()} ${getBottomBorder(itemIndex)}`"
          class="grid mt-4"
        >
          <div v-if="showNumber">
            <span
              class="grid h-[24px] w-[24px] place-items-center rounded-full bg-gray-600 text-xs text-white text-xs-bold"
            >
              {{ itemIndex + 1 }}</span
            >
          </div>
          <div
            class="text-base"
            :class="[!isLastItem(itemIndex) ? 'pb-4' : '', showNumber ? 'pl-8 md:pl-4' : '']"
            v-html="item.content"
          ></div>
        </div>
      </div>
    </template>
  </SectionFrame>
</template>
<script setup lang="ts">
import SectionFrame from '@/components/SectionFrame/SectionFrame.vue';
import { IHighlight } from '@/interfaces/forms/highlights-interface';

const props = defineProps<{
  showNumber: boolean;
  highlightsTitle: string;
  highlights: IHighlight[];
}>();

const getCols = () => {
  return props.showNumber ? ' grid-cols-[4%_96%]' : '';
};

const getBottomBorder = (itemIndex: number) => {
  return itemIndex + 1 < props.highlights.length ? 'border-b' : '';
};

const isLastItem = (itemIndex: number) => {
  return itemIndex + 1 === props.highlights.length;
};
</script>
