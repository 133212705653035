<template>
  <template v-if="tagOrientation == TagOrientation.vertical">
    <div class="flex flex-col space-y-3 md:space-y-4">
      <template v-for="(tagText, index) of tags" :key="index">
        <Tag :tagType="TagTypes.discount" :tagText="tagText" :outerClass="innerClass" />
      </template>
    </div>
  </template>
  <template v-else>
    <ul class="tags pl-0 flex flex-row list-none flex-wrap">
      <li v-for="(tagText, index) of tags" :key="index" class="mr-2">
        <Tag :tagType="tagType" :tagText="tagText"></Tag>
      </li>
    </ul>
  </template>
</template>
<script setup lang="ts">
import Tag from '@/components/Tag/Tag.vue';
import { TagOrientation, TagTypes } from '@/enums/tag-enums';

const props = defineProps<{
  tags: string[];
  tagType: TagTypes;
  tagOrientation?: TagOrientation;
  innerClass?: string;
}>();

const tagOrientation = props.tagOrientation ?? TagOrientation.horizontal;
</script>
