import {
  postDecisionIntelligenceCreateOffer,
  getDecisionIntelligenceOffer,
  acknowlegeDecisionOffer,
  createLead,
  acknowlegeRenewalDecisionOffer,
  createRsaPrepayOffer,
  getRsaPrepayOffer,
  acknowledgePrepayOffer,
} from '@/constants/forms-api.const';
import { RequestServices as _http } from '../common/request.services';
import {
  IAcknowledgeDecisionRequest,
  ICreateDecisionOffer,
  ICreateRoadsidePrepaidOfferRequest,
  IRenewalAcknowledgementModel,
} from '@/interfaces/decision-intelligence/decision-intelligence-request';
import { ICreateLeadRequestModel } from '@/interfaces/lead/lead-model';

export const DecisionIntelligenceService = {
  /**
   * Create Decision Intelligence Offer Request
   *
   * @param quoteId quote id
   * @returns
   */
  createDecisionIntelligenceOffer(request: ICreateDecisionOffer) {
    return _http.HttpPost(postDecisionIntelligenceCreateOffer, request);
  },
  /**
   * Get decision offer by id
   * @param decisionOfferId decision offer id
   * @returns
   */
  getDecisionIntelligenceOffer(decisionOfferId: string) {
    return _http.HttpGet(getDecisionIntelligenceOffer.replace('{0}', decisionOfferId));
  },
  /**
   * Acknowledge decision offer
   * @param decisionId decision offer id
   * @param request request for decision ack request
   * @returns
   */
  acknowlegeDecisionOffer(decisionId: string, request: IAcknowledgeDecisionRequest) {
    return _http.HttpPost(acknowlegeDecisionOffer.replace('{0}', decisionId), request);
  },

  acknowledgeRenewalDecisionOffer(request: IRenewalAcknowledgementModel) {
    return _http.HttpPost(acknowlegeRenewalDecisionOffer, request);
  },
  /**
   *
   * @param d365Id Create lead in D365
   * @param request
   * @returns
   */
  createLead(d365Id: string, request: ICreateLeadRequestModel) {
    return _http.HttpPost(createLead.replace('{0}', d365Id), request);
  },

  /**
   * Create roadside prepay offer
   * @param request request for creating RSA offer
   * @returns
   */
  createRsaPrepayOffer(request: ICreateRoadsidePrepaidOfferRequest) {
    return _http.HttpPost(createRsaPrepayOffer, request);
  },

  /**
   * get roadside prepay offer
   * @param decisionId decision Id
   * @returns
   */
  getRsaPrepayOffer(decisionId: string) {
    return _http.HttpGet(getRsaPrepayOffer.replace('{0}', decisionId));
  },

  /**
   * ack rsa prepay offer
   * @param prn prn
   * @param apttusQuoteId apttus quote id of selected product
   * @param apttusId apttus id
   * @returns
   */
  acknowledgePrepayOffer(prn: string, apttusQuoteId: string, apttusId: string) {
    return _http.HttpPost(
      acknowledgePrepayOffer.replace('{0}', prn).replace('{1}', apttusQuoteId).replace('{2}', apttusId)
    );
  },
};
