import animateScrollTo from 'animated-scroll-to';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

/**
 * Smooth scroll to an element in the form wizard.
 *
 * @param {String} el The element to scroll to
 * @param {Number} timeout Begin scrolling after element has attached to the DOM
 * @param {Number} verticalOffset The amount of space to show before the element
 *
 */
export function scrollToElement(el = 'body', timeout = 100, verticalOffset = 0) {
  const elementToScrollTo = document.querySelector(el);
  scrollToElementRaw(elementToScrollTo, timeout, verticalOffset);
}

export function scrollToElementRaw(el: Element | null | undefined, timeout = 100, verticalOffset = 0) {
  if (el) {
    setTimeout(() => {
      animateScrollTo(el, {
        minDuration: 400,
        maxDuration: 600,
        verticalOffset,
      });
    }, timeout);
  }
}

export function isDevEnv() {
  return process.env.NODE_ENV === 'development';
}

/**
 * Obtain the screen size using Tailwind breakpoints.
 *
 * @returns {Record<string, boolean>}
 */
export function getScreenSize() {
  const breakpoints = useBreakpoints(breakpointsTailwind);

  const xs = breakpoints.smaller('sm');
  const xse = breakpoints.smallerOrEqual('sm');
  const sm = breakpoints.between('sm', 'md');
  const md = breakpoints.between('md', 'lg');
  const lg = breakpoints.between('lg', 'xl');
  const xl = breakpoints.between('xl', '2xl');
  const xxl = breakpoints['2xl'];

  return {
    xs,
    xse,
    sm,
    md,
    lg,
    xl,
    xxl,
  };
}

export function isMobileDevice() {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
}

/**
 * Check if the user is at the bottom
 *
 * @returns {boolean} isReachBottom
 */
export function isReachBottom() {
  return document.body.scrollHeight - window.innerHeight <= window.scrollY;
}
