<template>
  <div v-if="options?.length" class="toggle-container">
    <div class="toggle-border">
      <button
        v-for="(option, index) in options"
        :key="index"
        class="toggle-btn min-w-24 w-full"
        :class="props.context?.node?.value == option.value ? 'toggle-btn-active' : ''"
        type="button"
        :value="option.value"
        @click="() => emitChange(option.value)"
      >
        {{ option.label }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IDropdownItem } from '@/interfaces';
import { computed, onMounted } from 'vue';

const props = defineProps<
  Partial<{
    context: any;
  }>
>();

const options = computed(() => (props?.context?.options as IDropdownItem[]) ?? []);

function emitChange(value: string) {
  props.context?.node.input(value);
}

onMounted(() => {
  emitChange(props?.context?.defaultOption ?? options.value[0]?.value);
});
</script>
