import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }

import { computed } from 'vue';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { FormKit } from '@formkit/vue';
import { VueFormInputProps } from '@/composables/useFormInputData';
import { hashCode } from '@/utils/hash-util';


export default /*@__PURE__*/_defineComponent({
  __name: 'RACQFormKit',
  props: /*@__PURE__*/_mergeModels({
    bindings: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;

const model = _useModel<any>(__props, "modelValue");

/**
 * Generate a unique key for the field so that if the bindings change it will force a re-render of the component.
 * This is required to work around some dynamic data binding issues especially with the Formkit dropdown component.
 */
const key = computed(() => {
  if (props.bindings.options) {
    return props.bindings.name + props.bindings.type + hashCode(JSON.stringify(props.bindings.options));
  }
  return props.bindings.name + props.bindings.type;
});

const combinedInnerClass = computed(() => {
  return `${props.bindings.innerClass ?? ''} ${isCheckbox.value && props.bindings.options && props.bindings.options.length > 1 ? 'mt-0.5' : 'self-start'}`;
});

const isCheckbox = computed(() => {
  return props.bindings.type === 'checkbox';
});

const isNativeRadio = computed(() => {
  return props.bindings.type === 'nativeRadio';
});

const hasCheckboxOptions = computed(() => {
  return props.bindings.options != null;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.bindings.outerClass)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_unref(FormKit), _mergeProps(_ctx.bindings, {
        key: key.value,
        modelValue: model.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
        postContent: "",
        outerClass: "w-full",
        innerClass: combinedInnerClass.value,
        classes: {
          options: `mb-0 ${_ctx.bindings.classesOptions} `,
          message: isCheckbox.value ? 'pl-10' : isNativeRadio.value ? 'mt-3' : '',
        },
        onpaste: _ctx.bindings.disablePaste ? 'return false' : ''
      }), _createSlots({ _: 2 }, [
        (isCheckbox.value && !hasCheckboxOptions.value)
          ? {
              name: "label",
              fn: _withCtx((context) => [
                _createVNode(_unref(RichText), {
                  class: "ml-2 text-sm text-racq-dark-grey",
                  field: { value: context.label ?? '' }
                }, null, 8, ["field"])
              ]),
              key: "0"
            }
          : undefined,
        (_ctx.bindings.suffixIcon)
          ? {
              name: "suffixIcon",
              fn: _withCtx((context) => [
                _createVNode(_unref(RichText), {
                  field: { value: context.suffixIcon ?? '' }
                }, null, 8, ["field"])
              ]),
              key: "1"
            }
          : undefined,
        (_ctx.bindings.helpMd)
          ? {
              name: "help",
              fn: _withCtx(() => [
                _createVNode(_unref(RichText), {
                  field: { value: _ctx.bindings.helpMd },
                  class: "richtext richtext--no-base font-sans text-paragraph-color text-m mb-3"
                }, null, 8, ["field"])
              ]),
              key: "2"
            }
          : undefined
      ]), 1040, ["modelValue", "innerClass", "classes", "onpaste"]))
    ]),
    _renderSlot(_ctx.$slots, "after"),
    (_ctx.bindings.helpSm)
      ? (_openBlock(), _createBlock(_unref(RichText), {
          key: 0,
          class: "formkit-help text-sm text-racq-dark-grey mt-4",
          field: { value: _ctx.bindings.helpSm }
        }, null, 8, ["field"]))
      : _createCommentVNode("", true),
    (_ctx.bindings.postContent && _ctx.bindings.showPostContent)
      ? (_openBlock(), _createBlock(_unref(RichText), {
          key: 1,
          class: _normalizeClass(_ctx.bindings.postContentClass),
          field: { value: _ctx.bindings.postContent }
        }, null, 8, ["class", "field"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})