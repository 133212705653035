import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseIcon',
  props: {
    iconClass: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_ctx.iconClass)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass([_ctx.iconClass, "icon w-6 h-6 bg-no-repeat bg-contain inline-block"])
      }, null, 2))
    : _createCommentVNode("", true)
}
}

})