import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mx-2 text-sm-bold"
}

import { ref, onMounted, computed, watch } from 'vue';
import { IFormStep, IQuote, SubmitQuote } from '@/interfaces';
import {
  useFormStore,
  useJeopardyStore,
  useReferenceDataStore,
  usePaymentStore,
  useConfigSettingsStore,
  IQConfigSettings,
  SettingCategory,
} from '@/store';
import { PaymentTypes } from '@/enums/payment-types.enum';
import SectionFrame from '@/components/SectionFrame/SectionFrame.vue';
import PaymentSection from '@/components/PaymentSection/PaymentSection.vue';
import { JP_GENERIC_CODE } from '@/constants/jeopardy-codes.const';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import DollarAmount from '@/components/DollarAmount/DollarAmount.vue';
import RACQFormKit from '@/components/RACQFormKit/RACQFormKit.vue';
import useFormInputData from '@/composables/useFormInputData';
import { PaymentFrequency } from '@/enums/payment-frequency';
import { toCurrency } from '@/utils/convert';
import { useI18n } from 'vue-i18n';
import { QuoteTypes } from '@/constants/quote-types.const';
import IQPaymentError from '@/components/IQPaymentError/IQPaymentError.vue';

// Define props and emitters
// ------------------------------------------------------------- //

export const emitUpdatePaymentDetails = 'emitUpdatePaymentDetails';
export const emitSubmitQuote = 'emitSubmitQuote';

export default /*@__PURE__*/_defineComponent({
  __name: 'IQPaymentPanel',
  props: {
    step: {},
    quote: {},
    checkAutoRenewAvailable: { type: Boolean },
    paymentSuccessful: { type: Boolean },
    paymentFailedErrorMessage: {},
    paymentPanelContent: {},
    errors: {}
  },
  emits: [emitUpdatePaymentDetails, emitSubmitQuote],
  setup(__props: any, { emit: __emit }) {

const props = __props;
// Define Store
// ------------------------------------------------------------- //
const formStore = useFormStore();
const jpStore = useJeopardyStore();
const paymentStore = usePaymentStore();
const refStore = useReferenceDataStore();
const configSettingsConfigStore = useConfigSettingsStore();

const translation = useI18n();
const dictionary: Record<string, string> = translation.getLocaleMessage('en') as any;

const emit = __emit;
const paymentFrequencyPriceSuffix = ref<string>('');

// Define Composables
// ------------------------------------------------------------- //
const { formInputData } = useFormInputData(props.step.formInputs!);
const premiumLabel = computed(() => {
  if (!props.quote || !props.quote.insuranceQuoteSummary || !props.quote.discounts) return;

  let value = isAnnual.value
    ? props.step.formInputs?.annualPremiumLabel?.label?.value
    : props.step.formInputs?.monthlyPremiumLabel?.label?.value;
  value = value.replace('{{annualPremium}}', toCurrency(props.quote?.insuranceQuoteSummary?.yearlyAnnualPremium));
  value = value.replace('{{instalmentFee}}', toCurrency(props.quote?.insuranceQuoteSummary?.payByTheMonthFee / 12));

  if (isAnnual.value)
    value = value.replace(
      '{{onlineDiscount}}',
      dictionary.IQ_OnlineDiscount.replace(
        '$#value#',
        toCurrency(props.quote.discounts?.totalYearlyOnlineDiscountAmount?.value)
      )
    );
  else
    value = value.replace(
      '{{onlineDiscount}}',
      dictionary.IQ_OnlineDiscount.replace(
        '$#value#',
        toCurrency(props.quote.discounts?.totalMonthlyOnlineDiscountAmount?.value)
      )
    );
  return {
    value,
  };
});

// Define Component state
// ------------------------------------------------------------- //
const formData = ref<Record<string, any>>({});
const insuranceType = ref<string>();
const isAnnual = computed(() => formData.value.paymentFrequency == PaymentFrequency.Annually.toString());
const autoRenewIsEnabled = ref<boolean>(false);

const premium = computed(() => {
  if (isAnnual.value) {
    return props.quote?.insuranceQuoteSummary?.yearlyAnnualPremium;
  } else {
    return props.quote?.insuranceQuoteSummary?.monthlyDeposit;
  }
});

const paymentStepLabel = computed(() => {
  let value = props.step.formInputs?.paymentTitle?.label?.value;
  value = value?.replace('{{quoteType}}', insuranceType.value);
  return {
    value,
  };
});

// Lifecycle hooks
// ------------------------------------------------------------- //
onMounted(async () => {
  const submissionRetries = (await configSettingsConfigStore.getSetting(
    IQConfigSettings.PaymentSubmissionRetries,
    SettingCategory.IQSettings
  )) as number;
  paymentStore.maxRetries = submissionRetries ?? 3;
  await initialisePayment();
});

watch(
  () => formData.value.paymentTermsAndConditions,
  (newValue) => {
    paymentStore.disablePaymentPanel = !newValue;
  }
);
watch(
  () => formData.value.paymentFrequency,
  () => {
    setPaymentPriceSuffix();
    setPaymentFrequencyPostContent();
  }
);
watch(() => formData.value.futureRenewal, setAutoRenewPostContent);
watch(
  () => paymentStore.paymentRetryAttempts,
  async function () {
    //This is done to reset the zebra iframe so payment can be attempted again.
    await resetPaymentIframe();
    await initialisePayment();

    if (paymentStore.hasExceededMaxPaymentAttempts()) {
      formStore.setDisableAllSteps();
    }
  }
);

// Define Functions
// ------------------------------------------------------------- //

async function initialisePayment() {
  if (props.checkAutoRenewAvailable) {
    autoRenewIsEnabled.value =
      formData.value.paymentFrequency == PaymentFrequency.Monthly &&
      ((await configSettingsConfigStore.getSetting(
        IQConfigSettings.IsHomeAutoRenewalPreferenceEnabled,
        SettingCategory.IQSettings
      )) as boolean);
  }

  /** Set dynamic contents */
  paymentStore.submitPaymentCallback = onSubmit;
  paymentStore.disablePaymentPanel = !formData.value.paymentTermsAndConditions;

  reverseMapPaymentDetails();

  setPaymentPriceSuffix();
  setDiscountLabel();
  setPaymentFrequencyPostContent();
  setAutoRenewPostContent();
  insuranceType.value = await refStore.getInsuranceProductName(QuoteTypes[props.quote.quoteType!]);

  /** Map payment details for FZ Iframe */
  paymentStore.amount = premium?.value?.toString() ?? '0';
  paymentStore.buttonText = props.step.formInputs?.paymentMethod?.fields[0]?.SubmitPaymentButtonLabel?.value;
  paymentStore.reference = props.quote?.reference ?? '';
}

function setDiscountLabel() {
  const paymentSection = props.step.formInputs?.paymentMethod?.fields[0];

  if (!paymentSection) return; // Safeguard against undefined `paymentSection`

  if (isAnnual.value) {
    paymentSection.PayLaterContent.value = paymentSection.PayLaterContent?.value.replace(
      '{{onlineDiscount}}',
      dictionary.IQ_OnlineDiscount.replace(
        '$#value#',
        toCurrency(props.quote.discounts?.totalYearlyOnlineDiscountAmount?.value)
      )
    );
  } else {
    paymentSection.PayLaterContent.value = paymentSection.PayLaterContent?.value.replace(
      '{{onlineDiscount}}',
      dictionary.IQ_OnlineDiscount.replace(
        '$#value#',
        toCurrency(props.quote.discounts?.totalMonthlyOnlineDiscountAmount?.value)
      )
    );
  }
}

function setPaymentPriceSuffix() {
  const optionsValue = props.step.formInputs?.paymentFrequencyPricePrefix?.options.value;
  if (!optionsValue) return;

  const pricePrefixes = Object.fromEntries(new URLSearchParams(optionsValue));
  const enumKey = PaymentFrequency[formData.value.paymentFrequency];

  paymentFrequencyPriceSuffix.value = pricePrefixes[enumKey] || '';
}

function setPaymentFrequencyPostContent() {
  if (!props.quote?.insuranceQuoteSummary) return;

  let value =
    formData.value.paymentFrequency === PaymentFrequency.Monthly
      ? props.step.formInputs?.paymentFrequencyMonthlyPostContent?.label?.value
      : props.step.formInputs?.paymentFrequencyAnnuallyPostContent?.label?.value;
  value = value.replace('{{annualAmount}}', toCurrency(props.quote?.insuranceQuoteSummary?.yearlyAnnualPremium));
  value = value.replace('{{monthlyAmount}}', toCurrency(props.quote?.insuranceQuoteSummary?.monthlyDeposit));

  // Show post content
  formStore.updateInput({
    inputId: 'paymentFrequency',
    updates: {
      showPostContent: true,
      postContent: {
        value,
      },
    },
  });
}

async function setAutoRenewPostContent() {
  if (!autoRenewIsEnabled.value) return;
  const value =
    formData.value.futureRenewal == 'true'
      ? props.step.formInputs?.futureRenewalOn?.label?.value
      : props.step.formInputs?.futureRenewalOff?.label?.value;
  // Show post content
  formStore.updateInput({
    inputId: 'futureRenewal',
    updates: {
      showPostContent: true,
      postContent: {
        value,
      },
    },
  });
}

function reverseMapPaymentDetails() {
  formData.value.paymentFrequency = props.quote.paymentFrequency;
  formData.value.futureRenewal = props.quote.renewalPreference?.toString();
}

function mapPaymentDetails() {
  emit(
    emitUpdatePaymentDetails,
    formData.value.paymentFrequency,
    formData.value.futureRenewal,
    paymentStore.selectedPaymentMethod
  );
}

function showJeopardyPanel(jpCode: string) {
  jpStore.showJeopardyPanel({
    jpCode: jpCode,
  });
}

async function resetPaymentIframe() {
  paymentStore.reference = '';
}

async function onSubmit() {
  try {
    if (paymentStore.paymentRetryAttempts >= paymentStore.maxRetries) return;

    mapPaymentDetails();

    let submitQuote = null;

    switch (props.quote.paymentType) {
      case PaymentTypes.CreditCard:
        submitQuote = new SubmitQuote().createSubmitQuoteUsingCardPaymentDetails(paymentStore.cardDetails);
        break;
      case PaymentTypes.BankAccount:
        if (paymentStore.directDebitDetails) {
          submitQuote = new SubmitQuote().createSubmitQuoteUsingBankAccountDetails(paymentStore.directDebitDetails);
        }
        break;
      default:
        submitQuote = new SubmitQuote();
        break;
    }

    if (!submitQuote) {
      throw JP_GENERIC_CODE;
    }

    emit(emitSubmitQuote, submitQuote);
  } catch (jPCode: any) {
    formStore.showLoadingForCurrentStep(false);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createBlock(_component_FormKit, {
    modelValue: formData.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData).value = $event)),
    type: "form",
    onSubmit: () => {}
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(RichText), {
        class: "text-racq-navy text-xl2",
        field: paymentStepLabel.value
      }, null, 8, ["field"]),
      _createVNode(DollarAmount, {
        amount: premium.value,
        showStarSign: false
      }, {
        default: _withCtx(() => [
          (paymentFrequencyPriceSuffix.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(paymentFrequencyPriceSuffix.value), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["amount"]),
      _createVNode(_unref(RichText), {
        class: "text-sm",
        field: premiumLabel.value
      }, null, 8, ["field"]),
      _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1)),
      _createVNode(RACQFormKit, {
        bindings: _unref(formInputData)('paymentFrequency')
      }, null, 8, ["bindings"]),
      (autoRenewIsEnabled.value)
        ? (_openBlock(), _createBlock(RACQFormKit, {
            key: 0,
            bindings: _unref(formInputData)('futureRenewal'),
            class: "mt-6"
          }, null, 8, ["bindings"]))
        : _createCommentVNode("", true),
      _createVNode(RACQFormKit, {
        bindings: _unref(formInputData)('paymentTermsAndConditions'),
        class: "my-6"
      }, null, 8, ["bindings"]),
      _createVNode(IQPaymentError, {
        step: _ctx.step,
        paymentSuccessful: _ctx.paymentSuccessful,
        errors: _ctx.errors
      }, null, 8, ["step", "paymentSuccessful", "errors"]),
      (!_ctx.paymentSuccessful)
        ? (_openBlock(), _createBlock(SectionFrame, {
            key: 1,
            sectionTitle: _ctx.step.formInputs?.paymentMethod?.SectionTitle?.value
          }, {
            sectionFrameContent: _withCtx(() => [
              _createVNode(PaymentSection, _normalizeProps(_guardReactiveProps(_ctx.step.formInputs?.paymentMethod?.fields[0])), null, 16)
            ]),
            _: 1
          }, 8, ["sectionTitle"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})