import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-properties"]
const _hoisted_2 = { class: "cx-side-button-group" }

import liveChatConfigProd from './data/config.prod';
import jquery from 'jquery';
import { SitecoreProps } from '@/interfaces/pages/sitecore-props.interface';
import { Field } from '@sitecore-jss/sitecore-jss-vue';
import { nextTick, onMounted, onUnmounted, computed, watch, ref } from 'vue';
import { useLiveChatStore, useJeopardyStore } from '@/store';

/**
 * Variables
 */
declare const CXBus: any;
declare const _genesys: any;

export default /*@__PURE__*/_defineComponent({
  __name: 'LiveChat',
  props: {
    rendering: {},
    fields: {},
    params: {}
  },
  setup(__props: any) {

const store = useLiveChatStore();
const jpStore = useJeopardyStore();
const props = __props;
const $ = jquery;
const chatOpened = ref(false);
let liveChatConfig: any = {},
  liveChatModel = {
    defaults: {
      RACQPlugin: undefined,
      config: {},

      // Keep track of WebChat and WebChatService states
      chatOpen: false,
      chatReady: false,
      chatCategoryOnline: false,

      chatServiceReady: false,
      chatSessionActive: false,

      chatButtonEnabled: false,
      chatButtonVisible: false,
    },
  },
  config: any = {},
  instance: any;

const chatProperties = computed(
  () =>
    `${liveChatModel.defaults.chatCategoryOnline}|${liveChatModel.defaults.chatServiceReady}|${liveChatModel.defaults.chatReady}`
);
const chatButtonProperties = computed(
  () =>
    `${liveChatModel.defaults.chatButtonEnabled}|${liveChatModel.defaults.chatOpen}|${liveChatModel.defaults.chatSessionActive}`
);

const fireLiveChatOnClick = (e: Event) => {
  const className = (e.target as any).classList as DOMTokenList;
  if (className.contains('btn-livechat')) {
    store.startChat();
  }
};

/**
 * Hooks
 */
onMounted(() => {
  if ((props.fields?.Enabled as Field)?.value) {
    liveChatConfig = liveChatConfigProd;
    nextTick(function () {
      init();
    });

    // Fire livechat when .btn-livechat clicked
    document.addEventListener('click', fireLiveChatOnClick);
  }
});

onUnmounted(() => {
  document.removeEventListener('click', fireLiveChatOnClick);
});

watch(chatProperties, function (value) {
  const [chatCategoryOnline, chatServiceReady, chatReady] = value.split('|');
  if (!stringToBoolean(chatReady) || !stringToBoolean(chatServiceReady) || !stringToBoolean(chatCategoryOnline)) return;
  liveChatModel.defaults.chatButtonEnabled = true;
});

watch(chatButtonProperties, function (value) {
  const [chatButtonEnabled, chatOpen, chatSessionActive] = value.split('|');
  if (!stringToBoolean(chatButtonEnabled) || stringToBoolean(chatOpen) || stringToBoolean(chatSessionActive)) {
    liveChatModel.defaults.chatButtonVisible = false;
  } else {
    liveChatModel.defaults.chatButtonVisible = true;
  }
});

watch(
  () => liveChatModel.defaults.chatButtonVisible,
  (value) => {
    const isVisible = value;

    if (isVisible) {
      store.RACQPlugin.command('WebChat.showChatButton', { customEvent: true });
    } else {
      store.RACQPlugin.command('WebChat.hideChatButton', { customEvent: true });
    }
  }
);

/**
 * Functions
 */

function checkChat(category: string) {
  jpStore.setChatLoading(true);
  return new Promise(function (resolve, reject) {
    store.RACQPlugin.command('RACQPlugin.checkChat', {
      category: category,
    })
      .done((data: any) => {
        jpStore.setChatEnabled(true);
        store.setChatEnabled(true);
        resolve(data);
      })
      .fail((errorMessage: any) => {
        jpStore.setChatEnabled(false);
        store.setChatEnabled(false);
        reject(errorMessage);
      });
  });
}

function addPrintButton() {
  const buttonContainer = $('.cx-webchat .cx-buttons-window-control');
  const printButton = $('<button type="button" class="cx-icon cx-print-transcript"></button>');

  buttonContainer.prepend(printButton);

  const printTranscript = () => {
    store.RACQPlugin.command('WebChatService.getTranscript')
      .done(function (transcript: any) {
        if (!transcript || !transcript.messages) {
          return;
        }

        const messages = transcript.messages
          .filter((message: any) => !['TypingStopped', 'TypingStarted'].includes(message.type))
          .map(function (message: any) {
            return {
              text: message.text,
              name: message.from.name || 'User',
              date: new Date(message.timestamp).toLocaleString(),
            };
          });

        const transcriptTemplate = `
          <div class="cx-print-transcript-message">
            ${messages
              .filter((message: any) => message.text)
              .map((message: any) => `<span>${message.name} - ${message.date}</span><p>${message.text}</p>`)
              .join('')}
          </div>
        `;

        const printWindow = window.open();
        printWindow?.document.write(transcriptTemplate);

        setTimeout(function () {
          printWindow?.focus();
          printWindow?.print();
          printWindow?.close();
        });
      })
      .fail(function (error: any) {
        console.error('[Live Chat] Failed to get transcript:', error);
      });
  };

  printButton.on('click', printTranscript);
}

function addMenuToggle() {
  const emojiButton = $('.cx-menu .cx-emoji');
  const uploadButton = $('.cx-menu .cx-upload');
  const cobrowseButton = $('.cx-menu .cx-cobrowse');

  // Check if at least one of the menu buttons is enabled
  if (
    emojiButton.css('display') === 'block' ||
    uploadButton.css('display') === 'block' ||
    cobrowseButton.css('display') === 'block'
  ) {
    const inputContainer = $('.cx-webchat .cx-input-container');
    const menuToggle = $("<button type='button' class='cx-menu-toggle'></button>");

    inputContainer.append(menuToggle);

    menuToggle.on('click', () => {
      $('.cx-menu-cell').toggleClass('visible');
    });
  }
}

function liveChatViewInit() {
  const category = config.checkChat.defaultCategory;
  // Prevent built-in showChatButton event
  store.RACQPlugin.before('WebChat.showChatButton', function (data: any) {
    if (data.customEvent) {
      liveChatModel.defaults.chatButtonVisible = true;
      return data;
    }

    // Returning nothing prevents the event
    return;
  });

  // Prevent built-in hideChatButton event
  store.RACQPlugin.before('WebChat.hideChatButton', function (data: any) {
    // Prevent every event that is not custom event
    if (data.customEvent) {
      liveChatModel.defaults.chatButtonVisible = false;
      return data;
    }

    return;
  });

  // Overwrite webchat.open event
  store.RACQPlugin.before('WebChat.open', function (data: any) {
    liveChatModel.defaults.chatOpen = true;
    chatOpened.value = true;

    // If restoring, allow it to pass through
    if (data.restoring || data.customEvent) {
      return data;
    }
    store
      .getLiveChatSessionData()
      .then((response: any) => {
        if (response.errors?.length) throw response;
        const newChat = {
          customEvent: true,
          form: {
            autoSubmit: true,
            // @todo maybe move this to config / make server customizable?
            firstname: response.firstName,
            lastname: response.lastName,
            email: response.email,
          },
          userData: {
            // @todo: pick-up user data from the page data
            'MEMBERSHIP-CARD': response.membershipCard,
            'X-RACQ-CH-COMMENTS': jpStore.additionalComments,
            'X-RACQ-CH-FORM-STEP': jpStore.cancellationReasons,
            'X-RACQ-CH-PAGE-ERROR': jpStore.jpCode + ': ' + jpStore.jpText,
            'X-RACQ-CH-SOURCE-PAGE': document.title,
            'X-RACQ-CH-REFERRAL-URL': document.URL,
            'X-RACQ-CH-CATEGORY': category,
            // "X-RACQ-CH-WEBSITE": ''
          },
        };
        store.RACQPlugin.command('WebChat.open', newChat);
      })
      .catch(function (error: any) {
        const newChat = {
          customEvent: true,
          form: {
            autoSubmit: true,
            // @todo maybe move this to config / make server customizable?
            firstname: 'Anonymous',
            lastname: 'Customer',
            email: 'anonymous@email.com',
          },
          userData: {
            // @todo: pick-up user data from the page data
            // "MEMBERSHIP-CARD": "123123123",
            // "X-RACQ-CH-COMMENTS": ''
            // "X-RACQ-CH-FORM-STEP": ''
            // "X-RACQ-CH-PAGE-ERROR": ''
            'X-RACQ-CH-SOURCE-PAGE': document.title,
            'X-RACQ-CH-REFERRAL-URL': document.URL,
            'X-RACQ-CH-CATEGORY': category,
            // "X-RACQ-CH-WEBSITE": ''
          },
        };
        store.RACQPlugin.command('WebChat.open', newChat);
      });
    return;
  });

  // Add custom layout elements to webchat
  store.RACQPlugin.subscribe('WebChat.opened', function () {
    addMenuToggle();
    addPrintButton();
  });
}

function registerCheckChat() {
  store.RACQPlugin.registerCommand('checkChat', function (e: any) {
    const category = e.data?.category || config.checkChat.defaultCategory;
    const categoryUrl = `${config.checkChat.url}${category}`;

    $.ajax(categoryUrl)
      .then(function (data) {
        if (data && data.online) {
          e.deferred.resolve(data);
        } else {
          e.deferred.reject(data.message || 'Unknown Error Occured');
        }
      })
      .catch(function (jqXHR, textStatus) {
        e.deferred.reject(textStatus);
      });
  });
}

function getSessionData(): Promise<any> {
  const promise = new Promise(function (resolve, reject) {
    if (!store.RACQPlugin) {
      reject({});
    }

    store.RACQPlugin.command('WebChatService.getSessionData').done(resolve).fail(reject);
  });

  return promise;
}

function minimize() {
  const isMobile = _genesys.widgets.common.isMobileDevice();

  if (!config.webchat?.minimizeOnMobileRestore || !isMobile) {
    return;
  }

  const promise = new Promise(function (resolve, reject) {
    if (!store.RACQPlugin) {
      reject({});
    }

    store.RACQPlugin.command('WebChat.minimize').done(resolve).fail(reject);
  });

  return promise;
}

function liveChatModelInit() {
  // Create new plugin command to check if chat category is online on the server
  registerCheckChat();
  if (!config.main.enabled) {
    // Live chat is disabled for this page and button will not be shown
    // It can only be restored from active chat session and all events logic below can be skipped.
    // We still init registerCheckChat above in case another script wants to use it.
    return;
  }

  // Track WebChat states:
  store.RACQPlugin.subscribe('WebChat.ready', function () {
    liveChatModel.defaults.chatReady = true; //set('chatReady', true);
  });

  store.RACQPlugin.subscribe('WebChat.closed', function () {
    liveChatModel.defaults.chatOpen = false;
    chatOpened.value = false;
  });

  // Track WebChatService states:
  store.RACQPlugin.subscribe('WebChatService.ready', async function () {
    // Check if WebChatService has session data stored
    const sessionData = await getSessionData();
    liveChatModel.defaults.chatSessionActive = sessionData.sessionID ? true : false;
    liveChatModel.defaults.chatServiceReady = true;
    // Assuming that if sessionID exists - session will be restored
    //set('chatSessionActive', );
    //set('chatServiceReady', true);
  });

  store.RACQPlugin.subscribe('WebChatService.started', function () {
    liveChatModel.defaults.chatSessionActive = true;
  });

  store.RACQPlugin.subscribe('WebChatService.restored', function () {
    //set('chatSessionActive', true);
    liveChatModel.defaults.chatSessionActive = true;
    minimize();
  });

  store.RACQPlugin.subscribe('WebChatService.ended', function () {
    liveChatModel.defaults.chatSessionActive = true;
  });
  const category = config.checkChat.defaultCategory;

  checkChat(category)
    .then(function () {
      liveChatModel.defaults.chatCategoryOnline = true;
    })
    .catch(function (errorMessage) {
      liveChatModel.defaults.chatCategoryOnline = false;
    });
}

function init() {
  if (instance) {
    console.warn('[Live Chat] Cannot re-initialize the widget.');
    return;
  }

  (window as any)._genesys = (window as any)._genesys || {};

  config = liveChatConfig;

  if (props.fields?.chatConfiguration) {
    const pageConfig = props.fields.chatConfiguration;

    config = $.extend(true, config, pageConfig);
  }

  _genesys.widgets = liveChatConfig;
  CXBus.configure({
    debug: config.main.debug,
    pluginsPath: window.location.origin + '/scripts/genesis/js/',
  });

  CXBus.loadPlugin('widgets-core').done(function () {
    // Both plugins always need to load in case active session exists,
    // even if component is not present on the page
    CXBus.loadPlugin('webchat');
    CXBus.loadPlugin('webchatservice');
  });

  // Push plugin to store
  store.initPlugin(CXBus.registerPlugin('RACQPlugin'));
  const liveChatView = liveChatViewInit();
  liveChatModelInit();

  instance = liveChatView;
}

function stringToBoolean(value: string) {
  if (value == 'true') return true;
  else {
    return false;
  }
}

return (_ctx: any,_cache: any) => {
  return (_unref(store).liveChatEnabled)
    ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "live-chat cx-widget cx-theme-racq",
        "data-properties": JSON.stringify(_ctx.fields!.chatConfiguration)
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            type: "button",
            class: "cx-side-button",
            "aria-label": "Start Live Chat",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(store).startChat()))
          })
        ])
      ], 8, _hoisted_1)), [
        [_vShow, !chatOpened.value]
      ])
    : _createCommentVNode("", true)
}
}

})