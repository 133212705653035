/**
 * Custom plugin to integrate spinner into the Vue Formkit library.
 * Spinner will be shown in the form input 'suffixIcon' slot.
 *
 * To make this solution work, this code leverages Formkit Schemas.
 * Read more about Schemas: https://formkit.com/essentials/schema
 *
 */
function formkitSpinnerPlugin(node: any) {
  node.on('created', () => {
    // extract tooltip attrs from node and set as props
    node.addProps(['loading']);

    const renderSchema = node?.props?.definition?.schema;

    if (!renderSchema) return;

    if (node.props.family == 'button') {
      node.props.definition.schema = (extensions: Record<string, any>) => {
        extensions.prefix = {
          $el: 'div',
          if: '$loading',
          attrs: {
            class: 'w-5 h-5 border-2 border-current border-r-transparent rounded-full mr-2 animate-spin',
          },
        };
        return renderSchema(extensions);
      };
    } else {
      node.props.definition.schema = (extensions: Record<string, any>) => {
        extensions.prefix = {
          if: '$loading',
          $el: 'i',
          attrs: {
            class: 'formkit-input-spinner mx-5 outline-none mt-1 right-[-5rem] absolute',
          },
        };

        return renderSchema(extensions);
      };
    }
  });
}

export default formkitSpinnerPlugin;
