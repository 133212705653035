export const toNumber = (number?: any) => {
  try {
    return number ? Number(number) : 0;
  } catch {
    return 0;
  }
};

export const toCurrency = (number?: number | string, showZeroDecimals: boolean = true): string => {
  try {
    if (number === undefined) {
      return '';
    }

    const formatted = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(Number(number));
    const [integerPart, decimalPart] = formatted.split('.');

    if (!showZeroDecimals && decimalPart === '00') {
      return integerPart;
    }

    return formatted;
  } catch {
    return '';
  }
};

export const toBool = (value?: any): boolean => {
  try {
    return value === 'true' || value === 1 || value === true;
  } catch {
    return false;
  }
};
