import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, unref as _unref, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 9 }

import { IFormStep, ISitecoreFormGenericInput, RACQAddressResult } from '@/interfaces';
import { computed } from 'vue';
import { FormInputTypes } from '@/enums/form-input-types.enum';
import useFormInputData, { getAdobeTargetDataAttributes } from '@/composables/useFormInputData';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import DateInput from '@/components/DateInput/DateInput.vue';
import TitleNameInput from '@/components/TitleNameInput/TitleNameInput.vue';
import RACQFormKit from '@/components/RACQFormKit/RACQFormKit.vue';
import Gender from '@/components/Gender/Gender.vue';
import Disclaimer from '@/components/Disclaimer/Disclaimer.vue';
import CallToActionCard from '@/components/CallToActionCard/CallToActionCard.vue';
import SectionFrame from '@/components/SectionFrame/SectionFrame.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import AccountLoginInfoBox from '@/components/AccountLoginInfoBox/AccountLoginInfoBox.vue';
import PhoneNumberInput from '@/components/PhoneNumberInput/PhoneNumberInput.vue';
import PanelTitle from '@/components/PanelTitle/PanelTitle.vue';
import SendEmailConfirmation from '@/components/EmailConfirmation/SendEmailConfirmation.vue';

// Define Vue props
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'FormStepInputs',
  props: {
    step: {}
  },
  setup(__props: any) {

const props = __props;

const { formInputData, convertSitecoreToVueRadioButtonCardOptions, convertSitecoreToVueCheckboxListOptions } =
  useFormInputData(props.step.formInputs!);
const inputs = computed<ISitecoreFormGenericInput[]>(() =>
  props.step?.formInputs ? Object.values(props.step?.formInputs).filter((input) => !input.isHidden) : []
);

const getRacqFormKitBindings = (input: any) => {
  if (input.inputType?.value == FormInputTypes.CHECKBOX && input.fields.length > 0) {
    return {
      ...formInputData(input.formInputId?.value),
      options: convertSitecoreToVueCheckboxListOptions(input),
    };
  } else {
    return formInputData(input.formInputId?.value);
  }
};

function adobeTargetDataTypes(input: Record<string, any>) {
  return getAdobeTargetDataAttributes(input, props.step.stepName!);
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(inputs.value as Record<string, any>[], (input) => {
    return (_openBlock(), _createElementBlock("div", _mergeProps({
      key: input.formInputId?.value + input.label?.value,
      ref_for: true
    }, adobeTargetDataTypes(input)), [
      _renderSlot(_ctx.$slots, input.formInputId?.value, {}, () => [
        (input.inputType?.value == _unref(FormInputTypes).ADDRESS_AUTOCOMPLETE)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(" [Please use AddressLookupOptions component] ")
            ], 64))
          : (input.inputType?.value == _unref(FormInputTypes).DATE_INPUT)
            ? (_openBlock(), _createBlock(DateInput, {
                key: 1,
                bindings: _unref(formInputData)(input.formInputId?.value),
                minDateToday: input?.minDateToday?.value,
                maxDateByDays: input?.maxDateByDays?.value,
                minDate: input?.minDate?.value
              }, null, 8, ["bindings", "minDateToday", "maxDateByDays", "minDate"]))
            : (input.inputType?.value == _unref(FormInputTypes).TITLE_NAME)
              ? (_openBlock(), _createBlock(TitleNameInput, {
                  key: 2,
                  bindings: _unref(formInputData)(input.formInputId?.value),
                  titleRequiredMessage: input?.titleRequiredMessage?.value,
                  firstnameRequiredErrorMessage: input?.firstnameRequiredErrorMessage?.value,
                  firstnameRegex: input?.firstnameRegex?.value,
                  firstnameRegexErrorMessage: input?.firstnameRegexErrorMessage?.value,
                  lastnameRequiredErrorMessage: input?.lastnameRequiredErrorMessage?.value,
                  lastnameRegex: input?.lastnameRegex?.value,
                  lastnameRegexErrorMessage: input?.lastnameRegexErrorMessage?.value
                }, null, 8, ["bindings", "titleRequiredMessage", "firstnameRequiredErrorMessage", "firstnameRegex", "firstnameRegexErrorMessage", "lastnameRequiredErrorMessage", "lastnameRegex", "lastnameRegexErrorMessage"]))
              : (input.inputType?.value == _unref(FormInputTypes).LABEL)
                ? (_openBlock(), _createBlock(_unref(RichText), {
                    key: 3,
                    field: input.label,
                    class: _normalizeClass(input.outerClass?.value)
                  }, null, 8, ["field", "class"]))
                : (input.inputType?.value == _unref(FormInputTypes).GENDER)
                  ? (_openBlock(), _createBlock(Gender, {
                      key: 4,
                      bindings: _unref(formInputData)(input.formInputId?.value)
                    }, null, 8, ["bindings"]))
                  : (input.inputType?.value == _unref(FormInputTypes).CALL_TO_ACTION_CARD)
                    ? (_openBlock(), _createBlock(CallToActionCard, {
                        key: 5,
                        bindings: _unref(formInputData)(input.formInputId?.value)
                      }, null, 8, ["bindings"]))
                    : (input.inputType?.value == _unref(FormInputTypes).DISCLAIMER)
                      ? (_openBlock(), _createBlock(Disclaimer, {
                          key: 6,
                          bindings: input
                        }, null, 8, ["bindings"]))
                      : (input.inputType?.value == _unref(FormInputTypes).SECTION_FRAME)
                        ? (_openBlock(), _createBlock(SectionFrame, {
                            key: 7,
                            bindings: _unref(formInputData)(input.formInputId?.value)
                          }, null, 8, ["bindings"]))
                        : (input.inputType?.value == _unref(FormInputTypes).RADIO_BUTTON_CARD_GROUP)
                          ? (_openBlock(), _createBlock(_component_FormKit, _mergeProps({
                              key: 8,
                              ref_for: true
                            }, _unref(formInputData)(input.formInputId?.value), {
                              options: _unref(convertSitecoreToVueRadioButtonCardOptions)(input)
                            }), null, 16, ["options"]))
                          : (input.inputType?.value == _unref(FormInputTypes).CUSTOM)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
                            : (input.inputType?.value == _unref(FormInputTypes).PHONE_NUMBER)
                              ? (_openBlock(), _createBlock(PhoneNumberInput, {
                                  key: 10,
                                  bindings: _unref(formInputData)(input.formInputId?.value)
                                }, null, 8, ["bindings"]))
                              : (input.inputType?.value == _unref(FormInputTypes).DATEPICKER)
                                ? (_openBlock(), _createBlock(DatePicker, {
                                    key: 11,
                                    bindings: _unref(formInputData)(input.formInputId?.value),
                                    minDateToday: input?.minDateToday?.value,
                                    maxDateByDays: input?.maxDateByDays?.value
                                  }, null, 8, ["bindings", "minDateToday", "maxDateByDays"]))
                                : (input.inputType?.value == _unref(FormInputTypes).LOGINBUTTON)
                                  ? (_openBlock(), _createBlock(AccountLoginInfoBox, {
                                      key: 12,
                                      fields: input
                                    }, null, 8, ["fields"]))
                                  : (input.inputType?.value == _unref(FormInputTypes).PANEL_TITLE)
                                    ? (_openBlock(), _createBlock(PanelTitle, {
                                        key: 13,
                                        bindings: _unref(formInputData)(input.formInputId?.value),
                                        title: input.title!,
                                        subTitle: input.subTitle!,
                                        icon: input.icon,
                                        isSecondary: input.isSecondary
                                      }, null, 8, ["bindings", "title", "subTitle", "icon", "isSecondary"]))
                                    : (input.inputType?.value == _unref(FormInputTypes).CUSTOM_EMAIL_CONFIRMATION)
                                      ? (_openBlock(), _createBlock(SendEmailConfirmation, {
                                          key: 14,
                                          bindings: _unref(formInputData)(input.formInputId?.value),
                                          emailButtonText: input.emailButtonText,
                                          emailHeading: input.emailHeading,
                                          isLoading: false
                                        }, null, 8, ["bindings", "emailButtonText", "emailHeading"]))
                                      : (input.inputType?.value == _unref(FormInputTypes).FIND_CAR)
                                        ? (_openBlock(), _createBlock(_component_FormKit, _mergeProps({
                                            key: 15,
                                            ref_for: true
                                          }, input, { type: "findCar" }), null, 16))
                                        : (_openBlock(), _createBlock(RACQFormKit, {
                                            key: 16,
                                            bindings: getRacqFormKitBindings(input)
                                          }, null, 8, ["bindings"]))
      ])
    ], 16))
  }), 128))
}
}

})