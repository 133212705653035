import { IQuote } from '@/interfaces';
import { toCurrency } from '@/utils/convert';
import { capitalize } from 'lodash-es';

export function getDiscountsList(
  translation: any,
  quote: Partial<IQuote>,
  options = {
    showAsMonthly: false,
  }
) {
  const dictionary: Record<string, string> = translation.getLocaleMessage('en');

  const discounts: string[] = [];
  if (quote?.discounts?.eligibleForCombinedPolicyDiscount?.value) {
    discounts.push(dictionary.IQ_CombinePolicyDiscount);
  }

  if (quote?.discounts?.eligibleForLoyaltyDiscount?.value) {
    discounts.push(
      dictionary.IQ_MemberDiscount.replace('#type#', capitalize(quote?.discounts?.highestMembershipLoyaltyLevel?.value))
    );
  }

  if (quote.discounts?.eligibleForMonthlyOnlineDiscount?.value && options?.showAsMonthly) {
    discounts.push(
      dictionary.IQ_OnlineDiscount.replace(
        '$#value#',
        toCurrency(quote?.discounts.totalMonthlyOnlineDiscountAmount?.value, false)
      )
    );
  }

  if (quote?.discounts?.eligibleForYearlyOnlineDiscount?.value && !options?.showAsMonthly) {
    discounts.push(
      dictionary.IQ_OnlineDiscount.replace(
        '$#value#',
        toCurrency(quote?.discounts.totalYearlyOnlineDiscountAmount?.value, false)
      )
    );
  }

  if (quote?.discounts?.eligibleForMultiPolicyDiscount?.value) {
    discounts.push(dictionary.IQ_MultiPolicyDiscount);
  }

  if (quote?.discounts?.eligibleForSeniorCardDiscount?.value) {
    discounts.push(dictionary.IQ_SeniorDiscountWithSeniorCard);
  }

  if (quote?.discounts?.eligibleForSeniorDiscount?.value) {
    discounts.push(dictionary.IQ_Over55DiscountNoSeniorCard);
  }

  //Comment from sitecore 8:
  // Leaving this out for now as it was not in the spec
  //var floodDiscount = quote.HomeAndContentsQuote.Discounts.EligibleForFloodDiscount.Value;
  //if (floodDiscount)
  //    data.Add(new NameValueModel(SitecoreItem.GetTranslateText("FloodDiscount")));
  //if (quote?.value.homeAndContentsQuote?.discounts?.eligibleForFloodDiscount?.value) {
  // TODO
  //}

  if (quote?.homeAndContentsQuote?.discounts?.eligibleForHouseHoldExcelenceBonus?.value) {
    discounts.push(dictionary.IQ_HouseholdExcellenceBonus);
  }

  if (quote?.homeAndContentsQuote?.homeQuote?.discounts?.eligibleForNoClaimDiscount?.value) {
    discounts.push(dictionary.IQ_NoClaimDiscount);
  } else if (quote?.homeAndContentsQuote?.contentsQuote?.discounts?.eligibleForNoClaimDiscount?.value) {
    discounts.push(dictionary.IQ_NoClaimDiscount);
  }

  if (quote?.homeAndContentsQuote?.contentsQuote?.discounts?.eligibleForApartmentDiscount?.value) {
    discounts.push(dictionary.IQ_ApartmentDiscount);
  }

  return discounts;
}
