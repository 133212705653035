import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col gap-4 basis-full mt-8 mb-10"
}
const _hoisted_2 = { class: "flex flex-col basis-full rounded-md border-solid border-2 border-racq-white" }
const _hoisted_3 = { class: "flex gap-4 rounded py-3 px-6" }
const _hoisted_4 = { class: "flex flex-col justify-center basis-9 shrink-0" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "flex flex-col justify-center ml-auto payment-confirmation__proposition-expander" }
const _hoisted_7 = ["aria-expanded"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "py-4 px-6" }

import { ref } from 'vue';
import {
  IDecisionIntelligenceOfferResponse,
  IStep,
} from '@/interfaces/decision-intelligence/decision-intelligence-response';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
// Define props and emitters
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'DecisionIntelligenceOfferReminder',
  props: {
    decisionOffer: {},
    stepTitle: {},
    isRenewal: { type: Boolean },
    isQuote: { type: Boolean },
    isCrossSell: { type: Boolean },
    expandedByDefault: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const iconMap = {
  chevronIcon: require('@/assets/img/icons/icon-nav-chevron-navy.svg'),
};
// Define Component state
// ------------------------------------------------------------- //

const expanded = ref(props.expandedByDefault);

// Define Functions
// ------------------------------------------------------------- //

const toggleExpand = () => {
  expanded.value = !expanded.value;
};

const getFilterStep = (): IStep => {
  const proposition = props.decisionOffer?.propositions?.filter((x) => x.incentivised)[0];
  return (
    proposition?.displaySteps?.filter(
      (x) =>
        x.stepName == props.stepTitle &&
        x.journey == (props.isRenewal ? 'Make a payment' : props.isQuote ? 'Quote' : 'Purchase') &&
        x.isCrossSell == props.isCrossSell
    )[0] ?? ({} as IStep)
  );
};

return (_ctx: any,_cache: any) => {
  return (_ctx.decisionOffer?.propositions?.filter((x) => x.incentivised)?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                src: getFilterStep().imageUrl,
                Width: "40",
                Height: "30",
                AlternateText: "offer image",
                class: "max-w-none h-7"
              }, null, 8, _hoisted_5)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(expanded.value ? 'text-racq-blue font-bold pt-1.5' : 'text-base-bold pt-1.5')
            }, [
              _createVNode(_unref(RichText), {
                field: { value: getFilterStep().headerText }
              }, null, 8, ["field"])
            ], 2),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                onclick: toggleExpand,
                "aria-expanded": expanded.value,
                type: "button"
              }, [
                _createElementVNode("img", {
                  class: _normalizeClass(expanded.value ? 'transform -rotate-90' : 'transform rotate-90'),
                  src: iconMap.chevronIcon
                }, null, 10, _hoisted_8)
              ], 8, _hoisted_7)
            ])
          ]),
          (expanded.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_unref(RichText), {
                    field: { value: getFilterStep().messageText }
                  }, null, 8, ["field"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})