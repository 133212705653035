import { defineStore } from 'pinia';
import {
  ChangeVehicleLocationService as _changeVehicleLocation,
  VehiclePolicy,
  ChangeVehicleLocationUpdateModel,
} from '@/services/store/change-vehicle-location.service';
import {
  QuoteSessionService as _quoteSessionService,
  ProcessPaymentRequest,
} from '@/services/store/quote-session.service';
import { QuotePremiumDetails } from '@/interfaces/entities/premium-details.interface';
import { ref } from 'vue';
import { JP_GENERIC_CODE } from '@/constants/jeopardy-codes.const';
import { IJeopardy, IServiceResponseError } from '@/interfaces/entities';
import { IReviewSummary, ISitecoreReviewSummaryItem } from '@/interfaces';
import { replaceBookmarks } from '@/utils/bookmark.replace';
import { STANDARD_DATE_FORMAT } from '@/constants/formats';
import { format } from 'date-fns';
import { useAnalyticsStore } from '@/store';
import { FormEvents } from '@/enums/form-events.enum';
import { IAnalyticsPaymentDetail, IFormAnalytics } from '@/interfaces/forms/analytics-interface';

export const useChangeVehicleLocationStore = defineStore('ChangeVehicleLocation', () => {
  const analyticsStore = useAnalyticsStore();

  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //
  const quoteSessionId = ref<string>();
  const currentPolicy = ref<VehiclePolicy>();
  const model = ref<ChangeVehicleLocationUpdateModel>();
  const quotePremiumDetails = ref<QuotePremiumDetails>();
  const jeopardies = ref<IJeopardy[]>([]);
  const errors = ref<IServiceResponseError[]>([]);
  const paymentReceiptNumber = ref<string>();

  /**
   * Get Policy
   */
  async function getPolicy() {
    const params = new URLSearchParams(location.search);
    quoteSessionId.value = params.get('quoteSessionId') ?? '';
    if (!quoteSessionId.value) throw JP_GENERIC_CODE;
    const response = await _changeVehicleLocation.getPolicy(quoteSessionId.value);
    errors.value = response.errors;
    if (response.success && response.data) {
      currentPolicy.value = response.data;
    }
    if (errors.value && errors.value.length > 0) {
      throw errors.value[0].message ?? JP_GENERIC_CODE;
    }
  }

  /**
   * Apply Business Rules
   */
  async function applyBusinessRules(requestQuote?: boolean, bindChange?: boolean) {
    const response = await _changeVehicleLocation.applyBusinessRules({
      quoteSessionId: quoteSessionId.value,
      model: model.value,
      requestQuote: requestQuote,
      bindChange: bindChange,
    });
    errors.value = response.errors;
    if (response.success && response.data) {
      jeopardies.value = response.data.jeopardies;
      quotePremiumDetails.value = response.data?.viewModel?.quotePremiumDetails;
    }
    if (errors.value && errors.value.length > 0) {
      throw errors.value[0].message ?? JP_GENERIC_CODE;
    }
    if (jeopardies.value && jeopardies.value.length > 0) {
      throw jeopardies.value[0].code;
    }
  }

  /**
   * Process Payment
   */
  async function processPayment(data: ProcessPaymentRequest) {
    const response = await _quoteSessionService.processPayment(
      currentPolicy.value?.policyNumber ?? '',
      quoteSessionId.value ?? '',
      data
    );
    errors.value = response.errors;
    paymentReceiptNumber.value = response.data?.receiptNumber;
    if (response.errors && response.errors.length > 0) {
      throw response.errors[0].message ?? JP_GENERIC_CODE;
    }
    return response;
  }

  /**
   * Withdraw Quote Session
   */
  async function withdrawQuoteSession() {
    const response = await _quoteSessionService.withdrawQuoteSession(quoteSessionId.value ?? '');
    errors.value = response.errors;
    if (response.errors && response.errors.length > 0) {
      throw response.errors[0].message ?? JP_GENERIC_CODE;
    }
    return response;
  }

  /**
   * Generate a review summary model for review and confirmation steps
   */
  function getReviewSummaryModel(summaryFields: any): IReviewSummary[] {
    const summaryFieldsResult: IReviewSummary[] = [];

    // The first IReviewSummary item is the changeEffectiveDate section
    const changeEffectiveDate = format(model.value?.changeEffectiveDate ?? '', STANDARD_DATE_FORMAT);
    const changeEffectiveDateObj = {
      changeEffectiveDate,
    };
    summaryFieldsResult.push(replaceBookmarks(summaryFields[0], changeEffectiveDateObj));

    // The second IReviewSummary item from sitecore is the vehicle summary section. We want to duplicate it for each coverage on the policy.
    for (const coverage of currentPolicy.value?.coverages ?? []) {
      const newVehicleLocation = model.value?.vehicleLocations?.find((item) => item.coverageId === coverage.id);
      const summary = {
        formattedLine1: coverage.vehicle?.formattedLine1,
        formattedLine2: coverage.vehicle?.formattedLine2,
        garagingLocation: newVehicleLocation
          ? `${newVehicleLocation.overnightParkingLocationAddressLine} ${newVehicleLocation.overnightParkingLocationSuburb}, ${newVehicleLocation.overnightParkingLocationPostcode}, ${newVehicleLocation.overnightParkingLocationState}`
          : coverage.garagingLocation,
        garagingType: newVehicleLocation ? newVehicleLocation.overnightParkingLocationCode : coverage.garagingType,
      };
      let summaryItem = {
        formContentId: summaryFields[1].formContentId,
        summaryTitle: summaryFields[1].summaryTitle,
        icon: summaryFields[1].icon,
        items: summaryFields[1].items,
      };
      summaryItem = replaceBookmarks(summaryItem, summary);
      summaryFieldsResult.push(summaryItem);
    }

    return summaryFieldsResult.map((field: any) => ({
      ...field,
      items: field.items.map((item: ISitecoreReviewSummaryItem) => {
        return {
          ...item,
          ...item.fields,
          editGoToStep: item.fields.editStep?.value,
          showEdit: item.fields.editStep?.value ?? false,
        };
      }),
    }));
  }

  /**
   * Push data to analytics
   *
   * @param options
   */
  const pushAnalytics = (options: {
    event: FormEvents;
    amount?: number;
    payment?: Partial<IAnalyticsPaymentDetail>;
  }) => {
    const firstVehicle = currentPolicy.value?.coverages?.at(0);
    const model: IFormAnalytics = {
      event: options.event,
      form: {
        detail: {
          makeName: firstVehicle?.vehicle?.make,
          modelName: firstVehicle?.vehicle?.model,
          vehicleYear: firstVehicle?.vehicle?.year,
        },
      },
    };
    if (options.amount) {
      model.form.amount = options.amount;
    }
    if (options.payment) {
      model.payment = options.payment;
    }

    analyticsStore.pushAnalytics(model);
  };

  return {
    getPolicy,
    applyBusinessRules,
    processPayment,
    withdrawQuoteSession,
    getReviewSummaryModel,
    pushAnalytics,
    currentPolicy,
    model,
    quotePremiumDetails,
    jeopardies,
    errors,
    paymentReceiptNumber,
  };
});
