import {
  addDirectDebitToPrn,
  applyDiscountToQuote,
  getPrnDetails,
  processPrnPayment,
  sendReceiptEmail,
} from '@/constants/forms-api.const';
import { RequestServices as _http } from '../common/request.services';
import {
  IApplyAssistanceQuoteDiscountRequest,
  IApplyAssistanceQuoteDiscountResponse,
  IApttusDirectDebitRequest,
  IPayWithCardResponse,
  IPaymentReferenceDetails,
  IProcessPaymentRequest,
  IReceiptEmailResponse,
  IReceiptRequest,
  ISuccessResponse,
} from '@/interfaces/make-a-payment/make-a-payment.interface';

export const MakeAPaymentService = {
  /**
   * Get prn details by prn
   * @param prn payment reference number
   * @returns
   */
  getPrnDetails(prn: string): Promise<IPaymentReferenceDetails> {
    return _http.HttpGet(getPrnDetails.replace('{0}', prn));
  },

  /**
   * Process payment for PRN
   * @param paymentRequest Payment request
   * @returns
   */
  processPayment(paymentRequest: IProcessPaymentRequest): Promise<IPayWithCardResponse> {
    return _http.HttpPost(processPrnPayment, paymentRequest);
  },
  /**
   * Send email receipt
   * @param receiptRequest
   * @returns
   */
  sendReceiptEmail(receiptRequest: IReceiptRequest): Promise<IReceiptEmailResponse> {
    return _http.HttpPost(sendReceiptEmail, receiptRequest);
  },
  /**
   * apply discount quote
   * @param discountRequest
   * @returns
   */
  applyDiscountToQuote(
    discountRequest: IApplyAssistanceQuoteDiscountRequest
  ): Promise<IApplyAssistanceQuoteDiscountResponse> {
    return _http.HttpPost(applyDiscountToQuote, discountRequest);
  },

  /**
   * add direct debit to apttus asset
   * @param apttusId apttus asset line item id
   * @param directDebitRequest direct debit request
   * @returns
   */
  addDirectDebitToPrn(apttusId: string, directDebitRequest: IApttusDirectDebitRequest): Promise<ISuccessResponse> {
    return _http.HttpPost(addDirectDebitToPrn.replace('{0}', apttusId), directDebitRequest);
  },
};
