import { getMotorExcessesUrl } from '@/constants/forms-api.const';
import { RequestServices as _http } from '../common/request.services';

export const CarService = {
  /**
   * Fetch motor excess
   *
   * @param quoteSessionId
   */
  getMotorExcess(quoteType: string, startDate?: string) {
    let url = getMotorExcessesUrl.replace('{0}', quoteType);
    if (!startDate) url = url.split('&')[0];
    else url = url.replace('{1}', startDate);
    return _http.HttpGet(url);
  },
};
