import { defineStore } from 'pinia';
import { ReferenceDataServices as _refData } from '@/services/store/reference-data.services';
import { ref } from 'vue';
import { INameValue } from '@/interfaces/entities/name-value.interface';

enum RefDataTypes {
  HOME_CLAIM_TYPES = 'HomeClaimTypes',
  HOME_LOSS_TYPES = 'HomeLossTypes',
  HOME_LOSS_VALUES = 'HomeLossValues',
}

export const useReferenceDataIQHomeStore = defineStore('ReferenceDataIQHome', () => {
  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //
  const refData = ref<Partial<Record<RefDataTypes, INameValue[]>>>({});

  /**
   * Get claim types
   *
   * @param quoteType
   * @returns
   */
  async function getHomeClaimTypes(quoteType: string): Promise<INameValue[]> {
    if (refData.value.HomeClaimTypes?.length) return refData.value.HomeClaimTypes;
    try {
      const res = await _refData.getHomeClaimTypes(quoteType);
      refData.value.HomeClaimTypes = res || [];
    } catch (error) {
      console.warn('Unable to fetch genders: ', error);
    }
    return refData.value.HomeClaimTypes || [];
  }

  async function getHomeLossTypes(quoteType: string): Promise<INameValue[]> {
    if (refData.value.HomeLossTypes?.length) return refData.value.HomeLossTypes;
    try {
      const res = await _refData.getHomeLossTypes(quoteType);
      refData.value.HomeLossTypes = res || [];
    } catch (error) {
      console.warn('Unable to fetch HomeLossTypes: ', error);
    }
    return refData.value.HomeLossTypes || [];
  }

  async function getHomeLossValues(quoteType: string): Promise<INameValue[]> {
    if (refData.value.HomeLossValues?.length) return refData.value.HomeLossValues;
    try {
      const res = await _refData.getHomeLossValues(quoteType);
      refData.value.HomeLossValues = res || [];
    } catch (error) {
      console.warn('Unable to fetch HomeLossValues: ', error);
    }
    return refData.value.HomeLossValues || [];
  }

  return {
    getHomeClaimTypes,
    getHomeLossTypes,
    getHomeLossValues,
  };
});
