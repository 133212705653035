import {
  getJORGoogleMapsApiKey,
  getJORGoogleMapsDefaultZoomLevel,
  getJORGoogleMapsMapId,
  getJORGoogleMapsTipText,
} from '@/constants/forms-api.const';
import { RequestServices as _http } from '@/services/common/request.services';

export const JORGoogleMapService = {
  getJORGoogleMapsApiKey(): Promise<string> {
    return _http.HttpGet(`${getJORGoogleMapsApiKey}`);
  },
  getJORGoogleMapsMapId(): Promise<string> {
    return _http.HttpGet(`${getJORGoogleMapsMapId}`);
  },
  getJORGoogleMapsDefaultZoomLevel(): Promise<number> {
    return _http.HttpGet(`${getJORGoogleMapsDefaultZoomLevel}`);
  },
  getJORGoogleMapsTipText(): Promise<string> {
    return _http.HttpGet(`${getJORGoogleMapsTipText}`);
  },
};
