<template>
  <div v-if="store.loginVisible" class="py-[30vh] flex items-center justify-center bg-racq-grey-background">
    <p>Logging you in. Please wait</p>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useAccountStore } from '@/store/account/account.state';
import { useRouter, useRoute } from 'vue-router';

/** Local state and data properties*/
const store = useAccountStore();

/** Computed Properties */

/** Functions */

/** Lifecycle Hooks */

onMounted(async () => {
  const route = useRoute();
  const router = useRouter();
  await router.isReady();
  store.authenticate(route);
});
</script>
