import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-4 h-6 flex-col justify-center items-start gap-2.5 inline-flex"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "flex flex-col w-full" }
const _hoisted_4 = {
  key: 0,
  class: "text-racq-dark-grey text-base-bold"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 2,
  class: "flex flex-row gap-4 mt-1"
}
const _hoisted_7 = ["href", "innerHTML"]

import { IInformationBox } from '@/interfaces/forms/information-box.interface';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InformationBox',
  props: {
    type: {},
    title: {},
    description: {},
    links: {}
  },
  setup(__props: any) {

const props = __props;

const iconMap = {
  info: require('@/assets/icons/info-information.svg'),
  warning: require('@/assets/icons/info-warning.svg'),
  error: require('@/assets/icons/info-error.svg'),
  success: require('@/assets/icons/info-success.svg'),
};

const backgroundColorMap = {
  info: 'bg-racq-info-blue',
  warning: 'bg-racq-info-yellow',
  error: 'bg-racq-info-red',
  success: 'bg-racq-info-green',
};

const icon = computed(() => iconMap[props.type]);
const backgroundColor = computed(() => backgroundColorMap[props.type]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['flex w-full px-6 py-5 rounded gap-4', backgroundColor.value])
  }, [
    (!!icon.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createElementVNode("img", {
            src: icon.value,
            alt: `${_ctx.type} icon`,
            class: "w-4 h-4"
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "text-racq-dark-grey text-m mt-[2px]",
            innerHTML: _ctx.description
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.links)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, index) => {
              return (_openBlock(), _createElementBlock("a", {
                key: index,
                href: link.url,
                class: "text-m",
                innerHTML: link.title
              }, null, 8, _hoisted_7))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})