import { IRiskAddressFormatRequest } from './../../interfaces/entities/risk-suburb-lookup-option.interface';
import {
  getAddressLookupAutocomplete,
  getAddressLookupSuburbs,
  getAddressLookupFormat,
  getCountryLookupAutocomplete,
  getIsCycloneCovered,
  formatRiskAddressUrl,
} from '@/constants/forms-api.const';
import { RequestServices as _http } from '@/services/common/request.services';

export const AddressLookupService = {
  getAddressLookup(key: string) {
    return _http.HttpGet(`${getAddressLookupAutocomplete.replace('{0}', key)}`);
  },

  getCountryLookup(key: string, allCountries: boolean) {
    return _http.HttpGet(`${getCountryLookupAutocomplete.replace('{0}', key).replace('{1}', allCountries.toString())}`);
  },

  getAddressLookupSuburbs(key: string) {
    return _http.HttpGet(`${getAddressLookupSuburbs.replace('{0}', key)}`);
  },

  getAddressLookupFormat(query: string) {
    return _http.HttpGet(getAddressLookupFormat + '?' + query);
  },

  getIsCycloneCovered(suburb: string, postcode: string, state: string): Promise<{ isCovered: boolean }> {
    return _http.HttpGet(`${getIsCycloneCovered}?suburb=${suburb}&postcode=${postcode}&state=${state}`);
  },

  formatRiskAddress(payload: IRiskAddressFormatRequest) {
    return _http.HttpPost(formatRiskAddressUrl, payload);
  },
};
