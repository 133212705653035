import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { IJeopardy, IQuote, IServiceResponseError, InsuranceQuotePhase, InsuranceQuoteType } from '@/interfaces';
import { QuoteTypes } from '@/constants/quote-types.const';
import { InsuranceQuoteService as _iqService } from '@/services/store/insurance-quote.service';
import { CarService as _iqmqService } from '@/services/store/car.service';
import { isArray } from 'lodash-es';
import { useJeopardyStore } from '../jeopardy/jeopardy.state';
import { dateToDateString } from '@/utils/data-transforms';
import { JP_RIDE_SHARE } from '@/constants/jeopardy-codes.const';

export const useCarStore = defineStore('Car', () => {
  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //
  const jpStore = useJeopardyStore();
  const quote = ref<Partial<IQuote>>({});
  const quoteId = computed<string>(() => quote.value.id ?? '');
  const quoteType = computed<string>(() => (quote.value.quoteType && QuoteTypes[quote.value.quoteType]) || '');
  const jeopardies = ref<IJeopardy[]>([]);
  const errors = ref<IServiceResponseError[]>([]);

  // ------------------------------------------------------------- //
  // Define Actions
  // ------------------------------------------------------------- //
  async function createQuote(quoteType: InsuranceQuoteType) {
    quote.value.quoteType = quoteType;
    quote.value.quotePhase = InsuranceQuotePhase.QuickQuote;
    quote.value.renewalPreference = true;
    quote.value.renewalTermAcceptance = true;
    const insuranceStartDate = quote.value.insuranceStartDate;
    const res = await _iqService.createQuote(quote.value);
    quote.value = {
      ...res.quote,
      insuranceStartDate,
    };
  }

  async function getMotorExcess() {
    const value = await _iqmqService.getMotorExcess(
      quoteType.value,
      quote.value.insuranceStartDate?.value
        ? dateToDateString(quote.value.insuranceStartDate?.value, 'yyyy-MM-dd')
        : undefined
    );
    return value;
  }

  /**
   * Apply Business Rules
   *
   * @param formData
   */
  const applyBusinessRules = async () => {
    try {
      const response = await _iqService.applyBusinessRules(quoteId.value, quote.value);
      quote.value = response?.quote || quote.value;

      await handleJeopardyOrReasonCodes(response);
    } catch (error) {
      await handleError(error);
    }
  };

  /**
   * Apply Business Rules
   *
   * @param formData
   */
  const saveQuote = async () => {
    try {
      const response = await _iqService.saveQuote(quoteId.value, quote.value);
      quote.value = response?.quote || quote.value;

      await handleJeopardyOrReasonCodes(response);
    } catch (error) {
      await handleError(error);
    }
  };

  /**
   * Calculate quote price
   *
   * @returns
   */
  const calculatePrice = async () => {
    try {
      const response = await _iqService.calculatePrice(quoteId.value, quote.value);
      quote.value = response?.quote || quote.value;

      await handleJeopardyOrReasonCodes(response);
    } catch (error) {
      await handleError(error);
    }
  };

  const handleJeopardyOrReasonCodes = async (response: any) => {
    errors.value = [];
    jeopardies.value = [];
    if (response.success && response.data && response.data.jeopardies?.length > 0) {
      jeopardies.value = response.data.jeopardies;
      throw response.data.jeopardies[0];
    }

    if (response?.reasonCodes?.length > 0) {
      errors.value = response?.reasonCodes || [];
      throw response.reasonCodes[0];
    }

    if (response?.errors?.length > 0) {
      errors.value = [response.errors[0]];
      throw response.errors[0].errorType;
    }
  };

  const handleError = async (error: any) => {
    const isErrorArray = isArray(error);
    errors.value = isErrorArray ? error : [error];
    if (!isErrorArray && error?.code) {
      throw error.code;
    }

    throw ['Unknown error'];
  };

  const applyRulesAndSave = async (calculateQuotePrice: boolean = false) => {
    try {
      // Apply business rules
      await applyBusinessRules();

      if (calculateQuotePrice && !errors.value?.length) {
        // Save Quote for Pricing
        await saveQuote();

        // Recalculate quote price
        await calculatePrice();
      }

      // Save quote with new price
      await saveQuote();
    } catch (error) {
      jpStore.showJeopardyPanel({
        jpCode: jeopardies.value[0]?.code,
      });
    }
  };

  const showRideSharingJeopardy = () => {
    jpStore.showJeopardyPanel({
      jpCode: JP_RIDE_SHARE,
    });
  };

  return {
    quote,
    quoteId,
    quoteType,
    createQuote,
    getMotorExcess,
    applyRulesAndSave,
    showRideSharingJeopardy,
  };
});
