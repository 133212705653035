<template>
  <div class="racq-dropdown">
    <button
      type="button"
      class="racq-dropdown__button"
      :class="{
        'racq-dropdown__button--slim': isSlim,
        'racq-dropdown__button--disabled': isDisabled,
      }"
      @click.stop="toggleDropdown"
    >
      <div class="flex justify-between items-center w-full">
        <span>
          {{
            context.attrs.keepPlaceHolder ? context.attrs.placeholder : (selectedOption ?? context.attrs.placeholder)
          }}</span
        >

        <svg
          class="racq-dropdown__icon"
          :class="{
            'racq-dropdown__icon--disabled': isDisabled,
          }"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M3 5L8 10L13 5" stroke="currentColor" stroke-width="2" />
        </svg>
      </div>
    </button>

    <!-- Dropdown Options -->
    <div
      v-show="isOpen && !isDisabled"
      class="racq-dropdown__menu"
      :class="{
        'racq-dropdown__menu--slim': isSlim,
      }"
    >
      <div class="p-1" role="menu">
        <button
          v-for="(option, index) in context.options"
          :key="index"
          type="button"
          class="racq-dropdown__option"
          @click.stop="selectOption(option)"
        >
          {{ option.label }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';

const props = defineProps<{
  context: any;
}>();

const isOpen = ref(false);
const selectedOption = ref(props.context?.value || null);

const isSlim = computed(() => {
  return props.context?.attrs?.classesOptions === 'dropdown-slim';
});

const isDisabled = computed(() => {
  return props.context?.attrs?.classesOptions === 'dropdown-disabled';
});

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const selectOption = (option: any) => {
  selectedOption.value = option.label;
  isOpen.value = false; // Close dropdown
  emitChange(option);
};

// Emit change to FormKit
const emitChange = (value: any) => {
  props.context?.node.input(value);
};

// Close dropdown when clicking outside
const closeDropdown = (e: MouseEvent) => {
  if (!(e.target as HTMLElement).closest('.racq-dropdown')) {
    isOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', closeDropdown);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', closeDropdown);
});
</script>
