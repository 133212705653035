import { defineStore } from 'pinia';
import { PaymentServices as _paymentService } from '@/services/store';
import { IPaymentConfigurationRequest } from '@/interfaces/entities/payment-configuration-request.interface';
import { IPaymentConfigurationResponse } from '@/interfaces/entities/payment-configuration-response.interface';
import { ref, readonly, Ref } from 'vue';
import { PaymentTypes } from '@/enums/payment-types.enum';
import { IBSBBranchDetails } from '@/interfaces/entities/bsb-branch-details.interface';
import { IDirectDebitDetails } from '@/interfaces/payment/direct-debit-details';
import { v4 } from 'uuid';
import { ICardDetails } from '@/interfaces';
import { IQConfigSettings, SettingCategory, useConfigSettingsStore } from '@/store';

export const usePaymentStore = defineStore('Payment', () => {
  const configSettingsStore = useConfigSettingsStore();

  const reference = ref<string>('');
  const amount = ref<string>('');
  const buttonText = ref<string>('');
  const tokenizeOnly = ref<boolean>(true);
  const googlePay = ref<boolean>(false);
  const bsbBranchDetails = ref<IBSBBranchDetails | null>(null);
  const submitPaymentCallback = ref<((type: PaymentTypes) => void) | null>(null);
  const disablePaymentPanel = ref(false);
  const paymentRetryAttempts = ref<number>(0);
  const maxRetries = ref<number>(3);

  // Fatzebra POST submit states
  const cardDetailsRef: Ref<ICardDetails | null> = ref(null);
  const directDebitDetails: Ref<IDirectDebitDetails | null> = ref(null);
  const selectedPaymentMethod = ref<PaymentTypes | null>(PaymentTypes.CreditCard);

  function changePaymentMethod(paymentMethod: PaymentTypes) {
    selectedPaymentMethod.value = paymentMethod;
  }

  async function setup(config?: {
    amount?: string;
    buttonText?: string;
    reference?: string;
    callback?: (type: PaymentTypes) => void;
  }) {
    amount.value = config?.amount ?? '0';
    buttonText.value = config?.buttonText ?? 'Submit Payment';
    reference.value = config?.reference ?? v4();
    submitPaymentCallback.value = config?.callback ?? submitPaymentCallback.value;

    const submissionRetries = (await configSettingsStore.getSetting(
      IQConfigSettings.PaymentSubmissionRetries,
      SettingCategory.IQSettings
    )) as number;
    if (submissionRetries) {
      maxRetries.value = submissionRetries;
    }
  }

  function mapToCardDetails(obj: any): ICardDetails {
    const arrCardExpiry = obj.card_expiry.split('/'); //The value will be always be in MM/yyyy format
    return {
      cardType: obj.card_type,
      cardHoldersName: obj.card_holder,
      cardExpiryMonth: arrCardExpiry[0],
      cardExpiryYear: arrCardExpiry[1],
      token: obj.token,
      maskedCardNumber: obj.card_number,
    };
  }

  async function setDirectDebitDetails(accountName: string, bsbNumber: string, accountNumber: string) {
    const branch = await _paymentService.bsbLookup(bsbNumber);
    directDebitDetails.value = <IDirectDebitDetails>{};
    directDebitDetails.value.nameOfAccount = accountName;
    directDebitDetails.value.bankStateBranchNumber = bsbNumber;
    directDebitDetails.value.financialInstitutionName = branch ? branch.name : '';
    directDebitDetails.value.accountNumber = accountNumber;
  }

  async function getPaymentIframeUrl(): Promise<IPaymentConfigurationResponse | null> {
    try {
      const paymentConfigurationPayload: IPaymentConfigurationRequest = {
        reference: reference.value,
        amount: amount.value,
        buttonText: buttonText.value,
        tokenizeOnly: tokenizeOnly.value,
        googlePay: googlePay.value,
      };

      const paymentConfiguration = await _paymentService.getPaymentIframeConfiguration(paymentConfigurationPayload);
      if (paymentConfiguration) {
        return paymentConfiguration;
      }
    } catch (error) {
      return null;
    }
    return null;
  }

  /**
   * Fz credit card tokenization complete
   *
   * @param payloadResponseData
   */
  function setFzTransactionState(payloadResponseData: object | null) {
    cardDetailsRef.value = payloadResponseData ? mapToCardDetails(payloadResponseData) : null;
    try {
      submitPaymentCallback.value && submitPaymentCallback.value(PaymentTypes.CreditCard);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Validate BSB
   *
   * @param bsb
   */
  async function bsbLookup(bsb: string) {
    const res = await _paymentService.bsbLookup(bsb);
    bsbBranchDetails.value = res;
    return !!res;
  }

  /**
   * Reset Fz IFrame
   */
  function resetIframe() {
    reference.value = v4();
  }

  /**
   * Increment payment attempt
   */
  function incrementPaymentAttempt() {
    paymentRetryAttempts.value = paymentRetryAttempts.value + 1;
  }

  /**
   * Increment payment attempt
   */
  function hasExceededMaxPaymentAttempts() {
    return paymentRetryAttempts.value >= maxRetries.value;
  }

  return {
    reference,
    amount,
    buttonText,
    tokenizeOnly,
    googlePay,
    selectedPaymentMethod,
    getPaymentIframeUrl,
    setFzTransactionState,
    changePaymentMethod,
    bsbLookup,
    submitPaymentCallback,
    disablePaymentPanel,
    cardDetails: readonly(cardDetailsRef),
    setDirectDebitDetails,
    directDebitDetails,
    setup,
    resetIframe,
    paymentRetryAttempts,
    maxRetries,
    incrementPaymentAttempt,
    hasExceededMaxPaymentAttempts,
    bsbBranchDetails,
  };
});
