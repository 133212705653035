import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-6" }

import { ref, onMounted, computed, watch } from 'vue';
import { IFoundVehicle } from '@/interfaces';
import useVehicleData from '@/composables/useVehicleData';
import { clone, uniqBy } from 'lodash-es';
import { IRadioButtonCard } from '@/interfaces/entities/radio-button-card.interface';
import carIcon from '@/assets/icons/car.svg';
import PanelTitle from '@/components/PanelTitle/PanelTitle.vue';

// Define Props & Emitters
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'FindYourCarList',
  props: {
    vehicleList: {},
    heading: {},
    foundVehiclesByRego: { type: Boolean },
    regoResultsStepTitle: {},
    regoResultsStepContent: {},
    makeAndModelResultsStepTitle: {},
    makeAndModelResultsStepContent: {}
  },
  emits: ['vehicleSelected'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;
const vehicleData = useVehicleData();
const vehicleDescriptionMode = ref('basic');
const uniqVehicleList = ref<IFoundVehicle[]>([]);

// Define Component state
// ------------------------------------------------------------- //
const selectedVehicle = ref<IFoundVehicle | undefined>(undefined);

const makeModelDescriptionRego = computed(
  () => (vehicle: IFoundVehicle) =>
    `${vehicle.make.description} ${vehicle.model.description} ${vehicle.registrationNumber.toUpperCase()} QLD`
);

const resultsStepTitle = computed(() => {
  return {
    value: props.foundVehiclesByRego ? props.regoResultsStepTitle : props.makeAndModelResultsStepTitle,
  };
});

const resultsStepContent = computed(() => {
  return {
    value: props.foundVehiclesByRego ? props.regoResultsStepContent : props.makeAndModelResultsStepContent,
  };
});

const rbcVehicleOptions = ref<IRadioButtonCard[]>([]);
const rbcSelectedVehicleOption = ref('');

// Define Lifycycle hooks
// ------------------------------------------------------------- //
onMounted(() => {
  uniqVehicleList.value = clone(props.vehicleList);
  vehicleDescriptionMode.value = 'basic';
  if (props.vehicleList?.length === 1) {
    rbcSelectedVehicleOption.value = props.vehicleList[0].glassesId?.toString();
  } else {
    // check if any duplicate for basic description
    const basicDescriptionsList = props.vehicleList
      .map((vehicle) => vehicleData.formattedCarSpecs(vehicle, vehicleDescriptionMode.value))
      .sort();

    const hasDuplicatedBasicDescription = basicDescriptionsList.some((description, index) => {
      if (!index) return false;
      return basicDescriptionsList[index - 1] == description;
    });

    // Uniq vehicle list
    if (hasDuplicatedBasicDescription) {
      vehicleDescriptionMode.value = 'detailed';
      uniqVehicleList.value = uniqBy(props.vehicleList, (vehicle) => {
        return vehicleData.formattedCarSpecs(vehicle, vehicleDescriptionMode.value);
      });
    }
  }

  rbcVehicleOptions.value = setRadioButtonCardOptions(uniqVehicleList.value);
});

// Watch for changes to update parent modelValue
watch(rbcSelectedVehicleOption, (newValue) => {
  const selectedVehicle = uniqVehicleList.value.find((uv) => uv.glassesId.toString() === newValue);
  selectVehicle(selectedVehicle as IFoundVehicle);
});

function setRadioButtonCardOptions(uniqueVehicles: IFoundVehicle[]): IRadioButtonCard[] {
  const rbcVehicleOptions: IRadioButtonCard[] = [];
  uniqueVehicles.forEach((uniqueVehicle) => {
    const rbcVehicleOption: IRadioButtonCard = {
      modelValue: rbcSelectedVehicleOption.value,
      value: uniqueVehicle.glassesId.toString(),
      groupName: 'vehicleOptions',
      bodyIcon: carIcon,
      leftTitle: makeModelDescriptionRego.value(uniqueVehicle),
      leftDescription: vehicleData.formattedCarSpecs(uniqueVehicle, vehicleDescriptionMode.value),
      showRightRadioButton: true,
    };
    rbcVehicleOptions.push(rbcVehicleOption);
  });
  return rbcVehicleOptions;
}

// Define Functions
// ------------------------------------------------------------- //
function selectVehicle(vehicle: IFoundVehicle) {
  selectedVehicle.value = vehicle;
  emit('vehicleSelected', vehicle);
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PanelTitle, {
      title: resultsStepTitle.value,
      subTitle: resultsStepContent.value
    }, null, 8, ["title", "subTitle"]),
    _createVNode(_component_FormKit, {
      modelValue: rbcSelectedVehicleOption.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((rbcSelectedVehicleOption).value = $event)),
      type: "radiobuttoncardgroup",
      title: props.heading,
      options: rbcVehicleOptions.value,
      initialValue: rbcSelectedVehicleOption.value,
      radioGroupClass: "car-list",
      innerClass: "input-xl !h-fit"
    }, null, 8, ["modelValue", "title", "options", "initialValue"])
  ]))
}
}

})