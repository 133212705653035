import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "benefits"
}
const _hoisted_2 = { class: "w-[100%]" }
const _hoisted_3 = { class: "w-[100%] text-base-bold" }

import BaseIcon from '@/components/BaseIcon/BaseIcon.vue';
import { QuoteTypes } from '@/constants/quote-types.const';
import { getInsuranceTypeDisplayName } from '@/utils/editable-steps/quote-content-builder';
import { useI18n } from 'vue-i18n';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MembershipBenefits',
  props: {
    membershipBenefit: {},
    showIcons: { type: Boolean },
    quoteType: {}
  },
  setup(__props: any) {

const translation = useI18n();
const dictionary: Record<string, string> = translation.getLocaleMessage('en') as any;

const props = __props;

function formatText(text: string) {
  if (!text) return text;
  return text.replaceAll('{0}', getInsuranceTypeDisplayName(dictionary, props.quoteType));
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(RichText), {
      field: _ctx.membershipBenefit?.fields.membershipBenefitHeading,
      class: "text-lg-bold pb-5"
    }, null, 8, ["field"]),
    _createVNode(_unref(RichText), {
      field: { value: formatText(_ctx.membershipBenefit?.fields.membershipBenefitSummaryText.value) },
      class: "text-base pb-2"
    }, null, 8, ["field"]),
    (_ctx.showIcons)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.membershipBenefit?.fields.benefitIcons, (benefitIcon, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "flex mb-2"
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(BaseIcon, {
                  iconClass: benefitIcon?.fields.contentIcon.value,
                  class: "icon"
                }, null, 8, ["iconClass"])
              ]),
              _createElementVNode("div", _hoisted_3, _toDisplayString(benefitIcon.name), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})