export enum PaymentTypes {
  CreditCard = 1,
  BankAccount = 2,
  PayLater = 3,
  PayPal = 4,
}

export enum PaymentMethod {
  CreditCard = 0,
  BankAccount = 1,
}
