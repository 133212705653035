import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-paragraph-color" }
const _hoisted_2 = {
  key: 0,
  class: "inline-block input-spacing"
}

import { ref, onMounted, computed } from 'vue';
import { useFormStore } from '@/store';
import { IFormStep, IPolicyHolderDetails, IQuote } from '@/interfaces';
import FormStepFooter from '@/components/FormStepFooter/FormStepFooter.vue';
import FormStepInputs from '@/components/FormStepInputs/FormStepInputs.vue';
import InformationBox from '@/components/InformationBox/InformationBox.vue';
import { InformationBoxTypes } from '@/interfaces/forms/information-box.interface';
import { AccountPreferences } from '@/enums/account-preferences.enum';
import { joinAddressComponentsForPolicyholder } from '@/mappers';


export const emitSubmitAccountPreferences = 'emitSubmitAccountPreferences';
const __default__ = {};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'AccountPreferences',
  props: {
    step: {},
    quote: {}
  },
  emits: [emitSubmitAccountPreferences],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

// Define props and emitters
// ------------------------------------------------------------- //
const props = __props;

const note = computed(() => {
  const raw = props.step.formInputs?.note?.label?.value ?? '';
  if (!raw) return { value: '' };

  const policyholderName = `${props.quote.primaryPolicyHolderDetails?.firstname?.value ?? ''} ${props.quote.primaryPolicyHolderDetails?.surname?.value ?? ''}`;

  // Get the name from the AccountPreferences enum
  const communicationPreference = AccountPreferences[formData.value.preference]?.toLowerCase() ?? '';

  const contactInfo =
    formData.value.preference === String(AccountPreferences.Email)
      ? (props.quote.primaryPolicyHolderDetails?.emailAddress?.value ?? '')
      : (joinAddressComponentsForPolicyholder(props.quote.primaryPolicyHolderDetails as IPolicyHolderDetails) ?? '');

  const updatedRaw = raw
    .replace('{{policyholder}}', policyholderName)
    .replace('{{communication}}', communicationPreference)
    .replace('{{contact}}', contactInfo);

  return { value: updatedRaw };
});

onMounted(() => {
  formData.value.preference = formData.value.preference = String(
    props.quote.primaryPolicyHolderDetails?.communicationPreference?.value ?? ''
  );
});

const formData = ref<Record<string, any>>({});
const formStore = useFormStore();

const onSubmit = async () => {
  try {
    emit(emitSubmitAccountPreferences, formData.value.preference);
  } catch (jpCode: any) {
    formStore.showLoadingForCurrentStep(false);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createBlock(_component_FormKit, {
    modelValue: formData.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData).value = $event)),
    type: "form",
    actions: false,
    incompleteMessage: false,
    onSubmit: onSubmit
  }, {
    default: _withCtx(({ state: { valid } }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(FormStepInputs, { step: _ctx.step }, null, 8, ["step"]),
        valid
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(InformationBox, {
                description: note.value.value,
                type: _unref(InformationBoxTypes).SUCCESS
              }, null, 8, ["description", "type"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(FormStepFooter, {
          step: _ctx.step,
          btnNextText: _ctx.step.btnNext,
          btnPrevText: _ctx.step.btnPrev
        }, null, 8, ["step", "btnNextText", "btnPrevText"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})