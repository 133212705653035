import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-6 py-8 px-5 bg-white form-float-box-border text-racq-dark-grey" }
const _hoisted_2 = { class: "text-xl !font-medium leading-7 mb-2" }
const _hoisted_3 = { value: true }
const _hoisted_4 = { value: false }
const _hoisted_5 = { class: "mb-6" }
const _hoisted_6 = { class: "w-full h-8 px-4 py-2 bg-racq-anti-flash-white rounded-lg justify-center items-start gap-5 inline-flex" }
const _hoisted_7 = { class: "text-center text-racq-med-grey text-xs leading-none" }

import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useIQOriginalQuoteStore } from '@/store/insurance-quote/original-quote.state';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { Item } from '@sitecore-jss/sitecore-jss/layout';
import { getDiscountsList } from '@/utils/iq-discounts';
import { hasQuotePremiumChanged } from '@/utils/display-rules/loyalty-rules';
import { IQuote } from '@/interfaces';
import DollarAmount from '@/components/DollarAmount/DollarAmount.vue';
import TagList from '@/components/TagList/TagList.vue';
import { TagTypes, TagOrientation } from '@/enums/tag-enums';

enum fieldNames {
  title = 'Title',
  annualPayment = 'Annual Payment',
  monthlyPayment = 'Monthly Payment',
  refNumber = 'Ref Number',
  quoteChangedLink = 'Quote Changed Link',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'IQFloatingBox',
  props: {
    fields: {},
    testParams: {}
  },
  setup(__props: any) {

const props = __props;

const showMonthlyPremium = ref<boolean>(true);
const premiumHasChangeLink = ref<any>();
const iqOriginalQuoteStore = useIQOriginalQuoteStore();

const referenceNumberText = computed(() => {
  return `${wordings.value.refNum.value} ${props.fields.quote?.reference}`;
});
const insuranceQuoteSummary = computed(() =>
  showMonthlyPremium.value
    ? props.fields.quote?.insuranceQuoteSummary?.monthlyDeposit
    : props.fields.quote?.insuranceQuoteSummary?.yearlyAnnualPremium
);
const { t, getLocaleMessage } = useI18n();
const discounts = computed(() =>
  getDiscountsList({ t, getLocaleMessage }, props.fields.quote, { showAsMonthly: showMonthlyPremium.value })
);
const wordings = computed(() => ({
  title: getFieldByName(fieldNames.title)?.content,
  annual: getFieldByName(fieldNames.annualPayment)?.content,
  monthly: getFieldByName(fieldNames.monthlyPayment)?.content,
  refNum: getFieldByName(fieldNames.refNumber)?.content,
}));

const quoteHasChanged = computed(() => {
  if (!iqOriginalQuoteStore.quote) return false;
  return hasQuotePremiumChanged(props.fields.quote, iqOriginalQuoteStore.quote);
});

onMounted(async () => {
  premiumHasChangeLink.value = getFieldByName(fieldNames.quoteChangedLink)?.content;
});

const getFieldByName = (fieldName: fieldNames) => {
  if (!props.fields.floatingBoxContext?.fields) return;
  const fields = props.fields.floatingBoxContext.fields ? props.fields.floatingBoxContext.fields : [];
  const field = (fields as any[]).find((x: any) => x.name === fieldName);
  return field;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, [
      _createVNode(_unref(Text), {
        field: wordings.value.title
      }, null, 8, ["field"])
    ]),
    _createVNode(DollarAmount, {
      showStarSign: false,
      amount: insuranceQuoteSummary.value ?? ''
    }, null, 8, ["amount"]),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showMonthlyPremium).value = $event)),
      class: "text-base !font-medium mb-6"
    }, [
      _createElementVNode("option", _hoisted_3, [
        _createVNode(_unref(Text), {
          field: wordings.value.monthly
        }, null, 8, ["field"])
      ]),
      _createElementVNode("option", _hoisted_4, [
        _createVNode(_unref(Text), {
          field: wordings.value.annual
        }, null, 8, ["field"])
      ])
    ], 512), [
      [_vModelSelect, showMonthlyPremium.value]
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(TagList, {
        tags: discounts.value,
        tagType: _unref(TagTypes).discount,
        tagOrientation: _unref(TagOrientation).vertical,
        innerClass: "w-fit"
      }, null, 8, ["tags", "tagType", "tagOrientation"])
    ]),
    (quoteHasChanged.value || props.testParams?.enableQuoteHasChanged)
      ? (_openBlock(), _createBlock(_unref(RichText), {
          key: 0,
          class: "flex flex-col space-y-3 text-m mb-6",
          field: { value: premiumHasChangeLink.value?.value }
        }, null, 8, ["field"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(referenceNumberText.value), 1)
    ])
  ]))
}
}

})