import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex-col justify-start items-end gap-8 md:gap-6 inline-flex ms-auto" }

import { computed } from 'vue';
import { IFormStep } from '@/interfaces';
import { useFormStore } from '@/store';

// Define props and emitters
// ------------------------------------------------------------- //


const editButtonClicked = 'editButtonClicked';
const __default__ = {}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'FormStepFooter',
  props: {
    step: {},
    isSticky: { type: Boolean },
    btnNextText: {},
    btnPrevText: {},
    btnDisagreeText: {},
    btnNextDisabled: { type: Boolean },
    btnNextClick: { type: Function },
    btnPrevClick: { type: Function },
    btnDisagreeClick: { type: Function }
  },
  emits: [editButtonClicked],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

// Initialise stores
// ------------------------------------------------------------- //
const formStore = useFormStore();

// Define Computed properties
// ------------------------------------------------------------- //
const isPrelimStep = computed(() => props.step.isPrelimStep);

const showEditBtn = computed(
  () =>
    props.step.isMasked &&
    props.step.stepNumber &&
    props.step.stepNumber <= (formStore.currentStep?.stepNumber || props.step.stepNumber)
);

// Functions
// ------------------------------------------------------------- //
/**
 * On click of 'Back' button
 *
 */
function onPrev() {
  if (isPrelimStep.value) {
    return formStore.goPrevPrelimStep();
  }

  formStore.goPrevStep();
}

/**
 * On click of the 'Edit' button of a form
 *
 * @param stepIndex
 */
function onEdit(stepIndex: number) {
  formStore.goToStep(stepIndex);
  emit(editButtonClicked);
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['form-buttons', { 'sticky-footer': _ctx.isSticky }])
  }, [
    (_ctx.btnPrevText && !_ctx.step.isMasked)
      ? (_openBlock(), _createBlock(_component_FormKit, {
          key: 0,
          type: "button",
          label: _ctx.btnPrevText,
          inputClass: "btn btn-secondary",
          disabled: _unref(formStore).disableAllSteps,
          onClick: _cache[0] || (_cache[0] = () => (_ctx.btnPrevClick ? _ctx.btnPrevClick() : onPrev()))
        }, null, 8, ["label", "disabled"]))
      : _createCommentVNode("", true),
    (_ctx.btnNextText && _ctx.step.isActive && !_ctx.step.isMasked)
      ? _renderSlot(_ctx.$slots, "btnNext", { key: 1 }, () => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_FormKit, {
              type: "submit",
              label: _ctx.btnNextText,
              disabled: _ctx.btnNextDisabled || _unref(formStore).disableAllSteps,
              inputClass: "btn btn-primary",
              onClick: _ctx.btnNextClick
            }, null, 8, ["label", "disabled", "onClick"]),
            (_ctx.btnDisagreeText && _ctx.btnDisagreeClick)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "link text-base-bold",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.btnDisagreeClick && _ctx.btnDisagreeClick(...args)))
                }, _toDisplayString(_ctx.btnDisagreeText), 1))
              : _createCommentVNode("", true)
          ])
        ])
      : _createCommentVNode("", true),
    (showEditBtn.value)
      ? (_openBlock(), _createBlock(_component_FormKit, {
          key: 2,
          type: "button",
          label: "Edit",
          disabled: _unref(formStore).disableAllSteps,
          inputClass: "btn btn-primary btn-edit",
          outerClass: "ms-auto z-[200]",
          onClick: _cache[2] || (_cache[2] = () => onEdit(_ctx.step.stepIndex!))
        }, null, 8, ["disabled"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})