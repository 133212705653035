<template>
  <div
    v-if="decisionOffer?.propositions != null && decisionOffer?.propositions.length"
    class="flex flex-col w-full justify-between gap-6 mt-9 mb-10"
  >
    <h3 class="text-xl text-base-bold">
      {{ DecisionIntelligenceStore.contentFields?.crossSellSubHeading.value }}
    </h3>
    <div
      v-for="(proposition, index) in decisionOffer.propositions"
      :key="index"
      :class="proposition.incentivised ? 'bg-racq-sky-blue rounded border-2 border-solid border-racq-white' : ''"
    >
      <div class="flex p-2 gap-1 items-center">
        <div>
          <img :src="iconMap.startIcon" alt="star" />
        </div>
        <RichText
          v-if="proposition.incentivised"
          class="text-xs text-base-bold text-racq-true-white"
          :field="DecisionIntelligenceStore.contentFields?.exclusiveHeading"
        />
      </div>
      <div class="bg-white">
        <div
          :class="
            proposition.incentivised
              ? 'p-8 shadow-md sm:flex-col md:flex-row'
              : 'p-8 rounded border border-solid border-racq-white shadow-md sm:flex-col md:flex-row'
          "
        >
          <div class="flex flex-col gap-8">
            <div class="flex flex-row gap-4">
              <div>
                <img :src="getFilterStep(proposition).imageUrl" alt="offer image" width="40" />
              </div>
              <h3 class="text-xl-bold">
                <RichText class="-right-3" :field="{ value: getFilterStep(proposition).headerText }" />
              </h3>
            </div>

            <ul class="text-racq-med-grey p-0 ms-4 list-disc">
              <li v-for="(benefit, key) in getFilterStep(proposition).crossSellOfferContent.benefitList" :key>
                <RichText :field="{ value: benefit.benefitText }" />
              </li>
            </ul>
            <div v-if="proposition.incentivised" class="border rounded p-5 text-base-bold text-lg bg-racq-pale-blue">
              <RichText :field="{ value: getFilterStep(proposition).crossSellOfferContent.incentiveMessageText }" />
            </div>
            <div class="flex sm:flex-col md:flex-row">
              <!--Repeater-->

              <button
                v-for="(action, key) in getFilterStep(proposition).crossSellOfferContent.actionList"
                :key
                type="button"
                :class="
                  key > 0
                    ? 'btn btn-secondary btn-no-caret mr-5 sm:mb-5 md:w-2/6 sm:w-full font-sans'
                    : 'btn btn-primary btn-no-caret mr-5 sm:mb-5 md:w-2/6 sm:w-full font-sans'
                "
                @click="
                  (e) => {
                    e.preventDefault();
                    executeAction(decisionOffer?.id ? decisionOffer?.id : '', action, proposition, index);
                  }
                "
              >
                {{ action.actionText }}
              </button>
            </div>
            <div v-if="false" class="text-racq-red my-2">
              Your session has timed out due to inactivity. You can now close the window.
            </div>
            <div v-if="showLeadForm[index]" class="mt-5">
              <DecisionIntelligenceLeadForm
                :proposition="proposition"
                :decisionId="decisionOffer.id"
                :quote="props.quote"
                :currentAction="currentAction"
                :isEmail="isEmail"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import {
  IAction,
  IDecisionIntelligenceOfferResponse,
  IProposition,
  IStep,
} from '@/interfaces/decision-intelligence/decision-intelligence-response';
import { useDecisionIntelligenceStore } from '@/store/decision-intelligence/decision-intelligence.state';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { IQuote } from '@/interfaces';
import { Outcome } from '@/interfaces/decision-intelligence/decision-intelligence-request';
import DecisionIntelligenceLeadForm from '@/components/DecisionIntelligenceLeadForm/DecisionIntelligenceLeadForm.vue';
import { mapDecisionOfferAcknowledge } from '@/mappers/decision-intelligence-mapper';
import { IPaymentReferenceDetails } from '@/interfaces/make-a-payment/make-a-payment.interface';

// Define props and emitters
// ------------------------------------------------------------- //
const props = defineProps<{
  decisionOffer?: IDecisionIntelligenceOfferResponse;
  stepTitle?: string;
  quote?: Partial<IQuote>;
  paymentReferenceDetails?: Partial<IPaymentReferenceDetails>;
}>();

// Lifecycle hooks
// ------------------------------------------------------------- //
onMounted(async () => {
  if (props.quote || props.paymentReferenceDetails) {
    await DecisionIntelligenceStore.acknowlegeDecisionOffer(
      props.decisionOffer?.id ?? '',
      mapDecisionOfferAcknowledge(Outcome.Surfaced, props.quote, props.paymentReferenceDetails)
    );
  }
});

// Define Component state
// ------------------------------------------------------------- //
const showLeadForm = ref([false, false]);
const isEmail = ref(false);
const currentAction = ref({} as IAction);
const DecisionIntelligenceStore = useDecisionIntelligenceStore();

const iconMap = {
  startIcon: require('@/assets/img/icons/star-icon.svg'),
};

// Define Functions
// ------------------------------------------------------------- //
const getFilterStep = (proposition: IProposition): IStep => {
  return proposition.displaySteps.filter((x) => x.stepName == props.stepTitle)[0];
};
const executeAction = (decisionId: string, action: IAction, proposition: IProposition, key: number) => {
  currentAction.value = action;
  if (action.actionType == 'Contact') {
    showLeadForm.value[key] = true;
    isEmail.value = false;
  }
  if (action.actionType == 'Email') {
    showLeadForm.value[key] = true;
    isEmail.value = true;
  }
  if (action.actionType == 'DigitalForm' || action.actionType == 'WebLink') {
    DecisionIntelligenceStore.acknowlegeDecisionOffer(
      decisionId,
      mapDecisionOfferAcknowledge(Outcome.Redirect, props.quote, props.paymentReferenceDetails, proposition, action)
    );
    const actionUrl =
      action.actionType == 'DigitalForm'
        ? `?crosssellquotereference=${props.quote ? props.quote.reference : props.paymentReferenceDetails?.prn}&propositionId${proposition.id}`
        : '';
    const linkUrl = `${action.linkUrl}${actionUrl}`;
    window.open(linkUrl, '_blank');
  }
};
</script>
