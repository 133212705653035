import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex rounded h-full" }
const _hoisted_2 = { class: "flex items-center justify-between" }
const _hoisted_3 = { class: "flex items-center w-[72px] object-cover self-stretch" }
const _hoisted_4 = { class: "flex gap-3 w-full items-center text-racq-dark-grey pl-3 min-[370px]:px-3" }
const _hoisted_5 = { class: "flex flex-col justify-center" }
const _hoisted_6 = { class: "text-base-bold text-left" }
const _hoisted_7 = { class: "text-sm text-left" }

import { Image } from '@sitecore-jss/sitecore-jss-vue';
import { ImageField } from '@sitecore-jss/sitecore-jss-vue/types/components/Image';


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioButtonCardListItem',
  props: {
    label: {},
    help: {},
    image: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_unref(Image), { media: _ctx.image }, null, 8, ["media"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.label), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.help), 1)
      ])
    ])
  ]))
}
}

})