<!-- RadioButtonCardGroup.vue -->
<template>
  <div class="flex flex-col">
    <div v-if="context.showValidationError" class="text-racq-error-red text-sm font-semibold pb-6">
      {{ context.validationMessage }}
    </div>
    <div class="radio-button-card-group flex flex-col flex-wrap gap-3" :class="context.radioGroupClass">
      <RadioButtonCard
        v-for="(option, index) in context.options"
        :key="option.value + index.toString()"
        v-model="selectedOption"
        :groupName="option.groupName"
        :callOutTag="option.callOutTag"
        :bodyImage="option.bodyImage"
        :bodyImageAlt="option.bodyImageAlt"
        :bodyIcon="option.bodyIcon"
        :bodyIconAlt="option.bodyIconAlt"
        :value="option.value"
        :leftTitle="option.leftTitle"
        :leftTitleClass="context.leftTitleClass"
        :leftDescription="option.leftDescription"
        :showLeftRadioButton="option.showLeftRadioButton"
        :rightTitle="option.rightTitle"
        :rightDescription="option.rightDescription"
        :showRightRadioButton="option.showRightRadioButton"
        :visibleBodyDescription="option.visibleBodyDescription"
        :hiddenBodyDescription="option.hiddenBodyDescription"
        :showMoreLinkLabel="option.showMoreLinkLabel"
        :showLessLinkLabel="option.showLessLinkLabel"
        :bindings="context.bindings"
        :innerClass="option.innerClass"
        :outerClass="option.outerClass"
        :bodyTopClass="option.bodyTopClass"
        :bodyBottomClass="option.bodyBottomClass"
        :bottomTagText="option.bottomTagText"
        :showBottomTag="option.showBottomTag"
      >
        <template #bottomContent>
          <Tag :tagType="TagTypes.discount" :tagText="option.bottomTagText" />
        </template>
      </RadioButtonCard>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import RadioButtonCard from './RadioButtonCard.vue'; // Import the RadioButtonCard component
import { IRadioButtonCard } from '@/interfaces/entities/radio-button-card.interface';
import Tag from '../Tag/Tag.vue';
import { TagTypes } from '@/enums/tag-enums';

interface RadioButtonCardGroupProps {
  options?: Array<IRadioButtonCard>;
  initialValue?: string | null;
  validationMessage?: string;
  radioGroupClass?: string;
  showValidationError?: boolean;
  leftTitleClass?: string;
  node?: any;
  bindings?: any;
  value?: any;
}

const props = defineProps<{
  context: RadioButtonCardGroupProps;
  modelValue?: any;
}>();
const emit = defineEmits(['update:modelValue']);
const selectedOption = ref(props.context?.initialValue || null);

// Watch for changes to update parent modelValue
watch(selectedOption, (newValue) => {
  props.context?.node?.input(newValue);
  emit('update:modelValue', newValue);
});

watch(
  [() => props.context?.initialValue, () => props.context?.value],
  ([newOption1, newOption2]) => {
    if (newOption1) {
      selectedOption.value = newOption1;
    }
    if (newOption2) {
      selectedOption.value = newOption2;
    }
  },
  { immediate: true, deep: true }
);
</script>
