import { IBSBBranchDetails } from './../../interfaces/entities/bsb-branch-details.interface';
import type { IPaymentConfigurationRequest } from '@/interfaces/entities/payment-configuration-request.interface';
import type { IPaymentConfigurationResponse } from '@/interfaces/entities/payment-configuration-response.interface';
import { RequestServices as _http } from '../common/request.services';
import { getFinancialInstitutionFromBsb, getPaymentConfiguration } from '@/constants/forms-api.const';

export const PaymentServices = {
  /**
   * Get FZ Iframe URL
   *
   * @param payload
   * @returns
   */
  async getPaymentIframeConfiguration(payload: IPaymentConfigurationRequest): Promise<IPaymentConfigurationResponse> {
    if (!payload.reference || !payload.amount) return { url: '', hostUrl: '' };
    return await _http.HttpGet(
      `${getPaymentConfiguration}?reference=${payload.reference}&amount=${payload.amount}&buttonText=${payload.buttonText}&tokenizeOnly=${payload.tokenizeOnly}&googlepay=${payload.googlePay}`
    );
  },

  /**
   * BSB Lookup
   *
   * @param bsb
   */
  async bsbLookup(bsb: string): Promise<IBSBBranchDetails | null> {
    return await _http.HttpGet(getFinancialInstitutionFromBsb.replace('{0}', bsb));
  },
} as const;
