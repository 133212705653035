import axios, { AxiosError } from 'axios';
import config from '@/temp/config';
import { isProduction } from '@/constants/forms-api.const';
import { parseJSON } from 'date-fns';
import { JP_LOGGEDOUT } from '@/constants/jeopardy-codes.const';

/** Sitecore Local JSS Key
 *  TODO: load from env var or inject at build time.
 */
const scApiKey = '{074B3CC1-DAB1-4092-B4EB-CE8420F3AEAF}';

const dateRegexp = /\d{4}-\d{2}-\d{2}T.*\d{2}:\d{2}/;

const notAuthorizedError = {
  errors: [
    {
      message: JP_LOGGEDOUT,
    },
  ],
};

const doesValueLookLikeADateString = (propValue: any) =>
  propValue && typeof propValue === 'string' && dateRegexp.test(propValue);

const transformResponse = (data: any) => {
  //to handle empty response
  if (data) {
    return JSON.parse(data, (key, value) => {
      const isValuePotentialDate = doesValueLookLikeADateString(value);
      if (isValuePotentialDate) {
        const attemptedParsedValue = parseJSON(value);
        if (attemptedParsedValue && !Number.isNaN(attemptedParsedValue.getTime())) {
          return attemptedParsedValue;
        } else {
          return value;
        }
      }
      return value;
    });
  }
  return data;
};

const handleException = (res: AxiosError) => {
  if (res.status == 401) {
    return { data: notAuthorizedError };
  }
  throw res;
};

export const RequestServices = {
  /**
   * Send get request
   *
   * @param endpoint
   * @param params
   * @returns
   */
  async HttpGet(endpoint: string, params: any = {}): Promise<any> {
    params.transformResponse = transformResponse;
    params.params = {};
    params.params.sc_apikey = isProduction ? config.sitecoreApiKey : scApiKey;
    if (!isProduction) params.withCredentials = true;
    const response = await axios.get(`${endpoint}`, params).catch(handleException);
    return response.data;
  },

  /**
   * Send post request
   *
   * @param endpoint
   * @param body
   * @param params
   * @returns
   */
  async HttpPost(endpoint: string, body: any = {}, params: any = {}): Promise<any> {
    try {
      params.transformResponse = transformResponse;
      params.params = {};
      params.params.sc_apikey = isProduction ? config.sitecoreApiKey : scApiKey;
      if (!isProduction) params.withCredentials = true;
      const response = await axios.post(`${endpoint}`, body, params).catch(handleException);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  /**
   * Send post request
   *
   * @param endpoint
   * @param body
   * @param params
   * @returns
   */
  async HttpPut(endpoint: string, body: any = {}, params: any = {}) {
    try {
      params.transformResponse = transformResponse;
      params.params = {};
      params.params.sc_apikey = isProduction ? config.sitecoreApiKey : scApiKey;
      if (!isProduction) params.withCredentials = true;
      const response = await axios.put(`${endpoint}`, body, params).catch(handleException);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
