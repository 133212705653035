import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { computed } from 'vue';
import { parse, isValid, addDays, compareAsc, isSameDay } from 'date-fns';
import { flatMap } from 'lodash-es';


export default /*@__PURE__*/_defineComponent({
  __name: 'DateInput',
  props: /*@__PURE__*/_mergeModels({
    bindings: {},
    maxDateByDays: {},
    minDateToday: { type: Boolean },
    minDate: {}
  }, {
    "modelValue": { required: false, default: '' },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const dateValue = _useModel<string>(__props, "modelValue");

const props = __props;

const validation = computed(() => {
  if (flatMap(props.bindings.validation || []).includes('dateValidator')) return props.bindings.validation;
  return [...(props.bindings.validation || []), [dateValidator]];
});

function onInput(target: any) {
  if (!target?.value || typeof target.value != 'string') return;
  target.value = target.value.replace(/[^0-9/]/g, '');

  // e.g 4 => 04/
  if (target.value.length == 1 && Number(target.value) > 3) {
    target.value = `0${target.value}/`;
  }
  // e.g 2/ => 02/
  if (target.value.length == 2 && target.value.includes('/')) {
    target.value = '0' + target.value;
  }
  // e.g 020 => 02/0
  if (target.value.length == 3 && !target.value.includes('/')) {
    target.value = target.value.slice(0, 2) + '/' + target.value[2];
  }
  // e.g 02/4 => 02/04/
  if (target.value.length == 4) {
    const month = Number(target.value.split('/')[1]);
    if (month > 1) target.value = target.value.split('/')[0] + '/' + `0${month}/`;
  }
  // e.g 02/1/ => 02/01/
  if (target.value.length == 5 && target.value.split('/').length == 3) {
    target.value = target.value.slice(0, 3) + '0' + target.value.slice(3, 5);
  }
  // e.g 02/032 => 02/03/2
  if (target.value.length == 6 && target.value.split('/').length != 3) {
    target.value = target.value.slice(0, 5) + '/' + target.value[5];
  }
}

// Pass to native
(window as any).RACQLintDateInput = onInput;

function dateValidator(node: any) {
  try {
    const date = parse(node.value, 'dd/MM/yyyy', new Date());

    if (!isValid(date) || node.value.length != 10) return false;

    // Validate for min date
    if (!validateMinDate(date)) return false;

    // Validate for max date
    if (!validateMaxDate(date)) return false;

    return true;
  } catch {
    return false;
  }
}

function validateMinDate(date: Date) {
  if (props.minDateToday) {
    const compareResult = compareAsc(date, new Date());
    if (!isSameDay(date, new Date()) && compareResult == -1) return false;
  }
  if (props.minDate) {
    const compareResult = compareAsc(date, new Date(props.minDate));
    if (compareResult == -1) return false;
  }
  return true;
}

function validateMaxDate(date: Date) {
  if (typeof props.maxDateByDays == 'number') {
    const maxDate = addDays(new Date(), props.maxDateByDays);
    const compareResult = compareAsc(date, maxDate);
    if (compareResult == 1) return false;
  }
  return true;
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.bindings.id + '__outer',
    class: _normalizeClass([_ctx.bindings.outerClass, "md:width-sm w-full"])
  }, [
    _createVNode(_component_FormKit, _mergeProps(_ctx.bindings, {
      modelValue: dateValue.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dateValue).value = $event)),
      type: "text",
      innerClass: _ctx.bindings.innerClass || 'input-sm',
      validation: validation.value,
      oninput: "window.RACQLintDateInput(this)",
      maxlength: "10",
      validationRules: { dateValidator: dateValidator }
    }), null, 16, ["modelValue", "innerClass", "validation", "validationRules"])
  ], 10, _hoisted_1))
}
}

})