<template>
  <div v-if="store" class="flex flex-col gap-8 md:gap-6">
    <p v-if="store.showTitle()" data-testid="addressComponentTitle" class="text-input-title">
      {{ props.addressTitle.value }}
    </p>

    <OverseasOption
      v-if="store.config?.allowOverseasAddress"
      :defaultIsOverseasOption="defaultIsOverseasOption"
      :instanceIdentifier="identifier"
      :overseasOptionLabel="overseasOptionLabel?.value"
      :overseasOptionTooltip="overseasOptionTooltip?.value"
    />

    <AddressLookup
      :bindings="addressLookupAutoProps"
      :defaultIsOverseasOption="defaultIsOverseasOption"
      :streetNoRequired="streetNoRequired?.value"
      :streetRequired="streetRequired?.value"
      :suburbRequired="suburbRequired?.value"
      :postcodeRequired="postcodeRequired?.value"
      :countryRequired="countryRequired?.value"
      :checkAddressRequired="checkAddressRequired?.value"
      :selectSuburbRequired="selectSuburbRequired?.value"
      :stateRequired="stateRequired?.value"
      :postcodeInvalid="postcodeInvalid?.value"
      :postalSameAsInsuredAddressLabel="postalSameAsInsuredAddressLabel?.value"
      :invalidInsuranceRiskSuburbMessage="invalidInsuranceRiskSuburbMessage?.value"
      :instanceIdentifier="identifier"
      @addressSelected="(v: RACQAddressResult) => onAddressSelected(v)"
    />

    <ManualAddressForm
      :name="name"
      :streetNoRequired="streetNoRequired?.value"
      :streetRequired="streetRequired?.value"
      :suburbRequired="suburbRequired?.value"
      :postcodeRequired="postcodeRequired?.value"
      :countryRequired="countryRequired?.value"
      :checkAddressRequired="checkAddressRequired?.value"
      :selectSuburbRequired="selectSuburbRequired?.value"
      :stateRequired="stateRequired?.value"
      :postcodeInvalid="postcodeInvalid?.value"
      :invalidInsuranceRiskSuburbMessage="invalidInsuranceRiskSuburbMessage?.value"
      :riskSuburbsLabel="riskSuburbsLabel?.value"
      :instanceIdentifier="identifier"
      @riskSuburbSelected="(v: RACQAddressResult) => onRiskSuburbSelected(v)"
    />

    <FormKit v-model="activeAddressComponent" type="hidden" :name="formInputId.value" />
  </div>
</template>
<script setup lang="ts">
import { watch, ref, onMounted, defineExpose, computed } from 'vue';
import OverseasOption from './OverseasOption.vue';
import AddressLookup from './AddressLookup.vue';
import ManualAddressForm from './ManualAddressForm.vue';
import { ISitecoreFormGenericInput, RACQAddressResult } from '@/interfaces';
import {
  addressLookupEmits,
  emitRiskSuburbSelected,
  emitAddressSelected,
  AddressComponentOptions,
  useMultiInstanceAddressOptionsStore,
} from '@/store';
import { convertSitecoreFormFieldToVueFormField } from '@/composables/useFormInputData';
import { Field } from '@sitecore-jss/sitecore-jss-vue';
import { v4 } from 'uuid';

interface AddressLookupProps extends ISitecoreFormGenericInput {
  // CMS Fields
  formInputId: Field<string>;
  innerClass?: Field<string>;
  streetNoRequired: Field<string>;
  streetRequired: Field<string>;
  suburbRequired: Field<string>;
  stateRequired: Field<string>;
  postcodeRequired: Field<string>;
  countryRequired: Field<string>;
  checkAddressRequired: Field<string>;
  selectSuburbRequired: Field<string>;
  postcodeInvalid: Field<string>;
  invalidInsuranceRiskSuburbMessage: Field<string>;
  postalSameAsInsuredAddressLabel?: Field<string>;
  addressTitle: Field<string>;
  overseasOptionLabel: Field<string>;
  riskSuburbsLabel: Field<string>;
  overseasOptionTooltip: Field<string>;
  postContent: Field<string>;

  // Props
  config?: AddressComponentOptions;
  initialPostalAddress?: RACQAddressResult;
  initialRiskAddress?: RACQAddressResult;
  labelClass?: string;
  isOversea?: boolean;
  defaultIsOverseasOption?: boolean;
  showManualEntries?: boolean;
  instanceIdentifier?: string;
}

const emit = defineEmits(addressLookupEmits);

const props = defineProps<AddressLookupProps>();

const addressLookupAutoProps = convertSitecoreFormFieldToVueFormField(props as any, props.formInputId.value);
const multiInstanceAddressStore = useMultiInstanceAddressOptionsStore();
const activeAddressComponent = ref<Partial<RACQAddressResult>>({});
const identifier = ref(props.instanceIdentifier || v4());
const store = computed(() =>
  multiInstanceAddressStore.itemIds.includes(identifier.value)
    ? multiInstanceAddressStore.getInstance(identifier.value)
    : undefined
);

onMounted(() => {
  if (!store.value) multiInstanceAddressStore.addInstance(identifier.value);
  if (props.config)
    store.value?.setupAddressComponent(props.config, props.initialRiskAddress, props.initialPostalAddress);
});

defineExpose({
  getStore: () => store.value,
});

/*
Decision maker as to whether auto or manual address is used.
*/
watch(
  () => store.value?.useAddress,
  async (newValue) => {
    const newAddress = newValue as RACQAddressResult;
    if (!store.value) return;
    if (store.value.isValidAddress(newAddress)) {
      if (!newValue?.dpid) {
        store.value.setManualAddress(newAddress);
        //When manual address set the address line to 'Not in list...'
        store.value.autoAddressComponents.addressLine = props?.postContent?.value;
      } else {
        store.value.setAutoAddress(newAddress);
      }
    } else {
      store.value.isOverseasSelection = props.defaultIsOverseasOption;
    }
  },
  { once: true }
);

function onRiskSuburbSelected(value: Partial<RACQAddressResult>) {
  activeAddressComponent.value = value;
  emit(emitRiskSuburbSelected, value);
}

function onAddressSelected(value: Partial<RACQAddressResult>) {
  activeAddressComponent.value = value;
  emit(emitAddressSelected, value);
}
</script>
