<template>
  <div>
    <!-- Use v-show here so it keeps the values for make and model -->
    <div v-show="!showFoundVehicles" class="form-vehicle-search-container flex flex-col gap-6 pb-6">
      <PanelTitle :icon="context.icon" :title="context.title" :subTitle="context.subTitle" />

      <!-- Use v-if here to bypass rego validation on submit step -->
      <FindCarByRego
        v-if="!showFoundVehicles"
        :findCarBtn="context.findCarBtn.value"
        :regoInputLabel="context.regoInputLabel.value"
        :help="context.isRoadside?.value ? context.roadsideRegoHelpText?.value : ''"
        :regoNumber="vehicleRegistration"
        @vehicleFound="
          (data: any) => {
            updateFoundVehicleList(data ? [data] : [], true);
            showFoundVehicleList();
            setQldRegoFound(true);
            isExpanded = false; // Hide make and model if vehicle found by rego
          }
        "
        @qldVehicleNotFound="setQldRegoFound(false)"
        @setRegistration="(data: string) => setRegistration(data)"
      />

      <button
        v-if="showExpandButton"
        type="button"
        class="toggle-button flex items-center bg-none border-[none] text-base-bold text-racq-blue w-fit"
        @click="toggleMakeAndModelSection"
      >
        {{ context.expandMakeAndModelLabel?.value }}
        <img
          :src="chevronDown"
          alt="chevron icon"
          :class="['w-[12px] h-[7px] ml-2 [transition:transform_0.5s_ease]', { '-scale-y-100': showMakeAndModel }]"
        />
      </button>

      <div v-if="showMakeAndModel">
        <RichText v-if="showExpandButton" :field="context.makeAndModelTitleMessage" />
        <FormKit type="form" @submit="showFoundVehicleList" #default="{ state: { valid } }">
          <FindYourCar
            ref="findYourCarEl"
            :topMakes="context.topMakes.value"
            :colourLabel="context.colourLabel.value"
            :yearLabel="context.yearLabel.value"
            :makeLabel="context.makeLabel.value"
            :modelLabel="context.modelLabel.value"
            :seriesLabel="context.seriesLabel.value"
            :bodyLabel="context.bodyLabel.value"
            :showColour="context.showColour.value"
            @modelMakeChanged="findCarStore.resetFoundVehicleList"
            @vehicleFound="(data: any) => updateFoundVehicleList(data)"
            @setColour="(data: string) => setVehicleColour(data)"
          />

          <div class="h-12 mt-6 md:h-min flex items-start">
            <FormKit
              :label="context.findCarBtn.value"
              type="submit"
              :disabled="!valid || foundVehiclesByRego || !foundVehicleList.length"
              :inputClass="`btn btn-primary md:text-left`"
            />
          </div>
        </FormKit>
      </div>
    </div>

    <div v-if="showFoundVehicles">
      <FindYourCarList
        :regoResultsStepTitle="context.regoResultsStepTitle.value"
        :regoResultsStepContent="context.regoResultsStepContent.value"
        :makeAndModelResultsStepTitle="context.makeAndModelResultsStepTitle.value"
        :makeAndModelResultsStepContent="context.makeAndModelResultsStepContent.value"
        :vehicleList="foundVehicleList"
        :foundVehiclesByRego="foundVehiclesByRego"
        @vehicleSelected="selectVehicle"
      />
      <RichText v-if="!qldRegoFound && showFoundVehicles" class="my-6" :field="context.regoNotFoundMessage" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import FindCarByRego from '@/components/FindCar/FindCarByRego.vue';
import FindYourCarList from '@/components/FindCar/FindYourCarList.vue';
import FindYourCar from '@/components/FindCar/FindYourCar.vue';
import { useAnalyticsStore, useJeopardyStore, useFindCarStore } from '@/store';
import type { IFoundVehicle } from '@/interfaces';
import chevronDown from '@/assets/icons/blue-chevron-down.svg';
import { storeToRefs } from 'pinia';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { ImageFieldValue } from '@sitecore-jss/sitecore-jss-vue/types/components/Image';
import PanelTitle from '@/components/PanelTitle/PanelTitle.vue';
import { FormEvents } from '@/enums/form-events.enum';

interface IFindCarProps {
  node?: any;
  value?: any;
  topMakes: { value: string };
  regoResultsStepTitle: { value: string };
  regoResultsStepContent: { value: string };
  makeAndModelResultsStepTitle: { value: string };
  makeAndModelResultsStepContent: { value: string };
  findCarBtn: { value: string };
  regoNotFoundMessage: { value: string };
  yearLabel: { value: string };
  makeLabel: { value: string };
  modelLabel: { value: string };
  seriesLabel: { value: string };
  bodyLabel: { value: string };
  colourLabel: { value: string };
  regoInputLabel: { value: string };
  showColour: { value: boolean };
  makeAndModelTitleMessage: { value: string };
  expandMakeAndModelLabel: { value: string };
  icon: Field<ImageFieldValue>;
  title: { value: string };
  subTitle: { value: string };
  roadsideRegoHelpText: { value: string };
  isRoadside: { value: boolean };
  formName: { value: string };
}

// Define Props
// ------------------------------------------------------------- //
const props = defineProps<{ context: IFindCarProps }>();

// Define Composables
// ------------------------------------------------------------- //
const analyticsStore = useAnalyticsStore();
const jpStore = useJeopardyStore();
const findCarStore = useFindCarStore();

// Define Component state
// ------------------------------------------------------------- //
const findYourCarEl = ref<typeof FindYourCar | undefined>(undefined);
const qldRegoFound = computed(() => findCarStore.isQldRegoFound);
const foundVehiclesByRego = ref(true);
const isExpanded = ref(false);

const vehicleRegistration = computed(() => findCarStore.registrationNumber);
const showMakeAndModel = computed(() => !qldRegoFound.value || isExpanded.value);
const showFoundVehicles = computed(() => findCarStore.showFoundVehicles);
const showExpandButton = computed(
  () => props.context.expandMakeAndModelLabel?.value && !props.context.isRoadside.value && qldRegoFound.value
);

const { selectedVehicle, foundVehicleList } = storeToRefs(findCarStore);

findCarStore.setFormName(props.context.formName.value);

// Define functions
// ------------------------------------------------------------- //
function setQldRegoFound(isFound: boolean) {
  findCarStore.isQldRegoFound = isFound;
}

function setRegistration(registrationNumber: string) {
  findCarStore.registrationNumber = registrationNumber;
}

function setVehicleColour(vehicleColour: string) {
  findCarStore.vehicleColour = vehicleColour;
}

function updateFoundVehicleList(data: IFoundVehicle[], byRego?: boolean) {
  selectedVehicle.value = undefined;
  foundVehicleList.value = data;
  foundVehiclesByRego.value = byRego || false;
}

const toggleMakeAndModelSection = (event: Event) => {
  event.preventDefault();
  if (!qldRegoFound.value) {
    isExpanded.value = true;
    return;
  }
  isExpanded.value = !isExpanded.value;
};

function showFoundVehicleList() {
  if (foundVehicleList.value.length && foundVehicleList.value[0].manufacturingYear >= 1960) {
    findCarStore.showCarList(true);

    /** Push event for analysis */
    if (foundVehicleList.value.length) {
      analyticsStore.pushAnalytics({
        event: FormEvents.STEP,
        form: {
          field: foundVehiclesByRego.value ? 'Found car registration' : 'Found car make and model',
          stepName: 'Find car confirmation',
          name: props.context.formName.value,
        },
      });
    }

    return;
  }

  jpStore.showJeopardyPanel({
    jpCode: findCarStore.pre1960VehicleJeopardyCode,
  });
}

function selectVehicle(vehicle: IFoundVehicle) {
  findCarStore.selectedVehicle = vehicle;
}
</script>
