import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex gap-4 md:flex-row sm:flex-col text-dark-grey-text"
}
const _hoisted_2 = { class: "flex gap-12 sm:gap-6" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = { class: "text-sm py-0 mb-0" }
const _hoisted_5 = ["src"]

import {
  IDecisionIntelligenceOfferResponse,
  IProposition,
  IStep,
} from '@/interfaces/decision-intelligence/decision-intelligence-response';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

// Define props and emitters
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'DecisionIntelligenceOffers',
  props: {
    decisionOffer: {},
    stepTitle: {}
  },
  setup(__props: any) {

const props = __props;

// Define Functions
// ------------------------------------------------------------- //
const getFilterStep = (proposition: IProposition): IStep => {
  return proposition.displaySteps.filter((x) => x.stepName.toLowerCase() == props.stepTitle?.toLowerCase())[0];
};

return (_ctx: any,_cache: any) => {
  return (_ctx.decisionOffer?.propositions != null && _ctx.decisionOffer?.propositions.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.decisionOffer.propositions, (proposition, key) => {
          return (_openBlock(), _createElementBlock("div", {
            key: key,
            class: "flex-1 px-8 py-4 border-solid border border-racq-white rounded"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(RichText), {
                  class: "mb-4 text-base-bold",
                  field: { value: getFilterStep(proposition).headerText }
                }, null, 8, ["field"]),
                _createElementVNode("p", _hoisted_4, [
                  _createVNode(_unref(RichText), {
                    field: { value: getFilterStep(proposition).messageText }
                  }, null, 8, ["field"])
                ])
              ]),
              _createElementVNode("img", {
                src: getFilterStep(proposition).imageUrl,
                class: "h-16 w-16",
                alt: "offer image"
              }, null, 8, _hoisted_5)
            ])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})