import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { useFeatureTogglesStore } from '@/store/feature-toggles/featureTogglesStore';
import { ref, onMounted, computed, reactive } from 'vue';
import { IFeatureToggle } from '@/interfaces/entities/feature-toggle';
import { getToggle, FeatureToggles } from '@/utils/feature-toggle';
import { prop } from 'vue-class-component';

export default /*@__PURE__*/_defineComponent({
  __name: 'DecisionIntelligenceDisclaimer',
  props: {
    quoteReference: {},
    isQuotePage: { type: Boolean },
    stepName: {}
  },
  setup(__props: any) {

const refFeatureToggles = useFeatureTogglesStore();
const featureToggle = ref<IFeatureToggle | null>();
const nudgeDisclaimer = ref<string>();

const props = __props;

onMounted(async () => {
  const featureToggles = await refFeatureToggles.getFeatureToggles();
  featureToggle.value = getToggle(FeatureToggles.DisablePropositions, featureToggles);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      id: `nudge-disclaimer-${props.stepName ? props.stepName?.replaceAll(' ', '-')?.toLowerCase() : 'quote'}`
    }, null, 8, _hoisted_1)
  ]))
}
}

})