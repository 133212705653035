import { LiveChatServices as _livechat } from '@/services/store';
import { useAnalyticsStore } from '../analytics/analytics.state';
import { defineStore } from 'pinia';
import { FormEvents } from '@/enums/form-events.enum';

export const useLiveChatStore = defineStore('LiveChat', {
  state: (): {
    _RACQPlugin?: any;
    _liveChatEnabled?: boolean;
  } => ({}),
  getters: {
    RACQPlugin: (state) => state._RACQPlugin,
    liveChatEnabled: (state) => state._liveChatEnabled,
  },
  actions: {
    /**
     * Initial webchat plugin
     * @param plugin
     */
    initPlugin(plugin: any) {
      this._RACQPlugin = plugin;
    },

    /**
     * Option chat window
     * @param jpCode
     * @param jpDescription
     * @param additionalComments
     * @param cancellationReasons
     */
    startChat() {
      this.addAnalyticsForLiveChat();
      this._RACQPlugin.command('WebChat.open', {});
    },

    /**
     * Get chat session data from server
     * @returns
     */
    async getLiveChatSessionData() {
      return _livechat.getLiveChatSessionData();
    },

    /**
     * Send analytics
     */
    addAnalyticsForLiveChat() {
      const analyticsData = {
        event: FormEvents.CHAT_STARTED,
        form: {},
      };

      useAnalyticsStore().pushAnalytics(analyticsData);
    },

    setChatEnabled(value: boolean) {
      this._liveChatEnabled = value;
    },
  },
});
