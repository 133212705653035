import { nextTick } from 'vue';

/**
 * Custom plugin to integrate spinner into the Vue Formkit library.
 * Spinner will be shown in the form input 'suffixIcon' slot.
 *
 * To make this solution work, this code leverages Formkit Schemas.
 * Read more about Schemas: https://formkit.com/essentials/schema
 *
 */
function formkitPreContentPlugin(node: any) {
  node.on('created', async () => {
    // extract tooltip attrs from node and set as props
    node.addProps(['preContentClass', 'preContent']);

    const renderSchema = node?.props?.definition?.schema;

    if (!renderSchema) return;
    if (node.props.preContent) {
      await nextTick();
      let labelType = 'legend';
      if (
        node.type !== 'radio' &&
        node.type !== 'checkbox' &&
        node.context?.type !== 'radio' &&
        node.context?.type !== 'checkbox'
      ) {
        labelType = 'label';
      }
      const input =
        document.querySelector(`*[data-id='${node.props.id}'] ${labelType}`) ||
        document.querySelector(`#${node.props.id}__outer ${labelType}`) ||
        document.querySelector(`#${node.props.id} ${labelType}`) ||
        document.querySelector(`label[for=${node.props.id}]`);

      const preContent = document.createElement('div');
      preContent.className = node.props.preContentClass;
      preContent.innerHTML = node.props.preContent;
      input?.after(preContent);
    }
  });
}

export default formkitPreContentPlugin;
