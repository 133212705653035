<template>
  <SectionFrame :sectionTitle="trackYourPatrolTitle" titleClasses="text-xl-bold">
    <template #sectionFrameContent>
      <div class="grid grid-cols-1 w-full gap-6">
        <p class="text-base" v-html="trackYourPatrolDescription"></p>

        <div class="">
          <label for="tracking-number" class="mb-1 formkit-label block text-input-label w-auto md:pe-8">
            Tracking number
          </label>
          <div class="flex flex-col md:flex-row items-start gap-3">
            <FormKit
              id="tracking-number"
              ref="trackingNumber"
              :modelValue="trackingNumber"
              type="text"
              outerClass="mb-0 sm:w-full md:w-[240px] !opacity-100"
              name="tracking-number"
              innerClass="w-full"
              inputClass="!text-current"
              disabled
            />
            <button
              type="button"
              class="cta-card-button px-3 py-2 text-base btn-md btn btn-secondary w-full md:w-auto"
              @click.stop.prevent="copyTrackingNumber"
            >
              <img
                :src="buttonIcon"
                :alt="isCopied ? 'success icon' : 'copy icon'"
                role="img"
                class="inline-block h-4 w-4"
              />
              {{ buttonText }}
            </button>
          </div>
        </div>
        <p v-html="appDownloadDescription"></p>
      </div>

      <div class="flex flex-row items-center">
        <template v-if="appleDownloadLink">
          <a :href="appleDownloadLink" target="_blank" class="inline-block mr-3">
            <img src="@/assets/icons/apple-app-store-button.svg" alt="apple app store icon" class="inline-block" />
          </a>
        </template>

        <template v-if="googleDownloadLink">
          <a :href="googleDownloadLink" target="_blank" class="inline-block">
            <img src="@/assets/icons/google-play-store-button.svg" alt="google play store icon" class="inline-block" />
          </a>
        </template>
      </div>
    </template>
  </SectionFrame>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import SectionFrame from '@/components/SectionFrame/SectionFrame.vue';

const props = defineProps<{
  trackYourPatrolTitle: string;
  trackYourPatrolDescription: string;
  appDownloadDescription: string;
  appleDownloadLink: string;
  googleDownloadLink: string;
  trackingNumber: string;
}>();

const localTrackingNumber = ref<string>(props.trackingNumber);
const buttonText = ref<string>('Copy number');
const isCopied = ref(false);
const buttonIcon = computed(() =>
  isCopied.value
    ? new URL('@/assets/icons/green-success-icon.svg', import.meta.url).href
    : new URL('@/assets/icons/copy-icon.svg', import.meta.url).href
);

const copyTrackingNumber = async () => {
  try {
    await navigator.clipboard.writeText(localTrackingNumber.value);
    buttonText.value = 'Copied';
    isCopied.value = true;
    setTimeout(() => {
      buttonText.value = 'Copy number';
      isCopied.value = false;
    }, 2000);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};
</script>
