import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-2 border-solid rounded-sm px-5 py-2" }
const _hoisted_2 = { class: "row pt-1 pb-3" }
const _hoisted_3 = { class: "col-12 border-b" }
const _hoisted_4 = { class: "text-base-bold pt-1 pb-3" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-12 border-b" }
const _hoisted_7 = { class: "text-sm pt-1 pb-3" }
const _hoisted_8 = { class: "row pt-1 pb-3" }
const _hoisted_9 = { class: "col-12 border-b" }
const _hoisted_10 = { class: "text-sm pt-1 pb-3" }
const _hoisted_11 = { class: "row pt-1 pb-3" }
const _hoisted_12 = { class: "col-12 border-b" }
const _hoisted_13 = { class: "text-sm pt-1 pb-3" }

import { IPolicyHolderDetails } from '@/interfaces';


export default /*@__PURE__*/_defineComponent({
  __name: 'PolicyHolder',
  props: {
    policyHolder: {}
  },
  setup(__props: any) {

const props = __props;

const formattedAddress = (policyHolder: IPolicyHolderDetails) => {
  return `${policyHolder.streetNumber.value} ${policyHolder.street.value}, ${policyHolder.suburb.value}, ${policyHolder.state.value}, ${policyHolder.postcode.value}, ${policyHolder.country.value}`;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.policyHolder.salutation.value) + " " + _toDisplayString(_ctx.policyHolder.firstname.value) + " " + _toDisplayString(_ctx.policyHolder.surname.value), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-sm-bold inline-flex" }, "Contact number", -1)),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.policyHolder.telephoneNumber.value), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-sm-bold inline-flex" }, "Email", -1)),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.policyHolder.emailAddress.value), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-sm-bold inline-flex" }, "Address", -1)),
        _createElementVNode("div", _hoisted_13, _toDisplayString(formattedAddress(_ctx.policyHolder)), 1)
      ])
    ])
  ]))
}
}

})