import { INameCode } from '@/interfaces/entities/name-code.interface';
import { INameValue } from '@/interfaces/entities/name-value.interface';
import { parse, format } from 'date-fns';

import { Field } from '@sitecore-jss/sitecore-jss-vue';
/**
 * Convert an Array of objects into an Object.
 *
 * @param arr The Array to map over.
 * @param key The desired field for each Object key.
 *            If no key is passe in, it is assumed that this will be used
 *            for sitecore form inputs using the formInputId.value
 *
 */
export function convertArrayToObject(arr: Record<any, any>[], key?: string): Record<string, any> {
  if (!arr?.length) return {};

  const newObject = Object.assign(
    {},
    ...arr
      // shouldn't have to do this ... it means a field is broken in sitecore
      .filter((item) => (item?.formInputId?.value ? true : false))
      //
      .map((item) => ({
        [key || item.formInputId.value]: item,
      }))
  );

  return newObject;
}

/**
 * Convert a PascalCase sting to kebab-case
 *
 * Used for converting Vue component names
 * to kebab case for file path importing.
 *
 */
export function pascalCaseToKebabCase(str = '') {
  return str
    .match(/[A-Z][0-9a-z]*/g)
    ?.join('-')
    .toLowerCase();
}

/**
 * Restrict decimals to a set number of digits.
 *
 * @param num
 * @param digits
 * @returns
 */
export function truncateDecimals(num: number, digits = 2) {
  const multiplier = Math.pow(10, digits);
  const adjustedNum = num * multiplier;
  const truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

  return truncatedNum / multiplier;
}

// TODO: Deprecated: This is a bad pattern, do not use. Instead see Homecontents_CoverDetails.vue for an example of mapping dynamic options
export function convertReferenceDataNameCodeToDynamicOptions(apiResponse: INameCode[], excludeCode?: string): any {
  if (!apiResponse || !apiResponse.values) return '';

  let filteredResponse;
  if (Array.isArray(apiResponse.values)) {
    filteredResponse = JSON.parse(JSON.stringify(apiResponse.values));
  } else {
    filteredResponse = JSON.parse(JSON.stringify(apiResponse));
  }

  if (filteredResponse && excludeCode) {
    filteredResponse = filteredResponse.filter((item: any) => item.code !== excludeCode);
  }

  return filteredResponse
    .map((item: any) => `${item.name}=${item.code}`)
    .map((e: any) => e)
    .join('&');
}

// TODO: Deprecated: This is a bad pattern, do not use. Instead see Homecontents_CoverDetails.vue for an example of mapping dynamic options
export function convertReferenceDataValueCodeToDynamicOptions(apiResponse: INameValue[], excludeCode?: string): any {
  if (!apiResponse) return '';

  let filteredResponse;
  if (Array.isArray(apiResponse.values)) {
    filteredResponse = JSON.parse(JSON.stringify(apiResponse.values));
  } else {
    filteredResponse = JSON.parse(JSON.stringify(apiResponse));
  }

  if (filteredResponse && excludeCode) {
    filteredResponse = filteredResponse.filter((item: any) => item.value !== excludeCode);
  }

  return filteredResponse
    .map((item: any) => `${item.name}=${item.value}`)
    .map((e: any) => e)
    .join('&');
}

export const convertToISOString = (dateString: string): string => {
  const [day, month, year] = dateString.split('/').map(Number);
  const dateObj = new Date(Date.UTC(year, month - 1, day)); // Months are 0-indexed in JavaScript
  dateObj.setMilliseconds(0);

  return dateObj.toISOString().replace('.000Z', 'Z');
};

export const convertToISODate = (dateString: string): Date => {
  const [day, month, year] = dateString.split('/').map(Number);
  const dateObj = new Date(Date.UTC(year, month - 1, day)); // Months are 0-indexed in JavaScript
  dateObj.setMilliseconds(0);

  return dateObj;
};

export const convertISOToDate = (isoString: string): string => {
  const dateObj = new Date(isoString);
  return convertISOToDateFormatted(dateObj);
};

export const convertISOToDateFormatted = (date: Date): string => {
  // Using Brisbane timezone to make sure everybody will get the same date
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'Australia/Brisbane',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

// Function to transform a generic field to the expected structure
export const transformField = (field: Field) => {
  return {
    value: typeof field.value === 'string' || typeof field.value === 'number' ? field.value : undefined,
    editable: field.editable,
  };
};

/**
 * Function to map form step to index of an array in the quote and return the current quote index for the step.
 * For example useful when needing to know which Pet to load and which Pet item to remove from the quote.
 * @param formStore
 * @param purchaseStepId
 * @param currentStepIndex
 * @returns
 */
export const getQuoteArrayItemIndexByStepIndex = (formStore: any, purchaseStepId: string, currentStepIndex: number) => {
  if (!formStore || !purchaseStepId || !currentStepIndex) return -1;

  const mapStepIndexAndIndexMapping = formStore.inScopeSteps
    .filter((x: any) => x.id.toLowerCase() === purchaseStepId.toLocaleLowerCase())
    .map((x: any, i: number) => {
      return { stepIndex: x.stepIndex, index: i };
    });

  const currentStep = mapStepIndexAndIndexMapping.find((x: any) => x.stepIndex === currentStepIndex);
  return currentStep ? currentStep?.index : -1;
};

/**
 * Convert Date to dateString string
 *
 * @param date
 * @param outputStringFormat
 */
export function dateToDateString(inputDate: Date, outputStringFormat: 'dd/MM/yyyy' | 'ddMMyyyy' | 'yyyy-MM-dd') {
  return format(inputDate, outputStringFormat);
}

/**
 * Convert dateDtring to Date
 *
 * @param inputDateString
 * @param inputStringFormat
 */
export function dateStringToDate(inputDateString: string, inputStringFormat: 'dd/MM/yyyy' | 'ddMMyyyy' | 'yyyy-MM-dd') {
  return parse(inputDateString, inputStringFormat, new Date());
}

/**
 * Flat map fields, { field1: { value: 123 } } => { field1: 123 }
 * @param item
 * @returns
 */
export function flatMapFields(item: Record<string, { value: any }>): Record<string, any> {
  const keys = Object.keys(item);
  const result: Record<string, any> = {};
  keys.forEach((key: string) => (result[key] = item[key]?.value));
  return result;
}
