import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/img/loader-34.gif'


const _hoisted_1 = {
  class: "mx-0 md:mx-auto max-w-[784px] pb-[40px] mb-5 w-full bg-white relative md:rounded md:drop-shadow-lg",
  "data-tooltip-wrapper": ""
}
const _hoisted_2 = {
  key: 0,
  class: "w-full h-full absolute bg-curtain-color z-[100]"
}
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: "Loading...",
  class: "absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]"
}
const _hoisted_4 = { class: "max-w-[100vw] pt-[24px] px-[16px] md:pt-[32px] md:px-[40px]" }

import FormHeader from '@/components/FormHeader/FormHeader.vue';
import { IFormStep } from '@/interfaces';

// Define props and state
// ------------------------------------------------------------- //


export default /*@__PURE__*/_defineComponent({
  __name: 'FormStep',
  props: {
    step: {},
    stepIndex: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.step.isLoading || _ctx.step.isMasked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.step.isLoading)
            ? (_openBlock(), _createElementBlock("img", _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "header", {}, () => [
      _createElementVNode("div", null, [
        _createVNode(FormHeader, {
          step: _ctx.step,
          stepIndex: _ctx.stepIndex || 0
        }, null, 8, ["step", "stepIndex"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})