import { getScreenSize } from '@/utils/dom-helpers';
import { createTooltip, hideAllPoppers } from 'floating-vue';

interface ITooltip {
  tooltipId: string;
  tooltipContent: string;
}

/**
 * Create RACQ styled tooltips using Floating Vue.
 *
 * Docs: https://floating-vue.starpad.dev/guide/config
 *
 * @param tooltipObject
 */
export async function renderTooltip(tooltipObject: ITooltip) {
  const tooltipTrigger = document.getElementById(tooltipObject.tooltipId);

  if (!tooltipTrigger) {
    console.warn('No tooltip ID found: ', tooltipObject.tooltipId);
    return;
  }

  // Floating Vue tooltip options
  // ref: https://floating-vue.starpad.dev/guide/config#default-values
  const tooltipOptions = {
    triggers: [],
    content: tooltipObject.tooltipContent || 'No tooltip content provided',
    /**
     * Set the distance between the tooltip trigger and the tooltip.
     *
     * The UI design requirement is to place the tooltip outside
     * and to the right of the form itself.
     *
     * If the viewport is reduced then the tooltip adjusts to
     * sit to the left, above or underneath the trigger.
     *
     *  distance: getTooltipDistance(tooltipTrigger, isSmallScreen),
     *
     * Skidding is an offset value that positions the tooltip arrow
     * up or down the y axis. On large screens the arrow is offset
     * to be positioned closer to the top of the tooltip.
     */
    skidding: 0, // this.isLargeScreen ? 24 : 0;

    /**
     * By default the tooltip will be placed to the right of the form.
     * Once the user starts to decrease the viewport, the tooltip
     * will shift it's position accordinly to these controls.
     */
    placement: 'bottom',

    /**
     * Enable HTML content to be rendered.
     */
    html: true,

    /**
     * Update popper on content resize
     */
    handleResize: true,

    /**
     * Flip to the opposite placement if needed
     */
    flip: false,

    /**
     * Hide tooltip when clicking outside
     */
    autoHide: true,
  };

  await hideAllPoppersAndPaddings();

  const tooltip = createTooltip(tooltipTrigger, tooltipOptions, null);
  tooltip.show();

  setTimeout(() => {
    document.querySelector('.v-popper__inner')?.addEventListener('click', hideAllPoppersAndPaddings);
  }, 500);
}

/**
 * Hide all tooltips and paddings
 */
export async function hideAllPoppersAndPaddings() {
  hideAllPoppers();
  document.querySelector('.v-popper__inner')?.removeEventListener('click', hideAllPoppersAndPaddings); // clean up click listener
  await new Promise((res) => {
    let timer: NodeJS.Timeout | undefined = setInterval(() => {
      if (document.getElementsByClassName('v-popper__popper').length == 0) {
        clearInterval(timer);
        timer = undefined;
        res(0);
      }
    }, 50);
    setTimeout(() => {
      if (timer) {
        clearInterval(timer);
        res(0);
      }
    }, 1000);
  });

  // Remove paddings for small screen *DS-1336
  while (document.getElementsByClassName('tooltip_padding').length) {
    document.getElementsByClassName('tooltip_padding')[0].remove();
  }
}

/**
 * Helper function to show tooltip on trigger click (button, icon, etc)
 *
 * @param event
 */
export async function showTooltipOnTriggerClick(event: Event, content: string) {
  const tooltipTrigger = event.target as HTMLButtonElement;
  if (tooltipTrigger.classList.contains('active')) {
    tooltipTrigger.classList.remove('active');
    await hideAllPoppersAndPaddings();
  }

  const allTooltipTriggers = document.querySelectorAll('.js-tooltip-trigger');
  allTooltipTriggers.forEach((triggerEl) => triggerEl.classList.remove('active'));
  tooltipTrigger.classList.add('active');

  const tooltipContent = content;
  const tooltipId = tooltipTrigger?.id;
  await renderTooltip({ tooltipId, tooltipContent });
}
