import { CoverTypeMapping, OccupancyTypesMapping, ProductMapping } from '@/enums/decision-intelligence.enum';
import { QuoteVehicleUseTypes } from '@/enums/quote-vehicle-use-types.enum';
import { IQuote, InsuranceQuoteType } from '@/interfaces';
import {
  ICreateDecisionOffer,
  IAcknowledgeDecisionRequest,
  Outcome,
  ICreateRoadsidePrepaidOfferRequest,
  IRenewalAcknowledgmentCrossSellModel,
} from '@/interfaces/decision-intelligence/decision-intelligence-request';
import { useAccountStore } from '@/store';
import { isMobileDevice } from '@/utils/dom-helpers';
import { IAction, IProposition } from '@/interfaces/decision-intelligence/decision-intelligence-response';
import { ICreateLeadRequestModel, LeadAttributes } from '@/interfaces/lead/lead-model';
import { IPaymentReferenceDetails, IQuotedOption } from '@/interfaces/make-a-payment/make-a-payment.interface';

export const mapDecisionIntelligenceRequest = (
  quote: Partial<IQuote>,
  isCrossSell: boolean,
  isQuote: boolean,
  tiggerStep?: string
): ICreateDecisionOffer => {
  const accountStore = useAccountStore();
  let vehicle = null;
  if (quote.quoteType == InsuranceQuoteType.MotorComprehensive) {
    vehicle = {
      driveType: quote?.motorQuote?.vehicleDetails?.glassesGuideVehicleDetails?.driveType.value,
      fuelType: quote.motorQuote?.vehicleDetails?.glassesGuideVehicleDetails?.fuelType.value,
      usageType: QuoteVehicleUseTypes[quote?.motorQuote?.vehicleDetails?.vehicleUseType ?? 1],
      vehicleMake: quote?.motorQuote?.vehicleDetails?.glassesGuideVehicleDetails?.make?.description,
      vehicleModel: quote?.motorQuote?.vehicleDetails?.glassesGuideVehicleDetails?.model?.description,
      vehicleRegistrationNumber: quote?.motorQuote?.vehicleDetails?.vehicleRegistration.value,
      vehicleSeries: quote?.motorQuote?.vehicleDetails?.glassesGuideVehicleDetails?.variant?.description,
      vehicleValue: quote?.motorQuote?.vehicleDetails?.glassesGuideVehicleDetails?.vehicleValue?.value,
      vehicleYear: quote?.motorQuote?.vehicleDetails?.glassesGuideVehicleDetails?.manufacturingYear.year,
    };
  }
  const mappedRequest = {
    filters: {
      maximumNumberOfItems: 2,
      maximumNumberOfNudges: 2,
      maximumNumberOfPropositions: 2,
    },
    decisionCriteria: {
      formInputs: {
        birthDate: quote.primaryPolicyHolderDetails?.dateOfBirth.value,
        existingMember: quote.primaryPolicyHolderDetails?.hasRacqMembership.value,
        lastName: quote.primaryPolicyHolderDetails?.surname.value,
        quoteReference: quote.reference,
        hasRoadsideAssistance: quote.primaryPolicyHolderDetails?.hasRoadsideProduct.value,
        annualPremium: quote.insuranceQuoteSummary?.yearlyAnnualPremium,
        coverType: CoverTypeMapping[InsuranceQuoteType[quote.quoteType ?? 0] as keyof typeof CoverTypeMapping],
        membershipLevel: quote.primaryPolicyHolderDetails?.membershipLoyaltyLevel.value,
        homeLoan: quote.homeAndContentsQuote?.propertyHasMortgage.value,
        home: {
          homeLoan: quote.homeAndContentsQuote?.propertyHasMortgage.value,
          homeLoanProvider: quote.homeAndContentsQuote?.propertyMortgageProvider.value,
          buildingType: quote.homeAndContentsQuote?.buildingDetails.buildingType.value,
          occupancyType:
            OccupancyTypesMapping[
              quote.homeAndContentsQuote?.propertyOwnershipType?.value as keyof typeof OccupancyTypesMapping
            ],
          dpid: quote.homeAndContentsQuote?.homeLocationDetails?.dpid.value,
          postCode: quote.homeAndContentsQuote?.homeLocationDetails?.postCode.value,
          yearBuilt: quote.homeAndContentsQuote?.buildingDetails?.yearBuilt.value,
          optionalPetCover: quote.homeAndContentsQuote?.advancedCoverOptions?.numberOfPetsToCover.value ?? 0 > 0,
        },
        vehicle: vehicle,
        policyNumber: quote.policyReference,
        homeInsuranceClaimed: quote.homeAndContentsQuote?.homeQuote.claimsWithinShortPeriod.value,
      },
      partyReferences: {
        d365Id: accountStore.identityDetails?.d365Id,
      },
      sessionContext: {
        channel: 'Website',
        deviceType: isMobileDevice() ? 'Mobile' : 'Desktop',
        journey: isQuote ? 'Quote' : 'Purchase',
        pillar: 'Insurance',
        product: ProductMapping[InsuranceQuoteType[quote.quoteType ?? 0] as keyof typeof ProductMapping],
        strategy: isCrossSell ? 'Cross-Sell' : 'Acquisition',
        triggerStep: tiggerStep,
      },
    },
  } as ICreateDecisionOffer;
  return mappedRequest;
};

export const mapDecisionIntelligenceRenewalRequest = (
  paymentReferenceDetails: IPaymentReferenceDetails,
  isCrossSell: boolean,
  tiggerStep?: string
): ICreateDecisionOffer => {
  const mappedRequest = {
    filters: {
      maximumNumberOfItems: 1,
      maximumNumberOfNudges: 1,
      maximumNumberOfPropositions: 1,
    },
    decisionCriteria: {
      formInputs: {
        prn: paymentReferenceDetails.prn,
        policyNumber: paymentReferenceDetails.policyNumber,
      },
      partyReferences: {
        d365Id: paymentReferenceDetails?.partyReferences.d365Id,
        apttusId: paymentReferenceDetails?.partyReferences.apttusId,
      },
      sessionContext: {
        channel: 'Website',
        journey: 'Renewal',
        pillar: 'Insurance',
        triggerStep: tiggerStep,
        deviceType: isMobileDevice() ? 'Mobile' : 'Desktop',
        strategy: isCrossSell ? 'Cross-Sell' : 'Retention',
        product: ProductMapping[paymentReferenceDetails.coverageCode as keyof typeof ProductMapping],
      },
    },
  } as ICreateDecisionOffer;
  return mappedRequest;
};

export const mapDecisionOfferAcknowledge = (
  outCome: Outcome,
  quote?: Partial<IQuote>,
  paymentReferenceDetails?: Partial<IPaymentReferenceDetails>,
  proposition?: IProposition,
  action?: IAction,
  leadId?: string
): IAcknowledgeDecisionRequest => {
  return {
    actionId: action?.actionId,
    outcome: Outcome[outCome],
    quoteReference: quote ? quote.reference : undefined,
    policyNumber: quote ? quote.policyReference : paymentReferenceDetails?.policyNumber,
    d365Id: quote ? quote.primaryPolicyHolderDetails?.partyId?.value : paymentReferenceDetails?.partyReferences?.d365Id,
    propositionId: proposition?.id,
    leadId: leadId,
  };
};

export const mapCreateLeadRequest = (action: IAction, selectedOption: string): ICreateLeadRequestModel => {
  const response = {
    leadAdditionalAttributes: [
      { typeCode: LeadAttributes.sourceCode, value: 'Digital' },
      { typeCode: LeadAttributes.campaign, value: action.leadCampaign },
      { typeCode: LeadAttributes.channel, value: action.leadChannelOfChoiceCode },
      { typeCode: LeadAttributes.areaOfInterest, value: action.leadAreaOfInterestCode },
      { typeCode: LeadAttributes.productType, value: action.leadProductType },
      { typeCode: LeadAttributes.purpose, value: action.leadPurpose },
      { typeCode: LeadAttributes.assignTo, value: action.leadAssignTo },
    ],
  };
  if (action.actionType == 'Contact') {
    response.leadAdditionalAttributes.push({ typeCode: LeadAttributes.preferedContactDate, value: selectedOption });
  }
  if (action.actionType == 'Email') {
    response.leadAdditionalAttributes.push({
      typeCode: LeadAttributes.preferedContactTimeofDay,
      value: selectedOption,
    });
  }
  return response;
};

export const mapRSAPrepayRequest = (
  paymentReferenceDetails: IPaymentReferenceDetails
): ICreateRoadsidePrepaidOfferRequest => {
  return {
    prn: paymentReferenceDetails.prn,
    apttusId: paymentReferenceDetails.apttusId,
    assetLineItemId: paymentReferenceDetails.assetLineItemId,
    currentProduct: {
      amount: paymentReferenceDetails.currentProduct.amount,
      apttusCartId: paymentReferenceDetails.currentProduct.apttusCartId,
      apttusQuoteId: paymentReferenceDetails.currentProduct.apttusQuoteId,
      description: paymentReferenceDetails.currentProduct.description,
      product: paymentReferenceDetails.currentProduct.product,
      productCode: paymentReferenceDetails.currentProduct.productCode,
    },
    renewalOptions: paymentReferenceDetails.renewalOptions.map((x: IQuotedOption) => {
      return {
        amount: x.amount,
        apttusCartId: x.apttusCartId,
        apttusQuoteId: x.apttusQuoteId,
        description: x.description,
        product: x.product,
        productCode: x.productCode,
      };
    }),
  };
};
