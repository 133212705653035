import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }

import { computed } from 'vue';
import { Placeholder, Field, ComponentRendering } from '@sitecore-jss/sitecore-jss-vue';
import { useMeta } from 'vue-meta';
import Login from '@/components/Login/Login.vue';
import { useAccountStore } from '@/store';
import NotFound from './NotFound.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Layout',
  props: {
    route: {}
  },
  setup(__props: any) {

const props = __props;
const accountStore = useAccountStore();
const performLogin = computed(() => (props.route.fields?.AuthenticationRequired as Field)?.value);
const pageTitle = computed(
  () => (props.route.fields?.pageTitle as Field)?.value || (props.route.fields?.Title as Field)?.value || ''
);
useMeta({
  title: (props.route.fields?.pageTitle as Field)?.value || (props.route.fields?.Title as Field)?.value || 'Page',
});

return (_ctx: any,_cache: any) => {
  const _component_metainfo = _resolveComponent("metainfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(Placeholder), {
          name: "jss-header",
          pageTitle: pageTitle.value,
          rendering: _ctx.route
        }, null, 8, ["pageTitle", "rendering"]),
        (performLogin.value)
          ? (_openBlock(), _createBlock(Login, { key: 0 }))
          : _createCommentVNode("", true),
        (!_unref(accountStore).loginVisible)
          ? (_openBlock(), _createBlock(_unref(Placeholder), {
              key: 1,
              name: "headless-main",
              rendering: _ctx.route,
              errorComponent: NotFound
            }, null, 8, ["rendering"]))
          : _createCommentVNode("", true),
        _createVNode(_unref(Placeholder), {
          name: "jss-footer",
          rendering: _ctx.route
        }, null, 8, ["rendering"])
      ])
    ])
  ], 64))
}
}

})