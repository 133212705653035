<template>
  <FormKit 
    v-bind="bindings" 
    v-model="phoneNumber" 
    oninput="this.value=this.value.replace(/[^0-9]/g, '')" 
    maxlength="10" 
    inputClass="input-remove-box-shadow"
    :suffixIcon="showClearIcon === true && phoneNumber.length > 0 ? CLEAR_ICON : ''" 
    suffixIconClass="cursor-pointer h-fit !self-auto pr-3" 
    @suffixIconClick="clearField()"
  />
</template>

<script setup lang="ts">
import {ref, computed } from 'vue';
import { FormKit } from '@formkit/vue';
import { CLEAR_ICON } from '@/constants/svg-images.const';

const props = defineProps<{
  bindings: any;
}>();

const emit = defineEmits(['update:modelValue']);
const phoneNumber = ref<string>('');
const showClearIcon = computed(() => props.bindings.showClearIcon as boolean)

function clearField() {
  phoneNumber.value = '';
  emit('update:modelValue', phoneNumber.value);
}
</script>
