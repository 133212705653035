<template>
  <div
    class="self-stretch pb-4 border-racq-pale-blue-border flex-col justify-start items-start flex"
    :class="classNames"
  >
    <div class="self-stretch justify-start items-start gap-0.5 inline-flex">
      <div class="grow shrink basis-0 pt-px flex-col justify-start items-start inline-flex">
        <div class="self-stretch leading-normal">
          <slot name="title"></slot>
        </div>
        <div class="self-stretch text-paragraph-color text-base leading-normal">
          <slot name="content"></slot>
        </div>
      </div>
      <div class="px-1 py-0.5flex-col justify-center items-start gap-2.5 inline-flex">
        <slot name="edit"></slot>
      </div>
    </div>
  </div>
  <slot name="showMore"></slot>
</template>

<script setup lang="ts">
import { IReviewSummaryItem } from '@/interfaces';

// Define props and emitters
// ------------------------------------------------------------- //
const props = defineProps<{
  item: IReviewSummaryItem;
  isLastReviewSummaryItem: boolean; // The last item for the reviewSummary section (e.g. Contents section, Building section)
}>();

import { computed } from 'vue';

const classNames = computed(() => (!props.isLastReviewSummaryItem ? 'border-b' : ''));
</script>
