import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, unref as _unref, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "tooltip-body pt-12" }

import { ref } from 'vue';
import { FormKit } from '@formkit/vue';
import RadioButtonCardListItem from './RadioButtonCardListItem.vue';
import ModalDialog from '@/components/ModalDialog/ModalDialog.vue';
import { cloneDeep } from 'lodash-es';


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioButtonCardList',
  props: /*@__PURE__*/_mergeModels({
    bindings: {},
    initialOptionsCountToShow: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;

const model = _useModel<string>(__props, "modelValue");

const showModal = ref(false);
const displayContext = ref(cloneDeep(props.bindings));

if (props.initialOptionsCountToShow) {
  displayContext.value.options = displayContext.value.options.slice(0, props.initialOptionsCountToShow);
}

const onInput = (value: string) => {
  if (!value) {
    displayContext.value.options = props.bindings.options.slice(0, props.initialOptionsCountToShow);
    return;
  }
  displayContext.value.options = props.bindings.options.filter((option: any) => option.value === value);
  model.value = value;
  showModal.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(FormKit), _mergeProps(displayContext.value, {
      modelValue: model.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
      type: "togglebuttons",
      enforced: "true",
      onInput: onInput
    }), {
      default: _withCtx((itemContext) => [
        _createVNode(RadioButtonCardListItem, _normalizeProps(_guardReactiveProps(itemContext.option)), null, 16)
      ]),
      _: 1
    }, 16, ["modelValue"]),
    (_ctx.initialOptionsCountToShow && !model.value)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "link text-base-bold",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (showModal.value = true))
        }, " Show more "))
      : (model.value)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "link text-base-bold",
            type: "button",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (showModal.value = true))
          }, " Edit selection "))
        : _createCommentVNode("", true),
    _createVNode(ModalDialog, {
      modelValue: showModal.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((showModal).value = $event)),
      closeOnEsc: true,
      showXCloseButton: true,
      dialogClass: "tooltip-dialog"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_unref(FormKit), _mergeProps(_ctx.bindings, {
            id: _ctx.bindings.id + 'modal',
            type: "togglebuttons",
            enforced: "true",
            label: "",
            onInput: onInput
          }), {
            default: _withCtx((itemContext) => [
              _createVNode(RadioButtonCardListItem, _normalizeProps(_guardReactiveProps(itemContext.option)), null, 16)
            ]),
            _: 1
          }, 16, ["id"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})