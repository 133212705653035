import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, unref as _unref, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "cta-card flex flex-col items-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "cta-card-title text-xl-bold mb-6"
}
const _hoisted_4 = {
  key: 2,
  class: "cta-card-content-container"
}
const _hoisted_5 = {
  key: 3,
  class: "cta-card-buttons mt-6 flex flex-col items-center w-full gap-4"
}
const _hoisted_6 = ["aria-label"]
const _hoisted_7 = ["aria-label"]

import { RichText } from '@sitecore-jss/sitecore-jss-vue';

interface CTACardProps {
  icon?: string;
  title?: string;
  content?: string;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CallToActionCard',
  props: {
    icon: {},
    title: {},
    content: {},
    primaryButtonLabel: {},
    secondaryButtonLabel: {}
  },
  emits: ['buttonClick'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const emitClick = (type: 'primaryCtaButton' | 'secondaryCtaButton') => {
  emit('buttonClick', type);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.icon,
          alt: "icon",
          class: "cta-card-icon h-20 w-20 mb-4"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.$slots.beforeContent || _ctx.content || _ctx.$slots.afterContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.$slots.beforeContent)
            ? _renderSlot(_ctx.$slots, "beforeContent", { key: 0 })
            : _createCommentVNode("", true),
          (_ctx.content)
            ? (_openBlock(), _createBlock(_unref(RichText), {
                key: 1,
                field: { value: _ctx.content },
                class: "cta-card-content m-0 p-0"
              }, null, 8, ["field"]))
            : _createCommentVNode("", true),
          (_ctx.$slots.afterContent)
            ? _renderSlot(_ctx.$slots, "afterContent", { key: 2 })
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.primaryButtonLabel || _ctx.secondaryButtonLabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.primaryButtonLabel)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "cta-card-button flex px-3 py-2 justify-center items-center self-stretch text-base btn btn-md btn-primary",
                "aria-label": _ctx.primaryButtonLabel,
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (emitClick('primaryCtaButton')), ["stop","prevent"]))
              }, _toDisplayString(_ctx.primaryButtonLabel), 9, _hoisted_6))
            : _createCommentVNode("", true),
          (_ctx.secondaryButtonLabel)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                type: "button",
                class: "cta-card-button flex px-3 py-2 justify-center items-center self-stretch text-base btn-md btn btn-secondary",
                "aria-label": _ctx.secondaryButtonLabel,
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (emitClick('secondaryCtaButton')), ["stop","prevent"]))
              }, _toDisplayString(_ctx.secondaryButtonLabel), 9, _hoisted_7))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})