/**
 * Set default props for form elements
 *
 */
function formkitDefaultPropsPlugin(node: any) {
  if (node.props.type === 'form') {
    if (node.props.submitBehavior === undefined) {
      node.props.submitBehavior = 'live';
    }
    if (node.props.actions === undefined) {
      node.props.actions = false;
    }
    if (node.props.incompleteMessage === undefined) {
      node.props.incompleteMessage = false;
    }
  }
}

export default formkitDefaultPropsPlugin;
