<!-- THIS IS PART OF FindCar.vue -->
<template>
  <div class="flex md:flex-row flex-col md:gap-4">
    <div class="flex flex-col">
      <FormKit
        v-model="vehicleRegistration"
        :label="regoInputLabel"
        inputClass="racq-w-md racq-max-w-md"
        :innerClass="{ 'bg-formfield-error': regoLookupErrorMessage && regoLookupErrorMessage !== regoNotFound }"
        outerClass="mb-0"
        :validation="[['required'], ['matches', regoRegEx]]"
        validationVisibility="submit"
        maxLength="9"
        :help="help"
        @keyup.enter="getVehicleFromRego"
      />

      <p
        v-if="regoLookupErrorMessage && regoLookupErrorMessage !== regoNotFound"
        class="text-xs text-racq-error-red mb-0"
      >
        {{ regoLookupErrorMessage }}
      </p>
    </div>

    <FormKit
      type="button"
      :label="findCarBtn"
      inputClass="btn btn-primary btn-md mt-4 md:mt-0"
      outerClass="flex items-start md:mt-[28px]"
      @click="getVehicleFromRego"
    />
  </div>
  <div v-show="regoLookupErrorMessage && regoLookupErrorMessage === regoNotFound">
    <InformationBox
      id="find-car-by-rego-error"
      :type="InformationBoxTypes.WARNING"
      :description="regoLookupErrorMessage"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, nextTick, onMounted } from 'vue';
import { hideAllPoppersAndPaddings } from '@/plugins/tooltip/renderTooltip';
import { useAnalyticsStore, useFormStore, useFindCarStore } from '@/store';
import vehicleService from '@/services/store/vehicle.service';
import { FormEvents } from '@/enums/form-events.enum';
import { useI18n } from 'vue-i18n';
import InformationBox from '@/components/InformationBox/InformationBox.vue';
import { InformationBoxTypes } from '@/interfaces/forms/information-box.interface';
import { storeToRefs } from 'pinia';

// Define Props & Emitters
// ------------------------------------------------------------- //
const props = defineProps<{
  regoInputLabel?: string;
  findCarBtn?: string;
  regoNumber?: string;
  help?: string;
}>();

const emit = defineEmits(['vehicleFound', 'qldVehicleNotFound', 'setRegistration']);
const formStore = useFormStore();
const analyticsStore = useAnalyticsStore();
const findCarStore = useFindCarStore();
const translation = useI18n();

const { isQldRegoFound } = storeToRefs(findCarStore);

// Vehicle rego can contain only case insensitive alphanumeric characters with the length ranging from 1 to 9 characters)
const regoRegEx = /^[a-zA-Z0-9]{1,9}$/;

// Define Component state
// ------------------------------------------------------------- //
const vehicleRegistration = ref('');
const regoLookupErrorMessage = ref('');
const regoNotFound = ref('');

watch(
  () => {
    return props.regoNumber;
  },
  (newVal, oldVal) => {
    if (newVal && newVal !== oldVal && newVal !== vehicleRegistration.value) {
      vehicleRegistration.value = newVal;
      getVehicleFromRego();
    }
  }
);

watch(
  isQldRegoFound,
  (newValue) => {
    if (newValue === false) {
      const errorMessages: Record<string, string> = translation.getLocaleMessage('en') as any;
      regoNotFound.value = regoLookupErrorMessage.value = errorMessages.REGO_NOTFOUND;
    }
  },
  { immediate: true }
);

onMounted(() => {
  if (props.regoNumber) {
    vehicleRegistration.value = props.regoNumber!;
  }
});

// Define functions
// ------------------------------------------------------------- //
async function getVehicleFromRego() {
  regoLookupErrorMessage.value = '';
  const errorMessages: Record<string, string> = translation.getLocaleMessage('en') as any;
  regoNotFound.value = errorMessages.REGO_NOTFOUND;

  if (!vehicleRegistration.value) {
    analyticsStore.pushAnalytics({
      event: FormEvents.SEARCH_REGISTRATION,
      form: {
        formErrorType: 'form error',
        formError: 'Enter your car registration number',
        formErrorDetail: errorMessages.REGO_EMPTY,
        name: findCarStore.formName,
      },
    });
    regoLookupErrorMessage.value = errorMessages.REGO_EMPTY;
    return;
  }

  /* Make sure rego only contains valid characters */
  if (!vehicleRegistration.value.match(regoRegEx)) {
    analyticsStore.pushAnalytics({
      event: FormEvents.SEARCH_REGISTRATION,
      form: {
        formErrorType: 'form error',
        formError: 'Invalid characters in rego',
        formErrorDetail: errorMessages.REGO_INVALID,
        name: findCarStore.formName,
      },
    });
    regoLookupErrorMessage.value = errorMessages.REGO_INVALID;
    return;
  }

  formStore.showLoadingForCurrentStep();

  try {
    // Set the registration number here, otherwise we lose it if it's not found and we do a manual lookup
    emit('setRegistration', vehicleRegistration.value);
    const vehicleDataFromRego = await vehicleService.regoLookup(vehicleRegistration.value);
    if (!vehicleDataFromRego) {
      regoLookupErrorMessage.value = errorMessages.REGO_NOTFOUND;
      await nextTick();
      analyticsStore.pushAnalytics({
        event: FormEvents.SEARCH_REGISTRATION,
        form: {
          formErrorType: 'form error',
          formError: 'Registration number not found',
          formErrorDetail:
            document.getElementById('find-car-by-rego-error')?.textContent ?? errorMessages.REGO_NOTFOUND,
          name: findCarStore.formName,
        },
      });
      emit('qldVehicleNotFound');
    }

    hideAllPoppersAndPaddings();
    vehicleDataFromRego && emit('vehicleFound', vehicleDataFromRego);
  } catch (e) {
    console.error('Rego lookup failed.', e);
    regoLookupErrorMessage.value = errorMessages.REGO_NOTFOUND;
    emit('qldVehicleNotFound');
  } finally {
    formStore.showLoadingForCurrentStep(false);
  }
}
</script>
