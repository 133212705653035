<template>
  <RACQFormKit :bindings="propBindings" />
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useReferenceDataStore } from '@/store';
import RACQFormKit from '@/components/RACQFormKit/RACQFormKit.vue';
import { IDropdownItem } from '@/interfaces';

const props = defineProps<{
  bindings: any;
}>();

const propBindings = computed(() => {
  return {
    ...props.bindings,
    options: options.value || [],
    type: 'radio',
  };
});

const refDataStore = useReferenceDataStore();
const options = ref<IDropdownItem[]>([]);

onMounted(() => {
  refDataStore
    .getGenders()
    .then((res) => {
      options.value = res?.map((option) => ({ label: option.name, value: option.value })) || [];
    })
    .catch(console.warn);
});
</script>
