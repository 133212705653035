import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src", "alt"]

import { ref, onMounted } from 'vue';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { replaceBookmark } from '@/utils/bookmark.replace';

const paymentReferenceNumberName = 'paymentReferenceNumber';


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentMethods',
  props: {
    paymentMethods: {},
    paymentReferenceNumber: {}
  },
  setup(__props: any) {

let filteredPaymentMethods = ref<any[]>([]);

const props = __props;

onMounted(async () => {
  filteredPaymentMethods.value = props.paymentMethods.filter(
    (x) =>
      x.fields?.methodContent?.value.indexOf(paymentReferenceNumberName) == -1 ||
      props.paymentReferenceNumber?.length > 0
  );
});

const replaceBookmarks = (content: string) => {
  return replaceBookmark(paymentReferenceNumberName, content, props.paymentReferenceNumber);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredPaymentMethods), (method, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: index,
      class: "grid grid-cols-[10%_40%_50%] border-b border-solid mb-5"
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          src: method.fields?.icon?.value?.src,
          alt: method.fields.icon?.value.alt,
          class: "h-[auto] w-[46px] m-auto"
        }, null, 8, _hoisted_1)
      ]),
      _createElementVNode("div", null, _toDisplayString(method.fields?.methodName?.value), 1),
      _createVNode(_unref(RichText), {
        field: { value: replaceBookmarks(method.fields?.methodContent?.value) }
      }, null, 8, ["field"])
    ]))
  }), 128))
}
}

})