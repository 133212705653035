<template>
  <RACQFormKit
    v-model="isOverseasSelected"
    :name="`postalOverseas-${instanceIdentifier}`"
    :bindings="
      {
        label: overseasOptionLabel,
        name: `postalOverseas-${instanceIdentifier}`,
        type: 'toggle',
        options: [
          { label: 'Australia', value: 'false' },
          { label: 'Overseas', value: 'true' },
        ],
        tooltip: {
          content: overseasOptionTooltip,
          tooltipId: 'overseas_option__tooltip_id',
          tooltipOuterId: 'overseas_option__outer',
          isModal: false,
        },
      } as VueFormInputProps
    "
  >
  </RACQFormKit>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useMultiInstanceAddressOptionsStore } from '@/store';
import { VueFormInputProps } from '@/composables/useFormInputData';
import RACQFormKit from '../RACQFormKit/RACQFormKit.vue';
import { toBool } from '@/utils/convert';
import { AUSTRALIA } from '@/constants/general-words.const';

const props = defineProps<{
  defaultIsOverseasOption?: boolean;
  instanceIdentifier: string;
  overseasOptionLabel: string;
  overseasOptionTooltip: string;
}>();

const multiInstanceAddressStore = useMultiInstanceAddressOptionsStore();
const addressLookupOptionsStore = multiInstanceAddressStore.getInstance(props.instanceIdentifier);

const isOverseasSelected = ref<string>();

onMounted(async () => {
  if (addressLookupOptionsStore.isOverseasManualAddress()) {
    isOverseasSelected.value = 'true';
    addressLookupOptionsStore.isOverseasSelection = true;
  }
});

watch(
  () => isOverseasSelected.value,
  async (newValue, oldValue) => {
    console.log('isOverseasSelected', newValue, oldValue);
    if (oldValue) {
      const enableManualEntry = oldValue !== undefined;
      addressLookupOptionsStore.onIsOverseasChanged(toBool(newValue), enableManualEntry);

      if (!toBool(newValue)) {
        addressLookupOptionsStore.manualAddressComponents.country = AUSTRALIA;
      }
    }
  }
);

watch(
  () => addressLookupOptionsStore.isOverseasSelection,
  async (newValue) => {
    isOverseasSelected.value = newValue.toString();
  }
);
</script>
