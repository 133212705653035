import {
  getInsuranceProduct,
  getSelfServiceChangeVehicleUrl,
  isInsuranceEligibleForCancellation,
} from '@/constants/forms-api.const';
import { RequestServices as _http } from '../common/request.services';
import { CancelInsuranceFormFetchPolicyResponse } from '@/interfaces';

export const CancelInsuranceFormService = {
  /**
   * Validate step 1
   * @param policyNumber
   * @param payload
   * @returns
   */
  isInsuranceEligibleForCancellation(
    policyNumber: string,
    payload: object
  ): Promise<{ jpCode: string; isEligible: boolean }> {
    return _http.HttpPost(isInsuranceEligibleForCancellation.replace('{0}', policyNumber), payload);
  },

  /**
   * Fetch Policy
   * @param policyNumber
   * @returns
   */
  fetchPolicy(policyNumber: string): Promise<CancelInsuranceFormFetchPolicyResponse> {
    return _http.HttpGet(getInsuranceProduct.replace('{0}', policyNumber));
  },

  /**
   * Get Change Vehicle Link
   * @param index
   */
  goChangeVehicleLink(): Promise<string> {
    return _http.HttpGet(getSelfServiceChangeVehicleUrl.replace('{0}', 'true'));
  },
} as const;
