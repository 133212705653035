import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, onMounted, ref } from 'vue';
import { useReferenceDataStore } from '@/store';
import RACQFormKit from '@/components/RACQFormKit/RACQFormKit.vue';
import { IDropdownItem } from '@/interfaces';


export default /*@__PURE__*/_defineComponent({
  __name: 'Gender',
  props: {
    bindings: {}
  },
  setup(__props: any) {

const props = __props;

const propBindings = computed(() => {
  return {
    ...props.bindings,
    options: options.value || [],
    type: 'radio',
  };
});

const refDataStore = useReferenceDataStore();
const options = ref<IDropdownItem[]>([]);

onMounted(() => {
  refDataStore
    .getGenders()
    .then((res) => {
      options.value = res?.map((option) => ({ label: option.name, value: option.value })) || [];
    })
    .catch(console.warn);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RACQFormKit, { bindings: propBindings.value }, null, 8, ["bindings"]))
}
}

})