import {
  BaseTrackableObject,
  TrackChangeOfString,
  TrackChangeOfNullableNumber,
  TrackChangeOfNullableBoolean,
  TrackChangeOfNumber,
  TrackChangeOfDate,
  TrackChangeOfBoolean,
} from './trackable-value.interface';
import { IPolicyHolderDetails } from './policyholder.interface';
import { IMotorQuote } from './motor-quote.interface';
import { IHomeAndContentsQuote } from './home-contents-quote.interface';
import { PaymentTypes } from '@/enums/payment-types.enum';

export interface IQuote extends BaseTrackableObject {
  quoteType?: InsuranceQuoteType;
  quotePhase: InsuranceQuotePhase;
  paymentFrequency?: PaymentFrequency;
  paymentType?: PaymentTypes;
  paymentReceiptNumber: string;
  paymentReferenceNumber: string;
  paymentDueDate?: Date;
  id: string;
  reference: string;
  clientIpAddress: string;
  policyReference: string;
  externalId: string;
  producerCode: TrackChangeOfString;
  motorQuote: IMotorQuote;
  homeAndContentsQuote: IHomeAndContentsQuote;
  //petsQuote: IPetsQuote;
  discounts: QuoteDiscounts;
  primaryPolicyHolderDetails: IPolicyHolderDetails;
  additionalPolicyHolderDetails: IPolicyHolderDetails[];
  additionalPolicyHolderDetailsCount: TrackChangeOfNumber;
  insuranceStartDate: TrackChangeOfDate;
  termStartDate?: Date;
  termEndDate?: Date;
  inceptionDate: Date;
  insuranceQuoteSummary: InsuranceQuoteSummary;
  coverageSummaries: CoverageSummary[];
  ruleCalculations: InsuranceQuoteRuleCalculations;
  dutyOfDisclosureAccepted: TrackChangeOfBoolean;
  membershipTermsAndConditionsAccepted: TrackChangeOfNullableBoolean;
  matchConfidence: string;
  renewalPreference: boolean;
  renewalTermAcceptance: boolean;
  rejectionReason: string;
}

export interface InsuranceQuoteSummary extends PricingSummary {
  monthlyAnnualPremium?: number;
  monthlyInstalment: number;
  payByTheMonthFee: number;
  bestPremium: number;
  policySystemPremium?: number;
  validToDate?: Date;
}

export interface PricingSummary {
  yearlyCallCenterPremium: number;
  yearlyAnnualPremium: number;
  monthlyCallCenterDeposit: number;
  monthlyDeposit: number;
  calculatedDate?: Date;
}

export interface PremiumDetails {
  calculationOrder: number;
  pointsUsed: number;
  percentageUsed: number;
  premiumValue: number;
  dailyPremiumValue?: number;
  coverageGroup?: number;
  description: string;
}

export interface QuoteDiscounts extends BaseTrackableObject {
  eligibleForMultiPolicyDiscount: TrackChangeOfBoolean;
  eligibleForCombinedPolicyDiscount: TrackChangeOfBoolean;
  eligibleForLoyaltyDiscount: TrackChangeOfBoolean;
  highestMembershipLoyaltyLevel: TrackChangeOfString;
  highestRoadsideProduct: TrackChangeOfString;
  eligibleForYearlyOnlineDiscount: TrackChangeOfBoolean;
  eligibleForMonthlyOnlineDiscount: TrackChangeOfBoolean;
  eligibleForSeniorCardDiscount: TrackChangeOfBoolean;
  eligibleForSeniorDiscount: TrackChangeOfBoolean;
  totalYearlyOnlineDiscountAmount: TrackChangeOfNullableNumber;
  totalMonthlyOnlineDiscountAmount: TrackChangeOfNullableNumber;
  policySystemDiscounts: PolicySystemDiscounts;
  eligibleForHomeBundleDiscount: TrackChangeOfBoolean;
  eligibleForStaffDiscount: TrackChangeOfBoolean;
  staffCategory: TrackChangeOfString;
}

export interface PolicySystemDiscounts extends BaseTrackableObject {
  ageDiscount?: boolean;
  alarmDiscount?: boolean;
  buildingTypeDiscount?: boolean;
  combinedDiscount?: boolean;
  driverExcellenceBonusDiscount?: boolean;
  householdExcellenceBonusDiscount?: boolean;
  multiPetDiscount?: boolean;
  multipleProductDiscount?: boolean;
  noClaimDiscount?: boolean;
  roadSideAssistanceLoyaltyDiscount?: boolean;
  seniorCardHolderDiscount?: boolean;
  staffDiscount?: boolean;
}

export interface CoverageSummary extends PricingSummary {
  coverageSummaryId: string;
  premiumDetails: PremiumDetails[];
}

export interface InsuranceQuoteRuleCalculations extends BaseTrackableObject {
  automationLevel: number;
  manualProcessingReasons: ManualProcessingReason[];
  prerenewalDirections: PrerenewalDirection[];
  payByTheMonthFeeWaived?: boolean;
  premiumLowerThanQuoted?: boolean;
  invalidRiskLocation?: boolean;
  discountDifferences: DiscountDifference[];
}

export interface ManualProcessingReason {
  code: number;
  description: string;
}

export interface PrerenewalDirection {
  code: string;
}

export interface DiscountDifference {
  discountType: number;
  iqHasDiscount: boolean;
  policySystemHasDiscount: boolean;
}

export enum InsuranceQuoteType {
  MotorComprehensive = 1,
  All = 2,
  Home = 3,
  Contents = 4,
  HomeAndContents = 5,
  MotorThirdPartyLiability = 6,
  MotorFireTheftAndThirdPartyLiability = 7,
  PetComp = 8,
}

export enum InsuranceQuotePhase {
  Unknown = 0,
  QuickQuote = 1,
  FullQuote = 2,
  MidtermPolicyChange = 3,
  RenewalPolicyChange = 4,
}

export enum PaymentFrequency {
  Annually = 1,
  Monthly = 2,
}

export enum ChangeState {
  Unchanged = 0,
  Changed = 1,
  BusinessRuleComplete = 2,
  UsedInQuote = 3,
}

export interface Coverage extends BaseTrackableObject {
  coverageId: string;
  coverageType: CoverageType;
  coverageDiscounts: CoverageDiscounts;
}

export interface CoverageDiscounts extends BaseTrackableObject {
  yearlyOnlineDiscountAmount: TrackChangeOfNullableNumber;
  monthlyOnlineDiscountAmount: TrackChangeOfNullableNumber;
}

export enum CoverageType {
  MotorComprehensive = 1,
  MotorThirdPartyLiability = 2,
  MotorFireTheftAndThirdPartyLiability = 3,
  Home = 4,
  Contents = 5,
  PetComprehensive = 6,
}
