import { showTooltipOnTriggerClick } from './renderTooltip';
import { nextTick } from 'vue';

/**
 * Custom plugin to integrate tooltips into the Vue Formkit library.
 * Tooltips will be shown in the form input 'suffixIcon' slot.
 *
 * To make this solution work, this code leverages Formkit Schemas.
 * Read more about Schemas: https://formkit.com/essentials/schema
 *
 */
function formkitTooltipPlugin(node: any) {
  node.on('created', async () => {
    // extract tooltip attrs from node and set as props
    node.addProps(['tooltip', 'type']);

    if (!node.props?.tooltip?.content) return;

    const focus = node.props.onFocus;

    // Handle tooltip visibility on focus of an input
    node.context.handlers.focus = (e: FocusEvent) => {
      focus?.(e);
    };

    const trigger = (event: Event) => {
      const content = node.props.tooltip?.content ?? '';
      const title = node.props.tooltip?.title ?? '';

      if (node.props.tooltip?.isModal) {
        content && (window as any)?.showRACQTooltipModal(title, content);
      } else {
        showTooltipOnTriggerClick(event, content);
        event.preventDefault();
      }
    };

    await nextTick();
    let labelType = 'legend';
    if (
      node.type !== 'radio' &&
      node.type !== 'checkbox' &&
      node.context?.type !== 'radio' &&
      node.context?.type !== 'checkbox'
    ) {
      labelType = 'label';
    }
    const input =
      document.querySelector(`*[data-id='${node.props.id}'] ${labelType}`) ||
      document.querySelector(`#${node.props.id}__outer ${labelType}`) ||
      document.querySelector(`#${node.props.id} ${labelType}`) ||
      document.querySelector(`label[for=${node.props.id}]`);

    const questionMark = document.createElement('span');
    questionMark.className = 'questionmark-icon js-tooltip-trigger';
    questionMark.id = node.props.id + '_tooltip_trigger';
    questionMark.onclick = trigger;
    input?.append(questionMark);
  });
}

export default formkitTooltipPlugin;
