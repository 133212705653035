import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "text-racq-error-red text-sm font-semibold pb-6"
}

import { ref, watch } from 'vue';
import RadioButtonCard from './RadioButtonCard.vue'; // Import the RadioButtonCard component
import { IRadioButtonCard } from '@/interfaces/entities/radio-button-card.interface';
import Tag from '../Tag/Tag.vue';
import { TagTypes } from '@/enums/tag-enums';

interface RadioButtonCardGroupProps {
  options?: Array<IRadioButtonCard>;
  initialValue?: string | null;
  validationMessage?: string;
  radioGroupClass?: string;
  showValidationError?: boolean;
  leftTitleClass?: string;
  node?: any;
  bindings?: any;
  value?: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioButtonCardGroup',
  props: {
    context: {},
    modelValue: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const selectedOption = ref(props.context?.initialValue || null);

// Watch for changes to update parent modelValue
watch(selectedOption, (newValue) => {
  props.context?.node?.input(newValue);
  emit('update:modelValue', newValue);
});

watch(
  [() => props.context?.initialValue, () => props.context?.value],
  ([newOption1, newOption2]) => {
    if (newOption1) {
      selectedOption.value = newOption1;
    }
    if (newOption2) {
      selectedOption.value = newOption2;
    }
  },
  { immediate: true, deep: true }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.context.showValidationError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.context.validationMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["radio-button-card-group flex flex-col flex-wrap gap-3", _ctx.context.radioGroupClass])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.context.options, (option, index) => {
        return (_openBlock(), _createBlock(RadioButtonCard, {
          key: option.value + index.toString(),
          modelValue: selectedOption.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedOption).value = $event)),
          groupName: option.groupName,
          callOutTag: option.callOutTag,
          bodyImage: option.bodyImage,
          bodyImageAlt: option.bodyImageAlt,
          bodyIcon: option.bodyIcon,
          bodyIconAlt: option.bodyIconAlt,
          value: option.value,
          leftTitle: option.leftTitle,
          leftTitleClass: _ctx.context.leftTitleClass,
          leftDescription: option.leftDescription,
          showLeftRadioButton: option.showLeftRadioButton,
          rightTitle: option.rightTitle,
          rightDescription: option.rightDescription,
          showRightRadioButton: option.showRightRadioButton,
          visibleBodyDescription: option.visibleBodyDescription,
          hiddenBodyDescription: option.hiddenBodyDescription,
          showMoreLinkLabel: option.showMoreLinkLabel,
          showLessLinkLabel: option.showLessLinkLabel,
          bindings: _ctx.context.bindings,
          innerClass: option.innerClass,
          outerClass: option.outerClass,
          bodyTopClass: option.bodyTopClass,
          bodyBottomClass: option.bodyBottomClass,
          bottomTagText: option.bottomTagText,
          showBottomTag: option.showBottomTag
        }, {
          bottomContent: _withCtx(() => [
            _createVNode(Tag, {
              tagType: _unref(TagTypes).discount,
              tagText: option.bottomTagText
            }, null, 8, ["tagType", "tagText"])
          ]),
          _: 2
        }, 1032, ["modelValue", "groupName", "callOutTag", "bodyImage", "bodyImageAlt", "bodyIcon", "bodyIconAlt", "value", "leftTitle", "leftTitleClass", "leftDescription", "showLeftRadioButton", "rightTitle", "rightDescription", "showRightRadioButton", "visibleBodyDescription", "hiddenBodyDescription", "showMoreLinkLabel", "showLessLinkLabel", "bindings", "innerClass", "outerClass", "bodyTopClass", "bodyBottomClass", "bottomTagText", "showBottomTag"]))
      }), 128))
    ], 2)
  ]))
}
}

})