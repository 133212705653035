<template>
  <div
    v-for="(option, index) of context?.options"
    :key="index"
    :class="['flex items-center mt-3', index < context?.options.length - 1 ? 'mb-3' : '']"
  >
    <input
      :id="context?.id + index"
      type="radio"
      :checked="option.value == context?.value"
      :value="option.value"
      :name="context?.id"
      class="w-5 h-5"
      @input="emitChange"
    />
    <label :for="context?.id + index" class="ms-3 text-base text-racq-dark-grey">{{ option.label }}</label>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  context: Object,
});

function emitChange(event: any) {
  props.context?.node.input(event?.target?.value);
}
</script>
