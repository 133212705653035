<template>
  <FormKit
    v-bind="bindings"
    v-model="value"
    :options="fetchSuburbsList"
    messagesClass="max-sm:ml-0"
    :innerClass="innerClass"
    :labelClass="labelClass"
    @input="handleSelection"
  />
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { AddressLookupService } from '@/services/store';
import type { IDropdownItem } from '@/interfaces';

const emit = defineEmits(['selected']);

const props = defineProps<{
  bindings: any;
  selectedSuburb: string;
  innerClass: string;
  labelClass: string;
}>();

const value = ref<string>(props.selectedSuburb);

watch(
  () => props.selectedSuburb,
  (newValue, oldValue) => {
    if (!oldValue && newValue) {
      value.value = newValue;
    }
  }
);

async function fetchSuburbsList({ search }: { search: string }): Promise<IDropdownItem[]> {
  try {
    if (!search || search.length < 3) return [];

    const data = await AddressLookupService.getAddressLookupSuburbs(search);

    return data.map((item: any) => ({
      label: item.suburb,
      value: item.suburb,
    }));
  } catch (error) {
    console.error('Unable to fetch suburbs list: ', error);
    return [];
  }
}

function handleSelection(suburb: string) {
  emit('selected', suburb);
}
</script>

<style scoped></style>
