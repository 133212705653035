import {
  CreateRoadsideJobRequest,
  getRoadsideProductCodeToProductId,
} from '@/interfaces/entities/jor-create-rsa-job.interface';
import { PartyMatchStatus } from '@/interfaces/entities/roadside-assistance.interface';
import { JoinOnRoadDetails } from '@/interfaces/pages/join-on-road.interface';
import { joinAddressComponents } from '@/mappers';
import { AccountServices as _account } from '@/services/store/account.services';
import { JoinOnRoadService as _joinOnRoadService } from '@/services/store/join-on-road.service';
import { RoadsideAssistanceService as _roadsideAssistanceService } from '@/services/store/roadside-assistance.service';

export default {
  // Step 1 (Breakdown details)
  enterComments: 'enterComments',

  // Step 1 (Breakdown details) - Breakdown cause
  causeValidationError: 'causeValidationError',

  // Step 2 (Vehicle details)
  registrationNumber: 'registrationNumber',
  findCarBtn: 'findCarBtn',
  cantFindCarMessage: 'cantFindCarMessage',
  regoResultsStepContent: 'regoResultsStepContent',
  makeAndModelResultsStepContent: 'makeAndModelResultsStepContent',
  findCar: 'findCar',

  // Step 3 (Your Details) - Personal details
  titleName: 'titleName',
  birthDate: 'birthDate',
  emailAddress: 'emailAddress',

  // Step 3 (Your Details)
  phoneNumber: 'phoneNumber',
  homeAddress: 'homeAddress',
  checkPostalAddress: 'checkPostalAddress',
  postalAddress: 'postalAddress',

  // Step 3 (Your Details) - Phone number today
  phoneNumberToday: 'phoneNumberToday',
  phoneNumberTodayDescription: 'phoneNumberTodayDescription',

  // Step 5 (Confirm Purchase)
  confirmPurchaseStepSettings: 'confirmPurchaseStepSettings',

  // Payment
  paymentProgressTracker: 'paymentProgressTracker',

  // Review
  reviewSummary: 'reviewSummary',
} as const;

function joinJorAddressComponents(
  country?: string,
  unitNumber?: string,
  streetNumber?: string,
  street?: string,
  suburb?: string,
  state?: string,
  postcode?: string
) {
  const addressParts = [];

  if (unitNumber && unitNumber.trim().length > 0) addressParts.push(unitNumber);
  if (streetNumber && streetNumber.trim().length > 0) addressParts.push(streetNumber);
  if (street && street.trim().length > 0) addressParts.push(street);
  if (suburb && suburb.trim().length > 0) addressParts.push(suburb);
  if (state && state.trim().length > 0) addressParts.push(state);
  if (postcode && postcode.trim().length > 0) addressParts.push(postcode);
  if (country && country.trim().length > 0) addressParts.push(country);

  return `${addressParts.shift()} ${addressParts.join(', ')}`;
}

export async function createRoadsideJobRequest(
  joinOnRoadDetails: JoinOnRoadDetails,
  apttusId: string | null,
  policyNumber: string | null,
  assetId: string | null,
  loyaltyLevel: string
): Promise<CreateRoadsideJobRequest> {
  // When party match status is Exact, need to lookup the existing user to retrieve loyaltyType and address.
  // Otherwise treat the loyalty level as Blue and the address as entered by the user.
  let address = joinJorAddressComponents(
    joinOnRoadDetails.homeAddress?.country ?? '',
    joinOnRoadDetails.homeAddress?.unitNumber ?? '',
    joinOnRoadDetails.homeAddress?.streetNumber ?? '',
    joinOnRoadDetails.homeAddress?.street ?? '',
    joinOnRoadDetails.homeAddress?.suburb ?? '',
    joinOnRoadDetails.homeAddress?.state ?? '',
    joinOnRoadDetails.homeAddress?.postcode ?? ''
  );

  if (joinOnRoadDetails.partyMatchStatus === PartyMatchStatus.Exact) {
    const residentialAddressResponse = await _account.getResidentialAddress(null, 'apttus', apttusId);
    if (residentialAddressResponse) {
      address = joinJorAddressComponents(
        residentialAddressResponse.country ?? '',
        residentialAddressResponse.unit ?? '',
        residentialAddressResponse.streetNumber ?? '',
        residentialAddressResponse.street ?? '',
        residentialAddressResponse.suburb ?? '',
        residentialAddressResponse.state ?? '',
        residentialAddressResponse.postcode ?? ''
      );
    }
  }

  return {
    channel: 'HTML JOR',
    policyNumber: policyNumber!,
    comments: [joinOnRoadDetails.comments],
    triageAnswers: {
      SAFE_LOCATION: ['NO'],
      BREAKDOWN_REASON: [joinOnRoadDetails.breakdownCause],
    },
    membership: {
      roadsideProductId: getRoadsideProductCodeToProductId(joinOnRoadDetails.jorSelectedProduct!)!,
      membershipCardNumber: assetId!,
      lastName: joinOnRoadDetails.lastName,
      firstName: joinOnRoadDetails.firstName,
      contact: {
        phone: joinOnRoadDetails.phoneNumberToday,
      },
      address: address,
      loyaltyLevel: loyaltyLevel,
    },
    vehicle: {
      colour: joinOnRoadDetails.vehicleColour,
      make: joinOnRoadDetails.vehicleMake,
      model: joinOnRoadDetails.vehicleModel,
      registration: joinOnRoadDetails.vehicleRegistration,
      buildYear: parseInt(joinOnRoadDetails.vehicleYear, 10),
      location: {
        latitude: joinOnRoadDetails.breakdownLocationLatitude,
        longitude: joinOnRoadDetails.breakdownLocationLongitude,
        comment: joinOnRoadDetails.breakdownLocationAddress,
      },
    },
  };
}
