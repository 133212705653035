import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { AccountServices as _account } from '@/services/store/account.services';
import { localURL } from '@/constants/forms-api.const';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { IdentityDetails } from '@/interfaces';

function urlUpdateForLocal(url: string) {
  const urlObject = new URL(url);
  const search_params = urlObject.searchParams;

  search_params.set('redirect_uri', localURL);
  urlObject.search = search_params.toString();
  return urlObject.toString();
}

export const useAccountStore = defineStore('Account', () => {
  const loginVisible = ref(false);
  const identityDetails = ref<Partial<IdentityDetails>>();
  const prefillForm = ref(false);
  const partyMatched = ref(false);

  const isAuthenticated = computed(() => _account.isAuthenticated());

  async function handleAuthenticated(route: RouteLocationNormalizedLoaded) {
    if (route?.query?.state) {
      const url = (route.query.state as string)?.split(';')[1]; //redirect back to form
      window.location.href = url;
    } else {
      await fetchIdentityDetails();
    }
  }

  async function handleUnauthenticated(route: RouteLocationNormalizedLoaded) {
    if (route?.query.code) {
      const response = await _account.authenticate(route.query.code as string);
      if (response.success) {
        loginVisible.value = false;
        const url = (route.query.state as string)?.split(';')[1]; // redirect back to form
        window.location.href = url;
      } else {
        throw response.error;
      }
    } else {
      let response = await _account.getLoginUrl(window.location.toString());
      if (process.env.NODE_ENV != 'production') {
        response = urlUpdateForLocal(response);
      }
      window.location.href = response;
    }
  }

  /**
   * Get Identity Details
   */
  const fetchIdentityDetails = async () => {
    const res = await _account.fetchIdentityDetails();
    identityDetails.value = res;
    prefillForm.value = true;
  };

  const fetchLoyaltyLevel = async (
    membershipCardNumber: string | null,
    externalSystem: string | null,
    externalSystemId: string | null
  ) => {
    const response = await _account.getLoyaltyType(membershipCardNumber, externalSystem, externalSystemId);
    return response;
  };

  /**
   * Back to account overview
   */
  const cancelForm = async () => {
    try {
      const url = await _account.getSelfServiceAccountOverviewPage();
      window.location.href = url;
    } catch (error) {
      console.warn('Unable to retrieve account overview URL: ', error);
    }
  };

  /**
   * Check authenticated state and callback
   */
  const authenticateOrCallBack = async (callBackIfAuthenticated: () => void) => {
    const isAuthenticated = await _account.isAuthenticated();
    if (!isAuthenticated) {
      let response = await _account.getLoginUrl(window.location.toString());
      if (process.env.NODE_ENV != 'production') {
        response = urlUpdateForLocal(response);
      }
      window.location.href = response;
    } else {
      callBackIfAuthenticated();
    }
  };

  /**
   * Check Login Status
   */
  const authenticate = async (route: RouteLocationNormalizedLoaded) => {
    // Check for logout
    if (route?.fullPath.toLowerCase().includes('logout')) {
      const logoutUrl = await _account.logOut();
      if (window && window.location) window.location.href = logoutUrl;
    }
    // Validate authentication
    else {
      const isAuthenticated = await _account.isAuthenticated();
      loginVisible.value = !isAuthenticated;
      // Redirect to login page if unauthenticated
      if (!isAuthenticated) {
        handleUnauthenticated(route);
      } else {
        handleAuthenticated(route);
      }
    }
  };

  const togglePrefillDetails = () => {
    prefillForm.value = !prefillForm.value;
  };

  const setIdentityDetailsFirstName = (firstName: string) => {
    if (!identityDetails.value) identityDetails.value = {};
    identityDetails.value.firstName = firstName;
  };

  const setIdentityDetailsD365Id = (d365Id: string) => {
    if (!identityDetails.value) identityDetails.value = {};
    identityDetails.value.d365Id = d365Id;
  };

  const setIdentityDetailsLoyaltyLevel = (loyaltyLevel: string) => {
    if (!identityDetails.value) identityDetails.value = {};
    if (!identityDetails.value.membershipLoyalty) {
      identityDetails.value.membershipLoyalty = loyaltyLevel;
    }
  };

  return {
    loginVisible,
    identityDetails,
    prefillForm,
    partyMatched,
    fetchIdentityDetails,
    fetchLoyaltyLevel,
    cancelForm,
    authenticateOrCallBack,
    authenticate,
    isAuthenticated,
    togglePrefillDetails,
    setIdentityDetailsFirstName,
    setIdentityDetailsD365Id,
    setIdentityDetailsLoyaltyLevel,
  };
});
