import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "racq-dropdown" }
const _hoisted_2 = { class: "flex justify-between items-center w-full" }
const _hoisted_3 = {
  class: "p-1",
  role: "menu"
}
const _hoisted_4 = ["onClick"]

import { ref, onMounted, onBeforeUnmount, computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RACQDropdown',
  props: {
    context: {}
  },
  setup(__props: any) {

const props = __props;

const isOpen = ref(false);
const selectedOption = ref(props.context?.value || null);

const isSlim = computed(() => {
  return props.context?.attrs?.classesOptions === 'dropdown-slim';
});

const isDisabled = computed(() => {
  return props.context?.attrs?.classesOptions === 'dropdown-disabled';
});

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const selectOption = (option: any) => {
  selectedOption.value = option.label;
  isOpen.value = false; // Close dropdown
  emitChange(option);
};

// Emit change to FormKit
const emitChange = (value: any) => {
  props.context?.node.input(value);
};

// Close dropdown when clicking outside
const closeDropdown = (e: MouseEvent) => {
  if (!(e.target as HTMLElement).closest('.racq-dropdown')) {
    isOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', closeDropdown);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', closeDropdown);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["racq-dropdown__button", {
        'racq-dropdown__button--slim': isSlim.value,
        'racq-dropdown__button--disabled': isDisabled.value,
      }]),
      onClick: _withModifiers(toggleDropdown, ["stop"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", null, _toDisplayString(_ctx.context.attrs.keepPlaceHolder ? _ctx.context.attrs.placeholder : (selectedOption.value ?? _ctx.context.attrs.placeholder)), 1),
        (_openBlock(), _createElementBlock("svg", {
          class: _normalizeClass(["racq-dropdown__icon", {
            'racq-dropdown__icon--disabled': isDisabled.value,
          }]),
          width: "16",
          height: "16",
          viewBox: "0 0 16 16",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("path", {
            d: "M3 5L8 10L13 5",
            stroke: "currentColor",
            "stroke-width": "2"
          }, null, -1)
        ]), 2))
      ])
    ], 2),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["racq-dropdown__menu", {
        'racq-dropdown__menu--slim': isSlim.value,
      }])
    }, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.context.options, (option, index) => {
          return (_openBlock(), _createElementBlock("button", {
            key: index,
            type: "button",
            class: "racq-dropdown__option",
            onClick: _withModifiers(($event: any) => (selectOption(option)), ["stop"])
          }, _toDisplayString(option.label), 9, _hoisted_4))
        }), 128))
      ])
    ], 2), [
      [_vShow, isOpen.value && !isDisabled.value]
    ])
  ]))
}
}

})