import { IQuote } from '@/interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { cloneDeep } from 'lodash-es';

export const useIQOriginalQuoteStore = defineStore('IQOriginalQuote', () => {
  const quote = ref<Partial<IQuote>>();

  /**
   * Will only set the quote once.
   */
  function setQuote(updateQuote: Partial<IQuote>) {
    if (!quote.value) {
      quote.value = cloneDeep(updateQuote);
    }
  }

  return {
    setQuote,
    quote,
  };
});
