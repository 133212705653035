<template>
  <div :class="bindings.outerClass">
    <RichText v-if="bindings.preContent" :class="bindings.preContentClass" :field="{ value: bindings.preContent }" />
    <FormKit v-bind="extendedBindings" v-model="model" :innerClass="bindings.innerClass || 'input-sm'">
      <template v-if="bindings.suffixIcon" #suffixIcon="context">
        <RichText :field="{ value: context.suffixIcon ?? '' }" />
      </template>
      <template #prevIcon>
        <BaseIcon iconClass="bg-datepicker-prev-icon" />
      </template>
      <template #nextIcon>
        <BaseIcon iconClass="bg-datepicker-next-icon" />
      </template>
      <template #calendarIcon> <BaseIcon iconClass="bg-datepicker-calendar-icon" /> </template>
    </FormKit>
    <RichText class="formkit-help text-sm text-racq-dark-grey mt-4" :field="{ value: helpTextTokenReplaced }" />
    <RichText
      v-if="bindings.postContent && bindings.showPostContent"
      :class="bindings.postContentClass"
      :field="{ value: bindings.postContent }"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { FormKit } from '@formkit/vue';
import { STANDARD_DATE_FORMAT } from '@/constants/formats';
import { format } from 'date-fns';
import type { VueFormInputProps } from '@/composables/useFormInputData';
import BaseIcon from '@/components/BaseIcon/BaseIcon.vue';

const props = defineProps<{
  bindings: VueFormInputProps;
  minDateToday?: boolean;
  maxDateByDays?: number;
  testDate?: Date;
}>();

let minDate: Date | null = null;
let maxDate: Date | null = null;

if (props.maxDateByDays) {
  maxDate = props.testDate ? new Date(props.testDate.getTime()) : new Date();
  maxDate.setDate(maxDate.getDate() + props.maxDateByDays);
}

if (props.minDateToday) {
  minDate = props.testDate ? new Date(props.testDate.getTime()) : new Date();
  minDate.setHours(0, 0, 0, 0);
}

const model = defineModel<Date | null>({ required: false, default: new Date() });

const extendedBindings = computed(() => {
  return {
    ...props.bindings,
    minDate,
    maxDate,
  };
});

const helpTextTokenReplaced = computed(() => {
  if (maxDate) {
    return props.bindings.helpSm?.replace('{maxDate}', format(maxDate, STANDARD_DATE_FORMAT));
  }
  return props.bindings.helpSm;
});
</script>
