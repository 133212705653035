<!-- THIS IS PART OF FindCar.vue -->
<template>
  <div class="find-your-car-wrapper flex flex-col gap-3">
    <FormKit
      v-model="vehicleSelection.year"
      :label="yearLabel"
      wrapperClass="flex flex-col gap-1"
      outerClass="input-xs2 !h-fit"
      validation="required"
      clearSearchOnOpen
      openOnFocus
      type="autocomplete"
      popover
      :options="vehicleYearsList"
      :loading="vehicleSelectionLoading.year"
      @input="(val: string) => handleDropdownChange('year', val)"
      :placeholder="DROPDOWN_PLACEHOLDER"
    />

    <template v-if="vehicleSelection.year !== 'Before 1960'">
      <FormKit
        v-model="vehicleSelection.make"
        :label="makeLabel"
        outerClass="input-md !h-fit"
        type="autocomplete"
        validation="required"
        clearSearchOnOpen
        openOnFocus
        :options="dynamicMakesList"
        :disabled="!vehicleSelection.year || vehicleSelectionLoading.year"
        :loading="vehicleSelectionLoading.make"
        @input="(val: string) => handleDropdownChange('make', val)"
        :placeholder="DROPDOWN_PLACEHOLDER"
      >
        <!--OPTION SLOT-->
        <template #option="{ option }">
          <div class="text-black px-2 py-1 hover:bg-racq-light-blue-grey">
            <span v-if="option.label">
              {{ option.label }}
            </span>
            <hr v-else />
          </div>
        </template>
        <!--OPTION SLOT-->
      </FormKit>

      <FormKit
        v-model="vehicleSelection.model"
        :label="modelLabel"
        outerClass="input-sm !h-fit"
        type="autocomplete"
        validation="required"
        clearSearchOnOpen
        openOnFocus
        :options="vehicleModelsList"
        :disabled="!vehicleSelection.make || vehicleSelectionLoading.make"
        :loading="vehicleSelectionLoading.model"
        @input="(val: string) => handleDropdownChange('model', val)"
        :placeholder="DROPDOWN_PLACEHOLDER"
      />

      <FormKit
        v-model="vehicleSelection.series"
        :label="seriesLabel"
        outerClass="input-sm !h-fit"
        type="autocomplete"
        validation="required"
        clearSearchOnOpen
        openOnFocus
        :options="vehicleSeriesList"
        :disabled="!vehicleSelection.model || vehicleSelectionLoading.model"
        :loading="vehicleSelectionLoading.series"
        @input="(val: string) => handleDropdownChange('series', val)"
        :placeholder="DROPDOWN_PLACEHOLDER"
      />

      <FormKit
        v-if="vehicleSelection.series !== 'ALL'"
        v-model="vehicleSelection.body"
        :label="bodyLabel"
        outerClass="input-xs2 !h-fit"
        type="autocomplete"
        validation="required"
        clearSearchOnOpen
        openOnFocus
        :options="vehicleBodiesList"
        :disabled="!vehicleSelection.series || vehicleSelectionLoading.series"
        :loading="vehicleSelectionLoading.body"
        @input="(val: string) => handleDropdownChange('body', val)"
        :placeholder="DROPDOWN_PLACEHOLDER"
      />

      <FormKit
        v-if="showColour"
        v-model="vehicleSelection.colour"
        :label="colourLabel"
        outerClass="input-xs2 !h-fit"
        type="text"
        clearSearchOnOpen
        validation="required:trim"
        openOnFocus
        :disabled="isColourDisabled()"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, reactive, defineExpose, watch } from 'vue';
import useVehicleData from '@/composables/useVehicleData';
import type { IDropdownItem, IFoundVehicle } from '@/interfaces';
import type { IVehicleOptions, IVehicleLoadingOptions } from '@/composables/useVehicleData';
import { FormKit } from '@formkit/vue';
import { DROPDOWN_PLACEHOLDER } from '@/constants/general-words.const';

// Define types and interfaces
// ------------------------------------------------------------- //
type vehicleSelectionTypes = 'year' | 'make' | 'model' | 'series' | 'body';

// Define Props & Emitters
// ------------------------------------------------------------- //
const props = defineProps<{
  topMakes?: string;
  showColour?: boolean;
  colourLabel?: string;
  yearLabel: string;
  makeLabel: string;
  modelLabel: string;
  seriesLabel: string;
  bodyLabel: string;
}>();

const emit = defineEmits(['vehicleFound', 'modelMakeChanged', 'setColour']);
defineExpose({
  resetVehicleSelection,
});

// Define Composables
// ------------------------------------------------------------- //
const {
  fetchVehicleYearsList,
  fetchVehicleMakesByYear,
  fetchModelsByYearAndMake,
  fetchVehicleVariantsByYearMakeModel,
  fetchVehicleDetailsByYearMakeModelVariantBody,
  fetchVehicleBodies,
} = useVehicleData();

// Define Component state
// ------------------------------------------------------------- //
const vehicleYearsList = ref<IDropdownItem[]>([]);
const vehicleMakesList = ref<IDropdownItem[]>([]);
const vehicleModelsList = ref<IDropdownItem[]>([]);
const vehicleSeriesList = ref<IDropdownItem[]>([]);
const vehicleBodiesList = ref<IDropdownItem[]>([]);
const availableVehiclesList = ref<IFoundVehicle[]>([]);

const vehicleSelection = reactive<IVehicleOptions>({
  year: '',
  make: '',
  model: '',
  series: '',
  body: '',
  colour: '',
});

const vehicleSelectionLoading = reactive<IVehicleLoadingOptions>({
  year: false,
  make: false,
  model: false,
  series: false,
  body: false,
  colour: false,
});

// Define Lifecycle hooks and exectute init functions
// ------------------------------------------------------------- //
onBeforeMount(async () => {
  vehicleYearsList.value = await fetchVehicleYearsList();
});

// Call refreshData only when stepActive becomes true
watch(vehicleSelection, (newVal) => {
  if (vehicleSelection?.colour) {
    emit('setColour', vehicleSelection?.colour);
  }
});

// Define functions
// ------------------------------------------------------------- //

const isColourDisabled = (): boolean => {
  if (vehicleSelection.series !== 'ALL') {
    return (!vehicleSelection.body || vehicleSelectionLoading?.body) as boolean;
  }
  return false;
};

const dynamicMakesList = ({ search }: { search: string }) => {
  if (search) {
    return vehicleMakesList.value.filter((option) => {
      return option.label?.toUpperCase().includes(search.toUpperCase());
    });
  } else {
    // Top Make
    try {
      if (props.topMakes) {
        const topMakes = props.topMakes
          .split('|')
          .map((make) => vehicleMakesList.value.find((option) => option.label.toUpperCase() == make.toUpperCase()))
          .filter((option) => option);
        return [...topMakes, { label: '', value: '' }, ...vehicleMakesList.value];
      }
    } catch (error) {
      console.warn('Unable to load top makes: ', error);
    }
    return vehicleMakesList.value;
  }
};

function resetVehicleSelection() {
  vehicleSelection.year = '';
  vehicleSelection.make = '';
  vehicleSelection.model = '';
  vehicleSelection.series = '';
  vehicleSelection.body = '';
  vehicleSelection.colour = '';
}

/**
 * Handle dropdown change by updating ref data
 *
 * @param dropdown
 * @param value
 */
async function handleDropdownChange(dropdown: vehicleSelectionTypes, value: string) {
  vehicleSelection[dropdown] = value;
  emit('modelMakeChanged');
  switch (dropdown) {
    case 'year': {
      vehicleSelection.make = '';
      vehicleSelection.model = '';
      vehicleSelection.series = '';
      vehicleSelection.body = '';

      vehicleMakesList.value = [];
      vehicleModelsList.value = [];
      vehicleSeriesList.value = [];
      vehicleBodiesList.value = [];
      availableVehiclesList.value = [];

      if (value === 'Before 1960') {
        emit('vehicleFound', [{ manufacturingYear: 1900 }]);
        return;
      }

      vehicleSelectionLoading.year = true;
      const vehicleMakesByYear = await fetchVehicleMakesByYear(value).finally(
        () => (vehicleSelectionLoading.year = false)
      );
      vehicleMakesList.value = vehicleMakesByYear;

      break;
    }

    case 'make': {
      vehicleSelection.model = '';
      vehicleSelection.series = '';
      vehicleSelection.body = '';

      vehicleModelsList.value = [];
      vehicleSeriesList.value = [];
      vehicleBodiesList.value = [];
      availableVehiclesList.value = [];

      vehicleSelectionLoading.make = true;
      const vehicleModels = await fetchModelsByYearAndMake(vehicleSelection.year, vehicleSelection.make).finally(
        () => (vehicleSelectionLoading.make = false)
      );
      vehicleModelsList.value = vehicleModels;

      break;
    }

    case 'model': {
      vehicleSelection.series = '';
      vehicleSelection.body = '';

      vehicleSeriesList.value = [];
      vehicleBodiesList.value = [];
      availableVehiclesList.value = [];

      vehicleSelectionLoading.model = true;
      const vehicleVariants = await fetchVehicleVariantsByYearMakeModel(
        vehicleSelection.year,
        vehicleSelection.make,
        vehicleSelection.model
      ).finally(() => (vehicleSelectionLoading.model = false));

      vehicleSeriesList.value = [{ label: 'All vehicles', value: 'ALL' }, ...vehicleVariants];

      break;
    }

    case 'series': {
      vehicleSelection.body = '';

      vehicleBodiesList.value = [];
      availableVehiclesList.value = [];

      if (!value) {
        return;
      }

      if (vehicleSelection.series === 'ALL') {
        const possibleVehiclesData = await fetchVehicleDetailsByYearMakeModelVariantBody(vehicleSelection);
        emit('vehicleFound', possibleVehiclesData);

        return;
      }

      vehicleSelectionLoading.series = true;
      const vehicleBodies = await fetchVehicleBodies(vehicleSelection).finally(
        () => (vehicleSelectionLoading.series = false)
      );
      vehicleBodiesList.value = vehicleBodies;
      break;
    }

    case 'body':
      availableVehiclesList.value = [];

      if (!value) {
        return;
      }

      vehicleSelectionLoading.body = true;
      // eslint-disable-next-line no-case-declarations
      const possibleVehiclesData = await fetchVehicleDetailsByYearMakeModelVariantBody(vehicleSelection).finally(
        () => (vehicleSelectionLoading.body = false)
      );
      availableVehiclesList.value = possibleVehiclesData;

      // emit results for parent to consume
      emit('vehicleFound', possibleVehiclesData);
      break;
  }
}
</script>

<style scoped>
.find-your-car-wrapper svg {
  background-position: 0;
  display: block;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;charset%3DUS-ASCII,%3Csvg%20baseProfile%3D%22tiny%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23727272%22%20d%3D%22M8.922%2011.828l-5.42-5.656h10.997L8.92%2011.828z%22%2F%3E%3C%2Fsvg%3E') !important;
}
</style>
