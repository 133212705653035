<template>
  <div class="relative">
    <div v-if="isMasked" class="absolute inset-x-0 inset-y-0 bg-curtain-color z-[100]"></div>
    <div class="payment-method-container flex flex-col">
      <!-- FZ IFRAME START -->
      <div v-if="acceptCardPayment">
        <div
          class="card-type-section flex space-between items-center border-b py-4"
          :class="paymentTypeSelectable && 'hover:bg-racq-white-background cursor-pointer'"
          @click="() => changePaymentMethod(PaymentTypes.CreditCard)"
        >
          <div
            v-if="paymentTypeSelectable"
            class="h-[20px] w-[20px] rounded-full me-3"
            :class="
              selectedPaymentType == PaymentTypes.CreditCard
                ? 'bg-racq-blue-active'
                : 'border-2 border-racq-light-blue-grey'
            "
          >
            <img src="@/assets/icons/white-tick.svg" alt="tick svg" class="mx-[4px] my-[5px]" />
          </div>
          <RichText v-if="CardTypeTitle?.value" :field="CardTypeTitle" class="card-type-title text-base-bold" />
          <RichText
            v-if="ShowCardIcons?.value && CardIcons?.value"
            class="card-type-icons flex ml-auto gap-2 h-6"
            :field="CardIcons"
          />
        </div>
      </div>
      <!-- FZ IFRAME START -->

      <!-- BANK ACCOUNT START -->
      <div v-if="acceptBankAccount">
        <div
          class="card-type-section flex space-between items-center border-b py-4"
          :class="paymentTypeSelectable && 'hover:bg-racq-white-background cursor-pointer'"
          @click="() => changePaymentMethod(PaymentTypes.BankAccount)"
        >
          <div
            v-if="paymentTypeSelectable"
            class="h-[20px] w-[20px] rounded-full me-3"
            :class="
              selectedPaymentType == PaymentTypes.BankAccount
                ? 'bg-racq-blue-active'
                : 'border-2 border-racq-light-blue-grey'
            "
          >
            <img src="@/assets/icons/white-tick.svg" alt="tick svg" class="mx-[4px] my-[5px]" />
          </div>
          <RichText v-if="BankAccountTitle?.value" :field="BankAccountTitle" class="card-type-title text-base-bold" />
          <RichText
            v-if="BankAccountIcon?.value"
            class="card-type-icons flex ml-auto gap-2 h-6"
            :field="BankAccountIcon"
          />
        </div>
      </div>
      <!-- BANK ACCOUNT END -->

      <!-- PAY LATER START -->
      <div v-if="acceptPayLater">
        <div
          class="card-type-section flex space-between items-center border-b py-4"
          :class="paymentTypeSelectable && 'hover:bg-racq-white-background cursor-pointer'"
          @click="() => changePaymentMethod(PaymentTypes.PayLater)"
        >
          <div
            v-if="paymentTypeSelectable"
            class="h-[20px] w-[20px] rounded-full me-3"
            :class="
              selectedPaymentType == PaymentTypes.PayLater
                ? 'bg-racq-blue-active'
                : 'border-2 border-racq-light-blue-grey'
            "
          >
            <img src="@/assets/icons/white-tick.svg" alt="tick svg" class="mx-[4px] my-[5px]" />
          </div>
          <RichText v-if="PayLaterTitle?.value" :field="PayLaterTitle" class="card-type-title text-base-bold" />
          <RichText v-if="PayLaterIcon?.value" class="card-type-icons flex ml-auto gap-2 h-6" :field="PayLaterIcon" />
        </div>
      </div>
      <!-- PAY LATER END -->

      <!-- PAYMENT INPUTS START -->
      <div>
        <div
          v-if="acceptCardPayment"
          class="iframe-section ease-in-out duration-500 transition-all my-6 max-h-[1000px]"
          :class="selectedPaymentType != PaymentTypes.CreditCard && '!overflow-hidden !max-h-[0px] !my-0 opacity-0'"
        >
          <slot>
            <PaymentSectionFZIframe />
          </slot>
          <RichText
            v-if="PaymentDisclaimer?.value"
            :field="PaymentDisclaimer"
            class="disclaimer-section flex flex-col text-sm font-normal"
          />
        </div>

        <div
          v-if="acceptBankAccount"
          class="ease-in-out duration-500 transition-all my-6 px-3 max-h-[1000px]"
          :class="selectedPaymentType != PaymentTypes.BankAccount && '!overflow-hidden !max-h-[0px] !my-0 opacity-0'"
        >
          <FormKit type="group" name="payment-section-bank-account">
            <template #default="{ state: { valid } }">
              <FormKit
                id="bank-account-account-name"
                ref="bankAccountName"
                type="text"
                :label="props.AccountNameLabel?.value"
                outerClass="mb-4"
                innerClass="input-md"
                name="bank-account-account-name"
                :tooltip="{
                  content: props.AccountNameTooltip?.value,
                  tooltipId: 'bank-account-account-name__tooltip_id',
                  tooltipOuterId: 'bank-account-account-name__outer',
                }"
                :validation="[['required'], ['matches', /^[a-zA-Z0-9 -]{1,100}$/]]"
                :validationMessages="{
                  required: props.AccountNameRequiredMessage?.value,
                  matches: props.AccountNameInvalidMessage?.value,
                }"
              />
              <FormKit
                id="bank-account-bsb"
                ref="bsbInput"
                type="mask"
                mask="###-###"
                :label="props.BsbLabel?.value"
                outerClass="mb-4"
                innerClass="input-xs"
                name="bank-account-bsb"
                :help="financialInstitutionName"
                :loading="checkingBsb"
                :validation="[['required'], ['matches', /^\d{3}-\d{3}$/], ['bsbLookup']]"
                :validationRules="{ bsbLookup }"
                :validationMessages="{
                  required: props.BsbRequiredMessage?.value,
                  matches: props.BsbInvalidMessage?.value,
                  bsbLookup: props.BsbNotFoundMessage?.value,
                }"
              >
                <template #messages="context">
                  <RichText
                    v-if="context.messages?.rule_bsbLookup?.value"
                    :class="context.classes?.message"
                    :field="{ value: replaceBsb(context.messages?.rule_bsbLookup?.value, context._value) }"
                  />
                  <RichText
                    v-if="context.messages?.rule_required?.value"
                    :class="context.classes?.message"
                    :field="context.messages?.rule_required"
                  />
                  <RichText
                    v-if="context.messages?.rule_matches?.value"
                    :class="context.classes?.message"
                    :field="context.messages?.rule_matches"
                  />
                </template>
              </FormKit>

              <FormKit
                id="bank-account-account-number"
                ref="bankAccountNumber"
                type="tel"
                :label="props.AccountNumberLabel?.value"
                outerClass="mb-4"
                innerClass="input-sm"
                name="bank-account-account-number"
                :validation="[['required'], ['matches', /^(\d{6,9})$/]]"
                :validationMessages="{
                  required: props.AccountNumberRequiredMessage?.value,
                  matches: props.AccountNumberInvalidMessage?.value,
                }"
              />

              <div class="flex w-full pt-4">
                <FormKit
                  type="submit"
                  :label="SubmitBankAccountLabel?.value"
                  inputClass="btn btn-primary btn-edit"
                  outerClass="ms-auto"
                  @click="() => valid && submitPayment(PaymentTypes.BankAccount)"
                />
              </div>
            </template>
          </FormKit>
        </div>

        <div
          v-if="acceptPayLater"
          class="ease-in-out duration-500 transition-all my-6 px-3 max-h-[1000px]"
          :class="selectedPaymentType != PaymentTypes.PayLater && '!overflow-hidden !max-h-[0px] !my-0 opacity-0'"
        >
          <RichText v-if="PayLaterContent?.value" :field="PayLaterContent" />
          <div class="flex w-full pt-4">
            <FormKit
              type="button"
              :label="SubmitPayLaterLabel?.value"
              inputClass="btn btn-secondary input-cta-arrow-right"
              @click="() => submitPayment(PaymentTypes.PayLater)"
            />
          </div>
        </div>
      </div>
      <!-- PAYMENT INPUTS END -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { computed, ref } from 'vue';
import PaymentSectionFZIframe from './PaymentSectionFZIframe.vue';
import { usePaymentStore } from '@/store';
import { IPaymentSectionProps } from '@/interfaces';
import { PaymentTypes } from '@/enums/payment-types.enum';
import { FormKit } from '@formkit/vue';

const props = defineProps<IPaymentSectionProps>();
const paymentStore = usePaymentStore();

const bankAccountName = ref<any>(null);
const bsbInput = ref<any>(null);
const bankAccountNumber = ref<any>(null);
const checkingBsb = ref<boolean>(false);

const acceptCardPayment = computed(() => {
  return props.AcceptPaymentTypes.some((type) => type.name == PaymentTypes[PaymentTypes.CreditCard]);
});

const acceptBankAccount = computed(() => {
  return props.AcceptPaymentTypes.some((type) => type.name == PaymentTypes[PaymentTypes.BankAccount]);
});

const acceptPayLater = computed(() => {
  return props.AcceptPaymentTypes.some((type) => type.name == PaymentTypes[PaymentTypes.PayLater]);
});

const isMasked = computed(() => paymentStore.disablePaymentPanel);

const paymentTypeSelectable = computed(() => {
  return props.AcceptPaymentTypes.length > 1;
});

const selectedPaymentType = computed(() => paymentStore.selectedPaymentMethod);

const financialInstitutionName = computed(() => {
  return paymentStore.bsbBranchDetails?.name
    ? `${paymentStore.bsbBranchDetails?.name}, ${paymentStore.bsbBranchDetails?.branch}`
    : undefined;
});

const bsbLookup = async function ({ value }: { value: string }) {
  checkingBsb.value = true;
  try {
    const bsb = value.replace('-', '');
    const res = await paymentStore.bsbLookup(bsb);
    return res;
  } catch (error) {
    return false;
  } finally {
    checkingBsb.value = false;
  }
};

const replaceBsb = (message: string, bsb: string) => {
  return message?.replace('{{bsb}}', bsb);
};

const submitPayment = async (paymentType: PaymentTypes) => {
  try {
    const bsb = bsbInput.value?.node?.value;
    const accountName = bankAccountName?.value?.node?.value;
    const accountNumber = bankAccountNumber?.value?.node?.value;

    if (bsb && accountName && accountNumber) {
      await paymentStore.setDirectDebitDetails(accountName, bsb, accountNumber);
    }

    paymentStore.submitPaymentCallback && paymentStore.submitPaymentCallback(paymentType);
  } catch (e) {
    console.error(e);
  }
};

/**
 * Change payment method handler
 *
 * @param paymentMethod
 */
const changePaymentMethod = (paymentMethod: PaymentTypes) => {
  if (!paymentTypeSelectable.value) return;
  paymentStore.changePaymentMethod(paymentMethod);
};
</script>
