import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer-container" }
const _hoisted_2 = { class: "forms-footer" }
const _hoisted_3 = { class: "top-footer-container" }
const _hoisted_4 = { class: "top-links" }
const _hoisted_5 = { class: "footer-logo" }
const _hoisted_6 = { class: "footer-copyright" }

import {
  Image as ScImage,
  Link as ScLink,
  ComponentFields,
  ComponentRendering,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Footer',
  props: {
    fields: {},
    rendering: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(Placeholder), {
            name: "jss-top-links",
            rendering: _ctx.rendering
          }, null, 8, ["rendering"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(ScLink), {
            field: _ctx.fields.racqWebsiteLink
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(ScImage), {
                media: _ctx.fields.racqLogo,
                alt: "RACQ Logo"
              }, null, 8, ["media"])
            ]),
            _: 1
          }, 8, ["field"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, "© RACQ Operations Pty Ltd " + _toDisplayString(new Date().getFullYear()), 1)
    ])
  ]))
}
}

})