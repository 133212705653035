<template>
  <metainfo>
    <template #title="{ content }">{{ content }}</template>
  </metainfo>
  <div>
    <div class="flex flex-col">
      <placeholder name="jss-header" :pageTitle="pageTitle" :rendering="route" />
      <Login v-if="performLogin" />
      <placeholder
        v-if="!accountStore.loginVisible"
        name="headless-main"
        :rendering="route"
        :errorComponent="NotFound"
      />
      <placeholder name="jss-footer" :rendering="route" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { Placeholder, Field, ComponentRendering } from '@sitecore-jss/sitecore-jss-vue';
import { useMeta } from 'vue-meta';
import Login from '@/components/Login/Login.vue';
import { useAccountStore } from '@/store';
import NotFound from './NotFound.vue';

const props = defineProps<{
  route: ComponentRendering;
}>();
const accountStore = useAccountStore();
const performLogin = computed(() => (props.route.fields?.AuthenticationRequired as Field)?.value);
const pageTitle = computed(
  () => (props.route.fields?.pageTitle as Field)?.value || (props.route.fields?.Title as Field)?.value || ''
);
useMeta({
  title: (props.route.fields?.pageTitle as Field)?.value || (props.route.fields?.Title as Field)?.value || 'Page',
});
</script>
