import { ref } from 'vue';
import { defineStore } from 'pinia';
import { RACQAddressResult, IRiskSuburbLookupOption } from '@/interfaces';
import { AddressLookupService } from '@/services/store';
import { AUSTRALIA } from '@/constants/general-words.const';
import { isEmptyObject } from '@/utils/check-helpers';
import { isPostalAddress as isPostalAddressCheck } from '@/utils/regex-helpers';

export const emitRiskSuburbSelected = 'riskSuburbSelected';
export const emitAddressSelected = 'addressSelected';
export const addressLookupEmits = [emitRiskSuburbSelected, emitAddressSelected];

export interface AddressComponentOptions {
  isPostalAddress?: boolean;
  allowPhysicalAddressesOnly?: boolean;
  allowOverseasAddress?: boolean;
  showPostalSameAsInsuredAddressSelection?: boolean;
  allowValidInsuranceRiskSuburbsOnly?: boolean;
  isCycloneCheckRequired?: boolean;
  addressOverride?: boolean; //TODO: Handle this
  testMode?: boolean; //TODO: Handle this
}

const _useAddressLookupOptionsStore = () => {
  const config = ref<AddressComponentOptions>();

  //Component selections
  const isOverseasSelection = ref<boolean>(false);
  const postalSameAsInsuredAddressSelection = ref<boolean>(false);
  const isCycloneCovered = ref<boolean>();

  const manualEditEnabled = ref<boolean>(false);
  const insuredAddress = ref<Partial<RACQAddressResult>>();
  const postalAddress = ref<Partial<RACQAddressResult>>();
  const useAddress = ref<Partial<RACQAddressResult>>();
  const isManualEntries = ref<boolean>(false);

  const autoAddressComponents = ref<Partial<RACQAddressResult>>({});
  const manualAddressComponents = ref<Partial<RACQAddressResult>>({});

  const suburbLookupOptions = ref<IRiskSuburbLookupOption[]>([]);

  const setupAddressComponent = (
    configOptions: AddressComponentOptions,
    quoteInsuredAddress?: RACQAddressResult,
    quotePostalAddress?: RACQAddressResult
  ) => {
    config.value = configOptions;

    insuredAddress.value = quoteInsuredAddress;
    postalAddress.value = quotePostalAddress;

    if (quotePostalAddress) {
      useAddress.value = quotePostalAddress;
      postalSameAsInsuredAddressSelection.value = checkPostalIsSameAsInsured(quotePostalAddress);
    }
  };

  const onSameAsPostalAddressChanged = () => {
    //toggle value, seems to be issue with timing of model and value
    postalSameAsInsuredAddressSelection.value = !postalSameAsInsuredAddressSelection.value;

    useAddress.value = postalSameAsInsuredAddressSelection.value ? insuredAddress.value : postalAddress.value;
    isManualEntries.value = false;

    if (postalSameAsInsuredAddressSelection.value) {
      isOverseasSelection.value = false;
      manualEditEnabled.value = false;
    }

    return useAddress.value;
  };

  const onIsOverseasChanged = (isOverseas: boolean, enableManualEntry: boolean) => {
    isOverseasSelection.value = isOverseas;
    manualEditEnabled.value = enableManualEntry;

    if (!isOverseas) {
      manualAddressComponents.value.country = AUSTRALIA;
    }

    if (isOverseas) {
      postalSameAsInsuredAddressSelection.value = false;
    }
  };

  const onEnableManualEntrySelected = () => {
    manualEditEnabled.value = true;
    isManualEntries.value = true;
    isOverseasSelection.value = false;
    postalSameAsInsuredAddressSelection.value = false;
  };

  const onRiskSuburbSelected = () => {
    manualEditEnabled.value = false;
  };

  const onAutoAddressSelected = (addressComponents: Partial<RACQAddressResult>) => {
    isManualEntries.value = false;
    manualEditEnabled.value = false;
    postalSameAsInsuredAddressSelection.value = checkPostalIsSameAsInsured(addressComponents);
  };

  const toggleManualFormDisabled = () => {
    manualEditEnabled.value = !manualEditEnabled.value;
  };

  const showTitle = () => {
    return config.value?.allowOverseasAddress && config.value?.isPostalAddress;
  };

  /**
   * Check to see if the location matches a list of valid risk suburbs that RACQ covers
   */
  const checkAddressInsuranceRiskSuburb = async (isManualAddress: boolean) => {
    try {
      const address = isManualAddress ? manualAddressComponents.value : autoAddressComponents.value;
      if (address.postcode) {
        const riskSuburbs: IRiskSuburbLookupOption[] = await AddressLookupService.getAddressLookupSuburbs(
          address.postcode
        );
        const match = riskSuburbs.find(
          (option) =>
            option.suburb.toLowerCase() == address.suburb?.toLowerCase() &&
            option.state.toLowerCase() == address.state?.toLowerCase()
        );

        await formatRiskAddress(isManualAddress);

        await checkIsCycloneCovered(match !== undefined, isManualAddress);

        return { match, riskSuburbs };
      }
      return null;
    } catch (error) {
      // do nothing
      console.error('Unable to lookup risk suburbs', error);
      return null;
    }
  };

  /**
   * Check to see if the location has cyclone insurance coverage
   */
  const checkIsCycloneCovered = async (insuranceRiskSuburbMatched: boolean, isManualAddress: boolean) => {
    const address = isManualAddress ? manualAddressComponents.value : autoAddressComponents.value;
    if (
      insuranceRiskSuburbMatched &&
      config.value?.isCycloneCheckRequired &&
      address.suburb &&
      address.postcode &&
      address.state
    ) {
      const response = await AddressLookupService.getIsCycloneCovered(address.suburb, address.postcode, address.state);
      address.isCycloneCovered = response?.isCovered;
    } else {
      address.isCycloneCovered = undefined;
    }
  };

  /**
   * Format risk address and obtain lurn + gnaf info
   */
  async function formatRiskAddress(isManualAddress: boolean) {
    const address = isManualAddress ? manualAddressComponents.value : autoAddressComponents.value;
    const addressline =
      address.addressLine ?? [address.unitNumber, address.streetNumber, address.street].filter((o) => o).join(' ');
    const res = await AddressLookupService.formatRiskAddress({
      addressline,
      unitNumber: address.unitNumber,
      streetNumber: address.streetNumber,
      streetName: address.street,
      postcode: address.postcode,
      suburb: address.suburb,
      state: address.state,
    });
    address.lurnCode = res?.matchedAddress?.lurnKey;
    address.lurnScale = res?.matchedAddress?.lurnScale;
    address.gnafAddressDetailPID = res?.matchedAddress?.gnafAddressDetailPID;
  }

  const isValidAddress = (value: Partial<RACQAddressResult>) => {
    if (isPostalAddressCheck(value.streetNumber ?? '')) {
      return value && value.streetNumber && value.suburb && value.postcode;
    } else if (value.country !== AUSTRALIA) {
      return value && value.streetNumber && value.street && value.suburb;
    } else {
      return value && value.streetNumber && value.street && value.suburb && value.postcode;
    }
  };

  const checkPostalIsSameAsInsured = (postalAddress: Partial<RACQAddressResult>) => {
    return postalAddress?.dpid == insuredAddress?.value?.dpid;
  };

  const resetManualAddress = () => {
    isManualEntries.value = false;
    manualAddressComponents.value = {};
    suburbLookupOptions.value = [];
  };

  const setManualAddress = (address: Partial<RACQAddressResult>) => {
    isManualEntries.value = true;
    manualAddressComponents.value = address;
    manualAddressComponents.value.addressLine = joinAddressLine(address);
    autoAddressComponents.value = {};
    isOverseasSelection.value = isOverseasManualAddress();
  };

  const setAutoAddress = (address: Partial<RACQAddressResult>) => {
    isManualEntries.value = false;
    autoAddressComponents.value = address;
    autoAddressComponents.value.addressLine = buildAddressLine(address);
    manualAddressComponents.value = {};
  };

  /**
   * Joining street number + street name
   * @param addressLine
   */
  const joinAddressLine = (address: Partial<RACQAddressResult>) => {
    let addressLine = address.unitNumber?.trim().length ? `${address.unitNumber.trim()} ` : '';
    addressLine += address.streetNumber?.trim().length ? `${address.streetNumber.trim()} ` : '';
    addressLine += address.street?.trim().length ? `${address.street.trim()}` : '';
    return addressLine;
  };

  /**
   * Build full address line
   *
   * @param address
   * @returns
   */
  const buildAddressLine = (address: Partial<RACQAddressResult>) => {
    let addressLine = address.addressLine?.trim() ?? joinAddressLine(address);
    const isPostalAddress =
      isPostalAddressCheck(address.streetNumber ?? '') || isPostalAddressCheck(address.addressLine ?? '');

    if (isPostalAddress) {
      return `${address.addressLine?.trim()}, ${address.suburb?.trim()}, ${address.state?.trim()}, ${address.postcode?.trim()}`.trim();
    }

    if (!isValidAddress(address)) return;

    addressLine += address.suburb?.trim().length ? `, ${address.suburb.trim()}, ` : '';

    if (!isOverseasSelection.value) {
      addressLine += address.state?.trim().length ? `${address.state.trim()}, ` : '';
    }

    addressLine += address.postcode?.trim().length ? `${address.postcode.trim()}` : '';

    if (isOverseasSelection.value) {
      addressLine += address.postcode?.trim().length ? ', ' : '';
      addressLine += address.country?.trim().length ? `${address.country.trim()}` : '';
    }
    return addressLine;
  };

  /*
  Only a manual address should be able to be overseas address.
  */
  const isOverseasManualAddress = () => {
    return (
      !isEmptyObject(manualAddressComponents?.value) &&
      manualAddressComponents.value.country !== undefined &&
      manualAddressComponents.value.country !== '' &&
      manualAddressComponents.value.country?.toLowerCase() !== AUSTRALIA.toLowerCase()
    );
  };

  const getActiveAddressComponent = () => {
    if (!isEmptyObject(manualAddressComponents)) {
      return manualAddressComponents;
    } else if (!isEmptyObject(autoAddressComponents)) {
      return autoAddressComponents;
    }
  };

  return {
    config,
    isOverseasSelection,
    postalSameAsInsuredAddressSelection,
    isCycloneCovered,
    manualEditEnabled,
    postalAddress,
    insuredAddress,
    useAddress,
    isManualEntries,
    autoAddressComponents,
    manualAddressComponents,
    suburbLookupOptions,
    setupAddressComponent,
    onSameAsPostalAddressChanged,
    onIsOverseasChanged,
    onEnableManualEntrySelected,
    onRiskSuburbSelected,
    onAutoAddressSelected,
    toggleManualFormDisabled,
    showTitle,
    checkAddressInsuranceRiskSuburb,
    isValidAddress,
    checkPostalIsSameAsInsured,
    resetManualAddress,
    setManualAddress,
    setAutoAddress,
    joinAddressLine,
    buildAddressLine,
    checkIsCycloneCovered,
    isOverseasManualAddress,
    getActiveAddressComponent,
  };
};

const useAddressLookupOptionsStore = (storeIdentifier: string) => {
  const storeName = `addressLookupOptionsStore-${storeIdentifier}`;
  const store = defineStore(storeName, _useAddressLookupOptionsStore);
  return store();
};

const addressLookupOptionsStores = new Map<string, ReturnType<typeof defineAddressStore>>();

function defineAddressStore(instanceId: string) {
  const addressLookupOptionsStore = useAddressLookupOptionsStore(instanceId);
  return addressLookupOptionsStore;
}

export const useMultiInstanceAddressOptionsStore = defineStore('multiInstanceAddressOptionsStore', () => {
  const itemIds = ref<string[]>([]);

  function addInstance(instanceId: string) {
    if (itemIds.value.indexOf(instanceId) > -1) return;

    addressLookupOptionsStores.set(instanceId, useAddressLookupOptionsStore(instanceId));
    itemIds.value.push(instanceId);
  }

  function getInstance(instanceId: string) {
    const storeInstance = addressLookupOptionsStores.get(instanceId);

    if (!storeInstance) {
      throw 'Instance not found';
    }

    return storeInstance;
  }

  function removeItem(instanceId: string) {
    const index = itemIds.value.indexOf(instanceId);
    if (index == -1) return;
    addressLookupOptionsStores.get(itemIds.value[index])!.$dispose();
    addressLookupOptionsStores.delete(itemIds.value[index]);
    itemIds.value.splice(index, 1);
  }

  return {
    itemIds,
    addInstance,
    getInstance,
    removeItem,
  };
});
