<template>
  <div class="d-flex flex-column">
    <div v-if="visible" class="ml-auto">
      <pre :style="{ maxHeight: '400px', maxWidth: '400px', overflow: 'scroll' }">{{
        JSON.stringify($jss.sitecoreContext(), null, 2)
      }}</pre>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useAccountStore } from '@/store/account/account.state';

export default {
  props: {
    visible: {
      type: Boolean,
    },
  },
  async mounted() {
    const router = useRouter();
    await router.isReady();
    if (this.$route.query.code && this.$route.query.state) {
      useAccountStore().authenticate(this.$route);
    }
  },
};
</script>
<style scoped>
pre {
  font-size: 11px;
}
</style>
