<template>
  <div :class="['form-buttons', { 'sticky-footer': isSticky }]">
    <FormKit
      v-if="btnPrevText && !step.isMasked"
      type="button"
      :label="btnPrevText"
      inputClass="btn btn-secondary"
      :disabled="formStore.disableAllSteps"
      @click="() => (btnPrevClick ? btnPrevClick() : onPrev())"
    />

    <slot v-if="btnNextText && step.isActive && !step.isMasked" name="btnNext">
      <div class="flex-col justify-start items-end gap-8 md:gap-6 inline-flex ms-auto">
        <FormKit
          type="submit"
          :label="btnNextText"
          :disabled="btnNextDisabled || formStore.disableAllSteps"
          inputClass="btn btn-primary"
          @click="btnNextClick"
        />
        <button
          v-if="btnDisagreeText && btnDisagreeClick"
          type="button"
          class="link text-base-bold"
          @click="btnDisagreeClick"
        >
          {{ btnDisagreeText }}
        </button>
      </div>
    </slot>

    <FormKit
      v-if="showEditBtn"
      type="button"
      label="Edit"
      :disabled="formStore.disableAllSteps"
      inputClass="btn btn-primary btn-edit"
      outerClass="ms-auto z-[200]"
      @click="() => onEdit(step.stepIndex!)"
    />
  </div>
</template>

<script lang="ts">
const editButtonClicked = 'editButtonClicked';
export default {}
</script>

<script setup lang="ts">
import { computed } from 'vue';
import { IFormStep } from '@/interfaces';
import { useFormStore } from '@/store';

// Define props and emitters
// ------------------------------------------------------------- //

const props = defineProps<{
  step: IFormStep;
  isSticky?: boolean;
  btnNextText?: string;
  btnPrevText?: string;
  btnDisagreeText?: string;
  btnNextDisabled?: boolean;
  btnNextClick?: () => void;
  btnPrevClick?: () => void;
  btnDisagreeClick?: () => void;
}>();

const emit = defineEmits([editButtonClicked]);

// Initialise stores
// ------------------------------------------------------------- //
const formStore = useFormStore();

// Define Computed properties
// ------------------------------------------------------------- //
const isPrelimStep = computed(() => props.step.isPrelimStep);

const showEditBtn = computed(
  () =>
    props.step.isMasked &&
    props.step.stepNumber &&
    props.step.stepNumber <= (formStore.currentStep?.stepNumber || props.step.stepNumber)
);

// Functions
// ------------------------------------------------------------- //
/**
 * On click of 'Back' button
 *
 */
function onPrev() {
  if (isPrelimStep.value) {
    return formStore.goPrevPrelimStep();
  }

  formStore.goPrevStep();
}

/**
 * On click of the 'Edit' button of a form
 *
 * @param stepIndex
 */
function onEdit(stepIndex: number) {
  formStore.goToStep(stepIndex);
  emit(editButtonClicked);
}
</script>
