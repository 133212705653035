<template>
  <div :class="bindings.outerClass">
    <div class="flex">
      <FormKit
        v-bind="bindings"
        :key="key"
        v-model="model"
        postContent=""
        outerClass="w-full"
        :innerClass="combinedInnerClass"
        :classes="{
          options: `mb-0 ${bindings.classesOptions} `,
          message: isCheckbox ? 'pl-10' : isNativeRadio ? 'mt-3' : '',
        }"
        :onpaste="bindings.disablePaste ? 'return false' : ''"
      >
        <template v-if="isCheckbox && !hasCheckboxOptions" #label="context">
          <RichText class="ml-2 text-sm text-racq-dark-grey" :field="{ value: context.label ?? '' }" />
        </template>
        <template v-if="bindings.suffixIcon" #suffixIcon="context">
          <RichText :field="{ value: context.suffixIcon ?? '' }" />
        </template>
        <template v-if="bindings.helpMd" #help>
          <RichText
            :field="{ value: bindings.helpMd }"
            class="richtext richtext--no-base font-sans text-paragraph-color text-m mb-3"
          />
        </template>
      </FormKit>
    </div>
    <slot name="after"></slot>
    <RichText
      v-if="bindings.helpSm"
      class="formkit-help text-sm text-racq-dark-grey mt-4"
      :field="{ value: bindings.helpSm }"
    />
    <RichText
      v-if="bindings.postContent && bindings.showPostContent"
      :class="bindings.postContentClass"
      :field="{ value: bindings.postContent }"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { FormKit } from '@formkit/vue';
import { VueFormInputProps } from '@/composables/useFormInputData';
import { hashCode } from '@/utils/hash-util';

const props = defineProps<{
  bindings: VueFormInputProps;
}>();

const model = defineModel<any>();

/**
 * Generate a unique key for the field so that if the bindings change it will force a re-render of the component.
 * This is required to work around some dynamic data binding issues especially with the Formkit dropdown component.
 */
const key = computed(() => {
  if (props.bindings.options) {
    return props.bindings.name + props.bindings.type + hashCode(JSON.stringify(props.bindings.options));
  }
  return props.bindings.name + props.bindings.type;
});

const combinedInnerClass = computed(() => {
  return `${props.bindings.innerClass ?? ''} ${isCheckbox.value && props.bindings.options && props.bindings.options.length > 1 ? 'mt-0.5' : 'self-start'}`;
});

const isCheckbox = computed(() => {
  return props.bindings.type === 'checkbox';
});

const isNativeRadio = computed(() => {
  return props.bindings.type === 'nativeRadio';
});

const hasCheckboxOptions = computed(() => {
  return props.bindings.options != null;
});
</script>
