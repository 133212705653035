<template>
  <div
    v-for="input in inputs as Record<string, any>[]"
    :key="input.formInputId?.value + input.label?.value"
    v-bind="adobeTargetDataTypes(input)"
  >
    <slot :name="input.formInputId?.value">
      <template v-if="input.inputType?.value == FormInputTypes.ADDRESS_AUTOCOMPLETE">
        [Please use AddressLookupOptions component]
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.DATE_INPUT">
        <DateInput
          :bindings="formInputData(input.formInputId?.value)"
          :minDateToday="input?.minDateToday?.value"
          :maxDateByDays="input?.maxDateByDays?.value"
          :minDate="input?.minDate?.value"
        />
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.TITLE_NAME">
        <TitleNameInput
          :bindings="formInputData(input.formInputId?.value)"
          :titleRequiredMessage="input?.titleRequiredMessage?.value"
          :firstnameRequiredErrorMessage="input?.firstnameRequiredErrorMessage?.value"
          :firstnameRegex="input?.firstnameRegex?.value"
          :firstnameRegexErrorMessage="input?.firstnameRegexErrorMessage?.value"
          :lastnameRequiredErrorMessage="input?.lastnameRequiredErrorMessage?.value"
          :lastnameRegex="input?.lastnameRegex?.value"
          :lastnameRegexErrorMessage="input?.lastnameRegexErrorMessage?.value"
        />
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.LABEL">
        <RichText :field="input.label" :class="input.outerClass?.value" />
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.GENDER">
        <Gender :bindings="formInputData(input.formInputId?.value)" />
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.CALL_TO_ACTION_CARD">
        <CallToActionCard :bindings="formInputData(input.formInputId?.value)" />
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.DISCLAIMER">
        <Disclaimer :bindings="input" />
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.SECTION_FRAME">
        <SectionFrame :bindings="formInputData(input.formInputId?.value)" />
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.RADIO_BUTTON_CARD_GROUP">
        <FormKit
          v-bind="formInputData(input.formInputId?.value)"
          :options="convertSitecoreToVueRadioButtonCardOptions(input)"
        />
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.CUSTOM">
        <div></div>
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.PHONE_NUMBER">
        <PhoneNumberInput :bindings="formInputData(input.formInputId?.value)" />
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.DATEPICKER">
        <DatePicker
          :bindings="formInputData(input.formInputId?.value)"
          :minDateToday="input?.minDateToday?.value"
          :maxDateByDays="input?.maxDateByDays?.value"
        />
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.LOGINBUTTON">
        <AccountLoginInfoBox :fields="input" />
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.PANEL_TITLE">
        <PanelTitle
          :bindings="formInputData(input.formInputId?.value)"
          :title="input.title!"
          :subTitle="input.subTitle!"
          :icon="input.icon"
          :isSecondary="input.isSecondary"
        />
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.CUSTOM_EMAIL_CONFIRMATION">
        <SendEmailConfirmation
          :bindings="formInputData(input.formInputId?.value)"
          :emailButtonText="input.emailButtonText"
          :emailHeading="input.emailHeading"
          :isLoading="false"
        />
      </template>
      <template v-else-if="input.inputType?.value == FormInputTypes.FIND_CAR">
        <FormKit v-bind="input" type="findCar" />
      </template>
      <template v-else>
        <RACQFormKit :bindings="getRacqFormKitBindings(input)" />
      </template>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { IFormStep, ISitecoreFormGenericInput, RACQAddressResult } from '@/interfaces';
import { computed } from 'vue';
import { FormInputTypes } from '@/enums/form-input-types.enum';
import useFormInputData, { getAdobeTargetDataAttributes } from '@/composables/useFormInputData';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import DateInput from '@/components/DateInput/DateInput.vue';
import TitleNameInput from '@/components/TitleNameInput/TitleNameInput.vue';
import RACQFormKit from '@/components/RACQFormKit/RACQFormKit.vue';
import Gender from '@/components/Gender/Gender.vue';
import Disclaimer from '@/components/Disclaimer/Disclaimer.vue';
import CallToActionCard from '@/components/CallToActionCard/CallToActionCard.vue';
import SectionFrame from '@/components/SectionFrame/SectionFrame.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import AccountLoginInfoBox from '@/components/AccountLoginInfoBox/AccountLoginInfoBox.vue';
import PhoneNumberInput from '@/components/PhoneNumberInput/PhoneNumberInput.vue';
import PanelTitle from '@/components/PanelTitle/PanelTitle.vue';
import SendEmailConfirmation from '@/components/EmailConfirmation/SendEmailConfirmation.vue';

// Define Vue props
// ------------------------------------------------------------- //
const props = defineProps<{
  step: IFormStep;
}>();

const { formInputData, convertSitecoreToVueRadioButtonCardOptions, convertSitecoreToVueCheckboxListOptions } =
  useFormInputData(props.step.formInputs!);
const inputs = computed<ISitecoreFormGenericInput[]>(() =>
  props.step?.formInputs ? Object.values(props.step?.formInputs).filter((input) => !input.isHidden) : []
);

const getRacqFormKitBindings = (input: any) => {
  if (input.inputType?.value == FormInputTypes.CHECKBOX && input.fields.length > 0) {
    return {
      ...formInputData(input.formInputId?.value),
      options: convertSitecoreToVueCheckboxListOptions(input),
    };
  } else {
    return formInputData(input.formInputId?.value);
  }
};

function adobeTargetDataTypes(input: Record<string, any>) {
  return getAdobeTargetDataAttributes(input, props.step.stepName!);
}
</script>
