import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import SectionFrame from '@/components/SectionFrame/SectionFrame.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddRemoveForm',
  props: {
    title: {},
    titleClasses: {},
    titleIconClass: {},
    customClasses: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(SectionFrame, {
      sectionTitle: _ctx.title,
      titleClasses: _ctx.titleClasses,
      titleIconClass: _ctx.titleIconClass,
      class: _normalizeClass([_ctx.customClasses || 'mb-5'])
    }, {
      buttonAction: _withCtx(() => [
        (_ctx.$slots.buttonAction)
          ? _renderSlot(_ctx.$slots, "buttonAction", { key: 0 })
          : _createCommentVNode("", true)
      ]),
      sectionFrameContent: _withCtx(() => [
        (_ctx.$slots.sectionFrameContent)
          ? _renderSlot(_ctx.$slots, "sectionFrameContent", { key: 0 })
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["sectionTitle", "titleClasses", "titleIconClass", "class"]),
    (_ctx.$slots.addButton)
      ? _renderSlot(_ctx.$slots, "addButton", { key: 0 })
      : _createCommentVNode("", true)
  ]))
}
}

})