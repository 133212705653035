import { cloneDeep } from 'lodash-es';
import { ref, computed, markRaw, defineAsyncComponent } from 'vue';
import { getScreenSize, scrollToElement, scrollToElementRaw } from '@/utils/dom-helpers';
import { defineStore } from 'pinia';
import { hideAllPoppersAndPaddings } from '@/plugins/tooltip/renderTooltip';
import { convertArrayToObject } from '@/utils/data-transforms';
import { NO_COMPONENT_DIR_ERROR, NO_STEPS_DATA_ERROR } from '@/constants/errors';
import { OrdinalNumbers } from '@/constants/ordinal-numbers.const';
import type {
  IFormStep,
  FormDropdownOption,
  FormDropdownItem,
  ISitecoreFormStep,
  FormProps,
  ISitecoreFormGenericInput,
} from '@/interfaces';
import { addOrdinalSuffix } from '@/utils/text-transforms';
import { useJeopardyStore } from '@/store';

export const useFormStore = defineStore('Form', () => {
  const jpStore = useJeopardyStore();

  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //
  const steps = ref<IFormStep[]>([]);
  const prelimSteps = ref<IFormStep[]>([]);
  const formComponentDir = ref('');
  const showStepper = ref(true);
  const folderPath = ref('');
  const inScopeSteps = ref<IFormStep[]>([]);
  const pageTitle = ref('');
  const formName = ref('');
  const removeStepCallback = ref<(stepIndex: number, step: IFormStep) => Promise<void>>();
  const iqFloatingBoxShown = ref(true);
  const disableAllSteps = ref(false);

  // ------------------------------------------------------------- //
  // Define Getters
  // ------------------------------------------------------------- //
  const currentPrelimStepIndex = computed(() => prelimSteps.value.findIndex((step) => step.isActive));
  const currentPrelimStep = computed(() => prelimSteps.value.find((step) => step.isActive));
  const allPrelimStepsComplete = computed(() => prelimSteps.value.every((step) => step.isComplete));

  const currentStepIndex = computed(() => inScopeSteps.value.findIndex((step) => step.isActive));
  const currentStep = computed(() => inScopeSteps.value.find((step) => step.isActive));
  const isStepperVisible = computed(() => showStepper);

  const prevActiveStepIndex = ref(0);
  const prevActiveStep = ref<IFormStep>();

  // ------------------------------------------------------------- //
  // Define Actions
  // ------------------------------------------------------------- //

  /**
   * Toggle the visibiltiy of stepper
   *
   * @param show
   */
  function makeStepperVisible(show: boolean = true) {
    showStepper.value = show;
  }

  /**
   * Toggle the loading state for the current step.
   *
   * @param show
   */
  function showLoadingForCurrentStep(show: boolean = true) {
    if (!allPrelimStepsComplete.value && currentPrelimStepIndex.value > -1) {
      prelimSteps.value[currentPrelimStepIndex.value].isLoading = show;
    } else if (currentStepIndex.value > -1) {
      inScopeSteps.value[currentStepIndex.value].isLoading = show;
    }
  }

  /**
   * Initialise the form steps from the props data injected in via
   * the FormBuilder.vue component.
   *
   * @param props
   */
  function initialiseForm(props: FormProps) {
    const allSteps: ISitecoreFormStep[] = (props.fields.data as any).datasource.steps;
    folderPath.value = (props.fields.data as any).datasource?.folderPath?.value ?? '';

    if (!props.componentDir) {
      console.error(NO_COMPONENT_DIR_ERROR);
      return;
    }

    setFormComponentDir(props.componentDir);

    if (!allSteps?.length) {
      console.error(NO_STEPS_DATA_ERROR);
      return;
    }

    initialiseSteps(allSteps);
  }

  /**
   * Initialise all steps on first page load.
   *
   */
  function initialiseSteps(sitecoreSteps: ISitecoreFormStep[]) {
    if (sitecoreSteps.length) {
      // Prelim steps are initial steps (eg) disclaimers, terms and conditions
      const prelimSteps = sitecoreSteps
        .filter((step) => step.isPrelimStep.value == '1')
        .map(convertSitecoreStepToVueStep);
      // Steps have a step tracker
      const primarySteps = sitecoreSteps
        .filter((step) => step.isPrelimStep.value != '1')
        .map(convertSitecoreStepToVueStep);

      setPrelimSteps(prelimSteps);
      setSteps(primarySteps);
    }
  }

  /**
   * Set primary steps data
   *
   * (ie) steps with sidebar tracker (not prelim)
   *
   * @param newStepsData
   */
  function setSteps(newStepsData: IFormStep[]) {
    steps.value = newStepsData;
    inScopeSteps.value = newStepsData.filter((step) => step.isInStepper);
    indexInScopeSteps();
  }

  /**
   * Update step number & index of all in scope steps
   */
  function indexInScopeSteps() {
    inScopeSteps.value.forEach((step, index) => {
      step.stepIndex = index;
      const inputKeys = Object.keys(step.formInputs || {});
      inputKeys.forEach((key) => {
        const input: ISitecoreFormGenericInput = step.formInputs![key];
        input.elementId = `${step.id}_${index}_${key}`;
      });
    });
  }

  /**
   * Set prelim steps data.
   *
   * @param newStepsData
   */
  function setPrelimSteps(newStepsData: IFormStep[]) {
    prelimSteps.value = newStepsData;
  }

  /**
   * Update input attributes
   *
   * @param options
   * @param currentStepInScope - Optional parameter. Defaults to the currently active step.
   */
  function updateInput(
    options: { inputId: string; updates: Partial<ISitecoreFormGenericInput> },
    currentStepInScope = inScopeSteps.value.find((step) => step.isActive)
  ) {
    if (currentStepInScope?.formInputs![options.inputId]) {
      currentStepInScope.formInputs[options.inputId] = {
        ...currentStepInScope.formInputs[options.inputId],
        ...options.updates,
      };
    }
  }

  /**
   * Add step to scope
   *
   * @param stepId
   * @param afterIndex
   */
  function addStepToScope(
    stepId: string,
    options: {
      afterIndex: number;
      removable: boolean;
      withOrdinalNumber?: boolean;
      isVisible?: boolean;
      uuid?: string;
    } = { afterIndex: currentStepIndex.value + 1, removable: false, withOrdinalNumber: false, isVisible: false }
  ) {
    const step = cloneDeep(steps.value.find((s) => s.id?.toLowerCase() == stepId.toLowerCase()));
    if (step) {
      step.withOrdinalNumber = options.withOrdinalNumber;
      const numberOfSameSteps = inScopeSteps.value.filter((s) => s.id == step.id).length;
      step.stepTitle = options.withOrdinalNumber ? addOrdinalSuffix(numberOfSameSteps, step.stepTitle) : step.stepTitle;
      inScopeSteps.value.splice(options.afterIndex, 0, step);
      step.isVisible = options.isVisible;
      step.uuid = options.uuid;
    }
    indexInScopeSteps();
  }

  /**
   * Remove Step to Scope
   * @param index
   */
  async function removeStep(index: number) {
    try {
      if (removeStepCallback.value) {
        await removeStepCallback.value(index, inScopeSteps.value[index]);
      }

      inScopeSteps.value[index].isActive && goPrevStep();
      inScopeSteps.value.splice(index, 1);
      // reorder ordinal numbers
      const otherSteps = inScopeSteps.value.filter((s) => s.id == inScopeSteps.value[index].id);
      otherSteps.forEach((step, index) => {
        if (step?.withOrdinalNumber) {
          const titleArray = step.stepTitle?.split(' ');
          titleArray?.splice(0, 1, OrdinalNumbers[index]);
          step.stepTitle = titleArray?.join(' ');
        }
      });

      indexInScopeSteps();
    } catch {
      // do nothing
    }
  }

  /**
   * Called when changing the step - stores the current step in prevStep/prevStepIndex
   */
  function setPrevActiveStep() {
    prevActiveStepIndex.value = currentStepIndex.value;
    prevActiveStep.value = currentStep.value;
  }

  /**
   * Go to specified step.
   *
   * @param stepIndex
   */
  function goToStep(stepIndex: number, options?: { completeCurrentStep: boolean }) {
    hideAllPoppersAndPaddings();

    setPrevActiveStep();
    jpStore.hideJeopardyPanel();

    setPrevActiveStep();

    inScopeSteps.value = inScopeSteps.value.map((step) => ({
      ...step,
      isActive: false,
      isMasked: true,
    }));

    const step = inScopeSteps.value[stepIndex - 1];
    if (step) {
      step.isComplete = true;
    }

    const stepToGoto = inScopeSteps.value[stepIndex];

    // set other steps to incomplete
    inScopeSteps.value.forEach((step, index) => {
      if (index >= stepIndex) {
        step.isComplete = false;
      }
    });
    stepToGoto.isMasked = false;
    stepToGoto.isVisible = true;
    stepToGoto.isActive = true;
    makeStepperVisible(!stepToGoto.hideStepper);

    const isSmallScreen = getScreenSize().xs.value;
    isSmallScreen
      ? window.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      : scrollToElement(`#${stepToGoto.id}${stepIndex}`, 100, -100);
  }

  function scrollToStep(stepIndex: number) {
    const stepToGoto = inScopeSteps.value[stepIndex];
    scrollToElement(`#${stepToGoto.id}${stepIndex}`, 100, -100);
  }

  function scrollToErrorMessage() {
    setTimeout(() => {
      const errorEl = document.querySelector(`.formkit-message.text-racq-error-red`);
      if (errorEl) {
        scrollToElementRaw(errorEl?.parentElement?.parentElement, 100, -100);
      }
    }, 200);
  }

  /**
   * Go to specified prelim step
   *
   * @param stepIndex
   */
  function gotoPrelimStep(stepIndex: number, options?: { completeCurrentStep: boolean }) {
    hideAllPoppersAndPaddings();

    setPrevActiveStep();
    jpStore.hideJeopardyPanel();

    setPrevActiveStep();

    const currentStepIndex = currentPrelimStepIndex.value;

    prelimSteps.value = prelimSteps.value.map((step) => ({
      ...step,
      isActive: false,
    }));

    if (options?.completeCurrentStep && prelimSteps.value[currentStepIndex]) {
      prelimSteps.value[currentStepIndex].isComplete = true;
    }

    const stepToGoto = prelimSteps.value[stepIndex];

    if (allPrelimStepsComplete.value || !stepToGoto) {
      scrollToElement('body', 100);
      return;
    }

    stepToGoto.isActive = true;
  }

  /**
   * Move to next step
   *
   */
  function goNextStep() {
    goToStep((currentStepIndex.value || 0) + 1);
  }

  /**
   * Move to previous step
   */
  function goPrevStep() {
    const prevStepIndex = currentStepIndex.value || 0;

    //if prevStepIndex is zero then previous step must have been preliminary if preliminary exists.
    if (prevStepIndex === 0 && prelimSteps.value.length > 0) {
      const lastPrelimStep = prelimSteps.value[prelimSteps.value.length - 1];
      if (lastPrelimStep) {
        lastPrelimStep.isVisible = true;
        lastPrelimStep.isActive = true;
        lastPrelimStep.isComplete = false;
      }
      return;
    }

    prevStepIndex && goToStep(prevStepIndex - 1, { completeCurrentStep: false });
  }

  /**
   * Move to next prelim step
   *
   */
  function goNextPrelimStep() {
    const nextStepIndex = prelimSteps.value[currentPrelimStepIndex.value]?.nextStepIndex ?? 0;

    gotoPrelimStep(nextStepIndex, { completeCurrentStep: true });
  }

  /**
   * Move to previous prelim step
   *
   */
  function goPrevPrelimStep() {
    const prevStepIndex = prelimSteps.value[currentPrelimStepIndex.value]?.prevStepIndex ?? 0;

    gotoPrelimStep(prevStepIndex, { completeCurrentStep: false });
  }

  /**
   * Toggle the visibility of the steps after the current/ active step
   *
   * @param isVisible
   */
  function toggleNextStepsVisibility(isVisible: boolean) {
    const currentStepIndex = currentStep.value?.stepIndex;
    if (currentStepIndex) {
      inScopeSteps.value.forEach((step, index) => {
        if (index > currentStepIndex) {
          step.isVisible = isVisible;
        }
      });
    }
  }

  /**
   * Hide the steps after the current/ active step
   */
  function hideNextSteps() {
    toggleNextStepsVisibility(false);
  }

  /**
   * Show the steps after the current/ active step
   */
  function showNextSteps() {
    toggleNextStepsVisibility(true);
  }

  /**
   * Convert sitecore content to vue and import the form
   */
  function convertSitecoreStepToVueStep(item: ISitecoreFormStep, index: number, arr: ISitecoreFormStep[]) {
    const componentName = item.component.value;

    // Note: Update filepath according to your form name and file directory structure
    const importedComponent = defineAsyncComponent(() => {
      try {
        if (item.componentPath?.value) return import(`@/forms/${item.componentPath?.value}/${componentName}.vue`);
        return import(
          `@/forms/${folderPath.value ? folderPath.value + '/' : ''}${formComponentDir.value}/steps/${componentName}.vue`
        );
      } catch {
        return import('@/NotFound.vue');
      }
    });

    const sanitizedId = item.stepId.value.toLowerCase().replace(/\s+/g, '_');

    return {
      id: sanitizedId,
      stepName: item.name,
      stepNumber: item.stepNumber?.value ?? index + 1,
      stepTitle: item.stepTitle?.value ?? item.name,
      stepContent: item.stepContent.value,
      isInStepper: item?.isDynamicStep?.value != '1',
      isVisible: index === 0,
      isActive: index === 0,
      isComplete: false,
      isPrelimStep: item?.isPrelimStep?.value == '1',
      isDynamicStep: item?.isDynamicStep?.value == '1',
      isRemovable: item?.isRemovable?.value == '1',
      isFinalStep: item?.isFinalStep?.value == '1',
      // Increase performance by marking component as 'raw' so it is not a Reactive object
      component: markRaw(importedComponent),
      nextStepIndex: Math.min(index + 1, arr.length),
      prevStepIndex: index <= 0 ? 0 : index - 1,
      btnNext: item.btnNext.value,
      btnPrev: item.btnPrev.value,
      btnDisagree: item.btnDisagree.value,
      hideStepper: item?.hideStepper?.value == '1',
      stepGroupName: item.stepGroupName?.value,
      formInputs: convertArrayToObject(item.formInputs),
      stepDisclaimer: item.stepDisclaimer?.value || '',
    } as unknown as IFormStep;
  }

  /**
   * Convert dropdown items to options
   *
   * @param sourceData
   * @param useListLabel
   * @returns
   */
  function getDropDownListItems(sourceData: FormDropdownItem[], useListLabel = false): FormDropdownOption[] {
    return sourceData.map((element) => {
      return {
        value: useListLabel ? element.fields.listValue.value : element.id,
        label: useListLabel ? element.fields.listLabel.value : element.fields.listValue.value,
      };
    });
  }

  /**
   * Set the form component directory path to
   * allow dynamic component file imports.
   *
   * @param dir
   */
  function setFormComponentDir(dir: string) {
    formComponentDir.value = dir;
  }

  /**
   * set post content visible
   */
  function showPostContent(stepId: string, inputId: string, show: boolean = true) {
    const formInput = inScopeSteps.value.find((s) => s.id === stepId)?.formInputs?.[inputId];
    if (formInput) formInput.showPostContent = show;
  }

  /**
   * Set the form name
   *
   * @param name
   */
  function setFormName(name: string) {
    formName.value = name;
  }

  /**
   * Set the page title
   *
   * @param title
   */
  function setPageTitle(title: string) {
    pageTitle.value = title;
  }

  /**
   * set IQ floating box shown
   */
  function showIQFloatingBox(show?: boolean) {
    iqFloatingBoxShown.value = show || false;
  }

  /**
   * Set dynamic options
   */
  function setDynamicOptions(stepId: string, dynamicOptions: any) {
    const step = inScopeSteps.value.find((s) => s.id == stepId);

    if (step?.formInputs) {
      Object.entries(dynamicOptions).forEach(([key, value]) => {
        if (step?.formInputs && step.formInputs[key]) {
          step.formInputs[key].options = { value: value };
        }
      });
    }
  }

  /**
   * get step by step ID
   */
  function getStep(stepId: string) {
    const step = inScopeSteps.value.find((s) => s.id == stepId);
    return step;
  }

  /**
   * Set disableAllSteps.value = true
   */
  function setDisableAllSteps() {
    disableAllSteps.value = true;
  }

  /**
   * Set current step to masked
   */
  function maskActiveStep() {
    if (currentStep.value) {
      currentStep.value.isMasked = true;
    } else if (currentPrelimStep.value) {
      currentPrelimStep.value.isMasked = true;
    }
  }

  /**
   * Set current step to not masked
   */
  function unMaskActiveStep() {
    if (currentStep.value) {
      currentStep.value.isMasked = false;
    } else if (currentPrelimStep.value) {
      currentPrelimStep.value.isMasked = false;
    }
  }

  /**
   * Set the form stepper state to show the form is completed
   */
  function setFormCompleted() {
    if (currentStep.value) {
      inScopeSteps.value.forEach((step) => {
        if (step.isFinalStep) {
          step.isComplete = true;
          step.hideStepper = true;
        } else {
          step.isComplete = true;
          step.hideStepper = true;
          step.isVisible = false;
        }
      });
      scrollToStep(currentStep.value.stepIndex!);
    }
  }

  return {
    prelimSteps,
    currentStep,
    prevActiveStep,
    inScopeSteps,
    formComponentDir,
    currentStepIndex,
    prevActiveStepIndex,
    currentPrelimStep,
    currentPrelimStepIndex,
    allPrelimStepsComplete,
    showStepper,
    isStepperVisible,
    removeStepCallback,
    pageTitle,
    formName,
    iqFloatingBoxShown,
    setSteps,
    scrollToStep,
    goToStep,
    goNextStep,
    goPrevStep,
    setPrelimSteps,
    gotoPrelimStep,
    initialiseForm,
    initialiseSteps,
    goNextPrelimStep,
    setFormComponentDir,
    goPrevPrelimStep,
    getDropDownListItems,
    showLoadingForCurrentStep,
    convertSitecoreStepToVueStep,
    makeStepperVisible,
    addStepToScope,
    removeStep,
    updateInput,
    showPostContent,
    setFormName,
    setPageTitle,
    showIQFloatingBox,
    setDynamicOptions,
    scrollToErrorMessage,
    getStep,
    setDisableAllSteps,
    disableAllSteps,
    maskActiveStep,
    unMaskActiveStep,
    setFormCompleted,
    hideNextSteps,
    showNextSteps,
  };
});
