import { changeVehicleLocationGetPolicy, changeVehicleLocationApplyBusinessRules } from '@/constants/forms-api.const';
import { RequestServices as _http } from '../common/request.services';
import { IJeopardy, IServiceResponse } from '@/interfaces/entities';
import { QuotePremiumDetails } from '@/interfaces/entities/premium-details.interface';
import { TermPaymentDetails } from '@/interfaces/entities/term-payment-details.interface';

export interface Coverage {
  id: string;
  garagingType: string;
  garagingLocation: string;
  vehicle: {
    formattedLine1: string;
    formattedLine2: string;
    make: string;
    model: string;
    year: string;
  };
}

export interface VehiclePolicy {
  policyNumber: string;
  coverages: Coverage[];
  termStart: Date | undefined;
  termEnd: Date | undefined;
  renewalTermStart: Date | undefined;
  renewalTermEnd: Date | undefined;
  currentTermPaymentDetails: TermPaymentDetails;
  renewalTermPaymentDetails: TermPaymentDetails;
  state: {
    isPolicyChangeAllowed: boolean;
    canDeferPayment: boolean;
    canChangePaymentDetails: boolean;
    canSwitchToMonthlyPayments: boolean;
    canReducePremium: boolean;
    isInRenewal: boolean;
    isPaymentDue: boolean;
    hasMultiplePaymentsDue: boolean;
    hasDraftRenewal: boolean;
    isOverdue: boolean;
    isFutureCancelled: boolean;
    hasSingleFutureChange: boolean;
    hasMultipleInSequenceFutureChanges: boolean;
    hasMultipleOutOfSequenceFutureChanges: boolean;
    hasCostOverride: boolean;
    policyChangeIssues: {
      hasOpenWorkOrderFromOtherUser: boolean;
      renewalTermHasFutureChange: boolean;
    };
  };
}

export interface ApplyRulesRequestOfChangeVehicleLocationUpdateModel {
  quoteSessionId?: string;
  requestQuote?: boolean;
  bindChange?: boolean;
  model?: ChangeVehicleLocationUpdateModel;
}

export interface ChangeVehicleLocationUpdateModel {
  changeEffectiveDate: Date;
  vehicleLocations: VehicleLocation[];
}

export interface VehicleLocation {
  coverageId: string;
  overnightParkingLocationCode: string;
  overnightParkingLocationSuburb: string;
  overnightParkingLocationPostcode: string;
  overnightParkingLocationState: string;
  overnightParkingLocationAddressLine?: string;
}

export interface ApplyRulesResponseOfChangeVehicleLocationViewModel {
  jeopardies: IJeopardy[];
  viewModel: ChangeVehicleLocationViewModel;
}

export interface ChangeVehicleLocationViewModel {
  policyNumber: string;
  quotePremiumDetails: QuotePremiumDetails;
}

export const ChangeVehicleLocationService = {
  /**
   * Get Policy
   *
   * @param quoteSessionId
   */
  getPolicy(quoteSessionId: string): Promise<IServiceResponse<VehiclePolicy>> {
    return _http.HttpGet(changeVehicleLocationGetPolicy.replace('{0}', quoteSessionId));
  },

  /**
   * Apply Business Rules
   *
   * @param request
   */
  applyBusinessRules(
    request: ApplyRulesRequestOfChangeVehicleLocationUpdateModel
  ): Promise<IServiceResponse<ApplyRulesResponseOfChangeVehicleLocationViewModel>> {
    return _http.HttpPost(changeVehicleLocationApplyBusinessRules, request);
  },
};
