import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/auto-renew.svg'


const _hoisted_1 = { class: "grid grid-cols-1 w-full" }
const _hoisted_2 = { class: "grid grid-cols-[15%_85%]" }
const _hoisted_3 = ["innerHTML"]

import SectionFrame from '@/components/SectionFrame/SectionFrame.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AutoRenewalMessage',
  props: {
    autoRenewalContent: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(SectionFrame, { class: "mt-2" }, {
    sectionFrameContent: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("div", null, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Auto renew icon",
              role: "img",
              class: "mt-0 w-[80px] h-[80px] rounded-full"
            })
          ], -1)),
          _createElementVNode("div", { innerHTML: _ctx.autoRenewalContent }, null, 8, _hoisted_3)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})