import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-3 text-base my-6 bg-racq-anti-flash-white border border-racq-blue-background" }
const _hoisted_2 = { class: "flex flex-col gap-1" }
const _hoisted_3 = { class: "text-m-bold text-racq-dark-grey" }
const _hoisted_4 = { class: "text-m text-dark-grey-text" }
const _hoisted_5 = ["src"]

import { ref } from 'vue';
import { ITableGroup, ITableGroupColumn } from '@/interfaces/entities/table-group.interface';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import PercentageBar from '@/components/PercentageBar/PercentageBar.vue';
import chevronDown from '@/assets/icons/blue-chevron-down.svg';
import { toBool, toNumber } from '@/utils/convert';

// Define props and emitters
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'TableControlItem',
  props: {
    tableData: {}
  },
  setup(__props: any) {



// Define Functions
// ------------------------------------------------------------- //
const getGridTemplate = (numberOfColumns: number, numberOfRow: number, showMore?: boolean, numberOfItems?: number) => {
  return {
    gridTemplateRows: `${showMore && !isExpanded.value ? `repeat(${numberOfItems}, minmax(0, auto))` : `repeat(${numberOfRow}, minmax(0, auto))`}`,
    gridTemplateColumns: `repeat(${numberOfColumns}, minmax(0, 1fr))`,
  };
};
const getBorderClass = (
  index: number,
  tableColumn: ITableGroupColumn,
  showMore?: boolean,
  initialNumberOfItemsToShow?: number
) => {
  if (showMore) {
    return !isExpanded.value && index == initialNumberOfItemsToShow! - 1
      ? ''
      : index == tableColumn.rows.length - 1
        ? ''
        : 'border-b-0 border-b-0';
  } else {
    return index == tableColumn.rows.length - 1 ? '' : 'border-b-0 border-b-0';
  }
};

const isExpanded = ref(false);
const toggleContent = () => {
  isExpanded.value = !isExpanded.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData.tableSections, (table, key) => {
    return (_openBlock(), _createElementBlock("div", { key: key }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(RichText), {
          field: table.heading
        }, null, 8, ["field"])
      ]),
      _createElementVNode("div", {
        class: "grid grid-flow-col gap-x-4",
        style: _normalizeStyle(
        getGridTemplate(
          table.tableColumn.length,
          table.tableColumn[0].rows.length,
          _unref(toBool)(table.showMoreButton?.value),
          _unref(toNumber)(table.initialNumberOfItemsToShow?.value)
        )
      )
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(table.tableColumn, (tableColumn, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "contents"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(toBool)(table.showMoreButton?.value)
            ? isExpanded.value
              ? tableColumn.rows
              : tableColumn.rows.slice(0, _unref(toNumber)(table.initialNumberOfItemsToShow?.value))
            : tableColumn.rows, (tableRow, row) => {
              return (_openBlock(), _createElementBlock("div", {
                key: row,
                class: _normalizeClass(["p-4 border border-racq-blue-background", [
            tableColumn.highlighted ? 'bg-racq-anti-flash-white' : '',
            getBorderClass(
              row,
              tableColumn,
              _unref(toBool)(table.showMoreButton?.value),
              _unref(toNumber)(table.initialNumberOfItemsToShow?.value)
            ),
          ]])
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_unref(RichText), {
                      field: tableRow.heading
                    }, null, 8, ["field"])
                  ]),
                  _createElementVNode("p", _hoisted_4, [
                    _createVNode(_unref(RichText), {
                      field: tableRow.description
                    }, null, 8, ["field"])
                  ]),
                  (tableRow.percentage?.value)
                    ? (_openBlock(), _createBlock(PercentageBar, {
                        key: 0,
                        percentage: tableRow.percentage.value
                      }, null, 8, ["percentage"]))
                    : _createCommentVNode("", true)
                ])
              ], 2))
            }), 128))
          ]))
        }), 128))
      ], 4),
      (_unref(toBool)(table.showMoreButton?.value))
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "toggle-button my-6 flex items-center bg-none border-[none] text-base-bold text-racq-blue w-fit",
            onClick: _withModifiers(toggleContent, ["stop","prevent"])
          }, [
            _createTextVNode(_toDisplayString(isExpanded.value ? table.showLessButtonText?.value : table.showMoreButtonText?.value) + " ", 1),
            _createElementVNode("img", {
              src: _unref(chevronDown),
              alt: "chevron icon",
              class: _normalizeClass(["w-[12px] h-[7px] ml-2 [transition:transform_0.5s_ease]", isExpanded.value ? 'transform rotate-180' : ''])
            }, null, 10, _hoisted_5)
          ]))
        : _createCommentVNode("", true)
    ]))
  }), 128))
}
}

})