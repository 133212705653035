import { IQuote } from '@/interfaces';
import { IFormStep } from '@/interfaces';
import { useAnalyticsStore, useAccountStore } from '@/store';
//import { useFormStore, useAccountStore } from '@/store';
import { QuoteTypes } from '@/constants/quote-types.const';
import { getInsuranceTypeDisplayName } from './editable-steps/quote-content-builder';
import { PaymentFrequency } from '@/enums/payment-frequency';
import { PaymentTypes } from '@/enums/payment-types.enum';
import { YES, NO } from '@/constants/general-words.const';

import {
  IFormAnalytics,
  IAnalyticsForm,
  IAnalyticsProfileInfo,
  IAnalyticsDetail,
  IAnalyticsPaymentDetail,
  IAnalyticsNavigation,
} from '@/interfaces/forms/analytics-interface';
import { FormEvents } from '@/enums/form-events.enum';

export class FormAnalyticsBuilder {
  private _quote: Partial<IQuote>;
  formAnalytics: IFormAnalytics;

  analyticsStore = useAnalyticsStore();

  constructor(quote: Partial<IQuote>, event: FormEvents, translation: any) {
    const dictionary: Record<string, string> = translation.getLocaleMessage('en') as any;
    this._quote = quote;

    this.formAnalytics = {} as IFormAnalytics;
    this.formAnalytics.form = {} as IAnalyticsForm;
    this.formAnalytics.event = event;
    this.formAnalytics.form.referenceID = quote.reference ?? '';

    if (quote.quoteType) {
      this.formAnalytics.form.productName = getInsuranceTypeDisplayName(dictionary, QuoteTypes[quote.quoteType]);
    }
  }

  populateProfileInfo() {
    const accountStore = useAccountStore();

    this.formAnalytics.profileInfo = {} as IAnalyticsProfileInfo;
    this.formAnalytics.profileInfo.contactID = accountStore.identityDetails?.d365Id ?? '';
    this._quote.primaryPolicyHolderDetails?.apttusId?.value ?? '';
    this.formAnalytics.profileInfo.insuranceHoldings = this._quote.coverageSummaries?.length?.toString() ?? '';
    this.formAnalytics.profileInfo.memberLevel =
      this._quote.primaryPolicyHolderDetails?.membershipLoyaltyLevel?.value ?? '';
    this.formAnalytics.profileInfo.postcode = this._quote.primaryPolicyHolderDetails?.postcode.value ?? '';
  }

  populateHomeContentsBuildingDetails() {
    this.formAnalytics.form.detail = {} as IAnalyticsDetail;
    this.formAnalytics.form.detail.buildingExcess = this._quote.homeAndContentsQuote?.homeQuote?.standardExcess?.value;
    this.formAnalytics.form.detail.contentsExcess =
      this._quote.homeAndContentsQuote?.contentsQuote?.standardExcess?.value;
    this.formAnalytics.form.detail.excess = this.calcTotalExcess(this._quote);
    this.formAnalytics.form.detail.contentsCost =
      this._quote.homeAndContentsQuote?.contentsQuote?.generalContentsReplacementAmount.value?.toString();
    this.formAnalytics.form.detail.homeValue =
      this._quote.homeAndContentsQuote?.homeQuote?.totalRebuildCost?.value?.toString();
    this.formAnalytics.form.detail.occupancyType = this._quote.homeAndContentsQuote?.propertyOwnershipType.value;
  }

  populatePaymentDetails(isActualPayment?: boolean, creditCardProvider?: string) {
    const paymentFrequency = PaymentFrequency[this._quote.paymentFrequency ?? 0];
    let amountPaid;
    if (this._quote?.paymentFrequency === PaymentFrequency.Annually) {
      amountPaid = this._quote?.insuranceQuoteSummary?.yearlyAnnualPremium as number;
    } else {
      amountPaid = this._quote?.insuranceQuoteSummary?.monthlyDeposit as number;
    }

    this.formAnalytics.payment = {} as IAnalyticsPaymentDetail;
    this.formAnalytics.payment.paymentID = this._quote?.paymentReceiptNumber ?? '';
    this.formAnalytics.payment.amountAnnual = this._quote.insuranceQuoteSummary?.yearlyAnnualPremium ?? 0;
    this.formAnalytics.payment.amountPaid = amountPaid;
    this.formAnalytics.payment.paymentFrequency = paymentFrequency;

    if (isActualPayment) {
      this.formAnalytics.payment.autoRenew = this._quote?.renewalPreference ? 'On' : 'Off';

      if (creditCardProvider) {
        this.formAnalytics.payment.creditCardProvider = creditCardProvider;
      }

      if (this._quote.paymentType) {
        this.formAnalytics.payment.paymentMethod = PaymentTypes[this._quote.paymentType];
      }

      this.formAnalytics.payment.policyBind =
        this._quote.policyReference && this._quote.policyReference?.length > 0 ? YES : NO;
    }
  }

  populateNavigation(name: string, url: string) {
    this.formAnalytics.eventInfo = {};
    this.formAnalytics.eventInfo.navigation = {} as IAnalyticsNavigation;
    this.formAnalytics.eventInfo.navigation.name = name;
    this.formAnalytics.eventInfo.navigation.URL = url;
  }

  populateJeopardy(errorType: string, errorDetails: string) {
    this.formAnalytics.form.formErrorType = errorType;
    this.formAnalytics.form.formErrorDetail = errorDetails;
  }

  populatePaymentAttempt(paymentAttempt: number) {
    this.formAnalytics.payment = {} as IAnalyticsPaymentDetail;
    this.formAnalytics.payment.paymentAttempt = paymentAttempt?.toString();
  }

  calcTotalExcess(quote: Partial<IQuote>) {
    return (
      (quote.homeAndContentsQuote?.homeQuote?.standardExcess?.value ?? 0) +
      (quote.homeAndContentsQuote?.contentsQuote?.standardExcess?.value ?? 0)
    );
  }

  pushAnalytics(appendToStepName?: string) {
    this.analyticsStore.pushAnalytics(this.formAnalytics, appendToStepName);
  }
}
