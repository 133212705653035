import { defineStore } from 'pinia';
import { useFormStore, useAccountStore } from '@/store';
import { IDecisionIntelligenceAnalytics, IFormAnalytics } from '@/interfaces/forms/analytics-interface';
import { FormEvents } from '@/enums/form-events.enum';

declare global {
  interface Window {
    adobeDataLayer: any[];
  }
}

export const useAnalyticsStore = defineStore('Analytics', () => {
  const formStore = useFormStore();
  const accountStore = useAccountStore();

  /**
   * Push data to adobe data layer
   *
   * @param options
   */
  const pushAnalytics = async (data: IFormAnalytics, appendToStepName?: string) => {
    if (typeof window === 'undefined' || !data?.event) return;

    // Set authentication status
    data.form.authStatus = (await accountStore.isAuthenticated) ? 'Logged in' : 'Not logged in';

    // Set form name if not already provided
    data.form.name ??= formStore.pageTitle;

    populateStepName(data, appendToStepName);

    // Set profile info if identity details exist
    if (accountStore.identityDetails?.d365Id) {
      data.profileInfo = {
        contactID: accountStore.identityDetails?.d365Id,
      };
    }

    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push(data);
  };

  /**
   * Populate step name
   *
   * @param data
   * @param appendToStepName
   */
  const populateStepName = (data: IFormAnalytics, appendToStepName?: string) => {
    if (!data.form.stepName && data.event != FormEvents.JEOPARDY) {
      if (data.event === FormEvents.START) {
        data.form.stepName = 'FormStarted';
      } else if (data.event === FormEvents.COMPLETE) {
        data.form.stepName = 'FormCompleted';
      } else if (formStore.currentStep?.stepName) {
        data.form.stepName = appendToStepName
          ? `${formStore.currentStep?.stepName}-${appendToStepName}`
          : formStore.currentStep?.stepName;
      }
    }
  };

  /**
   * Push data to adobe data layer
   *
   * @param options
   */
  const pushDecisionIntelligenceAnalytics = (data: IDecisionIntelligenceAnalytics) => {
    if (typeof window === 'undefined') return;
    if (!data?.event) return;

    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push(data);
  };

  return {
    pushAnalytics,
    pushDecisionIntelligenceAnalytics,
  };
});
