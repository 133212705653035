import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "top-0 sticky z-10 header-container lg:border-b border-solid border-light-grey bg-racq-true-white" }
const _hoisted_2 = { class: "forms-header w-full" }
const _hoisted_3 = { class: "header-logo" }
const _hoisted_4 = { class: "form-title" }
const _hoisted_5 = { class: "hidden ml-auto mr-[18px] lg:block xl:hidden w-[300px]" }
const _hoisted_6 = { class: "help-content" }

import { computed, onMounted } from 'vue';
import {
  Text as ScText,
  Image as ScImage,
  Link as ScLink,
  ComponentFields,
  ComponentRendering,
  Field,
} from '@sitecore-jss/sitecore-jss-vue';
import FormStepperBar from '@/components/FormStepperBar/FormStepperBar.vue';
import { useFormStore } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  props: {
    fields: {},
    rendering: {},
    pageTitle: { default: '' }
  },
  setup(__props: any) {

const formStore = useFormStore();

const props = __props;

const pageTitle = computed(() => {
  const pageTitle = props.fields?.pageTitle as Field<any>;
  return pageTitle?.value ? pageTitle : { value: props.pageTitle };
});

onMounted(() => {
  const pageTitleField = pageTitle.value;
  formStore.setPageTitle(pageTitleField.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(ScLink), {
          style: {"font-size":"0"},
          class: "flex",
          field: _ctx.fields.logoLink ? _ctx.fields.logoLink : {}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(ScImage), {
              media: _ctx.fields.racqLogo
            }, null, 8, ["media"])
          ]),
          _: 1
        }, 8, ["field"])
      ]),
      _createElementVNode("h1", _hoisted_4, [
        _createVNode(_unref(ScText), { field: pageTitle.value }, null, 8, ["field"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_unref(formStore).showStepper)
          ? (_openBlock(), _createBlock(FormStepperBar, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_unref(ScLink), {
          field: _ctx.fields.helpContentLink ? _ctx.fields.helpContentLink : {}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(ScImage), {
              media: _ctx.fields.helpLogo
            }, null, 8, ["media"])
          ]),
          _: 1
        }, 8, ["field"])
      ])
    ])
  ]))
}
}

})