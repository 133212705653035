<template>
  <RichText :field="membershipBenefit?.fields.membershipBenefitHeading" class="text-lg-bold pb-5" />
  <RichText
    :field="{ value: formatText(membershipBenefit?.fields.membershipBenefitSummaryText.value) }"
    class="text-base pb-2"
  />

  <ul v-if="showIcons" class="benefits">
    <li v-for="(benefitIcon, index) of membershipBenefit?.fields.benefitIcons" :key="index" class="flex mb-2">
      <div class="w-[100%]">
        <BaseIcon :iconClass="benefitIcon?.fields.contentIcon.value" class="icon"></BaseIcon>
      </div>
      <div class="w-[100%] text-base-bold">{{ benefitIcon.name }}</div>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import BaseIcon from '@/components/BaseIcon/BaseIcon.vue';
import { QuoteTypes } from '@/constants/quote-types.const';
import { getInsuranceTypeDisplayName } from '@/utils/editable-steps/quote-content-builder';
import { useI18n } from 'vue-i18n';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

const translation = useI18n();
const dictionary: Record<string, string> = translation.getLocaleMessage('en') as any;

const props = defineProps<{
  membershipBenefit: any;
  showIcons: boolean;
  quoteType: QuoteTypes;
}>();

function formatText(text: string) {
  if (!text) return text;
  return text.replaceAll('{0}', getInsuranceTypeDisplayName(dictionary, props.quoteType));
}
</script>

<style>
.benefits {
  .icon {
    height: 3.6rem !important;
    width: 3.6rem !important;
  }
}

ul.benefits {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  flex-wrap: wrap;
}

ul.benefits li {
  width: 25%;
  border-radius: 0.2rem;
  border: none;
  margin-right: 0rem;
  padding: 0.1rem 0.1rem;
  display: block;
  text-align: center;
}
</style>
