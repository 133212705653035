import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = ["innerHTML"]

import { onMounted, watch, ref } from 'vue';
import { useAnalyticsStore, usePaymentStore, useFormStore } from '@/store';
import { useI18n } from 'vue-i18n';
import { FormEvents } from '@/enums/form-events.enum';
import { IFormStep } from '@/interfaces';
import { FormAnalyticsBuilder } from '@/utils/form-analytics-builder';

//Invalid Credit Card FatZebra response codes to check against

export default /*@__PURE__*/_defineComponent({
  __name: 'IQPaymentError',
  props: {
    step: {},
    paymentSuccessful: { type: Boolean },
    errors: {}
  },
  setup(__props: any) {

const invalid_CC_Codes = [
  '04',
  '05',
  '06',
  '07',
  '12',
  '22',
  '34',
  '35',
  '36',
  '37',
  '41',
  '43',
  '56',
  '59',
  '62',
  '82',
  '93',
];

//Failed Credit Card Transaction FatZebra response codes to check against
const failed_TR_codes = ['01', '02', '13', '33', '39', '51', '54', '61', '65'];

const paymentStore = usePaymentStore();
const analyticsStore = useAnalyticsStore();
const formStore = useFormStore();

const translation = useI18n();
const dictionary: Record<string, string> = translation.getLocaleMessage('en') as any;

const displayErrors = ref<string[]>([]);

const props = __props;

watch(() => props.errors, processError);

onMounted(() => {
  processError();
});

function processError() {
  if (props.errors && props.errors.length) {
    const isPaymentAttempt = processCardPaymentErrors();

    if (!isPaymentAttempt) {
      displayErrors.value.push(dictionary.IQ_PAYMENT_UNSPECIFIED_ERROR);
    }

    if (isPaymentAttempt) {
      analyticsStore.pushAnalytics({
        event: FormEvents.PAYMENT_ATTEMPT,
        form: {},
        payment: { paymentAttempt: paymentStore.paymentRetryAttempts?.toString() },
      });
    } else {
      analyticsStore.pushAnalytics({
        event: FormEvents.PAYMENT_ATTEMPT,
        form: {
          formErrorType: 'form error',
          formError: 'Unspecified error',
          formErrorDetail: `${props.step.id}`,
        },
      });
    }
  }
}

function processCardPaymentErrors() {
  if (props.errors && props.errors.length > 0 && props.errors[0].errorType) {
    const isValidationDeclined = props.errors.find(
      (x) => x.errorDescription.toLowerCase().indexOf('validation declined') > -1
    );

    if (!isValidationDeclined) {
      displayErrors.value.push(dictionary.IQ_PAYMENT_CARD_ERROR_OTHER);
    }

    const isFatZebraError = props.errors.find((x) => x.errorDescription.indexOf('__') > -1);
    const fatZebraCode = isFatZebraError
      ? isFatZebraError?.errorDescription?.substr(0, isFatZebraError.errorDescription.indexOf('__'))
      : '';

    if (invalid_CC_Codes.findIndex((x) => x == fatZebraCode)) {
      displayErrors.value.push(dictionary.IQ_PAYMENT_CARD_ERROR_INVALID_CC);
    } else if (failed_TR_codes.findIndex((x) => x == fatZebraCode) > -1) {
      displayErrors.value.push(dictionary.IQ_PAYMENT_CARD_ERROR_FAILED_TR);
    } else {
      displayErrors.value.push(dictionary.IQ_PAYMENT_CARD_ERROR_OTHER);
    }

    return displayErrors.value.length > 0;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (displayErrors.value.length > 0 && !_ctx.paymentSuccessful)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "px-[30px] py-[20px] mb-2 border-2 rounded bg-red-100 mt-4 w-100",
          innerHTML: displayErrors.value
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}
}

})