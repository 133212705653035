<template>
  <div class="flex w-full">
    <div v-if="shouldDisplayDisclaimer" class="font-sans text-paragraph-color richtext richtext--no-base text-m mb-13">
      <!-- Display default disclaimer if no step disclaimers are available -->
      <template v-if="!stepDisclaimers.length">
        <RichText :field="{ value: fields?.defaultDisclaimer || '&nbsp;' }" />
      </template>

      <!-- Display disclaimers from the store if they exist -->
      <template v-if="stepDisclaimers.length">
        <p class="text-lg !font-medium font-sans leading-7">{{ dictionary.DisclaimerHeading }}</p>
        <template v-for="(disclaimer, key) in stepDisclaimers" :key="key">
          <RichText :field="{ value: disclaimer || '&nbsp;' }" tag="p" />
        </template>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useFormStepDisclaimerStore } from '@/store';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

// Define props and emitters
// ------------------------------------------------------------- //
const props = defineProps<{
  fields: {
    stepTitle?: string;
    defaultDisclaimer?: string;
  };
  testParams?: {
    mockDisclaimerHeading: string;
    mockFormStepDisclaimerStoreResponse: string[];
  };
}>();

// Define component state
// ------------------------------------------------------------- //
const translation = useI18n();
const dictionary: Record<string, string> = translation.getLocaleMessage('en') as any;
const formStepDisclaimerStore = useFormStepDisclaimerStore();

if (props.testParams) {
  formStepDisclaimerStore.additionalDisclaimer.length = props.testParams.mockFormStepDisclaimerStoreResponse.length;

  dictionary.DisclaimerHeading = props.testParams.mockDisclaimerHeading;
}
// Computed properties
// ------------------------------------------------------------- //
const stepDisclaimers = computed(() => {
  let disclaimers = formStepDisclaimerStore.getDisclaimersForStep(props.fields.stepTitle);

  if (props.testParams) {
    disclaimers = props.testParams.mockFormStepDisclaimerStoreResponse;
  }
  return disclaimers;
});

// Computed property to determine if the disclaimer should be shown
const shouldDisplayDisclaimer = computed(() => {
  return props.fields?.defaultDisclaimer || formStepDisclaimerStore?.additionalDisclaimer?.length;
});
</script>
