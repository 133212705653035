export const JP_GENERIC_CODE = 'JP_GENERIC';
export const JP_EXCEPTION = 'JP_EXCEPTION';
export const JP_LOGGEDOUT = 'JP_LOGGEDOUT';
export const JP_DOD_DISAGREE = 'JP_DOD_DISAGREE';
export const JP_CHANGENOTALLOWED = 'JP_CHANGENOTALLOWED';
export const JP1 = 'JP1'; // Unacceptable vehicle value/points/accept code
export const JP_DISAGREE_CONDITIONS = 'JP31'; //User does not agree to General conditions when purchasing a quote
export const JP_PAYMENT_MAX_ATTEMPTS_REACHED = 'JP_PAYMENT_MAX_ATTEMPTS_REACHED';
export const JP79 = 'JP79';

// JOR specific jeopardy codes
export const JP_JOR_GENERIC = 'JP_JOR_GENERIC';
export const JP_JOR_TECH_ERROR = 'JP_JOR_TECH_ERROR';
export const JP_JOR_INELIGIBLE_VEHICLE = 'JP_JOR_INELIGIBLE_VEHICLE'; // Ineligible vehicle for RSA (too long, too heavy, etc.)
export const JP_JOR_EMERG = 'JP_JOR_EMERG'; // Jeopardy users in JOR form when they are in critical situation
export const JP_JOR_CREATE_JOB_FAIL = 'JP_JOR_CREATE_JOB_FAIL'; // Jeopardy when the Create Roadside job fails

// IQMQ specific jeopardy codes
export const JP_RIDE_SHARE = 'JP_RIDE_SHARE';
