<template>
  <SectionFrame :sectionTitle="brandDetails.title" class="bg-racq-anti-flash-white">
    <template #sectionFrameContent>
      <div class="py-3">{{ brandDetails.subTitle }}</div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
        <div
          v-for="(brand, index) of brandDetails.brands"
          :key="index"
          class="grid grid-cols-[15%_75%_10%] md:grid-cols-[20%_70%_10%] rounded border-solid border-racq-pale-blue-border bg-racq-true-white border-2 py-5 px-3 mb-1"
        >
          <img :src="brand.fields.brand.value.src" :alt="brand.fields.brand.value.alt" class="h-[auto] w-[46px] mt-1" />
          <RichText :field="{ value: brand.fields.benefit.value }" class="overflow-ellipsis"></RichText>
          <div class="m-auto float-right">
            <Link
              v-if="brandDetails?.benefitsLink"
              :field="brandDetails?.benefitsLink"
              @click="() => pushAnalytics(`${OrdinalNumbers[index]} link`)"
            >
              <img src="@/assets/icons/arrow-right.svg" alt="Right pointing arrow icon" class="h-[20px] w-[auto]" />
            </Link>
          </div>
        </div>
      </div>
      <Link :field="brandDetails?.benefitsLink" @click="() => pushAnalytics(brandDetails.moreOffersText)">{{
        brandDetails.moreOffersText
      }}</Link>
    </template>
  </SectionFrame>
</template>

<script lang="ts" setup>
import { RichText, Link } from '@sitecore-jss/sitecore-jss-vue';
import { useI18n } from 'vue-i18n';
import SectionFrame from '@/components/SectionFrame/SectionFrame.vue';
import { IFormStep, IQuote } from '@/interfaces';
import { FormAnalyticsBuilder } from '@/utils/form-analytics-builder';
import { FormEvents } from '@/enums/form-events.enum';
import { OrdinalNumbers } from '@/constants/ordinal-numbers.const';

export interface IBenefitsBrands {
  brands: any[];
  benefitsLink: any;
  title: string;
  subTitle: string;
  moreOffersText: string;
}

const translation = useI18n();

const props = defineProps<{
  brandDetails: IBenefitsBrands;
  step: IFormStep;
  quote: IQuote;
}>();

function pushAnalytics(name: string) {
  const formAnalytics = new FormAnalyticsBuilder(props.quote, FormEvents.CROSS_SELL_CLICK, translation);
  formAnalytics.populateNavigation(name, props.brandDetails?.benefitsLink?.value?.url);
  formAnalytics.pushAnalytics();
}
</script>

<style>
.overflow-ellipsis p {
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
}
</style>
