<template>
  <FormKit v-model="formData" type="form" @submit="() => {}">
    <RichText class="text-racq-navy text-xl2" :field="paymentStepLabel" />
    <DollarAmount :amount="premium" :showStarSign="false">
      <span v-if="paymentFrequencyPriceSuffix" class="mx-2 text-sm-bold">{{ paymentFrequencyPriceSuffix }}</span>
    </DollarAmount>

    <RichText class="text-sm" :field="premiumLabel" />

    <hr />

    <RACQFormKit :bindings="formInputData('paymentFrequency')" />

    <RACQFormKit v-if="autoRenewIsEnabled" :bindings="formInputData('futureRenewal')" class="mt-6" />

    <RACQFormKit :bindings="formInputData('paymentTermsAndConditions')" class="my-6" />

    <IQPaymentError :step="step" :paymentSuccessful="paymentSuccessful" :errors="errors"></IQPaymentError>

    <SectionFrame v-if="!paymentSuccessful" :sectionTitle="step.formInputs?.paymentMethod?.SectionTitle?.value">
      <template #sectionFrameContent>
        <PaymentSection v-bind="step.formInputs?.paymentMethod?.fields[0]"></PaymentSection>
      </template>
    </SectionFrame>
  </FormKit>
</template>

<script lang="ts">
export const emitUpdatePaymentDetails = 'emitUpdatePaymentDetails';
export const emitSubmitQuote = 'emitSubmitQuote';
</script>

<script lang="ts" setup>
import { ref, onMounted, computed, watch } from 'vue';
import { IFormStep, IQuote, SubmitQuote } from '@/interfaces';
import {
  useFormStore,
  useJeopardyStore,
  useReferenceDataStore,
  usePaymentStore,
  useConfigSettingsStore,
  IQConfigSettings,
  SettingCategory,
} from '@/store';
import { PaymentTypes } from '@/enums/payment-types.enum';
import SectionFrame from '@/components/SectionFrame/SectionFrame.vue';
import PaymentSection from '@/components/PaymentSection/PaymentSection.vue';
import { JP_GENERIC_CODE } from '@/constants/jeopardy-codes.const';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import DollarAmount from '@/components/DollarAmount/DollarAmount.vue';
import RACQFormKit from '@/components/RACQFormKit/RACQFormKit.vue';
import useFormInputData from '@/composables/useFormInputData';
import { PaymentFrequency } from '@/enums/payment-frequency';
import { toCurrency } from '@/utils/convert';
import { useI18n } from 'vue-i18n';
import { QuoteTypes } from '@/constants/quote-types.const';
import IQPaymentError from '@/components/IQPaymentError/IQPaymentError.vue';

// Define props and emitters
// ------------------------------------------------------------- //
const props = defineProps<{
  step: IFormStep;
  quote: Partial<IQuote>;
  checkAutoRenewAvailable: boolean;
  paymentSuccessful: boolean;
  paymentFailedErrorMessage: string;
  paymentPanelContent: any;
  errors: any[];
}>();
// Define Store
// ------------------------------------------------------------- //
const formStore = useFormStore();
const jpStore = useJeopardyStore();
const paymentStore = usePaymentStore();
const refStore = useReferenceDataStore();
const configSettingsConfigStore = useConfigSettingsStore();

const translation = useI18n();
const dictionary: Record<string, string> = translation.getLocaleMessage('en') as any;

const emit = defineEmits([emitUpdatePaymentDetails, emitSubmitQuote]);
const paymentFrequencyPriceSuffix = ref<string>('');

// Define Composables
// ------------------------------------------------------------- //
const { formInputData } = useFormInputData(props.step.formInputs!);
const premiumLabel = computed(() => {
  if (!props.quote || !props.quote.insuranceQuoteSummary || !props.quote.discounts) return;

  let value = isAnnual.value
    ? props.step.formInputs?.annualPremiumLabel?.label?.value
    : props.step.formInputs?.monthlyPremiumLabel?.label?.value;
  value = value.replace('{{annualPremium}}', toCurrency(props.quote?.insuranceQuoteSummary?.yearlyAnnualPremium));
  value = value.replace('{{instalmentFee}}', toCurrency(props.quote?.insuranceQuoteSummary?.payByTheMonthFee / 12));

  if (isAnnual.value)
    value = value.replace(
      '{{onlineDiscount}}',
      dictionary.IQ_OnlineDiscount.replace(
        '$#value#',
        toCurrency(props.quote.discounts?.totalYearlyOnlineDiscountAmount?.value)
      )
    );
  else
    value = value.replace(
      '{{onlineDiscount}}',
      dictionary.IQ_OnlineDiscount.replace(
        '$#value#',
        toCurrency(props.quote.discounts?.totalMonthlyOnlineDiscountAmount?.value)
      )
    );
  return {
    value,
  };
});

// Define Component state
// ------------------------------------------------------------- //
const formData = ref<Record<string, any>>({});
const insuranceType = ref<string>();
const isAnnual = computed(() => formData.value.paymentFrequency == PaymentFrequency.Annually.toString());
const autoRenewIsEnabled = ref<boolean>(false);

const premium = computed(() => {
  if (isAnnual.value) {
    return props.quote?.insuranceQuoteSummary?.yearlyAnnualPremium;
  } else {
    return props.quote?.insuranceQuoteSummary?.monthlyDeposit;
  }
});

const paymentStepLabel = computed(() => {
  let value = props.step.formInputs?.paymentTitle?.label?.value;
  value = value?.replace('{{quoteType}}', insuranceType.value);
  return {
    value,
  };
});

// Lifecycle hooks
// ------------------------------------------------------------- //
onMounted(async () => {
  const submissionRetries = (await configSettingsConfigStore.getSetting(
    IQConfigSettings.PaymentSubmissionRetries,
    SettingCategory.IQSettings
  )) as number;
  paymentStore.maxRetries = submissionRetries ?? 3;
  await initialisePayment();
});

watch(
  () => formData.value.paymentTermsAndConditions,
  (newValue) => {
    paymentStore.disablePaymentPanel = !newValue;
  }
);
watch(
  () => formData.value.paymentFrequency,
  () => {
    setPaymentPriceSuffix();
    setPaymentFrequencyPostContent();
  }
);
watch(() => formData.value.futureRenewal, setAutoRenewPostContent);
watch(
  () => paymentStore.paymentRetryAttempts,
  async function () {
    //This is done to reset the zebra iframe so payment can be attempted again.
    await resetPaymentIframe();
    await initialisePayment();

    if (paymentStore.hasExceededMaxPaymentAttempts()) {
      formStore.setDisableAllSteps();
    }
  }
);

// Define Functions
// ------------------------------------------------------------- //

async function initialisePayment() {
  if (props.checkAutoRenewAvailable) {
    autoRenewIsEnabled.value =
      formData.value.paymentFrequency == PaymentFrequency.Monthly &&
      ((await configSettingsConfigStore.getSetting(
        IQConfigSettings.IsHomeAutoRenewalPreferenceEnabled,
        SettingCategory.IQSettings
      )) as boolean);
  }

  /** Set dynamic contents */
  paymentStore.submitPaymentCallback = onSubmit;
  paymentStore.disablePaymentPanel = !formData.value.paymentTermsAndConditions;

  reverseMapPaymentDetails();

  setPaymentPriceSuffix();
  setDiscountLabel();
  setPaymentFrequencyPostContent();
  setAutoRenewPostContent();
  insuranceType.value = await refStore.getInsuranceProductName(QuoteTypes[props.quote.quoteType!]);

  /** Map payment details for FZ Iframe */
  paymentStore.amount = premium?.value?.toString() ?? '0';
  paymentStore.buttonText = props.step.formInputs?.paymentMethod?.fields[0]?.SubmitPaymentButtonLabel?.value;
  paymentStore.reference = props.quote?.reference ?? '';
}

function setDiscountLabel() {
  const paymentSection = props.step.formInputs?.paymentMethod?.fields[0];

  if (!paymentSection) return; // Safeguard against undefined `paymentSection`

  if (isAnnual.value) {
    paymentSection.PayLaterContent.value = paymentSection.PayLaterContent?.value.replace(
      '{{onlineDiscount}}',
      dictionary.IQ_OnlineDiscount.replace(
        '$#value#',
        toCurrency(props.quote.discounts?.totalYearlyOnlineDiscountAmount?.value)
      )
    );
  } else {
    paymentSection.PayLaterContent.value = paymentSection.PayLaterContent?.value.replace(
      '{{onlineDiscount}}',
      dictionary.IQ_OnlineDiscount.replace(
        '$#value#',
        toCurrency(props.quote.discounts?.totalMonthlyOnlineDiscountAmount?.value)
      )
    );
  }
}

function setPaymentPriceSuffix() {
  const optionsValue = props.step.formInputs?.paymentFrequencyPricePrefix?.options.value;
  if (!optionsValue) return;

  const pricePrefixes = Object.fromEntries(new URLSearchParams(optionsValue));
  const enumKey = PaymentFrequency[formData.value.paymentFrequency];

  paymentFrequencyPriceSuffix.value = pricePrefixes[enumKey] || '';
}

function setPaymentFrequencyPostContent() {
  if (!props.quote?.insuranceQuoteSummary) return;

  let value =
    formData.value.paymentFrequency === PaymentFrequency.Monthly
      ? props.step.formInputs?.paymentFrequencyMonthlyPostContent?.label?.value
      : props.step.formInputs?.paymentFrequencyAnnuallyPostContent?.label?.value;
  value = value.replace('{{annualAmount}}', toCurrency(props.quote?.insuranceQuoteSummary?.yearlyAnnualPremium));
  value = value.replace('{{monthlyAmount}}', toCurrency(props.quote?.insuranceQuoteSummary?.monthlyDeposit));

  // Show post content
  formStore.updateInput({
    inputId: 'paymentFrequency',
    updates: {
      showPostContent: true,
      postContent: {
        value,
      },
    },
  });
}

async function setAutoRenewPostContent() {
  if (!autoRenewIsEnabled.value) return;
  const value =
    formData.value.futureRenewal == 'true'
      ? props.step.formInputs?.futureRenewalOn?.label?.value
      : props.step.formInputs?.futureRenewalOff?.label?.value;
  // Show post content
  formStore.updateInput({
    inputId: 'futureRenewal',
    updates: {
      showPostContent: true,
      postContent: {
        value,
      },
    },
  });
}

function reverseMapPaymentDetails() {
  formData.value.paymentFrequency = props.quote.paymentFrequency;
  formData.value.futureRenewal = props.quote.renewalPreference?.toString();
}

function mapPaymentDetails() {
  emit(
    emitUpdatePaymentDetails,
    formData.value.paymentFrequency,
    formData.value.futureRenewal,
    paymentStore.selectedPaymentMethod
  );
}

function showJeopardyPanel(jpCode: string) {
  jpStore.showJeopardyPanel({
    jpCode: jpCode,
  });
}

async function resetPaymentIframe() {
  paymentStore.reference = '';
}

async function onSubmit() {
  try {
    if (paymentStore.paymentRetryAttempts >= paymentStore.maxRetries) return;

    mapPaymentDetails();

    let submitQuote = null;

    switch (props.quote.paymentType) {
      case PaymentTypes.CreditCard:
        submitQuote = new SubmitQuote().createSubmitQuoteUsingCardPaymentDetails(paymentStore.cardDetails);
        break;
      case PaymentTypes.BankAccount:
        if (paymentStore.directDebitDetails) {
          submitQuote = new SubmitQuote().createSubmitQuoteUsingBankAccountDetails(paymentStore.directDebitDetails);
        }
        break;
      default:
        submitQuote = new SubmitQuote();
        break;
    }

    if (!submitQuote) {
      throw JP_GENERIC_CODE;
    }

    emit(emitSubmitQuote, submitQuote);
  } catch (jPCode: any) {
    formStore.showLoadingForCurrentStep(false);
  }
}
</script>
