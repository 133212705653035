<template>
  <div
    v-if="decisionOffer?.propositions != null && decisionOffer?.propositions.length"
    class="flex gap-4 md:flex-row sm:flex-col text-dark-grey-text"
  >
    <div
      v-for="(proposition, key) in decisionOffer.propositions"
      :key
      class="flex-1 px-8 py-4 border-solid border border-racq-white rounded"
    >
      <div class="flex gap-12 sm:gap-6">
        <div class="w-full">
          <RichText class="mb-4 text-base-bold" :field="{ value: getFilterStep(proposition).headerText }" />
          <p class="text-sm py-0 mb-0">
            <RichText :field="{ value: getFilterStep(proposition).messageText }" />
          </p>
        </div>
        <img :src="getFilterStep(proposition).imageUrl" class="h-16 w-16" alt="offer image" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  IDecisionIntelligenceOfferResponse,
  IProposition,
  IStep,
} from '@/interfaces/decision-intelligence/decision-intelligence-response';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

// Define props and emitters
// ------------------------------------------------------------- //
const props = defineProps<{
  decisionOffer?: IDecisionIntelligenceOfferResponse;
  stepTitle?: string;
}>();

// Define Functions
// ------------------------------------------------------------- //
const getFilterStep = (proposition: IProposition): IStep => {
  return proposition.displaySteps.filter((x) => x.stepName.toLowerCase() == props.stepTitle?.toLowerCase())[0];
};
</script>
