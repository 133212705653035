import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import configSettingsQuery from './configsettings.query.graphql';
import { useApolloClient } from '@vue/apollo-composable';

import { toBool } from '@/utils/convert';
import { toNumber } from 'lodash-es';

export enum IQConfigSettings {
  PaymentSubmissionRetries = 'PaymentSubmissionRetries',
  EnablePurchaseEmail = 'EnablePurchaseEmail',
  EnableQuoteEmail = 'EnableQuoteEmail',
  IsHomeAutoRenewalPreferenceEnabled = 'IsHomeAutoRenewalPreferenceEnabled',
  RACQWebsite = 'RACQWebsite',
  HomeQuotePath = 'HomeQuotePath',
  JpMessageSuffix = 'JpMessageSuffix',
  MaxAdditionalAccessories = 'MaxAdditionalAccessories',
}
export enum CommonConfigSettings {
  WaitForPropositionsOfferInSeconds = 'WaitForPropositionsOfferInSeconds',
  WaitForRoadsidePrepayOfferInSeconds = 'WaitForRoadsidePrepayOfferInSeconds',
}

export enum SettingCategory {
  CommonSettings = 'Common Config Settings',
  IQSettings = 'IQ Config Settings',
}

export const useConfigSettingsStore = defineStore('ConfigSettings', () => {
  const configSettingName = ref<string>(IQConfigSettings.EnablePurchaseEmail);
  const configCategory = ref<string>(SettingCategory.IQSettings);
  const iqConfigSettingsFolder = computed(
    () =>
      '/sitecore/content/RACQFormsGroup/RACQForms20/Settings/Config Settings Folder/' +
      configCategory.value +
      '/' +
      configSettingName.value
  );
  const { resolveClient } = useApolloClient();

  const getSetting = async (
    settingName: string,
    settingCategory: SettingCategory
  ): Promise<string | number | boolean | undefined> => {
    configCategory.value = settingCategory;
    configSettingName.value = settingName;
    const client = resolveClient();
    const result = await client.query({
      query: configSettingsQuery,
      variables: {
        path: iqConfigSettingsFolder.value,
      },
    });
    const setting = result?.data?.item?.fields?.filter((x: any) => x.name == 'Value')[0]?.value;
    if (setting) {
      const isBool = setting.toLowerCase() == 'true' || setting.toLowerCase() == 'false';

      if (isBool) return toBool(setting);
      if (parseFloat(setting)) return toNumber(setting);
      return setting;
    }
    return;
  };

  return {
    getSetting,
    configSettingName,
    configCategory,
  };
});
