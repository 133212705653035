import { defineStore } from 'pinia';
import { CancelRoadsideAssistanceFormService as _cancelRoadsideAssistanceFormService } from '@/services/store/cancel-roadside-assistance-form.service';
import { CancelJobRequest, CancelRoadsideAssistanceJob } from '@/interfaces/pages/cancel-roadside-form.interface';
import { ref } from 'vue';

export const useCancelRoadsideAssistanceStore = defineStore('CancelRoadsideAssistance', () => {
  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //
  const jobID = ref<string>('');
  const comments = ref<string>('');

  async function cancelJob(): Promise<CancelRoadsideAssistanceJob> {
    const cancelJobRequest: CancelJobRequest = {
      jobID: jobID.value,
      comments: comments.value,
    };
    return await _cancelRoadsideAssistanceFormService.cancelRoadsideAsssitanceJob(cancelJobRequest);
  }

  function $reset() {
    jobID.value = '';
    comments.value = '';
  }

  function clearComments() {
    comments.value = '';
  }

  return {
    jobID,
    comments,
    cancelJob,
    clearComments,
    $reset,
  };
});
