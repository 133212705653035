import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import { ITableGroup } from '@/interfaces/entities/table-group.interface';
import TableControlItem from './TableControlItem.vue';

// Define props and emitters
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'TableControlGroup',
  props: {
    tableData: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.tableData)
      ? (_openBlock(), _createBlock(TableControlItem, {
          key: 0,
          tableData: _ctx.tableData
        }, null, 8, ["tableData"]))
      : _createCommentVNode("", true)
  ]))
}
}

})