import { IQuote } from '@/interfaces';
import { useLiveChatStore } from '@/store';

export enum loyaltyReviewContent {
  headingTriedToValidate = 'headingTriedToValidate',
  headingPrimaryHolderFound = 'headingPrimaryHolderFound',
  headingSecondaryHolderFound = 'headingSecondaryHolderFound',
  textMemberLevel = 'textMemberLevel',
  textMultiMemberLevel = 'textMultiMemberLevel',
  textRoadsideAdded = 'textRoadsideAdded',
  textNotAMember = 'textNotAMember',
  textNewQuoteCost = 'textNewQuoteCost',
  textHelpNoChat = 'textHelpNoChat',
  textHelpWithChat = 'textHelpWithChat',
  textDiscount = 'textDiscount',
}

const blankLoyaltyResponse = {
  skipLoyaltyReviewStep: false,
  useHeading: '',
  useLoyaltyText: '',
  useNewQuoteCostText: loyaltyReviewContent.textNewQuoteCost,
  useHelpText: '',
};

export const displayLoyaltyReview = (quote: IQuote, originalQuote?: IQuote) => {
  const liveChatStore = useLiveChatStore();
  let useHeading;
  let useLoyaltyText;

  if (!quote || !originalQuote) {
    return blankLoyaltyResponse;
  }

  const highestLoyaltyLevelBeforePartyMatching = originalQuote.discounts?.highestMembershipLoyaltyLevel.value;
  const highestLoyaltyLevelAfterPartyMatching = quote.discounts?.highestMembershipLoyaltyLevel.value;
  const primaryPolicyHolderLoyaltyLevelAfterPartyMatching =
    quote.primaryPolicyHolderDetails.membershipLoyaltyLevel.value;

  const hasRoadSideBeforePartyMatching =
    (originalQuote.primaryPolicyHolderDetails?.hasRoadsideProduct.value as boolean) ||
    originalQuote.additionalPolicyHolderDetails?.some((x) => x.hasRoadsideProduct.value);

  const hasRoadSideAfterPartyMatching =
    (quote.primaryPolicyHolderDetails?.hasRoadsideProduct.value as boolean) ||
    quote.additionalPolicyHolderDetails?.some((x) => x.hasRoadsideProduct.value);

  const eligibleForLoyaltyDiscount = quote.discounts?.eligibleForLoyaltyDiscount.value;

  const skipLoyaltyReviewStep =
    (highestLoyaltyLevelBeforePartyMatching === highestLoyaltyLevelAfterPartyMatching &&
      hasRoadSideBeforePartyMatching === hasRoadSideAfterPartyMatching) ||
    (!hasRoadSideBeforePartyMatching && !hasRoadSideAfterPartyMatching);

  if (hasRoadSideAfterPartyMatching && eligibleForLoyaltyDiscount) {
    if (highestLoyaltyLevelBeforePartyMatching !== highestLoyaltyLevelAfterPartyMatching) {
      if (highestLoyaltyLevelAfterPartyMatching === primaryPolicyHolderLoyaltyLevelAfterPartyMatching) {
        useHeading = loyaltyReviewContent.headingPrimaryHolderFound;
        useLoyaltyText = loyaltyReviewContent.textMemberLevel;
      } else {
        useHeading = loyaltyReviewContent.headingSecondaryHolderFound;
        useLoyaltyText = loyaltyReviewContent.textMultiMemberLevel;
      }
    } else {
      useHeading = loyaltyReviewContent.headingSecondaryHolderFound;
      useLoyaltyText = loyaltyReviewContent.textRoadsideAdded;
    }
  } else {
    useHeading = loyaltyReviewContent.headingTriedToValidate;
    useLoyaltyText = loyaltyReviewContent.textNotAMember;
  }

  const shouldShowHelpText =
    originalQuote.insuranceQuoteSummary && quote.insuranceQuoteSummary
      ? originalQuote.insuranceQuoteSummary?.yearlyAnnualPremium < quote.insuranceQuoteSummary?.yearlyAnnualPremium
      : false;
  const useHelpText = shouldShowHelpText
    ? liveChatStore.liveChatEnabled
      ? loyaltyReviewContent.textHelpWithChat
      : loyaltyReviewContent.textHelpNoChat
    : '';

  return {
    skipLoyaltyReviewStep: skipLoyaltyReviewStep,
    useHeading: useHeading,
    useLoyaltyText: useLoyaltyText,
    useNewQuoteCostText: loyaltyReviewContent.textNewQuoteCost,
    useHelpText: useHelpText,
  };
};

export const displayLoyaltyReviewUsingStore = (iqStore: any) => {
  return displayLoyaltyReview(iqStore.quote, iqStore.originalQuote);
};

export const hasQuotePremiumChanged = (originalQuote: Partial<IQuote>, quote: Partial<IQuote>) => {
  quote?.insuranceQuoteSummary?.yearlyAnnualPremium != originalQuote?.insuranceQuoteSummary?.yearlyAnnualPremium;
};
