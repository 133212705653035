import { defineStore } from 'pinia';
import { ReferenceDataServices as _refData } from '@/services/store/reference-data.services';
import { ref } from 'vue';
import { INameValue } from '@/interfaces/entities/name-value.interface';
import { INameCode } from '@/interfaces/entities/name-code.interface';
import { IDropdownItem } from '@/interfaces';

enum RefDataTypes {
  GENDERS = 'Genders',
  PERSON_TITLES = 'PersonTitles',
  INSURANCE_PRODUCTS = 'InsuranceProducts',

  PET_TYPES = 'PetTypes',
  PET_BREEDS = 'PetBreeds',

  MEMBERSHIP_LEVELS = 'MembershipLevels',

  HOME_CLAIM_TYPES = 'HomeClaimTypes',
  HOME_LOSS_TYPES = 'HomeLossTypes',
  HOME_LOSS_VALUES = 'HomeLossValues',
  HOME_ADVANCED_COVER = 'HomeAdvancedCover',
  HOME_ROOF_TYPES = 'HomeRoofTypes',
  HOME_PRIMARY_CONSTRUCTION_MATERIALS = 'HomePrimaryConstructionMaterials',
  HOME_OCCUPANCY_TYPES = 'HomeOccupancyTypes',
  HOME_INSURANCE_PRODUCTS = 'HomeInsuranceProducts',
  HOME_BUILDING_TYPES = 'HomeBuildingTypes',
  HOME_BUILDINGPROPERTYFEATURES = 'HomeAndContentBuildingPropertyFeatures',
  NUM_OF_STOREYS = 'NumOfStoreys',
  LOWEST_OCCUPIED_FLOOR = 'LowestOccupiedFloor',
  HOME_FLOOR_OCCUPANCY = 'HomeFloorOccupancy',
  HOME_GROUP_COVER_PRICE_BANDS = 'HomeGroupCoverPriceBands',
  HOME_SMALL_BUSINESS_INSURANCE_LIMIT_LEVEL = 'HomeSmallBusinessInsuranceLimitValue',
  HOME_EARTHQUAKE_EXCESSES = 'HomeEathquakeExcesses',
  HOME_PET_EXCESSES = 'HomePetExcesses',
  HOME_PORTABLE_EXCESSES = 'HomePortableExcesses',
  HOME_PORTABLE_MOBILE_EXCESSES = 'HomePortableMobileExcesses',
  HOME_FRAME_MATERIALS = 'HomeFrameMaterials',
  HOME_FLOOR_MATERIALS = 'HomeFloorMaterials',
  QUOTING_FEES_BY_MONTH = 'QuotingFeesPayByMonth',
  PORTABLE_ITEM_CATEGORIES = 'PortableItemCategories',
}

export const useReferenceDataStore = defineStore('ReferenceData', () => {
  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //
  const refData = ref<Partial<Record<RefDataTypes, INameValue[]>>>({});
  const refDataNameCode = ref<Partial<Record<RefDataTypes, INameCode[]>>>({});

  //#region GENERIC
  async function getPersonTitles(): Promise<INameValue[]> {
    if (refData.value?.PersonTitles?.length) return refData.value.PersonTitles;
    try {
      const res = await _refData.getPersonTitles();
      refData.value.PersonTitles = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch person titles: ', error);
    }
    return refData.value.PersonTitles ?? [];
  }

  async function getGenders(): Promise<INameValue[]> {
    if (refData.value?.Genders?.length) return refData.value.Genders;
    try {
      const res = await _refData.getGenders();
      refData.value.Genders = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch Genders: ', error);
    }
    return refData.value.Genders ?? [];
  }

  async function getPetTypes(quoteType: string): Promise<INameValue[]> {
    if (refData.value?.PetTypes?.length) return refData.value.PetTypes;
    try {
      const res = await _refData.getPetTypes(quoteType);
      refData.value.PetTypes = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch PetTypes: ', error);
    }
    return refData.value.PetTypes ?? [];
  }

  async function getPetBreeds(quoteType: string, petType: string): Promise<INameValue[]> {
    if (refData.value.PetBreeds?.length) return refData.value.PetBreeds;
    try {
      const res = await _refData.getPetBreeds(quoteType, petType);
      refData.value.PetBreeds = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch PetBreeds: ', error);
    }
    return refData.value.PetBreeds ?? [];
  }

  async function getInsuranceProductName(quoteType: string) {
    if (!refData.value.InsuranceProducts?.length) {
      try {
        const res = await _refData.getInsuranceProducts();
        refData.value.InsuranceProducts = res ?? [];
      } catch (error) {
        console.warn('Unable to fetch insurance products: ', error);
      }
    }
    return refData.value.InsuranceProducts?.find((item) => item.value == quoteType)?.name;
  }
  //#endregion

  //#region HOME AND CONTENTS
  /**
   * Get claim types
   *
   * @param quoteType
   * @returns
   */
  async function getHomeClaimTypes(quoteType: string): Promise<INameValue[]> {
    if (refData.value.HomeClaimTypes?.length) return refData.value.HomeClaimTypes;
    try {
      const res = await _refData.getHomeClaimTypes(quoteType);
      refData.value.HomeClaimTypes = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch genders: ', error);
    }
    return refData.value.HomeClaimTypes ?? [];
  }

  async function getHomeLossTypes(quoteType: string): Promise<INameValue[]> {
    if (refData.value.HomeLossTypes?.length) return refData.value.HomeLossTypes;
    try {
      const res = await _refData.getHomeLossTypes(quoteType);
      refData.value.HomeLossTypes = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch HomeLossTypes: ', error);
    }
    return refData.value.HomeLossTypes ?? [];
  }

  async function getHomeLossValues(quoteType: string): Promise<INameValue[]> {
    if (refData.value.HomeLossValues?.length) return refData.value.HomeLossValues;
    try {
      const res = await _refData.getHomeLossValues(quoteType);
      refData.value.HomeLossValues = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch HomeLossValues: ', error);
    }
    return refData.value.HomeLossValues ?? [];
  }

  async function getHomeAdvancedCover(quoteType: string, occupancyType: string): Promise<any[]> {
    if (refData.value.HomeAdvancedCover?.length) return refData.value.HomeAdvancedCover;
    try {
      const res = await _refData.getHomeAdvancedCover(quoteType, occupancyType);
      refData.value.HomeAdvancedCover = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch Advanced Cover: ', error);
    }
    return refData.value.HomeAdvancedCover ?? [];
  }

  async function getHomeRoofTypeMaterials(quoteType: string): Promise<INameValue[]> {
    if (refData.value.HomeRoofTypes?.length) return refData.value.HomeRoofTypes;
    try {
      const res = await _refData.getHomeRoofTypeMaterials(quoteType);
      refData.value.HomeRoofTypes = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch Roof Types: ', error);
    }
    return refData.value.HomeRoofTypes ?? [];
  }

  async function getHomePrimaryConstructionMaterials(quoteType: string): Promise<INameValue[]> {
    if (refData.value.HomePrimaryConstructionMaterials?.length) return refData.value.HomePrimaryConstructionMaterials;
    try {
      const res = await _refData.getHomePrimaryConstructionMaterials(quoteType);
      refData.value.HomePrimaryConstructionMaterials = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch Primary Construction Materials: ', error);
    }
    return refData.value.HomePrimaryConstructionMaterials ?? [];
  }

  async function getHomeOccupancyTypes(): Promise<IDropdownItem[]> {
    if (refDataNameCode.value.HomeOccupancyTypes?.length) {
      return refDataNameCode.value.HomeOccupancyTypes.map((item) => {
        return {
          label: item.name,
          value: item.code,
        };
      });
    }
    try {
      const res = await _refData.getHomeOccupancyTypes();
      refDataNameCode.value.HomeOccupancyTypes = res?.values ?? [];
    } catch (error) {
      console.warn('Unable to fetch Occupancy Types: ', error);
    }
    return (
      refDataNameCode.value.HomeOccupancyTypes?.map((item) => {
        return {
          label: item.name,
          value: item.code,
        };
      }) ?? []
    );
  }

  async function getHomeAndContentBuildingPropertyFeatures(): Promise<IDropdownItem[]> {
    if (refData.value.HomeAndContentBuildingPropertyFeatures?.length) {
      return refData.value.HomeAndContentBuildingPropertyFeatures.map((item) => {
        return {
          label: item.name,
          value: item.value,
        };
      });
    }
    try {
      const res = await _refData.getHomeAndContentBuildingPropertyFeatures();
      refData.value.HomeAndContentBuildingPropertyFeatures = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch HomeAndContent BuildingPropertyFeatures: ', error);
    }
    return (
      refData.value.HomeAndContentBuildingPropertyFeatures?.map((item) => {
        return {
          label: item.name,
          value: item.value,
        };
      }) ?? []
    );
  }

  async function getHomeAndContentProducts(productType: string, buildingType: string): Promise<IDropdownItem[]> {
    try {
      const res = await _refData.getHomeAndContentProducts(productType, buildingType ?? '');
      return (
        res?.map((item) => {
          return {
            label: item.name,
            value: item.value,
          };
        }) ?? []
      );
    } catch (error) {
      console.warn('Unable to fetch HomeAndContent Products: ', error);
    }
    return [];
  }

  async function getBuildingTypes(): Promise<IDropdownItem[]> {
    if (refData.value.HomeBuildingTypes?.length) {
      return refData.value.HomeBuildingTypes.map((item) => {
        return {
          label: item.name,
          value: item.value,
        };
      });
    }
    try {
      const res = await _refData.getBuildingTypes();
      refData.value.HomeBuildingTypes = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch Building Types: ', error);
    }
    return (
      refData.value.HomeBuildingTypes?.map((item) => {
        return {
          label: item.name,
          value: item.value,
        };
      }) ?? []
    );
  }

  async function getLowestOccupiedFloor(buildingType: string): Promise<any[]> {
    if (refData.value.LowestOccupiedFloor?.length) return refData.value.LowestOccupiedFloor;
    try {
      const res = await _refData.getLowestOccupiedFloor(buildingType);
      refData.value.LowestOccupiedFloor = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch Lowest Occupied Floor: ', error);
    }
    return refData.value.LowestOccupiedFloor ?? [];
  }

  async function getNumOfStoreys(buildingType: string): Promise<IDropdownItem[]> {
    try {
      const res = await _refData.getNumOfStoreys(buildingType);
      return (
        res?.map((item) => {
          return {
            label: item.name,
            value: item.value,
          };
        }) ?? []
      );
    } catch (error) {
      console.warn('Unable to fetch Num Of Storeys: ', error);
    }
    return [];
  }

  async function getHomeFloorOccupancy(buildingType: string): Promise<IDropdownItem[]> {
    try {
      const res = await _refData.getHomeFloorOccupancy(buildingType);
      return (
        res?.values?.map((item) => {
          return {
            label: item.name,
            value: item.value,
          };
        }) ?? []
      );
    } catch (error) {
      console.warn('Unable to fetch Home Floor Occupancy: ', error);
    }
    return [];
  }

  async function getHomeGroupCoverPriceBands(quoteType: string): Promise<IDropdownItem[]> {
    if (refDataNameCode.value.HomeGroupCoverPriceBands?.length) {
      return refDataNameCode.value.HomeGroupCoverPriceBands.map((item) => {
        return {
          label: item.name,
          value: item.code,
        };
      });
    }
    try {
      const res = await _refData.getHomeGroupCoverPriceBands(quoteType);
      refDataNameCode.value.HomeGroupCoverPriceBands = res?.values ?? [];
    } catch (error) {
      console.warn('Unable to fetch HomeGroupCoverPriceBands: ', error);
    }
    return (
      refDataNameCode.value.HomeGroupCoverPriceBands?.map((item) => {
        return {
          label: item.name,
          value: item.code,
        };
      }) ?? []
    );
  }

  async function getPortableItemCategories(quoteType: string): Promise<IDropdownItem[]> {
    if (refDataNameCode.value.PortableItemCategories?.length) {
      return refDataNameCode.value.PortableItemCategories.map((item) => {
        return {
          label: item.name,
          value: item.code,
        };
      });
    }
    try {
      const res = await _refData.getPortableItemCategories(quoteType);
      refDataNameCode.value.PortableItemCategories = res?.values ?? [];
    } catch (error) {
      console.warn('Unable to fetch PortableItemCategories: ', error);
    }
    return (
      refDataNameCode.value.PortableItemCategories?.map((item) => {
        return {
          label: item.name,
          value: item.code,
        };
      }) ?? []
    );
  }

  async function getSmallBusinessInsuranceLimitValue(quoteType: string) {
    try {
      const res = await _refData.getSmallBusinessInsuranceLimitValue(quoteType);
      return res?.values ?? [];
    } catch (error) {
      console.warn('Unable to fetch Home Small Business Insurance Limit Value: ', error);
    }
    return [];
  }

  async function getEathquakeExcesses(quoteType: string): Promise<any[]> {
    if (refData.value.HomeEathquakeExcesses?.length) return refData.value.HomeEathquakeExcesses;
    try {
      const res = await _refData.getEathquakeExcesses(quoteType);
      refData.value.HomeEathquakeExcesses = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch Home Eathquake Excesses: ', error);
    }
    return refData.value.HomeEathquakeExcesses ?? [];
  }

  async function getPetExcesses(quoteType: string): Promise<any[]> {
    if (refData.value.HomePetExcesses?.length) return refData.value.HomePetExcesses;
    try {
      const res = await _refData.getPetExcesses(quoteType);
      refData.value.HomePetExcesses = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch Home Pet Excesses: ', error);
    }
    return refData.value.HomePetExcesses ?? [];
  }

  async function getPortableExcesses(quoteType: string): Promise<any[]> {
    if (refData.value.HomePortableExcesses?.length) return refData.value.HomePortableExcesses;
    try {
      const res = await _refData.getPortableExcesses(quoteType);
      refData.value.HomePortableExcesses = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch Home Portable Excesses: ', error);
    }
    return refData.value.HomePortableExcesses ?? [];
  }

  async function getPortableMobileExcesses(quoteType: string): Promise<any[]> {
    if (refData.value.HomePortableMobileExcesses?.length) return refData.value.HomePortableMobileExcesses;
    try {
      const res = await _refData.getPortableMobileExcesses(quoteType);
      refData.value.HomePortableMobileExcesses = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch Home Portable Excesses: ', error);
    }
    return refData.value.HomePortableMobileExcesses ?? [];
  }

  async function getFrameMaterials(quoteType: string): Promise<INameCode[]> {
    if (refDataNameCode.value.HomeFrameMaterials?.length) return refDataNameCode.value.HomeFrameMaterials;
    try {
      const res = await _refData.getFrameMaterials(quoteType);
      refDataNameCode.value.HomeFrameMaterials = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch Home Frame Material: ', error);
    }
    return refDataNameCode.value.HomeFrameMaterials ?? [];
  }

  async function getFloorMaterials(quoteType: string): Promise<INameCode[]> {
    if (refDataNameCode.value.HomeFloorMaterials?.length) return refDataNameCode.value.HomeFloorMaterials;
    try {
      const res = await _refData.getFloorMaterials(quoteType);
      refDataNameCode.value.HomeFloorMaterials = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch Home Floor Materials: ', error);
    }
    return refDataNameCode.value.HomeFloorMaterials ?? [];
  }

  async function getMembershipLevels(quoteType: string): Promise<any[]> {
    if (refData.value.MembershipLevels?.length) return refData.value.MembershipLevels;
    try {
      const res = await _refData.getMembershipLevels(quoteType);
      refData.value.MembershipLevels = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch Home Portable Excesses: ', error);
    }
    return refData.value.MembershipLevels ?? [];
  }

  async function getQuotingFeesPayByMonth(effectiveDate: Date): Promise<any> {
    if (refData.value.QuotingFeesPayByMonth?.length) return refData.value.QuotingFeesPayByMonth;
    try {
      const res = await _refData.getQuotingFeesPayByMonth(effectiveDate);
      refData.value.QuotingFeesPayByMonth = res ?? [];
    } catch (error) {
      console.warn('Unable to fetch Quoting Fees Pay By Month: ', error);
    }
    return refData.value.QuotingFeesPayByMonth ?? [];
  }

  async function getMonitoredAlarmTypes(quoteType: string): Promise<IDropdownItem[]> {
    try {
      const res = await _refData.getMonitoredAlarmTypes(quoteType);
      return (
        res?.map((item) => {
          return {
            label: item.name,
            value: item.value,
          };
        }) ?? []
      );
    } catch (error) {
      console.warn('Unable to fetch Monitored Alarm Types: ', error);
    }
    return [];
  }

  //#endregion

  return {
    getPersonTitles,
    getGenders,
    getPetTypes,
    getPetBreeds,
    getHomeClaimTypes,
    getHomeLossTypes,
    getHomeLossValues,
    getHomeAdvancedCover,
    getHomeRoofTypeMaterials,
    getHomePrimaryConstructionMaterials,
    getHomeOccupancyTypes,
    getHomeAndContentProducts,
    getHomeAndContentBuildingPropertyFeatures,
    getBuildingTypes,
    getNumOfStoreys,
    getLowestOccupiedFloor,
    getHomeFloorOccupancy,
    getHomeGroupCoverPriceBands,
    getSmallBusinessInsuranceLimitValue,
    getEathquakeExcesses,
    getPetExcesses,
    getPortableExcesses,
    getPortableMobileExcesses,
    getFrameMaterials,
    getFloorMaterials,
    getMonitoredAlarmTypes,
    getInsuranceProductName,
    getMembershipLevels,
    getQuotingFeesPayByMonth,
    getPortableItemCategories,
  };
});
