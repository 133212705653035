import { scrollToElement } from '@/utils/dom-helpers';

/**
 * Custom plugin to scroll to errors. https://formkit.com/essentials/examples
 *
 */
function formkitScrollToErrorsPlugin(node: any) {
  if (node.props.type === 'form') {
    const onSubmitInvalid = node.props.onSubmitInvalid;
    node.props.onSubmitInvalid = () => {
      if (onSubmitInvalid) {
        onSubmitInvalid(node);
      }
      scrollToErrors();
    };
    node.on('unsettled:errors', scrollToErrors);
  }

  function scrollToErrors() {
    let errorFound = false; // to fix dom sequencing issue
    node.walk((child: any) => {
      // Check if this child has errors
      if (child.ledger.value('blocking') || child.ledger.value('errors')) {
        // We found an input with validation errors
        const input = document.querySelector('#' + child.props.id);
        if (errorFound) return false;
        else if (input) {
          errorFound = true;
          scrollToElement('#' + child.props.id, 100, -100);
          // Stop searching
          return false;
        }
      }
    }, true);
  }
}

export default formkitScrollToErrorsPlugin;
