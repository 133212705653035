export interface IInformationBox {
  type: InformationBoxTypes;
  title?: string;
  description?: string;
  links?: Array<Link>;
}

interface Link {
  title: string;
  url: string;
}

export enum InformationBoxTypes {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}
