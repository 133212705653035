import { IDropdownItem, IFoundVehicle } from '@/interfaces';
import { startCase, camelCase } from 'lodash-es';
import { truncateDecimals } from '@/utils/data-transforms';
import vehicleService from '@/services/store/vehicle.service';

export interface IVehicleOptions {
  year: string;
  make: string;
  model: string;
  series?: string;
  body?: string;
  colour?: string;
}

export interface IVehicleLoadingOptions {
  year: boolean;
  make: boolean;
  model: boolean;
  series?: boolean;
  body?: boolean;
  colour?: boolean;
}

export function toTitleCase(str: string) {
  return startCase(camelCase(str));
}

/**
 * Create a formatted, human readable string from car specs data
 *
 * @param {*} vehicle The vehicle data obtained from NVIC or rego lookup
 * @param {'basic'|'detailed'} type Returns a basic or detailed car specs string
 *
 * @returns {String}
 */
function formattedCarSpecs(vehicle: IFoundVehicle, type = 'detailed') {
  const vehicleModelVariantSeries = `${toTitleCase(vehicle?.model?.description || '')} ${
    vehicle?.variant?.description || ''
  } ${vehicle?.option?.series || ''}`;

  const speeds = `${vehicle?.gears + ' Speed' || ''}`;

  const autoOrManual = vehicle?.option?.transmission === 'A' ? 'Auto' : 'Manual';

  const vehicleDoorsBodyTransmissionDriveType = `${vehicle?.option?.doors + ' Door' || ''} ${
    vehicle?.body?.description || ''
  } - ${speeds} ${autoOrManual} ${vehicle?.driveType || ''} -`;

  const engineCapacity =
    vehicle?.engineCapacity > 0 ? truncateDecimals(Number(vehicle?.engineCapacity * 0.001)) + 'L' : '';

  const vehicleCylindersEngineAndFuel = `${vehicle?.cylinders || ''} ${engineCapacity} ${
    vehicle?.fuelType?.code || ''
  }`;

  const vehicleTurbo = `${vehicle?.hasTurbo ? 'Turbo - ' : ' - '}`;

  if (type === 'detailed') {
    return `${vehicleModelVariantSeries} ${vehicleTurbo} ${vehicleDoorsBodyTransmissionDriveType} ${vehicleCylindersEngineAndFuel}`;
  }

  return `${vehicle?.manufacturingYear || ''} ${vehicle?.model?.description || ''} ${
    vehicle?.variant?.description || ''
  } ${vehicle?.body?.description || ''} ${vehicle?.option?.series || ''} ${autoOrManual}`;
}

/**
 * Fetch list of years.
 *
 */
async function fetchVehicleYearsList(): Promise<IDropdownItem[]> {
  const vehicleYearsData = await vehicleService.getVehicleYears();
  const mappedVehicleYears = vehicleService.mapVehicleDataToFormUI(vehicleYearsData);
  // Update the label value from 1900 => 'Before 1960' for better UX
  mappedVehicleYears[mappedVehicleYears.length - 1].label = 'Before 1960';

  return mappedVehicleYears;
}

/**
 * Search vehicle makes by year
 *
 * @param {String} year
 */
async function fetchVehicleMakesByYear(year: string): Promise<IDropdownItem[]> {
  if (year === 'Before 1960' || !year) return [];

  const vehicleMakesData = await vehicleService.getVehicleMakesByYear(year);

  if (vehicleMakesData) {
    return vehicleService.mapVehicleDataToFormUI(vehicleMakesData);
  }

  return [];
}

/**
 * Search vehicle models by year and make
 *
 */
async function fetchModelsByYearAndMake(year: string, make: string): Promise<IDropdownItem[]> {
  if (!year && !make) {
    return [];
  }

  const vehicleModelsData = await vehicleService.getVehicleModelsByYearAndMake(year, make);

  if (vehicleModelsData) {
    return vehicleService.mapVehicleDataToFormUI(vehicleModelsData);
  }

  return [];
}

/**
 * Fetch vehicle variants (series) by year, make and model
 *
 */
async function fetchVehicleVariantsByYearMakeModel(
  year: string,
  make: string,
  model: string
): Promise<IDropdownItem[]> {
  if (!year && !make && !model) {
    return [];
  }

  const vehicleSeriesData = await vehicleService.getVehicleVariantsByYearMakeModel(year, make, model);

  if (vehicleSeriesData) {
    return vehicleService.mapVehicleDataToFormUI(vehicleSeriesData);
  }

  return [];
}

/**
 * Fetch detailed vehicle data for a given year, make, model, variant and body.
 *
 */
async function fetchVehicleDetailsByYearMakeModelVariantBody({
  year,
  make,
  model,
  series,
  body,
}: IVehicleOptions): Promise<IFoundVehicle[]> {
  if (!year && !make && !model) {
    return [];
  }

  const possibleVehiclesData = await vehicleService.getVehicleDetailsByYearMakeModelVariantBody(
    year,
    make,
    model,
    series,
    body
  );

  if (possibleVehiclesData?.length) {
    return possibleVehiclesData;
  }

  return [];
}

/**
 * Fetch a list of bodies for a given vehicle year, make, model, series combination.
 *
 * @returns
 */
async function fetchVehicleBodies({ year, make, model, series }: IVehicleOptions): Promise<IDropdownItem[]> {
  if (!year && !make && !model) {
    return [];
  }

  const vehicleBodyData = await vehicleService.getVehicleBodiesByYearMakeModelVariant(year, make, model, series);

  if (vehicleBodyData) {
    return vehicleService.mapVehicleDataToFormUI(vehicleBodyData);
  }

  return [];
}

export default function useVehicleData() {
  return {
    formattedCarSpecs,
    fetchVehicleYearsList,
    fetchVehicleMakesByYear,
    fetchModelsByYearAndMake,
    fetchVehicleVariantsByYearMakeModel,
    fetchVehicleDetailsByYearMakeModelVariantBody,
    fetchVehicleBodies,
  };
}
