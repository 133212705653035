import { JP1 } from '@/constants/jeopardy-codes.const';
import { QuoteChangeState } from '@/enums/quote-change-state.enum';
import { GlassesGuideVehicleDetails, IFoundVehicle } from '@/interfaces';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

export const useFindCarStore = defineStore('FindCar', () => {
  const showFoundVehicles = ref(false);
  const selectedVehicle = ref<IFoundVehicle | undefined>(undefined);
  const foundVehicleList = ref<IFoundVehicle[]>([]);
  const registrationNumber = ref('');
  const vehicleColour = ref('');
  const isQldRegoFound = ref<boolean>(true);
  const formName = ref(''); // Track which form this component is used on for analytics
  const pre1960VehicleJeopardyCode = ref(JP1); // Set default jeopardy code for pre 1960 vehicle

  function showCarList(show: boolean) {
    showFoundVehicles.value = show;
  }

  function resetFoundVehicleList() {
    showFoundVehicles.value = false;
  }

  function setFormName(name: string) {
    formName.value = name;
  }

  function setPre1960VehicleJJeopardy(jeopardyCode: string) {
    pre1960VehicleJeopardyCode.value = jeopardyCode;
  }

  function toGlassGuideVehicleDetails(): GlassesGuideVehicleDetails | undefined {
    if (!selectedVehicle.value) return;
    return {
      vehicleId: {
        value: selectedVehicle.value.glassesId,
        state: QuoteChangeState.UsedInQuote,
      },
      make: selectedVehicle.value.make,
      model: selectedVehicle.value.model,
      variant: selectedVehicle.value.variant,
      body: selectedVehicle.value.body,
      option: {
        nvic: {
          value: selectedVehicle.value.nvic,
          state: QuoteChangeState.UsedInQuote,
        },
        doors: selectedVehicle.value.option?.doors,
        series: selectedVehicle.value.option?.series,
        transmission: selectedVehicle.value.option?.transmission,
      },
      manufacturingYear: {
        year: selectedVehicle.value.manufacturingYear,
      },
      vehicleAcceptCode: {
        value: selectedVehicle.value.acceptCode!,
        state: QuoteChangeState.UsedInQuote,
      },
      vehiclePerformanceCode: {
        value: selectedVehicle.value.highPerformance!,
        state: QuoteChangeState.UsedInQuote,
      },
      vehicleValue: {
        value: selectedVehicle.value.marketValue,
        state: QuoteChangeState.UsedInQuote,
      },
      vehiclePoints: {
        value: selectedVehicle.value.points,
        state: QuoteChangeState.UsedInQuote,
      },
      hasTurbo: {
        value: selectedVehicle.value.hasTurbo,
        state: QuoteChangeState.UsedInQuote,
      },
      gears: {
        value: selectedVehicle.value.gears,
        state: QuoteChangeState.UsedInQuote,
      },
      driveType: {
        value: selectedVehicle.value.driveType,
        state: QuoteChangeState.UsedInQuote,
      },
      cylinders: {
        value: selectedVehicle.value.cylinders,
        state: QuoteChangeState.UsedInQuote,
      },
      engineCapacity: {
        value: selectedVehicle.value.engineCapacity,
        state: QuoteChangeState.UsedInQuote,
      },
      fuelType: {
        value: selectedVehicle.value.fuelType?.code,
        state: QuoteChangeState.UsedInQuote,
      },
      source: {
        value: selectedVehicle.value.source,
        state: QuoteChangeState.UsedInQuote,
      },
    };
  }

  watch(
    showFoundVehicles,
    (newValue) => {
      if (newValue === false) {
        selectedVehicle.value = undefined;
      }
    },
    { immediate: true }
  );

  return {
    showFoundVehicles,
    selectedVehicle,
    foundVehicleList,
    registrationNumber,
    isQldRegoFound,
    vehicleColour,
    formName,
    pre1960VehicleJeopardyCode,
    showCarList,
    resetFoundVehicleList,
    toGlassGuideVehicleDetails,
    setFormName,
    setPre1960VehicleJJeopardy,
  };
});
