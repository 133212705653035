import { defineStore } from 'pinia';
import { InsuranceQuoteService as _iq } from '@/services/store/insurance-quote.service';
import { ref, computed } from 'vue';
import { IQuote, ISubmitQuote } from '@/interfaces';
import { QuoteTypes } from '@/constants/quote-types.const';
import { useIQOriginalQuoteStore } from '@/store';
import { IJeopardy, IServiceResponseError } from '@/interfaces/entities';
import { isArray } from 'lodash-es';
import { useAnalyticsStore } from '@/store';
import { FormEvents } from '@/enums/form-events.enum';

export const useHomeAndContentsStore = defineStore('HomeAndContents', () => {
  const analyticsStore = useAnalyticsStore();

  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //
  const quote = ref<Partial<IQuote>>({});
  const quoteId = ref<string>('');
  const quoteType = computed<string>(() => (quote.value.quoteType && QuoteTypes[quote.value.quoteType]) || '');
  const updatePostalAddressRequired = ref(false);
  const originalQuoteStore = useIQOriginalQuoteStore();

  const jeopardies = ref<IJeopardy[]>([]);
  const errors = ref<IServiceResponseError[]>([]);

  const originalQuote = computed(() => {
    return originalQuoteStore.quote;
  });

  const fetchQuote = async () => {
    // get quote id
    const params = new URLSearchParams(window.location.search);
    quoteId.value = params.get('ref') || '';
    if (!quoteId.value) throw 400;
    const res: { quote: Partial<IQuote>; lastUpdated: string } = await _iq.fetchQuote(quoteId.value);
    quote.value = res?.quote;

    originalQuoteStore.setQuote(quote.value);
  };

  /**
   * Create new quote
   *
   * @param quote
   */
  const createQuote = async () => {
    let responseError = null;

    try {
      const response = await _iq.createQuote(quote.value);
      if (response.quote) {
        quote.value = response.quote;
        quoteId.value = response.quote.id;
      }

      responseError = await handleJeopardyOrReasonCodes(response);
      if (responseError) throw responseError;
    } catch (error) {
      if (responseError) throw responseError;
      await handleError(error);
    }
  };

  /**
   * Apply Business Rules
   *
   * @param formData
   */
  const applyBusinessRules = async () => {
    let responseError = null;

    try {
      const response = await _iq.applyBusinessRules(quoteId.value, quote.value);
      quote.value = response?.quote || quote.value;

      responseError = await handleJeopardyOrReasonCodes(response);
      if (responseError) throw responseError;
      //Clear errors if successful
      errors.value.length = 0;
    } catch (error) {
      if (responseError) throw responseError;
      await handleError(error);
    }
  };

  /**
   * Apply Business Rules
   *
   * @param formData
   */
  const saveQuote = async () => {
    let responseError = null;

    try {
      const response = await _iq.saveQuote(quoteId.value, quote.value);
      quote.value = response?.quote || quote.value;

      responseError = await handleJeopardyOrReasonCodes(response);
      if (responseError) throw responseError;
    } catch (error) {
      if (responseError) throw responseError;
      await handleError(error);
    }
  };

  /**
   * Calculate quote price
   *
   * @returns
   */
  const calculatePrice = async () => {
    let responseError = null;

    try {
      const response = await _iq.calculatePrice(quoteId.value, quote.value);
      quote.value = response?.quote || quote.value;

      responseError = await handleJeopardyOrReasonCodes(response);
      if (responseError) throw responseError;
    } catch (error) {
      if (responseError) throw responseError;
      await handleError(error);
    }
  };

  const resetErrors = async () => {
    errors.value = [];
  };

  const applyRulesAndSave = async (calculateQuotePrice: boolean = true) => {
    // Apply business rules
    await applyBusinessRules();

    if (calculateQuotePrice && !errors.value?.length) {
      // Save Quote for Pricing
      await saveQuote();

      // Recalculate quote price
      await calculatePrice();
    }

    // Save quote with new price
    await saveQuote();
  };

  /**
   * DSQ-308 primary policyholder must agree t&c if postal address changed
   */
  const checkUpdatePostalAddressRequired = async (): Promise<boolean> => {
    const partyId = quote.value.primaryPolicyHolderDetails?.partyId?.value;
    const dpid = quote.value.primaryPolicyHolderDetails?.dpid?.value;
    if (!dpid)
      return true; // show t&c if manual / oversea address
    else if (!partyId) return false; // hide t&c for new contact
    const res = await _iq.checkUpdatePostalAddressRequired(partyId, dpid);
    updatePostalAddressRequired.value = res ?? false;
    return res;
  };

  /**
   * Apply Business Rules
   *
   * @param formData
   */
  const submitQuote = async (submitQuote: ISubmitQuote) => {
    let responseError = null;

    try {
      const response = await _iq.submitQuote(quoteId.value, submitQuote);
      quote.value = response?.quote || quote.value;

      responseError = await handleJeopardyOrReasonCodes(response);
      if (responseError) throw responseError;
    } catch (error) {
      if (responseError) throw responseError;
      await handleError(error);
    }
  };

  const handleJeopardyOrReasonCodes = async (response: any) => {
    if (response.success && response.data && response.data.jeopardies?.length > 0) {
      jeopardies.value = response.data.jeopardies;
      return response.data.jeopardies[0];
    }

    if (response?.reasonCodes?.length > 0) {
      errors.value = response?.reasonCodes || [];
      return response.reasonCodes[0];
    }

    if (response?.errors?.length > 0) {
      errors.value = [response.errors[0]];
      return response.errors[0].errorType;
    }

    return null;
  };

  const handleError = async (error: any) => {
    const isErrorArray = isArray(error);
    errors.value = isErrorArray ? error : [error];
    if (!isErrorArray && error?.code) {
      throw error.code;
    }

    throw ['Unknown error'];
  };

  /**
   * Push data to analytics
   *
   * @param options
   */
  const pushAnalytics = (options: { event: FormEvents }) => {
    analyticsStore.pushAnalytics({
      event: options.event,
      form: {},
    });
  };

  return {
    quote,
    quoteId,
    quoteType,
    originalQuote,
    updatePostalAddressRequired,
    errors,
    jeopardies,
    checkUpdatePostalAddressRequired,
    fetchQuote,
    createQuote,
    applyBusinessRules,
    saveQuote,
    calculatePrice,
    resetErrors,
    applyRulesAndSave,
    submitQuote,
    pushAnalytics,
  };
});
