<template>
  <div v-if="visible" class="flex flex-col">
    <main class="bg-racq-anti-flash-white flex flex-col grow h-screen">
      <div
        id="base-form-container"
        class="mx-0 md:mx-auto sm:pb-[40px] xl:max-w-[784px] lg:max-w-[992px] md:max-w-[784px] w-full"
      >
        <div class="sm:mt-0 md:mt-8 lg:mt-14">
          <section>
            <div
              class="max-w-[784px] pb-[40px] w-full bg-white mb-5 relative md:rounded md:drop-shadow-lg"
              data-tooltip-wrapper
            >
              <div class="max-w-[100vw] pt-[24px] px-[16px] md:pt-[32px] md:px-[40px]">
                <div class="text-xl-bold">Please wait...</div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  props: {
    delay: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  created() {
    // Wait {this.delay}ms before making the loading graphic visible.
    // If whatever other async operation completes within that timeframe,
    // then whichever parent component invoked the loading component will
    // presumably re-render without the loading component.
    // This helps prevent the loading graphic from too briefly "flashing" on the screen
    // causing an undesirable flicker for users.
    setTimeout(() => {
      this.visible = true;
    }, this.delay);
  },
};
</script>
