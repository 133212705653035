export interface VehicleRequestModel {
  nvic?: string;
  kerbWeight?: number | null;
  kerbWeightUnit?: string;
  length?: number | null;
  lengthUnit?: string;
  weight?: number | null;
  weightUnit?: string;
  loadCapacity?: number | null;
  loadCapacityUnit?: string;
  registrationNumber: string;
  manufacturingYear?: number | null;
  stateCode?: string;
  body: VehicleBodyRequestModel;
  make: VehicleDetailRequestModel;
  model: VehicleDetailRequestModel;
  variant: VehicleDetailRequestModel;
  option: VehicleOptionRequestModel;
  fuelType: VehicleFuelTypeRequestModel;
}

export interface VehicleDetailRequestModel {
  code?: string;
  description?: string;
}

export interface VehicleBodyRequestModel extends VehicleDetailRequestModel {
  colour?: string;
}

export interface VehicleOptionRequestModel {
  transmission?: string;
  transmissionTypeCode?: string;
  description?: string;
}

export interface VehicleFuelTypeRequestModel {
  description?: string;
}

export enum SurchargeLevels {
  Member = 'Member',
  NonMember = 'Non-Member',
}

export interface CreateQuoteRequest {
  productCode: string;
  productName: string;
  apttusId: string;
  surchargeLevel: SurchargeLevels;
  vehicle: VehicleRequestModel;
}

export interface CreateQuoteResponse {
  price?: number | null;
  apttusQuoteId: string;
  apttusCartId: string;
  productCode: string;
  productName: string;
  isError: boolean;
}
