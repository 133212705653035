import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, renderSlot as _renderSlot, createVNode as _createVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/icons/white-tick.svg'


const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "absolute inset-x-0 inset-y-0 bg-curtain-color z-[100]"
}
const _hoisted_3 = { class: "payment-method-container flex flex-col" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "flex w-full pt-4" }
const _hoisted_8 = { class: "flex w-full pt-4" }

import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { computed, ref } from 'vue';
import PaymentSectionFZIframe from './PaymentSectionFZIframe.vue';
import { usePaymentStore } from '@/store';
import { IPaymentSectionProps } from '@/interfaces';
import { PaymentTypes } from '@/enums/payment-types.enum';
import { FormKit } from '@formkit/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentSection',
  props: {
    AcceptPaymentTypes: {},
    BankAccountTitle: {},
    AccountNameInvalidMessage: {},
    AccountNameLabel: {},
    AccountNameRequiredMessage: {},
    AccountNameTooltip: {},
    AccountNumberInvalidMessage: {},
    AccountNumberLabel: {},
    AccountNumberRequiredMessage: {},
    BsbInvalidMessage: {},
    BsbLabel: {},
    BsbNotFoundMessage: {},
    BsbRequiredMessage: {},
    CardTypeTitle: {},
    CardIcons: {},
    PaymentDisclaimer: {},
    ShowCardIcons: {},
    SubmitPaymentButtonLabel: {},
    BankAccountIcon: {},
    PayLaterTitle: {},
    PayLaterIcon: {},
    SubmitPayLaterLabel: {},
    SubmitBankAccountLabel: {},
    PayLaterContent: {}
  },
  setup(__props: any) {

const props = __props;
const paymentStore = usePaymentStore();

const bankAccountName = ref<any>(null);
const bsbInput = ref<any>(null);
const bankAccountNumber = ref<any>(null);
const checkingBsb = ref<boolean>(false);

const acceptCardPayment = computed(() => {
  return props.AcceptPaymentTypes.some((type) => type.name == PaymentTypes[PaymentTypes.CreditCard]);
});

const acceptBankAccount = computed(() => {
  return props.AcceptPaymentTypes.some((type) => type.name == PaymentTypes[PaymentTypes.BankAccount]);
});

const acceptPayLater = computed(() => {
  return props.AcceptPaymentTypes.some((type) => type.name == PaymentTypes[PaymentTypes.PayLater]);
});

const isMasked = computed(() => paymentStore.disablePaymentPanel);

const paymentTypeSelectable = computed(() => {
  return props.AcceptPaymentTypes.length > 1;
});

const selectedPaymentType = computed(() => paymentStore.selectedPaymentMethod);

const financialInstitutionName = computed(() => {
  return paymentStore.bsbBranchDetails?.name
    ? `${paymentStore.bsbBranchDetails?.name}, ${paymentStore.bsbBranchDetails?.branch}`
    : undefined;
});

const bsbLookup = async function ({ value }: { value: string }) {
  checkingBsb.value = true;
  try {
    const bsb = value.replace('-', '');
    const res = await paymentStore.bsbLookup(bsb);
    return res;
  } catch (error) {
    return false;
  } finally {
    checkingBsb.value = false;
  }
};

const replaceBsb = (message: string, bsb: string) => {
  return message?.replace('{{bsb}}', bsb);
};

const submitPayment = async (paymentType: PaymentTypes) => {
  try {
    const bsb = bsbInput.value?.node?.value;
    const accountName = bankAccountName?.value?.node?.value;
    const accountNumber = bankAccountNumber?.value?.node?.value;

    if (bsb && accountName && accountNumber) {
      await paymentStore.setDirectDebitDetails(accountName, bsb, accountNumber);
    }

    paymentStore.submitPaymentCallback && paymentStore.submitPaymentCallback(paymentType);
  } catch (e) {
    console.error(e);
  }
};

/**
 * Change payment method handler
 *
 * @param paymentMethod
 */
const changePaymentMethod = (paymentMethod: PaymentTypes) => {
  if (!paymentTypeSelectable.value) return;
  paymentStore.changePaymentMethod(paymentMethod);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isMasked.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (acceptCardPayment.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass(["card-type-section flex space-between items-center border-b py-4", paymentTypeSelectable.value && 'hover:bg-racq-white-background cursor-pointer']),
              onClick: _cache[0] || (_cache[0] = () => changePaymentMethod(_unref(PaymentTypes).CreditCard))
            }, [
              (paymentTypeSelectable.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["h-[20px] w-[20px] rounded-full me-3", 
              selectedPaymentType.value == _unref(PaymentTypes).CreditCard
                ? 'bg-racq-blue-active'
                : 'border-2 border-racq-light-blue-grey'
            ])
                  }, _cache[4] || (_cache[4] = [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "tick svg",
                      class: "mx-[4px] my-[5px]"
                    }, null, -1)
                  ]), 2))
                : _createCommentVNode("", true),
              (_ctx.CardTypeTitle?.value)
                ? (_openBlock(), _createBlock(_unref(RichText), {
                    key: 1,
                    field: _ctx.CardTypeTitle,
                    class: "card-type-title text-base-bold"
                  }, null, 8, ["field"]))
                : _createCommentVNode("", true),
              (_ctx.ShowCardIcons?.value && _ctx.CardIcons?.value)
                ? (_openBlock(), _createBlock(_unref(RichText), {
                    key: 2,
                    class: "card-type-icons flex ml-auto gap-2 h-6",
                    field: _ctx.CardIcons
                  }, null, 8, ["field"]))
                : _createCommentVNode("", true)
            ], 2)
          ]))
        : _createCommentVNode("", true),
      (acceptBankAccount.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", {
              class: _normalizeClass(["card-type-section flex space-between items-center border-b py-4", paymentTypeSelectable.value && 'hover:bg-racq-white-background cursor-pointer']),
              onClick: _cache[1] || (_cache[1] = () => changePaymentMethod(_unref(PaymentTypes).BankAccount))
            }, [
              (paymentTypeSelectable.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["h-[20px] w-[20px] rounded-full me-3", 
              selectedPaymentType.value == _unref(PaymentTypes).BankAccount
                ? 'bg-racq-blue-active'
                : 'border-2 border-racq-light-blue-grey'
            ])
                  }, _cache[5] || (_cache[5] = [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "tick svg",
                      class: "mx-[4px] my-[5px]"
                    }, null, -1)
                  ]), 2))
                : _createCommentVNode("", true),
              (_ctx.BankAccountTitle?.value)
                ? (_openBlock(), _createBlock(_unref(RichText), {
                    key: 1,
                    field: _ctx.BankAccountTitle,
                    class: "card-type-title text-base-bold"
                  }, null, 8, ["field"]))
                : _createCommentVNode("", true),
              (_ctx.BankAccountIcon?.value)
                ? (_openBlock(), _createBlock(_unref(RichText), {
                    key: 2,
                    class: "card-type-icons flex ml-auto gap-2 h-6",
                    field: _ctx.BankAccountIcon
                  }, null, 8, ["field"]))
                : _createCommentVNode("", true)
            ], 2)
          ]))
        : _createCommentVNode("", true),
      (acceptPayLater.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", {
              class: _normalizeClass(["card-type-section flex space-between items-center border-b py-4", paymentTypeSelectable.value && 'hover:bg-racq-white-background cursor-pointer']),
              onClick: _cache[2] || (_cache[2] = () => changePaymentMethod(_unref(PaymentTypes).PayLater))
            }, [
              (paymentTypeSelectable.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["h-[20px] w-[20px] rounded-full me-3", 
              selectedPaymentType.value == _unref(PaymentTypes).PayLater
                ? 'bg-racq-blue-active'
                : 'border-2 border-racq-light-blue-grey'
            ])
                  }, _cache[6] || (_cache[6] = [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "tick svg",
                      class: "mx-[4px] my-[5px]"
                    }, null, -1)
                  ]), 2))
                : _createCommentVNode("", true),
              (_ctx.PayLaterTitle?.value)
                ? (_openBlock(), _createBlock(_unref(RichText), {
                    key: 1,
                    field: _ctx.PayLaterTitle,
                    class: "card-type-title text-base-bold"
                  }, null, 8, ["field"]))
                : _createCommentVNode("", true),
              (_ctx.PayLaterIcon?.value)
                ? (_openBlock(), _createBlock(_unref(RichText), {
                    key: 2,
                    class: "card-type-icons flex ml-auto gap-2 h-6",
                    field: _ctx.PayLaterIcon
                  }, null, 8, ["field"]))
                : _createCommentVNode("", true)
            ], 2)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        (acceptCardPayment.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["iframe-section ease-in-out duration-500 transition-all my-6 max-h-[1000px]", selectedPaymentType.value != _unref(PaymentTypes).CreditCard && '!overflow-hidden !max-h-[0px] !my-0 opacity-0'])
            }, [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createVNode(PaymentSectionFZIframe)
              ]),
              (_ctx.PaymentDisclaimer?.value)
                ? (_openBlock(), _createBlock(_unref(RichText), {
                    key: 0,
                    field: _ctx.PaymentDisclaimer,
                    class: "disclaimer-section flex flex-col text-sm font-normal"
                  }, null, 8, ["field"]))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true),
        (acceptBankAccount.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["ease-in-out duration-500 transition-all my-6 px-3 max-h-[1000px]", selectedPaymentType.value != _unref(PaymentTypes).BankAccount && '!overflow-hidden !max-h-[0px] !my-0 opacity-0'])
            }, [
              _createVNode(_unref(FormKit), {
                type: "group",
                name: "payment-section-bank-account"
              }, {
                default: _withCtx(({ state: { valid } }) => [
                  _createVNode(_unref(FormKit), {
                    id: "bank-account-account-name",
                    ref_key: "bankAccountName",
                    ref: bankAccountName,
                    type: "text",
                    label: props.AccountNameLabel?.value,
                    outerClass: "mb-4",
                    innerClass: "input-md",
                    name: "bank-account-account-name",
                    tooltip: {
                  content: props.AccountNameTooltip?.value,
                  tooltipId: 'bank-account-account-name__tooltip_id',
                  tooltipOuterId: 'bank-account-account-name__outer',
                },
                    validation: [['required'], ['matches', /^[a-zA-Z0-9 -]{1,100}$/]],
                    validationMessages: {
                  required: props.AccountNameRequiredMessage?.value,
                  matches: props.AccountNameInvalidMessage?.value,
                }
                  }, null, 8, ["label", "tooltip", "validationMessages"]),
                  _createVNode(_unref(FormKit), {
                    id: "bank-account-bsb",
                    ref_key: "bsbInput",
                    ref: bsbInput,
                    type: "mask",
                    mask: "###-###",
                    label: props.BsbLabel?.value,
                    outerClass: "mb-4",
                    innerClass: "input-xs",
                    name: "bank-account-bsb",
                    help: financialInstitutionName.value,
                    loading: checkingBsb.value,
                    validation: [['required'], ['matches', /^\d{3}-\d{3}$/], ['bsbLookup']],
                    validationRules: { bsbLookup: bsbLookup },
                    validationMessages: {
                  required: props.BsbRequiredMessage?.value,
                  matches: props.BsbInvalidMessage?.value,
                  bsbLookup: props.BsbNotFoundMessage?.value,
                }
                  }, {
                    messages: _withCtx((context) => [
                      (context.messages?.rule_bsbLookup?.value)
                        ? (_openBlock(), _createBlock(_unref(RichText), {
                            key: 0,
                            class: _normalizeClass(context.classes?.message),
                            field: { value: replaceBsb(context.messages?.rule_bsbLookup?.value, context._value) }
                          }, null, 8, ["class", "field"]))
                        : _createCommentVNode("", true),
                      (context.messages?.rule_required?.value)
                        ? (_openBlock(), _createBlock(_unref(RichText), {
                            key: 1,
                            class: _normalizeClass(context.classes?.message),
                            field: context.messages?.rule_required
                          }, null, 8, ["class", "field"]))
                        : _createCommentVNode("", true),
                      (context.messages?.rule_matches?.value)
                        ? (_openBlock(), _createBlock(_unref(RichText), {
                            key: 2,
                            class: _normalizeClass(context.classes?.message),
                            field: context.messages?.rule_matches
                          }, null, 8, ["class", "field"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["label", "help", "loading", "validationRules", "validationMessages"]),
                  _createVNode(_unref(FormKit), {
                    id: "bank-account-account-number",
                    ref_key: "bankAccountNumber",
                    ref: bankAccountNumber,
                    type: "tel",
                    label: props.AccountNumberLabel?.value,
                    outerClass: "mb-4",
                    innerClass: "input-sm",
                    name: "bank-account-account-number",
                    validation: [['required'], ['matches', /^(\d{6,9})$/]],
                    validationMessages: {
                  required: props.AccountNumberRequiredMessage?.value,
                  matches: props.AccountNumberInvalidMessage?.value,
                }
                  }, null, 8, ["label", "validationMessages"]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_unref(FormKit), {
                      type: "submit",
                      label: _ctx.SubmitBankAccountLabel?.value,
                      inputClass: "btn btn-primary btn-edit",
                      outerClass: "ms-auto",
                      onClick: () => valid && submitPayment(_unref(PaymentTypes).BankAccount)
                    }, null, 8, ["label", "onClick"])
                  ])
                ]),
                _: 1
              })
            ], 2))
          : _createCommentVNode("", true),
        (acceptPayLater.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(["ease-in-out duration-500 transition-all my-6 px-3 max-h-[1000px]", selectedPaymentType.value != _unref(PaymentTypes).PayLater && '!overflow-hidden !max-h-[0px] !my-0 opacity-0'])
            }, [
              (_ctx.PayLaterContent?.value)
                ? (_openBlock(), _createBlock(_unref(RichText), {
                    key: 0,
                    field: _ctx.PayLaterContent
                  }, null, 8, ["field"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_unref(FormKit), {
                  type: "button",
                  label: _ctx.SubmitPayLaterLabel?.value,
                  inputClass: "btn btn-secondary input-cta-arrow-right",
                  onClick: _cache[3] || (_cache[3] = () => submitPayment(_unref(PaymentTypes).PayLater))
                }, null, 8, ["label"])
              ])
            ], 2))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})