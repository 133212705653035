export interface IExperianResults {
  count: number;
  results: IExperianSuggestion[];
}

export interface IExperianSuggestion {
  matched: string[];
  format: string;
  suggestion: string;
  triggerManualEntry?: boolean;
}

export interface IExperianFormatResult {
  address: Record<IExperianAddressKeys, string>[];
  components: Record<IExperianComponentKeys, string>[];
  metadata: {
    addressIds: {
      dpid: string;
    };
  };
}

export interface RACQAddressResult {
  addressLine?: string;
  buildingName?: string;
  unitNumber?: string;
  streetNumber?: string;
  street?: string;
  suburb?: string;
  postcode?: string;
  state?: string;
  country?: string;
  countryISO?: string;
  dpid?: string;
  isCycloneCovered?: boolean;
  postalDeliveryType?: string;
  lurnCode?: string;
  lurnScale?: string;
  gnafAddressDetailPID?: string;
}

export enum IExperianAddressKeys {
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  addressLine3 = 'addressLine3',
  locality = 'locality',
  province = 'province',
  postalCode = 'postalCode',
  country = 'country',
}

export enum IExperianComponentKeys {
  DeliveryService1 = 'deliveryService1',
  DeliveryPointId1 = 'deliveryPointId1',
  SubBuilding1 = 'subBuilding1',
  SubBuilding2 = 'subBuilding2',
  Building1 = 'building1',
  Organisation1 = 'organisation1',
  StreetNumber1 = 'streetNumber1',
  StreetNumber2 = 'streetNumber2',
  Street1 = 'street1',
  Locality1 = 'locality1',
  PostalCode1 = 'postalCode1',
  Country1 = 'country1',
  CountryIso1 = 'countryISO1',
}
