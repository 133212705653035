import {
  checkLifestyleIsEligible,
  getJoinLifestyleFormInitialContent,
  postLifestyleSubmitPurchase,
} from '@/constants/forms-api.const';
import { RequestServices as _http } from '../common/request.services';
import { IPartyMatchRequest } from '@/interfaces/entities/party-match-request.interface';

export const JoinWithLifestyleService = {
  /**
   * Get Lifestyle Config
   */
  getJoinLifestyleFormInitialContent: () => {
    return _http.HttpGet(getJoinLifestyleFormInitialContent);
  },

  checkLifestyleIsEligible: (body: IPartyMatchRequest): Promise<boolean | null> => {
    return _http.HttpPost(checkLifestyleIsEligible, body);
  },

  /**
   * Submit Join Lifestyle
   *
   * @param body
   * @returns
   */
  submitPurchase: (body: Record<string, any>): Promise<any> => {
    return _http.HttpPost(postLifestyleSubmitPurchase, body);
  },
};
