export enum FormInputTypes {
  ADDRESS_AUTOCOMPLETE = 'addressautocomplete',
  LABEL = 'label',
  FIND_CAR = 'findcar',
  DATE_INPUT = 'dateinput',
  TITLE_NAME = 'titlename',
  CUSTOM = 'custom',
  GENDER = 'gender',
  CALL_TO_ACTION_CARD = 'calltoactioncard',
  DISCLAIMER = 'disclaimer',
  SECTION_FRAME = 'sectionframe',
  PAYMENT_SECTION = 'paymentsection',
  DATEPICKER = 'datepicker',
  LOGINBUTTON = 'loginButton',
  RADIO_BUTTON_CARD_GROUP = 'radiobuttoncardgroup',
  PROGRESS_TRACKER = 'progresstracker',
  PHONE_NUMBER = 'tel',
  PANEL_TITLE = 'paneltitle',
  CHECKBOX = 'checkbox',
  CUSTOM_EMAIL_CONFIRMATION = 'customemailconfirmation',
}
