import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col gap-8 md:gap-6"
}
const _hoisted_2 = {
  key: 0,
  "data-testid": "addressComponentTitle",
  class: "text-input-title"
}

import { watch, ref, onMounted, computed } from 'vue';
import OverseasOption from './OverseasOption.vue';
import AddressLookup from './AddressLookup.vue';
import ManualAddressForm from './ManualAddressForm.vue';
import { ISitecoreFormGenericInput, RACQAddressResult } from '@/interfaces';
import {
  addressLookupEmits,
  emitRiskSuburbSelected,
  emitAddressSelected,
  AddressComponentOptions,
  useMultiInstanceAddressOptionsStore,
} from '@/store';
import { convertSitecoreFormFieldToVueFormField } from '@/composables/useFormInputData';
import { Field } from '@sitecore-jss/sitecore-jss-vue';
import { v4 } from 'uuid';

interface AddressLookupProps extends ISitecoreFormGenericInput {
  // CMS Fields
  formInputId: Field<string>;
  innerClass?: Field<string>;
  streetNoRequired: Field<string>;
  streetRequired: Field<string>;
  suburbRequired: Field<string>;
  stateRequired: Field<string>;
  postcodeRequired: Field<string>;
  countryRequired: Field<string>;
  checkAddressRequired: Field<string>;
  selectSuburbRequired: Field<string>;
  postcodeInvalid: Field<string>;
  invalidInsuranceRiskSuburbMessage: Field<string>;
  postalSameAsInsuredAddressLabel?: Field<string>;
  addressTitle: Field<string>;
  overseasOptionLabel: Field<string>;
  riskSuburbsLabel: Field<string>;
  overseasOptionTooltip: Field<string>;
  postContent: Field<string>;

  // Props
  config?: AddressComponentOptions;
  initialPostalAddress?: RACQAddressResult;
  initialRiskAddress?: RACQAddressResult;
  labelClass?: string;
  isOversea?: boolean;
  defaultIsOverseasOption?: boolean;
  showManualEntries?: boolean;
  instanceIdentifier?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddressLookupOptions',
  props: {
    formInputId: {},
    innerClass: {},
    streetNoRequired: {},
    streetRequired: {},
    suburbRequired: {},
    stateRequired: {},
    postcodeRequired: {},
    countryRequired: {},
    checkAddressRequired: {},
    selectSuburbRequired: {},
    postcodeInvalid: {},
    invalidInsuranceRiskSuburbMessage: {},
    postalSameAsInsuredAddressLabel: {},
    addressTitle: {},
    overseasOptionLabel: {},
    riskSuburbsLabel: {},
    overseasOptionTooltip: {},
    postContent: {},
    config: {},
    initialPostalAddress: {},
    initialRiskAddress: {},
    labelClass: {},
    isOversea: { type: Boolean },
    defaultIsOverseasOption: { type: Boolean },
    showManualEntries: { type: Boolean },
    instanceIdentifier: {},
    id: {},
    name: {},
    label: {},
    inputType: {},
    placeholderText: {},
    preContent: {},
    postContentClass: {},
    preContentClass: {},
    tooltipContent: {},
    tooltipModalTitle: {},
    tooltipShowInModal: {},
    fields: {},
    emptyMessage: {},
    validation: {},
    outerClass: {},
    refData: {},
    prefixIcon: {},
    suffixIcon: {},
    helpXs: {},
    helpSm: {},
    helpMd: {},
    disablePaste: {},
    classesOptions: {},
    showClearIcon: {},
    options: {},
    defaultOption: {},
    isRequired: {},
    isRequiredErrorMessage: {},
    validationRegex: {},
    validationRegexErrorMessage: {},
    mustAccept: {},
    mustAcceptErrorMessage: {},
    mask: {},
    isHidden: { type: Boolean },
    showPostContent: { type: Boolean },
    dynamicOptions: {},
    elementId: {},
    allowOverseasAddress: {},
    allowPhysicalAddressesOnly: {},
    titleRequiredMessage: {},
    firstnameRequiredErrorMessage: {},
    firstnameRegex: {},
    firstnameRegexErrorMessage: {},
    lastnameRequiredErrorMessage: {},
    lastnameRegex: {},
    lastnameRegexErrorMessage: {},
    format: {},
    pickerOnly: {},
    minDateToday: {},
    maxDateByDays: {},
    icon: {},
    title: {},
    subTitle: {},
    decimals: {}
  },
  emits: addressLookupEmits,
  setup(__props: any, { expose: __expose, emit: __emit }) {

const emit = __emit;

const props = __props;

const addressLookupAutoProps = convertSitecoreFormFieldToVueFormField(props as any, props.formInputId.value);
const multiInstanceAddressStore = useMultiInstanceAddressOptionsStore();
const activeAddressComponent = ref<Partial<RACQAddressResult>>({});
const identifier = ref(props.instanceIdentifier || v4());
const store = computed(() =>
  multiInstanceAddressStore.itemIds.includes(identifier.value)
    ? multiInstanceAddressStore.getInstance(identifier.value)
    : undefined
);

onMounted(() => {
  if (!store.value) multiInstanceAddressStore.addInstance(identifier.value);
  if (props.config)
    store.value?.setupAddressComponent(props.config, props.initialRiskAddress, props.initialPostalAddress);
});

__expose({
  getStore: () => store.value,
});

/*
Decision maker as to whether auto or manual address is used.
*/
watch(
  () => store.value?.useAddress,
  async (newValue) => {
    const newAddress = newValue as RACQAddressResult;
    if (!store.value) return;
    if (store.value.isValidAddress(newAddress)) {
      if (!newValue?.dpid) {
        store.value.setManualAddress(newAddress);
        //When manual address set the address line to 'Not in list...'
        store.value.autoAddressComponents.addressLine = props?.postContent?.value;
      } else {
        store.value.setAutoAddress(newAddress);
      }
    } else {
      store.value.isOverseasSelection = props.defaultIsOverseasOption;
    }
  },
  { once: true }
);

function onRiskSuburbSelected(value: Partial<RACQAddressResult>) {
  activeAddressComponent.value = value;
  emit(emitRiskSuburbSelected, value);
}

function onAddressSelected(value: Partial<RACQAddressResult>) {
  activeAddressComponent.value = value;
  emit(emitAddressSelected, value);
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (store.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (store.value.showTitle())
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(props.addressTitle.value), 1))
          : _createCommentVNode("", true),
        (store.value.config?.allowOverseasAddress)
          ? (_openBlock(), _createBlock(OverseasOption, {
              key: 1,
              defaultIsOverseasOption: _ctx.defaultIsOverseasOption,
              instanceIdentifier: identifier.value,
              overseasOptionLabel: _ctx.overseasOptionLabel?.value,
              overseasOptionTooltip: _ctx.overseasOptionTooltip?.value
            }, null, 8, ["defaultIsOverseasOption", "instanceIdentifier", "overseasOptionLabel", "overseasOptionTooltip"]))
          : _createCommentVNode("", true),
        _createVNode(AddressLookup, {
          bindings: _unref(addressLookupAutoProps),
          defaultIsOverseasOption: _ctx.defaultIsOverseasOption,
          streetNoRequired: _ctx.streetNoRequired?.value,
          streetRequired: _ctx.streetRequired?.value,
          suburbRequired: _ctx.suburbRequired?.value,
          postcodeRequired: _ctx.postcodeRequired?.value,
          countryRequired: _ctx.countryRequired?.value,
          checkAddressRequired: _ctx.checkAddressRequired?.value,
          selectSuburbRequired: _ctx.selectSuburbRequired?.value,
          stateRequired: _ctx.stateRequired?.value,
          postcodeInvalid: _ctx.postcodeInvalid?.value,
          postalSameAsInsuredAddressLabel: _ctx.postalSameAsInsuredAddressLabel?.value,
          invalidInsuranceRiskSuburbMessage: _ctx.invalidInsuranceRiskSuburbMessage?.value,
          instanceIdentifier: identifier.value,
          onAddressSelected: _cache[0] || (_cache[0] = (v) => onAddressSelected(v))
        }, null, 8, ["bindings", "defaultIsOverseasOption", "streetNoRequired", "streetRequired", "suburbRequired", "postcodeRequired", "countryRequired", "checkAddressRequired", "selectSuburbRequired", "stateRequired", "postcodeInvalid", "postalSameAsInsuredAddressLabel", "invalidInsuranceRiskSuburbMessage", "instanceIdentifier"]),
        _createVNode(ManualAddressForm, {
          name: _ctx.name,
          streetNoRequired: _ctx.streetNoRequired?.value,
          streetRequired: _ctx.streetRequired?.value,
          suburbRequired: _ctx.suburbRequired?.value,
          postcodeRequired: _ctx.postcodeRequired?.value,
          countryRequired: _ctx.countryRequired?.value,
          checkAddressRequired: _ctx.checkAddressRequired?.value,
          selectSuburbRequired: _ctx.selectSuburbRequired?.value,
          stateRequired: _ctx.stateRequired?.value,
          postcodeInvalid: _ctx.postcodeInvalid?.value,
          invalidInsuranceRiskSuburbMessage: _ctx.invalidInsuranceRiskSuburbMessage?.value,
          riskSuburbsLabel: _ctx.riskSuburbsLabel?.value,
          instanceIdentifier: identifier.value,
          onRiskSuburbSelected: _cache[1] || (_cache[1] = (v) => onRiskSuburbSelected(v))
        }, null, 8, ["name", "streetNoRequired", "streetRequired", "suburbRequired", "postcodeRequired", "countryRequired", "checkAddressRequired", "selectSuburbRequired", "stateRequired", "postcodeInvalid", "invalidInsuranceRiskSuburbMessage", "riskSuburbsLabel", "instanceIdentifier"]),
        _createVNode(_component_FormKit, {
          modelValue: activeAddressComponent.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((activeAddressComponent).value = $event)),
          type: "hidden",
          name: _ctx.formInputId.value
        }, null, 8, ["modelValue", "name"])
      ]))
    : _createCommentVNode("", true)
}
}

})