import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useGoogleMapStore } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleCurrentLocationButton',
  emits: ['selected'],
  setup(__props, { emit: __emit }) {

// Add this line to reference the Google Maps types
/// <reference types="@types/google.maps" />

const googleMapStore = useGoogleMapStore();
const emit = __emit;

const getCurrentLocation = async () => {
  if (!navigator.geolocation) {
    alert('Geolocation is not supported by your browser');
    return;
  }

  navigator.geolocation.getCurrentPosition(
    async (position) => {
      const { latitude, longitude } = position.coords;

      // Use the Google Maps API to get the address from the coordinates
      const geocoder = new google.maps.Geocoder();
      const latlng = { lat: latitude, lng: longitude };

      geocoder.geocode({ location: latlng }, (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            const { formatted_address } = results[0];
            googleMapStore.setFormattedAddress(formatted_address);
            googleMapStore.setLatitude(latitude);
            googleMapStore.setLongitude(longitude);
            googleMapStore.setAddressStateFromAddressComponents(results[0]?.address_components);
          }
        }
      });
    },
    (error) => {
      googleMapStore.emitErrorEvent(error);
    }
  );
};

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createBlock(_component_FormKit, {
    type: "button",
    label: "Use current location",
    inputClass: "location-button-text btn btn-md btn-secondary py-2 px-4 h-[40px] font-sans font-base leading-normal !border-x !border-y border-racq-navy",
    onClick: getCurrentLocation
  }))
}
}

})