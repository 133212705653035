<template>
  <div v-if="addressLookupOptionsStore.isManualEntries || addressLookupOptionsStore.isOverseasSelection">
    <!-- PS: Need to sets the type as form, otherwise the submit button submits the step -->
    <FormKit
      v-model="addressLookupOptionsStore.manualAddressComponents"
      type="form"
      @submit="checkAddress()"
      :name="name + '_manual'"
      :disabled="!addressLookupOptionsStore.manualEditEnabled"
      :actions="false"
      :incompleteMessage="false"
    >
      <div class="flex flex-col gap-8 md:gap-6">
        <FormKit
          data-testid="unitNumber"
          name="unitNumber"
          validationLabel="unitNo"
          maxLength="100"
          label="Unit number (optional)"
          innerClass="input-xs"
        />
        <FormKit
          data-testid="streetNumber"
          name="streetNumber"
          validationLabel="Street No."
          label="Street number"
          maxLength="100"
          validation="required"
          messageClass="md:!pl-[0px]"
          :validationMessages="{ required: streetNoRequired }"
          innerClass="input-xs"
        />
        <FormKit
          data-testid="street"
          name="street"
          validationLabel="Street"
          label="Street"
          maxLength="100"
          validation="required"
          messageClass="md:!pl-[0px]"
          outerClass="col-span-2"
          :validationMessages="{ required: streetRequired }"
          innerClass="input-md"
        />
        <FormKit
          data-testid="suburb"
          name="suburb"
          validationLabel="Suburb"
          label="Suburb"
          maxLength="100"
          validation="required"
          messageClass="md:!pl-[0px]"
          outerClass="col-span-2"
          :validationMessages="{ required: suburbRequired }"
          innerClass="input-md"
        />
        <FormKit
          v-if="!addressLookupOptionsStore.isOverseasSelection"
          data-testid="state"
          name="state"
          validationLabel="State"
          type="dropdown"
          :options="states"
          label="State"
          validation="required"
          messagesClass="md:ml-[0px] md:pl-[0px]"
          :validationMessages="{ required: stateRequired }"
          innerClass="input-xs"
        />
        <FormKit
          v-if="!addressLookupOptionsStore.isOverseasSelection"
          data-testid="postcode"
          name="postcode"
          maxLength="4"
          validationLabel="Postcode"
          :validation="[['required'], ['matches', /\d{4,4}/]]"
          label="Postcode"
          messageClass="md:!pl-[0px]"
          type="tel"
          :validationMessages="{ required: postcodeRequired, matches: postcodeInvalid }"
          innerClass="input-xs"
        />
        <FormKit
          v-else
          name="postcode"
          validationLabel="Postcode"
          data-testid="postcode"
          label="Postcode (Optional)"
          maxLength="100"
          outerClass="col-span-2"
          innerClass="input-xs"
        />
        <FormKit
          v-if="addressLookupOptionsStore.isOverseasSelection"
          data-testid="country"
          name="country"
          validationLabel="Country"
          maxLength="100"
          label="Country"
          placeholder="Country"
          validation="required"
          :validationMessages="{ required: countryRequired }"
          messageClass="md:!pl-[0px]"
          outerClass="col-span-2"
          innerClass="input-xs"
          :options="countries"
          type="autocomplete"
          openOnFocus
          openOnClick
        />
        <FormKit
          ref="manualEditButtonRef"
          type="submit"
          data-testid="btnCheckAddress"
          :label="addressLookupOptionsStore.manualEditEnabled ? 'Check address' : 'Edit address'"
          outerClass="col-span-4"
          wrapperClass="$reset"
          :disabled="false"
          inputClass="btn btn-md btn-primary btn-edit"
        />
      </div>
    </FormKit>
    <FormKit
      v-if="addressLookupOptionsStore.manualEditEnabled && !addressLookupOptionsStore.isOverseasSelection"
      validation="required"
      wrapperClass="hidden"
      title="checkAddressValidation"
      :validationMessages="{ required: checkAddressRequired }"
    />

    <div v-if="suburbLookupOptions?.length">
      <hr class="input-spacing" />
      <p class="text-base-bold">{{ riskSuburbsLabel }}</p>

      <FormKit
        v-if="suburbLookupOptions?.length"
        ref="suburbLookupOptionsRef"
        v-model="selectedRiskSuburb"
        @input="riskAddressSelected"
        outerClass="mt-4"
        :options="suburbs"
        validation="required"
        title="suburbLookup"
        :validationMessages="{ required: selectSuburbRequired }"
        type="radiobuttoncardgroup"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { IDropdownItem, IRadioButtonCard, IRiskSuburbLookupOption } from '@/interfaces';
import { INameValue } from '@/interfaces/entities/name-value.interface';
import { useMultiInstanceAddressOptionsStore, useFormStore } from '@/store';
import { isPostalAddress } from '@/utils/regex-helpers';
import { joinAddressComponents } from '@/mappers';
import { addressLookupEmits, emitRiskSuburbSelected, useAddressLookupStore } from '@/store';
import { isEmptyObject } from '@/utils/check-helpers';
import { AUSTRALIA } from '@/constants/general-words.const';

const props = defineProps<{
  name: string;
  defaultIsOverseasOption?: boolean;
  showManualEntries?: boolean;
  streetNoRequired: string;
  streetRequired: string;
  suburbRequired: string;
  stateRequired: string;
  postcodeRequired: string;
  countryRequired: string;
  checkAddressRequired: string;
  selectSuburbRequired: string;
  postcodeInvalid: string;
  invalidInsuranceRiskSuburbMessage: string;
  riskSuburbsLabel: string;
  instanceIdentifier: string;
}>();

const manualEditButtonRef = ref(null);
const suburbLookupOptionsRef = ref(null);

const selectedRiskSuburb = ref<string>();
const states = ref<IDropdownItem[]>([]);
const countries = ref<IDropdownItem[]>([]);
const suburbs = ref<IRadioButtonCard[]>([]);

const formStore = useFormStore();
const addressLookupStore = useAddressLookupStore();

const multiInstanceAddressStore = useMultiInstanceAddressOptionsStore();
const addressLookupOptionsStore = multiInstanceAddressStore.getInstance(props.instanceIdentifier);

const translation = useI18n();

const emit = defineEmits(addressLookupEmits);

const suburbLookupOptions = computed({
  get() {
    return addressLookupOptionsStore.suburbLookupOptions || [];
  },
  set(newValue) {
    addressLookupOptionsStore.suburbLookupOptions = newValue;
  },
});

onMounted(async () => {
  const dict: Record<string, string> = translation?.getLocaleMessage('en') as any;
  if (dict?.INPUT_ADDRESS_STATE) {
    states.value = dict.INPUT_ADDRESS_STATE.split(',').map((item) => ({ label: item, value: item }));
  }

  if (addressLookupOptionsStore.isOverseasSelection) {
    countries.value = await lookupCountry();
  }
});

function riskAddressSelected(selected: any) {
  if (!selected) return;
  const selectedOption = JSON.parse(selected);
  addressLookupOptionsStore.manualAddressComponents.suburb = selectedOption.suburb;
  addressLookupOptionsStore.manualAddressComponents.state = selectedOption.state;
  addressLookupOptionsStore.manualAddressComponents.country = selectedOption.country;

  //Do this to keep the selected value shown with tick/confirmation as per UX feedback.
  suburbLookupOptions.value = [convertAddressComponentToRiskSuburb()];

  const selectedFromList = suburbs.value.find(
    (x) =>
      cleanRiskAddressForComparison(x.leftTitle) ===
      cleanRiskAddressForComparison(
        addressLookupOptionsStore.buildAddressLine(addressLookupOptionsStore.manualAddressComponents)
      )
  );

  if (!isEmptyObject(addressLookupOptionsStore.manualAddressComponents) && selectedFromList) {
    emit(emitRiskSuburbSelected, addressLookupOptionsStore.manualAddressComponents);
    addressLookupOptionsStore.onRiskSuburbSelected();
  }
}

function cleanRiskAddressForComparison(address: string) {
  return address.replaceAll(',', '').replaceAll(' ', '');
}

/**
 * Handles when the manual address is set, here we utilise the suburb lookup options to show selection confirmed.
 */
watch(
  () => addressLookupOptionsStore.manualAddressComponents,
  async (newValue) => {
    if (newValue) {
      const riskSuburb = convertAddressComponentToRiskSuburb();

      if (
        riskSuburb.suburb &&
        riskSuburb.postcode &&
        (riskSuburb.state || addressLookupOptionsStore.isOverseasSelection)
      ) {
        suburbLookupOptions.value = [riskSuburb];
        selectedRiskSuburb.value =
          suburbLookupOptions.value.length > 0 ? JSON.stringify(suburbLookupOptions.value[0]) : '';
      }
    }
  },
  { once: true }
);

watch(
  () => addressLookupOptionsStore.isOverseasSelection,
  async (newValue) => {
    if (newValue && countries.value.length == 0) {
      countries.value = await lookupCountry();
    }
  }
);

watch(suburbLookupOptions, (newValue) => {
  suburbs.value = newValue.map((option) => ({
    leftTitle: joinAddressComponents({
      ...addressLookupOptionsStore.manualAddressComponents,
      ...option,
    }),
    value: JSON.stringify(option),
    showLeftRadioButton: true,
    groupName: '',
  }));
});

async function lookupCountry(): Promise<IDropdownItem[]> {
  try {
    const data: INameValue[] = await addressLookupStore.getCountryLookup('', true);
    return (
      data?.map((item: INameValue) => ({
        label: item.name,
        value: item.value,
      })) || []
    );
  } catch {
    return [];
  }
}

function convertAddressComponentToRiskSuburb(checkCountry?: boolean): IRiskSuburbLookupOption {
  if (checkCountry && !addressLookupOptionsStore.isOverseasSelection) {
    addressLookupOptionsStore.manualAddressComponents.country = AUSTRALIA;
  }

  return {
    suburb: addressLookupOptionsStore.manualAddressComponents?.suburb,
    postcode: addressLookupOptionsStore.manualAddressComponents?.postcode,
    state: addressLookupOptionsStore.manualAddressComponents?.state,
    country: addressLookupOptionsStore.manualAddressComponents?.country,
  } as IRiskSuburbLookupOption;
}

async function checkAddress() {
  // Reset suburb options
  selectedRiskSuburb.value = undefined;
  suburbLookupOptions.value = [];

  //If in 'Edit address' state enable the form and exit.
  if (!addressLookupOptionsStore.manualEditEnabled) {
    addressLookupOptionsStore.toggleManualFormDisabled();
    return;
  }

  if (addressLookupOptionsStore.isOverseasSelection) {
    validateOverseasAddressAndEmit();
    return;
  } else {
    addressLookupOptionsStore.manualAddressComponents.country = AUSTRALIA;
    addressLookupOptionsStore.manualAddressComponents.countryISO = '';
  }

  const riskSelectionMode = (await checkAddressInsuranceRiskSuburb()) ?? false;
  if (riskSelectionMode) return;

  if (isPostalAddress(addressLookupOptionsStore.manualAddressComponents.street ?? '')) {
    if (addressLookupOptionsStore?.config?.allowPhysicalAddressesOnly) {
      addressLookupOptionsStore.manualAddressComponents.street = '';
      if (!isEmptyObject(addressLookupOptionsStore.manualAddressComponents)) {
        emit(emitRiskSuburbSelected, addressLookupOptionsStore.manualAddressComponents);
      }
    }
  } else {
    addressLookupOptionsStore.manualAddressComponents.addressLine = addressLookupOptionsStore.joinAddressLine(
      addressLookupOptionsStore.manualAddressComponents
    );
    addressLookupOptionsStore.useAddress = addressLookupOptionsStore.manualAddressComponents;

    if (!isEmptyObject(addressLookupOptionsStore.manualAddressComponents)) {
      emit(emitRiskSuburbSelected, addressLookupOptionsStore.manualAddressComponents);
    }
  }
}

async function checkAddressInsuranceRiskSuburb() {
  formStore.showLoadingForCurrentStep();

  const results = await addressLookupOptionsStore.checkAddressInsuranceRiskSuburb(true);

  if (addressLookupOptionsStore?.config?.allowValidInsuranceRiskSuburbsOnly) {
    if (!addressLookupOptionsStore.manualEditEnabled && manualEditButtonRef.value) {
      (manualEditButtonRef.value as any).node.setErrors(results?.match ? '' : props.invalidInsuranceRiskSuburbMessage);
    }
  }

  if (results?.match) {
    //UX even when there is a single match we want to show the list and prompt to select the address.
    suburbLookupOptions.value = [results?.match];
    addressLookupOptionsStore.onRiskSuburbSelected();
  } else {
    suburbLookupOptions.value = results?.riskSuburbs ?? [];
    selectedRiskSuburb.value = undefined;
  }

  formStore.showLoadingForCurrentStep(false);

  const selectRiskMode = !results?.match && results && results.riskSuburbs.length > 0;
  return selectRiskMode;
}

function validateOverseasAddressAndEmit() {
  if (addressLookupOptionsStore.manualAddressComponents) {
    if (addressLookupOptionsStore.isOverseasSelection) {
      const validCountry = countries.value.find(
        (x) => x.value === addressLookupOptionsStore.manualAddressComponents.country
      );

      if (
        validCountry &&
        addressLookupOptionsStore.manualAddressComponents.suburb &&
        addressLookupOptionsStore.manualAddressComponents.suburb.length > 0
      ) {
        //Do this to keep the selected value shown with tick/confirmation as per UX feedback.
        suburbLookupOptions.value = [convertAddressComponentToRiskSuburb()];
        emit(emitRiskSuburbSelected, addressLookupOptionsStore.manualAddressComponents);
        addressLookupOptionsStore.toggleManualFormDisabled();
      }
    }
  }
}
</script>
