import { IQuote } from '@/interfaces';
import { replaceBookmark } from '@/utils/bookmark.replace';
import { toCurrency } from '@/utils/convert';

export enum Bookmarks {
  firstName = 'firstName',
  membershipLevel = 'membershipLevel',
  quoteAmountAnnually = 'quoteAmountAnnually',
  quoteAmountMonthly = 'quoteAmountMonthly',
}

export const replaceLoyaltyReviewBookmarks = (content: string, quote: Partial<IQuote>) => {
  let replaceBookmarksContent = replaceBookmark(
    Bookmarks.firstName,
    content,
    quote?.primaryPolicyHolderDetails?.firstname.value ?? ''
  );
  replaceBookmarksContent = replaceBookmark(
    Bookmarks.membershipLevel,
    replaceBookmarksContent,
    quote?.primaryPolicyHolderDetails?.membershipLoyaltyLevel.value ?? ''
  );

  if (quote.insuranceQuoteSummary) {
    replaceBookmarksContent = replaceBookmark(
      Bookmarks.quoteAmountAnnually,
      replaceBookmarksContent,
      toCurrency(quote.insuranceQuoteSummary.yearlyAnnualPremium)
    );
    replaceBookmarksContent = replaceBookmark(
      Bookmarks.quoteAmountMonthly,
      replaceBookmarksContent,
      toCurrency(quote.insuranceQuoteSummary.monthlyInstalment)
    );
  }

  return replaceBookmarksContent;
};
