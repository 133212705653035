<template>
  <span
    :class="[
      'flex items-center text-xs text-racq-dark-grey bg-racq-info-blue/10 rounded-lg border-none py-[5px] pl-1.5 pr-2',
      getTypeClasses(),
      outerClass ?? '',
    ]"
  >
    {{ tagText }}
  </span>
</template>

<script setup lang="ts">
import { TagTypes } from '@/enums/tag-enums';

const props = defineProps<{
  tagText: string;
  tagType: TagTypes;
  outerClass?: string;
}>();

const getTypeClasses = () => {
  switch (props.tagType) {
    case TagTypes.discount:
      return 'discount-tag';

    default:
      return '';
  }
};
</script>
