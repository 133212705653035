export interface TriageAnswer {
  id: string;
  answer: string;
  eventTypes: string;
  eventSubType: string;
  status: string;
  imageUrl?: string;
  title?: string;
}

export interface TriageQuestion {
  id: string;
  question: string;
  multipleChoice: boolean;
  answers: TriageAnswer[]; // Adjust the type of answers if you have a specific structure for answers
}

export interface RoadsideAssistance {
  club: string;
  clubName: string;
  description: string;
  initialQuestionId: string;
  triageQuestions: TriageQuestion[];
}

export interface AssistanceMatchPartyRequest {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber?: string;
  emailAddress?: string;
  postalAddressDpid?: string;
}

export interface AssistanceMatchPartyResponse {
  apttusId?: string;
  d365Id?: string;
  matchStatus?: PartyMatchStatus;
}

export enum PartyMatchStatus {
  None = 'None',
  Exact = 'Exact',
  Multiple = 'Multiple',
}
