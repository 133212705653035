import { OrdinalNumbers } from '@/constants/ordinal-numbers.const';

export function formatCurrency(currencyValue?: number | null, decimalPlaces: number = 0): string {
  if (!currencyValue) return '';

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'AUD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: decimalPlaces, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: decimalPlaces, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(currencyValue).replace('A', '').trim();
}

export function toProperCase(str = '') {
  if (!str || str == '' || typeof str !== 'string') return;
  return str.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
}

export function addOrdinalSuffix(
  stepNumber: number | undefined,
  text: string | undefined = '',
  textWithPlaceholder: string | null = null
): string {
  // Return an empty string if both text and textWithPlaceholder are missing
  if (!text && !textWithPlaceholder) return '';

  // If stepNumber is null, return text or textWithPlaceholder directly
  if (stepNumber == null) return text ?? textWithPlaceholder ?? '';

  // Lookup ordinal suffix or default to empty string if stepNumber is out of range
  const ordinalText = OrdinalNumbers[stepNumber] ?? '';

  // Use textWithPlaceholder if provided, replacing placeholder with ordinalText
  if (textWithPlaceholder) {
    return textWithPlaceholder.replace('{ordinalSuffix}', ordinalText).trim();
  }

  // Fallback to appending ordinalText to text
  return `${ordinalText} ${text}`.trim();
}

/**
 * Format masked card number
 *
 * @param cardNumber 1234XXXXXXXX5678
 * @returns **** **** **** 1234
 */
export function formatMaskedCardNumber(cardNumber: string) {
  try {
    return '**** **** **** ' + cardNumber.slice(cardNumber.length - 4, cardNumber.length);
  } catch {
    return cardNumber;
  }
}
