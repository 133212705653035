import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { id: "jeopardy-container" }
const _hoisted_2 = {
  key: 0,
  class: "sm:mt-0 md:mt-8 lg:mt-14"
}
const _hoisted_3 = { class: "flex-col justify-start items-start gap-6 inline-flex w-full" }
const _hoisted_4 = {
  key: 0,
  class: "justify-start items-start gap-4 inline-flex"
}
const _hoisted_5 = { class: "grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex" }
const _hoisted_6 = { class: "self-stretch text-racq-dark-grey text-xl-bold" }
const _hoisted_7 = { class: "self-stretch text-racq-dark-grey text-base" }
const _hoisted_8 = {
  key: 1,
  class: "block w-full"
}
const _hoisted_9 = {
  key: 2,
  class: "block w-full"
}
const _hoisted_10 = {
  key: 3,
  class: "self-stretch bg-racq-anti-flash-white rounded-md flex-col justify-start items-start flex"
}
const _hoisted_11 = {
  key: 4,
  class: "self-stretch bg-racq-anti-flash-white rounded-md flex-col justify-start items-start flex"
}
const _hoisted_12 = { class: "self-stretch pt-5 rounded justify-center items-center gap-2.5 inline-flex" }
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 0,
  class: "self-stretch pt-4 pb-6 flex-col justify-center items-center flex"
}
const _hoisted_15 = { class: "px-5 flex-col justify-start items-center gap-4 flex racq-max-w-lg" }
const _hoisted_16 = { class: "text-center text-racq-dark-grey text-lg-bold" }
const _hoisted_17 = { class: "text-center text-dark-grey-text text-m" }
const _hoisted_18 = {
  key: 1,
  class: "self-stretch h-5 px-8 justify-start items-start gap-6 inline-flex"
}
const _hoisted_19 = {
  key: 2,
  class: "self-stretch pt-4 pb-6 rounded justify-center items-center gap-2.5 inline-flex"
}
const _hoisted_20 = { class: "grow shrink basis-0 flex-col justify-start items-center gap-2.5 inline-flex racq-max-w-lg" }
const _hoisted_21 = { class: "text-center text-racq-dark-grey text-lg-bold" }
const _hoisted_22 = { class: "text-center text-dark-grey-text text-m" }
const _hoisted_23 = { class: "flex-col justify-start items-center gap-4 flex" }
const _hoisted_24 = ["href"]
const _hoisted_25 = {
  key: 3,
  class: "self-stretch h-5 px-8 justify-start items-start gap-6 inline-flex"
}
const _hoisted_26 = {
  key: 4,
  class: "self-stretch pt-4 pb-6 flex-col justify-center items-center flex"
}
const _hoisted_27 = { class: "px-5 flex-col justify-start items-center gap-4 flex racq-max-w-lg" }
const _hoisted_28 = { class: "text-center text-racq-dark-grey text-lg-bold" }
const _hoisted_29 = { class: "text-center text-dark-grey-text text-m" }
const _hoisted_30 = {
  key: 5,
  class: "form-buttons w-full"
}

import { useRoute } from 'vue-router';
import { watch, computed, ref } from 'vue';
import FormStep from '@/components/FormStep/FormStep.vue';
import { FormKit } from '@formkit/vue';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { LinkField } from '@sitecore-jss/sitecore-jss-vue/types/components/Link';
import { useJeopardyStore, useLiveChatStore, useFormStore } from '@/store';
import { scrollToElement } from '@/utils/dom-helpers';
import AccountLoginInfoBox from '@/components/AccountLoginInfoBox/AccountLoginInfoBox.vue';
import { JP_LOGGEDOUT } from '@/constants/jeopardy-codes.const';

// Define props and state
// ------------------------------------------------------------- //


export default /*@__PURE__*/_defineComponent({
  __name: 'JeopardyV2',
  props: {
    fields: {},
    testParams: {}
  },
  setup(__props: any) {

const props = __props;

const route = useRoute();

// Initialise Pinia store/s
// ------------------------------------------------------------- //
const jpStore = useJeopardyStore();
const formStore = useFormStore();
const liveChatStore = useLiveChatStore();

// Computed properties
// ------------------------------------------------------------- //

const fields = computed(() => props.fields);
const jpText = computed(() => jpStore.jpText);
const jpTextLoaded = computed(() => jpStore.jpTextLoaded);
const jpHeading = computed(() => jpStore.jpHeading);
const showJeopardy = computed(() => jpStore.showJeopardy);
const isChatEnabled = computed(() => jpStore.isChatEnabled);
const isChatLoading = computed(() => jpStore.isChatLoading);
const isCallBackVisible = computed(() => jpStore.isCallBackVisible);
const isCallUsVisible = computed(() => jpStore.isCallUsVisible);
const backBtnText = computed(() => jpStore.backBtnText);
const isSessionTimeout = computed(() => JP_LOGGEDOUT == jpStore.jpCode);
const backBtnDisabled = computed(() => jpStore.backBtnDisabled || isSessionTimeout.value);
const callBackFormLink = computed(
  () =>
    (props.fields.callBackFormLink as LinkField)?.value?.href +
    '?policyid=' +
    (route?.query?.policyId ?? route?.query?.policyNumber)
);

// Component setup
// ------------------------------------------------------------- //

if (props.testParams) {
  // props.testParams is only populated via storybook / tests to set initial state
  jpStore.setChatLoading(props.testParams.isChatLoading);
  jpStore.showJeopardyPanel({
    isChatEnabled: props.testParams.isChatEnabled,
    isCallBackVisible: props.testParams.isCallBackVisible,
    isCallUsVisible: props.testParams.isCallUsVisible,
    jpCode: props.testParams.jpCode,
  });
}

// Lifecycle Hooks
// ------------------------------------------------------------- //

watch(
  () => jpStore.showJeopardy,
  (newValue) => {
    if (jpStore.showDynamic) {
      setJeopardyFieldsDynamic();
    } else {
      setJeopardyFieldsDefault();
    }
    if (newValue) {
      scrollToElement('#jeopardy-container', 500, -100);
    }
  }
);

// Functions
// ------------------------------------------------------------- //

/**
 * Sets the fields on the jeopardy to the one loaded into jpDynamicPanelResult.
 */
function setJeopardyFieldsDynamic() {
  setJeopardyFields(jpStore.jpDynamicPanelResult);
}

/**
 * Sets the fields on the jeopardy to the default.
 */
function setJeopardyFieldsDefault() {
  setJeopardyFields(jpStore.jpDefaultPanelResult);
}

function setJeopardyFields(jpPanelResult: any) {
  fields.value.liveChatHeading.value = jpPanelResult.item.liveChatHeading.value;
  fields.value.liveChatContent.value = jpPanelResult.item.liveChatContent.value;
  fields.value.liveChatButtonText.value = jpPanelResult.item.liveChatButtonText.value;
  fields.value.callUsHeading.value = jpPanelResult.item.callUsHeading.value;
  fields.value.callUsContent.value = jpPanelResult.item.callUsContent.value;
  fields.value.callUsButtonLabel.value = jpPanelResult.item.callUsButtonLabel.value;
  fields.value.callUsNumber.value = jpPanelResult.item.callUsNumber.value;
  fields.value.callBackHeading.value = jpPanelResult.item.callBackHeading.value;
  fields.value.callBackContent.value = jpPanelResult.item.callBackContent.value;
  fields.value.callBackButtonText.value = jpPanelResult.item.callBackButtonText.value;
  fields.value.callBackFormLink.value = jpPanelResult.item.callBackFormLink.value;
}

function startChat() {
  liveChatStore.startChat();
}

function btnBackClick() {
  const activeStep = formStore.inScopeSteps.findIndex((item) => item.isActive);
  formStore.scrollToStep(activeStep == -1 ? 0 : activeStep);
  jpStore.btnBackClick();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (showJeopardy.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(FormStep, {
            step: _unref(jpStore).step
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                (jpTextLoaded.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("h3", _hoisted_6, _toDisplayString(jpHeading.value), 1),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_unref(RichText), {
                            field: { value: jpText.value }
                          }, null, 8, ["field"])
                        ])
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[0] || (_cache[0] = [
                      _createElementVNode("div", { class: "w-32 h-8 animate-pulse bg-racq-pale-blue-border mb-3" }, null, -1),
                      _createElementVNode("div", { class: "w-full h-6 animate-pulse bg-racq-pale-blue-border mb-3" }, null, -1),
                      _createElementVNode("div", { class: "w-1/2 h-6 animate-pulse bg-racq-pale-blue-border" }, null, -1)
                    ]))),
                (isSessionTimeout.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(AccountLoginInfoBox, { fields: fields.value }, null, 8, ["fields"])
                    ]))
                  : (isChatLoading.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[1] || (_cache[1] = [
                        _createElementVNode("div", { class: "self-stretch pt-5 rounded justify-center items-center gap-2.5 inline-flex" }, [
                          _createElementVNode("div", { class: "w-16 h-16 relative bg-racq-pale-blue-border animate-pulse" })
                        ], -1),
                        _createElementVNode("div", { class: "self-stretch pt-4 pb-6 flex-col justify-center items-center flex" }, [
                          _createElementVNode("div", { class: "px-5 flex-col justify-start items-center gap-4 flex racq-max-w-lg w-full" }, [
                            _createElementVNode("div", { class: "h-8 w-full bg-racq-pale-blue-border animate-pulse" }),
                            _createElementVNode("div", { class: "h-4 w-full bg-racq-pale-blue-border animate-pulse" }),
                            _createElementVNode("div", { class: "h-4 w-full bg-racq-pale-blue-border animate-pulse" }),
                            _createElementVNode("div", { class: "h-8 w-40 bg-racq-pale-blue-border animate-pulse" }),
                            _createElementVNode("div", { class: "h-4 w-full bg-racq-pale-blue-border animate-pulse" })
                          ])
                        ], -1)
                      ])))
                    : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("img", {
                            src: require('@/assets/icons/chat.svg'),
                            alt: "Vehicle",
                            role: "img",
                            class: "w-16 h-16 relative"
                          }, null, 8, _hoisted_13)
                        ]),
                        (isChatEnabled.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                              _createElementVNode("div", _hoisted_15, [
                                _createElementVNode("div", _hoisted_16, [
                                  _createVNode(_unref(RichText), {
                                    field: fields.value.liveChatHeading
                                  }, null, 8, ["field"])
                                ]),
                                _createVNode(_unref(FormKit), {
                                  type: "button",
                                  label: fields.value.liveChatButtonText.value,
                                  inputClass: "btn btn-primary",
                                  onClick: startChat
                                }, null, 8, ["label"]),
                                _createElementVNode("div", _hoisted_17, [
                                  _createVNode(_unref(RichText), {
                                    field: fields.value.liveChatContent
                                  }, null, 8, ["field"])
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (isChatEnabled.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[2] || (_cache[2] = [
                              _createElementVNode("div", { class: "grow shrink basis-0 h-5 pt-2 flex-col justify-start items-start gap-2.5 inline-flex" }, [
                                _createElementVNode("div", { class: "h-7 relative border-t border-racq-pale-blue-border w-full" })
                              ], -1)
                            ])))
                          : _createCommentVNode("", true),
                        (isCallUsVisible.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                              _createElementVNode("div", _hoisted_20, [
                                _createElementVNode("div", _hoisted_21, [
                                  _createVNode(_unref(RichText), {
                                    field: fields.value.callUsHeading
                                  }, null, 8, ["field"])
                                ]),
                                _createElementVNode("div", _hoisted_22, [
                                  _createVNode(_unref(RichText), {
                                    field: fields.value.callUsContent
                                  }, null, 8, ["field"])
                                ]),
                                _createElementVNode("div", _hoisted_23, [
                                  _createElementVNode("a", {
                                    class: "text-center text-racq-dark-grey text-xl2-bold no-underline",
                                    href: `tel:${fields.value.callUsNumber.value}`
                                  }, _toDisplayString(fields.value.callUsNumber.value), 9, _hoisted_24),
                                  _createVNode(_unref(FormKit), {
                                    type: "linkButton",
                                    inputClass: "btn btn-primary",
                                    text: fields.value.callUsButtonLabel.value,
                                    href: `tel:${fields.value.callUsNumber.value}`
                                  }, null, 8, ["text", "href"])
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (isCallBackVisible.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_25, _cache[3] || (_cache[3] = [
                              _createElementVNode("div", { class: "grow shrink basis-0 h-5 pt-2 flex-col justify-start items-start gap-2.5 inline-flex" }, [
                                _createElementVNode("div", { class: "h-7 relative border-t border-racq-pale-blue-border w-full" })
                              ], -1)
                            ])))
                          : _createCommentVNode("", true),
                        (isCallBackVisible.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                              _createElementVNode("div", _hoisted_27, [
                                _createElementVNode("div", _hoisted_28, [
                                  _createVNode(_unref(RichText), {
                                    field: fields.value.callBackHeading
                                  }, null, 8, ["field"])
                                ]),
                                _createVNode(_unref(FormKit), {
                                  type: "routerLinkButton",
                                  inputClass: "btn btn-primary",
                                  text: fields.value.callBackButtonText.value,
                                  to: callBackFormLink.value
                                }, null, 8, ["text", "to"]),
                                _createElementVNode("div", _hoisted_29, [
                                  _createVNode(_unref(RichText), {
                                    field: fields.value.callBackContent
                                  }, null, 8, ["field"])
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ])),
                (!backBtnDisabled.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                      _createVNode(_unref(FormKit), {
                        type: "button",
                        label: backBtnText.value,
                        disabled: _unref(formStore).disableAllSteps,
                        inputClass: "btn btn-secondary",
                        outerClass: "",
                        onClick: btnBackClick
                      }, null, 8, ["label", "disabled"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["step"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})