import {
  checkUpdatePostalAddressRequired,
  getInsuranceQuoteById,
  postInsuranceQuoteCreateQuote,
  postInsuranceQuoteApplyRules,
  postInsuranceQuoteCalculatePrice,
  postInsuranceQuoteUpdateQuote,
  postInsuranceSubmitQuote,
} from '@/constants/forms-api.const';
import { RequestServices as _http } from '../common/request.services';
import { IQuote, ISubmitQuote } from '@/interfaces';
import { IQErrorReasonTypes } from '@/enums/iq-error-reason-types.enum';

export interface QuoteResponse {
  quote: IQuote;
  lastUpdated?: Date;
}

export const InsuranceQuoteService = {
  /**
   * Fetch quote details
   *
   * @param quoteId
   * @returns
   */
  fetchQuote(quoteId: string) {
    return _http.HttpGet(getInsuranceQuoteById.replace('{0}', quoteId));
  },

  /**
   * Create new quote
   *
   * @param quote
   */
  createQuote(quote: Partial<IQuote>): Promise<QuoteResponse> {
    return _http.HttpPost(postInsuranceQuoteCreateQuote, { quote });
  },

  /**
   * Apply business rules
   *
   * @param quote
   */
  applyBusinessRules(quoteId: string, quote: Partial<IQuote>) {
    return _http.HttpPost(postInsuranceQuoteApplyRules.replace('{0}', quoteId), { quote }).then((res) => {
      if (res.reasonCodes?.length) {
        res.reasonCodes = res.reasonCodes.filter((reason: { typeOfReason: IQErrorReasonTypes; code: string }) => {
          return reason.typeOfReason != IQErrorReasonTypes.ValueAdjusted;
        });
      }
      return res;
    });
  },

  /**
   * Save quote
   *
   * @param quote
   */
  saveQuote(quoteId: string, quote: Partial<IQuote>) {
    return _http.HttpPost(postInsuranceQuoteUpdateQuote.replace('{0}', quoteId), { quote });
  },

  /**
   * Calculate price
   *
   * @param quote
   */
  calculatePrice(quoteId: string, quote: Partial<IQuote>) {
    return _http.HttpPost(postInsuranceQuoteCalculatePrice.replace('{0}', quoteId), { quote });
  },

  /**
   * Check update postal address is required
   *
   * @param partyId
   * @param dpid
   */
  checkUpdatePostalAddressRequired(partyId: string, dpid: number) {
    return _http.HttpGet(checkUpdatePostalAddressRequired.replace('{0}', partyId).replace('{1}', dpid.toString()));
  },

  /**
   * Submit Quote
   *
   * @param quote
   */
  submitQuote(quoteId: string, submitQuote: ISubmitQuote) {
    return _http.HttpPost(postInsuranceSubmitQuote.replace('{0}', quoteId), submitQuote);
  },
};
