<template>
  <div>
    <FormKit v-bind="displayContext" v-model="model" type="togglebuttons" enforced="true" @input="onInput">
      <template #default="itemContext">
        <RadioButtonCardListItem v-bind="itemContext.option" />
      </template>
    </FormKit>

    <button
      v-if="initialOptionsCountToShow && !model"
      type="button"
      class="link text-base-bold"
      @click="showModal = true"
    >
      Show more
    </button>
    <button v-else-if="model" class="link text-base-bold" type="button" @click="showModal = true">
      Edit selection
    </button>

    <ModalDialog v-model="showModal" :closeOnEsc="true" :showXCloseButton="true" dialogClass="tooltip-dialog">
      <div class="tooltip-body pt-12">
        <FormKit
          v-bind="bindings"
          :id="bindings.id + 'modal'"
          type="togglebuttons"
          enforced="true"
          label=""
          @input="onInput"
        >
          <template #default="itemContext">
            <RadioButtonCardListItem v-bind="itemContext.option" />
          </template>
        </FormKit>
      </div>
    </ModalDialog>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { FormKit } from '@formkit/vue';
import RadioButtonCardListItem from './RadioButtonCardListItem.vue';
import ModalDialog from '@/components/ModalDialog/ModalDialog.vue';
import { cloneDeep } from 'lodash-es';

const props = defineProps<{
  bindings: any;
  initialOptionsCountToShow?: number;
}>();

const model = defineModel<string>();

const showModal = ref(false);
const displayContext = ref(cloneDeep(props.bindings));

if (props.initialOptionsCountToShow) {
  displayContext.value.options = displayContext.value.options.slice(0, props.initialOptionsCountToShow);
}

const onInput = (value: string) => {
  if (!value) {
    displayContext.value.options = props.bindings.options.slice(0, props.initialOptionsCountToShow);
    return;
  }
  displayContext.value.options = props.bindings.options.filter((option: any) => option.value === value);
  model.value = value;
  showModal.value = false;
};
</script>
