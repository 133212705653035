<template>
  <div :class="['flex w-full px-6 py-5 rounded gap-4', backgroundColor]">
    <span v-if="!!icon" class="w-4 h-6 flex-col justify-center items-start gap-2.5 inline-flex">
      <img :src="icon" :alt="`${type} icon`" class="w-4 h-4" />
    </span>
    <div class="flex flex-col w-full">
      <div v-if="title" class="text-racq-dark-grey text-base-bold">{{ title }}</div>
      <div v-if="description" class="text-racq-dark-grey text-m mt-[2px]" v-html="description" />
      <div v-if="links" class="flex flex-row gap-4 mt-1">
        <a v-for="(link, index) in links" :key="index" :href="link.url" class="text-m" v-html="link.title" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IInformationBox } from '@/interfaces/forms/information-box.interface';
import { computed } from 'vue';

const props = defineProps<IInformationBox>();

const iconMap = {
  info: require('@/assets/icons/info-information.svg'),
  warning: require('@/assets/icons/info-warning.svg'),
  error: require('@/assets/icons/info-error.svg'),
  success: require('@/assets/icons/info-success.svg'),
};

const backgroundColorMap = {
  info: 'bg-racq-info-blue',
  warning: 'bg-racq-info-yellow',
  error: 'bg-racq-info-red',
  success: 'bg-racq-info-green',
};

const icon = computed(() => iconMap[props.type]);
const backgroundColor = computed(() => backgroundColorMap[props.type]);
</script>
