import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex md:flex-row flex-col md:gap-4" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = {
  key: 0,
  class: "text-xs text-racq-error-red mb-0"
}

import { ref, watch, nextTick, onMounted } from 'vue';
import { hideAllPoppersAndPaddings } from '@/plugins/tooltip/renderTooltip';
import { useAnalyticsStore, useFormStore, useFindCarStore } from '@/store';
import vehicleService from '@/services/store/vehicle.service';
import { FormEvents } from '@/enums/form-events.enum';
import { useI18n } from 'vue-i18n';
import InformationBox from '@/components/InformationBox/InformationBox.vue';
import { InformationBoxTypes } from '@/interfaces/forms/information-box.interface';
import { storeToRefs } from 'pinia';

// Define Props & Emitters
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'FindCarByRego',
  props: {
    regoInputLabel: {},
    findCarBtn: {},
    regoNumber: {},
    help: {}
  },
  emits: ['vehicleFound', 'qldVehicleNotFound', 'setRegistration'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;
const formStore = useFormStore();
const analyticsStore = useAnalyticsStore();
const findCarStore = useFindCarStore();
const translation = useI18n();

const { isQldRegoFound } = storeToRefs(findCarStore);

// Vehicle rego can contain only case insensitive alphanumeric characters with the length ranging from 1 to 9 characters)
const regoRegEx = /^[a-zA-Z0-9]{1,9}$/;

// Define Component state
// ------------------------------------------------------------- //
const vehicleRegistration = ref('');
const regoLookupErrorMessage = ref('');
const regoNotFound = ref('');

watch(
  () => {
    return props.regoNumber;
  },
  (newVal, oldVal) => {
    if (newVal && newVal !== oldVal && newVal !== vehicleRegistration.value) {
      vehicleRegistration.value = newVal;
      getVehicleFromRego();
    }
  }
);

watch(
  isQldRegoFound,
  (newValue) => {
    if (newValue === false) {
      const errorMessages: Record<string, string> = translation.getLocaleMessage('en') as any;
      regoNotFound.value = regoLookupErrorMessage.value = errorMessages.REGO_NOTFOUND;
    }
  },
  { immediate: true }
);

onMounted(() => {
  if (props.regoNumber) {
    vehicleRegistration.value = props.regoNumber!;
  }
});

// Define functions
// ------------------------------------------------------------- //
async function getVehicleFromRego() {
  regoLookupErrorMessage.value = '';
  const errorMessages: Record<string, string> = translation.getLocaleMessage('en') as any;
  regoNotFound.value = errorMessages.REGO_NOTFOUND;

  if (!vehicleRegistration.value) {
    analyticsStore.pushAnalytics({
      event: FormEvents.SEARCH_REGISTRATION,
      form: {
        formErrorType: 'form error',
        formError: 'Enter your car registration number',
        formErrorDetail: errorMessages.REGO_EMPTY,
        name: findCarStore.formName,
      },
    });
    regoLookupErrorMessage.value = errorMessages.REGO_EMPTY;
    return;
  }

  /* Make sure rego only contains valid characters */
  if (!vehicleRegistration.value.match(regoRegEx)) {
    analyticsStore.pushAnalytics({
      event: FormEvents.SEARCH_REGISTRATION,
      form: {
        formErrorType: 'form error',
        formError: 'Invalid characters in rego',
        formErrorDetail: errorMessages.REGO_INVALID,
        name: findCarStore.formName,
      },
    });
    regoLookupErrorMessage.value = errorMessages.REGO_INVALID;
    return;
  }

  formStore.showLoadingForCurrentStep();

  try {
    // Set the registration number here, otherwise we lose it if it's not found and we do a manual lookup
    emit('setRegistration', vehicleRegistration.value);
    const vehicleDataFromRego = await vehicleService.regoLookup(vehicleRegistration.value);
    if (!vehicleDataFromRego) {
      regoLookupErrorMessage.value = errorMessages.REGO_NOTFOUND;
      await nextTick();
      analyticsStore.pushAnalytics({
        event: FormEvents.SEARCH_REGISTRATION,
        form: {
          formErrorType: 'form error',
          formError: 'Registration number not found',
          formErrorDetail:
            document.getElementById('find-car-by-rego-error')?.textContent ?? errorMessages.REGO_NOTFOUND,
          name: findCarStore.formName,
        },
      });
      emit('qldVehicleNotFound');
    }

    hideAllPoppersAndPaddings();
    vehicleDataFromRego && emit('vehicleFound', vehicleDataFromRego);
  } catch (e) {
    console.error('Rego lookup failed.', e);
    regoLookupErrorMessage.value = errorMessages.REGO_NOTFOUND;
    emit('qldVehicleNotFound');
  } finally {
    formStore.showLoadingForCurrentStep(false);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FormKit, {
          modelValue: vehicleRegistration.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vehicleRegistration).value = $event)),
          label: _ctx.regoInputLabel,
          inputClass: "racq-w-md racq-max-w-md",
          innerClass: { 'bg-formfield-error': regoLookupErrorMessage.value && regoLookupErrorMessage.value !== regoNotFound.value },
          outerClass: "mb-0",
          validation: [['required'], ['matches', regoRegEx]],
          validationVisibility: "submit",
          maxLength: "9",
          help: _ctx.help,
          onKeyup: _withKeys(getVehicleFromRego, ["enter"])
        }, null, 8, ["modelValue", "label", "innerClass", "validation", "help"]),
        (regoLookupErrorMessage.value && regoLookupErrorMessage.value !== regoNotFound.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(regoLookupErrorMessage.value), 1))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_FormKit, {
        type: "button",
        label: _ctx.findCarBtn,
        inputClass: "btn btn-primary btn-md mt-4 md:mt-0",
        outerClass: "flex items-start md:mt-[28px]",
        onClick: getVehicleFromRego
      }, null, 8, ["label"])
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(InformationBox, {
        id: "find-car-by-rego-error",
        type: _unref(InformationBoxTypes).WARNING,
        description: regoLookupErrorMessage.value
      }, null, 8, ["type", "description"])
    ], 512), [
      [_vShow, regoLookupErrorMessage.value && regoLookupErrorMessage.value === regoNotFound.value]
    ])
  ], 64))
}
}

})