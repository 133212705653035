<template>
  <component :is="formComponent" v-bind="$props" :componentDir="componentDir" />
</template>

<script setup lang="ts">
/**
 * This is the root level component, picked up by Sitecore via SSR.
 *
 * Inside the props is a field called "componentName".
 * This is the name of the form component to render.
 *
 */
import { defineAsyncComponent, computed } from 'vue';
import type { FormProps } from '@/interfaces/forms/form-props.interface';
import { pascalCaseToKebabCase } from '@/utils/data-transforms';

const props = defineProps<FormProps>();
const componentName = computed(() => (props?.rendering?.fields as any).data?.datasource.componentName.value);
const folderPath = computed(() => (props?.rendering?.fields as any).data?.datasource.folderPath?.value ?? '');
const componentDir = pascalCaseToKebabCase(componentName.value);
const formComponent = defineAsyncComponent(
  () => import(`@/forms/${folderPath.value ? folderPath.value + '/' : ''}${componentDir}/${componentName.value}.vue`)
);
</script>
