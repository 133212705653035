import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "rounded shadow border-2 border-racq-pale-blue-border flex flex-col basis-full" }
const _hoisted_2 = {
  key: 0,
  class: "px-8 pt-6 text-xl-bold"
}
const _hoisted_3 = { class: "self-stretch pb-1 flex-col justify-start items-start gap-2 flex" }
const _hoisted_4 = { class: "self-stretch justify-start items-center gap-3 inline-flex" }
const _hoisted_5 = { class: "grow shrink basis-0 text-racq-dark-grey text-lg-bold" }
const _hoisted_6 = { class: "self-stretch flex-col justify-start items-start gap-4 flex" }
const _hoisted_7 = { class: "relative" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 1,
  class: "w-100 text-center mt-5 mb-5"
}

import { ref } from 'vue';
import { IReviewSummary, IReviewSummaryItem } from '@/interfaces';
import ReviewSummaryItem from '@/components/ReviewSummary/ReviewSummaryItem.vue';
import BaseIcon from '@/components/BaseIcon/BaseIcon.vue';
import { useFormStore } from '@/store';
import { Text, RichText, Link } from '@sitecore-jss/sitecore-jss-vue';
import { LinkField } from '@sitecore-jss/sitecore-jss-vue/types/components/Link';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReviewSummary',
  props: {
    reviewSummaries: {},
    isConfirmationStep: { type: Boolean },
    disableExpandToggle: { type: Boolean },
    title: {}
  },
  emits: ["editClicked"],
  setup(__props: any, { emit: __emit }) {

const formStore = useFormStore();
const expanded = ref<boolean>(false);

const props = __props;

// Exposed to track which "edit" link was clicked from the ReviewSummary
const emit = __emit;
function onEditDetails(goToStep: number, reviewSummary: IReviewSummary, reviewSummaryItem: IReviewSummaryItem) {
  emit('editClicked', reviewSummary, reviewSummaryItem);
  formStore.goToStep(goToStep);
}

/**
 * Returns whether the reviewSummaryItemContent contains a slot item
 * @param reviewSummaryItemContent The ReviewSummaryItemContent to check.
 */
function shouldRenderMapSlot(reviewSummaryItemContent: string): boolean {
  if (reviewSummaryItemContent?.includes('#googleMapWithPinStatic')) {
    return true;
  }
  return false;
}

function formatLink(url: string): LinkField {
  return {
    value: {
      href: url,
      target: '_self',
    },
  };
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "policyDiscounts"),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reviewSummaries, (summary, sumIndex) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          key: sumIndex,
          class: _normalizeClass(["self-stretch px-8 pt-8 pb-4 border-racq-pale-blue-border flex-col justify-start items-start gap-4 flex", sumIndex === _ctx.reviewSummaries.length - 1 ? '' : 'border-b'])
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (summary.icon?.value)
                ? (_openBlock(), _createBlock(BaseIcon, {
                    key: 0,
                    iconClass: summary.icon?.value
                  }, null, 8, ["iconClass"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(Text), {
                  field: summary.summaryTitle
                }, null, 8, ["field"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(summary.items, (item, itemIndex) => {
              return (_openBlock(), _createBlock(ReviewSummaryItem, {
                key: itemIndex,
                item: item,
                isLastReviewSummaryItem: itemIndex === summary.items.length - 1
              }, {
                title: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_unref(RichText), {
                      field: item.title,
                      class: "text-racq-dark-grey text-base-bold"
                    }, null, 8, ["field"])
                  ])
                ]),
                content: _withCtx(() => [
                  _createVNode(_unref(RichText), {
                    field: item.content,
                    class: "text-base text-block"
                  }, null, 8, ["field"]),
                  (shouldRenderMapSlot(item.content.value))
                    ? _renderSlot(_ctx.$slots, "googleMapWithPinStatic", { key: 0 })
                    : _createCommentVNode("", true)
                ]),
                edit: _withCtx(() => [
                  (item.editUrl && !_ctx.isConfirmationStep && item.showEdit)
                    ? (_openBlock(), _createBlock(_unref(Link), {
                        key: 0,
                        field: formatLink(item.editUrl),
                        class: "text-base-bold underline"
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("Edit")
                        ])),
                        _: 2
                      }, 1032, ["field"]))
                    : _createCommentVNode("", true),
                  (item.editGoToStep != null && !_ctx.isConfirmationStep && item.showEdit)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        type: "button",
                        class: "link text-base-bold",
                        onClick: ($event: any) => (onEditDetails(item.editGoToStep, summary, item))
                      }, " Edit ", 8, _hoisted_8))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["item", "isLastReviewSummaryItem"]))
            }), 128)),
            _renderSlot(_ctx.$slots, "summaryItemFooter")
          ])
        ], 2)), [
          [_vShow, summary.items.length && (!_ctx.isConfirmationStep || _ctx.disableExpandToggle || sumIndex == 0 || expanded.value)]
        ])
      }), 128))
    ]),
    (!_ctx.isConfirmationStep || expanded.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _renderSlot(_ctx.$slots, "additionalPolicyHolders")
        ]))
      : _createCommentVNode("", true),
    (_ctx.isConfirmationStep && !_ctx.disableExpandToggle && _ctx.reviewSummaries.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_FormKit, {
            type: "button",
            label: expanded.value ? 'Show less' : 'Show more',
            inputClass: "text-racq-navy",
            suffixIcon: expanded.value ? 'up' : 'down',
            onClick: _cache[0] || (_cache[0] = ($event: any) => (expanded.value = !expanded.value))
          }, null, 8, ["label", "suffixIcon"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})