import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/icons/arrow-right.svg'


const _hoisted_1 = { class: "py-3" }
const _hoisted_2 = { class: "grid grid-cols-1 md:grid-cols-2 gap-2 mb-2" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "m-auto float-right" }

import { RichText, Link } from '@sitecore-jss/sitecore-jss-vue';
import { useI18n } from 'vue-i18n';
import SectionFrame from '@/components/SectionFrame/SectionFrame.vue';
import { IFormStep, IQuote } from '@/interfaces';
import { FormAnalyticsBuilder } from '@/utils/form-analytics-builder';
import { FormEvents } from '@/enums/form-events.enum';
import { OrdinalNumbers } from '@/constants/ordinal-numbers.const';

export interface IBenefitsBrands {
  brands: any[];
  benefitsLink: any;
  title: string;
  subTitle: string;
  moreOffersText: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MembershipBenefitsBrands',
  props: {
    brandDetails: {},
    step: {},
    quote: {}
  },
  setup(__props: any) {

const translation = useI18n();

const props = __props;

function pushAnalytics(name: string) {
  const formAnalytics = new FormAnalyticsBuilder(props.quote, FormEvents.CROSS_SELL_CLICK, translation);
  formAnalytics.populateNavigation(name, props.brandDetails?.benefitsLink?.value?.url);
  formAnalytics.pushAnalytics();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(SectionFrame, {
    sectionTitle: _ctx.brandDetails.title,
    class: "bg-racq-anti-flash-white"
  }, {
    sectionFrameContent: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.brandDetails.subTitle), 1),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.brandDetails.brands, (brand, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "grid grid-cols-[15%_75%_10%] md:grid-cols-[20%_70%_10%] rounded border-solid border-racq-pale-blue-border bg-racq-true-white border-2 py-5 px-3 mb-1"
          }, [
            _createElementVNode("img", {
              src: brand.fields.brand.value.src,
              alt: brand.fields.brand.value.alt,
              class: "h-[auto] w-[46px] mt-1"
            }, null, 8, _hoisted_3),
            _createVNode(_unref(RichText), {
              field: { value: brand.fields.benefit.value },
              class: "overflow-ellipsis"
            }, null, 8, ["field"]),
            _createElementVNode("div", _hoisted_4, [
              (_ctx.brandDetails?.benefitsLink)
                ? (_openBlock(), _createBlock(_unref(Link), {
                    key: 0,
                    field: _ctx.brandDetails?.benefitsLink,
                    onClick: () => pushAnalytics(`${_unref(OrdinalNumbers)[index]} link`)
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createElementVNode("img", {
                        src: _imports_0,
                        alt: "Right pointing arrow icon",
                        class: "h-[20px] w-[auto]"
                      }, null, -1)
                    ])),
                    _: 2
                  }, 1032, ["field", "onClick"]))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128))
      ]),
      _createVNode(_unref(Link), {
        field: _ctx.brandDetails?.benefitsLink,
        onClick: _cache[0] || (_cache[0] = () => pushAnalytics(_ctx.brandDetails.moreOffersText))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.brandDetails.moreOffersText), 1)
        ]),
        _: 1
      }, 8, ["field"])
    ]),
    _: 1
  }, 8, ["sectionTitle"]))
}
}

})