import {
  bindQuoteSessionUrl,
  quoteSessionProcessPaymentUrl,
  withdrawQuoteSessionUrl,
} from './../../constants/forms-api.const';
import { RequestServices as _http } from '../common/request.services';
import { IServiceResponse, IServiceResponseNoData } from '@/interfaces/entities';

export interface ProcessPaymentRequest extends PayWithCardRequest {
  interimHeaderComments?: string;
  saveInterimHeader: boolean;
}

export interface PayWithCardRequest {
  cardToken: string;
  amount: number;
  invoiceId?: string;
}

export interface ProcessPaymentResponse {
  receiptNumber: string;
  result: string;
}

export const QuoteSessionService = {
  /**
   * Bind quote change
   *
   * @param quoteSessionId
   * @returns
   */
  bindQuoteSession: (quoteSessionId: string) => {
    return _http.HttpPost(bindQuoteSessionUrl.replace('{0}', quoteSessionId), {});
  },

  /**
   * Withdraw Quote Session
   *
   * @param quoteSessionId
   * @returns
   */
  withdrawQuoteSession: (quoteSessionId: string): Promise<IServiceResponseNoData> => {
    return _http.HttpPost(withdrawQuoteSessionUrl.replace('{0}', quoteSessionId));
  },

  /**
   * Process payment
   *
   * @param quoteSessionId
   * @returns
   */
  processPayment: (
    policyNumber: string,
    quoteSessionId: string,
    data: ProcessPaymentRequest
  ): Promise<IServiceResponse<ProcessPaymentResponse>> => {
    return _http.HttpPost(
      quoteSessionProcessPaymentUrl.replace('{0}', policyNumber).replace('{1}', quoteSessionId),
      data
    );
  },
};
