<template>
  <div>
    <div
      :id="`nudge-disclaimer-${props.stepName ? props.stepName?.replaceAll(' ', '-')?.toLowerCase() : 'quote'}`"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { useFeatureTogglesStore } from '@/store/feature-toggles/featureTogglesStore';
const refFeatureToggles = useFeatureTogglesStore();
import { ref, onMounted, computed, reactive } from 'vue';
import { IFeatureToggle } from '@/interfaces/entities/feature-toggle';
import { getToggle, FeatureToggles } from '@/utils/feature-toggle';
import { prop } from 'vue-class-component';
const featureToggle = ref<IFeatureToggle | null>();
const nudgeDisclaimer = ref<string>();

const props = defineProps<{
  quoteReference: string;
  isQuotePage: boolean;
  stepName?: string;
}>();

onMounted(async () => {
  const featureToggles = await refFeatureToggles.getFeatureToggles();
  featureToggle.value = getToggle(FeatureToggles.DisablePropositions, featureToggles);
});
</script>
