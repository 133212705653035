import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "px-4 pt-5" }
const _hoisted_2 = { class: "p-5 rounded bg-racq-white-background flex flex-wrap md:mb-2" }
const _hoisted_3 = { class: "flex lg:w-4/5" }
const _hoisted_4 = { class: "mr-4" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "text-base mb-4 md:mb-0" }
const _hoisted_7 = ["data-testid", "innerHTML"]
const _hoisted_8 = ["data-testid", "innerHTML"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "tooltip-title" }
const _hoisted_11 = ["innerHTML"]

import { ref, onMounted } from 'vue';
import FormStepFooter from '@/components/FormStepFooter/FormStepFooter.vue';
import { IFormStep } from '@/interfaces';
import { useAnalyticsStore, useFormStore, useJeopardyStore } from '@/store';
import { replaceLoyaltyReviewBookmarks } from '@/forms/insurance/quote/home-and-contents-purchase/mappings/loyalty-review-mapping';
import useFormInputData from '@/composables/useFormInputData';
import { displayLoyaltyReview, loyaltyReviewContent } from '@/utils/display-rules/loyalty-rules';
import { IQuote } from '@/interfaces';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { FormEvents } from '@/enums/form-events.enum';
import ModalDialog from '../ModalDialog/ModalDialog.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MembershipLoyaltyReview',
  props: {
    step: {},
    quote: {},
    originalQuote: {}
  },
  setup(__props: any) {

const formStore = useFormStore();
const jpStore = useJeopardyStore();
const analyticsStore = useAnalyticsStore();

const props = __props;

const loyaltyLevelResponse = ref<any>();
const heading = ref<string>('');
const loyaltyLevelText = ref<string>('');
const discountText = ref<string>('');
const newQuoteCostText = ref<string>('');
const helpText = ref<string>('');

const quoteChangedModalData = ref<any>();

const showWhyQuoteChangedModal = ref<boolean>(false);

const accountInfoBoxSettings = ref<Record<string, any>>({
  icon: require('@/assets/img/icons/user-icon.svg'),
  unauthenticatedButtonText: '',
  unauthenticatedGreetings: heading,
  params: {
    authenticated: false,
    firstName: '',
  },
});

onMounted(() => {
  if (!props.quote || !props.quote.quoteType) return;

  quoteChangedModalData.value = props.step.formInputs?.modalWhyHasMyQuoteChanged?.datasource?.fields;

  const { formInputData } = useFormInputData(props.step.formInputs!);

  loyaltyLevelResponse.value = displayLoyaltyReview(props.quote as IQuote, props.originalQuote as IQuote);

  heading.value = replaceLoyaltyReviewBookmarks(
    formInputData(loyaltyLevelResponse.value.useHeading).label,
    props.quote
  );

  loyaltyLevelText.value = replaceLoyaltyReviewBookmarks(
    formInputData(loyaltyLevelResponse.value.useLoyaltyText).label,
    props.quote
  );

  /*
  In sitecore 8 this is only used for Car Hire it seems. litDiscount.Text = string.Format(eligibleForFreeCarHire ? CurrentItem.GetFieldValue("RVMEntitlementWithHireCar_Text") : CurrentItem.GetFieldValue("RVMEntitlementNoHireCar_Text"), discount);
  A content field called textDiscount will need to be added to any step needing this.
  */
  discountText.value = replaceLoyaltyReviewBookmarks(
    formInputData(loyaltyReviewContent.textDiscount).label,
    props.quote
  );

  newQuoteCostText.value = replaceLoyaltyReviewBookmarks(
    formInputData(loyaltyReviewContent.textNewQuoteCost).label,
    props.quote
  );

  helpText.value = replaceLoyaltyReviewBookmarks(
    formInputData(loyaltyLevelResponse.value.useHelpText).label,
    props.quote
  );

  analyticsStore.pushAnalytics({
    event: FormEvents.START,
    form: {},
  });
});

async function onSubmit() {
  try {
    formStore.showLoadingForCurrentStep();
    formStore.showLoadingForCurrentStep(false);
    formStore.goNextStep();
  } catch (jPCode: any) {
    formStore.showLoadingForCurrentStep(false);
    showJeopardyPanel(jPCode);
  }
}

function showJeopardyPanel(jpCode: string) {
  jpStore.showJeopardyPanel({
    jpCode: jpCode,
  });
}

function onModalClose(e: any) {
  showWhyQuoteChangedModal.value = e;
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createBlock(_component_FormKit, {
    type: "form",
    actions: false,
    incompleteMessage: false,
    onSubmit: onSubmit
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                src: accountInfoBoxSettings.value.icon,
                alt: "user profile icon",
                role: "img",
                class: "min-w-12 w-12 min-h-12 h-12"
              }, null, 8, _hoisted_5)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", {
                "data-testid": loyaltyLevelResponse.value?.useHeading,
                innerHTML: heading.value
              }, null, 8, _hoisted_7),
              (loyaltyLevelResponse.value)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "input-spacing text-base contents",
                    "data-testid": loyaltyLevelResponse.value?.useLoyaltyText,
                    innerHTML: loyaltyLevelText.value
                  }, null, 8, _hoisted_8))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        (loyaltyLevelResponse.value)
          ? (_openBlock(), _createBlock(_unref(RichText), {
              key: 0,
              class: "input-spacing text-lg pt-[24px]",
              field: { value: newQuoteCostText.value },
              "data-testid": loyaltyLevelResponse.value?.useNewQuoteCostText
            }, null, 8, ["field", "data-testid"]))
          : _createCommentVNode("", true),
        (loyaltyLevelResponse.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("button", {
                type: "button",
                class: "link text-m",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (showWhyQuoteChangedModal.value = true))
              }, _toDisplayString(quoteChangedModalData.value?.modalTriggerLinkText?.value), 1)
            ]))
          : _createCommentVNode("", true),
        (loyaltyLevelResponse.value && discountText.value)
          ? (_openBlock(), _createBlock(_unref(RichText), {
              key: 2,
              class: "input-spacing text-lg",
              field: { value: discountText.value },
              "data-testid": loyaltyLevelResponse.value?.discountText
            }, null, 8, ["field", "data-testid"]))
          : _createCommentVNode("", true),
        (loyaltyLevelResponse.value)
          ? (_openBlock(), _createBlock(_unref(RichText), {
              key: 3,
              class: "input-spacing text-sm",
              field: { value: helpText.value },
              "data-testid": loyaltyLevelResponse.value?.useHelpText
            }, null, 8, ["field", "data-testid"]))
          : _createCommentVNode("", true),
        _createVNode(ModalDialog, {
          modelValue: showWhyQuoteChangedModal.value,
          showXCloseButton: true,
          closeOnEsc: false,
          dialogClass: "tooltip-dialog",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => onModalClose(value))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_10, _toDisplayString(quoteChangedModalData.value?.modalTitle?.value), 1),
            _createElementVNode("div", {
              class: "tooltip-body richtext",
              innerHTML: quoteChangedModalData.value?.modalBody?.value
            }, null, 8, _hoisted_11)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createVNode(FormStepFooter, {
        step: _ctx.step,
        btnNextText: _ctx.step.btnNext,
        btnPrevText: _ctx.step.btnPrev
      }, null, 8, ["step", "btnNextText", "btnPrevText"])
    ]),
    _: 1
  }))
}
}

})