import { createInput } from '@formkit/vue';

export const routerLinkButton = createInput(
  {
    $cmp: 'RouterLink',
    props: {
      class: '$classes.input',
    },
    children: '$text',
    bind: '$attrs',
  },
  {
    family: 'button',
    props: ['text'],
  }
);

export const linkButton = createInput(
  {
    $el: 'a',
    props: {
      class: '$classes.input',
    },
    children: '$text',
    bind: '$attrs',
  },
  {
    family: 'button',
    props: ['text', 'inputClass'],
  }
);
