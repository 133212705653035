<template>
  <div class="rbc-item flex flex-col w-full">
    <input
      v-model="internalValue"
      type="radio"
      :name="groupName"
      :value="value"
      :aria-label="leftTitle"
      class="hidden-radio hidden"
      @change="updateValue"
    />
    <div v-if="!!callOutTag" class="rbc-header flex justify-start items-center h-6 bg-racq-blue rounded-t">
      <span class="rbc-header-image">
        <slot>
          <!-- Add your image here -->
          <img src="@/assets/img/icons/Star.svg" alt="star icon" class="mx-1 py-1" />
        </slot>
      </span>
      <span class="rbc-tag-title text-xs-bold text-racq-true-white uppercase py-1">{{ callOutTag }}</span>
    </div>
    <div
      :class="[
        'rbc-inner-container align-middle cursor-pointer overflow-hidden h-full',
        valuesAreEqual() ? 'bg-racq-anti-flash-white border-racq-blue-active border-2' : 'border-racq-light-blue-grey',
        !!callOutTag ? 'border border-t-0 rounded-b' : 'border rounded',
        outerClass,
      ]"
      @click.stop="handleClick"
    >
      <div class="rbc-body flex flex-col h-full" :class="innerClass">
        <div class="rbc-body-top flex rounded h-full">
          <div v-if="!!bodyImage" class="rbc-body-top-left flex items-center justify-between">
            <span class="rbc-body-image flex items-center w-[72px] object-cover self-stretch">
              <!-- 72x72 image -->
              <img :src="bodyImage" :alt="bodyImageAlt" />
            </span>
          </div>
          <div
            :class="[
              'rbc-body-top-right flex gap-3 w-full items-center text-racq-dark-grey',
              !!bodyImage && !bodyIcon ? 'px-3' : 'p-4',
              { 'bg-racq-anti-flash-white': valuesAreEqual() },
              bodyTopClass,
            ]"
          >
            <span
              v-if="!!bodyIcon"
              class="rbc-body-icon flex items-center justify-center pl-px pt-px pr-0.5 pb-0.5 w-[40px] h-[40px] object-cover"
            >
              <img :src="bodyIcon" :alt="bodyIconAlt" />
            </span>
            <div class="rbc-left-section flex flex-col justify-center">
              <div class="rbc-ls-top-row flex gap-3">
                <div v-if="showLeftRadioButton" class="rbc-left-radio flex items-center self-stretch gap-[10px]">
                  <span :class="['radio-indicator', { 'checked-indicator': valuesAreEqual() }]"></span>
                </div>
                <div
                  class="rbc-ls-title text-lg-bold flex flex-col justify-center items-start self-stretch"
                  :class="leftTitleClass"
                >
                  {{ leftTitle }}
                </div>
              </div>
              <div class="rbc-ls-bottom-row flex gap-3">
                <div v-if="showLeftRadioButton" class="rbc-ls-br-placeholder w-[20px]"></div>
                <div
                  v-if="!!leftDescription"
                  class="rbc-ls-description text-sm flex flex-col pb-1 items-start self-stretch"
                >
                  <RichText :field="{ value: leftDescription }" />
                </div>
              </div>
              <div v-if="showBottomTag" class="flex gap-3">
                <slot name="bottomContent"> </slot>
              </div>
            </div>
            <div class="rbc-right-section flex flex-col self-start text-right ml-auto">
              <div v-if="!!rightTitle || showRightRadioButton" class="rbc-rs-top-row flex gap-3 justify-between">
                <div
                  v-if="!!rightTitle"
                  class="rbc-rs-title text-xl-bold flex flex-col justify-center items-end self-stretch gap-[10px] flex-1"
                >
                  {{ rightTitle }}
                </div>
                <div
                  v-if="showRightRadioButton"
                  class="rbc-right-radio flex items-center self-stretch gap-[10px] flex-1 justify-end max-w-fit"
                >
                  <span :class="['radio-indicator', { 'checked-indicator': valuesAreEqual() }]"></span>
                </div>
              </div>
              <div v-if="!!rightDescription" class="rbc-rs-bottom-row flex gap-3">
                <div
                  v-if="!!rightDescription"
                  class="rbc-rs-description text-xs flex flex-col pb-1 items-end self-stretch gap-[10px] grow"
                >
                  {{ rightDescription }}
                </div>
                <div class="rbc-rs-br-placeholder w-[20px] grow"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!!visibleBodyDescription || !!hiddenBodyDescription"
          class="rbc-body-bottom flex flex-col gap-2 pl-4 pr-8 pb-3 text-dark-grey-text"
          :class="bodyBottomClass"
        >
          <div
            :class="[
              'rbc-bb-body flex flex-col pb-2 gap-[2px] overflow-hidden [transition:max-height_0.5_ease]',
              { collapsed: !isExpanded },
            ]"
          >
            <RichText
              v-if="!!visibleBodyDescription"
              :field="{ value: visibleBodyDescription }"
              class="flex flex-col"
            />
            <RichText
              v-if="!!hiddenBodyDescription && isExpanded"
              :field="{ value: hiddenBodyDescription }"
              class="flex flex-col"
            />
          </div>
          <button
            v-if="!!hiddenBodyDescription"
            type="button"
            class="toggle-button flex items-center bg-none border-[none] cursor-pointer text-base-bold text-racq-blue w-fit"
            @click.stop.prevent="toggleContent"
          >
            {{ isExpanded ? showLessLinkLabel : showMoreLinkLabel }}
            <img
              :src="chevronDown"
              alt="chevron icon"
              :class="['w-[12px] h-[7px] ml-2 [transition:transform_0.5s_ease]', { flipped: isExpanded }]"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { IRadioButtonCard } from '@/interfaces/entities/radio-button-card.interface';
import chevronDown from '@/assets/icons/blue-chevron-down.svg';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

const props = defineProps<IRadioButtonCard>();
const emit = defineEmits(['update:modelValue']);
// Create an internal state that mirrors the prop
const internalValue = ref(props.modelValue);
const isExpanded = ref(false);

const toggleContent = () => {
  isExpanded.value = !isExpanded.value;
};

// Emit the updated value to the parent when radio changes
const updateValue = () => {
  emit('update:modelValue', internalValue.value);
};

// Make the whole div clickable and focus the radio input
const handleClick = () => {
  if (internalValue.value !== props.value) {
    internalValue.value = props.value;
    emit('update:modelValue', internalValue.value);
  }
};

const valuesAreEqual = () => {
  return _.isEqual(props.value, internalValue.value);
};

// Watch for changes to modelValue and update internalValue accordingly
watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue;
  }
);
</script>
<!-- external styles defined under radio-button-card.css -->
