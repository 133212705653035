export enum FormEvents {
  START = 'form.start',
  STEP = 'form.step',
  COMPLETE = 'form.complete',
  JEOPARDY = 'form.jeopardy',
  RESPONSE = 'form.response',
  REQUEST = 'form.request',
  SEARCH_REGISTRATION = 'search.registration',
  PAYMENT_ATTEMPT = 'payAttempt',
  CROSS_SELL_CLICK = 'crossSellClick',
  CHAT_STARTED = 'form.chatstarted',
  FORM_SUBMIT = 'form.submit',
}
