import { defineStore } from 'pinia';
import { MakeAPaymentService } from '@/services/store/make-a-payment.service';
import { ref } from 'vue';
import {
  IApplyAssistanceQuoteDiscountRequest,
  IApplyAssistanceQuoteDiscountResponse,
  IPayWithCardResponse,
  IPaymentReferenceDetails,
  IProcessPaymentRequest,
  IQuotedOption,
  IReceiptEmailResponse,
  IReceiptRequest,
  ISuccessResponse,
} from '@/interfaces/make-a-payment/make-a-payment.interface';
import { ICardDetails, IOffer } from '@/interfaces';
import { mapDirectDebitRequest } from '@/forms/make-a-payment/mappings/make-a-payment-mappings';

export const useMakeAPaymentStore = defineStore('MakeAPayment', () => {
  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //
  const paymentReferenceDetails = ref<IPaymentReferenceDetails>({} as IPaymentReferenceDetails);
  const paymentResponse = ref<IPayWithCardResponse>();
  const selectedQuotedOption = ref<IQuotedOption>();
  const resetTable = ref<number>(1);

  const setSelectedQuoteOption = (quote: IQuotedOption) => {
    selectedQuotedOption.value = quote;
  };
  /**
   * Get prn details
   * @param prn payment reference number
   * @returns
   */
  const getPrnDetails = async (prn: string) => {
    const response = await MakeAPaymentService.getPrnDetails(prn);
    paymentReferenceDetails.value.prn = prn;
    response.isInurance = !(response.renewalOptions && response.renewalOptions?.length > 0);
    response.prn = prn;
    paymentReferenceDetails.value = response;
  };

  /**
   * Process payment for prn
   * @param paymentRequest payment request
   * @returns
   */
  const processPrnPayment = async (paymentRequest: IProcessPaymentRequest): Promise<IPayWithCardResponse> => {
    const response = await MakeAPaymentService.processPayment(paymentRequest);
    paymentResponse.value = response;
    return response;
  };

  /**
   * Send receipt email to user
   * @param receiptRequest receipt request for sending email
   * @returns
   */
  const sendReceiptEmail = async (receiptRequest: IReceiptRequest): Promise<IReceiptEmailResponse> => {
    return await MakeAPaymentService.sendReceiptEmail(receiptRequest);
  };

  /**
   * apply discount to quote
   * @param discountRequest quote discount request
   * @returns
   */
  const applyDiscountToQuote = async (
    discountRequest: IApplyAssistanceQuoteDiscountRequest
  ): Promise<IApplyAssistanceQuoteDiscountResponse> => {
    return await MakeAPaymentService.applyDiscountToQuote(discountRequest);
  };

  /**
   * Sort upgrade options based on DI preference
   * @param rsaQuoteOptions
   */
  const sortRenewalOptionsBasedOnOffer = (rsaQuoteOptions?: IOffer[]) => {
    if (rsaQuoteOptions?.length) {
      paymentReferenceDetails.value.renewalOptions.sort((a: IQuotedOption, b: IQuotedOption) => {
        if (a.productCode == paymentReferenceDetails.value.renewalOptions[0].productCode) return -1; //do not sort the current 1st product as it will need to remain at 1
        if (b.productCode == paymentReferenceDetails.value.renewalOptions[0].productCode) return 1;
        return (
          (rsaQuoteOptions.find((x) => x.data.quote.productCode == a.productCode)?.rank ?? 10) - //choosing higher number rank so that ones without rank are always sorted at the end
          (rsaQuoteOptions.find((x) => x.data.quote.productCode == b.productCode)?.rank ?? 10)
        );
      });
    }
  };

  /**
   * Add direct debit to asset line item
   * @param cardDetails card details
   * @returns
   */
  const addDirectDebitToPrn = async (cardDetails: ICardDetails): Promise<ISuccessResponse> => {
    return await MakeAPaymentService.addDirectDebitToPrn(
      paymentReferenceDetails.value.apttusId,
      mapDirectDebitRequest(paymentReferenceDetails.value, cardDetails)
    );
  };

  return {
    paymentReferenceDetails,
    selectedQuotedOption,
    getPrnDetails,
    processPrnPayment,
    paymentResponse,
    sendReceiptEmail,
    setSelectedQuoteOption,
    applyDiscountToQuote,
    sortRenewalOptionsBasedOnOffer,
    addDirectDebitToPrn,
    resetTable,
  };
});
