import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { TagTypes } from '@/enums/tag-enums';


export default /*@__PURE__*/_defineComponent({
  __name: 'Tag',
  props: {
    tagText: {},
    tagType: {},
    outerClass: {}
  },
  setup(__props: any) {

const props = __props;

const getTypeClasses = () => {
  switch (props.tagType) {
    case TagTypes.discount:
      return 'discount-tag';

    default:
      return '';
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass([
      'flex items-center text-xs text-racq-dark-grey bg-racq-info-blue/10 rounded-lg border-none py-[5px] pl-1.5 pr-2',
      getTypeClasses(),
      _ctx.outerClass ?? '',
    ])
  }, _toDisplayString(_ctx.tagText), 3))
}
}

})