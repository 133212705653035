import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "checked", "value", "name"]
const _hoisted_2 = ["for"]


export default /*@__PURE__*/_defineComponent({
  __name: 'NativeRadio',
  props: {
  context: Object,
},
  setup(__props) {

const props = __props;

function emitChange(event: any) {
  props.context?.node.input(event?.target?.value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.context?.options, (option, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: index,
      class: _normalizeClass(['flex items-center mt-3', index < __props.context?.options.length - 1 ? 'mb-3' : ''])
    }, [
      _createElementVNode("input", {
        id: __props.context?.id + index,
        type: "radio",
        checked: option.value == __props.context?.value,
        value: option.value,
        name: __props.context?.id,
        class: "w-5 h-5",
        onInput: emitChange
      }, null, 40, _hoisted_1),
      _createElementVNode("label", {
        for: __props.context?.id + index,
        class: "ms-3 text-base text-racq-dark-grey"
      }, _toDisplayString(option.label), 9, _hoisted_2)
    ], 2))
  }), 128))
}
}

})