import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col md:flex-row gap-1 md:gap-4 bg-white md:rounded px-6 md:px-5 pt-2 md:pt-0 pb-5 md:pb-0 justify-center md:justify-start items-center mt-12 md:mt-14" }
const _hoisted_2 = { class: "text-center md:text-left pt-1 pb-3 md:py-6" }
const _hoisted_3 = { class: "text-lg-bold text-dark-grey-text" }
const _hoisted_4 = { class: "text-base text-dark-grey-text" }
const _hoisted_5 = { class: "flex gap-1 md:ml-auto" }

import { Field, RichText, Text, Image } from '@sitecore-jss/sitecore-jss-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'IQAwardBanner',
  props: {
    fields: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Image), {
      media: _ctx.fields.PrefixIcon
    }, null, 8, ["media"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Text), {
          field: _ctx.fields.Title
        }, null, 8, ["field"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_unref(RichText), {
          field: _ctx.fields.Content
        }, null, 8, ["field"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_unref(Image), {
        media: _ctx.fields.SuffixIconStart,
        class: "w-16 h-16"
      }, null, 8, ["media"]),
      _createVNode(_unref(Image), {
        media: _ctx.fields.SuffixIconEnd,
        class: "w-16 h-16"
      }, null, 8, ["media"])
    ])
  ]))
}
}

})