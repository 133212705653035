<template>
  <div :id="bindings.id + '__outer'" :class="bindings.outerClass" class="md:width-sm w-full">
    <FormKit
      v-bind="bindings"
      v-model="dateValue"
      type="text"
      :innerClass="bindings.innerClass || 'input-sm'"
      :validation="validation"
      oninput="window.RACQLintDateInput(this)"
      maxlength="10"
      :validationRules="{ dateValidator }"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { parse, isValid, addDays, compareAsc, isSameDay } from 'date-fns';
import { flatMap } from 'lodash-es';

const dateValue = defineModel<string>({ required: false, default: '' });

const props = defineProps<{
  bindings: any;
  maxDateByDays?: number;
  minDateToday?: boolean;
  minDate?: string;
}>();

const validation = computed(() => {
  if (flatMap(props.bindings.validation || []).includes('dateValidator')) return props.bindings.validation;
  return [...(props.bindings.validation || []), [dateValidator]];
});

function onInput(target: any) {
  if (!target?.value || typeof target.value != 'string') return;
  target.value = target.value.replace(/[^0-9/]/g, '');

  // e.g 4 => 04/
  if (target.value.length == 1 && Number(target.value) > 3) {
    target.value = `0${target.value}/`;
  }
  // e.g 2/ => 02/
  if (target.value.length == 2 && target.value.includes('/')) {
    target.value = '0' + target.value;
  }
  // e.g 020 => 02/0
  if (target.value.length == 3 && !target.value.includes('/')) {
    target.value = target.value.slice(0, 2) + '/' + target.value[2];
  }
  // e.g 02/4 => 02/04/
  if (target.value.length == 4) {
    const month = Number(target.value.split('/')[1]);
    if (month > 1) target.value = target.value.split('/')[0] + '/' + `0${month}/`;
  }
  // e.g 02/1/ => 02/01/
  if (target.value.length == 5 && target.value.split('/').length == 3) {
    target.value = target.value.slice(0, 3) + '0' + target.value.slice(3, 5);
  }
  // e.g 02/032 => 02/03/2
  if (target.value.length == 6 && target.value.split('/').length != 3) {
    target.value = target.value.slice(0, 5) + '/' + target.value[5];
  }
}

// Pass to native
(window as any).RACQLintDateInput = onInput;

function dateValidator(node: any) {
  try {
    const date = parse(node.value, 'dd/MM/yyyy', new Date());

    if (!isValid(date) || node.value.length != 10) return false;

    // Validate for min date
    if (!validateMinDate(date)) return false;

    // Validate for max date
    if (!validateMaxDate(date)) return false;

    return true;
  } catch {
    return false;
  }
}

function validateMinDate(date: Date) {
  if (props.minDateToday) {
    const compareResult = compareAsc(date, new Date());
    if (!isSameDay(date, new Date()) && compareResult == -1) return false;
  }
  if (props.minDate) {
    const compareResult = compareAsc(date, new Date(props.minDate));
    if (compareResult == -1) return false;
  }
  return true;
}

function validateMaxDate(date: Date) {
  if (typeof props.maxDateByDays == 'number') {
    const maxDate = addDays(new Date(), props.maxDateByDays);
    const compareResult = compareAsc(date, maxDate);
    if (compareResult == 1) return false;
  }
  return true;
}
</script>
