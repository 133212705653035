import { ref, computed, watch } from 'vue';
import { defineStore } from 'pinia';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { IFormStep } from '@/interfaces';
import { JP_EXCEPTION } from '@/constants/jeopardy-codes.const';
import { useFormStore, useAnalyticsStore } from '@/store';
import { FormEvents } from '@/enums/form-events.enum';
import jpCodeQueryGraphQL from './jeopardy-code.query.graphql';
import jpPanelQueryGraphQL from './jeopardy-panel.query.graphql';
import { Field } from '@sitecore-jss/sitecore-jss-vue';
import GraphQLClientFactory from '@/lib/GraphQLClientFactory';
import config from '@/temp/config';
import { IFormAnalytics } from '@/interfaces/forms/analytics-interface';

interface JPPanelQueryResult {
  item: {
    id: string;
    name: string;
    jeopardyPanelCode: {
      value: string;
    };
    liveChatHeading: {
      value: string;
    };
    liveChatContent: {
      value: string;
    };
    liveChatButtonText: {
      value: string;
    };
    callUsHeading: {
      value: string;
    };
    callUsContent: {
      value: string;
    };
    callUsNumber: {
      value: string;
    };
    callUsButtonLabel: {
      value: string;
    };
    callBackHeading: {
      value: string;
    };
    callBackContent: {
      value: string;
    };
    callBackButtonText: {
      value: string;
    };
    callBackFormLink: {
      value: string;
    };
  };
}

export const useJeopardyStore = defineStore('FormJeopardy', () => {
  const formStore = useFormStore();
  const analyticsStore = useAnalyticsStore();

  const step = ref<IFormStep>({
    stepNumber: -1,
    stepName: 'Jeopardy',
    stepTitle: '',
  });
  const showJeopardy = ref(false);
  const showDynamic = ref(false);
  const isChatEnabled = ref(false);
  const isChatLoading = ref(false);
  const isCallBackVisible = ref(false);
  const isCallUsVisible = ref(true);
  const jpCode = ref(JP_EXCEPTION);
  const additionalComments = ref();
  const cancellationReasons = ref();
  const backBtnDisabled = ref(false);
  const backBtnCallback = ref<() => void>();
  const backBtnText = ref('Back');
  const jpTextSuffix = ref<string>('');
  const jpDynamicPanelResult = ref<JPPanelQueryResult | null>(null);
  const jpDefaultPanelResult = ref<JPPanelQueryResult | null>(null);

  const jpCodeQueryGraphQLPath = computed(
    () => '/sitecore/content/RACQFormsGroup/RACQForms20/Data/Jeopardy/Jeopardy Codes/' + jpCode.value
  );
  const { result: jpCodeQueryResult, loading: jpCodeQueryLoading } = useQuery(jpCodeQueryGraphQL, {
    path: jpCodeQueryGraphQLPath,
  });

  const jpText = computed(() => (jpCodeQueryResult.value?.item?.jpText?.value ?? '') + jpTextSuffix.value);
  const jpTextLoaded = computed(() => !jpCodeQueryLoading.value);
  const jpStepTitle = computed(() => jpCodeQueryResult.value?.item?.jpStepTitle?.value as string);
  const jpHeading = computed(() => jpCodeQueryResult.value?.item?.jpHeading?.value as string);

  watch(jpStepTitle, (newValue) => {
    if (newValue) {
      step.value.stepTitle = newValue;
    }
  });

  const jpPanelQueryLoading = ref(true);
  const fetchDynamic = async (jeopardyPanelName: string): Promise<void> => {
    provideApolloClient(GraphQLClientFactory(config.graphQLEndpoint, true));

    const jpPanelQueryGraphQLPath = computed(
      () => '/sitecore/content/RACQFormsGroup/RACQForms20/Data/Jeopardy/Jeopardy Panels/' + jeopardyPanelName
    );
    const { result: jpPanelQueryResult, loading: jpPanelQueryLoadingTemp } = useQuery(jpPanelQueryGraphQL, {
      path: jpPanelQueryGraphQLPath,
    });

    // Watch for changes in the query result and update our ref
    watch(jpPanelQueryResult, (newValue) => {
      jpDynamicPanelResult.value = newValue;
      jpPanelQueryLoading.value = jpPanelQueryLoadingTemp.value;
    });

    // Returns when loading is done
    return new Promise((resolve) => {
      watch(
        jpPanelQueryLoading,
        (newValue) => {
          if (!newValue) {
            resolve();
          }
        },
        { immediate: true }
      );
    });
  };

  watch(jpText, (newValue) => {
    // Jp code is not null AND show jp AND jp details it not null AND jp details not equal to suffix (avoid duplicate analytics)
    if (jpCode.value && showJeopardy.value && newValue && newValue != jpTextSuffix.value) {
      const model: IFormAnalytics = {
        event: FormEvents.JEOPARDY,
        form: {
          formErrorType: jpCode.value,
          formErrorDetail: newValue,
        },
      };
      if (formStore.formName) {
        model.form.name = formStore.formName;
      }
      analyticsStore.pushAnalytics(model);
    }
  });

  /**
   * Run callback for cancel
   */
  const btnBackClick = () => {
    if (backBtnCallback.value) backBtnCallback.value();
    else {
      showJeopardy.value = false;
      formStore.unMaskActiveStep();
    }
  };

  /**
   * Set Chat Loading
   * @param value
   */
  const setChatLoading = (value: boolean) => {
    isChatLoading.value = value;
  };

  /**
   * Set Chat Enabled
   * @param value
   */
  const setChatEnabled = (value: boolean) => {
    isChatEnabled.value = value;
    isChatLoading.value = false;
  };

  /**
   * Hide Jeopardy Panel
   * @param value
   */
  const hideJeopardyPanel = () => {
    showJeopardy.value = false;
  };

  /**
   * Show JP Panel
   * @param options
   */
  const showJeopardyPanel = (options: {
    jpCode: string;
    isChatEnabled?: boolean;
    isCallBackVisible?: boolean;
    isCallUsVisible?: boolean;
    backBtnDisabled?: boolean;
    backBtnText?: string;
    backBtnCallback?: () => void;
    areNextStepsVisible?: boolean;
    dynamicJeopardyPanelName?: string; // If this param is passed then the method MUST be treated as async
    textSuffix?: string;
  }): Promise<void> | void => {
    // initialise common jeopardy config values
    const updateJeopardyConfig = () => {
      if (options.jpCode?.length && options.jpCode.startsWith('JP')) jpCode.value = options.jpCode;
      if (options.isChatEnabled !== undefined) isChatEnabled.value = options.isChatEnabled;
      if (options.isCallBackVisible !== undefined) isCallBackVisible.value = options.isCallBackVisible;
      if (options.isCallUsVisible !== undefined) isCallUsVisible.value = options.isCallUsVisible;
      if (options.backBtnDisabled !== undefined) backBtnDisabled.value = options.backBtnDisabled;
      if (options.backBtnDisabled) formStore.makeStepperVisible(false); // Stepper should be hidden when back button is disabled.
      if (options.backBtnText !== undefined) backBtnText.value = options.backBtnText;
      if (options.backBtnCallback !== undefined) backBtnCallback.value = options.backBtnCallback;
      if (options.areNextStepsVisible !== undefined)
        options.areNextStepsVisible ? formStore.showNextSteps() : formStore.hideNextSteps();
      if (options.textSuffix !== undefined) jpTextSuffix.value = options.textSuffix;

      formStore.maskActiveStep();
    };

    if (!options.dynamicJeopardyPanelName) {
      showJeopardy.value = true;
      showDynamic.value = false;
      updateJeopardyConfig();
    } else {
      // Method is ASYNC
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve) => {
        showDynamic.value = true;
        await setDynamicJeopardyPanel(options.dynamicJeopardyPanelName!);
        showJeopardy.value = true;
        updateJeopardyConfig();
        resolve();
      });
    }
  };

  const setDynamicJeopardyPanel = async (panelName: string) => {
    await fetchDynamic(panelName);
  };

  const setDefaultJeopardyPanel = (fields: Record<string, Field<any>>) => {
    jpDefaultPanelResult.value = {
      item: {
        id: '',
        name: '',
        jeopardyPanelCode: { value: fields.jeopardyPanelCode.value },
        liveChatHeading: { value: fields.liveChatHeading.value },
        liveChatContent: { value: fields.liveChatContent.value },
        liveChatButtonText: { value: fields.liveChatButtonText.value },
        callUsHeading: { value: fields.callUsHeading.value },
        callUsContent: { value: fields.callUsContent.value },
        callUsButtonLabel: { value: fields.callUsButtonLabel.value },
        callUsNumber: { value: fields.callUsNumber.value },
        callBackHeading: { value: fields.callBackHeading.value },
        callBackContent: { value: fields.callBackContent.value },
        callBackButtonText: { value: fields.callBackButtonText.value },
        callBackFormLink: { value: fields.callBackFormLink.value },
      },
    };
  };

  return {
    step,
    showJeopardy,
    showDynamic,
    isChatEnabled,
    isChatLoading,
    isCallBackVisible,
    isCallUsVisible,
    jpDynamicPanelResult,
    jpDefaultPanelResult,
    jpCode,
    jpText,
    jpTextLoaded,
    jpTextSuffix,
    jpHeading,
    jpStepTitle,
    additionalComments,
    cancellationReasons,
    backBtnDisabled,
    backBtnCallback,
    backBtnText,
    btnBackClick,
    setChatLoading,
    setChatEnabled,
    hideJeopardyPanel,
    showJeopardyPanel,
    setDynamicJeopardyPanel,
    setDefaultJeopardyPanel,
  };
});
