<template>
  <div class="">
    <div
      class="grow shrink self-stretch p-5 md:px-6 md:py-5 rounded bg-racq-anti-flash-white justify-start items-start gap-4 flex flex-col md:flex-row"
    >
      <div class="grow shrink justify-start items-start gap-4 md:gap-5 flex">
        <Image :media="fields.icon" class="w-12 h-12" />
        <div class="grow shrink flex-col justify-start items-start inline-flex">
          <RichText :field="{ value: greetingsWithoutPlaceholder }" />
        </div>
      </div>
      <FormKit
        v-if="fields.unauthenticatedButtonText?.value && !authenticated"
        type="button"
        :label="fields.unauthenticatedButtonText.value"
        inputClass="btn btn-sm btn-secondary w-full md:w-max"
        outerClass="w-full md:w-max"
        @click="loginButtonClick"
      />
      <FormKit
        v-else-if="authenticated && accountStore.prefillForm && fields.clearPrefillDetailsText?.value"
        type="button"
        :label="fields.clearPrefillDetailsText.value"
        inputClass="btn btn-sm btn-secondary w-full md:w-max"
        outerClass="w-full md:w-max"
        @click="togglePrefillDetailsButtonClick"
      />
      <FormKit
        v-else-if="authenticated && !accountStore.prefillForm && fields.restorePrefillDetailsText?.value"
        type="button"
        :label="fields.restorePrefillDetailsText.value"
        inputClass="btn btn-sm btn-secondary w-full md:w-max"
        outerClass="w-full md:w-max"
        @click="togglePrefillDetailsButtonClick"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useAccountStore } from '@/store/account/account.state';
import { replaceBookmark } from '@/utils/bookmark.replace';
import { Field, RichText, Image } from '@sitecore-jss/sitecore-jss-vue';

const accountStore = useAccountStore();
const route = useRoute();
const props = defineProps<{
  fields: Record<string, Field<any>>;
  testParams?: {
    authenticated: boolean;
    firstName?: string;
  };
}>();

const authenticated = ref(false);
const greetingsWithoutPlaceholder = ref('');

// props.testParams is only populated via storybook to set initial state
if (props.testParams) {
  authenticated.value = props.testParams.authenticated;
  accountStore.prefillForm = props.testParams.authenticated;
  accountStore.setIdentityDetailsFirstName(props.testParams.firstName ?? '');
}

// Lifecycle hooks
// ------------------------------------------------------------- //
onMounted(async () => {
  try {
    if (!props.testParams) {
      const loggedIn = await accountStore.isAuthenticated;
      if (loggedIn) {
        await accountStore.fetchIdentityDetails();
      }
      authenticated.value = loggedIn || accountStore.partyMatched;
    }
  } catch (error) {
    console.error(error);
  } finally {
    setGreetings(authenticated.value);
  }
});

const setUrlParam = () => {
  const url = new URL(window.location.href);
  url.searchParams.set('login', 'true');
  window.history.pushState(null, '', url.toString());
};

const loginButtonClick = async () => {
  if (!authenticated.value) {
    setUrlParam();
    await accountStore.authenticate(route);
  }
};

const togglePrefillDetailsButtonClick = () => {
  accountStore.togglePrefillDetails();
};

function setGreetings(authenticated: boolean) {
  if (authenticated) {
    greetingsWithoutPlaceholder.value = replaceBookmark(
      'firstName',
      props.fields?.authenticatedGreetings?.value ?? '',
      accountStore.identityDetails?.firstName ?? ''
    );
  } else {
    greetingsWithoutPlaceholder.value = props.fields?.unauthenticatedGreetings?.value;
  }
}
</script>
