import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { FormKit } from '@formkit/vue';
import { STANDARD_DATE_FORMAT } from '@/constants/formats';
import { format } from 'date-fns';
import type { VueFormInputProps } from '@/composables/useFormInputData';
import BaseIcon from '@/components/BaseIcon/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DatePicker',
  props: /*@__PURE__*/_mergeModels({
    bindings: {},
    minDateToday: { type: Boolean },
    maxDateByDays: {},
    testDate: {}
  }, {
    "modelValue": { required: false, default: new Date() },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;

let minDate: Date | null = null;
let maxDate: Date | null = null;

if (props.maxDateByDays) {
  maxDate = props.testDate ? new Date(props.testDate.getTime()) : new Date();
  maxDate.setDate(maxDate.getDate() + props.maxDateByDays);
}

if (props.minDateToday) {
  minDate = props.testDate ? new Date(props.testDate.getTime()) : new Date();
  minDate.setHours(0, 0, 0, 0);
}

const model = _useModel<Date | null>(__props, "modelValue");

const extendedBindings = computed(() => {
  return {
    ...props.bindings,
    minDate,
    maxDate,
  };
});

const helpTextTokenReplaced = computed(() => {
  if (maxDate) {
    return props.bindings.helpSm?.replace('{maxDate}', format(maxDate, STANDARD_DATE_FORMAT));
  }
  return props.bindings.helpSm;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.bindings.outerClass)
  }, [
    (_ctx.bindings.preContent)
      ? (_openBlock(), _createBlock(_unref(RichText), {
          key: 0,
          class: _normalizeClass(_ctx.bindings.preContentClass),
          field: { value: _ctx.bindings.preContent }
        }, null, 8, ["class", "field"]))
      : _createCommentVNode("", true),
    _createVNode(_unref(FormKit), _mergeProps(extendedBindings.value, {
      modelValue: model.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
      innerClass: _ctx.bindings.innerClass || 'input-sm'
    }), _createSlots({
      prevIcon: _withCtx(() => [
        _createVNode(BaseIcon, { iconClass: "bg-datepicker-prev-icon" })
      ]),
      nextIcon: _withCtx(() => [
        _createVNode(BaseIcon, { iconClass: "bg-datepicker-next-icon" })
      ]),
      calendarIcon: _withCtx(() => [
        _createVNode(BaseIcon, { iconClass: "bg-datepicker-calendar-icon" })
      ]),
      _: 2
    }, [
      (_ctx.bindings.suffixIcon)
        ? {
            name: "suffixIcon",
            fn: _withCtx((context) => [
              _createVNode(_unref(RichText), {
                field: { value: context.suffixIcon ?? '' }
              }, null, 8, ["field"])
            ]),
            key: "0"
          }
        : undefined
    ]), 1040, ["modelValue", "innerClass"]),
    _createVNode(_unref(RichText), {
      class: "formkit-help text-sm text-racq-dark-grey mt-4",
      field: { value: helpTextTokenReplaced.value }
    }, null, 8, ["field"]),
    (_ctx.bindings.postContent && _ctx.bindings.showPostContent)
      ? (_openBlock(), _createBlock(_unref(RichText), {
          key: 1,
          class: _normalizeClass(_ctx.bindings.postContentClass),
          field: { value: _ctx.bindings.postContent }
        }, null, 8, ["class", "field"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})