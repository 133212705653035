import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-5"
}
const _hoisted_2 = { class: "grid grid-cols-1 md:grid-cols-2 gap-2 mb-2" }

import { IPolicyHolderDetails } from '@/interfaces';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import PolicyHolder from './PolicyHolder.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdditionalPolicyHolders',
  props: {
    title: {},
    policyHolders: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.policyHolders.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_unref(RichText), {
            field: { value: _ctx.title },
            class: "mt-5"
          }, null, 8, ["field"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.policyHolders, (policyHolder, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createVNode(PolicyHolder, { policyHolder: policyHolder }, null, 8, ["policyHolder"])
        ]))
      }), 128))
    ])
  ], 64))
}
}

})