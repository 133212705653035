import { STANDARD_DATE_FORMAT } from '@/constants/formats';
import { ICardDetails, IReviewSummary, IReviewSummaryItem } from '@/interfaces';
import { IRenewalAcknowledgementModel } from '@/interfaces/decision-intelligence/decision-intelligence-request';
import { IDecisionIntelligenceOfferResponse } from '@/interfaces/decision-intelligence/decision-intelligence-response';
import {
  ITableGroup,
  ITableGroupColumn,
  ITableGroupRow,
  ITableSection,
} from '@/interfaces/entities/table-group.interface';
import {
  IApplyAssistanceQuoteDiscountRequest,
  IApttusDirectDebitRequest,
  IPayWithCardResponse,
  IPaymentReferenceDetails,
  IQuotedOption,
  IReceiptRequest,
} from '@/interfaces/make-a-payment/make-a-payment.interface';
import { replaceBookmark } from '@/utils/bookmark.replace';
import { getInsuranceTypeDisplayName } from '@/utils/editable-steps/quote-content-builder';
import { format } from 'date-fns';

export enum Bookmarks {
  amountPaid = 'amountPaid',
  insuranceType = 'insuranceType',
  paymentReferenceNumber = 'paymentReferenceNumber',
  paymentDate = 'paymentDate',
  prn = 'prn',
}
export enum MakeAPaymentStepIds {
  Upgrade_Options = 'upgradeoptions',
}

export const mapReceiptRequest = (
  prn: string,
  emailAddress: string,
  paymentReferenceDetails?: IPaymentReferenceDetails,
  paymentResponse?: IPayWithCardResponse
): IReceiptRequest => {
  return {
    pRN: prn,
    emailAddress: emailAddress,
    amount: paymentReferenceDetails?.amount,
    paymentResult: paymentResponse?.result,
    product: paymentReferenceDetails?.description,
    receiptNumber: paymentResponse?.transactionId,
    isInsurance: paymentReferenceDetails?.isInurance,
  };
};
export const replacePolicySummaryBookmarks = (
  content: string,
  paymentReferenceDetails: IPaymentReferenceDetails,
  prn: string,
  dictionary: Record<string, string>
) => {
  let replaceBookmarksContent = replaceBookmark(Bookmarks.paymentDate, content, format(Date(), STANDARD_DATE_FORMAT));

  replaceBookmarksContent = replaceBookmark(
    Bookmarks.amountPaid,
    replaceBookmarksContent,
    paymentReferenceDetails.amount?.toString() ?? ''
  );
  replaceBookmarksContent = replaceBookmark(
    Bookmarks.insuranceType,
    replaceBookmarksContent,
    getInsuranceTypeDisplayName(dictionary, paymentReferenceDetails?.coverageCode ?? '')
  );
  replaceBookmarksContent = replaceBookmark(Bookmarks.paymentReferenceNumber, replaceBookmarksContent, prn);
  return replaceBookmarksContent;
};
export const mapItems = (
  summaryItems: any,
  paymentReferenceDetails: IPaymentReferenceDetails,
  prn: string,
  dictionary: Record<string, string>
) => {
  const mappedItems: IReviewSummaryItem[] = summaryItems.map((val: any) => {
    const item: IReviewSummaryItem = {
      title: val.fields.title,
      content: {
        value: replacePolicySummaryBookmarks(val.fields.content.value, paymentReferenceDetails, prn, dictionary),
      },
      showItem: true,
      showEdit: false,
      editUrl: '',
      titleIcon: { value: '' },
    };
    return item;
  });
  return mappedItems.filter((x) => x.showItem);
};
export const mapSummaries = (
  formInputs: any,
  dictionary: Record<string, string>,
  paymentReferenceDetails: IPaymentReferenceDetails,
  prn: string
): IReviewSummary[] => {
  const reviewSummaries = formInputs?.reviewSummary.fields.map((val: any) => {
    const summ: IReviewSummary = {
      summaryTitle: val.summaryTitle,
      icon: val.icon,
      formContentId: val.formContentId,
      items: mapItems(val.items, paymentReferenceDetails, prn, dictionary),
    };
    return summ;
  });
  return reviewSummaries;
};
export const mapRows = (groupRows: any): ITableGroupRow[] => {
  return groupRows.map((x: any) => {
    return {
      heading: x.heading,
      description: x.description,
      percentage: x.percentage,
    };
  });
};

export const mapColumns = (groupColumns: any, selectedCode?: string): ITableGroupColumn[] => {
  return groupColumns.map((x: any) => {
    return {
      code: x.code,
      rows: mapRows(x.fields),
      highlighted: x.code.value == selectedCode,
    };
  });
};

export const mapTableSections = (formInputs: any, selectedCode?: string): ITableSection[] => {
  const tableSections: ITableSection[] = formInputs.roadsideUpgradeTable.fields.map((x: any) => {
    return {
      heading: x.heading,
      showMoreButton: x.showMoreButton,
      initialNumberOfItemsToShow: x.initialNumberOfItemsToShow,
      showMoreButtonText: x.showMoreButtonText,
      showLessButtonText: x.showLessButtonText,
      tableColumn: mapColumns(x.fields, selectedCode),
    };
  });
  return tableSections;
};

export const mapUpgradeTable = (formInputs: any, displayCodes: string[], selectedCode?: string): ITableGroup => {
  const tableSections = mapTableSections(formInputs, selectedCode);
  tableSections.forEach((x: ITableSection) => {
    x.tableColumn = x.tableColumn.filter((x) => displayCodes.includes(x.code?.value ?? ''));
  });
  return { tableSections: tableSections };
};

export const mapRenewalAcknowledgement = (
  paymentReferenceDetails: IPaymentReferenceDetails,
  decisionOfferResponse: IDecisionIntelligenceOfferResponse,
  paymentAttempled: boolean,
  paymentSucceeded: boolean
): IRenewalAcknowledgementModel => {
  return {
    paymentReferenceDetails: paymentReferenceDetails,
    decisionOfferResponse: decisionOfferResponse,
    paymentAttempted: paymentAttempled,
    paymentSucceeded: paymentSucceeded,
  };
};

export const mapDiscountRequest = (selectedOption?: IQuotedOption): IApplyAssistanceQuoteDiscountRequest => {
  return {
    apttusCartId: selectedOption?.apttusCartId ?? '',
    apttusQuoteId: selectedOption?.apttusQuoteId ?? '',
  };
};

export const mapDirectDebitRequest = (
  paymentReferenceDetails: IPaymentReferenceDetails,
  cardDetails: ICardDetails
): IApttusDirectDebitRequest => {
  return {
    creditCard: {
      token: cardDetails.token,
      maskedCardNumber: cardDetails.maskedCardNumber,
      cardExpiry: `${cardDetails.cardExpiryMonth}/${cardDetails.cardExpiryYear}`,
      cardHolder: cardDetails.cardHoldersName,
      cardType: cardDetails.cardType,
    },
    assetLineItems: [
      {
        assetLineItemId: paymentReferenceDetails.assetLineItemId,
        enabled: true,
      },
    ],
  };
};
