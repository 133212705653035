import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mt-3 w-[250px] ml-[-280px] absolute" }
const _hoisted_2 = ["data-step-id"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "step-number text-center text-dark-grey-text font-bold rounded-full mr-3 w-[30px] min-w-[30px] h-[30px] border-racq-navy border-2 leading-[1.65]" }
const _hoisted_5 = { class: "min-h-[28px]" }
const _hoisted_6 = { class: "step-display-number" }
const _hoisted_7 = { class: "step-text text-lg-bold text-left" }

import { IFormStep } from '@/interfaces';
import { computed, onMounted } from 'vue';
import { useFormStore } from '@/store';
import { uniqBy } from 'lodash-es';

// Initialise Composables
// -------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'FormStepper',
  props: {
    steps: {},
    isMock: { type: Boolean }
  },
  setup(__props: any) {

const formStore = useFormStore();

// Initialise Props
// -------------------------- //
const props = __props;

// Lifecycle Hooks
// -------------------------- //
onMounted(() => {
  if (props.steps && props.isMock) {
    formStore.setSteps(props.steps);
  }
});

// Computed Properties
// -------------------------- //

const formStepperSteps = computed(() => {
  const visibleSteps = formStore.inScopeSteps.filter((step) => !step.hideStepper);
  return uniqBy(visibleSteps, (step) => step.stepGroupName || step.stepTitle);
});

const formCompleted = computed(() => {
  return formStore.inScopeSteps.some((step) => step.isActive && step.isFinalStep);
});

const getStepClass = computed(() => {
  return (step: IFormStep) => {
    const stepUniqueName = step.stepGroupName || step.stepTitle;
    const isComplete = formCompleted.value || areAllStepsCompleteInGroup(stepUniqueName ?? '');
    if (isComplete) {
      return 'step-completed';
    }
    return anyStepsActiveInGroup(stepUniqueName ?? '') ? 'step-active' : 'step-disabled';
  };
});

// Functions
// -------------------------- //
function scrollToStep(stepTitle: string) {
  const stepIndex = formStore.inScopeSteps.findIndex((s) => s.stepTitle == stepTitle);
  if (formStore.inScopeSteps[stepIndex].isVisible) {
    formStore.scrollToStep(stepIndex);
  }
}

function areAllStepsCompleteInGroup(identifier: string): boolean {
  const stepsInGroup = formStore.inScopeSteps.filter(
    (step) => step.stepGroupName === identifier || step.stepTitle === identifier
  );
  return stepsInGroup.every((step) => step.isComplete);
}

function anyStepsActiveInGroup(identifier: string): boolean {
  const stepsInGroup = formStore.inScopeSteps.filter(
    (step) => step.stepGroupName === identifier || step.stepTitle === identifier
  );
  return stepsInGroup.some((step) => step.isActive);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["racq-form-stepper w-full xl:block sticky top-[90px]", _ctx.isMock ? 'h-96' : 'hidden'])
  }, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formStepperSteps.value, (step, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          "data-step-id": step.id,
          class: _normalizeClass(["step mb-[26px] relative content-baseline bg-racq-anti-flash-white", getStepClass.value(step)])
        }, [
          _createElementVNode("button", {
            class: "step-button hover:!no-underline flex items-start",
            type: "button",
            onClick: () => step.stepTitle && scrollToStep(step.stepTitle)
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(index + 1), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_7, _toDisplayString(step.stepGroupName || step.stepTitle), 1)
          ], 8, _hoisted_3)
        ], 10, _hoisted_2))
      }), 128))
    ])
  ], 2))
}
}

})