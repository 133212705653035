<template>
  <FormKit
    type="button"
    label="Use current location"
    inputClass="location-button-text btn btn-md btn-secondary py-2 px-4 h-[40px] font-sans font-base leading-normal !border-x !border-y border-racq-navy"
    @click="getCurrentLocation"
  />
</template>

<script setup lang="ts">
// Add this line to reference the Google Maps types
/// <reference types="@types/google.maps" />

import { useGoogleMapStore } from '@/store';

const googleMapStore = useGoogleMapStore();
const emit = defineEmits(['selected']);

const getCurrentLocation = async () => {
  if (!navigator.geolocation) {
    alert('Geolocation is not supported by your browser');
    return;
  }

  navigator.geolocation.getCurrentPosition(
    async (position) => {
      const { latitude, longitude } = position.coords;

      // Use the Google Maps API to get the address from the coordinates
      const geocoder = new google.maps.Geocoder();
      const latlng = { lat: latitude, lng: longitude };

      geocoder.geocode({ location: latlng }, (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            const { formatted_address } = results[0];
            googleMapStore.setFormattedAddress(formatted_address);
            googleMapStore.setLatitude(latitude);
            googleMapStore.setLongitude(longitude);
            googleMapStore.setAddressStateFromAddressComponents(results[0]?.address_components);
          }
        }
      });
    },
    (error) => {
      googleMapStore.emitErrorEvent(error);
    }
  );
};
</script>

<style scoped>
:deep(.location-button-text) {
  font-size: 16px !important;
}
</style>
