<template>
  <div class="racq-form-stepper w-full xl:block sticky top-[90px]" :class="isMock ? 'h-96' : 'hidden'">
    <ul class="mt-3 w-[250px] ml-[-280px] absolute">
      <li
        v-for="(step, index) in formStepperSteps"
        :key="index"
        :data-step-id="step.id"
        class="step mb-[26px] relative content-baseline bg-racq-anti-flash-white"
        :class="getStepClass(step)"
      >
        <button
          class="step-button hover:!no-underline flex items-start"
          type="button"
          @click="() => step.stepTitle && scrollToStep(step.stepTitle)"
        >
          <div
            class="step-number text-center text-dark-grey-text font-bold rounded-full mr-3 w-[30px] min-w-[30px] h-[30px] border-racq-navy border-2 leading-[1.65]"
          >
            <!-- Extra div layout to bypass WCAG visibility rule -->
            <div class="min-h-[28px]">
              <span class="step-display-number">{{ index + 1 }}</span>
            </div>
          </div>
          <div class="step-text text-lg-bold text-left">
            {{ step.stepGroupName || step.stepTitle }}
          </div>
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { IFormStep } from '@/interfaces';
import { computed, onMounted } from 'vue';
import { useFormStore } from '@/store';
import { uniqBy } from 'lodash-es';

// Initialise Composables
// -------------------------- //
const formStore = useFormStore();

// Initialise Props
// -------------------------- //
const props = defineProps<{
  steps: IFormStep[];
  isMock?: boolean;
}>();

// Lifecycle Hooks
// -------------------------- //
onMounted(() => {
  if (props.steps && props.isMock) {
    formStore.setSteps(props.steps);
  }
});

// Computed Properties
// -------------------------- //

const formStepperSteps = computed(() => {
  const visibleSteps = formStore.inScopeSteps.filter((step) => !step.hideStepper);
  return uniqBy(visibleSteps, (step) => step.stepGroupName || step.stepTitle);
});

const formCompleted = computed(() => {
  return formStore.inScopeSteps.some((step) => step.isActive && step.isFinalStep);
});

const getStepClass = computed(() => {
  return (step: IFormStep) => {
    const stepUniqueName = step.stepGroupName || step.stepTitle;
    const isComplete = formCompleted.value || areAllStepsCompleteInGroup(stepUniqueName ?? '');
    if (isComplete) {
      return 'step-completed';
    }
    return anyStepsActiveInGroup(stepUniqueName ?? '') ? 'step-active' : 'step-disabled';
  };
});

// Functions
// -------------------------- //
function scrollToStep(stepTitle: string) {
  const stepIndex = formStore.inScopeSteps.findIndex((s) => s.stepTitle == stepTitle);
  if (formStore.inScopeSteps[stepIndex].isVisible) {
    formStore.scrollToStep(stepIndex);
  }
}

function areAllStepsCompleteInGroup(identifier: string): boolean {
  const stepsInGroup = formStore.inScopeSteps.filter(
    (step) => step.stepGroupName === identifier || step.stepTitle === identifier
  );
  return stepsInGroup.every((step) => step.isComplete);
}

function anyStepsActiveInGroup(identifier: string): boolean {
  const stepsInGroup = formStore.inScopeSteps.filter(
    (step) => step.stepGroupName === identifier || step.stepTitle === identifier
  );
  return stepsInGroup.some((step) => step.isActive);
}
</script>
