import { getLiveChatSessionData } from '@/constants/forms-api.const';
import { RequestServices as _http } from '../common/request.services';

export const LiveChatServices = {
  /**
   * Get chat session data from sitecore 10
   * @returns
   */
  getLiveChatSessionData() {
    return _http.HttpGet(`${getLiveChatSessionData}`);
  },
} as const;
