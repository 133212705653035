import type { IRefDataItem, IRefDataCodeItem, IDropdownItem, IFoundVehicle } from '@/interfaces';
import { RequestServices as _http } from '../common/request.services';
import {
  getVehicleYears,
  getVehiclesByMake,
  getVehicleModels,
  getVehicleVariants,
  getVehicleBodies,
  getVehicleDetails,
  getVehicleByRego,
  getVehicleEligibility,
  getCombinedAccessoriesList,
} from '@/constants/forms-api.const';
import { IVehicleEligibility } from '@/interfaces/entities/vehicle-eligibility.interface';

export default {
  async getVehicleYears(): Promise<IRefDataCodeItem[]> {
    return await _http.HttpGet(getVehicleYears);
  },

  // https://pj336-dev-s10-form-racq.racqgroup.local/formsapi/v1/vehicle/getmakes?year=2020
  async getVehicleMakesByYear(year: string | number): Promise<IRefDataCodeItem[]> {
    if (!year) {
      return [];
    }

    return await _http.HttpGet(`${getVehiclesByMake}?year=${year}`);
  },

  // https://pj336-dev-s10-form-racq.racqgroup.local/formsapi/v1/vehicle/getmodels?year=2021&make=FORD
  async getVehicleModelsByYearAndMake(year: string, make: string): Promise<IRefDataCodeItem[]> {
    if (!year || !make) {
      return [];
    }

    return await _http.HttpGet(`${getVehicleModels}?year=${year}&make=${make}`);
  },

  // https://pj336-dev-s10-form-racq.racqgroup.local/formsapi/v1/vehicle/getvariants?year=2021&make=MERE&model=GLA
  async getVehicleVariantsByYearMakeModel(year: string, make: string, model: string): Promise<IRefDataCodeItem[]> {
    if (!year || !make || !model) {
      return [];
    }

    return await _http.HttpGet(`${getVehicleVariants}?year=${year}&make=${make}&model=${model}`);
  },

  // https://pj336-dev-s10-form-racq.racqgroup.local/formsapi/v1/vehicle/getbodies?year=2021&make=MERE&model=GLA&variant=35%204MATIC
  async getVehicleBodiesByYearMakeModelVariant(
    year: string,
    make: string,
    model: string,
    variant = ''
  ): Promise<IRefDataCodeItem[]> {
    if (!year || !make || !model) {
      return [];
    }

    return await _http.HttpGet(`${getVehicleBodies}?year=${year}&make=${make}&model=${model}&variant=${variant || ''}`);
  },

  // https://pj336-dev-s10-form-racq.racqgroup.local/formsapi/v1/vehicle/getvehicledetails?year=2021&make=MERE&model=GLA&variant=35%204MATIC&body=WAGON
  // Note: An empty variant and body string searches all vehicle bodies
  async getVehicleDetailsByYearMakeModelVariantBody(
    year: string,
    make: string,
    model: string,
    variant = '',
    body = ''
  ): Promise<IFoundVehicle[]> {
    if (!year || !make || !model) return [];

    const vehicleVariant = variant === 'ALL' ? '' : variant || '';

    return await _http.HttpGet(
      `${getVehicleDetails}?year=${year}&make=${make}&model=${model}&variant=${vehicleVariant}&body=${body}`
    );
  },

  // https://pj336-dev-s10-form-racq.racqgroup.local/formsapi/v1/vehicle/getvehiclebyrego?rego=DOB33
  async regoLookup(rego: string) {
    if (!rego) return;

    return await _http.HttpGet(`${getVehicleByRego}?rego=${rego}`);
  },

  /**
   * Determines whether the specified vehicle (glassesId) is eligible for RSA
   * https://pj336-dev-s10-form-racq.racqgroup.local/formsapi/v1/vehicle/getvehicleeligibility?glassesId=1642110
   */
  async getEligibility(glassesId: number): Promise<IVehicleEligibility | null> {
    if (!glassesId) return null;

    return await _http.HttpGet(`${getVehicleEligibility}?glassesId=${glassesId}`);
  },

  /**
   * A simple helper function to map API data to the UI
   * to work with FormKit's API.
   *
   * @param data
   * @returns
   */
  mapVehicleDataToFormUI(data: Array<IRefDataCodeItem | IRefDataItem>): IDropdownItem[] {
    if (!data?.length) return [];

    const isRefDataItem = (item: IRefDataCodeItem | IRefDataItem): item is IRefDataCodeItem => {
      return (item as IRefDataCodeItem)?.code !== undefined && (item as IRefDataCodeItem)?.description !== undefined;
    };

    return data.map((d) => {
      if (isRefDataItem(d)) {
        return {
          value: d.code,
          label: d.description,
        };
      } else {
        return {
          value: d.name,
          label: d.value,
        };
      }
    });
  },

  fetchAfterMarketAccessoriesAndModifications() {
    return _http.HttpGet(getCombinedAccessoriesList);
  },
} as const;
