<template>
  <div v-if="showDisclaimer" class="my-6">
    <RACQFormKit :bindings="bindings" />
  </div>
</template>
<script setup lang="ts">
import { VueFormInputProps } from '@/composables/useFormInputData';
import { onMounted, watch, computed } from 'vue';
import RACQFormKit from '@/components/RACQFormKit/RACQFormKit.vue';

const props = defineProps<{
  iqStore: any;
  bindings: VueFormInputProps;
}>();

const showDisclaimer = computed(() => props.iqStore.updatePostalAddressRequired);

onMounted(async () => {
  await props.iqStore.checkUpdatePostalAddressRequired();
});

watch(
  () => props.iqStore.quote.primaryPolicyHolderDetails?.partyId?.value,
  props.iqStore.checkUpdatePostalAddressRequired
);
watch(
  () => props.iqStore.quote.primaryPolicyHolderDetails?.dpid?.value,
  props.iqStore.checkUpdatePostalAddressRequired
);
</script>
