<template>
  <div
    v-for="(method, index) in filteredPaymentMethods"
    :key="index"
    class="grid grid-cols-[10%_40%_50%] border-b border-solid mb-5"
  >
    <div>
      <img
        :src="method.fields?.icon?.value?.src"
        :alt="method.fields.icon?.value.alt"
        class="h-[auto] w-[46px] m-auto"
      />
    </div>
    <div>{{ method.fields?.methodName?.value }}</div>
    <RichText :field="{ value: replaceBookmarks(method.fields?.methodContent?.value) }" />
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { replaceBookmark } from '@/utils/bookmark.replace';

let filteredPaymentMethods = ref<any[]>([]);

const props = defineProps<{
  paymentMethods: any[];
  paymentReferenceNumber: string;
}>();

const paymentReferenceNumberName = 'paymentReferenceNumber';

onMounted(async () => {
  filteredPaymentMethods.value = props.paymentMethods.filter(
    (x) =>
      x.fields?.methodContent?.value.indexOf(paymentReferenceNumberName) == -1 ||
      props.paymentReferenceNumber?.length > 0
  );
});

const replaceBookmarks = (content: string) => {
  return replaceBookmark(paymentReferenceNumberName, content, props.paymentReferenceNumber);
};
</script>
