<template>
  <div class="relative w-full h-full">
    <div
      v-if="isTipVisible"
      class="absolute top-0 left-0 w-full h-11 opacity-90 bg-racq-cool-grey flex items-center justify-center text-white pointer-events-none z-10 box-shadow text-sm rounded-t"
    >
      {{ tip }}
    </div>
    <div id="map" class="w-full h-full border border-solid border-racq-pale-blue-border rounded"></div>
  </div>
</template>

<script lang="ts" setup>
// Add this line to reference the Google Maps types
/// <reference types="@types/google.maps" />

// import { GoogleMapService } from '@/services/store';
import { useGoogleMapStore } from '@/store';
import { ref, watch, onMounted, computed } from 'vue';
import lottie from 'lottie-web';

const googleMapStore = useGoogleMapStore();
const locationlat = computed(() => googleMapStore._latitude);
const locationlng = computed(() => googleMapStore._longitude);
const mapInstance = ref<google.maps.Map | null>(null);
const markerInstance = ref<google.maps.marker.AdvancedMarkerElement | null>(null);
const infoWindowInstance = ref<google.maps.InfoWindow | null>(null);
const isTipVisible = ref(false);

let myMap: google.maps.Map;

const props = defineProps({
  tip: {
    type: String,
    required: false,
    default: 'TIP: Move the map to adjust the address',
  },
  mapId: {
    type: String,
    required: true,
    default: 'f100d5ac9d15000f',
  },
  mapApiKey: {
    type: String,
    required: true,
    default: 'AIzaSyBFMsEMG4Qdu9fVvpVbCKI-nW5ur8IzFn4',
  },
  zoomLevel: {
    type: Number,
    required: false,
    default: 17,
  },
  showTip: {
    type: Boolean,
    required: false,
    default: true,
  },
});

async function initMap() {
  try {
    const mapElement = document.getElementById('map');
    if (mapElement) {
      const latLng = new google.maps.LatLng(-22.2358275, 149.1037463);
      const map = new google.maps.Map(mapElement, {
        center: latLng,
        zoom: 5,
        disableDefaultUI: true,
        zoomControl: true,
        mapId: props.mapId,
      });
      initMarker(map);
      await initInfoWindow(latLng);

      map.addListener('dragend', async () => {
        const currZoom = map.getZoom();

        // automatically shows the marker on the map when zoom level is 18 or higher
        if (markerInstance.value?.map || (currZoom ?? 0) >= props.zoomLevel) {
          await updateMarker(true);
        }
      });
      // map.addListener('zoom_changed', updateMarker());

      mapInstance.value = map;
      myMap = map;
    } else {
      console.warn('Map element not found');
    }
  } catch (error) {
    console.warn('Error loading Google Maps API:', error);
  }
}

function isValidToUpdateMarker(): boolean {
  if (!mapInstance.value) {
    console.warn('Map is not initialized');
    return false;
  }

  if (!mapInstance.value.getCenter()) {
    console.warn('Center of the map is not found');
    return false;
  }

  if (!markerInstance.value || !infoWindowInstance.value) {
    console.warn('Marker or window instance not found');
    return false;
  }
  return true;
}

async function updateMarker(updateAddress: boolean): Promise<void> {
  if (!isValidToUpdateMarker()) {
    return;
  }

  const centerOfMapPosition = mapInstance.value?.getCenter();
  if (!centerOfMapPosition) {
    console.warn('Failed to get map center position');
    return;
  }

  const lat = centerOfMapPosition.lat();
  const lng = centerOfMapPosition.lng();

  let formattedAddress = googleMapStore.formattedAddress;
  const latLng = new google.maps.LatLng(lat, lng);
  if (updateAddress) {
    formattedAddress = await getAddressByPosition(latLng);
  }

  // automatically shows the marker on the map when zoom level is 18 or higher
  markerInstance.value!.position = latLng;
  const zoomLevel = myMap.getZoom();
  if (!markerInstance.value?.map && zoomLevel && zoomLevel >= props.zoomLevel) {
    markerInstance.value!.map = myMap;
    if (props.showTip) isTipVisible.value = true;
  }

  if (!markerInstance.value?.map) {
    console.warn('Failed to get map from Marker instance');
    return;
  }

  const infoDisplay = getInfoDisplayElement(formattedAddress);
  if (!infoWindowInstance.value?.isOpen) {
    infoWindowInstance.value?.open(mapInstance.value, markerInstance.value);
  }
  infoWindowInstance.value?.setContent(infoDisplay);

  googleMapStore.setFormattedAddress(formattedAddress);
  googleMapStore.setLatitude(lat);
  googleMapStore.setLongitude(lng);

  // Scroll to the map element
  const mapElement = document.getElementById('map');
  if (mapElement) {
    mapElement.scrollIntoView({ behavior: 'smooth' });
  }
}

async function initInfoWindow(latLng: google.maps.LatLng) {
  if (markerInstance.value) {
    const formattedAddress = await getAddressByPosition(latLng);
    const infoDisplay = getInfoDisplayElement(formattedAddress);
    const infoWindow = new google.maps.InfoWindow({
      headerDisabled: true,
      content: infoDisplay,
      zIndex: 3,
      pixelOffset: new google.maps.Size(0, -8),
    });

    infoWindowInstance.value = infoWindow;
  }
}

function initMarker(map: google.maps.Map) {
  const animationContainer = document.createElement('div');
  animationContainer.style.width = '50px';
  animationContainer.style.height = '50px';
  animationContainer.classList.add('element-with-shadow');

  lottie.loadAnimation({
    container: animationContainer,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: require('@/assets/animated-icons/Location_marker.json'), // if the path doesn't work in staging should move it to typescript
  });

  const racqmarker = new google.maps.marker.AdvancedMarkerElement({
    map: map,
    position: map.getCenter(),
    content: animationContainer as HTMLElement,
    title: 'RACQ Marker.',
  });

  racqmarker.map = null; // Hide the marker initially

  markerInstance.value = racqmarker;
}

function getInfoDisplayElement(formattedAddress: string) {
  const headerDiv = document.createElement('div');
  headerDiv.textContent = 'Your Location';
  headerDiv.classList.add('font-medium', 'leading-normal');
  const addressDiv = document.createElement('div');

  addressDiv.textContent = formattedAddress.length > 30 ? formattedAddress.slice(0, 30) + '...' : formattedAddress;
  addressDiv.classList.add('font-normal', 'leading-tight');
  const container = document.createElement('div');
  container.classList.add(
    'font-sans',
    'text-center',
    'text-[15px]',
    'h-[74px]',
    'sm:w-full',
    'md:w-[274px]',
    'p-1',
    'pb-2',
    'content-center'
  );
  container.appendChild(headerDiv);
  container.appendChild(addressDiv);
  return container;
}

async function getAddressByPosition(latLng: google.maps.LatLng): Promise<string> {
  const geocoder = new google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results && results[0]) {
        resolve(results[0].formatted_address);
      } else {
        reject('Geocode was not successful for the following reason: ' + status);
      }
    });
  });
}

async function waitForGoogleMaps(): Promise<void> {
  return new Promise((resolve) => {
    const checkInterval = setInterval(() => {
      if (typeof google !== 'undefined' && google.maps) {
        clearInterval(checkInterval);
        resolve();
      }
    }, 1000); // Check every 100ms
  });
}

onMounted(async () => {
  try {
    await waitForGoogleMaps();
    await initMap();
  } catch (error) {
    console.warn('Error loading Google Maps script:', error);
  }
});

watch(
  () => [locationlat.value, locationlng.value],
  async ([lat, lng]) => {
    if (!mapInstance.value) {
      console.warn('Map is not initialized from watch googlemapwithpin');
      return;
    }

    const latLng = new google.maps.LatLng(lat, lng);

    if (!markerInstance.value || !infoWindowInstance.value) {
      console.warn('Marker or InfoWindow is not initialized');
      return;
    }

    try {
      const currZoom = mapInstance.value.getZoom();
      if ((currZoom ?? 0) < props.zoomLevel) {
        mapInstance.value.setZoom(props.zoomLevel);
      }
      mapInstance.value.setCenter(latLng);
      await updateMarker(false);
    } catch (error) {
      console.warn('Error updating marker:', error);
    }
  }
);
</script>

<style lang="scss" scoped>
:deep(.element-with-shadow) {
  filter: drop-shadow(0px 0px 20px rgba(8, 100, 188, 33));
}
</style>
