import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-8 md:gap-6" }
const _hoisted_2 = {
  key: 1,
  class: "text-racq-dark-grey text-xl-bold"
}
const _hoisted_3 = { class: "flex flex-col gap-6" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "text-racq-dark-grey text-xl4-bold" }
const _hoisted_6 = { class: "text-racq-dark-grey text-base" }
const _hoisted_7 = {
  key: 1,
  class: "flex flex-col gap-1"
}
const _hoisted_8 = {
  key: 0,
  class: "text-racq-med-grey text-base text-block"
}
const _hoisted_9 = {
  key: 2,
  class: "text-racq-med-grey text-base text-block"
}
const _hoisted_10 = {
  key: 3,
  class: "text-racq-med-grey text-sm text-block"
}

import { ref, computed } from 'vue';
import { Text, RichText, Field } from '@sitecore-jss/sitecore-jss-vue';
import { QuotePremiumDetails } from '@/interfaces/entities/premium-details.interface';
import { TermPaymentDetails } from '@/interfaces/entities/term-payment-details.interface';
import { formatCurrency } from '@/utils/text-transforms';
import { format as formatDate } from 'date-fns';
import InformationBox from '@/components/InformationBox/InformationBox.vue';
import { InformationBoxTypes } from '@/interfaces/forms/information-box.interface';
import ModalDialog from '@/components/ModalDialog/ModalDialog.vue';

interface ViewModel {
  infoText: Field<any>;
  panelTitle: Field<any>;
  baseText?: Field<any>;
  subText?: Field<any>;
  smallText?: Field<any>;
  newPremium?: number | null;
  newPremiumPostText?: string | null;
  showTooltip?: boolean;
  tooltipLabel?: Field<any>;
  tooltipText?: Field<any>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PremiumDetailsReview',
  props: {
    fields: {},
    premiumDetails: {},
    currentTermPaymentDetails: {},
    policyInRenewal: { type: Boolean },
    renewalTermStart: {},
    renewalTermEnd: {}
  },
  setup(__props: any) {

const props = __props;

const showTooltipModal = ref(false);

const viewModel = computed<ViewModel>(() => {
  const model: ViewModel | undefined =
    props.policyInRenewal && props.renewalTermStart && props.renewalTermEnd
      ? computePolicyInRenewalViewModel()
      : computeMidTermPolicyViewModel();
  if (model) return model;
  throw new Error('State not mapped');
});

// Define Functions
// ------------------------------------------------------------- //
function computePolicyInRenewalViewModel(): ViewModel | undefined {
  if (props.premiumDetails.monthlyNonResponsivePremium && props.premiumDetails.annualNonResponsivePremium) {
    return {
      infoText: props.fields.inRenewalMonthlyNonResponsivePremiumInfo,
      panelTitle: props.fields.inRenewalMonthlyNonResponsivePremiumTitle,
      newPremium: props.premiumDetails.monthlyNonResponsivePremium,
      newPremiumPostText: ' per month',
      subText: {
        value: `Total price:
          <span class="text-base-bold">${formatCurrency(props.premiumDetails.annualNonResponsivePremium, 2)}</span>
          for period
          ${formatDate(props.renewalTermStart!, 'dd MMMM yyyy')} to ${formatDate(props.renewalTermEnd!, 'dd MMMM yyyy')}`,
      },
      showTooltip: true,
      tooltipLabel: props.fields.premiumChangeTooltipLabel,
      tooltipText: props.fields.premiumChangeTooltipText,
      smallText: props.fields.inRenewalMonthlyNonResponsivePremiumChangeText,
    };
  } else if (props.premiumDetails.annualNonResponsivePremium) {
    return {
      infoText: props.fields.inRenewalAnnualNonResponsivePremiumInfo,
      panelTitle: props.fields.inRenewalAnnualNonResponsivePremiumTitle,
      newPremium: props.premiumDetails.annualNonResponsivePremium,
      newPremiumPostText: ' per year',
      subText: {
        value: `For period
          ${formatDate(props.renewalTermStart!, 'dd MMMM yyyy')} to ${formatDate(props.renewalTermEnd!, 'dd MMMM yyyy')}`,
      },
      showTooltip: true,
      tooltipLabel: props.fields.premiumChangeTooltipLabel,
      tooltipText: props.fields.premiumChangeTooltipText,
      smallText: props.fields.inRenewalAnnualNonResponsivePremiumChangeText,
    };
  } else if (props.premiumDetails.annualResponsiveAmountDue) {
    return {
      infoText: props.fields.inRenewalResponsivePremiumInfo,
      panelTitle: props.fields.inRenewalResponsivePremiumTitle,
      newPremium: props.premiumDetails.annualResponsiveAmountDue,
      newPremiumPostText: ' per year',
      subText: {
        value: `For period
          ${formatDate(props.renewalTermStart!, 'dd MMMM yyyy')} to ${formatDate(props.renewalTermEnd!, 'dd MMMM yyyy')}`,
      },
      showTooltip: true,
      tooltipLabel: props.fields.paymentRequiredTooltipLabel,
      tooltipText: props.fields.paymentRequiredTooltipText,
      smallText: props.fields.inRenewalResponsivePremiumChangeText,
    };
  }
}

function computeMidTermPolicyViewModel(): ViewModel | undefined {
  if (!props.premiumDetails.premiumChanged) {
    return {
      infoText: props.fields.premiumNotChangedInfo,
      panelTitle: props.fields.premiumNotChangedTitle,
      baseText: props.fields.premiumNotChangedSubTitle,
    };
  } else if (props.premiumDetails.monthlyNonResponsivePremium || props.premiumDetails.monthlyNonResponsiveDeposit) {
    let subText: Field<string> | undefined;
    if (props.currentTermPaymentDetails.nextInvoiceAmountDue)
      subText = {
        value: `Current premium:
          <span class="text-base-bold">${formatCurrency(props.currentTermPaymentDetails.nextInvoiceAmountDue, 2)}</span>
          per month`,
      };

    if (props.fields.premiumSubText?.value) subText = props.fields.premiumSubText;
    return {
      infoText: props.fields.monthlyNonResponsivePremiumInfo,
      panelTitle: props.fields.monthlyNonResponsivePremiumTitle,
      newPremium: props.premiumDetails.monthlyNonResponsivePremium ?? props.premiumDetails.monthlyNonResponsiveDeposit,
      newPremiumPostText: props.fields.premiumPostText?.value || ' next monthly payment*',
      subText,
      showTooltip: true,
      tooltipLabel: props.fields.premiumChangeTooltipLabel,
      tooltipText: props.fields.premiumChangeTooltipText,
      smallText: props.fields.monthlyNonResponsivePremiumChangeText,
    };
  } else if (props.premiumDetails.annualRefund) {
    return {
      infoText: props.fields.annualRefundInfo,
      panelTitle: props.fields.annualRefundTitle,
      newPremium: props.premiumDetails.annualRefund,
      smallText: props.fields.annualRefundChangeText,
    };
  } else if (props.premiumDetails.annualNonResponsivePremium) {
    return {
      infoText: props.fields.annualNonResponsivePremiumInfo,
      panelTitle: props.fields.annualNonResponsivePremiumTitle,
      newPremium: props.premiumDetails.annualNonResponsiveAmountDue,
      showTooltip: true,
      tooltipLabel: props.fields.premiumChangeTooltipLabel,
      tooltipText: props.fields.premiumChangeTooltipText,
      baseText: props.fields.annualNonResponsivePremiumChangeText,
    };
  } else if (props.premiumDetails.annualResponsivePremium) {
    return {
      infoText: props.fields.annualResponsivePremiumInfo,
      panelTitle: props.fields.annualResponsivePremiumTitle,
      newPremium: props.premiumDetails.annualResponsiveAmountDue,
      showTooltip: true,
      tooltipLabel: props.fields.paymentRequiredTooltipLabel,
      tooltipText: props.fields.paymentRequiredTooltipText,
      baseText: props.fields.annualResponsivePremiumChangeText,
    };
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (viewModel.value.infoText?.value)
        ? (_openBlock(), _createBlock(InformationBox, {
            key: 0,
            type: _unref(InformationBoxTypes).INFO,
            description: viewModel.value.infoText?.value
          }, null, 8, ["type", "description"]))
        : _createCommentVNode("", true),
      (viewModel.value.panelTitle?.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_unref(Text), {
              field: viewModel.value.panelTitle
            }, null, 8, ["field"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (viewModel.value.newPremium)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(formatCurrency)(viewModel.value.newPremium, 2)), 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(viewModel.value.newPremiumPostText), 1)
            ]))
          : _createCommentVNode("", true),
        (viewModel.value.subText || viewModel.value.showTooltip)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (viewModel.value.subText)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_unref(RichText), {
                      field: viewModel.value.subText
                    }, null, 8, ["field"])
                  ]))
                : _createCommentVNode("", true),
              (viewModel.value.showTooltip && viewModel.value.tooltipLabel?.value)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    type: "button",
                    class: "link text-m self-start",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (showTooltipModal.value = true))
                  }, [
                    _createVNode(_unref(Text), {
                      field: viewModel.value.tooltipLabel
                    }, null, 8, ["field"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (viewModel.value.baseText)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_unref(RichText), {
                field: viewModel.value.baseText
              }, null, 8, ["field"])
            ]))
          : _createCommentVNode("", true),
        (viewModel.value.smallText)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_unref(RichText), {
                field: viewModel.value.smallText
              }, null, 8, ["field"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(ModalDialog, {
      modelValue: showTooltipModal.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((showTooltipModal).value = $event)),
      closeOnEsc: true,
      showXCloseButton: true,
      dialogClass: "tooltip-dialog"
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(RichText), {
          field: viewModel.value.tooltipLabel,
          class: "tooltip-title"
        }, null, 8, ["field"]),
        _createVNode(_unref(RichText), {
          field: viewModel.value.tooltipText,
          class: "tooltip-body richtext"
        }, null, 8, ["field"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})