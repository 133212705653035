<template>
  <div class="flex flex-col gap-8 md:gap-6">
    <InformationBox
      v-if="viewModel.infoText?.value"
      :type="InformationBoxTypes.INFO"
      :description="viewModel.infoText?.value"
    />
    <div v-if="viewModel.panelTitle?.value" class="text-racq-dark-grey text-xl-bold">
      <Text :field="viewModel.panelTitle" />
    </div>
    <div class="flex flex-col gap-6">
      <div v-if="viewModel.newPremium">
        <span class="text-racq-dark-grey text-xl4-bold"> {{ formatCurrency(viewModel.newPremium, 2) }} </span>
        <span class="text-racq-dark-grey text-base"> {{ viewModel.newPremiumPostText }} </span>
      </div>
      <div v-if="viewModel.subText || viewModel.showTooltip" class="flex flex-col gap-1">
        <div v-if="viewModel.subText" class="text-racq-med-grey text-base text-block">
          <RichText :field="viewModel.subText" />
        </div>
        <button
          v-if="viewModel.showTooltip && viewModel.tooltipLabel?.value"
          type="button"
          class="link text-m self-start"
          @click="showTooltipModal = true"
        >
          <Text :field="viewModel.tooltipLabel" />
        </button>
      </div>
      <div v-if="viewModel.baseText" class="text-racq-med-grey text-base text-block">
        <RichText :field="viewModel.baseText" />
      </div>
      <div v-if="viewModel.smallText" class="text-racq-med-grey text-sm text-block">
        <RichText :field="viewModel.smallText" />
      </div>
    </div>
  </div>
  <ModalDialog v-model="showTooltipModal" :closeOnEsc="true" :showXCloseButton="true" dialogClass="tooltip-dialog">
    <RichText :field="viewModel.tooltipLabel" class="tooltip-title" />
    <RichText :field="viewModel.tooltipText" class="tooltip-body richtext" />
  </ModalDialog>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { Text, RichText, Field } from '@sitecore-jss/sitecore-jss-vue';
import { QuotePremiumDetails } from '@/interfaces/entities/premium-details.interface';
import { TermPaymentDetails } from '@/interfaces/entities/term-payment-details.interface';
import { formatCurrency } from '@/utils/text-transforms';
import { format as formatDate } from 'date-fns';
import InformationBox from '@/components/InformationBox/InformationBox.vue';
import { InformationBoxTypes } from '@/interfaces/forms/information-box.interface';
import ModalDialog from '@/components/ModalDialog/ModalDialog.vue';

const props = defineProps<{
  fields: Record<string, Field<any>>;
  premiumDetails: QuotePremiumDetails;
  currentTermPaymentDetails: TermPaymentDetails;
  policyInRenewal: boolean;
  renewalTermStart: Date | undefined;
  renewalTermEnd: Date | undefined;
}>();

const showTooltipModal = ref(false);

interface ViewModel {
  infoText: Field<any>;
  panelTitle: Field<any>;
  baseText?: Field<any>;
  subText?: Field<any>;
  smallText?: Field<any>;
  newPremium?: number | null;
  newPremiumPostText?: string | null;
  showTooltip?: boolean;
  tooltipLabel?: Field<any>;
  tooltipText?: Field<any>;
}

const viewModel = computed<ViewModel>(() => {
  const model: ViewModel | undefined =
    props.policyInRenewal && props.renewalTermStart && props.renewalTermEnd
      ? computePolicyInRenewalViewModel()
      : computeMidTermPolicyViewModel();
  if (model) return model;
  throw new Error('State not mapped');
});

// Define Functions
// ------------------------------------------------------------- //
function computePolicyInRenewalViewModel(): ViewModel | undefined {
  if (props.premiumDetails.monthlyNonResponsivePremium && props.premiumDetails.annualNonResponsivePremium) {
    return {
      infoText: props.fields.inRenewalMonthlyNonResponsivePremiumInfo,
      panelTitle: props.fields.inRenewalMonthlyNonResponsivePremiumTitle,
      newPremium: props.premiumDetails.monthlyNonResponsivePremium,
      newPremiumPostText: ' per month',
      subText: {
        value: `Total price:
          <span class="text-base-bold">${formatCurrency(props.premiumDetails.annualNonResponsivePremium, 2)}</span>
          for period
          ${formatDate(props.renewalTermStart!, 'dd MMMM yyyy')} to ${formatDate(props.renewalTermEnd!, 'dd MMMM yyyy')}`,
      },
      showTooltip: true,
      tooltipLabel: props.fields.premiumChangeTooltipLabel,
      tooltipText: props.fields.premiumChangeTooltipText,
      smallText: props.fields.inRenewalMonthlyNonResponsivePremiumChangeText,
    };
  } else if (props.premiumDetails.annualNonResponsivePremium) {
    return {
      infoText: props.fields.inRenewalAnnualNonResponsivePremiumInfo,
      panelTitle: props.fields.inRenewalAnnualNonResponsivePremiumTitle,
      newPremium: props.premiumDetails.annualNonResponsivePremium,
      newPremiumPostText: ' per year',
      subText: {
        value: `For period
          ${formatDate(props.renewalTermStart!, 'dd MMMM yyyy')} to ${formatDate(props.renewalTermEnd!, 'dd MMMM yyyy')}`,
      },
      showTooltip: true,
      tooltipLabel: props.fields.premiumChangeTooltipLabel,
      tooltipText: props.fields.premiumChangeTooltipText,
      smallText: props.fields.inRenewalAnnualNonResponsivePremiumChangeText,
    };
  } else if (props.premiumDetails.annualResponsiveAmountDue) {
    return {
      infoText: props.fields.inRenewalResponsivePremiumInfo,
      panelTitle: props.fields.inRenewalResponsivePremiumTitle,
      newPremium: props.premiumDetails.annualResponsiveAmountDue,
      newPremiumPostText: ' per year',
      subText: {
        value: `For period
          ${formatDate(props.renewalTermStart!, 'dd MMMM yyyy')} to ${formatDate(props.renewalTermEnd!, 'dd MMMM yyyy')}`,
      },
      showTooltip: true,
      tooltipLabel: props.fields.paymentRequiredTooltipLabel,
      tooltipText: props.fields.paymentRequiredTooltipText,
      smallText: props.fields.inRenewalResponsivePremiumChangeText,
    };
  }
}

function computeMidTermPolicyViewModel(): ViewModel | undefined {
  if (!props.premiumDetails.premiumChanged) {
    return {
      infoText: props.fields.premiumNotChangedInfo,
      panelTitle: props.fields.premiumNotChangedTitle,
      baseText: props.fields.premiumNotChangedSubTitle,
    };
  } else if (props.premiumDetails.monthlyNonResponsivePremium || props.premiumDetails.monthlyNonResponsiveDeposit) {
    let subText: Field<string> | undefined;
    if (props.currentTermPaymentDetails.nextInvoiceAmountDue)
      subText = {
        value: `Current premium:
          <span class="text-base-bold">${formatCurrency(props.currentTermPaymentDetails.nextInvoiceAmountDue, 2)}</span>
          per month`,
      };

    if (props.fields.premiumSubText?.value) subText = props.fields.premiumSubText;
    return {
      infoText: props.fields.monthlyNonResponsivePremiumInfo,
      panelTitle: props.fields.monthlyNonResponsivePremiumTitle,
      newPremium: props.premiumDetails.monthlyNonResponsivePremium ?? props.premiumDetails.monthlyNonResponsiveDeposit,
      newPremiumPostText: props.fields.premiumPostText?.value || ' next monthly payment*',
      subText,
      showTooltip: true,
      tooltipLabel: props.fields.premiumChangeTooltipLabel,
      tooltipText: props.fields.premiumChangeTooltipText,
      smallText: props.fields.monthlyNonResponsivePremiumChangeText,
    };
  } else if (props.premiumDetails.annualRefund) {
    return {
      infoText: props.fields.annualRefundInfo,
      panelTitle: props.fields.annualRefundTitle,
      newPremium: props.premiumDetails.annualRefund,
      smallText: props.fields.annualRefundChangeText,
    };
  } else if (props.premiumDetails.annualNonResponsivePremium) {
    return {
      infoText: props.fields.annualNonResponsivePremiumInfo,
      panelTitle: props.fields.annualNonResponsivePremiumTitle,
      newPremium: props.premiumDetails.annualNonResponsiveAmountDue,
      showTooltip: true,
      tooltipLabel: props.fields.premiumChangeTooltipLabel,
      tooltipText: props.fields.premiumChangeTooltipText,
      baseText: props.fields.annualNonResponsivePremiumChangeText,
    };
  } else if (props.premiumDetails.annualResponsivePremium) {
    return {
      infoText: props.fields.annualResponsivePremiumInfo,
      panelTitle: props.fields.annualResponsivePremiumTitle,
      newPremium: props.premiumDetails.annualResponsiveAmountDue,
      showTooltip: true,
      tooltipLabel: props.fields.paymentRequiredTooltipLabel,
      tooltipText: props.fields.paymentRequiredTooltipText,
      baseText: props.fields.annualResponsivePremiumChangeText,
    };
  }
}
</script>
