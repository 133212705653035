import { QuoteChangeState } from '@/enums/quote-change-state.enum';
import { IPolicyHolderDetails } from '@/interfaces/insurance-quote/policyholder.interface';
import { RACQAddressResult } from '@/interfaces';
import { convertISOToDateFormatted, convertToISODate } from '@/utils/data-transforms';
import { AUSTRALIA } from '@/constants/general-words.const';

const minDate = new Date(-8640000000000000);

/**
 * Map policyholder helper
 *
 * @param policyholder
 * @param formData
 */
export const mapPolicyholder = (policyholder: IPolicyHolderDetails, formData: Record<string, any>) => {
  if (policyholder?.emailAddress?.value != formData.email) {
    policyholder.emailAddress = {
      value: formData.email,
      state: QuoteChangeState.Changed,
    };
  }
  if (policyholder?.firstname?.value != formData.fullname?.firstName) {
    policyholder.firstname = {
      value: formData.fullname?.firstName,
      state: QuoteChangeState.Changed,
    };
  }
  if (policyholder?.surname?.value != formData.fullname?.lastName) {
    policyholder.surname = {
      value: formData.fullname?.lastName,
      state: QuoteChangeState.Changed,
    };
  }
  if (policyholder?.salutation?.value != formData.fullname?.title) {
    policyholder.salutation = {
      value: formData.fullname?.title,
      state: QuoteChangeState.Changed,
    };
  }
  if (policyholder?.dateOfBirth?.value != convertToISODate(formData.dateOfBirth)) {
    policyholder.dateOfBirth = {
      value: convertToISODate(formData.dateOfBirth),
      state: QuoteChangeState.Changed,
    };
  }
  if (policyholder?.gender?.value != formData.gender) {
    policyholder.gender = {
      value: formData.gender,
      state: QuoteChangeState.Changed,
    };
  }
  if (policyholder?.telephoneNumber?.value != formData.primaryContactNumber) {
    policyholder.telephoneNumber = {
      value: formData.primaryContactNumber,
      state: QuoteChangeState.Changed,
    };
  }
  if (policyholder?.secondaryTelephoneNumber?.value != formData.secondaryContactNumber) {
    policyholder.secondaryTelephoneNumber = {
      value: formData.secondaryContactNumber,
      state: QuoteChangeState.Changed,
    };
  }

  // Postal Address
  if (policyholder?.postcode?.value != formData.postalAddress?.postcode) {
    policyholder.postcode = {
      value: formData.postalAddress?.postcode,
      state: QuoteChangeState.Changed,
    };
  }
  if (policyholder?.suburb?.value != formData.postalAddress?.suburb) {
    policyholder.suburb = {
      value: formData.postalAddress?.suburb,
      state: QuoteChangeState.Changed,
    };
  }
  if (policyholder?.street?.value != formData.postalAddress?.street) {
    policyholder.street = {
      value: formData.postalAddress?.street,
      state: QuoteChangeState.Changed,
    };
  }
  if (policyholder?.streetNumber?.value != formData.postalAddress?.streetNumber) {
    policyholder.streetNumber = {
      value: formData.postalAddress?.streetNumber,
      state: QuoteChangeState.Changed,
    };
  }
  if (policyholder?.country?.value != formData.postalAddress?.country) {
    policyholder.country = {
      value: formData.postalAddress?.country,
      state: QuoteChangeState.Changed,
    };
  }
  if (policyholder?.unitNumber?.value != formData.postalAddress?.unitNumber) {
    policyholder.unitNumber = {
      value: formData.postalAddress?.unitNumber,
      state: QuoteChangeState.Changed,
    };
  }
  if (policyholder?.state?.value != formData.postalAddress?.state) {
    policyholder.state = {
      value: formData.postalAddress?.state,
      state: QuoteChangeState.Changed,
    };
  }
  if (policyholder?.dpid?.value != formData.postalAddress?.dpid) {
    policyholder.dpid = {
      value: formData.postalAddress?.dpid,
      state: QuoteChangeState.Changed,
    };
  }
};

/**
 * Reverse policyholder from quote to form data
 *
 * @param policyholder
 * @param formData
 */
export const reversePolicyholder = (policyholder: IPolicyHolderDetails, formData: Record<string, any>) => {
  formData.email = policyholder?.emailAddress.value;
  formData.fullname = {
    firstName: policyholder?.firstname.value,
    lastName: policyholder?.surname.value,
    title: policyholder?.salutation.value,
  };
  if (policyholder?.dateOfBirth.value && policyholder.dateOfBirth.value > minDate)
    formData.dateOfBirth = convertISOToDateFormatted(policyholder?.dateOfBirth?.value);
  formData.gender = policyholder?.gender.value;
  formData.primaryContactNumber = policyholder?.telephoneNumber.value;
  formData.secondaryContactNumber = policyholder?.secondaryTelephoneNumber.value;

  formData.postalOversea =
    policyholder?.country.value && policyholder?.country.value?.toUpperCase() != 'AUSTRALIA' ? 'true' : 'false';

  if (policyholder?.street.value) {
    formData.postalAddress_is_manual = !policyholder?.dpid.value;
    formData.postalAddress = reversePolicyholderPostalAddress(policyholder);
    formData.postalAddress_auto = joinAddressComponents(formData.postalAddress);
  }
};

export const reversePolicyholderPostalAddress = (policyholder: IPolicyHolderDetails): Partial<RACQAddressResult> => {
  return {
    postcode: policyholder?.postcode?.value,
    suburb: policyholder?.suburb?.value,
    street: policyholder?.street?.value,
    streetNumber: policyholder?.streetNumber?.value,
    country: policyholder?.country?.value,
    unitNumber: policyholder?.unitNumber?.value,
    state: policyholder?.state?.value,
    dpid: policyholder?.dpid?.value,
  } as Partial<RACQAddressResult>;
};

export const joinAddressComponents = (postAddress: Record<string, string>) => {
  const isOverseas = postAddress.country && postAddress.country != AUSTRALIA;

  const addressParts = [];

  if (postAddress.unitNumber && postAddress.unitNumber.trim().length > 0) addressParts.push(postAddress.unitNumber);
  if (postAddress.streetNumber && postAddress.streetNumber.trim().length > 0)
    addressParts.push(postAddress.streetNumber);
  if (postAddress.street && postAddress.street.trim().length > 0) addressParts.push(postAddress.street);
  if (postAddress.suburb && postAddress.suburb.trim().length > 0) addressParts.push(postAddress.suburb);
  if (postAddress.state && postAddress.state.trim().length > 0 && !isOverseas) addressParts.push(postAddress.state);
  if (postAddress.postcode && postAddress.postcode.trim().length > 0) addressParts.push(postAddress.postcode);
  if (postAddress.country && postAddress.country.trim().length > 0 && isOverseas)
    addressParts.push(postAddress.country);

  return `${addressParts.shift()} ${addressParts.join(', ')}`;
};

export const joinAddressComponentsForPolicyholder = (policyholder: IPolicyHolderDetails) => {
  return `${policyholder.unitNumber?.value || ''} ${policyholder.streetNumber?.value} ${policyholder.street?.value}, ${policyholder.suburb?.value} ${policyholder.state?.value} ${policyholder.postcode?.value}`.trim();
};
