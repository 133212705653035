<template>
  <div class="pt-6 py-8 px-5 bg-white form-float-box-border text-racq-dark-grey">
    <h4 class="text-xl !font-medium leading-7 mb-2"><Text :field="wordings.title" /></h4>
    <DollarAmount :showStarSign="false" :amount="insuranceQuoteSummary ?? ''" />

    <select v-model="showMonthlyPremium" class="text-base !font-medium mb-6">
      <option :value="true"><Text :field="wordings.monthly" /></option>
      <option :value="false"><Text :field="wordings.annual" /></option>
    </select>

    <div class="mb-6">
      <TagList
        :tags="discounts"
        :tagType="TagTypes.discount"
        :tagOrientation="TagOrientation.vertical"
        innerClass="w-fit"
      />
    </div>
    <template v-if="quoteHasChanged || props.testParams?.enableQuoteHasChanged">
      <RichText class="flex flex-col space-y-3 text-m mb-6" :field="{ value: premiumHasChangeLink?.value }" />
    </template>
    <div class="w-full h-8 px-4 py-2 bg-racq-anti-flash-white rounded-lg justify-center items-start gap-5 inline-flex">
      <div class="text-center text-racq-med-grey text-xs leading-none">
        {{ referenceNumberText }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useIQOriginalQuoteStore } from '@/store/insurance-quote/original-quote.state';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { Item } from '@sitecore-jss/sitecore-jss/layout';
import { getDiscountsList } from '@/utils/iq-discounts';
import { hasQuotePremiumChanged } from '@/utils/display-rules/loyalty-rules';
import { IQuote } from '@/interfaces';
import DollarAmount from '@/components/DollarAmount/DollarAmount.vue';
import TagList from '@/components/TagList/TagList.vue';
import { TagTypes, TagOrientation } from '@/enums/tag-enums';

enum fieldNames {
  title = 'Title',
  annualPayment = 'Annual Payment',
  monthlyPayment = 'Monthly Payment',
  refNumber = 'Ref Number',
  quoteChangedLink = 'Quote Changed Link',
}

const props = defineProps<{
  fields: {
    quote: Partial<IQuote>;
    floatingBoxContext: Item;
  };
  testParams?: {
    enableQuoteHasChanged: boolean;
  };
}>();

const showMonthlyPremium = ref<boolean>(true);
const premiumHasChangeLink = ref<any>();
const iqOriginalQuoteStore = useIQOriginalQuoteStore();

const referenceNumberText = computed(() => {
  return `${wordings.value.refNum.value} ${props.fields.quote?.reference}`;
});
const insuranceQuoteSummary = computed(() =>
  showMonthlyPremium.value
    ? props.fields.quote?.insuranceQuoteSummary?.monthlyDeposit
    : props.fields.quote?.insuranceQuoteSummary?.yearlyAnnualPremium
);
const { t, getLocaleMessage } = useI18n();
const discounts = computed(() =>
  getDiscountsList({ t, getLocaleMessage }, props.fields.quote, { showAsMonthly: showMonthlyPremium.value })
);
const wordings = computed(() => ({
  title: getFieldByName(fieldNames.title)?.content,
  annual: getFieldByName(fieldNames.annualPayment)?.content,
  monthly: getFieldByName(fieldNames.monthlyPayment)?.content,
  refNum: getFieldByName(fieldNames.refNumber)?.content,
}));

const quoteHasChanged = computed(() => {
  if (!iqOriginalQuoteStore.quote) return false;
  return hasQuotePremiumChanged(props.fields.quote, iqOriginalQuoteStore.quote);
});

onMounted(async () => {
  premiumHasChangeLink.value = getFieldByName(fieldNames.quoteChangedLink)?.content;
});

const getFieldByName = (fieldName: fieldNames) => {
  if (!props.fields.floatingBoxContext?.fields) return;
  const fields = props.fields.floatingBoxContext.fields ? props.fields.floatingBoxContext.fields : [];
  const field = (fields as any[]).find((x: any) => x.name === fieldName);
  return field;
};
</script>
