<template>
  <div>
    <div class="rounded shadow border-2 border-racq-pale-blue-border flex flex-col basis-full">
      <template v-if="title">
        <div class="px-8 pt-6 text-xl-bold">{{ title }}</div>
      </template>

      <slot name="policyDiscounts"></slot>

      <div
        v-for="(summary, sumIndex) in reviewSummaries"
        v-show="summary.items.length && (!isConfirmationStep || disableExpandToggle || sumIndex == 0 || expanded)"
        :key="sumIndex"
        class="self-stretch px-8 pt-8 pb-4 border-racq-pale-blue-border flex-col justify-start items-start gap-4 flex"
        :class="sumIndex === reviewSummaries.length - 1 ? '' : 'border-b'"
      >
        <div class="self-stretch pb-1 flex-col justify-start items-start gap-2 flex">
          <div class="self-stretch justify-start items-center gap-3 inline-flex">
            <BaseIcon v-if="summary.icon?.value" :iconClass="summary.icon?.value" />
            <div class="grow shrink basis-0 text-racq-dark-grey text-lg-bold">
              <Text :field="summary.summaryTitle" />
            </div>
          </div>
        </div>

        <div class="self-stretch flex-col justify-start items-start gap-4 flex">
          <ReviewSummaryItem
            v-for="(item, itemIndex) in summary.items"
            :key="itemIndex"
            :item="item"
            :isLastReviewSummaryItem="itemIndex === summary.items.length - 1"
          >
            <template #title>
              <div class="relative">
                <RichText :field="item.title" class="text-racq-dark-grey text-base-bold" />
              </div>
            </template>
            <template #content>
              <RichText :field="item.content" class="text-base text-block" />
              <template v-if="shouldRenderMapSlot(item.content.value)">
                <slot name="googleMapWithPinStatic"></slot>
              </template>
            </template>
            <template #edit>
              <Link
                v-if="item.editUrl && !isConfirmationStep && item.showEdit"
                :field="formatLink(item.editUrl)"
                class="text-base-bold underline"
                >Edit</Link
              >
              <button
                v-if="item.editGoToStep != null && !isConfirmationStep && item.showEdit"
                type="button"
                class="link text-base-bold"
                @click="onEditDetails(item.editGoToStep, summary, item)"
              >
                Edit
              </button>
            </template>
          </ReviewSummaryItem>
          <slot name="summaryItemFooter"></slot>
        </div>
      </div>
    </div>

    <div v-if="!isConfirmationStep || expanded">
      <slot name="additionalPolicyHolders"></slot>
    </div>

    <div
      v-if="isConfirmationStep && !disableExpandToggle && reviewSummaries.length > 1"
      class="w-100 text-center mt-5 mb-5"
    >
      <FormKit
        type="button"
        :label="expanded ? 'Show less' : 'Show more'"
        inputClass="text-racq-navy"
        :suffixIcon="expanded ? 'up' : 'down'"
        @click="expanded = !expanded"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { IReviewSummary, IReviewSummaryItem } from '@/interfaces';
import ReviewSummaryItem from '@/components/ReviewSummary/ReviewSummaryItem.vue';
import BaseIcon from '@/components/BaseIcon/BaseIcon.vue';
import { useFormStore } from '@/store';
import { Text, RichText, Link } from '@sitecore-jss/sitecore-jss-vue';
import { LinkField } from '@sitecore-jss/sitecore-jss-vue/types/components/Link';

const formStore = useFormStore();
const expanded = ref<boolean>(false);

const props = defineProps<{
  reviewSummaries: IReviewSummary[];
  isConfirmationStep: boolean;
  disableExpandToggle?: boolean;
  title?: string;
}>();

// Exposed to track which "edit" link was clicked from the ReviewSummary
const emit = defineEmits<(e: 'editClicked', summary: IReviewSummary, item: IReviewSummaryItem) => void>();
function onEditDetails(goToStep: number, reviewSummary: IReviewSummary, reviewSummaryItem: IReviewSummaryItem) {
  emit('editClicked', reviewSummary, reviewSummaryItem);
  formStore.goToStep(goToStep);
}

/**
 * Returns whether the reviewSummaryItemContent contains a slot item
 * @param reviewSummaryItemContent The ReviewSummaryItemContent to check.
 */
function shouldRenderMapSlot(reviewSummaryItemContent: string): boolean {
  if (reviewSummaryItemContent?.includes('#googleMapWithPinStatic')) {
    return true;
  }
  return false;
}

function formatLink(url: string): LinkField {
  return {
    value: {
      href: url,
      target: '_self',
    },
  };
}
</script>
