<template>
  <div class="flex flex-col gap-3">
    <RichText :field="emailHeading" class="text-xl text-base-bold" />
    <div class="px-8 py-6 bg-racq-anti-flash-white border-2 border-solid border-racq-blue-background">
      <FormKit type="form" :actions="false" @submit="emailSendClick">
        <div class="flex flex-col gap-2">
          <div class="flex gap-5 flex-col md:flex-row">
            <RACQFormKit type="text" :bindings="bindings" />
            <div>
              <FormKit
                type="submit"
                :loading="isLoading"
                :disabled="isLoading"
                :inputClass="`btn btn-md btn-secondary md:mt-7`"
                :label="emailButtonText.value"
              />
            </div>
          </div>
          <Alert v-if="alert && showAlert" :alert="alert" />
        </div>
      </FormKit>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormKit from '../RACQFormKit/RACQFormKit.vue';
import Alert from '../Alert/Alert.vue';
import { IAlert } from '@/interfaces/forms/alert-interface';
import { AlertTypes } from '@/enums/alert-types-enum';
import { computed } from 'vue';

// Define props and emitters
// ------------------------------------------------------------- //
const props = defineProps<{
  bindings: any;
  emailHeading: { value: string };
  emailButtonText: { value: string };
  successText: { value: string };
  errorText: { value: string };
  isLoading: boolean;
  isSuccess: boolean;
  showAlert: boolean;
}>();
const emit = defineEmits(['sendEmailFired']);

// Define Component state
// ------------------------------------------------------------- //
const alert = computed(() => {
  return {
    showIcon: true,
    alertType: props.isSuccess ? AlertTypes.success : AlertTypes.danger,
    text: props.isSuccess ? props.successText.value : props.errorText.value,
  } as IAlert;
});
// Define Functions
// ------------------------------------------------------------- //

const emailSendClick = (values: any) => {
  emit('sendEmailFired', values);
};
</script>
