import { defineStore } from 'pinia';
import { ref, computed, watch, toRefs } from 'vue';
import { JoinOnRoadService as _joinOnRoadService } from '@/services/store/join-on-road.service';
import { JoinOnRoadDetails, JoinOnRoadGoogleMapDetails } from '@/interfaces/pages/join-on-road.interface';
import { CreatePartyRequest, CreatePartyResponse } from '@/interfaces/entities/jor-create-party.interface';
import { JORGoogleMapService } from '@/services/store/jor-google-map.service';
import { JoinOnRoadProduct } from '@/interfaces/entities/jor-product.interface';
import { RoadsideAssistanceService as _roadsideAssistanceService } from '@/services/store/roadside-assistance.service';
import { AccountServices as _account } from '@/services/store/account.services';
import vehicleService from '@/services/store/vehicle.service';
import {
  AssistanceMatchPartyRequest,
  AssistanceMatchPartyResponse,
  PartyMatchStatus,
} from '@/interfaces/entities/roadside-assistance.interface';
import { dateToDateString } from '@/utils/data-transforms';
import { IVehicleEligibility } from '@/interfaces/entities/vehicle-eligibility.interface';
import {
  CreateQuoteRequest,
  CreateQuoteResponse,
  SurchargeLevels,
} from '@/interfaces/entities/jor-create-quote.interface';
import { IReviewSummary, IReviewSummaryItem } from '@/interfaces';
import { FinaliseQuoteResponse } from '@/interfaces/entities/jor-finalise-quote.interface';
import { createRoadsideJobRequest } from '@/forms/join-on-road/mappings/join-on-road-mappings';
import { calculateAge } from '@/utils/common-helper';
import { useAnalyticsStore } from '../analytics/analytics.state';
import {
  IAnalyticsForm,
  IAnalyticsPaymentDetail,
  IAnalyticsProfileInfo,
  IFormAnalytics,
} from '@/interfaces/forms/analytics-interface';
import { FormEvents } from '@/enums/form-events.enum';
import { useFormStore } from '../form/formStore';

export const useJoinOnRoadStore = defineStore('JoinOnRoad', () => {
  const analyticsStore = useAnalyticsStore();
  const formStore = useFormStore();

  // State
  const joinOnRoadDetails = ref<JoinOnRoadDetails>({
    breakdownLocationLatitude: 0,
    breakdownLocationLongitude: 0,
    breakdownLocationAddress: '',
    comments: '',
    vehicleRegistration: '',
    vehicleYear: '',
    vehicleMake: '',
    vehicleModel: '',
    vehicleColour: '',
    isSearchByRego: false,
    phoneNumber: '',
    homeAddress: undefined,
    checkPostalAddress: false,
    postalAddress: undefined,
    phoneNumberToday: '',
    breakdownCause: '',
    title: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    birthDate: new Date(),
    jorSelectedProduct: undefined,
    finalProductPrice: 0,
    partyMatchStatus: undefined,
  });

  const joinOnRoadGoogleMapDetails = ref<JoinOnRoadGoogleMapDetails>({
    apiKey: '',
    mapId: '',
    defaultZoomLevel: 0,
    tipText: '',
  });

  const joinOnRoadVehicleEligibility = ref<IVehicleEligibility | null>();

  const joinOnRoadProducts = ref<JoinOnRoadProduct[]>([]);
  const baseProductCode = 'AR007'; /* base product is RACQ Everyday - AR007 */
  const nonMemberSurchargeProductCode = 'ARIS';
  const memberSurchageProductCode = 'ARIS01';
  const skipYourDetailsPhoneAddressStep = ref<boolean | null>(null);
  const isPartyMatched = ref<boolean>(false);
  const isPartyExitsOrCreated = ref<boolean>(false);
  const isQuoteCreated = ref<boolean>(false);
  const apttusId = ref<string | null>(null);
  const d365Id = ref<string | null>(null);
  const apttusCartId = ref<string | null>(null);
  const apttusQuoteId = ref<string | null>(null);
  const finalQuotePrice = ref<number | null>(null);
  const isQuoteFinalised = ref<boolean>(false);
  const assetId = ref<string | null>(null);
  const assetLineItemId = ref<string | null>(null);
  const prn = ref<string | null>(null);
  const invoiceId = ref<string | null>(null);
  const policyNumber = ref<string | null>(null);
  const surchargeProductPrice = computed(() =>
    isPartyMatched.value ? getProductPrice(memberSurchageProductCode) : getProductPrice(nonMemberSurchargeProductCode)
  );
  const surchargeProductMemberDiscount = computed(
    () => getProductPrice(nonMemberSurchargeProductCode) - getProductPrice(memberSurchageProductCode)
  );
  const coreProductPrice = computed(() => getProductPrice(joinOnRoadDetails.value?.jorSelectedProduct));
  const totalProductPrice = computed(() => surchargeProductPrice.value + coreProductPrice.value);
  const isBaseProduct = computed(() => joinOnRoadDetails.value.jorSelectedProduct === baseProductCode);
  const productTitle = computed(() => getProductTitle(joinOnRoadDetails.value?.jorSelectedProduct));
  const matchPartyState = ref<AssistanceMatchPartyResponse | null>(null);
  const creditCardDetails = ref<Record<string, string>>({});
  const paymentReceipt = ref<{
    result: string;
    transactionId: string;
    reference: string;
  }>();
  const rsaTrackingId = ref<string | null>(null);

  const createQuoteAttemptCount = ref<number>(0);
  const reviewSummaryItemEdit = ref<IReviewSummaryItem>();
  const reviewSummaryEdit = ref<IReviewSummary>();
  const shouldAdjustYourDetailsStep = ref<boolean>(false); // Ref to track the adding/removing of yourdetails (with phone number and home/postal address fields) step
  const {
    vehicleRegistration,
    vehicleYear,
    vehicleMake,
    vehicleModel,
    vehicleColour,
    firstName,
    lastName,
    emailAddress,
  } = toRefs(joinOnRoadDetails.value);
  const birthDate = computed(() => dateToDateString(joinOnRoadDetails.value?.birthDate, 'ddMMyyyy'));

  // Getters
  const getJoinOnRoadDetails = computed(() => joinOnRoadDetails.value);
  const getJoinOnRoadGoogleMapDetails = computed(() => joinOnRoadGoogleMapDetails.value);

  // Actions
  async function retrieveJORGoogleMapsDetails() {
    joinOnRoadGoogleMapDetails.value.apiKey = await JORGoogleMapService.getJORGoogleMapsApiKey();
    joinOnRoadGoogleMapDetails.value.mapId = await JORGoogleMapService.getJORGoogleMapsMapId();
    joinOnRoadGoogleMapDetails.value.defaultZoomLevel = await JORGoogleMapService.getJORGoogleMapsDefaultZoomLevel();
    joinOnRoadGoogleMapDetails.value.tipText = await JORGoogleMapService.getJORGoogleMapsTipText();
  }

  function setBreakdownLocation(latitude: number, longitude: number, formattedAddress: string) {
    joinOnRoadDetails.value.breakdownLocationLatitude = latitude;
    joinOnRoadDetails.value.breakdownLocationLongitude = longitude;
    joinOnRoadDetails.value.breakdownLocationAddress = formattedAddress;
  }

  function isBreakdownLocationSet() {
    return (
      joinOnRoadDetails.value.breakdownLocationLatitude !== 0 &&
      joinOnRoadDetails.value.breakdownLocationLongitude !== 0
    );
  }

  function setComments(comments: string) {
    joinOnRoadDetails.value.comments = comments;
  }

  function setReviewSummaryEditClicked(summary: IReviewSummary, summaryReviewItem: IReviewSummaryItem) {
    reviewSummaryEdit.value = summary;
    reviewSummaryItemEdit.value = summaryReviewItem;
  }

  function clearComments() {
    joinOnRoadDetails.value.comments = '';
  }

  function setVehicleDetails(
    vehicleRegistration: string,
    vehicleYear: string,
    vehicleMake: string,
    vehicleModel: string,
    vehicleColour: string,
    isSearchByRego: boolean
  ) {
    joinOnRoadDetails.value.vehicleRegistration = vehicleRegistration;
    joinOnRoadDetails.value.vehicleYear = vehicleYear;
    joinOnRoadDetails.value.vehicleMake = vehicleMake;
    joinOnRoadDetails.value.vehicleModel = vehicleModel;
    joinOnRoadDetails.value.vehicleColour = vehicleColour;
    joinOnRoadDetails.value.isSearchByRego = isSearchByRego;
  }

  async function setVehicleEligibility(glassesId: number) {
    const vehicleEligibility = await vehicleService.getEligibility(glassesId);
    joinOnRoadVehicleEligibility.value = vehicleEligibility;
  }

  function clearVehicleDetails() {
    joinOnRoadDetails.value.vehicleRegistration = '';
    joinOnRoadDetails.value.vehicleYear = '';
    joinOnRoadDetails.value.vehicleMake = '';
    joinOnRoadDetails.value.vehicleModel = '';
    joinOnRoadDetails.value.vehicleColour = '';
    joinOnRoadDetails.value.isSearchByRego = false;
  }

  async function setJORProducts(): Promise<boolean> {
    const jorProducts: JoinOnRoadProduct[] | null = await _joinOnRoadService.getJORProducts();
    if (jorProducts === null) {
      return false;
    }

    /*
    Set the type property based on productCode.
    ARIS, ARIS01 - Surcharge
    Rest of the ones -Product
    */

    const surchargeProductCodes = new Set([nonMemberSurchargeProductCode, memberSurchageProductCode]);
    joinOnRoadProducts.value = jorProducts.map((jorProduct) => {
      return {
        ...jorProduct,
        type: surchargeProductCodes.has(jorProduct.productCode) ? 'surcharge' : 'product',
      };
    });

    return true;
  }

  function setDefaultJORProduct() {
    const defaultProductCode = baseProductCode;
    if (
      joinOnRoadProducts.value.length > 0 &&
      joinOnRoadProducts.value.some((jorProduct) => jorProduct.productCode === defaultProductCode)
    ) {
      joinOnRoadDetails.value.jorSelectedProduct = defaultProductCode;
    }
  }

  function updateJORProductSelection(productCode: string) {
    if (
      joinOnRoadProducts.value.length > 0 &&
      joinOnRoadProducts.value.some(
        (jorProduct) => jorProduct.productCode === productCode && jorProduct.type === 'product'
      )
    ) {
      joinOnRoadDetails.value.jorSelectedProduct = productCode;
    }
  }

  function getProductTitle(productCode: string | undefined): string {
    return joinOnRoadProducts.value?.find((jorProduct) => jorProduct.productCode === productCode)?.productName ?? '';
  }

  function getProductPrice(productCode: string | undefined): number {
    return joinOnRoadProducts.value?.find((jorProduct) => jorProduct.productCode === productCode)?.price ?? 0;
  }

  function setFinalProductPrice() {
    joinOnRoadDetails.value.finalProductPrice = totalProductPrice.value;
  }

  async function setMatchPartyState(): Promise<boolean> {
    try {
      const matchPartyRequest: AssistanceMatchPartyRequest = {
        firstName: joinOnRoadDetails.value?.firstName,
        lastName: joinOnRoadDetails.value?.lastName,
        dateOfBirth: dateToDateString(joinOnRoadDetails.value.birthDate, 'yyyy-MM-dd'),
        emailAddress: joinOnRoadDetails.value?.emailAddress,
      };
      const response = await _roadsideAssistanceService.matchParty(matchPartyRequest);
      matchPartyState.value = response;
      return response !== null;
    } catch {
      return false;
    }
  }

  async function createParty() {
    if (!isPartyMatched.value && !isPartyExitsOrCreated.value) {
      const response: CreatePartyResponse | null = await _joinOnRoadService.CreateParty(mapToCreatePartyRequest());
      if (response) {
        apttusId.value = response.apttusId;
        d365Id.value = response.d365Id;
        isPartyExitsOrCreated.value = true;
      }
    }
  }

  function mapToCreatePartyRequest(): CreatePartyRequest {
    const homeAddress = joinOnRoadDetails.value.homeAddress;
    const postalAddress = joinOnRoadDetails.value.postalAddress;
    const request: CreatePartyRequest = {
      title: joinOnRoadDetails.value.title,
      firstName: joinOnRoadDetails.value?.firstName,
      lastName: joinOnRoadDetails.value?.lastName,
      dateOfBirth: dateToDateString(joinOnRoadDetails.value.birthDate, 'yyyy-MM-dd'),
      emailAddress: joinOnRoadDetails.value?.emailAddress,
      preferredPhoneNumber: joinOnRoadDetails.value.phoneNumber,
      membershipType: productTitle.value,
      homeAddress: {
        dpid: homeAddress?.dpid,
        unit: homeAddress?.unitNumber,
        streetNumber: homeAddress?.streetNumber,
        street: homeAddress?.street,
        suburb: homeAddress?.suburb,
        postcode: homeAddress?.postcode,
        state: homeAddress?.state,
        country: homeAddress?.countryISO,
      },
      postalAddress: {
        postalDeliveryType: postalAddress?.postalDeliveryType,
        dpid: postalAddress?.dpid,
        unit: postalAddress?.unitNumber,
        streetNumber: postalAddress?.streetNumber,
        street: postalAddress?.street,
        suburb: postalAddress?.suburb,
        postcode: postalAddress?.postcode,
        state: postalAddress?.state,
        country: postalAddress?.countryISO,
      },
    };
    return request;
  }

  async function createQuote() {
    const maxCreateQuoteAttempts = 2;
    if (
      apttusId.value &&
      isPartyExitsOrCreated.value &&
      !isQuoteCreated.value &&
      createQuoteAttemptCount.value < maxCreateQuoteAttempts
    ) {
      createQuoteAttemptCount.value += 1;
      const response: CreateQuoteResponse | null = await _joinOnRoadService.CreateQuote(mapToCreateQuoteRequest());
      if (!response) {
        invalidateQuote();
      } else if (response.isError) {
        invalidateQuote();
        createQuote();
      } else if (!response.isError) {
        apttusCartId.value = response.apttusCartId;
        apttusQuoteId.value = response.apttusQuoteId;
        finalQuotePrice.value = response.price ?? null;
        createQuoteAttemptCount.value = 0;
        isQuoteCreated.value = true;
      }
    }
  }

  function mapToCreateQuoteRequest(): CreateQuoteRequest {
    const vehicleDetail = joinOnRoadVehicleEligibility.value?.vehicleDetail;
    const request: CreateQuoteRequest = {
      productCode: joinOnRoadDetails.value.jorSelectedProduct ?? '',
      productName: productTitle.value,
      apttusId: apttusId.value ?? '',
      surchargeLevel: isPartyMatched.value ? SurchargeLevels.Member : SurchargeLevels.NonMember,
      vehicle: {
        nvic: vehicleDetail?.nvic,
        kerbWeight: vehicleDetail?.kerbWeight,
        kerbWeightUnit: vehicleDetail?.kerbWeightUnit,
        length: vehicleDetail?.length,
        lengthUnit: vehicleDetail?.lengthUnit,
        weight: vehicleDetail?.weight,
        weightUnit: vehicleDetail?.weightUnit,
        loadCapacity: vehicleDetail?.loadCapacity,
        loadCapacityUnit: vehicleDetail?.loadCapacityUnit,
        registrationNumber: vehicleDetail?.registrationNumber ?? joinOnRoadDetails.value.vehicleRegistration,
        manufacturingYear: vehicleDetail?.manufacturingYear ?? Number(joinOnRoadDetails.value.vehicleYear),
        stateCode: vehicleDetail?.stateCode ?? joinOnRoadDetails.value.postalAddress?.state,
        body: {
          code: vehicleDetail?.body.code,
          description: vehicleDetail?.body.description,
          colour: vehicleDetail?.body.colour ?? joinOnRoadDetails.value.vehicleColour,
        },
        make: {
          code: vehicleDetail?.make.code,
          description: vehicleDetail?.make.description ?? joinOnRoadDetails.value.vehicleMake,
        },
        model: {
          code: vehicleDetail?.model.code,
          description: vehicleDetail?.model.description ?? joinOnRoadDetails.value.vehicleModel,
        },
        variant: {
          code: vehicleDetail?.variant.code,
          description: vehicleDetail?.variant.description,
        },
        option: {
          transmission: vehicleDetail?.option.transmission,
        },
        fuelType: {
          description: vehicleDetail?.fuelType.code,
        },
      },
    };
    return request;
  }

  async function finaliseQuote(): Promise<boolean> {
    try {
      if (apttusQuoteId.value !== null && apttusCartId.value !== null) {
        const response: FinaliseQuoteResponse | null = await _joinOnRoadService.FinaliseQuote(
          apttusQuoteId.value ?? '',
          apttusCartId.value ?? ''
        );
        if (!response || response.isError) {
          invalidateQuoteFinalising();
          throw new Error('API_FAILED');
        } else if (!response.isError) {
          assetId.value = response.assetId;
          prn.value = response.prn;
          invoiceId.value = response.invoiceLineItem.invoiceId;
          isQuoteFinalised.value = true;
          assetLineItemId.value = response.invoiceLineItem.id;
          policyNumber.value = response.policyNumber;
        }
        return true;
      } else {
        throw new Error('MISSING_PARAMS');
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  function invalidateParty() {
    apttusId.value = d365Id.value = null;
    isPartyMatched.value = isPartyExitsOrCreated.value = false;
  }

  function invalidateQuote() {
    apttusCartId.value = apttusQuoteId.value = finalQuotePrice.value = null;
    isQuoteCreated.value = false;
  }

  function invalidateQuoteFinalising() {
    assetId.value = prn.value = invoiceId.value = assetLineItemId.value = null;
    isQuoteFinalised.value = false;
  }

  const partyMatchStatusMap = {
    [PartyMatchStatus.Exact]: PartyMatchStatus.Exact,
    [PartyMatchStatus.None]: PartyMatchStatus.None,
    [PartyMatchStatus.Multiple]: PartyMatchStatus.Multiple,
  };

  watch(matchPartyState, (newVal) => {
    if (newVal) {
      invalidateParty();
      invalidateQuote();
      if (newVal !== null) {
        // Assign the party match status
        joinOnRoadDetails.value.partyMatchStatus =
          partyMatchStatusMap[newVal.matchStatus as PartyMatchStatus] || undefined;

        skipYourDetailsPhoneAddressStep.value = isPartyMatched.value = newVal.matchStatus === PartyMatchStatus.Exact;
        if (newVal.matchStatus === PartyMatchStatus.Exact) {
          apttusId.value = newVal?.apttusId ?? null;
          d365Id.value = newVal?.d365Id ?? null;
          isPartyExitsOrCreated.value = isPartyMatched.value = true;
        }
      } else {
        skipYourDetailsPhoneAddressStep.value = null;
      }
    }
  });

  // Change in product selection - invalidate quote
  watch(productTitle, (newVal) => {
    if (newVal) {
      invalidateQuote();
      createQuoteAttemptCount.value = 0;
    }
  });

  // Change in vehicle information - invalidate quote
  watch([vehicleRegistration, vehicleYear, vehicleMake, vehicleModel, vehicleModel], (newValues, oldValues) => {
    if (newValues.some((val, index) => val !== oldValues[index])) {
      invalidateQuote();
      createQuoteAttemptCount.value = 0;
    }
  });

  // Change in personal information - invalidate party and quote
  watch([firstName, lastName, emailAddress, birthDate], (newValues, oldValues) => {
    if (newValues.some((val, index) => val !== oldValues[index])) {
      shouldAdjustYourDetailsStep.value = true;
      invalidateParty();
      invalidateQuote();
    }
  });

  const mapPaymentDetails = (formData: Record<string, any>) => {
    creditCardDetails.value = {
      cardToken: formData.cardToken,
      cardHolder: formData.cardHolder,
      maskedCardNumber: formData.MaskedCardNumber,
      cardExpiry: formData.cardExpiry,
      cardType: formData.cardType,
      autoRenew: formData.autoRenew,
    };
  };

  const submitPurchase = async () => {
    try {
      const result = await _joinOnRoadService.submitPurchase({
        // Standard Fields
        cardToken: creditCardDetails.value.cardToken,
        autoRenew: creditCardDetails.value.autoRenew,
        prn: prn.value,
        amount: finalQuotePrice.value,
        invoiceId: invoiceId.value,
        apttusId: apttusId.value,
        // AutoRenew Exclusive Fields
        cardHolder: creditCardDetails.value.cardHolder,
        MaskedCardNumber: creditCardDetails.value.maskedCardNumber,
        cardExpiry: creditCardDetails.value.cardExpiry,
        cardType: creditCardDetails.value.cardType,
        assetId: assetId.value,
      });

      paymentReceipt.value = result;
      return result;
    } catch (error) {
      return null;
    }
  };

  const createRoadsideJob = async (loyaltyLevel: string) => {
    try {
      const rsaRequest = await createRoadsideJobRequest(
        joinOnRoadDetails.value,
        apttusId.value,
        policyNumber.value,
        assetId.value,
        loyaltyLevel
      );

      const result = await _roadsideAssistanceService.createJob(rsaRequest);

      if (result?.jobId) {
        // Set the trackingId if successful
        rsaTrackingId.value = result.jobId;
      }
      return result;
    } catch (error) {
      return null;
    }
  };

  const isUnderAge = (birthDate: Date): boolean | null => {
    try {
      const minAge = 21;
      return calculateAge(birthDate) < minAge;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const pushAnalytics = (options: {
    event: FormEvents;
    form: IAnalyticsForm;
    profileInfo?: IAnalyticsProfileInfo;
    paymentDetail?: Partial<IAnalyticsPaymentDetail>;
  }) => {
    const model: IFormAnalytics = {
      event: options.event,
      form: options.form,
    };
    if (options.profileInfo) {
      model.profileInfo = options.profileInfo;
    }
    if (options.paymentDetail) {
      model.payment = options.paymentDetail;
    }
    model.form.name = formStore.formName;

    analyticsStore.pushAnalytics(model);
  };

  return {
    joinOnRoadDetails,
    joinOnRoadGoogleMapDetails,
    joinOnRoadVehicleEligibility,
    joinOnRoadProducts,
    baseProductCode,
    surchargeProductPrice,
    surchargeProductMemberDiscount,
    coreProductPrice,
    totalProductPrice,
    isBaseProduct,
    productTitle,
    matchPartyState,
    creditCardDetails,
    skipYourDetailsPhoneAddressStep,
    isPartyMatched,
    isPartyExitsOrCreated,
    isQuoteCreated,
    apttusId,
    d365Id,
    apttusCartId,
    apttusQuoteId,
    finalQuotePrice,
    isQuoteFinalised,
    assetId,
    paymentReceipt,
    rsaTrackingId,
    assetLineItemId,
    prn,
    invoiceId,
    policyNumber,
    shouldAdjustYourDetailsStep,
    getJoinOnRoadDetails,
    getJoinOnRoadGoogleMapDetails,
    reviewSummaryEdit,
    reviewSummaryItemEdit,
    retrieveJORGoogleMapsDetails,
    setBreakdownLocation,
    isBreakdownLocationSet,
    setComments,
    clearComments,
    setVehicleDetails,
    clearVehicleDetails,
    setJORProducts,
    setDefaultJORProduct,
    updateJORProductSelection,
    getProductPrice,
    getProductTitle,
    setFinalProductPrice,
    setMatchPartyState,
    createParty,
    createQuote,
    finaliseQuote,
    mapPaymentDetails,
    submitPurchase,
    setVehicleEligibility,
    setReviewSummaryEditClicked,
    createRoadsideJob,
    isUnderAge,
    pushAnalytics,
  };
});
