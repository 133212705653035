<template>
  <div class="border-2 border-solid rounded-sm px-5 py-2">
    <div class="row pt-1 pb-3">
      <div class="col-12 border-b">
        <div class="text-base-bold pt-1 pb-3">
          {{ policyHolder.salutation.value }} {{ policyHolder.firstname.value }} {{ policyHolder.surname.value }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 border-b">
        <div class="text-sm-bold inline-flex">Contact number</div>
        <div class="text-sm pt-1 pb-3">{{ policyHolder.telephoneNumber.value }}</div>
      </div>
    </div>
    <div class="row pt-1 pb-3">
      <div class="col-12 border-b">
        <div class="text-sm-bold inline-flex">Email</div>
        <div class="text-sm pt-1 pb-3">{{ policyHolder.emailAddress.value }}</div>
      </div>
    </div>
    <div class="row pt-1 pb-3">
      <div class="col-12 border-b">
        <div class="text-sm-bold inline-flex">Address</div>
        <div class="text-sm pt-1 pb-3">{{ formattedAddress(policyHolder) }}</div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { IPolicyHolderDetails } from '@/interfaces';

const props = defineProps<{
  policyHolder: IPolicyHolderDetails;
}>();

const formattedAddress = (policyHolder: IPolicyHolderDetails) => {
  return `${policyHolder.streetNumber.value} ${policyHolder.street.value}, ${policyHolder.suburb.value}, ${policyHolder.state.value}, ${policyHolder.postcode.value}, ${policyHolder.country.value}`;
};
</script>
