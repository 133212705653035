import { IPaymentReferenceDetails } from '../make-a-payment/make-a-payment.interface';
import { IDecisionIntelligenceOfferResponse } from './decision-intelligence-response';

export interface ICreateDecisionOffer {
  decisionCriteria: IDecisionCriteria;
  filters?: IFilters;
}

export interface IDecisionCriteria {
  sessionContext: ISessionContext;
  partyReferences: IPartyReferences;
  formInputs: IFormInputs;
}

export interface ISessionContext {
  channel: string;
  strategy: string;
  pillar: string;
  product: string;
  journey: string;
  triggerStep?: string;
  sourceIp?: string;
  deviceType: string;
}

export interface IPartyReferences {
  d365Id?: string;
  apttusId?: string;
}

export interface IFormInputs {
  postCode?: string | null;
  firstName: string | null;
  lastName: string | null;
  birthDate?: string | null;
  numberOfClaims?: string | null;
  quoteReference?: string | null;
  annualPremium?: number | null;
  coverType?: string | null;
  vehicle?: IVehicle | null;
  prn?: string | null;
  policyNumber?: string | null;
  renewalDate?: string | null;
  home?: IHome | null;
  existingMember?: boolean | null;
  hasRoadsideAssistance?: boolean | null;
  homeInsuranceClaimed?: boolean | null;
  contentInsuranceClaimed?: boolean | null;
  driverUnder25?: boolean | null;
  occupancyType?: string | null;
  homeLoan?: boolean | null;
  petCover?: boolean | null;
  membershipLevel?: string | null;
  eligibleForCombinedPolicyDiscount?: boolean | null;
}

export interface IFilters {
  maximumNumberOfNudges: number;
  maximumNumberOfPropositions: number;
  maximumNumberOfItems: number;
}

export interface IVehicle {
  vehicleRegistrationNumber: string;
  vehicleMake?: string;
  vehicleModel?: string;
  vehicleSeries?: string;
  vehicleYear?: number;
  vehicleValue?: string;
  usageType: string;
  fuelType: string;
  driveType: string;
}

export interface IHome {
  postCode?: string;
  dpid?: string;
  yearBuilt?: string;
  occupancyType?: string;
  buildingType?: string;
  homeLoan?: boolean;
  homeLoanProvider?: string;
  optionalPetCover?: boolean;
}

export interface ICreateDecisionOfferResponse {
  id: string;
  createdDate: string;
}

export interface IAcknowledgeDecisionRequest {
  propositionId?: string;
  outcome: string;
  policyNumber?: string;
  d365Id?: string;
  quoteReference?: string;
  apttusId?: string;
  prn?: string;
  actionId?: number | null;
  leadId?: string;
}

export enum Outcome {
  Discarded,
  Surfaced,
  Accepted,
  Completed,
  LeadCaptured,
  LeadFailed,
  Redirect,
}

export interface IRenewalAcknowledgementModel {
  paymentReferenceDetails?: IPaymentReferenceDetails;
  decisionOfferResponse?: IDecisionIntelligenceOfferResponse;
  paymentAttempted: boolean;
  paymentSucceeded: boolean;
  productName?: string;
}

export interface ICreateRoadsidePrepaidOfferRequest {
  apttusId: string;
  assetLineItemId: string;
  prn: string;
  currentProduct: IOfferProductQuoteRequest;
  renewalOptions: IOfferProductQuoteRequest[];
}

export interface IOfferProductQuoteRequest extends IOfferProductRequest {
  apttusCartId: string;
  apttusQuoteId: string;
  amount: number | null;
}

export interface IOfferProductRequest {
  product: string;
  description: string;
  productCode: string;
}

export interface IRenewalAcknowledgmentCrossSellModel {
  decisionOfferResponse?: IDecisionIntelligenceOfferResponse;
  acknowledgeDecisionRequest?: IAcknowledgeDecisionRequest;
  paymentReferenceDetails?: IPaymentReferenceDetails;
}
