import { cancelRoadsideAssistanceJob } from '@/constants/forms-api.const';
import { RequestServices as _http } from '@/services/common/request.services';
import { CancelJobRequest, CancelRoadsideAssistanceJob } from '@/interfaces/pages/cancel-roadside-form.interface';

export const CancelRoadsideAssistanceFormService = {
  /**
   * Cancel Roadside Assistance Job
   * @param payload
   */
  async cancelRoadsideAsssitanceJob(payload: CancelJobRequest): Promise<CancelRoadsideAssistanceJob> {
    return await _http.HttpPost(cancelRoadsideAssistanceJob, payload);
  },
} as const;
