import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/apple-app-store-button.svg'
import _imports_1 from '@/assets/icons/google-play-store-button.svg'


const _hoisted_1 = { class: "grid grid-cols-1 w-full gap-6" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "flex flex-col md:flex-row items-start gap-3" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "flex flex-row items-center" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]

import { computed, ref } from 'vue';
import SectionFrame from '@/components/SectionFrame/SectionFrame.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TrackPatrol',
  props: {
    trackYourPatrolTitle: {},
    trackYourPatrolDescription: {},
    appDownloadDescription: {},
    appleDownloadLink: {},
    googleDownloadLink: {},
    trackingNumber: {}
  },
  setup(__props: any) {

const props = __props;

const localTrackingNumber = ref<string>(props.trackingNumber);
const buttonText = ref<string>('Copy number');
const isCopied = ref(false);
const buttonIcon = computed(() =>
  isCopied.value
    ? new URL('@/assets/icons/green-success-icon.svg', import.meta.url).href
    : new URL('@/assets/icons/copy-icon.svg', import.meta.url).href
);

const copyTrackingNumber = async () => {
  try {
    await navigator.clipboard.writeText(localTrackingNumber.value);
    buttonText.value = 'Copied';
    isCopied.value = true;
    setTimeout(() => {
      buttonText.value = 'Copy number';
      isCopied.value = false;
    }, 2000);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createBlock(SectionFrame, {
    sectionTitle: _ctx.trackYourPatrolTitle,
    titleClasses: "text-xl-bold"
  }, {
    sectionFrameContent: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", {
          class: "text-base",
          innerHTML: _ctx.trackYourPatrolDescription
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _cache[0] || (_cache[0] = _createElementVNode("label", {
            for: "tracking-number",
            class: "mb-1 formkit-label block text-input-label w-auto md:pe-8"
          }, " Tracking number ", -1)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_FormKit, {
              id: "tracking-number",
              ref: "trackingNumber",
              modelValue: _ctx.trackingNumber,
              type: "text",
              outerClass: "mb-0 sm:w-full md:w-[240px] !opacity-100",
              name: "tracking-number",
              innerClass: "w-full",
              inputClass: "!text-current",
              disabled: ""
            }, null, 8, ["modelValue"]),
            _createElementVNode("button", {
              type: "button",
              class: "cta-card-button px-3 py-2 text-base btn-md btn btn-secondary w-full md:w-auto",
              onClick: _withModifiers(copyTrackingNumber, ["stop","prevent"])
            }, [
              _createElementVNode("img", {
                src: buttonIcon.value,
                alt: isCopied.value ? 'success icon' : 'copy icon',
                role: "img",
                class: "inline-block h-4 w-4"
              }, null, 8, _hoisted_5),
              _createTextVNode(" " + _toDisplayString(buttonText.value), 1)
            ])
          ])
        ]),
        _createElementVNode("p", { innerHTML: _ctx.appDownloadDescription }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.appleDownloadLink)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.appleDownloadLink,
              target: "_blank",
              class: "inline-block mr-3"
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "apple app store icon",
                class: "inline-block"
              }, null, -1)
            ]), 8, _hoisted_8))
          : _createCommentVNode("", true),
        (_ctx.googleDownloadLink)
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: _ctx.googleDownloadLink,
              target: "_blank",
              class: "inline-block"
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("img", {
                src: _imports_1,
                alt: "google play store icon",
                class: "inline-block"
              }, null, -1)
            ]), 8, _hoisted_9))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["sectionTitle"]))
}
}

})