import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full bg-racq-navy py-4 px-6 flex relative md:rounded-t" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = {
  key: 2,
  class: "text-racq-navy bg-white rounded-full min-w-[28px] !leading-[30px] w-[28px] h-[28px] text-center mr-3 text-base-bold bg-step-completed-icon"
}
const _hoisted_5 = ["textContent"]

import { computed } from 'vue';
import { IFormStep } from '@/interfaces';
import { useFormStore } from '@/store';
import { uniqBy } from 'lodash-es';

// Define Vue props
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'FormHeader',
  props: {
    step: {},
    stepIndex: {}
  },
  setup(__props: any) {

const props = __props;

// Initialise Pinia store/s
// ------------------------------------------------------------- //
const formStore = useFormStore();

// Computed Properties
// ------------------------------------------------------------- //
const stepNumber = computed(() => {
  const uniqSteps = uniqBy(formStore.inScopeSteps, (step) => step.stepGroupName || step.stepTitle);
  return (
    uniqSteps.findIndex(
      (step) =>
        (props.step.stepGroupName && step.stepGroupName == props.step.stepGroupName) ||
        step.stepTitle == props.step.stepTitle
    ) + 1
  );
});

// Functions
// ------------------------------------------------------------- //
const removeStep = () => {
  formStore.removeStep(props.step.stepIndex!);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.step.hideHeaderNumber)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : ((stepNumber.value || _ctx.stepIndex) && !_ctx.step.isComplete)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "text-racq-navy bg-white rounded-full min-w-[28px] !leading-[30px] w-[28px] h-[28px] text-center mr-3 text-base-bold",
            textContent: _toDisplayString(stepNumber.value || _ctx.stepIndex)
          }, null, 8, _hoisted_3))
        : (_ctx.step.isComplete)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4))
          : _createCommentVNode("", true),
    _createElementVNode("h2", {
      class: "text-white text-xl",
      textContent: _toDisplayString(_ctx.step.stepTitle)
    }, null, 8, _hoisted_5),
    (_ctx.step.isRemovable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 3,
          type: "button",
          class: "link text-white ms-auto text-m no-underline hover:underline active:text-white focus:outline-white",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (removeStep()))
        }, " REMOVE "))
      : _createCommentVNode("", true)
  ]))
}
}

})