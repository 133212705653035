import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex flex-col gap-8 md:gap-6" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "text-base-bold" }

import { onMounted, ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { IDropdownItem, IRadioButtonCard, IRiskSuburbLookupOption } from '@/interfaces';
import { INameValue } from '@/interfaces/entities/name-value.interface';
import { useMultiInstanceAddressOptionsStore, useFormStore } from '@/store';
import { isPostalAddress } from '@/utils/regex-helpers';
import { joinAddressComponents } from '@/mappers';
import { addressLookupEmits, emitRiskSuburbSelected, useAddressLookupStore } from '@/store';
import { isEmptyObject } from '@/utils/check-helpers';
import { AUSTRALIA } from '@/constants/general-words.const';


export default /*@__PURE__*/_defineComponent({
  __name: 'ManualAddressForm',
  props: {
    name: {},
    defaultIsOverseasOption: { type: Boolean },
    showManualEntries: { type: Boolean },
    streetNoRequired: {},
    streetRequired: {},
    suburbRequired: {},
    stateRequired: {},
    postcodeRequired: {},
    countryRequired: {},
    checkAddressRequired: {},
    selectSuburbRequired: {},
    postcodeInvalid: {},
    invalidInsuranceRiskSuburbMessage: {},
    riskSuburbsLabel: {},
    instanceIdentifier: {}
  },
  emits: addressLookupEmits,
  setup(__props: any, { emit: __emit }) {

const props = __props;

const manualEditButtonRef = ref(null);
const suburbLookupOptionsRef = ref(null);

const selectedRiskSuburb = ref<string>();
const states = ref<IDropdownItem[]>([]);
const countries = ref<IDropdownItem[]>([]);
const suburbs = ref<IRadioButtonCard[]>([]);

const formStore = useFormStore();
const addressLookupStore = useAddressLookupStore();

const multiInstanceAddressStore = useMultiInstanceAddressOptionsStore();
const addressLookupOptionsStore = multiInstanceAddressStore.getInstance(props.instanceIdentifier);

const translation = useI18n();

const emit = __emit;

const suburbLookupOptions = computed({
  get() {
    return addressLookupOptionsStore.suburbLookupOptions || [];
  },
  set(newValue) {
    addressLookupOptionsStore.suburbLookupOptions = newValue;
  },
});

onMounted(async () => {
  const dict: Record<string, string> = translation?.getLocaleMessage('en') as any;
  if (dict?.INPUT_ADDRESS_STATE) {
    states.value = dict.INPUT_ADDRESS_STATE.split(',').map((item) => ({ label: item, value: item }));
  }

  if (addressLookupOptionsStore.isOverseasSelection) {
    countries.value = await lookupCountry();
  }
});

function riskAddressSelected(selected: any) {
  if (!selected) return;
  const selectedOption = JSON.parse(selected);
  addressLookupOptionsStore.manualAddressComponents.suburb = selectedOption.suburb;
  addressLookupOptionsStore.manualAddressComponents.state = selectedOption.state;
  addressLookupOptionsStore.manualAddressComponents.country = selectedOption.country;

  //Do this to keep the selected value shown with tick/confirmation as per UX feedback.
  suburbLookupOptions.value = [convertAddressComponentToRiskSuburb()];

  const selectedFromList = suburbs.value.find(
    (x) =>
      cleanRiskAddressForComparison(x.leftTitle) ===
      cleanRiskAddressForComparison(
        addressLookupOptionsStore.buildAddressLine(addressLookupOptionsStore.manualAddressComponents)
      )
  );

  if (!isEmptyObject(addressLookupOptionsStore.manualAddressComponents) && selectedFromList) {
    emit(emitRiskSuburbSelected, addressLookupOptionsStore.manualAddressComponents);
    addressLookupOptionsStore.onRiskSuburbSelected();
  }
}

function cleanRiskAddressForComparison(address: string) {
  return address.replaceAll(',', '').replaceAll(' ', '');
}

/**
 * Handles when the manual address is set, here we utilise the suburb lookup options to show selection confirmed.
 */
watch(
  () => addressLookupOptionsStore.manualAddressComponents,
  async (newValue) => {
    if (newValue) {
      const riskSuburb = convertAddressComponentToRiskSuburb();

      if (
        riskSuburb.suburb &&
        riskSuburb.postcode &&
        (riskSuburb.state || addressLookupOptionsStore.isOverseasSelection)
      ) {
        suburbLookupOptions.value = [riskSuburb];
        selectedRiskSuburb.value =
          suburbLookupOptions.value.length > 0 ? JSON.stringify(suburbLookupOptions.value[0]) : '';
      }
    }
  },
  { once: true }
);

watch(
  () => addressLookupOptionsStore.isOverseasSelection,
  async (newValue) => {
    if (newValue && countries.value.length == 0) {
      countries.value = await lookupCountry();
    }
  }
);

watch(suburbLookupOptions, (newValue) => {
  suburbs.value = newValue.map((option) => ({
    leftTitle: joinAddressComponents({
      ...addressLookupOptionsStore.manualAddressComponents,
      ...option,
    }),
    value: JSON.stringify(option),
    showLeftRadioButton: true,
    groupName: '',
  }));
});

async function lookupCountry(): Promise<IDropdownItem[]> {
  try {
    const data: INameValue[] = await addressLookupStore.getCountryLookup('', true);
    return (
      data?.map((item: INameValue) => ({
        label: item.name,
        value: item.value,
      })) || []
    );
  } catch {
    return [];
  }
}

function convertAddressComponentToRiskSuburb(checkCountry?: boolean): IRiskSuburbLookupOption {
  if (checkCountry && !addressLookupOptionsStore.isOverseasSelection) {
    addressLookupOptionsStore.manualAddressComponents.country = AUSTRALIA;
  }

  return {
    suburb: addressLookupOptionsStore.manualAddressComponents?.suburb,
    postcode: addressLookupOptionsStore.manualAddressComponents?.postcode,
    state: addressLookupOptionsStore.manualAddressComponents?.state,
    country: addressLookupOptionsStore.manualAddressComponents?.country,
  } as IRiskSuburbLookupOption;
}

async function checkAddress() {
  // Reset suburb options
  selectedRiskSuburb.value = undefined;
  suburbLookupOptions.value = [];

  //If in 'Edit address' state enable the form and exit.
  if (!addressLookupOptionsStore.manualEditEnabled) {
    addressLookupOptionsStore.toggleManualFormDisabled();
    return;
  }

  if (addressLookupOptionsStore.isOverseasSelection) {
    validateOverseasAddressAndEmit();
    return;
  } else {
    addressLookupOptionsStore.manualAddressComponents.country = AUSTRALIA;
    addressLookupOptionsStore.manualAddressComponents.countryISO = '';
  }

  const riskSelectionMode = (await checkAddressInsuranceRiskSuburb()) ?? false;
  if (riskSelectionMode) return;

  if (isPostalAddress(addressLookupOptionsStore.manualAddressComponents.street ?? '')) {
    if (addressLookupOptionsStore?.config?.allowPhysicalAddressesOnly) {
      addressLookupOptionsStore.manualAddressComponents.street = '';
      if (!isEmptyObject(addressLookupOptionsStore.manualAddressComponents)) {
        emit(emitRiskSuburbSelected, addressLookupOptionsStore.manualAddressComponents);
      }
    }
  } else {
    addressLookupOptionsStore.manualAddressComponents.addressLine = addressLookupOptionsStore.joinAddressLine(
      addressLookupOptionsStore.manualAddressComponents
    );
    addressLookupOptionsStore.useAddress = addressLookupOptionsStore.manualAddressComponents;

    if (!isEmptyObject(addressLookupOptionsStore.manualAddressComponents)) {
      emit(emitRiskSuburbSelected, addressLookupOptionsStore.manualAddressComponents);
    }
  }
}

async function checkAddressInsuranceRiskSuburb() {
  formStore.showLoadingForCurrentStep();

  const results = await addressLookupOptionsStore.checkAddressInsuranceRiskSuburb(true);

  if (addressLookupOptionsStore?.config?.allowValidInsuranceRiskSuburbsOnly) {
    if (!addressLookupOptionsStore.manualEditEnabled && manualEditButtonRef.value) {
      (manualEditButtonRef.value as any).node.setErrors(results?.match ? '' : props.invalidInsuranceRiskSuburbMessage);
    }
  }

  if (results?.match) {
    //UX even when there is a single match we want to show the list and prompt to select the address.
    suburbLookupOptions.value = [results?.match];
    addressLookupOptionsStore.onRiskSuburbSelected();
  } else {
    suburbLookupOptions.value = results?.riskSuburbs ?? [];
    selectedRiskSuburb.value = undefined;
  }

  formStore.showLoadingForCurrentStep(false);

  const selectRiskMode = !results?.match && results && results.riskSuburbs.length > 0;
  return selectRiskMode;
}

function validateOverseasAddressAndEmit() {
  if (addressLookupOptionsStore.manualAddressComponents) {
    if (addressLookupOptionsStore.isOverseasSelection) {
      const validCountry = countries.value.find(
        (x) => x.value === addressLookupOptionsStore.manualAddressComponents.country
      );

      if (
        validCountry &&
        addressLookupOptionsStore.manualAddressComponents.suburb &&
        addressLookupOptionsStore.manualAddressComponents.suburb.length > 0
      ) {
        //Do this to keep the selected value shown with tick/confirmation as per UX feedback.
        suburbLookupOptions.value = [convertAddressComponentToRiskSuburb()];
        emit(emitRiskSuburbSelected, addressLookupOptionsStore.manualAddressComponents);
        addressLookupOptionsStore.toggleManualFormDisabled();
      }
    }
  }
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_unref(addressLookupOptionsStore).isManualEntries || _unref(addressLookupOptionsStore).isOverseasSelection)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_FormKit, {
          modelValue: _unref(addressLookupOptionsStore).manualAddressComponents,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(addressLookupOptionsStore).manualAddressComponents) = $event)),
          type: "form",
          onSubmit: _cache[1] || (_cache[1] = ($event: any) => (checkAddress())),
          name: _ctx.name + '_manual',
          disabled: !_unref(addressLookupOptionsStore).manualEditEnabled,
          actions: false,
          incompleteMessage: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_FormKit, {
                "data-testid": "unitNumber",
                name: "unitNumber",
                validationLabel: "unitNo",
                maxLength: "100",
                label: "Unit number (optional)",
                innerClass: "input-xs"
              }),
              _createVNode(_component_FormKit, {
                "data-testid": "streetNumber",
                name: "streetNumber",
                validationLabel: "Street No.",
                label: "Street number",
                maxLength: "100",
                validation: "required",
                messageClass: "md:!pl-[0px]",
                validationMessages: { required: _ctx.streetNoRequired },
                innerClass: "input-xs"
              }, null, 8, ["validationMessages"]),
              _createVNode(_component_FormKit, {
                "data-testid": "street",
                name: "street",
                validationLabel: "Street",
                label: "Street",
                maxLength: "100",
                validation: "required",
                messageClass: "md:!pl-[0px]",
                outerClass: "col-span-2",
                validationMessages: { required: _ctx.streetRequired },
                innerClass: "input-md"
              }, null, 8, ["validationMessages"]),
              _createVNode(_component_FormKit, {
                "data-testid": "suburb",
                name: "suburb",
                validationLabel: "Suburb",
                label: "Suburb",
                maxLength: "100",
                validation: "required",
                messageClass: "md:!pl-[0px]",
                outerClass: "col-span-2",
                validationMessages: { required: _ctx.suburbRequired },
                innerClass: "input-md"
              }, null, 8, ["validationMessages"]),
              (!_unref(addressLookupOptionsStore).isOverseasSelection)
                ? (_openBlock(), _createBlock(_component_FormKit, {
                    key: 0,
                    "data-testid": "state",
                    name: "state",
                    validationLabel: "State",
                    type: "dropdown",
                    options: states.value,
                    label: "State",
                    validation: "required",
                    messagesClass: "md:ml-[0px] md:pl-[0px]",
                    validationMessages: { required: _ctx.stateRequired },
                    innerClass: "input-xs"
                  }, null, 8, ["options", "validationMessages"]))
                : _createCommentVNode("", true),
              (!_unref(addressLookupOptionsStore).isOverseasSelection)
                ? (_openBlock(), _createBlock(_component_FormKit, {
                    key: 1,
                    "data-testid": "postcode",
                    name: "postcode",
                    maxLength: "4",
                    validationLabel: "Postcode",
                    validation: [['required'], ['matches', /\d{4,4}/]],
                    label: "Postcode",
                    messageClass: "md:!pl-[0px]",
                    type: "tel",
                    validationMessages: { required: _ctx.postcodeRequired, matches: _ctx.postcodeInvalid },
                    innerClass: "input-xs"
                  }, null, 8, ["validationMessages"]))
                : (_openBlock(), _createBlock(_component_FormKit, {
                    key: 2,
                    name: "postcode",
                    validationLabel: "Postcode",
                    "data-testid": "postcode",
                    label: "Postcode (Optional)",
                    maxLength: "100",
                    outerClass: "col-span-2",
                    innerClass: "input-xs"
                  })),
              (_unref(addressLookupOptionsStore).isOverseasSelection)
                ? (_openBlock(), _createBlock(_component_FormKit, {
                    key: 3,
                    "data-testid": "country",
                    name: "country",
                    validationLabel: "Country",
                    maxLength: "100",
                    label: "Country",
                    placeholder: "Country",
                    validation: "required",
                    validationMessages: { required: _ctx.countryRequired },
                    messageClass: "md:!pl-[0px]",
                    outerClass: "col-span-2",
                    innerClass: "input-xs",
                    options: countries.value,
                    type: "autocomplete",
                    openOnFocus: "",
                    openOnClick: ""
                  }, null, 8, ["validationMessages", "options"]))
                : _createCommentVNode("", true),
              _createVNode(_component_FormKit, {
                ref_key: "manualEditButtonRef",
                ref: manualEditButtonRef,
                type: "submit",
                "data-testid": "btnCheckAddress",
                label: _unref(addressLookupOptionsStore).manualEditEnabled ? 'Check address' : 'Edit address',
                outerClass: "col-span-4",
                wrapperClass: "$reset",
                disabled: false,
                inputClass: "btn btn-md btn-primary btn-edit"
              }, null, 8, ["label"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "name", "disabled"]),
        (_unref(addressLookupOptionsStore).manualEditEnabled && !_unref(addressLookupOptionsStore).isOverseasSelection)
          ? (_openBlock(), _createBlock(_component_FormKit, {
              key: 0,
              validation: "required",
              wrapperClass: "hidden",
              title: "checkAddressValidation",
              validationMessages: { required: _ctx.checkAddressRequired }
            }, null, 8, ["validationMessages"]))
          : _createCommentVNode("", true),
        (suburbLookupOptions.value?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[3] || (_cache[3] = _createElementVNode("hr", { class: "input-spacing" }, null, -1)),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.riskSuburbsLabel), 1),
              (suburbLookupOptions.value?.length)
                ? (_openBlock(), _createBlock(_component_FormKit, {
                    key: 0,
                    ref_key: "suburbLookupOptionsRef",
                    ref: suburbLookupOptionsRef,
                    modelValue: selectedRiskSuburb.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedRiskSuburb).value = $event)),
                    onInput: riskAddressSelected,
                    outerClass: "mt-4",
                    options: suburbs.value,
                    validation: "required",
                    title: "suburbLookup",
                    validationMessages: { required: _ctx.selectSuburbRequired },
                    type: "radiobuttoncardgroup"
                  }, null, 8, ["modelValue", "options", "validationMessages"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})