import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 place-items-center" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]

import Alert from '@/components/Alert/Alert.vue';
import { IImage } from '@/interfaces/forms/image-interface';
import { IAlert } from '@/interfaces/forms/alert-interface';
import InformationBox from '@/components/InformationBox/InformationBox.vue';
import { IInformationBox } from '@/interfaces/forms/information-box.interface';


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmationHeader',
  props: {
    topAlert: {},
    informationBox: {},
    icon: {},
    title: {},
    subTitle: {},
    totalAmountSummary: {},
    policyOrReferenceNumber: {},
    receiptNumber: {},
    paymentReferenceNumber: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.topAlert)
      ? (_openBlock(), _createBlock(Alert, {
          key: 0,
          alert: _ctx.topAlert,
          class: "mb-6"
        }, null, 8, ["alert"]))
      : _createCommentVNode("", true),
    (_ctx.informationBox)
      ? (_openBlock(), _createBlock(InformationBox, _mergeProps({ key: 1 }, _ctx.informationBox, { class: "mb-6" }), null, 16))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.icon.fileName,
        alt: _ctx.icon.alt,
        role: "img",
        class: _normalizeClass([
        'mb-2',
        _ctx.icon.rounded === undefined || _ctx.icon.rounded ? 'rounded-full' : '',
        _ctx.icon.width ? `w-[${_ctx.icon.width}px]` : 'w-[80px]',
        _ctx.icon.height ? `h-[${_ctx.icon.height}px]` : 'h-[80px]',
      ])
      }, null, 10, _hoisted_2),
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "text-xl2-bold pb-3 text-center",
            innerHTML: _ctx.title
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.subTitle)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "text-lg pb-6 text-center",
            innerHTML: _ctx.subTitle
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.totalAmountSummary)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "text-xl3-bold pb-5 text-center",
            innerHTML: _ctx.totalAmountSummary
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.policyOrReferenceNumber)
        ? (_openBlock(), _createElementBlock("span", {
            key: 3,
            class: "inline-flex items-center rounded-lg p-4 mb-2 text-m ring-1 ring-inset ring-gray-500/10",
            innerHTML: _ctx.policyOrReferenceNumber
          }, null, 8, _hoisted_6))
        : _createCommentVNode("", true),
      (_ctx.paymentReferenceNumber)
        ? (_openBlock(), _createElementBlock("span", {
            key: 4,
            class: "inline-flex items-center rounded-lg bg-racq-anti-flash-white p-4 mb-2 text-sm text-gray-600 ring-1 ring-inset ring-gray-500/10",
            innerHTML: _ctx.paymentReferenceNumber
          }, null, 8, _hoisted_7))
        : _createCommentVNode("", true),
      (_ctx.receiptNumber)
        ? (_openBlock(), _createElementBlock("span", {
            key: 5,
            class: "inline-flex items-center rounded-lg bg-racq-anti-flash-white p-4 mb-2 text-sm text-gray-600 ring-1 ring-inset ring-gray-500/10",
            innerHTML: _ctx.receiptNumber
          }, null, 8, _hoisted_8))
        : _createCommentVNode("", true)
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("hr", { class: "bg-racq-white" }, null, -1))
  ], 64))
}
}

})