export interface CreateRoadsideJobRequest {
  membership: Membership;
  triageAnswers: TriageAnswers;
  vehicle: RequestVehicle;
  channel: string;
  comments: string[];
  policyNumber: string;
}

export interface Membership {
  membershipCardNumber: string;
  contact: Contact;
  firstName: string;
  lastName: string;
  address: string;
  loyaltyLevel?: string;
  roadsideProductId: string;
}

export interface Contact {
  phone: string;
}

export interface RequestVehicle extends Vehicle {
  location: Location;
  batteries?: string;
}

export interface Vehicle {
  make: string;
  model: string;
  buildYear?: number; // YYYY
  colour: string;
  registration: string;
  registrationState?: RegistrationState;
}

export interface Location {
  latitude?: number;
  longitude?: number;
  locationTimestamp?: Date;
  comment: string;
  heading?: number;
  velocity?: number;
}

export enum RegistrationState {
  ACT = 'ACT',
  NSW = 'NSW',
  VIC = 'VIC',
  QLD = 'QLD',
  TAS = 'TAS',
  WA = 'WA',
  SA = 'SA',
  NT = 'NT',
}

export interface CreateRoadsideJobResponse {
  eventId: string;
  jobId: string;
  assistanceId?: string;
  membership: RsaMembership;
  triageAnswers: TriageAnswers;
  roadsideAssistanceStatus: RsaStatusState;
}

export interface RsaStatus {
  dispatchGroup: string;
  estimatedArrivalTimestamp?: Date;
  statusUpdateTimestamp?: Date;
  state: RsaStatusState;
}

export interface RsaStatusState {
  type: string;
}

export interface RsaMembership {
  membershipId: string;
  contact: Contact;
}

export interface TriageAnswers {
  [key: string]: string[];
}

export interface Address {
  dpid?: string;
  addressType?: string;
  unit?: string;
  streetNumber?: string;
  street?: string;
  suburb?: string;
  postcode?: string;
  state?: string;
  country?: string;
  unitType?: string;
  buildingLevel?: string;
  formattedStreet?: string;
  formattedLong?: string;
}

const RoadsideProductCodeToProductIdMap: { [key: string]: string } = {
  AR007: '2363', // RACQ Everyday
  AR008: '2364', // RACQ Everyday Plus
  AR009: '2365', // RACQ Ultra
  AR010: '2366', // RACQ Ultimate
};

export function getRoadsideProductCodeToProductId(roadsideProductCode: string): string | undefined {
  return RoadsideProductCodeToProductIdMap[roadsideProductCode];
}
