import { differenceInYears, isAfter } from 'date-fns';

export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function calculateAge(birthDate: Date): number {
  const today = new Date();

  // Validate that the birthDate is not in the future
  if (isAfter(birthDate, today)) {
    throw new Error('Birth date cannot be in the future');
  }

  return differenceInYears(today, birthDate);
}
