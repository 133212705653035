<template>
  <div :class="classes" class="px-5 py-4 rounded justify-start items-center gap-4 flex">
    <img
      v-if="alert.showIcon && icon"
      :src="alert.customIcon?.fileName ? alert.customIcon.fileName : icon.fileName"
      :alt="icon?.alt"
      role="img"
      class="w-5 h-5 relative"
    />
    <div class="grow shrink basis-0 text-base-bold">
      {{ alert.text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { AlertTypes } from '@/enums/alert-types-enum';
import { IAlert } from '@/interfaces/forms/alert-interface';

const props = defineProps<{
  alert: IAlert;
}>();

const classes = computed(() => getTypeClasses());
const icon = computed(() => getIcon());

const getTypeClasses = () => {
  switch (props.alert.alertType) {
    case AlertTypes.info:
      return 'text-white bg-racq-alert-info';
    case AlertTypes.success:
      return 'text-white bg-racq-alert-success';
    case AlertTypes.warning:
      return 'text-racq-dark-grey bg-racq-alert-warning';
    case AlertTypes.danger:
      return 'text-white bg-racq-alert-danger';
  }
  return '';
};

const getIcon = () => {
  let icon = null;
  switch (props.alert.alertType) {
    case AlertTypes.info:
      icon = { fileName: require(`@/assets/icons/info-icon-white.svg`), alt: 'Information icon' };
      break;
    case AlertTypes.success:
      icon = { fileName: require(`@/assets/icons/success-icon-white.svg`), alt: 'Tick icon' };
      break;
    case AlertTypes.warning:
      icon = { fileName: require(`@/assets/icons/warning-icon-black.svg`), alt: 'Warning icon' };
      break;
    case AlertTypes.danger:
      icon = { fileName: require(`@/assets/icons/danger-icon-white.svg`), alt: 'Danger icon' };
      break;
  }

  return icon;
};
</script>
