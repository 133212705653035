import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-8 md:gap-6 md:mb-10" }
const _hoisted_2 = {
  key: 0,
  class: "text-racq-dark-grey text-xl2-bold md:text-xl2-bold mb-5"
}
const _hoisted_3 = {
  key: 1,
  class: "text-racq-dark-grey text-lg-bold mb-2"
}
const _hoisted_4 = {
  key: 2,
  class: "text-paragraph-color richtext"
}
const _hoisted_5 = {
  key: 3,
  class: "mt-8 md:mt-6"
}
const _hoisted_6 = {
  key: 4,
  class: "border-b border-solid border-racq-pale-blue-border mt-10 md:mt-8 mb-2"
}

import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';
import InformationBox from '@/components/InformationBox/InformationBox.vue';
import { InformationBoxTypes } from '@/interfaces/forms/information-box.interface';


export default /*@__PURE__*/_defineComponent({
  __name: 'Disclaimer',
  props: {
    bindings: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bindings.sections, (section, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (section.fields?.ContentHeading?.value)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_2, [
              _createVNode(_unref(Text), {
                field: section.fields.ContentHeading
              }, null, 8, ["field"])
            ]))
          : _createCommentVNode("", true),
        (section.fields?.ContentSubheading?.value)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_3, [
              _createVNode(_unref(Text), {
                field: section.fields.ContentSubheading
              }, null, 8, ["field"])
            ]))
          : _createCommentVNode("", true),
        (section.fields?.ContentBody?.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_unref(RichText), {
                field: section.fields.ContentBody
              }, null, 8, ["field"])
            ]))
          : _createCommentVNode("", true),
        (section.fields?.InfoText?.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(InformationBox, {
                description: section.fields?.InfoText?.value,
                type: _unref(InformationBoxTypes).INFO
              }, null, 8, ["description", "type"])
            ]))
          : _createCommentVNode("", true),
        (section.fields?.IsBottomBorder?.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}
}

})