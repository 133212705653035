import i18n from './i18n';

/**
 * Configuration object for genesys chat with custom extensions.
 * For built-in config options refer to this documentation:
 * https://docs.genesys.com/Documentation/GWC/Current/Deployment/GWCConfig
 */
export default {
  main: {
    // This is controlled by html element that contains the class .live-chat it merges with data-properties
    // which is then controlled by Sitecore under website/Settings/LiveChatConfiguration (Debug mode)
    debug: true,
    themes: {
      racq: 'cx-theme-racq',
    },
    theme: 'racq',
    lang: 'en',
    i18n: i18n,
    mobileMode: 'auto',
    plugins: ['cx-webchat', 'cx-webchat-service']
  },
  webchat: {
    dataURL: 'https://gms-c1.gsn.cloud/genesys/2/chat/racq-v1',
    userData: {},
    actionsMenu: true,
    emojis: false,
    inviteOnRestoreTimeout: false,
    proactive: {
      enabled: false,
      idleTimer: 10,
      cancelTimer: 30,
    },
    chatButton: {
      enabled: true,
      template: false,
      openDelay: 0,
      hideDuringInvite: true,
    },
    minimizeOnMobileRestore: true,
  },
  checkChat: {
    url: 'https://gms-c1.gsn.cloud/genesys/1/service/racq-checkchat?_category=',
    defaultCategory: 'LD Training',
  },
};
