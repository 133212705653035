import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import {
  IDecisionIntelligenceOfferResponse,
  IGetOfferRequestResponse,
  IOfferQuoteData,
} from '@/interfaces/decision-intelligence/decision-intelligence-response';
import {
  IAcknowledgeDecisionRequest,
  ICreateDecisionOffer,
  ICreateDecisionOfferResponse,
  IRenewalAcknowledgementModel,
} from '@/interfaces/decision-intelligence/decision-intelligence-request';
import { DecisionIntelligenceService } from '@/services/store/decision-intelligence-service';
import { Item } from '@sitecore-jss/sitecore-jss/layout';
import { IDropdownItem, IQuote } from '@/interfaces';
import { IAdobeDataLayerLead, ICreateLeadRequestModel } from '@/interfaces/lead/lead-model';
import { DecisionContentTypes } from '@/enums/decision-intelligence.enum';
import { useFormStepDisclaimerStore } from '../form-step-disclaimer/form-step-disclaimer-store.state';
import { useFeatureTogglesStore } from '../feature-toggles/featureTogglesStore';
import {
  ENABLE_CROSSSELL,
  ENABLE_PROPOSITIONS,
  ENABLE_RENEWAL_CROSSSELLPROPOSITIONS,
  ENABLE_RENEWAL_PROPOSITIONS,
  ENABLE_ROADSIDE_PREPAY_OFFER,
} from '@/constants/feature-toggles';
import {
  mapDecisionIntelligenceRenewalRequest,
  mapDecisionIntelligenceRequest,
  mapRSAPrepayRequest,
} from '@/mappers/decision-intelligence-mapper';
import { useAnalyticsStore } from '../analytics/analytics.state';
import { IPaymentReferenceDetails } from '@/interfaces/make-a-payment/make-a-payment.interface';
import { FormEvents } from '@/enums/form-events.enum';
import {
  CommonConfigSettings,
  SettingCategory,
  useConfigSettingsStore,
} from '../common-config-settings/config-settings.state';
import { useMakeAPaymentStore } from '../make-a-payment/make-a-payment.state';
import { sleep } from '@/utils/common-helper';
import { toNumber } from 'lodash-es';
export const useDecisionIntelligenceStore = defineStore('DecisionIntelligenceStore', () => {
  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //
  const decisionOfferResponse = ref<IDecisionIntelligenceOfferResponse>();
  const decisionOfferCrossSellResponse = ref<IDecisionIntelligenceOfferResponse>();
  const createDecisionOfferResponse = ref<ICreateDecisionOfferResponse>();
  const createDecisionOfferCrossSellResponse = ref<ICreateDecisionOfferResponse>();
  const createRSAPrepayOferResponse = ref<ICreateDecisionOfferResponse>();
  const rsaPrepayOffer = ref<IGetOfferRequestResponse>();
  const isDIOfferLoading = ref<boolean>(false);
  const decisionOfferContent = ref<Partial<Item>>({});
  const formStepdisclaimerStore = useFormStepDisclaimerStore();
  const featureTogglesStore = useFeatureTogglesStore();
  const analyticsStore = useAnalyticsStore();
  const commonConfigSettingsStore = useConfigSettingsStore();
  const makeAPaymentStore = useMakeAPaymentStore();
  // ------------------------------------------------------------- //
  // Computed
  // ------------------------------------------------------------- //
  const contentFields = computed(() =>
    (decisionOfferContent?.value?.fields as any)?.find(
      (a: any) => a.name == DecisionContentTypes.DecisionIntelligenceCrossSellOffers
    )
  );

  const contactTimes = computed(() =>
    (decisionOfferContent?.value?.fields as any)?.find((a: any) => a.name == DecisionContentTypes.ContactUsTimes)
  );

  const emailTimes = computed(() =>
    (decisionOfferContent?.value?.fields as any)?.find((a: any) => a.name == DecisionContentTypes.EmailTimes)
  );

  // ------------------------------------------------------------- //
  // Methods
  // ------------------------------------------------------------- //
  const isDIFeatureToggleEnabled = async (isCrossSell: boolean, isRenewal: boolean): Promise<boolean> => {
    let isDIFeatureEnabled = false;
    if (isRenewal) {
      isDIFeatureEnabled = isCrossSell
        ? await featureTogglesStore.isFeatureToggleEnabled(ENABLE_RENEWAL_PROPOSITIONS)
        : await featureTogglesStore.isFeatureToggleEnabled(ENABLE_RENEWAL_CROSSSELLPROPOSITIONS);
    } else {
      isDIFeatureEnabled = isCrossSell
        ? await featureTogglesStore.isFeatureToggleEnabled(ENABLE_CROSSSELL)
        : await featureTogglesStore.isFeatureToggleEnabled(ENABLE_PROPOSITIONS);
    }
    return isDIFeatureEnabled;
  };
  /**
   * create decision offfer request
   * @param request
   */
  const callDecisionIntelligenceOfferRequest = async (request: ICreateDecisionOffer, isCrossSell: boolean) => {
    try {
      const response = await DecisionIntelligenceService.createDecisionIntelligenceOffer(request);
      if (isCrossSell) {
        createDecisionOfferCrossSellResponse.value = response;
      } else {
        createDecisionOfferResponse.value = response;
      }
    } catch (error) {
      console.error('Error getting offer', error);
    }
  };

  /**
   * Get deicision by decision id
   * @param decisionId
   * @returns
   */
  const callGetDecisionOffer = async (
    decisionId: string,
    isQuote: boolean,
    isCrossSell: boolean,
    isRenewal?: boolean
  ) => {
    try {
      const response = await DecisionIntelligenceService.getDecisionIntelligenceOffer(decisionId);
      if (isCrossSell) {
        decisionOfferCrossSellResponse.value = response;
      } else {
        decisionOfferResponse.value = response;
      }
      analyticsStore.pushDecisionIntelligenceAnalytics({ event: FormEvents.RESPONSE, data: response });
      formStepdisclaimerStore.setDIDisclaimers(response, isQuote, isCrossSell, isRenewal);
      return response;
    } catch (error) {
      console.error('Error getting offer', error);
    }
  };
  /**
   * Get decision offer request through form step
   */
  const getDecisionOffer = async (isCrossSell: boolean, isQuote: boolean, isRenewal?: boolean) => {
    try {
      if (await isDIFeatureToggleEnabled(isCrossSell, isRenewal ?? false)) {
        await callGetDecisionOffer(
          isCrossSell
            ? (createDecisionOfferCrossSellResponse.value?.id ?? '')
            : (createDecisionOfferResponse.value?.id ?? ''),
          isQuote,
          isCrossSell,
          isRenewal
        );
      }
    } catch (error) {
      console.error('Error creating offer request', error);
    }
  };

  /**
   * Create decision offer request for renewal
   * @param paymentReferenceDetails payment reference details
   * @param isQuote
   * @param isCrossSell
   * @param stepTitle
   */
  const createDecisionOfferRenewal = async (
    paymentReferenceDetails: IPaymentReferenceDetails,
    isCrossSell: boolean,
    stepTitle?: string
  ) => {
    try {
      const isFeatureToggleEnabled = await isDIFeatureToggleEnabled(isCrossSell, true);
      if (isFeatureToggleEnabled) {
        const decisionRequest = mapDecisionIntelligenceRenewalRequest(paymentReferenceDetails, isCrossSell, stepTitle);
        analyticsStore.pushDecisionIntelligenceAnalytics({ event: FormEvents.REQUEST, data: decisionRequest });
        await callDecisionIntelligenceOfferRequest(decisionRequest, isCrossSell);
      }
    } catch (error) {
      console.error('Error creating offer request', error);
    }
  };

  /**
   * Create decision offer request
   * @param quote quote object
   * @param isQuote
   * @param isCrossSell
   * @param stepTitle
   */
  const createDecisionOffer = async (
    quote: Partial<IQuote>,
    isQuote: boolean,
    isCrossSell: boolean,
    stepTitle?: string
  ) => {
    try {
      const isFeatureToggleEnabled = await isDIFeatureToggleEnabled(isCrossSell, false);
      if (isFeatureToggleEnabled) {
        const decisionRequest = mapDecisionIntelligenceRequest(quote, isCrossSell, isQuote, stepTitle);
        analyticsStore.pushDecisionIntelligenceAnalytics({ event: FormEvents.REQUEST, data: decisionRequest });
        await callDecisionIntelligenceOfferRequest(decisionRequest, isCrossSell);
      }
    } catch (error) {
      console.error('Error creating offer request', error);
    }
  };

  /**
   * Get decision intelligence content
   * @param content
   * @returns
   */
  const setDecisionIntelligenceContent = (content: Item) => {
    if (decisionOfferContent.value.id && decisionOfferContent.value.id != '') {
      return;
    }
    decisionOfferContent.value = content;
  };

  /**
   * Acknowledge decision offer id
   * @param decisionId decision Id
   * @param request
   */
  const acknowlegeDecisionOffer = async (decisionId: string, request: IAcknowledgeDecisionRequest) => {
    if (decisionId != '') {
      await DecisionIntelligenceService.acknowlegeDecisionOffer(decisionId, request);
    }
  };

  const acknowledgeRenewalDecisionOffer = async (request: IRenewalAcknowledgementModel) => {
    await DecisionIntelligenceService.acknowledgeRenewalDecisionOffer(request);
  };

  /**
   * Create lead in D365
   * @param d365Id
   * @param request
   */
  const createLead = async (d365Id: string, request: ICreateLeadRequestModel) => {
    const dataLayer = request as IAdobeDataLayerLead;
    try {
      const response = await DecisionIntelligenceService.createLead(d365Id, request);
      dataLayer.leadId = response.leadId;
      dataLayer.request = 'LeadCreated';
      analyticsStore.pushDecisionIntelligenceAnalytics({ event: FormEvents.FORM_SUBMIT, data: dataLayer });
      return response;
    } catch (error) {
      dataLayer.request = 'LeadFailed';
      analyticsStore.pushDecisionIntelligenceAnalytics({ event: FormEvents.FORM_SUBMIT, data: dataLayer });
      throw error;
    }
  };

  /**
   * Get options for email
   * @returns
   */
  const getEmailOptionsDropDown = (): IDropdownItem[] => {
    const emailOptions = [] as IDropdownItem[];
    if (emailTimes.value.fields?.length) {
      emailTimes.value.fields.forEach((element: any) => {
        emailOptions.push({
          label: element.listLabel.value,
          value: element.listValue.value,
        });
      });
    }
    return emailOptions;
  };
  /**
   * Get dropdown values for contact time
   * @returns
   */
  const getContactOptionsDropDown = (): IDropdownItem[] => {
    const emailOptions = [] as IDropdownItem[];
    if (contactTimes.value.fields?.length) {
      contactTimes.value.fields.forEach((element: any) => {
        emailOptions.push({
          label: element.listLabel.value,
          value: element.listValue.value,
        });
      });
    }
    return emailOptions;
  };

  const createRSAPrepayOffer = async (paymentReferenceDetails: IPaymentReferenceDetails) => {
    try {
      const rsaPrepayOfferEnabled = await featureTogglesStore.isFeatureToggleEnabled(ENABLE_ROADSIDE_PREPAY_OFFER);
      if (rsaPrepayOfferEnabled) {
        const request = mapRSAPrepayRequest(paymentReferenceDetails);
        const response = await DecisionIntelligenceService.createRsaPrepayOffer(request);
        createRSAPrepayOferResponse.value = response;
      }
    } catch (e) {
      console.error('Error creating prepay offer', e);
    }
  };

  const getRSAPrepayOffer = async () => {
    try {
      const rsaPrepayOfferEnabled = await featureTogglesStore.isFeatureToggleEnabled(ENABLE_ROADSIDE_PREPAY_OFFER);
      if (rsaPrepayOfferEnabled) {
        rsaPrepayOffer.value = await DecisionIntelligenceService.getRsaPrepayOffer(
          createRSAPrepayOferResponse.value?.id ?? ''
        );
      }
    } catch (error) {
      console.error('Error getting prepay offer', error);
    }
  };

  const processRenewalDecisionOfferRequest = async (stepTitle: string) => {
    if (makeAPaymentStore.paymentReferenceDetails.isInurance) {
      const delayTime = await commonConfigSettingsStore.getSetting(
        CommonConfigSettings.WaitForPropositionsOfferInSeconds,
        SettingCategory.CommonSettings
      );
      isDIOfferLoading.value = true;
      await createDecisionOfferRenewal(makeAPaymentStore.paymentReferenceDetails, false, stepTitle);

      await sleep(toNumber(delayTime) * 1000);
      getDecisionOffer(false, false, true);
      acknowledgeRenewalDecisionOffer({
        paymentReferenceDetails: makeAPaymentStore.paymentReferenceDetails,
        decisionOfferResponse: decisionOfferResponse.value,
        paymentAttempted: false,
        paymentSucceeded: false,
      });
      isDIOfferLoading.value = false;
    } else {
      if (
        !makeAPaymentStore.paymentReferenceDetails.notEligibleForUpgrade &&
        !makeAPaymentStore.paymentReferenceDetails.isNotARoadsideProduct
      ) {
        const delayTime = await commonConfigSettingsStore.getSetting(
          CommonConfigSettings.WaitForRoadsidePrepayOfferInSeconds,
          SettingCategory.CommonSettings
        );
        isDIOfferLoading.value = true;
        await createRSAPrepayOffer(makeAPaymentStore.paymentReferenceDetails);
        await sleep(toNumber(delayTime) * 1000);
        await getRSAPrepayOffer();
        isDIOfferLoading.value = false;
      }
    }
  };
  const processRenewalDecisionCrossSellOfferRequest = async (stepTitle?: string) => {
    const delayTime = await commonConfigSettingsStore.getSetting(
      CommonConfigSettings.WaitForPropositionsOfferInSeconds,
      SettingCategory.CommonSettings
    );
    if (makeAPaymentStore.paymentReferenceDetails.isInurance) {
      await createDecisionOfferRenewal(makeAPaymentStore.paymentReferenceDetails, true, stepTitle);

      await sleep(toNumber(delayTime) * 1000);
      await getDecisionOffer(true, false, true);
      await acknowledgeRenewalDecisionOffer({
        paymentReferenceDetails: makeAPaymentStore.paymentReferenceDetails,
        decisionOfferResponse: decisionOfferResponse.value,
        paymentAttempted: false,
        paymentSucceeded: false,
      });
    }
  };

  const getPrepayOfferProduct = (productCode: string): IOfferQuoteData => {
    if (rsaPrepayOffer.value?.offers?.length) {
      const currentOffer = rsaPrepayOffer.value.offers.filter((x) => x.data.quote.productCode == productCode)[0];
      if (currentOffer) {
        if (currentOffer.data.quote.discountAmount > 0) {
          return currentOffer.data.quote;
        }
      }
    }
    return {} as IOfferQuoteData;
  };

  const acknowledgePrepayOffer = async () => {
    if (
      rsaPrepayOffer.value?.offers?.length &&
      !makeAPaymentStore.paymentReferenceDetails.notEligibleForUpgrade &&
      !makeAPaymentStore.paymentReferenceDetails.isInurance
    ) {
      const isCurrentProduct =
        makeAPaymentStore.paymentReferenceDetails?.currentProduct?.apttusQuoteId ==
        makeAPaymentStore.selectedQuotedOption?.apttusQuoteId;
      if (!isCurrentProduct) {
        //only send prepay offer ack if it is upgrade
        DecisionIntelligenceService.acknowledgePrepayOffer(
          makeAPaymentStore.paymentReferenceDetails.prn,
          makeAPaymentStore.selectedQuotedOption?.apttusQuoteId ?? '',
          makeAPaymentStore.paymentReferenceDetails.apttusId
        );
      }
    }
  };

  const $reset = () => {
    decisionOfferCrossSellResponse.value = undefined;
    decisionOfferResponse.value = undefined;
    createDecisionOfferResponse.value = undefined;
    createDecisionOfferCrossSellResponse.value = undefined;
  };
  return {
    decisionOfferResponse,
    createDecisionOfferResponse,
    decisionOfferContent,
    decisionOfferCrossSellResponse,
    rsaPrepayOffer,
    createRSAPrepayOferResponse,
    createDecisionOfferCrossSellResponse,
    createDecisionOffer,
    setDecisionIntelligenceContent,
    contentFields,
    acknowlegeDecisionOffer,
    contactTimes,
    emailTimes,
    getEmailOptionsDropDown,
    getContactOptionsDropDown,
    createLead,
    getDecisionOffer,
    createDecisionOfferRenewal,
    acknowledgeRenewalDecisionOffer,
    createRSAPrepayOffer,
    $reset,
    getRSAPrepayOffer,
    isDIOfferLoading,
    processRenewalDecisionOfferRequest,
    processRenewalDecisionCrossSellOfferRequest,
    getPrepayOfferProduct,
    acknowledgePrepayOffer,
  };
});
