<template>
  <div>
    <slot name="introContent"></slot>
    <FormKit
      type="button"
      :label="expanded ? `${toggleButtonCollapseText}` : `${toggleButtonExpandText}`"
      inputClass="text-racq-navy mb-2"
      :suffixIcon="expanded ? 'up' : 'down'"
      @click="expanded = !expanded"
    />
    <div v-if="expanded">
      <slot name="conditionalContent"></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

defineProps<{
  toggleButtonExpandText: string;
  toggleButtonCollapseText: string;
}>();

const expanded = ref<boolean>(false);
</script>
