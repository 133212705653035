<template>
  <div class="flex flex-col gap-8 md:gap-6">
    <Alert
      v-if="fields.detailsUpdatedText?.value"
      :alert="{
        showIcon: true,
        alertType: AlertTypes.success,
        text: fields.detailsUpdatedText.value,
      }"
    />
    <div v-if="panelTitle?.value" class="text-racq-dark-grey text-xl-bold">
      <Text :field="panelTitle" />
    </div>
    <div v-if="confirmationDetailsText?.value" class="text-racq-med-grey richtext text-block">
      <RichText :field="confirmationDetailsText" />
    </div>
    <div v-if="viewModel.mdText?.value" class="text-racq-med-grey text-m text-block">
      <RichText :field="viewModel.mdText" />
    </div>
    <div v-if="viewModel.smallText?.value" class="text-racq-med-grey text-sm text-block">
      <RichText :field="viewModel.smallText" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { Text, RichText, Field } from '@sitecore-jss/sitecore-jss-vue';
import { QuotePremiumDetails } from '@/interfaces/entities/premium-details.interface';
import { TermPaymentDetails } from '@/interfaces/entities/term-payment-details.interface';
import { formatCurrency } from '@/utils/text-transforms';
import { format as formatDate } from 'date-fns';
import { AlertTypes } from '@/enums/alert-types-enum';
import Alert from '@/components/Alert/Alert.vue';

const props = defineProps<{
  fields: Record<string, Field<any>>;
  panelTitle: Field<any>;
  confirmationDetailsText: Field<any>;
  premiumDetails: QuotePremiumDetails;
  renewalTermPaymentDetails: TermPaymentDetails | undefined;
  policyInRenewal: boolean;
  renewalTermStart: Date | undefined;
  renewalTermEnd: Date | undefined;
  paymentReceiptNumber: string | undefined;
}>();

interface ViewModel {
  mdText: Field<any>;
  smallText?: Field<any>;
}

const viewModel = computed<ViewModel>(() => {
  if (props.policyInRenewal && props.renewalTermStart && props.renewalTermEnd) {
    if (props.premiumDetails.monthlyNonResponsivePremium) {
      return {
        mdText: {
          value: props.fields.inRenewalMonthlyNonResponsivePremiumChangeText.value
            .replace('{nextPayment}', formatCurrency(props.premiumDetails.monthlyNonResponsiveDeposit, 2))
            .replace('{regularMonthlyPayment}', formatCurrency(props.premiumDetails.monthlyNonResponsivePremium, 2))
            .replace(
              '{nextDeductionDate}',
              props.premiumDetails.nonResponsiveNextInvoiceDue
                ? formatDate(props.premiumDetails.nonResponsiveNextInvoiceDue, 'dd MMMM yyyy')
                : ''
            ),
        },
        smallText: {
          value: props.premiumDetails.annualNonResponsivePremium
            ? props.fields.inRenewalMonthlyNonResponsivePremiumChangeSmallText.value.replace(
                '{totalAnnualPremium}',
                formatCurrency(props.premiumDetails.annualNonResponsivePremium, 2)
              )
            : '',
        },
      };
    } else if (props.premiumDetails.annualNonResponsivePremium) {
      return {
        mdText: {
          value: props.fields.inRenewalAnnualNonResponsivePremiumChangeText.value.replace(
            '{totalDue}',
            formatCurrency(props.premiumDetails.annualNonResponsivePremium, 2)
          ),
        },
        smallText: {
          value: props.renewalTermPaymentDetails?.nextInvoiceDueDate
            ? props.fields.inRenewalAnnualNonResponsivePremiumChangeSmallText.value.replace(
                '{nextDeductionDate}',
                formatDate(props.renewalTermPaymentDetails.nextInvoiceDueDate, 'dd MMMM yyyy')
              )
            : '',
        },
      };
    } else if (props.premiumDetails.annualResponsiveAmountDue) {
      return {
        mdText: {
          value: props.fields.inRenewalResponsivePremiumChangeText.value.replace(
            '{amount}',
            formatCurrency(props.premiumDetails.annualResponsiveAmountDue, 2)
          ),
        },
        smallText: {
          value: props.fields.inRenewalResponsivePremiumChangeSmallText.value.replace(
            '{receiptNumber}',
            props.paymentReceiptNumber
          ),
        },
      };
    }
  } else {
    if (!props.premiumDetails.premiumChanged) {
      return {
        mdText: props.fields.premiumNotChangedText,
      };
    } else if (props.premiumDetails.monthlyNonResponsivePremium) {
      return {
        mdText: {
          value: props.fields.monthlyNonResponsivePremiumChangeText.value
            .replace('{nextPayment}', formatCurrency(props.premiumDetails.monthlyNonResponsiveDeposit, 2))
            .replace('{regularMonthlyPayment}', formatCurrency(props.premiumDetails.monthlyNonResponsivePremium, 2))
            .replace(
              '{nextDeductionDate}',
              props.premiumDetails.nonResponsiveNextInvoiceDue
                ? formatDate(props.premiumDetails.nonResponsiveNextInvoiceDue, 'dd MMMM yyyy')
                : ''
            ),
        },
        smallText: props.fields.monthlyNonResponsivePremiumChangeSmallText,
      };
    } else if (props.premiumDetails.annualRefund) {
      return {
        mdText: {
          value: props.fields.annualRefundText.value.replace(
            '{amount}',
            formatCurrency(props.premiumDetails.annualRefund, 2)
          ),
        },
        smallText: props.fields.annualRefundSmallText,
      };
    } else if (props.premiumDetails.annualNonResponsiveAmountDue) {
      return {
        mdText: {
          value: props.fields.annualNonResponsivePremiumChangeText.value.replace(
            '{amount}',
            formatCurrency(props.premiumDetails.annualNonResponsiveAmountDue, 2)
          ),
        },
        smallText: props.fields.annualNonResponsivePremiumChangeSmallText,
      };
    } else if (props.premiumDetails.annualResponsiveAmountDue) {
      return {
        mdText: {
          value: props.fields.annualResponsivePremiumChangeText.value.replace(
            '{amount}',
            formatCurrency(props.premiumDetails.annualResponsiveAmountDue, 2)
          ),
        },
        smallText: {
          value: props.fields.annualResponsivePremiumChangeSmallText.value.replace(
            '{receiptNumber}',
            props.paymentReceiptNumber
          ),
        },
      };
    } else if (!props.premiumDetails.monthlyNonResponsivePremium && props.premiumDetails.monthlyNonResponsiveDeposit) {
      return {
        mdText: {
          value: props.fields.monthlyNonResponsiveDepositText.value
            .replace('{nextPayment}', formatCurrency(props.premiumDetails.monthlyNonResponsiveDeposit, 2))
            .replace(
              '{nextDeductionDate}',
              props.premiumDetails.nonResponsiveNextInvoiceDue
                ? formatDate(props.premiumDetails.nonResponsiveNextInvoiceDue, 'dd MMMM yyyy')
                : ''
            ),
        },
        smallText: props.fields.monthlyNonResponsiveDepositSmallText,
      };
    }
  }
  throw new Error('State not mapped');
});
</script>
