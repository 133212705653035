import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex mobile-stepper items-center w-full lg:px-0"
}
const _hoisted_2 = { class: "flex-initial w-fit whitespace-nowrap font-sans font-normal text-racq-navy leading-normal text-base ms-auto me-4" }
const _hoisted_3 = { class: "me-auto flex py-0 h-2 w-full overflow-hidden bg-white rounded" }

import { IFormStep } from '@/interfaces';
import { computed, onMounted } from 'vue';
import { useFormStore } from '@/store';
import { uniqBy } from 'lodash-es';

// Initialise Composables
// -------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'FormStepperBar',
  props: {
    steps: {},
    isMock: { type: Boolean }
  },
  setup(__props: any) {

const formStore = useFormStore();

// Initialise Props
// -------------------------- //
const props = __props;

// Lifecycle Hooks
// -------------------------- //
onMounted(() => {
  if (props.steps && props.isMock) {
    formStore.setSteps(props.steps);
  }
});

// Computed Properties
// -------------------------- //
const isStepperVisible = computed(
  () => formStore.isStepperVisible && formStepperSteps.value.length > 1 && formStore.allPrelimStepsComplete
);
const currentActiveStepNumber = computed(() => {
  const activeStep = formStore.inScopeSteps.find((step) => step.isActive);
  return (
    formStepperSteps.value.findIndex(
      (step) =>
        (activeStep?.stepGroupName && step.stepGroupName == activeStep.stepGroupName) ||
        step.stepTitle == activeStep?.stepTitle
    ) + 1
  );
});

const formStepperSteps = computed(() => {
  const visibleSteps = formStore.inScopeSteps.filter((step) => !step.hideStepper);
  return uniqBy(visibleSteps, (step) => step.stepGroupName || step.stepTitle);
});

return (_ctx: any,_cache: any) => {
  return (isStepperVisible.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(currentActiveStepNumber.value) + " of " + _toDisplayString(formStepperSteps.value.length), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formStepperSteps.value, (step, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(["h-2 flex-1", currentActiveStepNumber.value >= index + 1 ? 'bg-racq-blue' : 'bg-racq-anti-flash-white'])
            }, null, 2))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})