import { defineStore } from 'pinia';
import { ref } from 'vue';
import { AddressLookupService as addressLookupStore } from '@/services/store';
import { INameValue } from '@/interfaces/entities/name-value.interface';

enum RefDataTypes {
  COUNTRIES = 'Countries',
}

export const useAddressLookupStore = defineStore('AddressLookupStore', () => {
  const addressData = ref<Partial<Record<RefDataTypes, INameValue[]>>>({});

  async function getCountryLookup(key: string, allCountries: boolean): Promise<INameValue[]> {
    if (addressData.value?.Countries?.length) return addressData.value.Countries;
    try {
      const res = await addressLookupStore.getCountryLookup(key, allCountries);
      addressData.value.Countries = res || [];
    } catch (error) {
      console.warn('Unable to fetch countries: ', error);
    }
    return addressData.value.Countries || [];
  }

  return {
    getCountryLookup,
  };
});
