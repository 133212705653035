import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "find-your-car-wrapper flex flex-col gap-3" }
const _hoisted_2 = { class: "text-black px-2 py-1 hover:bg-racq-light-blue-grey" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import { ref, onBeforeMount, reactive, watch } from 'vue';
import useVehicleData from '@/composables/useVehicleData';
import type { IDropdownItem, IFoundVehicle } from '@/interfaces';
import type { IVehicleOptions, IVehicleLoadingOptions } from '@/composables/useVehicleData';
import { FormKit } from '@formkit/vue';
import { DROPDOWN_PLACEHOLDER } from '@/constants/general-words.const';

// Define types and interfaces
// ------------------------------------------------------------- //
type vehicleSelectionTypes = 'year' | 'make' | 'model' | 'series' | 'body';

// Define Props & Emitters
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'FindYourCar',
  props: {
    topMakes: {},
    showColour: { type: Boolean },
    colourLabel: {},
    yearLabel: {},
    makeLabel: {},
    modelLabel: {},
    seriesLabel: {},
    bodyLabel: {}
  },
  emits: ['vehicleFound', 'modelMakeChanged', 'setColour'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;
__expose({
  resetVehicleSelection,
});

// Define Composables
// ------------------------------------------------------------- //
const {
  fetchVehicleYearsList,
  fetchVehicleMakesByYear,
  fetchModelsByYearAndMake,
  fetchVehicleVariantsByYearMakeModel,
  fetchVehicleDetailsByYearMakeModelVariantBody,
  fetchVehicleBodies,
} = useVehicleData();

// Define Component state
// ------------------------------------------------------------- //
const vehicleYearsList = ref<IDropdownItem[]>([]);
const vehicleMakesList = ref<IDropdownItem[]>([]);
const vehicleModelsList = ref<IDropdownItem[]>([]);
const vehicleSeriesList = ref<IDropdownItem[]>([]);
const vehicleBodiesList = ref<IDropdownItem[]>([]);
const availableVehiclesList = ref<IFoundVehicle[]>([]);

const vehicleSelection = reactive<IVehicleOptions>({
  year: '',
  make: '',
  model: '',
  series: '',
  body: '',
  colour: '',
});

const vehicleSelectionLoading = reactive<IVehicleLoadingOptions>({
  year: false,
  make: false,
  model: false,
  series: false,
  body: false,
  colour: false,
});

// Define Lifecycle hooks and exectute init functions
// ------------------------------------------------------------- //
onBeforeMount(async () => {
  vehicleYearsList.value = await fetchVehicleYearsList();
});

// Call refreshData only when stepActive becomes true
watch(vehicleSelection, (newVal) => {
  if (vehicleSelection?.colour) {
    emit('setColour', vehicleSelection?.colour);
  }
});

// Define functions
// ------------------------------------------------------------- //

const isColourDisabled = (): boolean => {
  if (vehicleSelection.series !== 'ALL') {
    return (!vehicleSelection.body || vehicleSelectionLoading?.body) as boolean;
  }
  return false;
};

const dynamicMakesList = ({ search }: { search: string }) => {
  if (search) {
    return vehicleMakesList.value.filter((option) => {
      return option.label?.toUpperCase().includes(search.toUpperCase());
    });
  } else {
    // Top Make
    try {
      if (props.topMakes) {
        const topMakes = props.topMakes
          .split('|')
          .map((make) => vehicleMakesList.value.find((option) => option.label.toUpperCase() == make.toUpperCase()))
          .filter((option) => option);
        return [...topMakes, { label: '', value: '' }, ...vehicleMakesList.value];
      }
    } catch (error) {
      console.warn('Unable to load top makes: ', error);
    }
    return vehicleMakesList.value;
  }
};

function resetVehicleSelection() {
  vehicleSelection.year = '';
  vehicleSelection.make = '';
  vehicleSelection.model = '';
  vehicleSelection.series = '';
  vehicleSelection.body = '';
  vehicleSelection.colour = '';
}

/**
 * Handle dropdown change by updating ref data
 *
 * @param dropdown
 * @param value
 */
async function handleDropdownChange(dropdown: vehicleSelectionTypes, value: string) {
  vehicleSelection[dropdown] = value;
  emit('modelMakeChanged');
  switch (dropdown) {
    case 'year': {
      vehicleSelection.make = '';
      vehicleSelection.model = '';
      vehicleSelection.series = '';
      vehicleSelection.body = '';

      vehicleMakesList.value = [];
      vehicleModelsList.value = [];
      vehicleSeriesList.value = [];
      vehicleBodiesList.value = [];
      availableVehiclesList.value = [];

      if (value === 'Before 1960') {
        emit('vehicleFound', [{ manufacturingYear: 1900 }]);
        return;
      }

      vehicleSelectionLoading.year = true;
      const vehicleMakesByYear = await fetchVehicleMakesByYear(value).finally(
        () => (vehicleSelectionLoading.year = false)
      );
      vehicleMakesList.value = vehicleMakesByYear;

      break;
    }

    case 'make': {
      vehicleSelection.model = '';
      vehicleSelection.series = '';
      vehicleSelection.body = '';

      vehicleModelsList.value = [];
      vehicleSeriesList.value = [];
      vehicleBodiesList.value = [];
      availableVehiclesList.value = [];

      vehicleSelectionLoading.make = true;
      const vehicleModels = await fetchModelsByYearAndMake(vehicleSelection.year, vehicleSelection.make).finally(
        () => (vehicleSelectionLoading.make = false)
      );
      vehicleModelsList.value = vehicleModels;

      break;
    }

    case 'model': {
      vehicleSelection.series = '';
      vehicleSelection.body = '';

      vehicleSeriesList.value = [];
      vehicleBodiesList.value = [];
      availableVehiclesList.value = [];

      vehicleSelectionLoading.model = true;
      const vehicleVariants = await fetchVehicleVariantsByYearMakeModel(
        vehicleSelection.year,
        vehicleSelection.make,
        vehicleSelection.model
      ).finally(() => (vehicleSelectionLoading.model = false));

      vehicleSeriesList.value = [{ label: 'All vehicles', value: 'ALL' }, ...vehicleVariants];

      break;
    }

    case 'series': {
      vehicleSelection.body = '';

      vehicleBodiesList.value = [];
      availableVehiclesList.value = [];

      if (!value) {
        return;
      }

      if (vehicleSelection.series === 'ALL') {
        const possibleVehiclesData = await fetchVehicleDetailsByYearMakeModelVariantBody(vehicleSelection);
        emit('vehicleFound', possibleVehiclesData);

        return;
      }

      vehicleSelectionLoading.series = true;
      const vehicleBodies = await fetchVehicleBodies(vehicleSelection).finally(
        () => (vehicleSelectionLoading.series = false)
      );
      vehicleBodiesList.value = vehicleBodies;
      break;
    }

    case 'body':
      availableVehiclesList.value = [];

      if (!value) {
        return;
      }

      vehicleSelectionLoading.body = true;
      // eslint-disable-next-line no-case-declarations
      const possibleVehiclesData = await fetchVehicleDetailsByYearMakeModelVariantBody(vehicleSelection).finally(
        () => (vehicleSelectionLoading.body = false)
      );
      availableVehiclesList.value = possibleVehiclesData;

      // emit results for parent to consume
      emit('vehicleFound', possibleVehiclesData);
      break;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(FormKit), {
      modelValue: vehicleSelection.year,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vehicleSelection.year) = $event)),
      label: _ctx.yearLabel,
      wrapperClass: "flex flex-col gap-1",
      outerClass: "input-xs2 !h-fit",
      validation: "required",
      clearSearchOnOpen: "",
      openOnFocus: "",
      type: "autocomplete",
      popover: "",
      options: vehicleYearsList.value,
      loading: vehicleSelectionLoading.year,
      onInput: _cache[1] || (_cache[1] = (val) => handleDropdownChange('year', val)),
      placeholder: _unref(DROPDOWN_PLACEHOLDER)
    }, null, 8, ["modelValue", "label", "options", "loading", "placeholder"]),
    (vehicleSelection.year !== 'Before 1960')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_unref(FormKit), {
            modelValue: vehicleSelection.make,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((vehicleSelection.make) = $event)),
            label: _ctx.makeLabel,
            outerClass: "input-md !h-fit",
            type: "autocomplete",
            validation: "required",
            clearSearchOnOpen: "",
            openOnFocus: "",
            options: dynamicMakesList,
            disabled: !vehicleSelection.year || vehicleSelectionLoading.year,
            loading: vehicleSelectionLoading.make,
            onInput: _cache[3] || (_cache[3] = (val) => handleDropdownChange('make', val)),
            placeholder: _unref(DROPDOWN_PLACEHOLDER)
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("div", _hoisted_2, [
                (option.label)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(option.label), 1))
                  : (_openBlock(), _createElementBlock("hr", _hoisted_4))
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "label", "disabled", "loading", "placeholder"]),
          _createVNode(_unref(FormKit), {
            modelValue: vehicleSelection.model,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((vehicleSelection.model) = $event)),
            label: _ctx.modelLabel,
            outerClass: "input-sm !h-fit",
            type: "autocomplete",
            validation: "required",
            clearSearchOnOpen: "",
            openOnFocus: "",
            options: vehicleModelsList.value,
            disabled: !vehicleSelection.make || vehicleSelectionLoading.make,
            loading: vehicleSelectionLoading.model,
            onInput: _cache[5] || (_cache[5] = (val) => handleDropdownChange('model', val)),
            placeholder: _unref(DROPDOWN_PLACEHOLDER)
          }, null, 8, ["modelValue", "label", "options", "disabled", "loading", "placeholder"]),
          _createVNode(_unref(FormKit), {
            modelValue: vehicleSelection.series,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((vehicleSelection.series) = $event)),
            label: _ctx.seriesLabel,
            outerClass: "input-sm !h-fit",
            type: "autocomplete",
            validation: "required",
            clearSearchOnOpen: "",
            openOnFocus: "",
            options: vehicleSeriesList.value,
            disabled: !vehicleSelection.model || vehicleSelectionLoading.model,
            loading: vehicleSelectionLoading.series,
            onInput: _cache[7] || (_cache[7] = (val) => handleDropdownChange('series', val)),
            placeholder: _unref(DROPDOWN_PLACEHOLDER)
          }, null, 8, ["modelValue", "label", "options", "disabled", "loading", "placeholder"]),
          (vehicleSelection.series !== 'ALL')
            ? (_openBlock(), _createBlock(_unref(FormKit), {
                key: 0,
                modelValue: vehicleSelection.body,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((vehicleSelection.body) = $event)),
                label: _ctx.bodyLabel,
                outerClass: "input-xs2 !h-fit",
                type: "autocomplete",
                validation: "required",
                clearSearchOnOpen: "",
                openOnFocus: "",
                options: vehicleBodiesList.value,
                disabled: !vehicleSelection.series || vehicleSelectionLoading.series,
                loading: vehicleSelectionLoading.body,
                onInput: _cache[9] || (_cache[9] = (val) => handleDropdownChange('body', val)),
                placeholder: _unref(DROPDOWN_PLACEHOLDER)
              }, null, 8, ["modelValue", "label", "options", "disabled", "loading", "placeholder"]))
            : _createCommentVNode("", true),
          (_ctx.showColour)
            ? (_openBlock(), _createBlock(_unref(FormKit), {
                key: 1,
                modelValue: vehicleSelection.colour,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((vehicleSelection.colour) = $event)),
                label: _ctx.colourLabel,
                outerClass: "input-xs2 !h-fit",
                type: "text",
                clearSearchOnOpen: "",
                validation: "required:trim",
                openOnFocus: "",
                disabled: isColourDisabled()
              }, null, 8, ["modelValue", "label", "disabled"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})