<template>
  <FormKit type="form" :actions="false" @submit="handleSubmit">
    <p>
      <RichText
        :field="
          isEmail
            ? DecisionIntelligenceStore.contentFields?.emailInstructionText
            : DecisionIntelligenceStore.contentFields?.contactInstructionText
        "
      />
    </p>
    <!--times for email from content-->
    <div class="my-4">
      <FormKit
        class="my-4"
        type="dropdown"
        name="option"
        data-testid="selecttime"
        innerClass="input-md"
        label="Time for email"
        placeholder="Select a time"
        :options="isEmail ? getEmailOptions() : getContactOptions()"
        :value="null"
        validation="required"
        popover
      />
    </div>
    <RichText
      v-if="showError"
      class="text-racq-red my-2"
      :field="DecisionIntelligenceStore.contentFields?.emailErrorText"
    />
    <div v-if="showSuccess" class="flex gap-4 mb-3.5 p-5 bg-racq-alert-success">
      <img :src="iconMap.successIcon" alt="success icon" />
      <RichText class="text-racq-true-white" :field="DecisionIntelligenceStore.contentFields?.emailSuccessText" />
    </div>

    <div class="flex gap-4 items-center">
      <FormKit type="submit" :loading="isSubmitting" data-testid="submit-lead" inputClass="btn btn-primary">
        Submit
      </FormKit>
    </div>
  </FormKit>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { IAction, IProposition } from '@/interfaces/decision-intelligence/decision-intelligence-response';
import { FormKit } from '@formkit/vue';
import { useDecisionIntelligenceStore } from '@/store/decision-intelligence/decision-intelligence.state';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { IDropdownItem, IQuote } from '@/interfaces';
import { Outcome } from '@/interfaces/decision-intelligence/decision-intelligence-request';
import { ICreateLeadResponseModel } from '@/interfaces/lead/lead-model';
import { mapCreateLeadRequest, mapDecisionOfferAcknowledge } from '@/mappers/decision-intelligence-mapper';
import { IPaymentReferenceDetails } from '@/interfaces/make-a-payment/make-a-payment.interface';

// Define props and emitters
// ------------------------------------------------------------- //
const props = defineProps<{
  quote?: Partial<IQuote>;
  paymentReferenceDetails?: Partial<IPaymentReferenceDetails>;
  isEmail: boolean;
  currentAction: IAction;
  proposition: IProposition;
  decisionId?: string;
}>();

// Define Component state
// ------------------------------------------------------------- //
const showSuccess = ref(false);
const showError = ref(false);
const isSubmitting = ref(false);
const DecisionIntelligenceStore = useDecisionIntelligenceStore();

const iconMap = {
  successIcon: require('@/assets/img/icons/racq-success-icon.svg'),
};

// Define Functions
// ------------------------------------------------------------- //
const getEmailOptions = (): IDropdownItem[] => {
  return DecisionIntelligenceStore.getEmailOptionsDropDown();
};
const getContactOptions = (): IDropdownItem[] => {
  return DecisionIntelligenceStore.getContactOptionsDropDown();
};
const handleSubmit = async (value: any) => {
  try {
    isSubmitting.value = true;
    const leadRequest = mapCreateLeadRequest(props.currentAction, value.option);
    const response = (await DecisionIntelligenceStore.createLead(
      props.quote
        ? (props.quote.primaryPolicyHolderDetails?.partyId.value ?? '')
        : (props.paymentReferenceDetails?.partyReferences?.d365Id ?? ''),
      leadRequest
    )) as ICreateLeadResponseModel;
    if (props.decisionId) {
      if (response?.leadId) {
        const ackRequest = mapDecisionOfferAcknowledge(
          Outcome.LeadCaptured,
          props.quote,
          props.paymentReferenceDetails,
          props.proposition,
          props.currentAction,
          response.leadId
        );
        await DecisionIntelligenceStore.acknowlegeDecisionOffer(props.decisionId, ackRequest);
      } else {
        const ackRequest = mapDecisionOfferAcknowledge(
          Outcome.LeadFailed,
          props.quote,
          props.paymentReferenceDetails,
          props.proposition,
          props.currentAction
        );
        await DecisionIntelligenceStore.acknowlegeDecisionOffer(props.decisionId, ackRequest);
      }
    }
    isSubmitting.value = false;
    showSuccess.value = true;
    showError.value = false;
  } catch (error) {
    console.error('error creating lead', error);
    showError.value = true;
    showSuccess.value = false;
    isSubmitting.value = false;
  }
};
</script>
