import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full" }
const _hoisted_2 = {
  key: 0,
  class: "font-sans text-paragraph-color richtext richtext--no-base text-m mb-13"
}
const _hoisted_3 = { class: "text-lg !font-medium font-sans leading-7" }

import { useFormStepDisclaimerStore } from '@/store';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

// Define props and emitters
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'FormStepDisclaimers',
  props: {
    fields: {},
    testParams: {}
  },
  setup(__props: any) {

const props = __props;

// Define component state
// ------------------------------------------------------------- //
const translation = useI18n();
const dictionary: Record<string, string> = translation.getLocaleMessage('en') as any;
const formStepDisclaimerStore = useFormStepDisclaimerStore();

if (props.testParams) {
  formStepDisclaimerStore.additionalDisclaimer.length = props.testParams.mockFormStepDisclaimerStoreResponse.length;

  dictionary.DisclaimerHeading = props.testParams.mockDisclaimerHeading;
}
// Computed properties
// ------------------------------------------------------------- //
const stepDisclaimers = computed(() => {
  let disclaimers = formStepDisclaimerStore.getDisclaimersForStep(props.fields.stepTitle);

  if (props.testParams) {
    disclaimers = props.testParams.mockFormStepDisclaimerStoreResponse;
  }
  return disclaimers;
});

// Computed property to determine if the disclaimer should be shown
const shouldDisplayDisclaimer = computed(() => {
  return props.fields?.defaultDisclaimer || formStepDisclaimerStore?.additionalDisclaimer?.length;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (shouldDisplayDisclaimer.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!stepDisclaimers.value.length)
            ? (_openBlock(), _createBlock(_unref(RichText), {
                key: 0,
                field: { value: _ctx.fields?.defaultDisclaimer || ' ' }
              }, null, 8, ["field"]))
            : _createCommentVNode("", true),
          (stepDisclaimers.value.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(dictionary).DisclaimerHeading), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stepDisclaimers.value, (disclaimer, key) => {
                  return (_openBlock(), _createBlock(_unref(RichText), {
                    key: key,
                    field: { value: disclaimer || ' ' },
                    tag: "p"
                  }, null, 8, ["field"]))
                }), 128))
              ], 64))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})