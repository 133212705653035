import { defineComponent as _defineComponent } from 'vue'
import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, unref as _unref, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/img/icons/Star.svg'


const _hoisted_1 = { class: "rbc-item flex flex-col w-full" }
const _hoisted_2 = ["name", "value", "aria-label"]
const _hoisted_3 = {
  key: 0,
  class: "rbc-header flex justify-start items-center h-6 bg-racq-blue rounded-t"
}
const _hoisted_4 = { class: "rbc-header-image" }
const _hoisted_5 = { class: "rbc-tag-title text-xs-bold text-racq-true-white uppercase py-1" }
const _hoisted_6 = { class: "rbc-body-top flex rounded h-full" }
const _hoisted_7 = {
  key: 0,
  class: "rbc-body-top-left flex items-center justify-between"
}
const _hoisted_8 = { class: "rbc-body-image flex items-center w-[72px] object-cover self-stretch" }
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = {
  key: 0,
  class: "rbc-body-icon flex items-center justify-center pl-px pt-px pr-0.5 pb-0.5 w-[40px] h-[40px] object-cover"
}
const _hoisted_11 = ["src", "alt"]
const _hoisted_12 = { class: "rbc-left-section flex flex-col justify-center" }
const _hoisted_13 = { class: "rbc-ls-top-row flex gap-3" }
const _hoisted_14 = {
  key: 0,
  class: "rbc-left-radio flex items-center self-stretch gap-[10px]"
}
const _hoisted_15 = { class: "rbc-ls-bottom-row flex gap-3" }
const _hoisted_16 = {
  key: 0,
  class: "rbc-ls-br-placeholder w-[20px]"
}
const _hoisted_17 = {
  key: 1,
  class: "rbc-ls-description text-sm flex flex-col pb-1 items-start self-stretch"
}
const _hoisted_18 = {
  key: 0,
  class: "flex gap-3"
}
const _hoisted_19 = { class: "rbc-right-section flex flex-col self-start text-right ml-auto" }
const _hoisted_20 = {
  key: 0,
  class: "rbc-rs-top-row flex gap-3 justify-between"
}
const _hoisted_21 = {
  key: 0,
  class: "rbc-rs-title text-xl-bold flex flex-col justify-center items-end self-stretch gap-[10px] flex-1"
}
const _hoisted_22 = {
  key: 1,
  class: "rbc-right-radio flex items-center self-stretch gap-[10px] flex-1 justify-end max-w-fit"
}
const _hoisted_23 = {
  key: 1,
  class: "rbc-rs-bottom-row flex gap-3"
}
const _hoisted_24 = {
  key: 0,
  class: "rbc-rs-description text-xs flex flex-col pb-1 items-end self-stretch gap-[10px] grow"
}
const _hoisted_25 = ["src"]

import { ref, watch } from 'vue';
import { IRadioButtonCard } from '@/interfaces/entities/radio-button-card.interface';
import chevronDown from '@/assets/icons/blue-chevron-down.svg';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioButtonCard',
  props: {
    callOutTag: {},
    bodyImage: {},
    bodyImageAlt: {},
    bodyIcon: {},
    bodyIconAlt: {},
    showLeftRadioButton: { type: Boolean },
    leftTitle: {},
    leftTitleClass: {},
    leftDescription: {},
    rightTitle: {},
    rightDescription: {},
    showRightRadioButton: { type: Boolean },
    visibleBodyDescription: {},
    hiddenBodyDescription: {},
    showMoreLinkLabel: {},
    showLessLinkLabel: {},
    value: {},
    groupName: {},
    modelValue: {},
    bindings: {},
    innerClass: {},
    outerClass: {},
    bodyTopClass: {},
    bodyBottomClass: {},
    showBottomTag: { type: Boolean },
    bottomTagText: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
// Create an internal state that mirrors the prop
const internalValue = ref(props.modelValue);
const isExpanded = ref(false);

const toggleContent = () => {
  isExpanded.value = !isExpanded.value;
};

// Emit the updated value to the parent when radio changes
const updateValue = () => {
  emit('update:modelValue', internalValue.value);
};

// Make the whole div clickable and focus the radio input
const handleClick = () => {
  if (internalValue.value !== props.value) {
    internalValue.value = props.value;
    emit('update:modelValue', internalValue.value);
  }
};

const valuesAreEqual = () => {
  return _.isEqual(props.value, internalValue.value);
};

// Watch for changes to modelValue and update internalValue accordingly
watch(
  () => props.modelValue,
  (newValue) => {
    internalValue.value = newValue;
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((internalValue).value = $event)),
      type: "radio",
      name: _ctx.groupName,
      value: _ctx.value,
      "aria-label": _ctx.leftTitle,
      class: "hidden-radio hidden",
      onChange: updateValue
    }, null, 40, _hoisted_2), [
      [_vModelRadio, internalValue.value]
    ]),
    (!!_ctx.callOutTag)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _cache[1] || (_cache[1] = _createElementVNode("img", {
                src: _imports_0,
                alt: "star icon",
                class: "mx-1 py-1"
              }, null, -1))
            ])
          ]),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.callOutTag), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([
        'rbc-inner-container align-middle cursor-pointer overflow-hidden h-full',
        valuesAreEqual() ? 'bg-racq-anti-flash-white border-racq-blue-active border-2' : 'border-racq-light-blue-grey',
        !!_ctx.callOutTag ? 'border border-t-0 rounded-b' : 'border rounded',
        _ctx.outerClass,
      ]),
      onClick: _withModifiers(handleClick, ["stop"])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["rbc-body flex flex-col h-full", _ctx.innerClass])
      }, [
        _createElementVNode("div", _hoisted_6, [
          (!!_ctx.bodyImage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, [
                  _createElementVNode("img", {
                    src: _ctx.bodyImage,
                    alt: _ctx.bodyImageAlt
                  }, null, 8, _hoisted_9)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass([
              'rbc-body-top-right flex gap-3 w-full items-center text-racq-dark-grey',
              !!_ctx.bodyImage && !_ctx.bodyIcon ? 'px-3' : 'p-4',
              { 'bg-racq-anti-flash-white': valuesAreEqual() },
              _ctx.bodyTopClass,
            ])
          }, [
            (!!_ctx.bodyIcon)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                  _createElementVNode("img", {
                    src: _ctx.bodyIcon,
                    alt: _ctx.bodyIconAlt
                  }, null, 8, _hoisted_11)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                (_ctx.showLeftRadioButton)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("span", {
                        class: _normalizeClass(['radio-indicator', { 'checked-indicator': valuesAreEqual() }])
                      }, null, 2)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass(["rbc-ls-title text-lg-bold flex flex-col justify-center items-start self-stretch", _ctx.leftTitleClass])
                }, _toDisplayString(_ctx.leftTitle), 3)
              ]),
              _createElementVNode("div", _hoisted_15, [
                (_ctx.showLeftRadioButton)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16))
                  : _createCommentVNode("", true),
                (!!_ctx.leftDescription)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createVNode(_unref(RichText), {
                        field: { value: _ctx.leftDescription }
                      }, null, 8, ["field"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.showBottomTag)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _renderSlot(_ctx.$slots, "bottomContent")
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_19, [
              (!!_ctx.rightTitle || _ctx.showRightRadioButton)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    (!!_ctx.rightTitle)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.rightTitle), 1))
                      : _createCommentVNode("", true),
                    (_ctx.showRightRadioButton)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                          _createElementVNode("span", {
                            class: _normalizeClass(['radio-indicator', { 'checked-indicator': valuesAreEqual() }])
                          }, null, 2)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (!!_ctx.rightDescription)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    (!!_ctx.rightDescription)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(_ctx.rightDescription), 1))
                      : _createCommentVNode("", true),
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "rbc-rs-br-placeholder w-[20px] grow" }, null, -1))
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 2)
        ]),
        (!!_ctx.visibleBodyDescription || !!_ctx.hiddenBodyDescription)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["rbc-body-bottom flex flex-col gap-2 pl-4 pr-8 pb-3 text-dark-grey-text", _ctx.bodyBottomClass])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([
              'rbc-bb-body flex flex-col pb-2 gap-[2px] overflow-hidden [transition:max-height_0.5_ease]',
              { collapsed: !isExpanded.value },
            ])
              }, [
                (!!_ctx.visibleBodyDescription)
                  ? (_openBlock(), _createBlock(_unref(RichText), {
                      key: 0,
                      field: { value: _ctx.visibleBodyDescription },
                      class: "flex flex-col"
                    }, null, 8, ["field"]))
                  : _createCommentVNode("", true),
                (!!_ctx.hiddenBodyDescription && isExpanded.value)
                  ? (_openBlock(), _createBlock(_unref(RichText), {
                      key: 1,
                      field: { value: _ctx.hiddenBodyDescription },
                      class: "flex flex-col"
                    }, null, 8, ["field"]))
                  : _createCommentVNode("", true)
              ], 2),
              (!!_ctx.hiddenBodyDescription)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "toggle-button flex items-center bg-none border-[none] cursor-pointer text-base-bold text-racq-blue w-fit",
                    onClick: _withModifiers(toggleContent, ["stop","prevent"])
                  }, [
                    _createTextVNode(_toDisplayString(isExpanded.value ? _ctx.showLessLinkLabel : _ctx.showMoreLinkLabel) + " ", 1),
                    _createElementVNode("img", {
                      src: _unref(chevronDown),
                      alt: "chevron icon",
                      class: _normalizeClass(['w-[12px] h-[7px] ml-2 [transition:transform_0.5s_ease]', { flipped: isExpanded.value }])
                    }, null, 10, _hoisted_25)
                  ]))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)
  ]))
}
}

})