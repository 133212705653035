/// <reference types="@types/google.maps" />
import { GoogleMapDetails } from '@/interfaces/entities/google-map.interface';
import { defineStore } from 'pinia';
import { EventEmitter } from 'events';

// Define the gm_authFailure function
declare global {
  interface Window {
    gm_authFailure: (error: any) => void;
  }
}

export const useGoogleMapStore = defineStore('GoogleMap', {
  state: (): GoogleMapDetails => {
    return {
      _formattedAddress: '',
      _latitude: 0,
      _longitude: 0,
      _placeId: '',
      _apiKey: '',
      _mapId: '',
      _defaultZoomLevel: 0,
      _tipText: '',
      _eventEmitter: new EventEmitter(),
      _addressState: null,
    };
  },
  getters: {
    formattedAddress: (state) => state._formattedAddress,
    latitude: (state) => state._latitude,
    longitude: (state) => state._longitude,
    placeId: (state) => state._placeId,
    apiKey: (state) => state._apiKey,
    mapId: (state) => state._mapId,
    defaultZoomLevel: (state) => state._defaultZoomLevel,
    tipText: (state) => state._tipText,
    eventEmitter: (state) => state._eventEmitter,
    errorEventName: () => 'googleMapsApiError',
  },
  actions: {
    setFormattedAddress(address: string) {
      this._formattedAddress = address;
    },
    setLatitude(latitude: number) {
      this._latitude = latitude;
    },
    setLongitude(longitude: number) {
      this._longitude = longitude;
    },
    setPlaceId(placeId: string) {
      this._placeId = placeId;
    },
    setApiKey(apiKey: string) {
      this._apiKey = apiKey;
    },
    setMapId(mapId: string) {
      this._mapId = mapId;
    },
    setDefaultZoomLevel(defaultZoomLevel: number) {
      this._defaultZoomLevel = defaultZoomLevel;
    },
    setTipText(tipText: string) {
      this._tipText = tipText;
    },
    setAddressStateFromAddressComponents(addressComponents: any) {
      let addressState = null;
      if (addressComponents?.length > 0) {
        const firstComponent = addressComponents[0];
        // Check which format the components are in
        const shortTextKey = 'short_name' in firstComponent ? 'short_name' : 'shortText';

        for (const component of addressComponents) {
          if (component?.types.includes('administrative_area_level_1')) {
            addressState = component[shortTextKey];
            break;
          }
        }
      }
      this._addressState = addressState;
    },
    /**
     * Load the Google Maps API
     * @param apiKey
     */
    async loadGoogleMapsApi(): Promise<void> {
      if (this._apiKey) {
        return new Promise((resolve, reject) => {
          window.gm_authFailure = (error: any) => this.emitErrorEvent(error);

          if (document.getElementById('google-maps-api')) {
            resolve();
            return;
          }

          const script = document.createElement('script');
          script.id = 'google-maps-api';
          script.src = `https://maps.googleapis.com/maps/api/js?key=${this._apiKey}&libraries=places,marker`;
          script.async = true;
          script.defer = true;
          script.onload = () => {
            console.log('Google Maps API script loaded.');
            resolve();
          };
          script.onerror = (error) => {
            console.log('Google Maps API script error:', error);
            this.emitErrorEvent(error);
            reject(error);
          };
          document.head.appendChild(script);
        });
      }
    },
    emitErrorEvent(error: any) {
      if (!this._eventEmitter) {
        this._eventEmitter = new EventEmitter();
      }
      this._eventEmitter.emit(this.errorEventName, error);
    },
  },
});
