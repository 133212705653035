import { IDisclaimer } from '@/interfaces';
import {
  IBenefit,
  IDecisionIntelligenceOfferResponse,
  IProposition,
  IStep,
} from '@/interfaces/decision-intelligence/decision-intelligence-response';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useFormStepDisclaimerStore = defineStore('FormStepDisclaimerStore', () => {
  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //
  const additionalDisclaimer = ref<IDisclaimer[]>([]);

  // ------------------------------------------------------------- //
  // Methods
  // ------------------------------------------------------------- //
  const appendDisclaimer = (
    disclaimer: string,
    termsConditionsText: string,
    currentDisclaimer: IDisclaimer
  ): IDisclaimer => {
    if (disclaimer) {
      currentDisclaimer.disclaimers?.push(disclaimer);
    }
    if (termsConditionsText) {
      currentDisclaimer.disclaimers?.push(termsConditionsText);
    }
    return currentDisclaimer;
  };
  const setDIDisclaimers = (
    request: IDecisionIntelligenceOfferResponse,
    isQuote: boolean,
    isCrossSell?: boolean,
    isRenewal?: boolean
  ) => {
    const disclaimers: IDisclaimer[] = [];

    if (request.propositions?.length) {
      request.propositions.forEach((currentProposition: IProposition) => {
        if (currentProposition.displaySteps?.length) {
          currentProposition.displaySteps
            .filter(
              (x) =>
                x.journey ==
                  (isRenewal ? (isCrossSell ? 'Renewal' : 'Make a payment') : isQuote ? 'Quote' : 'Purchase') &&
                x.isCrossSell == isCrossSell
            )
            .forEach((currentStep: IStep) => {
              let currentDisclaimer = { stepTitle: currentStep.stepName, disclaimers: [] } as IDisclaimer;
              currentDisclaimer = appendDisclaimer(
                currentStep.disclaimerText,
                currentStep.termsConditionsText,
                currentDisclaimer
              );
              currentStep.crossSellOfferContent?.benefitList?.forEach((benefitList: IBenefit) => {
                currentDisclaimer = appendDisclaimer(
                  benefitList?.benefitDisclaimer,
                  benefitList?.benefitTermsConditionsText,
                  currentDisclaimer
                );
              });
              currentDisclaimer = appendDisclaimer(
                currentStep?.crossSellOfferContent?.incentiveDisclaimerText,
                currentStep?.crossSellOfferContent?.incentiveTermsConditionsText,
                currentDisclaimer
              );

              disclaimers.push(currentDisclaimer);
            });
        }
      });
    }
    additionalDisclaimer.value = additionalDisclaimer.value.concat(disclaimers);
  };
  const getDisclaimersForStep = (stepName?: string): string[] => {
    let disclaimers = [] as string[];
    additionalDisclaimer.value
      ?.filter((x) => x?.stepTitle.toLowerCase() == stepName?.toLowerCase())
      .forEach((x) => {
        if (x.disclaimers?.length) {
          disclaimers = disclaimers.concat(x.disclaimers);
        }
      });
    return disclaimers;
  };

  const $reset = () => {
    additionalDisclaimer.value = [];
  };

  return {
    setDIDisclaimers,
    additionalDisclaimer,
    getDisclaimersForStep,
    $reset,
  };
});
