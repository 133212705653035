import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "self-stretch justify-start items-start gap-0.5 inline-flex" }
const _hoisted_2 = { class: "grow shrink basis-0 pt-px flex-col justify-start items-start inline-flex" }
const _hoisted_3 = { class: "self-stretch leading-normal" }
const _hoisted_4 = { class: "self-stretch text-paragraph-color text-base leading-normal" }
const _hoisted_5 = { class: "px-1 py-0.5flex-col justify-center items-start gap-2.5 inline-flex" }

import { IReviewSummaryItem } from '@/interfaces';

// Define props and emitters
// ------------------------------------------------------------- //
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReviewSummaryItem',
  props: {
    item: {},
    isLastReviewSummaryItem: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const classNames = computed(() => (!props.isLastReviewSummaryItem ? 'border-b' : ''));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["self-stretch pb-4 border-racq-pale-blue-border flex-col justify-start items-start flex", classNames.value])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "title")
          ]),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "content")
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "edit")
        ])
      ])
    ], 2),
    _renderSlot(_ctx.$slots, "showMore")
  ], 64))
}
}

})