import { INameCode } from '@/interfaces/entities/name-code.interface';
import { RequestServices as _http } from '../common/request.services';
import {
  getPersonTitles as getPersonTitlesApi,
  getGenders as getGendersApi,
  getPetBreeds as getPetBreedsApi,
  getPetTypes as getPetTypesApi,
  fetchHomeClaimTypes,
  fetchHomeLossTypes,
  fetchHomeLossValues,
  getHomeAdvancedCover,
  getHomeFloorOccupancy,
  getHomeGroupCoverPriceBands,
  getSmallBusinessInsuranceLimitValue,
  getEathquakeExcesses,
  getPortableExcesses,
  getPetExcesses,
  getCombinedAccessoriesList,
  getRoofTypeMaterials,
  getPrimaryConstructionMaterials,
  getHomeOccupancyTypes,
  getBuildingTypes,
  getPortableMobileExcesses,
  getAtFaultClaimTypes,
  getAtFaultClaimValues,
  getDriverLicenseIssueTypes,
  getFrameMaterials,
  getFloorMaterials,
  getMonitoredAlarmTypes,
  getInsuranceProducts,
  getHomeAndContentProducts,
  getHomeAndContentBuildingPropertyFeatures,
  getMembershipLevels,
  getNumOfStoreys,
  getLowestOccupiedFloor,
  getQuotingFeesPayByMonth,
  getParkingLocationsUrl,
  getVehicleBusinessUseTypesListUrl,
  getFinanceTypesUrl,
  getDriverLicenseYearsHeldRangesUrl,
  getPortableItemCategories,
} from '@/constants/forms-api.const';
import { INameValue } from '@/interfaces/entities/name-value.interface';

export const ReferenceDataServices = {
  getPersonTitles() {
    return _http.HttpGet(getPersonTitlesApi);
  },

  getGenders() {
    return _http.HttpGet(getGendersApi);
  },

  getPetTypes(quoteType: string) {
    return _http.HttpGet(getPetTypesApi.replace('{0}', quoteType));
  },

  getPetBreeds(quoteType: string, petType: string) {
    return _http.HttpGet(getPetBreedsApi.replace('{0}', quoteType).replace('{1}', petType));
  },

  getInsuranceProducts() {
    return _http.HttpGet(getInsuranceProducts);
  },

  getHomeAndContentProducts(occupancyType: string, buildingType: string): Promise<INameValue[]> {
    return _http.HttpGet(getHomeAndContentProducts.replace('{0}', occupancyType).replace('{1}', buildingType));
  },
  getHomeAndContentBuildingPropertyFeatures() {
    return _http.HttpGet(getHomeAndContentBuildingPropertyFeatures);
  },
  //#region HOME AND CONTENT
  getHomeClaimTypes(quoteType: string) {
    return _http.HttpGet(fetchHomeClaimTypes.replace('{0}', quoteType));
  },

  getHomeLossTypes(quoteType: string) {
    return _http.HttpGet(fetchHomeLossTypes.replace('{0}', quoteType));
  },

  getHomeLossValues(quoteType: string) {
    return _http.HttpGet(fetchHomeLossValues.replace('{0}', quoteType));
  },

  getHomeAdvancedCover(quoteType: string, occupancyType: string) {
    return _http.HttpGet(getHomeAdvancedCover.replace('{0}', quoteType).replace('{1}', occupancyType));
  },

  getHomeRoofTypeMaterials(quoteType: string) {
    return _http.HttpGet(getRoofTypeMaterials.replace('{0}', quoteType));
  },

  getHomePrimaryConstructionMaterials(quoteType: string) {
    return _http.HttpGet(getPrimaryConstructionMaterials.replace('{0}', quoteType));
  },

  getHomeOccupancyTypes(): Promise<{ values: INameCode[] }> {
    return _http.HttpGet(getHomeOccupancyTypes);
  },

  getNumOfStoreys(buildingType: string): Promise<INameValue[]> {
    return _http.HttpGet(getNumOfStoreys.replace('{0}', buildingType));
  },

  getLowestOccupiedFloor(buildingType: string) {
    return _http.HttpGet(getLowestOccupiedFloor.replace('{0}', buildingType));
  },

  getBuildingTypes(): Promise<INameValue[]> {
    return _http.HttpGet(getBuildingTypes);
  },

  getHomeFloorOccupancy(buildingType: string): Promise<{ values: INameValue[] }> {
    return _http.HttpGet(getHomeFloorOccupancy.replace('{0}', buildingType));
  },

  getHomeGroupCoverPriceBands(quoteType: string): Promise<{ values: INameCode[] }> {
    return _http.HttpGet(getHomeGroupCoverPriceBands.replace('{0}', quoteType));
  },

  getPortableItemCategories(quoteType: string): Promise<{ values: INameCode[] }> {
    return _http.HttpGet(getPortableItemCategories.replace('{0}', quoteType));
  },

  getSmallBusinessInsuranceLimitValue(quoteType: string) {
    return _http.HttpGet(getSmallBusinessInsuranceLimitValue.replace('{0}', quoteType));
  },

  getEathquakeExcesses(quoteType: string) {
    return _http.HttpGet(getEathquakeExcesses.replace('{0}', quoteType));
  },

  getPetExcesses(quoteType: string) {
    return _http.HttpGet(getPetExcesses.replace('{0}', quoteType));
  },

  getPortableExcesses(quoteType: string) {
    return _http.HttpGet(getPortableExcesses.replace('{0}', quoteType));
  },

  getPortableMobileExcesses(quoteType: string) {
    return _http.HttpGet(getPortableMobileExcesses.replace('{0}', quoteType));
  },

  getFrameMaterials(quoteType: string) {
    return _http.HttpGet(getFrameMaterials.replace('{0}', quoteType));
  },

  getFloorMaterials(quoteType: string) {
    return _http.HttpGet(getFloorMaterials.replace('{0}', quoteType));
  },

  getMembershipLevels(quoteType: string) {
    return _http.HttpGet(getMembershipLevels.replace('{0}', quoteType));
  },

  getMonitoredAlarmTypes(quoteType: string): Promise<INameValue[]> {
    return _http.HttpGet(getMonitoredAlarmTypes.replace('{0}', quoteType));
  },
  //#endregion

  //#region MOTOR
  getCombinedAccessoriesList() {
    return _http.HttpGet(getCombinedAccessoriesList);
  },

  getAtFaultClaimTypes() {
    return _http.HttpGet(getAtFaultClaimTypes);
  },

  getAtFaultClaimValues() {
    return _http.HttpGet(getAtFaultClaimValues);
  },

  getLicenceCancellationTypes() {
    return _http.HttpGet(getDriverLicenseIssueTypes);
  },

  getQuotingFeesPayByMonth(effectiveDate: Date) {
    return _http.HttpGet(getQuotingFeesPayByMonth.replace('{0}', effectiveDate.toDateString()));
  },

  getParkingLocations() {
    return _http.HttpGet(getParkingLocationsUrl);
  },

  getBusinessUseTypes() {
    return _http.HttpGet(getVehicleBusinessUseTypesListUrl);
  },

  getFinanceTypes() {
    return _http.HttpGet(getFinanceTypesUrl);
  },

  getDriverLicenseYearsHeldRanges() {
    return _http.HttpGet(getDriverLicenseYearsHeldRangesUrl);
  },

  //#endregion
};
