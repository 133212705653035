import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { FormKit } from '@formkit/vue';
import { IDropdownItem } from '@/interfaces';
import { useGoogleMapStore } from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleMapAddressInput',
  emits: ['selected'],
  setup(__props, { emit: __emit }) {

// Add this line to reference the Google Maps types
/// <reference types="@types/google.maps" />

const googleMapStore = useGoogleMapStore();
const formattedAddress = computed(() => googleMapStore._formattedAddress);

const emit = __emit;

async function getSuggestions({ search }: { search: string }): Promise<IDropdownItem[]> {
  try {
    if (!search || search.length < 3) return [];

    // Create a session token.
    const token = new google.maps.places.AutocompleteSessionToken();
    // Add an initial request body.
    let request: google.maps.places.AutocompleteRequest = {
      input: search,
      origin: { lat: -27.5866031, lng: 153.1018371 },
      includedRegionCodes: ['au'], // only allow au address
      language: 'en-AU',
      region: 'au',
      sessionToken: token,
    };

    const { suggestions } = await google.maps.places.AutocompleteSuggestion.fetchAutocompleteSuggestions(request);
    return suggestions.map((suggestion: google.maps.places.AutocompleteSuggestion) => ({
      label: suggestion.placePrediction?.text.toString() ?? '',
      value: suggestion.placePrediction,
    }));
  } catch (error) {
    console.warn('Unable to lookup: ', error);
    return [];
  }
}

const selectSuggestion = async (suggestion: google.maps.places.PlacePrediction) => {
  if (!suggestion || !(suggestion instanceof google.maps.places.PlacePrediction)) return;
  try {
    const place = suggestion.toPlace();
    await place.fetchFields({ fields: ['location', 'formattedAddress', 'id', 'addressComponents'] });

    googleMapStore.setFormattedAddress(place.formattedAddress ?? '');
    googleMapStore.setLatitude(place.location?.lat() ?? 0);
    googleMapStore.setLongitude(place.location?.lng() ?? 0);
    googleMapStore.setAddressStateFromAddressComponents(place.addressComponents);

    document.getElementsByName('address')?.[0]?.blur();
    emit('selected', { lat: place.location?.lat() ?? 0, lng: place.location?.lng() ?? 0 });
  } catch (error) {
    console.warn('Unable to fetch place details: ', error);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(FormKit), {
    modelValue: formattedAddress.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formattedAddress).value = $event)),
    type: "autocomplete",
    name: "address",
    "aria-label": "Breakdown Location",
    placeholder: "Enter location",
    options: getSuggestions,
    prefixIcon: "search",
    wrapperClass: "flex block",
    outerClass: "$remove:pb-4",
    listboxButtonClass: "$reset hidden",
    selectIconClass: "$reset hidden",
    innerClass: "$remove:md:max-w-[300px]",
    inputClass: "$remove:px-3 h-[40px] font-sans !font-normal !leading-none text-base pr-3 pl-0",
    prefixIconClass: "$remove:bg-gradient-to-b $remove:border-gray-400 $remove:border-r",
    listboxClass: "font-sans !font-normal !leading-none text-base",
    messagesClass: "$reset list-none flex ml-0 pl-0",
    validation: "required",
    validationMessages: {
      required: 'Please provide a valid address',
    },
    validationVisibility: "submit",
    onInput: selectSuggestion
  }, null, 8, ["modelValue"]))
}
}

})