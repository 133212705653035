import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "grow shrink self-stretch p-5 md:px-6 md:py-5 rounded bg-racq-anti-flash-white justify-start items-start gap-4 flex flex-col md:flex-row" }
const _hoisted_3 = { class: "grow shrink justify-start items-start gap-4 md:gap-5 flex" }
const _hoisted_4 = { class: "grow shrink flex-col justify-start items-start inline-flex" }

import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useAccountStore } from '@/store/account/account.state';
import { replaceBookmark } from '@/utils/bookmark.replace';
import { Field, RichText, Image } from '@sitecore-jss/sitecore-jss-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AccountLoginInfoBox',
  props: {
    fields: {},
    testParams: {}
  },
  setup(__props: any) {

const accountStore = useAccountStore();
const route = useRoute();
const props = __props;

const authenticated = ref(false);
const greetingsWithoutPlaceholder = ref('');

// props.testParams is only populated via storybook to set initial state
if (props.testParams) {
  authenticated.value = props.testParams.authenticated;
  accountStore.prefillForm = props.testParams.authenticated;
  accountStore.setIdentityDetailsFirstName(props.testParams.firstName ?? '');
}

// Lifecycle hooks
// ------------------------------------------------------------- //
onMounted(async () => {
  try {
    if (!props.testParams) {
      const loggedIn = await accountStore.isAuthenticated;
      if (loggedIn) {
        await accountStore.fetchIdentityDetails();
      }
      authenticated.value = loggedIn || accountStore.partyMatched;
    }
  } catch (error) {
    console.error(error);
  } finally {
    setGreetings(authenticated.value);
  }
});

const setUrlParam = () => {
  const url = new URL(window.location.href);
  url.searchParams.set('login', 'true');
  window.history.pushState(null, '', url.toString());
};

const loginButtonClick = async () => {
  if (!authenticated.value) {
    setUrlParam();
    await accountStore.authenticate(route);
  }
};

const togglePrefillDetailsButtonClick = () => {
  accountStore.togglePrefillDetails();
};

function setGreetings(authenticated: boolean) {
  if (authenticated) {
    greetingsWithoutPlaceholder.value = replaceBookmark(
      'firstName',
      props.fields?.authenticatedGreetings?.value ?? '',
      accountStore.identityDetails?.firstName ?? ''
    );
  } else {
    greetingsWithoutPlaceholder.value = props.fields?.unauthenticatedGreetings?.value;
  }
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Image), {
          media: _ctx.fields.icon,
          class: "w-12 h-12"
        }, null, 8, ["media"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(RichText), {
            field: { value: greetingsWithoutPlaceholder.value }
          }, null, 8, ["field"])
        ])
      ]),
      (_ctx.fields.unauthenticatedButtonText?.value && !authenticated.value)
        ? (_openBlock(), _createBlock(_component_FormKit, {
            key: 0,
            type: "button",
            label: _ctx.fields.unauthenticatedButtonText.value,
            inputClass: "btn btn-sm btn-secondary w-full md:w-max",
            outerClass: "w-full md:w-max",
            onClick: loginButtonClick
          }, null, 8, ["label"]))
        : (authenticated.value && _unref(accountStore).prefillForm && _ctx.fields.clearPrefillDetailsText?.value)
          ? (_openBlock(), _createBlock(_component_FormKit, {
              key: 1,
              type: "button",
              label: _ctx.fields.clearPrefillDetailsText.value,
              inputClass: "btn btn-sm btn-secondary w-full md:w-max",
              outerClass: "w-full md:w-max",
              onClick: togglePrefillDetailsButtonClick
            }, null, 8, ["label"]))
          : (authenticated.value && !_unref(accountStore).prefillForm && _ctx.fields.restorePrefillDetailsText?.value)
            ? (_openBlock(), _createBlock(_component_FormKit, {
                key: 2,
                type: "button",
                label: _ctx.fields.restorePrefillDetailsText.value,
                inputClass: "btn btn-sm btn-secondary w-full md:w-max",
                outerClass: "w-full md:w-max",
                onClick: togglePrefillDetailsButtonClick
              }, null, 8, ["label"]))
            : _createCommentVNode("", true)
    ])
  ]))
}
}

})