import { CancelInsuranceFormState, CancellationReasons } from '@/interfaces';
import { defineStore } from 'pinia';
import { CancelInsuranceFormService as service } from '@/services/store';
import { useInsuranceProductStore } from '../insurance-product/insurance-product.state';
import { useFormStore } from '../form/formStore';

export const useCancelInsuranceFormStore = defineStore('CancelInsuranceForm', {
  state: (): CancelInsuranceFormState => ({
    cancellationReasons: [],
  }),
  actions: {
    /**
     * Validate step 1
     */
    async isInsuranceEligibleForCancellation(payload: {
      subCancellationReasonId: string;
      cancellationReasonId: string;
    }) {
      const formStore = useFormStore();
      try {
        formStore.showLoadingForCurrentStep();
        const policyStore = useInsuranceProductStore();
        const res = await service.isInsuranceEligibleForCancellation(
          policyStore.insuranceProduct!.policyNumber,
          payload
        );

        return res;
      } catch (error) {
        // TODO fix this
        // throw error;
      } finally {
        formStore.showLoadingForCurrentStep(false);
      }
    },

    setCancellationReasons(reasons: CancellationReasons[]) {
      this.cancellationReasons = reasons;
    },

    /** Fetch Policy Detail */
    async fetchPolicy(policyNumber: string) {
      try {
        const res = await service.fetchPolicy(policyNumber);
        const policyStore = useInsuranceProductStore();
        policyStore.setInsuranceProduct(res.insurancePolicy);
        this.insuranceIndexNumber = res.insuranceIndexNumber;
        this.coverageDetails = res.coverageDetails;
        this.currentDateTime = res.currentDateTime;
        this.defaultCancellationDate = res.defaultCancellationDate;
        this.insuranceIndexNumber = res.insuranceIndexNumber;
        this.insurancePolicyType = res.insurancePolicyType;
        this.insuranceType = res.insuranceType;
        this.maxCancellationDate = res.maxCancellationDate;
      } catch (error) {
        // useJeopardyStore().setStepTitle()
      }
    },

    /**
     * Go to change vehicle form
     */
    async goChangeVehicleLink() {
      const url = await service.goChangeVehicleLink();
      window.location.href = url.replace('{0}', this.insuranceIndexNumber?.toString() || '');
    },
  },
  getters: {
    reasons: (state) => state.cancellationReasons,
  },
});
