import { defineStore } from 'pinia';
import { ChangePaymentDetailsService as _changePaymentDetails } from '@/services/store/change-payment-details.service';
import { QuoteSessionService as _quoteSession } from '@/services/store/quote-session.service';
import { ref } from 'vue';
import { JP_GENERIC_CODE } from '@/constants/jeopardy-codes.const';
import {
  IChangePaymentDetailsModel,
  IChangePaymentDetailsViewModel,
  ICreditCardPaymentDetails,
  IDirectDebitDetails,
  InsuranceProduct,
} from '@/interfaces';
import { PaymentMethod } from '@/enums/payment-types.enum';
import { IJeopardy } from '@/interfaces/entities';
import { JeopardyLevel } from '@/enums/jeopardy-level.enum';

export const useChangePaymentDetailsStore = defineStore('ChangePaymentDetails', () => {
  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //
  const quoteSessionId = ref<string | null>();
  const creditCardDetails = ref<ICreditCardPaymentDetails>();
  const bankAccountDetails = ref<IDirectDebitDetails>();
  const paymentMethod = ref<PaymentMethod>();
  const insuranceProduct = ref<InsuranceProduct>();
  const model = ref<Partial<IChangePaymentDetailsModel>>({});
  const errors = ref<IJeopardy[]>([]);
  const insuranceProductName = ref<string | undefined>('');
  const responseViewModel = ref<IChangePaymentDetailsViewModel>();

  /**
   * Fetch payment details
   */
  async function fetchPaymentDetails() {
    const params = new URLSearchParams(location.search);
    quoteSessionId.value = params.get('quoteSessionId');
    if (!quoteSessionId.value) throw JP_GENERIC_CODE;
    const res = await _changePaymentDetails.fetchPaymentDetails(quoteSessionId.value);
    if (res.currentBankAccountDetails) {
      bankAccountDetails.value = res.currentBankAccountDetails;
      paymentMethod.value = PaymentMethod.BankAccount;
    } else if (res.currentCreditCardDetails) {
      creditCardDetails.value = res.currentCreditCardDetails;
      paymentMethod.value = PaymentMethod.CreditCard;
    }

    insuranceProduct.value = res.currentPolicy;
    model.value.authorisedPolicyNumbers = res.authorisedPolicyNumbers;
    model.value.d365Id = res.d365Id;
    model.value.userId = res.userId;
  }

  /**
   * Apply rules and save to session
   *
   * @param requestQuote
   */
  async function applyRulesAndSave(requestQuote: boolean, bindChange: boolean = false) {
    if (model.value.paymentMethod == PaymentMethod.BankAccount) {
      delete model.value.creditCardDetails;
    } else if (model.value.paymentMethod == PaymentMethod.CreditCard) {
      delete model.value.bankAccountDetails;
    }
    const payload = {
      quoteSessionId: quoteSessionId.value,
      requestQuote,
      bindChange,
      currentPolicy: insuranceProduct.value,
      model: model.value,
    };

    errors.value = [];
    const res: { jeopardies: IJeopardy[]; viewModel: IChangePaymentDetailsViewModel } =
      await _changePaymentDetails.applyRulesAndSave(payload);

    responseViewModel.value = res?.viewModel;

    errors.value = res?.jeopardies || [
      {
        code: JP_GENERIC_CODE,
        level: JeopardyLevel.Error,
      },
    ];
  }

  async function withdrawQuoteSession() {
    try {
      await _quoteSession.withdrawQuoteSession(quoteSessionId.value!);
    } catch (ex) {
      console.warn('Unable to withdraw quote session and work order: ', ex);
    }
  }

  return {
    fetchPaymentDetails,
    applyRulesAndSave,
    withdrawQuoteSession,
    insuranceProductName,
    insuranceProduct,
    creditCardDetails,
    bankAccountDetails,
    paymentMethod,
    model,
    errors,
    responseViewModel,
  };
});
