import {
  getJORProducts,
  postJORCreateParty,
  postJORCreateQuote,
  postJORFinaliseQuote,
  postJorSubmitPurchase,
} from '@/constants/forms-api.const';
import { RequestServices as _http } from '../common/request.services';
import { JoinOnRoadProduct } from '@/interfaces/entities/jor-product.interface';
import { CreatePartyRequest, CreatePartyResponse } from '@/interfaces/entities/jor-create-party.interface';
import { CreateQuoteRequest, CreateQuoteResponse } from '@/interfaces/entities/jor-create-quote.interface';
import { FinaliseQuoteResponse } from '@/interfaces/entities/jor-finalise-quote.interface';

export const JoinOnRoadService = {
  /**
   * Fetch join on road products
   * @returns
   */
  async getJORProducts(): Promise<JoinOnRoadProduct[] | null> {
    try {
      return await _http.HttpGet(`${getJORProducts}`);
    } catch (error) {
      console.error('Failed to load JOR products');
      return null;
    }
  },

  /**
   * Create Party for JOR new member
   *
   * @param body
   * @returns
   */
  async CreateParty(body: CreatePartyRequest): Promise<CreatePartyResponse | null> {
    try {
      return await _http.HttpPost(postJORCreateParty, body);
    } catch (error) {
      console.error('Failed to create party for JOR new member');
      return null;
    }
  },

  /**
   * Create Quote for JOR
   *
   * @param body
   * @returns
   */
  async CreateQuote(body: CreateQuoteRequest): Promise<CreateQuoteResponse | null> {
    try {
      return await _http.HttpPost(postJORCreateQuote, body);
    } catch (error) {
      console.error('Failed to create quote for JOR');
      return null;
    }
  },

  /**
   * Finalise Quote for JOR
   *
   * @param apttusQuoteId
   * @param apttusCartId
   * @returns
   */
  async FinaliseQuote(apttusQuoteId: string, apttusCartId: string): Promise<FinaliseQuoteResponse | null> {
    try {
      return await _http.HttpPost(postJORFinaliseQuote.replace('{0}', apttusQuoteId).replace('{1}', apttusCartId));
    } catch (error) {
      console.error('Failed to finalise quote for JOR');
      return null;
    }
  },

  /**
   * Submit Purchase for Join On Road
   *
   * @param body
   * @returns
   */
  submitPurchase: (body: Record<string, any>): Promise<any> => {
    return _http.HttpPost(postJorSubmitPurchase, body);
  },
} as const;
