/**
 * Check if object is empty {}
 *
 * @param object {}
 * @returns bool
 */
export function isEmptyObject(object: any) {
  try {
    return JSON.stringify(object) === '{}';
  } catch {
    return true;
  }
}
