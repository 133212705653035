import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { ref, watch, onMounted, onUnmounted } from 'vue';
import { usePaymentStore } from '@/store';

// Define Composables
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentSectionFZIframe',
  setup(__props) {

const paymentStore = usePaymentStore();

// Define Component state
// ------------------------------------------------------------- //
const iframeUrl = ref<string>('');

// Define fuctions
// ------------------------------------------------------------- //
// This is based on the javascript provided by FatZebra
// Ref: https://docs.fatzebra.com/reference/hosted-payment-pages#iframe-and-postmessage-support
async function messageListener(event: any) {
  // Older browsers will have a query-string style data payload
  // Whereas newer browsers will have an object
  let payload = event.data;

  if (typeof event.data == 'string') {
    if (/\[object/i.test(event.data)) {
      alert('Sorry, it looks like there has been a problem communicating with your browser...'); // Raised if the serialization failed
    }

    // Deserialize into an object
    const pairs = payload.split('&');
    payload = {};

    for (const element of pairs) {
      const kv = element.split('=');
      payload[kv[0]] = kv[1];
    }
  }

  if ('data' in payload) {
    const payloadData = payload.data;
    if (payloadData && payloadData.r && payloadData.token) {
      paymentStore.setFzTransactionState(payloadData);
    }

    return true;
  }
}

// Lifecycle hooks
// ------------------------------------------------------------- //
watch(
  () => [paymentStore.reference, paymentStore.amount, paymentStore.buttonText],
  async ([newReference, newAmount, newButtonText]) => {
    const loadIframe =
      newReference === '' ||
      newReference ||
      newAmount ||
      (newButtonText && (!paymentStore.reference || !paymentStore.amount));

    if (loadIframe) {
      const iframeResponse = await paymentStore.getPaymentIframeUrl();
      if (iframeResponse) {
        iframeUrl.value = iframeResponse.url;
      }
      (window as any).paymentHost = iframeResponse ? iframeResponse.hostUrl : '';
    } else if (!paymentStore.reference || !paymentStore.amount) {
      console.error('missing reference or amount. please check store');
    }

    /*   if(newReference=='') {
      console.error('missing reference. please check store');
      const iframeResponse = await paymentStore.getPaymentIframeUrl();
        if(iframeResponse)
        {
          iframeUrl.value = iframeResponse.url;
        }
        (window as any).paymentHost = iframeResponse ? iframeResponse.hostUrl : '';  
      return;
    }

    if(newReference || newAmount || newButtonText) {
      if(!paymentStore.reference || !paymentStore.amount) {
        console.error('missing reference or amount. please check store');
      } else {
        const iframeResponse = await paymentStore.getPaymentIframeUrl();
        if(iframeResponse)
        {
          iframeUrl.value = iframeResponse.url;
        }
        (window as any).paymentHost = iframeResponse ? iframeResponse.hostUrl : '';  
      } 
    } */
  }
);

onMounted(async () => {
  window.addEventListener('message', messageListener);
});

onUnmounted(() => {
  window.removeEventListener('message', messageListener);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("iframe", {
      src: iframeUrl.value,
      title: "FatZebra payment widget",
      class: "w-full border-0 h-[600px]"
    }, null, 8, _hoisted_1)
  ]))
}
}

})