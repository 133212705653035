import { IFeatureToggle } from '@/interfaces/entities/feature-toggle';

export enum FeatureToggles {
  DisablePropositions = 'disablePropositions',
  DisableCrossSell = 'disableCrosssell',
}

export function getToggle(toggleName: FeatureToggles, featureToggles: IFeatureToggle[]): IFeatureToggle | null {
  if (!toggleName || !featureToggles) return null;
  const toggle = featureToggles.find((x) => x.name == toggleName);
  return toggle ?? null;
}
