import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col w-full justify-between gap-6 mt-9 mb-10"
}
const _hoisted_2 = { class: "text-xl text-base-bold" }
const _hoisted_3 = { class: "flex p-2 gap-1 items-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "bg-white" }
const _hoisted_6 = { class: "flex flex-col gap-8" }
const _hoisted_7 = { class: "flex flex-row gap-4" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "text-xl-bold" }
const _hoisted_10 = { class: "text-racq-med-grey p-0 ms-4 list-disc" }
const _hoisted_11 = {
  key: 0,
  class: "border rounded p-5 text-base-bold text-lg bg-racq-pale-blue"
}
const _hoisted_12 = { class: "flex sm:flex-col md:flex-row" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 1,
  class: "text-racq-red my-2"
}
const _hoisted_15 = {
  key: 2,
  class: "mt-5"
}

import { onMounted, ref } from 'vue';
import {
  IAction,
  IDecisionIntelligenceOfferResponse,
  IProposition,
  IStep,
} from '@/interfaces/decision-intelligence/decision-intelligence-response';
import { useDecisionIntelligenceStore } from '@/store/decision-intelligence/decision-intelligence.state';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { IQuote } from '@/interfaces';
import { Outcome } from '@/interfaces/decision-intelligence/decision-intelligence-request';
import DecisionIntelligenceLeadForm from '@/components/DecisionIntelligenceLeadForm/DecisionIntelligenceLeadForm.vue';
import { mapDecisionOfferAcknowledge } from '@/mappers/decision-intelligence-mapper';
import { IPaymentReferenceDetails } from '@/interfaces/make-a-payment/make-a-payment.interface';

// Define props and emitters
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'DecisionIntelligenceCrossSellOffers',
  props: {
    decisionOffer: {},
    stepTitle: {},
    quote: {},
    paymentReferenceDetails: {}
  },
  setup(__props: any) {

const props = __props;

// Lifecycle hooks
// ------------------------------------------------------------- //
onMounted(async () => {
  if (props.quote || props.paymentReferenceDetails) {
    await DecisionIntelligenceStore.acknowlegeDecisionOffer(
      props.decisionOffer?.id ?? '',
      mapDecisionOfferAcknowledge(Outcome.Surfaced, props.quote, props.paymentReferenceDetails)
    );
  }
});

// Define Component state
// ------------------------------------------------------------- //
const showLeadForm = ref([false, false]);
const isEmail = ref(false);
const currentAction = ref({} as IAction);
const DecisionIntelligenceStore = useDecisionIntelligenceStore();

const iconMap = {
  startIcon: require('@/assets/img/icons/star-icon.svg'),
};

// Define Functions
// ------------------------------------------------------------- //
const getFilterStep = (proposition: IProposition): IStep => {
  return proposition.displaySteps.filter((x) => x.stepName == props.stepTitle)[0];
};
const executeAction = (decisionId: string, action: IAction, proposition: IProposition, key: number) => {
  currentAction.value = action;
  if (action.actionType == 'Contact') {
    showLeadForm.value[key] = true;
    isEmail.value = false;
  }
  if (action.actionType == 'Email') {
    showLeadForm.value[key] = true;
    isEmail.value = true;
  }
  if (action.actionType == 'DigitalForm' || action.actionType == 'WebLink') {
    DecisionIntelligenceStore.acknowlegeDecisionOffer(
      decisionId,
      mapDecisionOfferAcknowledge(Outcome.Redirect, props.quote, props.paymentReferenceDetails, proposition, action)
    );
    const actionUrl =
      action.actionType == 'DigitalForm'
        ? `?crosssellquotereference=${props.quote ? props.quote.reference : props.paymentReferenceDetails?.prn}&propositionId${proposition.id}`
        : '';
    const linkUrl = `${action.linkUrl}${actionUrl}`;
    window.open(linkUrl, '_blank');
  }
};

return (_ctx: any,_cache: any) => {
  return (_ctx.decisionOffer?.propositions != null && _ctx.decisionOffer?.propositions.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_unref(DecisionIntelligenceStore).contentFields?.crossSellSubHeading.value), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.decisionOffer.propositions, (proposition, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(proposition.incentivised ? 'bg-racq-sky-blue rounded border-2 border-solid border-racq-white' : '')
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  src: iconMap.startIcon,
                  alt: "star"
                }, null, 8, _hoisted_4)
              ]),
              (proposition.incentivised)
                ? (_openBlock(), _createBlock(_unref(RichText), {
                    key: 0,
                    class: "text-xs text-base-bold text-racq-true-white",
                    field: _unref(DecisionIntelligenceStore).contentFields?.exclusiveHeading
                  }, null, 8, ["field"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass(
            proposition.incentivised
              ? 'p-8 shadow-md sm:flex-col md:flex-row'
              : 'p-8 rounded border border-solid border-racq-white shadow-md sm:flex-col md:flex-row'
          )
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", null, [
                      _createElementVNode("img", {
                        src: getFilterStep(proposition).imageUrl,
                        alt: "offer image",
                        width: "40"
                      }, null, 8, _hoisted_8)
                    ]),
                    _createElementVNode("h3", _hoisted_9, [
                      _createVNode(_unref(RichText), {
                        class: "-right-3",
                        field: { value: getFilterStep(proposition).headerText }
                      }, null, 8, ["field"])
                    ])
                  ]),
                  _createElementVNode("ul", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getFilterStep(proposition).crossSellOfferContent.benefitList, (benefit, key) => {
                      return (_openBlock(), _createElementBlock("li", { key: key }, [
                        _createVNode(_unref(RichText), {
                          field: { value: benefit.benefitText }
                        }, null, 8, ["field"])
                      ]))
                    }), 128))
                  ]),
                  (proposition.incentivised)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createVNode(_unref(RichText), {
                          field: { value: getFilterStep(proposition).crossSellOfferContent.incentiveMessageText }
                        }, null, 8, ["field"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getFilterStep(proposition).crossSellOfferContent.actionList, (action, key) => {
                      return (_openBlock(), _createElementBlock("button", {
                        key: key,
                        type: "button",
                        class: _normalizeClass(
                  key > 0
                    ? 'btn btn-secondary btn-no-caret mr-5 sm:mb-5 md:w-2/6 sm:w-full font-sans'
                    : 'btn btn-primary btn-no-caret mr-5 sm:mb-5 md:w-2/6 sm:w-full font-sans'
                ),
                        onClick: 
                  (e) => {
                    e.preventDefault();
                    executeAction(_ctx.decisionOffer?.id ? _ctx.decisionOffer?.id : '', action, proposition, index);
                  }
                
                      }, _toDisplayString(action.actionText), 11, _hoisted_13))
                    }), 128))
                  ]),
                  false
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, " Your session has timed out due to inactivity. You can now close the window. "))
                    : _createCommentVNode("", true),
                  (showLeadForm.value[index])
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _createVNode(DecisionIntelligenceLeadForm, {
                          proposition: proposition,
                          decisionId: _ctx.decisionOffer.id,
                          quote: props.quote,
                          currentAction: currentAction.value,
                          isEmail: isEmail.value
                        }, null, 8, ["proposition", "decisionId", "quote", "currentAction", "isEmail"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ], 2)
            ])
          ], 2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})