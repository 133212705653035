<template>
  <div v-show="formattedAmount.amount && formattedAmount.decimal" :class="[outerClass ?? 'font-sans']">
    <h1 :class="[innerClass ? `${innerClass} mb-1` : 'text-racq-dark-grey text-xl3 !font-medium mb-1']">
      <span>${{ formattedAmount.amount }}.{{ formattedAmount.decimal }}</span>
      <sup v-if="showStarSign">*</sup>
      <slot></slot>
    </h1>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { toCurrency } from '@/utils/convert';

const props = defineProps<{
  amount?: number | string;
  showStarSign: boolean;
  innerClass?: string;
  outerClass?: string;
}>();

const formattedAmount = computed(() => {
  try {
    if (!props.amount) return { amount: '', decimal: '' };
    const val = toCurrency(props.amount);
    return {
      amount: val.slice(1, val.lastIndexOf('.')),
      decimal: val.slice(val.lastIndexOf('.') + 1),
    };
  } catch (error) {
    return { amount: '', decimal: '' };
  }
});
</script>
