import {
  getServiceabilityRoadsideAssistanceJob,
  matchPartyRoadsideAssistanceJob,
  postCreateJobJoinOnRoad,
} from '@/constants/forms-api.const';
import { RequestServices as _http } from '@/services/common/request.services';
import {
  RoadsideAssistance,
  AssistanceMatchPartyRequest,
  AssistanceMatchPartyResponse,
} from '@/interfaces/entities/roadside-assistance.interface';
import {
  CreateRoadsideJobRequest,
  CreateRoadsideJobResponse,
} from '@/interfaces/entities/jor-create-rsa-job.interface';

export const RoadsideAssistanceService = {
  async getServiceability(latitude: number, longitude: number): Promise<RoadsideAssistance> {
    return await _http.HttpGet(
      getServiceabilityRoadsideAssistanceJob.replace('{0}', latitude.toString()).replace('{1}', longitude.toString())
    );
  },

  /**
   * Match Party Roadside Assistance Job
   * @param payload
   */
  async matchParty(payload: AssistanceMatchPartyRequest): Promise<AssistanceMatchPartyResponse> {
    return await _http.HttpPost(matchPartyRoadsideAssistanceJob, payload);
  },

  /**
   * Create roadside assistance job
   */
  async createJob(payload: CreateRoadsideJobRequest): Promise<CreateRoadsideJobResponse> {
    return await _http.HttpPost(postCreateJobJoinOnRoad, payload);
  },
} as const;
