<template>
  <main class="bg-racq-anti-flash-white flex flex-col grow">
    <div class="px-4 pt-6 pb-5 bg-white lg:hidden">
      <h3 v-if="pageTitle" :class="`md:hidden text-racq-navy m-0 text-xl2-bold`">{{ pageTitle }}</h3>
      <FormStepperBar v-if="isStepperVisible" class="mt-2 h-6 w-full justify-stretch items-center" />
    </div>

    <div
      id="base-form-container"
      class="mx-0 md:mx-auto mb-12 md:mb-14 xl:max-w-[784px] lg:max-w-[992px] md:max-w-[784px] w-full sm:mt-0 md:mt-8 lg:mt-14"
    >
      <FormStepper
        v-if="isStepperVisible && allPrelimStepsComplete"
        :steps="steps"
        :currentStepIndex="formStore.currentStepIndex"
      />
      <transition name="component-fade" mode="out-in">
        <!-- PRELIM STEPS  -->
        <div v-if="!allPrelimStepsComplete && !showJeopardy" :key="prelimStepIndex">
          <template v-for="(prelimStep, index) in prelimSteps" :key="prelimStep.id">
            <div v-if="index === prelimStepIndex">
              <FormStep :step="prelimStep">
                <component :is="prelimStep.component" :step="prelimStep" />
              </FormStep>
            </div>
          </template>
        </div>
        <!-- PRELIM STEPS END -->

        <!-- PRIMARY STEPS START -->
        <div v-else-if="allPrelimStepsComplete">
          <section
            v-for="(step, index) in steps"
            :id="step.id + index.toString()"
            :key="index"
            :class="[
              'relative js-scrollspy-form-step xl:w-full',
              {
                '!hidden md:!flex': index != formStore.currentStepIndex && step.isVisible,
                '!hidden': !step.isVisible,
              },
              floatBoxMounted ? 'lg:racq-step-width' : 'lg:racq-w-xl5 lg:mx-auto',
              step.isActive ? 'z-[1]' : 'z-[0]',
            ]"
            :dataStepName="step.stepGroupName || step.stepTitle"
          >
            <FormStep :step="step" :stepIndex="index + 1">
              <component :is="step.component" v-if="step.isVisible" :step="step" />
            </FormStep>
            <div
              v-if="step.isActive"
              :class="floatBoxMounted ? 'w-full lg:w-64 lg:absolute lg-down:!transform-none' : 'hidden'"
              :style="{
                transform: `translate(${floatBoxTranslate.x}px, -${floatBoxTranslate.y}px)`,
              }"
            >
              <slot name="floatBox"></slot>
            </div>
          </section>
        </div>
        <!-- PRIMARY STEPS END -->
      </transition>

      <slot name="bottomBox"></slot>

      <JeopardyV2 :fields="jpFields" />
    </div>
    <div class="mx-0 md:mx-auto -mt-5 px-6 md:px-0 max-w-[784px] w-full">
      <FormStepDisclaimers
        :fields="{
          defaultDisclaimer: activeStep?.stepDisclaimer,
          stepTitle: activeStep?.stepTitle,
        }"
      />
    </div>
  </main>

  <ModalDialog v-model="showTooltipModal" :closeOnEsc="true" :showXCloseButton="true" dialogClass="tooltip-dialog">
    <RichText v-if="tooltipModalTitle" :field="{ value: tooltipModalTitle }" class="tooltip-title" />
    <RichText :field="{ value: tooltipModalContent }" class="tooltip-body richtext" />
  </ModalDialog>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, getCurrentInstance } from 'vue';
import { ComponentFields, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { FormRenderingData } from '@/interfaces/pages/sitecore-props.interface';

import FormStepper from '@/components/FormStepper/FormStepper.vue';
import FormStepperBar from '@/components/FormStepperBar/FormStepperBar.vue';
import FormStep from '@/components/FormStep/FormStep.vue';
import JeopardyV2 from '@/components/JeopardyV2/JeopardyV2.vue';
import ModalDialog from '@/components/ModalDialog/ModalDialog.vue';
import FormStepDisclaimers from '@/components/FormStepDisclaimers/FormStepDisclaimers.vue';

import { IFormStep } from '@/interfaces';
import { useJeopardyStore, useFormStore } from '@/store';

// Define props and state
// ------------------------------------------------------------- //
const props = defineProps<{
  rendering: FormRenderingData;
  fields: ComponentFields;
  steps: IFormStep[];
  floatBoxMounted?: boolean;
}>();

const bodyHeightObserver = ref<ResizeObserver>();
const floatBoxTranslate = ref<{ x: number; y: number }>({ x: 0, y: 0 });
const showTooltipModal = ref(false);
const tooltipModalTitle = ref('');
const tooltipModalContent = ref('');

// Initialise stores
// ------------------------------------------------------------- //
const jpStore = useJeopardyStore();
const formStore = useFormStore();

// Computed Properties
// ------------------------------------------------------------- //
const showJeopardy = computed(() => jpStore.showJeopardy);
const prelimSteps = computed(() => formStore.prelimSteps);
const prelimStepIndex = computed(() => formStore.currentPrelimStepIndex);
const allPrelimStepsComplete = computed(() => formStore.allPrelimStepsComplete);
const formName = computed(() => props.rendering?.fields?.data?.datasource?.name || '');
const pageTitle = computed(() => formStore.pageTitle);
const isStepperVisible = computed(() => formStore.isStepperVisible.value);
const activeStep = computed(() =>
  formStore.allPrelimStepsComplete ? formStore.currentStep : formStore.currentPrelimStep
);

const app = getCurrentInstance();
const jpFields = app?.appContext.config.globalProperties.$jss.routeData().placeholders['jss-jeopardy'][0].fields;

onMounted(() => {
  bodyHeightObserver.value = new ResizeObserver(updateFloatBox);
  bodyHeightObserver.value.observe(document.getElementById('base-form-container')!);

  // Set the default Jeopardy to display for this form
  jpStore.setDefaultJeopardyPanel(jpFields);

  formStore.setFormName(formName.value);
});

onUnmounted(() => {
  bodyHeightObserver.value?.disconnect();
});

/**
 * Calculate Float Box Transform Values
 */
const updateFloatBox = () => {
  if (formStore.currentStep?.id) {
    const timer = setInterval(() => {
      const currentStepContainer = document.querySelector(
        `#${formStore.currentStep!.id! + formStore.currentStepIndex.toString()} > div`
      );
      if (currentStepContainer) {
        if (currentStepContainer?.clientHeight) clearInterval(timer);

        floatBoxTranslate.value.x = currentStepContainer.clientWidth + 30;
        floatBoxTranslate.value.y = currentStepContainer.clientHeight + 20;
      }
    }, 100);
  }
};

/**
 * Add as global function to be able to access by tooltip plugin
 */
(window as any).showRACQTooltipModal = (title: string, content: string) => {
  showTooltipModal.value = true;
  tooltipModalTitle.value = title;
  tooltipModalContent.value = content;
};

(window as any).hideRACQTooltipModal = () => {
  showTooltipModal.value = false;
};
</script>
