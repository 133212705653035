import { defineStore } from 'pinia';
import { ReferenceDataServices as _refData } from '@/services/store/reference-data.services';
import { ref } from 'vue';
import { INameValue } from '@/interfaces/entities/name-value.interface';
import { IFaultClaim } from '@/interfaces/entities/motor-fault-claim.interface';

enum RefDataTypes {
  ACCESSORIES_MODIFICATION = 'AccessoriesAndModification',
  AT_FAULT_CLAIM_TYPES = 'AtFaultClaimTypes',
  AT_FAULT_CLAIM_VALUES = 'AtFaultClaimValues',
  CANCELLATION_TYPES = 'CancellationTypes',
  PARKING_LOCATIONS = 'ParkingLocations',
  BUSINESS_USE_TYPES = 'BusinessUseTypes',
  FINANCE_TYPES = 'FinanceTypes',
  DRIVER_LICENSE_YEARS_HELD = 'DriverLicenseYearsHeldRanges',
}

export const useReferenceDataIQMotorStore = defineStore('ReferenceDataIQMotor', () => {
  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //
  const refData = ref<Partial<Record<RefDataTypes, INameValue[]>>>({});

  /**
   * Get accessories and modifications
   *
   * @param quoteType
   * @returns
   */
  async function getAccessoriesAndModification(): Promise<INameValue[]> {
    if (refData.value.AccessoriesAndModification?.length) return refData.value.AccessoriesAndModification;
    try {
      const res = await _refData.getCombinedAccessoriesList();
      refData.value.AccessoriesAndModification = res || [];
    } catch (error) {
      console.warn('Unable to fetch accessories and modification: ', error);
    }
    return refData.value.AccessoriesAndModification || [];
  }

  /**
   * Get claim types
   *
   * @param quoteType
   * @returns
   */
  async function getAtFaultClaimTypes(): Promise<INameValue[]> {
    if (refData.value.AtFaultClaimTypes?.length) return refData.value.AtFaultClaimTypes;
    try {
      const res = await _refData.getAtFaultClaimTypes();
      refData.value.AtFaultClaimTypes = res || [];
    } catch (error) {
      console.warn('Unable to fetch claim types: ', error);
    }
    return refData.value.AtFaultClaimTypes || [];
  }

  /**
   * Get claim types
   *
   * @param quoteType
   * @returns
   */
  async function getAtFaultClaimValues(): Promise<IFaultClaim[]> {
    if (refData.value.AtFaultClaimValues?.length) return refData.value.AtFaultClaimValues;
    try {
      const res = await _refData.getAtFaultClaimValues();
      refData.value.AtFaultClaimValues = res || [];
    } catch (error) {
      console.warn('Unable to fetch claim values: ', error);
    }
    return refData.value.AtFaultClaimValues || [];
  }

  /**
   * Get claim types
   *
   * @param quoteType
   * @returns
   */
  async function getCancellationTypes(): Promise<INameValue[]> {
    if (refData.value.CancellationTypes?.length) return refData.value.CancellationTypes;
    try {
      const res = await _refData.getLicenceCancellationTypes();
      refData.value.CancellationTypes = res || [];
    } catch (error) {
      console.warn('Unable to fetch cancellation types: ', error);
    }
    return refData.value.CancellationTypes || [];
  }

  /**
   * Get parking locations
   *
   * @param quoteType
   * @returns
   */
  async function getParkingLocations(): Promise<INameValue[]> {
    if (refData.value.ParkingLocations?.length) return refData.value.ParkingLocations;
    try {
      const res = await _refData.getParkingLocations();
      refData.value.ParkingLocations = res || [];
    } catch (error) {
      console.warn('Unable to fetch parking locations: ', error);
    }
    return refData.value.ParkingLocations || [];
  }

  async function getBusinessUseTypes(): Promise<INameValue[]> {
    if (refData.value.BusinessUseTypes?.length) return refData.value.BusinessUseTypes;
    try {
      const res = await _refData.getBusinessUseTypes();
      refData.value.BusinessUseTypes = res || [];
    } catch (error) {
      console.warn('Unable to fetch business use types: ', error);
    }
    return refData.value.BusinessUseTypes || [];
  }

  async function getFinanceTypes(): Promise<INameValue[]> {
    if (refData.value.FinanceTypes?.length) return refData.value.FinanceTypes;
    try {
      const res = await _refData.getFinanceTypes();
      refData.value.FinanceTypes = res || [];
    } catch (error) {
      console.warn('Unable to fetch business use types: ', error);
    }
    return refData.value.FinanceTypes || [];
  }

  async function getDriverLicenseYearsHeldRanges(): Promise<INameValue[]> {
    if (refData.value.DriverLicenseYearsHeldRanges?.length) return refData.value.DriverLicenseYearsHeldRanges;
    try {
      const res = await _refData.getDriverLicenseYearsHeldRanges();
      refData.value.DriverLicenseYearsHeldRanges = res || [];
    } catch (error) {
      console.warn('Unable to fetch DriverLicenseYearsHeldRanges: ', error);
    }
    return refData.value.DriverLicenseYearsHeldRanges || [];
  }

  return {
    getAccessoriesAndModification,
    getAtFaultClaimTypes,
    getAtFaultClaimValues,
    getCancellationTypes,
    getParkingLocations,
    getBusinessUseTypes,
    getFinanceTypes,
    getDriverLicenseYearsHeldRanges,
  };
});
