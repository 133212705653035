<template>
  <FormKit type="form" :actions="false" :incompleteMessage="false" @submit="onSubmit">
    <div class="px-4 pt-5">
      <div class="p-5 rounded bg-racq-white-background flex flex-wrap md:mb-2">
        <div class="flex lg:w-4/5">
          <div class="mr-4">
            <img
              :src="accountInfoBoxSettings.icon"
              alt="user profile icon"
              role="img"
              class="min-w-12 w-12 min-h-12 h-12"
            />
          </div>
          <div class="text-base mb-4 md:mb-0">
            <span :data-testid="loyaltyLevelResponse?.useHeading" v-html="heading"></span>
            <span
              v-if="loyaltyLevelResponse"
              class="input-spacing text-base contents"
              :data-testid="loyaltyLevelResponse?.useLoyaltyText"
              v-html="loyaltyLevelText"
            ></span>
          </div>
        </div>
      </div>

      <RichText
        v-if="loyaltyLevelResponse"
        class="input-spacing text-lg pt-[24px]"
        :field="{ value: newQuoteCostText }"
        :data-testid="loyaltyLevelResponse?.useNewQuoteCostText"
      />

      <div v-if="loyaltyLevelResponse">
        <button type="button" class="link text-m" @click="showWhyQuoteChangedModal = true">
          {{ quoteChangedModalData?.modalTriggerLinkText?.value }}
        </button>
      </div>

      <RichText
        v-if="loyaltyLevelResponse && discountText"
        class="input-spacing text-lg"
        :field="{ value: discountText }"
        :data-testid="loyaltyLevelResponse?.discountText"
      />

      <RichText
        v-if="loyaltyLevelResponse"
        class="input-spacing text-sm"
        :field="{ value: helpText }"
        :data-testid="loyaltyLevelResponse?.useHelpText"
      />

      <ModalDialog
        :modelValue="showWhyQuoteChangedModal"
        :showXCloseButton="true"
        :closeOnEsc="false"
        dialogClass="tooltip-dialog"
        @update:modelValue="(value) => onModalClose(value)"
      >
        <div class="tooltip-title">{{ quoteChangedModalData?.modalTitle?.value }}</div>
        <div class="tooltip-body richtext" v-html="quoteChangedModalData?.modalBody?.value"></div>
      </ModalDialog>
    </div>
    <FormStepFooter :step="step" :btnNextText="step.btnNext" :btnPrevText="step.btnPrev" />
  </FormKit>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import FormStepFooter from '@/components/FormStepFooter/FormStepFooter.vue';
import { IFormStep } from '@/interfaces';
import { useAnalyticsStore, useFormStore, useJeopardyStore } from '@/store';
import { replaceLoyaltyReviewBookmarks } from '@/forms/insurance/quote/home-and-contents-purchase/mappings/loyalty-review-mapping';
import useFormInputData from '@/composables/useFormInputData';
import { displayLoyaltyReview, loyaltyReviewContent } from '@/utils/display-rules/loyalty-rules';
import { IQuote } from '@/interfaces';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { FormEvents } from '@/enums/form-events.enum';
import ModalDialog from '../ModalDialog/ModalDialog.vue';

const formStore = useFormStore();
const jpStore = useJeopardyStore();
const analyticsStore = useAnalyticsStore();

const props = defineProps<{
  step: IFormStep;
  quote: Partial<IQuote>;
  originalQuote?: Partial<IQuote>;
}>();

const loyaltyLevelResponse = ref<any>();
const heading = ref<string>('');
const loyaltyLevelText = ref<string>('');
const discountText = ref<string>('');
const newQuoteCostText = ref<string>('');
const helpText = ref<string>('');

const quoteChangedModalData = ref<any>();

const showWhyQuoteChangedModal = ref<boolean>(false);

const accountInfoBoxSettings = ref<Record<string, any>>({
  icon: require('@/assets/img/icons/user-icon.svg'),
  unauthenticatedButtonText: '',
  unauthenticatedGreetings: heading,
  params: {
    authenticated: false,
    firstName: '',
  },
});

onMounted(() => {
  if (!props.quote || !props.quote.quoteType) return;

  quoteChangedModalData.value = props.step.formInputs?.modalWhyHasMyQuoteChanged?.datasource?.fields;

  const { formInputData } = useFormInputData(props.step.formInputs!);

  loyaltyLevelResponse.value = displayLoyaltyReview(props.quote as IQuote, props.originalQuote as IQuote);

  heading.value = replaceLoyaltyReviewBookmarks(
    formInputData(loyaltyLevelResponse.value.useHeading).label,
    props.quote
  );

  loyaltyLevelText.value = replaceLoyaltyReviewBookmarks(
    formInputData(loyaltyLevelResponse.value.useLoyaltyText).label,
    props.quote
  );

  /*
  In sitecore 8 this is only used for Car Hire it seems. litDiscount.Text = string.Format(eligibleForFreeCarHire ? CurrentItem.GetFieldValue("RVMEntitlementWithHireCar_Text") : CurrentItem.GetFieldValue("RVMEntitlementNoHireCar_Text"), discount);
  A content field called textDiscount will need to be added to any step needing this.
  */
  discountText.value = replaceLoyaltyReviewBookmarks(
    formInputData(loyaltyReviewContent.textDiscount).label,
    props.quote
  );

  newQuoteCostText.value = replaceLoyaltyReviewBookmarks(
    formInputData(loyaltyReviewContent.textNewQuoteCost).label,
    props.quote
  );

  helpText.value = replaceLoyaltyReviewBookmarks(
    formInputData(loyaltyLevelResponse.value.useHelpText).label,
    props.quote
  );

  analyticsStore.pushAnalytics({
    event: FormEvents.START,
    form: {},
  });
});

async function onSubmit() {
  try {
    formStore.showLoadingForCurrentStep();
    formStore.showLoadingForCurrentStep(false);
    formStore.goNextStep();
  } catch (jPCode: any) {
    formStore.showLoadingForCurrentStep(false);
    showJeopardyPanel(jPCode);
  }
}

function showJeopardyPanel(jpCode: string) {
  jpStore.showJeopardyPanel({
    jpCode: jpCode,
  });
}

function onModalClose(e: any) {
  showWhyQuoteChangedModal.value = e;
}
</script>
