import { ImageField } from '@sitecore-jss/sitecore-jss-vue/types/components/Image';
import { ISitecoreFormGenericInput } from '@/interfaces';
import { IRadioButtonCard } from '@/interfaces/entities/radio-button-card.interface';

interface TooltipProps {
  title?: string;
  content: string;
  tooltipId: string;
  // Vue 2 migration alert: this code might not be required
  // this is an edge case where a '%' symbol is required before the tooltip trigger
  // not ideal, but Vue Formulate is very hard to modify otherwise
  contentBeforeTrigger?: string;
  tooltipOuterId?: string;
  isModal?: boolean;
}

export interface VueFormInputProps {
  label: string;
  name: string;
  type: string;
  placeholder: string;
  postContent?: string;
  preContent?: string;
  postContentClass?: string;
  preContentClass?: string;
  tooltip?: TooltipProps;
  format?: string;
  fields?: ISitecoreFormGenericInput[];
  emptyMessage?: string;
  validation?: any[][];
  validationMessages?: Record<string, string>;
  validationLabel?: string;
  validationVisibility?: string;
  validationRegex?: string;
  innerClass?: string;
  outerClass?: string;
  options?: { label: string; value: any; help?: string; image?: ImageField }[];
  defaultOption?: string;
  help?: string;
  helpSm?: string;
  helpMd?: string;
  showPostContent?: boolean;
  loading?: boolean;
  refData?: string;
  value?: any;
  prefixIcon?: string;
  suffixIcon?: string;
  mask?: string;
  disablePaste?: boolean;
  pickerOnly?: boolean;
  radioGroupClass?: string;
  classesOptions?: string;
  decimals?: string;
  disabled?: boolean;
  keepPlaceHolder?: boolean;
  showClearIcon?: boolean;
}

function setupFormKitClass(vBindFormData: VueFormInputProps, formData: ISitecoreFormGenericInput) {
  if (formData?.innerClass?.value) {
    vBindFormData.innerClass = formData?.innerClass?.value;
  }
  if (formData?.outerClass?.value) {
    vBindFormData.outerClass = formData?.outerClass?.value;
  }
}

function setupValidation(vBindFormData: VueFormInputProps, formData: ISitecoreFormGenericInput) {
  /** Format Validation */
  if (formData?.isRequired?.value) {
    vBindFormData.validation = [...(vBindFormData.validation || []), ['required']];

    if (formData?.isRequiredErrorMessage?.value) {
      vBindFormData.validationMessages = {
        ...(vBindFormData.validationMessages || {}),
        required: formData?.isRequiredErrorMessage?.value,
      };
    }
  }

  // Regex validation
  if (formData?.validationRegex?.value) {
    vBindFormData.validation = [
      ...(vBindFormData.validation || []),
      ['matches', new RegExp(formData.validationRegex.value)],
    ];

    vBindFormData.validationRegex = formData?.validationRegex?.value;

    if (formData?.validationRegexErrorMessage?.value) {
      vBindFormData.validationMessages = {
        ...(vBindFormData.validationMessages || {}),
        matches: formData?.validationRegexErrorMessage?.value,
      };
    }
  }

  // Custom validation
  if (formData?.validation?.value) {
    try {
      const params = new URLSearchParams(formData.validation.value);
      params.forEach((key, value) => {
        const payload: { message: string; regex?: string } = JSON.parse(key);

        if (payload.regex) {
          vBindFormData.validation = [...(vBindFormData.validation || []), [value, new RegExp(payload.regex)]];
        } else {
          vBindFormData.validation = [...(vBindFormData.validation || []), [value]];
        }

        if (payload.message) {
          vBindFormData.validationMessages = {
            ...(vBindFormData.validationMessages || {}),
            [value]: payload.message,
          };
        }
      });
    } catch (error) {
      console.warn('Unable to format field validation: ', error);
    }
  }
}

function setupAck(vBindFormData: VueFormInputProps, formData: ISitecoreFormGenericInput) {
  // Must accept for checkbox ack
  if (formData?.mustAccept?.value) {
    vBindFormData.validation = [...(vBindFormData.validation || []), ['accepted']];

    vBindFormData.validationVisibility = 'dirty';

    if (formData?.mustAcceptErrorMessage?.value) {
      vBindFormData.validationMessages = {
        ...(vBindFormData.validationMessages || {}),
        accepted: formData?.mustAcceptErrorMessage?.value,
      };
    }
  }
}

/**
 * Convert a form field in Sitecore into an Object suitable for FormKit.
 *
 * @param formData
 * @param keyName
 * @returns
 */
export function convertSitecoreFormFieldToVueFormField(
  formData: ISitecoreFormGenericInput,
  keyName: string
): VueFormInputProps {
  const vBindFormData: VueFormInputProps = {
    label: formData?.label?.value ?? '',
    validationLabel: formData?.label?.value ?? '',
    name: formData?.formInputId?.value || 'FormInputId mising in SC - keyName:' + keyName,
    type: formData?.inputType?.value || 'FormInputType missing in SC - keyName:' + keyName,
    placeholder: formData?.placeholderText?.value ?? '',
    postContent: formData?.postContent?.value,
    preContent: formData?.preContent?.value,
    postContentClass: formData?.postContentClass?.value,
    preContentClass: formData?.preContentClass?.value,
    showPostContent: formData?.showPostContent,
    refData: formData?.refData?.value,
    prefixIcon: formData?.prefixIcon?.value,
    suffixIcon: formData?.suffixIcon?.value,
    help: formData?.helpXs?.value,
    helpSm: formData?.helpSm?.value,
    helpMd: formData?.helpMd?.value,
    disablePaste: formData?.disablePaste?.value,
    classesOptions: formData?.classesOptions?.value,
    decimals: formData?.decimals?.value,
    showClearIcon: formData?.showClearIcon?.value,
  };

  setupFormKitClass(vBindFormData, formData);
  setupValidation(vBindFormData, formData);
  setupAck(vBindFormData, formData);

  if (formData?.options?.value) {
    const urlSearchParams = new URLSearchParams(formData?.options?.value?.replaceAll('+', '%2B'));
    const options = [];
    for (const [label, value] of urlSearchParams) {
      options.push({ label: label, value: value });
    }
    vBindFormData.options = options;
  } else if (formData?.inputType?.value === 'dropdown') {
    vBindFormData.options = [];
    const dropdownFields = formData.fields as DropdownOption[];
    if (dropdownFields) {
      vBindFormData.options = dropdownFields.map((option) => {
        return {
          label: option.Label?.value ?? '',
          value: option.Value?.value,
          help: option.HelpText?.value,
          image: option.Image,
        };
      });
    }
  }

  if (formData?.defaultOption?.value) {
    vBindFormData.defaultOption = formData?.defaultOption?.value;
  }

  if (formData?.tooltipContent?.value) {
    vBindFormData.tooltip = {
      title: formData?.tooltipModalTitle?.value,
      content: formData?.tooltipContent?.value,
      tooltipId: formData?.formInputId?.value + '__tooltip_id',
      tooltipOuterId: formData?.formInputId?.value + '__outer',
      isModal: formData?.tooltipShowInModal?.value,
    };
  }

  if (formData?.emptyMessage?.value) {
    vBindFormData.emptyMessage = formData.emptyMessage.value;
  }
  if (formData?.mask?.value) {
    vBindFormData.mask = formData?.mask?.value;
  }
  if (formData?.defaultOption?.value) {
    vBindFormData.value = formData?.defaultOption?.value;
  }
  if (formData?.format?.value) {
    vBindFormData.format = formData?.format?.value;
  }
  if (formData?.pickerOnly?.value) {
    vBindFormData.pickerOnly = formData?.pickerOnly?.value;
  }

  return vBindFormData;
}

// #region RadioButtonCardGroup
interface RadioButtonCardGroup {
  GroupName: { value: string };
  fields: RadioButtonCard[];
}

interface RadioButtonCard {
  Value: { value: string };
  CallOutTag: { value: string };
  BodyImage: { value: { src: string; alt: string } };
  BodyIcon: { value: { src: string; alt: string } };
  ShowLeftRadioButton: { value: boolean };
  LeftTitle: { value: string };
  LeftDescription: { value: string };
  RightTitle: { value: string };
  RightDescription: { value: string };
  ShowRightRadioButton: { value: boolean };
  VisibleBodyDescription: { value: string };
  HiddenBodyDescription: { value: string };
  ShowMoreLinkLabel: { value: string };
  ShowLessLinkLabel: { value: string };
  innerClass: { value: string };
  outerClass: { value: string };
  bottomTagText: { value: string };
  showBottomTag: { value: boolean };
  BodyBottomClass: { value: string };
}

function convertSitecoreToVueRadioButtonCardOptions(
  formData: ISitecoreFormGenericInput,
  selectedOption?: string,
  bindings?: any
): IRadioButtonCard[] {
  const radioOptions: IRadioButtonCard[] = [];

  if (formData?.inputType?.value === 'radiobuttoncardgroup') {
    const radioButtonCardGroup = formData as unknown as RadioButtonCardGroup;

    return radioButtonCardGroup.fields.map((radioButtonCard: RadioButtonCard) => {
      const rbcItem: IRadioButtonCard = {
        bindings: bindings,
        modelValue: selectedOption,
        value: radioButtonCard.Value.value,
        groupName: radioButtonCardGroup.GroupName.value,
        leftTitle: radioButtonCard.LeftTitle.value,
        bodyIcon: radioButtonCard.BodyIcon.value.src,
        bodyIconAlt: radioButtonCard.BodyIcon.value.alt,
        bodyImage: radioButtonCard.BodyImage.value.src,
        bodyImageAlt: radioButtonCard.BodyImage.value.alt,
        callOutTag: radioButtonCard.CallOutTag.value,
        rightTitle: radioButtonCard.RightTitle.value,
        leftDescription: radioButtonCard.LeftDescription.value,
        rightDescription: radioButtonCard.RightDescription.value,
        visibleBodyDescription: radioButtonCard.VisibleBodyDescription.value,
        hiddenBodyDescription: radioButtonCard.HiddenBodyDescription.value,
        showLeftRadioButton: radioButtonCard.ShowLeftRadioButton.value,
        showLessLinkLabel: radioButtonCard.ShowLessLinkLabel.value,
        showMoreLinkLabel: radioButtonCard.ShowMoreLinkLabel.value,
        showRightRadioButton: radioButtonCard.ShowRightRadioButton.value,
        innerClass: radioButtonCard.innerClass.value,
        outerClass: radioButtonCard.outerClass.value,
        bottomTagText: radioButtonCard.bottomTagText.value,
        bodyBottomClass: radioButtonCard.BodyBottomClass.value,
        showBottomTag: radioButtonCard.showBottomTag.value,
      };

      return rbcItem;
    });
  }

  return radioOptions;
}
// #endregion

// #region CheckboxList
interface CheckboxList {
  GroupName: { value: string };
  fields: CheckboxListOption[];
}

interface CheckboxListOption {
  Label?: { value: string };
  Value?: { value: string };
  HelpText?: { value: string };
}

interface DropdownOption {
  Label?: { value: string };
  Value?: { value: string };
  HelpText?: { value: string };
  Image?: ImageField;
}

function convertSitecoreToVueCheckboxListOptions(
  formData: ISitecoreFormGenericInput
): { label: string; value: any; help?: string }[] {
  const checkboxOptions: { label: string; value: any; help?: string }[] = [];
  if (formData?.inputType?.value === 'checkbox') {
    const checkboxList = formData as unknown as CheckboxList;

    return checkboxList.fields.map((checkboxListOption: CheckboxListOption) => {
      const cbItem: { label: string; value: any; help?: string } = {
        label: checkboxListOption.Label?.value ?? '',
        value: checkboxListOption.Value?.value,
        help: checkboxListOption.HelpText?.value,
      };

      return cbItem;
    });
  }

  return checkboxOptions;
}

export function getAdobeTargetDataAttributes(input: Record<string, any>, stepName: string) {
  return {
    'data-component-type': input.name + '_' + input.id,
    'data-component-datasource': stepName,
  };
}
// #endregion

export default function useFormInputData(formInputs: Record<string, ISitecoreFormGenericInput>) {
  /**
   * Create an Object with all VueFormulate fields mapped from Sitecore.
   *
   * @param {string} keyName The policy data payload key name (mapped as ID in Sitecore)
   * @param {number} index Use if you have multiple instances of the form input on the form, to set a unique ID
   */
  function formInputData(keyName: string, index?: number) {
    const formData = formInputs[keyName];
    const vBindFormData = convertSitecoreFormFieldToVueFormField(formData, keyName);

    // This is when we have a custom component that is a group of fields.
    // For example: Find your car is a group of inputs grouped by a "custom" input type
    if (formData?.inputType?.value === 'custom' && formData?.fields?.length) {
      vBindFormData.fields = formData.fields;
    }

    return vBindFormData;
  }

  return {
    formInputData,
    convertSitecoreToVueRadioButtonCardOptions,
    convertSitecoreToVueCheckboxListOptions,
    getAdobeTargetDataAttributes,
  };
}
