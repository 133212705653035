<template>
  <header class="top-0 sticky z-10 header-container lg:border-b border-solid border-light-grey bg-racq-true-white">
    <div class="forms-header w-full">
      <div class="header-logo">
        <sc-link style="font-size: 0" class="flex" :field="fields.logoLink ? fields.logoLink : {}">
          <sc-image :media="fields.racqLogo" />
        </sc-link>
      </div>
      <h1 class="form-title"><sc-text :field="pageTitle" /></h1>
      <div class="hidden ml-auto mr-[18px] lg:block xl:hidden w-[300px]">
        <FormStepperBar v-if="formStore.showStepper" />
      </div>
      <div class="help-content">
        <sc-link :field="fields.helpContentLink ? fields.helpContentLink : {}">
          <sc-image :media="fields.helpLogo" />
        </sc-link>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import {
  Text as ScText,
  Image as ScImage,
  Link as ScLink,
  ComponentFields,
  ComponentRendering,
  Field,
} from '@sitecore-jss/sitecore-jss-vue';
import FormStepperBar from '@/components/FormStepperBar/FormStepperBar.vue';
import { useFormStore } from '@/store';

const formStore = useFormStore();

const props = withDefaults(
  defineProps<{
    fields: ComponentFields;
    rendering: ComponentRendering;
    pageTitle?: string;
  }>(),
  { pageTitle: '' }
);

const pageTitle = computed(() => {
  const pageTitle = props.fields?.pageTitle as Field<any>;
  return pageTitle?.value ? pageTitle : { value: props.pageTitle };
});

onMounted(() => {
  const pageTitleField = pageTitle.value;
  formStore.setPageTitle(pageTitleField.value);
});
</script>
