export interface ICreateLeadRequestModel {
  leadAdditionalAttributes: ILeadAdditionalAttributes[];
}

export interface ILeadAdditionalAttributes {
  typeCode: string;
  value: any;
}
export interface ICreateLeadResponseModel {
  leadId: string;
}

export const LeadAttributes = {
  sourceCode: 'SourceCode',
  campaign: 'Campaign',
  channel: 'Channel',
  preferedContactDate: 'PreferedContactDate',
  preferedContactTimeofDay: 'PreferedContactTimeofDay',
  areaOfInterest: 'AreaOfInterest',
  productType: 'ProductType',
  purpose: 'Purpose',
  postCode: 'PostCode',
  stage: 'ProductType',
  assignTo: 'Assign to',
};
export interface IAdobeDataLayerLead extends ICreateLeadRequestModel {
  leadId: string;
  request: string;
}
