import {
  getLoginUrl,
  authenticationUrl,
  isAuthenticatedUrl,
  logoutUrl,
  isProduction,
  localURL,
  getSelfServiceAccountOverviewUrl,
  getIdentityDetails,
  getQueryIdentity,
  getLoyaltyType,
  getResidentialAddress,
} from '@/constants/forms-api.const';
import { RequestServices as _http } from '@/services/common/request.services';
import { IdentityDetails } from '@/interfaces';
import { Address } from '@/interfaces/entities/jor-create-rsa-job.interface';

// Some methods share the base QueryIdentity functionality and params (membershipCardNumber, externalSystem, externalSystemId)
// This function encapsulates that common functionality.
function baseQueryIdentityParams(
  membershipCardNumber: string | null,
  externalSystem: string | null,
  externalSystemId: string | null
): URLSearchParams {
  const params = new URLSearchParams();

  if (membershipCardNumber) {
    params.append('membershipCardNumber', membershipCardNumber);
  }
  if (externalSystem) {
    params.append('externalSystem', externalSystem);
  }
  if (externalSystemId) {
    params.append('externalSystemId', externalSystemId);
  }
  return params;
}

export const AccountServices = {
  /**
   * Fetch Identity Details
   * @returns
   */
  fetchIdentityDetails(): Promise<IdentityDetails> {
    return _http.HttpGet(getIdentityDetails);
  },

  /**
   * Get account overview URL
   * @returns
   */
  getSelfServiceAccountOverviewPage(): Promise<string> {
    return _http.HttpGet(`${getSelfServiceAccountOverviewUrl}`);
  },

  async getQueryIdentity(
    membershipCardNumber: string | null,
    externalSystem: string | null,
    externalSystemId: string | null
  ): Promise<any> {
    const queryString = baseQueryIdentityParams(membershipCardNumber, externalSystem, externalSystemId).toString();
    const url = queryString ? `${getQueryIdentity}?${queryString}` : getQueryIdentity;

    return await _http.HttpGet(url);
  },

  async getLoyaltyType(
    membershipCardNumber: string | null,
    externalSystem: string | null,
    externalSystemId: string | null
  ): Promise<string> {
    const queryString = baseQueryIdentityParams(membershipCardNumber, externalSystem, externalSystemId).toString();
    const url = queryString ? `${getLoyaltyType}?${queryString}` : getLoyaltyType;

    return await _http.HttpGet(url);
  },

  async getResidentialAddress(
    membershipCardNumber: string | null,
    externalSystem: string | null,
    externalSystemId: string | null
  ): Promise<Address> {
    const queryString = baseQueryIdentityParams(membershipCardNumber, externalSystem, externalSystemId).toString();
    const url = queryString ? `${getResidentialAddress}?${queryString}` : getResidentialAddress;

    return await _http.HttpGet(url);
  },

  /**
   * Get Login URL
   * @param currentUrl
   * @returns
   */
  getLoginUrl(currentUrl: string): Promise<string> {
    const url = getLoginUrl.replace('{0}', encodeURIComponent(currentUrl));
    return _http.HttpGet(`${url}`);
  },

  authenticate(code: string): Promise<{ success: boolean; error: string }> {
    return _http.HttpPost(`${authenticationUrl}`, {
      code: code,
      redirect_uri: isProduction ? '' : localURL,
    });
  },

  /**
   * Check login status in sitecore 10
   * @returns
   */
  isAuthenticated(): Promise<boolean> {
    return _http.HttpGet(`${isAuthenticatedUrl}`);
  },

  /**
   * Logout sitecore 10
   * @returns
   */
  logOut(): Promise<string> {
    return _http.HttpGet(`${logoutUrl}`);
  },
} as const;
