<template>
  <FormKit v-model="value" type="group" :name="bindings.name">
    <div v-if="messagesLoaded" :id="bindings.id + '__outer'" :class="bindings.outerClass">
      <FormKit
        v-bind="bindings"
        ref="titleRef"
        :label="placeholders[0] || 'Title'"
        :validationLabel="placeholders[0] || 'Title'"
        placeholder="Please select"
        type="dropdown"
        :options="titles"
        name="title"
        outerClass="pb-0 input-spacing"
        placeholderClass="pr-1"
        optionClass="pr-1"
        :innerClass="bindings.innerClass || 'input-xs'"
        :validation="bindings.validation"
        :validationMessages="titleValidationMessage"
        :aria-label="bindings.name"
      />

      <FormKit
        ref="firstNameRef"
        :label="placeholders[1] || 'First name'"
        :validationLabel="placeholders[1] || 'First name'"
        type="text"
        name="firstName"
        outerClass="pb-0 input-spacing"
        :innerClass="bindings.innerClass || 'input-md'"
        :validation="firstnameValidation"
        :validationMessages="firstnameValidationMessage"
        :aria-label="bindings.name"
      />

      <FormKit
        ref="lastNameRef"
        :label="placeholders[2] || 'Last name'"
        :validationLabel="placeholders[2] || 'Last name'"
        type="text"
        name="lastName"
        outerClass="pb-0"
        :innerClass="bindings.innerClass || 'input-md'"
        :validation="lastnameValidation"
        :validationMessages="lastnameValidationMessage"
        :aria-label="bindings.name"
      />
    </div>
  </FormKit>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from 'vue';
import { useReferenceDataStore } from '@/store';
import { IDropdownItem } from '@/interfaces';
import { FormKit } from '@formkit/vue';
import { cloneDeep } from 'lodash-es';

const props = defineProps<{
  bindings: any;
  titleRequiredMessage?: string;
  firstnameRequiredErrorMessage?: string;
  firstnameRegex?: string;
  firstnameRegexErrorMessage?: string;
  lastnameRequiredErrorMessage?: string;
  lastnameRegex?: string;
  lastnameRegexErrorMessage?: string;
}>();

const value = defineModel<{
  title: string;
  firstName: string;
  lastName: string;
}>({ required: false });
const refDataStore = useReferenceDataStore();
const titles = ref<IDropdownItem[]>([]);
const placeholders = ref<string[]>([]);
const titleRef = ref();
const firstNameRef = ref();
const lastNameRef = ref();
const messagesLoaded = ref(false);

const titleValidationMessage = ref<Record<string, string>>(cloneDeep(props.bindings.validationMessages) || {});
const firstnameValidationMessage = ref<Record<string, string>>(cloneDeep(props.bindings.validationMessages) || {});
const firstnameValidation = ref<any[]>(props.bindings.validation || []);
const lastnameValidationMessage = ref<Record<string, string>>(cloneDeep(props.bindings.validationMessages) || {});
const lastnameValidation = ref<any[]>(props.bindings.validation || []);

onBeforeMount(async () => {
  const res = await refDataStore.getPersonTitles();
  titles.value = res.map((item) => ({
    label: item.name,
    value: item.value,
  }));
  if (props?.bindings?.placeholder) {
    placeholders.value = props.bindings.placeholder.split(',');
  }

  if (props.titleRequiredMessage) {
    titleValidationMessage.value.required = props.titleRequiredMessage;
  }

  if (props.firstnameRegexErrorMessage) {
    firstnameValidationMessage.value.matches = props.firstnameRegexErrorMessage;
  }

  if (props.firstnameRequiredErrorMessage) {
    firstnameValidationMessage.value.required = props.firstnameRequiredErrorMessage;
  }

  if (props.lastnameRegexErrorMessage) {
    lastnameValidationMessage.value.matches = props.lastnameRegexErrorMessage;
  }

  if (props.lastnameRequiredErrorMessage) {
    lastnameValidationMessage.value.required = props.lastnameRequiredErrorMessage;
  }

  if (props.firstnameRegex) {
    firstnameValidation.value = [...firstnameValidation.value, ['matches', new RegExp(props.firstnameRegex)]];
  }

  if (props.lastnameRegex) {
    lastnameValidation.value = [...lastnameValidation.value, ['matches', new RegExp(props.lastnameRegex)]];
  }

  messagesLoaded.value = true;
});
</script>
