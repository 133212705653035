import { IPaymentDetails } from './payment-details';
import { ICardDetails } from './card-details';
import { IDirectDebitDetails } from './direct-debit-details';

export interface ISubmitQuote {
  paymentDetails: IPaymentDetails;
}

export class SubmitQuote implements ISubmitQuote {
  paymentDetails: IPaymentDetails;

  constructor() {
    this.paymentDetails = {} as IPaymentDetails;
  }

  createSubmitQuoteUsingCardPaymentDetails(paymentStoreCardDetails: any): ISubmitQuote {
    const cardDetails: ICardDetails = {
      cardType: paymentStoreCardDetails.cardType,
      cardHoldersName: paymentStoreCardDetails.cardHoldersName,
      cardExpiryMonth: paymentStoreCardDetails.cardExpiryMonth,
      cardExpiryYear: paymentStoreCardDetails.cardExpiryYear,
      token: paymentStoreCardDetails.token,
      maskedCardNumber: paymentStoreCardDetails.maskedCardNumber,
    };

    this.paymentDetails.cardDetails = cardDetails;

    return this;
  }

  createSubmitQuoteUsingBankAccountDetails(directDebitDetails: IDirectDebitDetails): ISubmitQuote {
    this.paymentDetails.directDebitDetails = directDebitDetails;

    return this;
  }
}
