import { defineStore } from 'pinia';
import { useAccountStore } from '@/store/account/account.state';
import { AutoGlassFormService as _autoGlassService } from '@/services/store/auto-glass-form.service';
import { AutoGlassFormRequest } from '@/interfaces/pages/auto-glass-form.interface';
import { ref, readonly } from 'vue';
import { useRoute } from 'vue-router';
import { useFindCarStore } from '../find-car/find-car.state';

export const useAutoGlassStore = defineStore('AutoGlass', () => {
  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //
  const firstName = ref<string>('');
  const lastName = ref<string>('');
  const email = ref<string>('');
  const mobileNumber = ref<string>('');
  const suburb = ref<string>('');
  const isMember = ref<boolean>();
  const vehicleRegistration = ref<string>('');
  const vehicleYear = ref<string>('');
  const vehicleMake = ref<string>('');
  const vehicleModel = ref<string>('');
  const isFromSelfService = ref(false);
  const accountStore = useAccountStore();
  const findCarStore = useFindCarStore();

  async function submitForm() {
    const autoGlassFormRequest: AutoGlassFormRequest = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      mobileNumber: mobileNumber.value,
      suburb: suburb.value,
      isMember: isMember.value,
      vehicleRegistration: vehicleRegistration.value,
      vehicleYear: vehicleYear.value,
      vehicleMake: vehicleMake.value,
      vehicleModel: vehicleModel.value,
    };
    await _autoGlassService.submitAutoGlassFormDetails(autoGlassFormRequest);
  }

  async function getAssistanceProductAndSetRego(policyId: string) {
    if (policyId) {
      try {
        const route = useRoute();
        await accountStore.authenticate(route);
        const assistanceProduct = await _autoGlassService.getAssistanceProduct(policyId);
        if (assistanceProduct?.vehicle) {
          vehicleRegistration.value = findCarStore.registrationNumber = assistanceProduct.vehicle.registrationNumber;
          isFromSelfService.value = true;
        }
      } catch (error) {
        await accountStore.authenticateOrCallBack(rediectToSelfServiceRoadsideAssistance);
      }
    }
  }

  /**
   * Back to self service roadside assistance
   */
  async function rediectToSelfServiceRoadsideAssistance() {
    try {
      const url = await _autoGlassService.getSelfServiceRoadsideAssistancePage();
      window.location.href = url;
    } catch (error) {
      console.warn('Unable to retrieve self service roadside assistance URL: ', error);
      // Session timeout
      window.location.href = 'https://www.racq.com.au';
    }
  }

  function $reset() {
    firstName.value = '';
    lastName.value = '';
    email.value = '';
    mobileNumber.value = '';
    suburb.value = '';
    isMember.value = undefined;
    vehicleRegistration.value = '';
    vehicleYear.value = '';
    vehicleMake.value = '';
    vehicleModel.value = '';
  }

  function $resetSelfServiceEntry() {
    isFromSelfService.value = false;
  }

  return {
    firstName,
    lastName,
    email,
    mobileNumber,
    suburb,
    isMember,
    vehicleRegistration,
    vehicleYear,
    vehicleMake,
    vehicleModel,
    isFromSelfService: readonly(isFromSelfService),
    submitForm,
    getAssistanceProductAndSetRego,
    rediectToSelfServiceRoadsideAssistance,
    $reset,
    $resetSelfServiceEntry,
  };
});
