import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "justify-start items-start gap-4 inline-flex text-block" }
const _hoisted_2 = {
  key: 0,
  class: "w-[40px] h-[60px] flex flex-col justify-center"
}
const _hoisted_3 = {
  key: 0,
  class: "self-stretch text-racq-dark-grey text-lg-bold"
}
const _hoisted_4 = {
  key: 1,
  class: "self-stretch text-racq-dark-grey text-xl-bold"
}
const _hoisted_5 = { class: "self-stretch text-racq-med-grey text-base" }

import { Text, RichText, Image, Field } from '@sitecore-jss/sitecore-jss-vue';
import { ImageFieldValue } from '@sitecore-jss/sitecore-jss-vue/types/components/Image';


export default /*@__PURE__*/_defineComponent({
  __name: 'PanelTitle',
  props: {
    bindings: {},
    icon: {},
    title: {},
    subTitle: {},
    isSecondary: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.bindings?.outerClass)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.icon?.value?.src)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_unref(Image), {
              media: _ctx.icon,
              class: "w-[40px]"
            }, null, 8, ["media"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["grow shrink basis-0 flex-col justify-start items-start inline-flex", _ctx.isSecondary?.value ? 'gap-1' : 'gap-2'])
      }, [
        (_ctx.isSecondary?.value)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_3, [
              _createVNode(_unref(Text), { field: _ctx.title }, null, 8, ["field"])
            ]))
          : (_openBlock(), _createElementBlock("h3", _hoisted_4, [
              _createVNode(_unref(Text), { field: _ctx.title }, null, 8, ["field"])
            ])),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(RichText), { field: _ctx.subTitle }, null, 8, ["field"])
        ])
      ], 2)
    ])
  ], 2))
}
}

})