export const PO_Box_Regex = /(?:Post[\s]*Office[\s]*?|P[.[\s]*]?O\.?)+[\s]*Box[\s]*#?/gim;
export const GPO_Box_Regex = /(?:General[\s]*Post[\s]*Office[\s]*?|G[.[\s]*]?P[.[\s]*]?O\.? )+[\s]*Box[\s]*#?/gim;

export function isPostalAddress(value: string) {
  const isPoBox = new RegExp(PO_Box_Regex).test(value);
  const isGpoBox = new RegExp(GPO_Box_Regex).test(value); //test

  return isPoBox || isGpoBox;
}

export const Emoji_Regex = /\p{Extended_Pictographic}/gu;
export function emojiValidation({ value }: any) {
  return !value.match(Emoji_Regex)?.length;
}
