import { defineStore } from 'pinia';
import { JoinWithLifestyleService as _joinWithLifestyle } from '@/services/store/join-with-lifestyle.service';
import { ref } from 'vue';
import { IPartyMatchRequest } from '@/interfaces/entities/party-match-request.interface';
import { format, parse, compareAsc, addYears } from 'date-fns';

interface PersonalDetails extends Partial<IPartyMatchRequest> {
  title?: string;
  unit?: string;
  buildingName?: string;
  streetNumber?: string;
  street?: string;
  suburb?: string;
  postcode?: string;
  state?: string;
  country?: string;
  postalDeliveryType?: string;
}

export const useJoinWithLifestyleStore = defineStore('JoinWithLifestyle', () => {
  // ------------------------------------------------------------- //
  // Define State
  // ------------------------------------------------------------- //
  const initialConfig = ref<
    Partial<{
      price: number;
      priceSuffix: string;
      productName: string;
      secondDisclaimer: string;
    }>
  >({});

  const partyDetails = ref<PersonalDetails>({});
  const paymentReceipt = ref<{
    prn: string;
    assetId: string;
    invoiceId: string;
    quoteId: string;
    result?: string;
    isNewMember?: boolean;
  }>();
  const creditCardDetails = ref<Record<string, string>>({});

  const mapPersonalDetails = (formData: Record<string, any>) => {
    partyDetails.value.title = formData.fullname?.title;
    partyDetails.value.firstName = formData.fullname?.firstName;
    partyDetails.value.lastName = formData.fullname?.lastName;
    partyDetails.value.phoneNumber = formData?.contactNumber;
    partyDetails.value.dateOfBirth = format(parse(formData?.dateOfBirth, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd', {});
    partyDetails.value.emailAddress = formData?.email;
    partyDetails.value.postalAddressDpid = formData.postalAddress?.dpid;

    partyDetails.value.unit = formData.postalAddress?.unitNumber;
    partyDetails.value.buildingName = formData.postalAddress?.buildingName;
    partyDetails.value.streetNumber = formData.postalAddress?.streetNumber;
    partyDetails.value.street = formData.postalAddress?.street;
    partyDetails.value.suburb = formData.postalAddress?.suburb;
    partyDetails.value.postcode = formData.postalAddress?.postcode;
    partyDetails.value.state = formData.postalAddress?.state;
    partyDetails.value.country = formData.postalAddress?.country;
    partyDetails.value.postalDeliveryType = formData.postalAddress?.postalDeliveryType;
  };

  const fetchConfig = async () => {
    const res = await _joinWithLifestyle.getJoinLifestyleFormInitialContent();
    initialConfig.value = res;
  };

  const checkEligibility = async () => {
    const res = await _joinWithLifestyle.checkLifestyleIsEligible({
      firstName: partyDetails.value.firstName!,
      lastName: partyDetails.value.lastName!,
      dateOfBirth: partyDetails.value.dateOfBirth!,
      phoneNumber: partyDetails.value.phoneNumber!,
      emailAddress: partyDetails.value.emailAddress!,
      postalAddressDpid: partyDetails.value.postalAddressDpid!,
    });
    if (res == null) throw 'Party match failed';
    return res;
  };

  const mapPaymentDetails = (formData: Record<string, any>) => {
    creditCardDetails.value = {
      cardToken: formData.cardToken,
      cardHolder: formData.cardHolder,
      MaskedCardNumber: formData.MaskedCardNumber,
      cardExpiry: formData.cardExpiry,
      cardType: formData.cardType,
      autoRenew: formData.autoRenew,
    };
  };

  const submitPurchase = async () => {
    try {
      const result = await _joinWithLifestyle.submitPurchase({
        firstName: partyDetails.value.firstName!,
        lastName: partyDetails.value.lastName!,
        dateOfBirth: partyDetails.value.dateOfBirth!,
        phoneNumber: partyDetails.value.phoneNumber!,
        emailAddress: partyDetails.value.emailAddress!,
        postalAddressDpid: partyDetails.value.postalAddressDpid!,
        postalDeliveryType: partyDetails.value.postalDeliveryType,
        title: partyDetails.value.title,
        unit: partyDetails.value.unit,
        buildingName: partyDetails.value.buildingName,
        streetNumber: partyDetails.value.streetNumber,
        street: partyDetails.value.street,
        suburb: partyDetails.value.suburb,
        postcode: partyDetails.value.postcode,
        state: partyDetails.value.state,
        country: partyDetails.value.country,
        cardToken: creditCardDetails.value.cardToken,
        cardHolder: creditCardDetails.value.cardHolder,
        MaskedCardNumber: creditCardDetails.value.MaskedCardNumber,
        cardExpiry: creditCardDetails.value.cardExpiry,
        cardType: creditCardDetails.value.cardType,
        autoRenew: creditCardDetails.value.autoRenew,
        ...(paymentReceipt.value || {}),
      });

      paymentReceipt.value = result;
      return result;
    } catch {
      return null;
    }
  };

  function isUnderAge() {
    if (!partyDetails.value.dateOfBirth) return;
    const dob = parse(partyDetails.value.dateOfBirth, 'yyyy-MM-dd', new Date());
    return compareAsc(new Date(), addYears(dob, 18)) < 0;
  }

  return {
    paymentReceipt,
    partyDetails,
    initialConfig,
    fetchConfig,
    checkEligibility,
    mapPersonalDetails,
    mapPaymentDetails,
    submitPurchase,
    isUnderAge,
  };
});
