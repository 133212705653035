import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-24 h-5 bg-racq-blue-border relative rounded-3xl" }
const _hoisted_2 = ["aria-label"]


export default /*@__PURE__*/_defineComponent({
  __name: 'PercentageBar',
  props: {
    percentage: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        "aria-label": `${_ctx.percentage}%`,
        class: _normalizeClass(["bg-racq-blue absolute top-0 left-0 h-full", _ctx.percentage == '100' ? 'rounded-l-3xl rounded-r-3xl' : 'rounded-l-3xl']),
        style: _normalizeStyle({ width: _ctx.percentage + '%' })
      }, null, 14, _hoisted_2)
    ])
  ]))
}
}

})