import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-6"
}

import { VueFormInputProps } from '@/composables/useFormInputData';
import { onMounted, watch, computed } from 'vue';
import RACQFormKit from '@/components/RACQFormKit/RACQFormKit.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdatePostalAddressDisclaimer',
  props: {
    iqStore: {},
    bindings: {}
  },
  setup(__props: any) {

const props = __props;

const showDisclaimer = computed(() => props.iqStore.updatePostalAddressRequired);

onMounted(async () => {
  await props.iqStore.checkUpdatePostalAddressRequired();
});

watch(
  () => props.iqStore.quote.primaryPolicyHolderDetails?.partyId?.value,
  props.iqStore.checkUpdatePostalAddressRequired
);
watch(
  () => props.iqStore.quote.primaryPolicyHolderDetails?.dpid?.value,
  props.iqStore.checkUpdatePostalAddressRequired
);

return (_ctx: any,_cache: any) => {
  return (showDisclaimer.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(RACQFormKit, { bindings: _ctx.bindings }, null, 8, ["bindings"])
      ]))
    : _createCommentVNode("", true)
}
}

})