import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-vehicle-search-container flex flex-col gap-6 pb-6" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "h-12 mt-6 md:h-min flex items-start" }
const _hoisted_5 = { key: 0 }

import { ref, computed } from 'vue';
import FindCarByRego from '@/components/FindCar/FindCarByRego.vue';
import FindYourCarList from '@/components/FindCar/FindYourCarList.vue';
import FindYourCar from '@/components/FindCar/FindYourCar.vue';
import { useAnalyticsStore, useJeopardyStore, useFindCarStore } from '@/store';
import type { IFoundVehicle } from '@/interfaces';
import chevronDown from '@/assets/icons/blue-chevron-down.svg';
import { storeToRefs } from 'pinia';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { ImageFieldValue } from '@sitecore-jss/sitecore-jss-vue/types/components/Image';
import PanelTitle from '@/components/PanelTitle/PanelTitle.vue';
import { FormEvents } from '@/enums/form-events.enum';

interface IFindCarProps {
  node?: any;
  value?: any;
  topMakes: { value: string };
  regoResultsStepTitle: { value: string };
  regoResultsStepContent: { value: string };
  makeAndModelResultsStepTitle: { value: string };
  makeAndModelResultsStepContent: { value: string };
  findCarBtn: { value: string };
  regoNotFoundMessage: { value: string };
  yearLabel: { value: string };
  makeLabel: { value: string };
  modelLabel: { value: string };
  seriesLabel: { value: string };
  bodyLabel: { value: string };
  colourLabel: { value: string };
  regoInputLabel: { value: string };
  showColour: { value: boolean };
  makeAndModelTitleMessage: { value: string };
  expandMakeAndModelLabel: { value: string };
  icon: Field<ImageFieldValue>;
  title: { value: string };
  subTitle: { value: string };
  roadsideRegoHelpText: { value: string };
  isRoadside: { value: boolean };
  formName: { value: string };
}

// Define Props
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'FindCar',
  props: {
    context: {}
  },
  setup(__props: any) {

const props = __props;

// Define Composables
// ------------------------------------------------------------- //
const analyticsStore = useAnalyticsStore();
const jpStore = useJeopardyStore();
const findCarStore = useFindCarStore();

// Define Component state
// ------------------------------------------------------------- //
const findYourCarEl = ref<typeof FindYourCar | undefined>(undefined);
const qldRegoFound = computed(() => findCarStore.isQldRegoFound);
const foundVehiclesByRego = ref(true);
const isExpanded = ref(false);

const vehicleRegistration = computed(() => findCarStore.registrationNumber);
const showMakeAndModel = computed(() => !qldRegoFound.value || isExpanded.value);
const showFoundVehicles = computed(() => findCarStore.showFoundVehicles);
const showExpandButton = computed(
  () => props.context.expandMakeAndModelLabel?.value && !props.context.isRoadside.value && qldRegoFound.value
);

const { selectedVehicle, foundVehicleList } = storeToRefs(findCarStore);

findCarStore.setFormName(props.context.formName.value);

// Define functions
// ------------------------------------------------------------- //
function setQldRegoFound(isFound: boolean) {
  findCarStore.isQldRegoFound = isFound;
}

function setRegistration(registrationNumber: string) {
  findCarStore.registrationNumber = registrationNumber;
}

function setVehicleColour(vehicleColour: string) {
  findCarStore.vehicleColour = vehicleColour;
}

function updateFoundVehicleList(data: IFoundVehicle[], byRego?: boolean) {
  selectedVehicle.value = undefined;
  foundVehicleList.value = data;
  foundVehiclesByRego.value = byRego || false;
}

const toggleMakeAndModelSection = (event: Event) => {
  event.preventDefault();
  if (!qldRegoFound.value) {
    isExpanded.value = true;
    return;
  }
  isExpanded.value = !isExpanded.value;
};

function showFoundVehicleList() {
  if (foundVehicleList.value.length && foundVehicleList.value[0].manufacturingYear >= 1960) {
    findCarStore.showCarList(true);

    /** Push event for analysis */
    if (foundVehicleList.value.length) {
      analyticsStore.pushAnalytics({
        event: FormEvents.STEP,
        form: {
          field: foundVehiclesByRego.value ? 'Found car registration' : 'Found car make and model',
          stepName: 'Find car confirmation',
          name: props.context.formName.value,
        },
      });
    }

    return;
  }

  jpStore.showJeopardyPanel({
    jpCode: findCarStore.pre1960VehicleJeopardyCode,
  });
}

function selectVehicle(vehicle: IFoundVehicle) {
  findCarStore.selectedVehicle = vehicle;
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(PanelTitle, {
        icon: _ctx.context.icon,
        title: _ctx.context.title,
        subTitle: _ctx.context.subTitle
      }, null, 8, ["icon", "title", "subTitle"]),
      (!showFoundVehicles.value)
        ? (_openBlock(), _createBlock(FindCarByRego, {
            key: 0,
            findCarBtn: _ctx.context.findCarBtn.value,
            regoInputLabel: _ctx.context.regoInputLabel.value,
            help: _ctx.context.isRoadside?.value ? _ctx.context.roadsideRegoHelpText?.value : '',
            regoNumber: vehicleRegistration.value,
            onVehicleFound: _cache[0] || (_cache[0] = 
          (data) => {
            updateFoundVehicleList(data ? [data] : [], true);
            showFoundVehicleList();
            setQldRegoFound(true);
            isExpanded.value = false; // Hide make and model if vehicle found by rego
          }
        ),
            onQldVehicleNotFound: _cache[1] || (_cache[1] = ($event: any) => (setQldRegoFound(false))),
            onSetRegistration: _cache[2] || (_cache[2] = (data) => setRegistration(data))
          }, null, 8, ["findCarBtn", "regoInputLabel", "help", "regoNumber"]))
        : _createCommentVNode("", true),
      (showExpandButton.value)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: "toggle-button flex items-center bg-none border-[none] text-base-bold text-racq-blue w-fit",
            onClick: toggleMakeAndModelSection
          }, [
            _createTextVNode(_toDisplayString(_ctx.context.expandMakeAndModelLabel?.value) + " ", 1),
            _createElementVNode("img", {
              src: _unref(chevronDown),
              alt: "chevron icon",
              class: _normalizeClass(['w-[12px] h-[7px] ml-2 [transition:transform_0.5s_ease]', { '-scale-y-100': showMakeAndModel.value }])
            }, null, 10, _hoisted_2)
          ]))
        : _createCommentVNode("", true),
      (showMakeAndModel.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (showExpandButton.value)
              ? (_openBlock(), _createBlock(_unref(RichText), {
                  key: 0,
                  field: _ctx.context.makeAndModelTitleMessage
                }, null, 8, ["field"]))
              : _createCommentVNode("", true),
            _createVNode(_component_FormKit, {
              type: "form",
              onSubmit: showFoundVehicleList
            }, {
              default: _withCtx(({ state: { valid } }) => [
                _createVNode(FindYourCar, {
                  ref_key: "findYourCarEl",
                  ref: findYourCarEl,
                  topMakes: _ctx.context.topMakes.value,
                  colourLabel: _ctx.context.colourLabel.value,
                  yearLabel: _ctx.context.yearLabel.value,
                  makeLabel: _ctx.context.makeLabel.value,
                  modelLabel: _ctx.context.modelLabel.value,
                  seriesLabel: _ctx.context.seriesLabel.value,
                  bodyLabel: _ctx.context.bodyLabel.value,
                  showColour: _ctx.context.showColour.value,
                  onModelMakeChanged: _unref(findCarStore).resetFoundVehicleList,
                  onVehicleFound: _cache[3] || (_cache[3] = (data) => updateFoundVehicleList(data)),
                  onSetColour: _cache[4] || (_cache[4] = (data) => setVehicleColour(data))
                }, null, 8, ["topMakes", "colourLabel", "yearLabel", "makeLabel", "modelLabel", "seriesLabel", "bodyLabel", "showColour", "onModelMakeChanged"]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_FormKit, {
                    label: _ctx.context.findCarBtn.value,
                    type: "submit",
                    disabled: !valid || foundVehiclesByRego.value || !_unref(foundVehicleList).length,
                    inputClass: `btn btn-primary md:text-left`
                  }, null, 8, ["label", "disabled"])
                ])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, !showFoundVehicles.value]
    ]),
    (showFoundVehicles.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(FindYourCarList, {
            regoResultsStepTitle: _ctx.context.regoResultsStepTitle.value,
            regoResultsStepContent: _ctx.context.regoResultsStepContent.value,
            makeAndModelResultsStepTitle: _ctx.context.makeAndModelResultsStepTitle.value,
            makeAndModelResultsStepContent: _ctx.context.makeAndModelResultsStepContent.value,
            vehicleList: _unref(foundVehicleList),
            foundVehiclesByRego: foundVehiclesByRego.value,
            onVehicleSelected: selectVehicle
          }, null, 8, ["regoResultsStepTitle", "regoResultsStepContent", "makeAndModelResultsStepTitle", "makeAndModelResultsStepContent", "vehicleList", "foundVehiclesByRego"]),
          (!qldRegoFound.value && showFoundVehicles.value)
            ? (_openBlock(), _createBlock(_unref(RichText), {
                key: 0,
                class: "my-6",
                field: _ctx.context.regoNotFoundMessage
              }, null, 8, ["field"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})