export enum DecisionContentTypes {
  DecisionIntelligenceCrossSellOffers = 'DecisionIntelligenceCrossSellOffers',
  ContactUsTimes = 'Contact Us Times',
  EmailTimes = 'Email Times',
  DecisionIntelligenceContent = 'Decision Intelligence Content',
}
export enum CoverTypeMapping {
  MC = 'Comprehensive Car', //MotorComprehensive
  HH = 'Home', //Home
  HC = 'Contents', //Contents
  HHCOMB = 'Home and Contents', // Home & Contents
  MT = 'Third Party Liability', //MotorThirdPartyLiability
  MF = 'Fire, Theft and Third Party', //MotorFireTheftAndThirdPartyLiability
  PET = 'Pet', //Pet
}
export enum OccupancyTypesMapping {
  OWNEROCC = 'Owner Occupied',
  LANDLORD = 'Landlord',
  TENANTPERM = 'Renting',
}
export const ProductMapping = {
  MC: 'Motor Insurance', //MotorComprehensive
  HH: 'Home Insurance', //Home
  All: 'All', //All
  HC: 'Home Insurance', //Contents
  HHCOMB: 'Home Insurance', // Home & Contents
  MT: 'Motor Insurance', //MotorThirdPartyLiability
  MF: 'Motor Insurance', //MotorFireTheftAndThirdPartyLiability
  PET: 'Pet Insurance', //Pet
};
