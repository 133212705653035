<template>
  <!--TODO: This component will be used in DI offer reminders  -->
  <div
    v-if="decisionOffer?.propositions?.filter((x) => x.incentivised)?.length"
    class="flex flex-col gap-4 basis-full mt-8 mb-10"
  >
    <div class="flex flex-col basis-full rounded-md border-solid border-2 border-racq-white">
      <div class="flex gap-4 rounded py-3 px-6">
        <div class="flex flex-col justify-center basis-9 shrink-0">
          <img
            :src="getFilterStep().imageUrl"
            Width="40"
            Height="30"
            AlternateText="offer image"
            class="max-w-none h-7"
          />
        </div>
        <div :class="expanded ? 'text-racq-blue font-bold pt-1.5' : 'text-base-bold pt-1.5'">
          <RichText :field="{ value: getFilterStep().headerText }" />
        </div>
        <div class="flex flex-col justify-center ml-auto payment-confirmation__proposition-expander">
          <button :onclick="toggleExpand" :aria-expanded="expanded" type="button">
            <img :class="expanded ? 'transform -rotate-90' : 'transform rotate-90'" :src="iconMap.chevronIcon" />
          </button>
        </div>
      </div>
      <div v-if="expanded">
        <div class="py-4 px-6">
          <RichText :field="{ value: getFilterStep().messageText }" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import {
  IDecisionIntelligenceOfferResponse,
  IStep,
} from '@/interfaces/decision-intelligence/decision-intelligence-response';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
// Define props and emitters
// ------------------------------------------------------------- //
const props = defineProps<{
  decisionOffer?: IDecisionIntelligenceOfferResponse;
  stepTitle?: string;
  isRenewal?: boolean;
  isQuote?: boolean;
  isCrossSell: boolean;
  expandedByDefault: boolean;
}>();

const iconMap = {
  chevronIcon: require('@/assets/img/icons/icon-nav-chevron-navy.svg'),
};
// Define Component state
// ------------------------------------------------------------- //

const expanded = ref(props.expandedByDefault);

// Define Functions
// ------------------------------------------------------------- //

const toggleExpand = () => {
  expanded.value = !expanded.value;
};

const getFilterStep = (): IStep => {
  const proposition = props.decisionOffer?.propositions?.filter((x) => x.incentivised)[0];
  return (
    proposition?.displaySteps?.filter(
      (x) =>
        x.stepName == props.stepTitle &&
        x.journey == (props.isRenewal ? 'Make a payment' : props.isQuote ? 'Quote' : 'Purchase') &&
        x.isCrossSell == props.isCrossSell
    )[0] ?? ({} as IStep)
  );
};
</script>
