import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-3" }
const _hoisted_2 = { class: "px-8 py-6 bg-racq-anti-flash-white border-2 border-solid border-racq-blue-background" }
const _hoisted_3 = { class: "flex flex-col gap-2" }
const _hoisted_4 = { class: "flex gap-5 flex-col md:flex-row" }

import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormKit from '../RACQFormKit/RACQFormKit.vue';
import Alert from '../Alert/Alert.vue';
import { IAlert } from '@/interfaces/forms/alert-interface';
import { AlertTypes } from '@/enums/alert-types-enum';
import { computed } from 'vue';

// Define props and emitters
// ------------------------------------------------------------- //

export default /*@__PURE__*/_defineComponent({
  __name: 'SendEmailConfirmation',
  props: {
    bindings: {},
    emailHeading: {},
    emailButtonText: {},
    successText: {},
    errorText: {},
    isLoading: { type: Boolean },
    isSuccess: { type: Boolean },
    showAlert: { type: Boolean }
  },
  emits: ['sendEmailFired'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

// Define Component state
// ------------------------------------------------------------- //
const alert = computed(() => {
  return {
    showIcon: true,
    alertType: props.isSuccess ? AlertTypes.success : AlertTypes.danger,
    text: props.isSuccess ? props.successText.value : props.errorText.value,
  } as IAlert;
});
// Define Functions
// ------------------------------------------------------------- //

const emailSendClick = (values: any) => {
  emit('sendEmailFired', values);
};

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(RichText), {
      field: _ctx.emailHeading,
      class: "text-xl text-base-bold"
    }, null, 8, ["field"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormKit, {
        type: "form",
        actions: false,
        onSubmit: emailSendClick
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(RACQFormKit, {
                type: "text",
                bindings: _ctx.bindings
              }, null, 8, ["bindings"]),
              _createElementVNode("div", null, [
                _createVNode(_component_FormKit, {
                  type: "submit",
                  loading: _ctx.isLoading,
                  disabled: _ctx.isLoading,
                  inputClass: `btn btn-md btn-secondary md:mt-7`,
                  label: _ctx.emailButtonText.value
                }, null, 8, ["loading", "disabled", "label"])
              ])
            ]),
            (alert.value && _ctx.showAlert)
              ? (_openBlock(), _createBlock(Alert, {
                  key: 0,
                  alert: alert.value
                }, null, 8, ["alert"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})