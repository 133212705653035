export enum QuoteTypes {
  MC = 1, //MotorComprehensive
  All = 2, //All
  HH = 3, //Home
  HC = 4, //Contents
  HHCOMB = 5, // Home & Contents
  MT = 6, //MotorThirdPartyLiability
  MF = 7, //MotorFireTheftAndThirdPartyLiability
  PET = 8, //Pet
}
