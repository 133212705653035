<template>
  <div id="jeopardy-container">
    <div v-if="showJeopardy" class="sm:mt-0 md:mt-8 lg:mt-14">
      <FormStep :step="jpStore.step">
        <div class="flex-col justify-start items-start gap-6 inline-flex w-full">
          <div v-if="jpTextLoaded" class="justify-start items-start gap-4 inline-flex">
            <div class="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
              <h3 class="self-stretch text-racq-dark-grey text-xl-bold">{{ jpHeading }}</h3>
              <div class="self-stretch text-racq-dark-grey text-base">
                <RichText :field="{ value: jpText }" />
              </div>
            </div>
          </div>

          <div v-else class="block w-full">
            <div class="w-32 h-8 animate-pulse bg-racq-pale-blue-border mb-3"></div>
            <div class="w-full h-6 animate-pulse bg-racq-pale-blue-border mb-3"></div>
            <div class="w-1/2 h-6 animate-pulse bg-racq-pale-blue-border"></div>
          </div>

          <div v-if="isSessionTimeout" class="block w-full">
            <AccountLoginInfoBox :fields="fields" />
          </div>

          <div
            v-else-if="isChatLoading"
            class="self-stretch bg-racq-anti-flash-white rounded-md flex-col justify-start items-start flex"
          >
            <div class="self-stretch pt-5 rounded justify-center items-center gap-2.5 inline-flex">
              <div class="w-16 h-16 relative bg-racq-pale-blue-border animate-pulse"></div>
            </div>
            <div class="self-stretch pt-4 pb-6 flex-col justify-center items-center flex">
              <div class="px-5 flex-col justify-start items-center gap-4 flex racq-max-w-lg w-full">
                <div class="h-8 w-full bg-racq-pale-blue-border animate-pulse"></div>
                <div class="h-4 w-full bg-racq-pale-blue-border animate-pulse"></div>
                <div class="h-4 w-full bg-racq-pale-blue-border animate-pulse"></div>
                <div class="h-8 w-40 bg-racq-pale-blue-border animate-pulse"></div>
                <div class="h-4 w-full bg-racq-pale-blue-border animate-pulse"></div>
              </div>
            </div>
          </div>

          <div v-else class="self-stretch bg-racq-anti-flash-white rounded-md flex-col justify-start items-start flex">
            <div class="self-stretch pt-5 rounded justify-center items-center gap-2.5 inline-flex">
              <img :src="require('@/assets/icons/chat.svg')" alt="Vehicle" role="img" class="w-16 h-16 relative" />
            </div>

            <div v-if="isChatEnabled" class="self-stretch pt-4 pb-6 flex-col justify-center items-center flex">
              <div class="px-5 flex-col justify-start items-center gap-4 flex racq-max-w-lg">
                <div class="text-center text-racq-dark-grey text-lg-bold">
                  <RichText :field="fields.liveChatHeading" />
                </div>

                <FormKit
                  type="button"
                  :label="fields.liveChatButtonText.value"
                  inputClass="btn btn-primary"
                  @click="startChat"
                />
                <div class="text-center text-dark-grey-text text-m">
                  <RichText :field="fields.liveChatContent" />
                </div>
              </div>
            </div>

            <div v-if="isChatEnabled" class="self-stretch h-5 px-8 justify-start items-start gap-6 inline-flex">
              <div class="grow shrink basis-0 h-5 pt-2 flex-col justify-start items-start gap-2.5 inline-flex">
                <div class="h-7 relative border-t border-racq-pale-blue-border w-full"></div>
              </div>
            </div>

            <div
              v-if="isCallUsVisible"
              class="self-stretch pt-4 pb-6 rounded justify-center items-center gap-2.5 inline-flex"
            >
              <div class="grow shrink basis-0 flex-col justify-start items-center gap-2.5 inline-flex racq-max-w-lg">
                <div class="text-center text-racq-dark-grey text-lg-bold">
                  <RichText :field="fields.callUsHeading" />
                </div>
                <div class="text-center text-dark-grey-text text-m">
                  <RichText :field="fields.callUsContent" />
                </div>
                <div class="flex-col justify-start items-center gap-4 flex">
                  <a
                    class="text-center text-racq-dark-grey text-xl2-bold no-underline"
                    :href="`tel:${fields.callUsNumber.value}`"
                  >
                    {{ fields.callUsNumber.value }}
                  </a>
                  <FormKit
                    type="linkButton"
                    inputClass="btn btn-primary"
                    :text="fields.callUsButtonLabel.value"
                    :href="`tel:${fields.callUsNumber.value}`"
                  />
                </div>
              </div>
            </div>

            <div v-if="isCallBackVisible" class="self-stretch h-5 px-8 justify-start items-start gap-6 inline-flex">
              <div class="grow shrink basis-0 h-5 pt-2 flex-col justify-start items-start gap-2.5 inline-flex">
                <div class="h-7 relative border-t border-racq-pale-blue-border w-full"></div>
              </div>
            </div>

            <div v-if="isCallBackVisible" class="self-stretch pt-4 pb-6 flex-col justify-center items-center flex">
              <div class="px-5 flex-col justify-start items-center gap-4 flex racq-max-w-lg">
                <div class="text-center text-racq-dark-grey text-lg-bold">
                  <RichText :field="fields.callBackHeading" />
                </div>
                <FormKit
                  type="routerLinkButton"
                  inputClass="btn btn-primary"
                  :text="fields.callBackButtonText.value"
                  :to="callBackFormLink"
                />
                <div class="text-center text-dark-grey-text text-m">
                  <RichText :field="fields.callBackContent" />
                </div>
              </div>
            </div>
          </div>

          <div v-if="!backBtnDisabled" class="form-buttons w-full">
            <FormKit
              type="button"
              :label="backBtnText"
              :disabled="formStore.disableAllSteps"
              inputClass="btn btn-secondary"
              outerClass=""
              @click="btnBackClick"
            />
          </div>
        </div>
      </FormStep>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { watch, computed, ref } from 'vue';
import FormStep from '@/components/FormStep/FormStep.vue';
import { FormKit } from '@formkit/vue';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { LinkField } from '@sitecore-jss/sitecore-jss-vue/types/components/Link';
import { useJeopardyStore, useLiveChatStore, useFormStore } from '@/store';
import { scrollToElement } from '@/utils/dom-helpers';
import AccountLoginInfoBox from '@/components/AccountLoginInfoBox/AccountLoginInfoBox.vue';
import { JP_LOGGEDOUT } from '@/constants/jeopardy-codes.const';

// Define props and state
// ------------------------------------------------------------- //

const props = defineProps<{
  fields: Record<string, Field<any>>;
  testParams?: {
    isChatEnabled: boolean;
    isChatLoading: boolean;
    isCallBackVisible: boolean;
    isCallUsVisible: boolean;
    jpCode: string;
  };
}>();

const route = useRoute();

// Initialise Pinia store/s
// ------------------------------------------------------------- //
const jpStore = useJeopardyStore();
const formStore = useFormStore();
const liveChatStore = useLiveChatStore();

// Computed properties
// ------------------------------------------------------------- //

const fields = computed(() => props.fields);
const jpText = computed(() => jpStore.jpText);
const jpTextLoaded = computed(() => jpStore.jpTextLoaded);
const jpHeading = computed(() => jpStore.jpHeading);
const showJeopardy = computed(() => jpStore.showJeopardy);
const isChatEnabled = computed(() => jpStore.isChatEnabled);
const isChatLoading = computed(() => jpStore.isChatLoading);
const isCallBackVisible = computed(() => jpStore.isCallBackVisible);
const isCallUsVisible = computed(() => jpStore.isCallUsVisible);
const backBtnText = computed(() => jpStore.backBtnText);
const isSessionTimeout = computed(() => JP_LOGGEDOUT == jpStore.jpCode);
const backBtnDisabled = computed(() => jpStore.backBtnDisabled || isSessionTimeout.value);
const callBackFormLink = computed(
  () =>
    (props.fields.callBackFormLink as LinkField)?.value?.href +
    '?policyid=' +
    (route?.query?.policyId ?? route?.query?.policyNumber)
);

// Component setup
// ------------------------------------------------------------- //

if (props.testParams) {
  // props.testParams is only populated via storybook / tests to set initial state
  jpStore.setChatLoading(props.testParams.isChatLoading);
  jpStore.showJeopardyPanel({
    isChatEnabled: props.testParams.isChatEnabled,
    isCallBackVisible: props.testParams.isCallBackVisible,
    isCallUsVisible: props.testParams.isCallUsVisible,
    jpCode: props.testParams.jpCode,
  });
}

// Lifecycle Hooks
// ------------------------------------------------------------- //

watch(
  () => jpStore.showJeopardy,
  (newValue) => {
    if (jpStore.showDynamic) {
      setJeopardyFieldsDynamic();
    } else {
      setJeopardyFieldsDefault();
    }
    if (newValue) {
      scrollToElement('#jeopardy-container', 500, -100);
    }
  }
);

// Functions
// ------------------------------------------------------------- //

/**
 * Sets the fields on the jeopardy to the one loaded into jpDynamicPanelResult.
 */
function setJeopardyFieldsDynamic() {
  setJeopardyFields(jpStore.jpDynamicPanelResult);
}

/**
 * Sets the fields on the jeopardy to the default.
 */
function setJeopardyFieldsDefault() {
  setJeopardyFields(jpStore.jpDefaultPanelResult);
}

function setJeopardyFields(jpPanelResult: any) {
  fields.value.liveChatHeading.value = jpPanelResult.item.liveChatHeading.value;
  fields.value.liveChatContent.value = jpPanelResult.item.liveChatContent.value;
  fields.value.liveChatButtonText.value = jpPanelResult.item.liveChatButtonText.value;
  fields.value.callUsHeading.value = jpPanelResult.item.callUsHeading.value;
  fields.value.callUsContent.value = jpPanelResult.item.callUsContent.value;
  fields.value.callUsButtonLabel.value = jpPanelResult.item.callUsButtonLabel.value;
  fields.value.callUsNumber.value = jpPanelResult.item.callUsNumber.value;
  fields.value.callBackHeading.value = jpPanelResult.item.callBackHeading.value;
  fields.value.callBackContent.value = jpPanelResult.item.callBackContent.value;
  fields.value.callBackButtonText.value = jpPanelResult.item.callBackButtonText.value;
  fields.value.callBackFormLink.value = jpPanelResult.item.callBackFormLink.value;
}

function startChat() {
  liveChatStore.startChat();
}

function btnBackClick() {
  const activeStep = formStore.inScopeSteps.findIndex((item) => item.isActive);
  formStore.scrollToStep(activeStep == -1 ? 0 : activeStep);
  jpStore.btnBackClick();
}
</script>
