<template>
  <div class="cta-card flex flex-col items-center">
    <img v-if="icon" :src="icon" alt="icon" class="cta-card-icon h-20 w-20 mb-4" />
    <div v-if="title" class="cta-card-title text-xl-bold mb-6">{{ title }}</div>
    <div v-if="$slots.beforeContent || content || $slots.afterContent" class="cta-card-content-container">
      <slot v-if="$slots.beforeContent" name="beforeContent"></slot>
      <RichText v-if="content" :field="{ value: content }" class="cta-card-content m-0 p-0" />
      <slot v-if="$slots.afterContent" name="afterContent"></slot>
    </div>
    <div
      v-if="primaryButtonLabel || secondaryButtonLabel"
      class="cta-card-buttons mt-6 flex flex-col items-center w-full gap-4"
    >
      <button
        v-if="primaryButtonLabel"
        type="button"
        class="cta-card-button flex px-3 py-2 justify-center items-center self-stretch text-base btn btn-md btn-primary"
        :aria-label="primaryButtonLabel"
        @click.stop.prevent="emitClick('primaryCtaButton')"
      >
        {{ primaryButtonLabel }}
      </button>
      <button
        v-if="secondaryButtonLabel"
        type="button"
        class="cta-card-button flex px-3 py-2 justify-center items-center self-stretch text-base btn-md btn btn-secondary"
        :aria-label="secondaryButtonLabel"
        @click.stop.prevent="emitClick('secondaryCtaButton')"
      >
        {{ secondaryButtonLabel }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RichText } from '@sitecore-jss/sitecore-jss-vue';

interface CTACardProps {
  icon?: string;
  title?: string;
  content?: string;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
}

const props = defineProps<CTACardProps>();

const emit = defineEmits(['buttonClick']);

const emitClick = (type: 'primaryCtaButton' | 'secondaryCtaButton') => {
  emit('buttonClick', type);
};
</script>
<!-- external styles defined under call-to-action-card.css -->
