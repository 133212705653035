import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watch } from 'vue';
import { AddressLookupService } from '@/services/store';
import type { IDropdownItem } from '@/interfaces';


export default /*@__PURE__*/_defineComponent({
  __name: 'SuburbLookupInput',
  props: {
    bindings: {},
    selectedSuburb: {},
    innerClass: {},
    labelClass: {}
  },
  emits: ['selected'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

const value = ref<string>(props.selectedSuburb);

watch(
  () => props.selectedSuburb,
  (newValue, oldValue) => {
    if (!oldValue && newValue) {
      value.value = newValue;
    }
  }
);

async function fetchSuburbsList({ search }: { search: string }): Promise<IDropdownItem[]> {
  try {
    if (!search || search.length < 3) return [];

    const data = await AddressLookupService.getAddressLookupSuburbs(search);

    return data.map((item: any) => ({
      label: item.suburb,
      value: item.suburb,
    }));
  } catch (error) {
    console.error('Unable to fetch suburbs list: ', error);
    return [];
  }
}

function handleSelection(suburb: string) {
  emit('selected', suburb);
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_openBlock(), _createBlock(_component_FormKit, _mergeProps(_ctx.bindings, {
    modelValue: value.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
    options: fetchSuburbsList,
    messagesClass: "max-sm:ml-0",
    innerClass: _ctx.innerClass,
    labelClass: _ctx.labelClass,
    onInput: handleSelection
  }), null, 16, ["modelValue", "innerClass", "labelClass"]))
}
}

})