import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex flex-col gap-8 md:gap-6" }

import { ref, watch } from 'vue';
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { AddressLookupService } from '@/services/store';
import type { IDropdownItem, IExperianSuggestion, IExperianResults, IExperianFormatResult } from '@/interfaces';
import { hideAllPoppersAndPaddings } from '@/plugins/tooltip/renderTooltip';
import RACQFormKit from '../RACQFormKit/RACQFormKit.vue';
import { VueFormInputProps } from '@/composables/useFormInputData';
import { addressLookupEmits, emitAddressSelected, useFormStore, useMultiInstanceAddressOptionsStore } from '@/store';
import { isPostalAddress } from '@/utils/regex-helpers';
import { RACQAddressResult } from '@/interfaces';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddressLookup',
  props: {
    bindings: {},
    innerClass: {},
    labelClass: {},
    defaultIsOverseasOption: { type: Boolean },
    showManualEntries: { type: Boolean },
    streetNoRequired: {},
    streetRequired: {},
    suburbRequired: {},
    stateRequired: {},
    postcodeRequired: {},
    countryRequired: {},
    checkAddressRequired: {},
    selectSuburbRequired: {},
    postcodeInvalid: {},
    invalidInsuranceRiskSuburbMessage: {},
    postalSameAsInsuredAddressLabel: {},
    instanceIdentifier: {}
  },
  emits: addressLookupEmits,
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

const addressPicker = ref(null);
const displayAddressText = ref<string>();

const formStore = useFormStore();
const multiInstanceAddressStore = useMultiInstanceAddressOptionsStore();
const addressLookupOptionsStore = multiInstanceAddressStore.getInstance(props.instanceIdentifier);

watch(
  () => addressLookupOptionsStore?.isOverseasSelection,
  (newValue) => {
    if (!newValue) {
      addressLookupOptionsStore.isManualEntries = false;
    }
  }
);

// To watch changes from other components / steps
watch(
  () => addressLookupOptionsStore?.autoAddressComponents,
  (newValue) => {
    const newAddress = addressLookupOptionsStore.buildAddressLine(newValue);

    // Do nothing if the component is in manual mode, keep the display text as No - I will enter manually
    if (newAddress != displayAddressText.value && !addressLookupOptionsStore.isManualEntries) {
      displayAddressText.value = newAddress;
    }
  }
);

function postalSameAsInsuredAddressChanged(event: any) {
  event.preventDefault();

  // Do nothing if the component is in manual mode, keep the display text as No - I will enter manually
  if (addressLookupOptionsStore.isManualEntries) return;

  const useAddress = addressLookupOptionsStore.onSameAsPostalAddressChanged();

  //If the postal address has previously been saved as same as insured address and the same as insured address checkbox is unticked
  //we don't want the same address showing so we clear it.
  if (
    !addressLookupOptionsStore.postalSameAsInsuredAddressSelection &&
    addressLookupOptionsStore.checkPostalIsSameAsInsured(addressLookupOptionsStore.postalAddress as RACQAddressResult)
  ) {
    emit(emitAddressSelected, {});
    displayAddressText.value = '';
  } else {
    displayAddressText.value = addressLookupOptionsStore.buildAddressLine(useAddress!);
    emit(emitAddressSelected, useAddress);
  }
}

async function lookup({ search }: { search: string }): Promise<IDropdownItem[]> {
  try {
    if (!search || search.length < 3) return [];

    const data: IExperianResults = await AddressLookupService.getAddressLookup(search);

    if (addressLookupOptionsStore?.config?.allowPhysicalAddressesOnly) {
      data.results = data.results.filter((result) => {
        return !isPostalAddress(result.suggestion);
      });
    }

    data.results.push({
      matched: [],
      suggestion: props.bindings.postContent,
      format: '',
      triggerManualEntry: true,
    });

    return (
      data.results?.map((item: IExperianSuggestion) => ({
        label: item.suggestion,
        value: item,
      })) || []
    );
  } catch (error) {
    console.error('Unable to lookup: ', error);
    return [];
  }
}

async function checkAddressInsuranceRiskSuburb() {
  formStore.showLoadingForCurrentStep();

  const results = await addressLookupOptionsStore.checkAddressInsuranceRiskSuburb(false);

  if (addressLookupOptionsStore?.config?.allowValidInsuranceRiskSuburbsOnly) {
    if (addressPicker.value) {
      (addressPicker.value as any).node.setErrors(results?.match ? '' : props.invalidInsuranceRiskSuburbMessage);
    }
  }

  formStore.showLoadingForCurrentStep(false);
}

async function handleSelection(address: IExperianSuggestion) {
  if (typeof address == 'string') {
    return;
  }

  if (!address) {
    // hide manual entry fields if user cleared address lookup field
    addressLookupOptionsStore.resetManualAddress();
    return;
  } else if (address.triggerManualEntry && !addressLookupOptionsStore.isManualEntries) {
    addressLookupOptionsStore.onEnableManualEntrySelected();

    setTimeout(() => {
      hideAllPoppersAndPaddings();
    }, 1000);
    return;
  }

  // Format Address
  if (address.format) {
    const data: IExperianFormatResult = await AddressLookupService.getAddressLookupFormat(address.format.split('?')[1]);

    addressLookupOptionsStore.autoAddressComponents = {
      postalDeliveryType: data.components?.find((comp) => comp.deliveryService1)?.deliveryService1 || '',
      addressLine: data.address
        .filter((comp) => comp.addressLine1 || comp.addressLine2 || comp.addressLine3)
        .map((item) => Object.values(item).toString())
        .join(' '),
      unitNumber: data.components?.find((comp) => comp.subBuilding1)?.subBuilding1 || '',
      buildingName: data.components?.find((comp) => comp.building1)?.building1 || '',
      streetNumber: data.components?.find((comp) => comp.streetNumber1)?.streetNumber1 || '',
      street: data.components?.find((comp) => comp.street1)?.street1 || '',
      suburb: data.components?.find((comp) => comp.locality1)?.locality1 || '',
      postcode: data.components?.find((comp) => comp.postalCode1)?.postalCode1 || '',
      state: data.address?.find((comp) => comp.province)?.province || '',
      country: data.components?.find((comp) => comp.country1)?.country1 || '',
      countryISO: data.components?.find((comp) => comp.countryISO1)?.countryISO1 || '',
      dpid: data.components?.find((comp) => comp.deliveryPointId1)?.deliveryPointId1 || '',
    };
    if (addressLookupOptionsStore?.config?.allowValidInsuranceRiskSuburbsOnly) {
      await checkAddressInsuranceRiskSuburb();
    }

    //Clear street before emitting the address.
    if (
      addressLookupOptionsStore.config?.allowPhysicalAddressesOnly &&
      isPostalAddress(addressLookupOptionsStore.autoAddressComponents.street ?? '')
    ) {
      addressLookupOptionsStore.autoAddressComponents.street = '';
    }

    addressLookupOptionsStore.onAutoAddressSelected(addressLookupOptionsStore.autoAddressComponents);

    emit(emitAddressSelected, addressLookupOptionsStore.autoAddressComponents);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_FormKit = _resolveComponent("FormKit")!

  return (_unref(addressLookupOptionsStore))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.bindings.preContent)
          ? (_openBlock(), _createBlock(_unref(RichText), {
              key: 0,
              class: _normalizeClass(_ctx.bindings.preContentClass),
              field: { value: _ctx.bindings.preContent }
            }, null, 8, ["class", "field"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          (!_unref(addressLookupOptionsStore).isOverseasSelection)
            ? (_openBlock(), _createBlock(_component_FormKit, _mergeProps({
                key: 0,
                ref_key: "addressPicker",
                ref: addressPicker
              }, _ctx.bindings, {
                modelValue: displayAddressText.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((displayAddressText).value = $event)),
                options: lookup,
                type: "autocomplete",
                autocomplete: "off",
                "data-testid": `${_ctx.bindings.name}_auto_lookup`,
                name: _ctx.bindings.name + '_auto',
                innerClass: _ctx.innerClass || 'input-xl',
                outerClass: "",
                labelClass: _ctx.labelClass,
                listboxButtonClass: "$reset hidden",
                onInput: handleSelection
              }), null, 16, ["modelValue", "data-testid", "name", "innerClass", "labelClass"]))
            : _createCommentVNode("", true),
          (_unref(addressLookupOptionsStore)?.config?.showPostalSameAsInsuredAddressSelection)
            ? (_openBlock(), _createBlock(RACQFormKit, {
                key: 1,
                modelValue: _unref(addressLookupOptionsStore).postalSameAsInsuredAddressSelection,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(addressLookupOptionsStore).postalSameAsInsuredAddressSelection) = $event)),
                name: `${_ctx.bindings.name}_sameasinsured`,
                "data-testid": `${_ctx.bindings.name}_sameasinsured`,
                bindings: 
          {
            label: _ctx.postalSameAsInsuredAddressLabel,
            name: `${_ctx.bindings.name}__sameasinsured`,
            type: 'checkbox',
            placeholder: '',
            classesOptions: '',
          } as VueFormInputProps
        ,
                onChange: postalSameAsInsuredAddressChanged
              }, null, 8, ["modelValue", "name", "data-testid", "bindings"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})