<template>
  <div class="w-full bg-racq-navy py-4 px-6 flex relative md:rounded-t">
    <div v-if="step.hideHeaderNumber"></div>
    <div
      v-else-if="(stepNumber || stepIndex) && !step.isComplete"
      class="text-racq-navy bg-white rounded-full min-w-[28px] !leading-[30px] w-[28px] h-[28px] text-center mr-3 text-base-bold"
      v-text="stepNumber || stepIndex"
    />
    <div
      v-else-if="step.isComplete"
      class="text-racq-navy bg-white rounded-full min-w-[28px] !leading-[30px] w-[28px] h-[28px] text-center mr-3 text-base-bold bg-step-completed-icon"
    />
    <h2 class="text-white text-xl" v-text="step.stepTitle" />

    <button
      v-if="step.isRemovable"
      type="button"
      class="link text-white ms-auto text-m no-underline hover:underline active:text-white focus:outline-white"
      @click="removeStep()"
    >
      REMOVE
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { IFormStep } from '@/interfaces';
import { useFormStore } from '@/store';
import { uniqBy } from 'lodash-es';

// Define Vue props
// ------------------------------------------------------------- //
const props = defineProps<{
  step: IFormStep;
  stepIndex: number;
}>();

// Initialise Pinia store/s
// ------------------------------------------------------------- //
const formStore = useFormStore();

// Computed Properties
// ------------------------------------------------------------- //
const stepNumber = computed(() => {
  const uniqSteps = uniqBy(formStore.inScopeSteps, (step) => step.stepGroupName || step.stepTitle);
  return (
    uniqSteps.findIndex(
      (step) =>
        (props.step.stepGroupName && step.stepGroupName == props.step.stepGroupName) ||
        step.stepTitle == props.step.stepTitle
    ) + 1
  );
});

// Functions
// ------------------------------------------------------------- //
const removeStep = () => {
  formStore.removeStep(props.step.stepIndex!);
};
</script>
