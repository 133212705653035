import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 w-full" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "grid h-[24px] w-[24px] place-items-center rounded-full bg-gray-600 text-xs text-white text-xs-bold" }
const _hoisted_4 = ["innerHTML"]

import SectionFrame from '@/components/SectionFrame/SectionFrame.vue';
import { IHighlight } from '@/interfaces/forms/highlights-interface';


export default /*@__PURE__*/_defineComponent({
  __name: 'Highlights',
  props: {
    showNumber: { type: Boolean },
    highlightsTitle: {},
    highlights: {}
  },
  setup(__props: any) {

const props = __props;

const getCols = () => {
  return props.showNumber ? ' grid-cols-[4%_96%]' : '';
};

const getBottomBorder = (itemIndex: number) => {
  return itemIndex + 1 < props.highlights.length ? 'border-b' : '';
};

const isLastItem = (itemIndex: number) => {
  return itemIndex + 1 === props.highlights.length;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(SectionFrame, {
    sectionTitle: _ctx.highlightsTitle,
    titleClasses: "text-xl-bold"
  }, {
    sectionFrameContent: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.highlights, (item, itemIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            key: itemIndex,
            class: _normalizeClass([`${getCols()} ${getBottomBorder(itemIndex)}`, "grid mt-4"])
          }, [
            (_ctx.showNumber)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(itemIndex + 1), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["text-base", [!isLastItem(itemIndex) ? 'pb-4' : '', _ctx.showNumber ? 'pl-8 md:pl-4' : '']]),
              innerHTML: item.content
            }, null, 10, _hoisted_4)
          ], 2))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["sectionTitle"]))
}
}

})