import { InsuranceProduct } from '@/interfaces/entities/insurance-product.interface';
import { defineStore } from 'pinia';

export const useInsuranceProductStore = defineStore('InsuranceProduct', {
  state: (): {
    _insuranceProduct?: InsuranceProduct;
  } => ({}),
  actions: {
    setInsuranceProduct(product: any) {
      this._insuranceProduct = product;
    },
  },
  getters: {
    insuranceProduct: (state) => state._insuranceProduct,
    isPet: (state) => state._insuranceProduct?.coverages[0]?.isPet,
    isHome: (state) => state._insuranceProduct?.coverages[0]?.isHome,
  },
});
