import { IQuote, InsuranceQuoteType } from '@/interfaces';
import { QuoteTypes } from '@/constants/quote-types.const';
import { FormUrlBase } from '@/constants/forms-api.const';
export enum HomeQuoteStepIds {
  policyStartDate = 0,
  startYourQuote = 100,
  buildingDetails = 200,
  portableItems = 300,
  homeItems = 400,
  generalContents = 500,
  personalDetails = 600,
  yourQuote = 700,
}

const usesGoToFunction = ['personalDetails'];

export const formatPrimaryPolicyHolderAddress = (quote: Partial<IQuote>, includeCountry: boolean = true) => {
  let formattedAddress = `${quote.primaryPolicyHolderDetails?.streetNumber?.value} 
    ${quote.primaryPolicyHolderDetails?.street?.value}, 
    ${quote.primaryPolicyHolderDetails?.suburb?.value},
    ${quote.primaryPolicyHolderDetails?.state?.value},
    ${quote.primaryPolicyHolderDetails?.postcode?.value}`;

  formattedAddress = includeCountry
    ? `${formattedAddress}, ${quote.primaryPolicyHolderDetails?.country?.value}`
    : formattedAddress;

  return formattedAddress;
};

export const formatHomeLocationDetails = (quote: IQuote) => {
  return `${quote.homeAndContentsQuote?.homeLocationDetails?.streetNumber?.value} 
      ${quote.homeAndContentsQuote?.homeLocationDetails?.streetName?.value} 
      ${quote.homeAndContentsQuote?.homeLocationDetails?.suburb?.value} 
      ${quote.homeAndContentsQuote?.homeLocationDetails?.state?.value}
      ${quote.homeAndContentsQuote?.homeLocationDetails?.postCode?.value}`;
};

export const homeContentsQuoteBase = FormUrlBase + '/homecontents';

export const getEditGoToStep = (editStep: string) => {
  const useGoToFunction = usesGoToFunction.findIndex((x) => x == editStep) > -1;
  if (!useGoToFunction) return undefined;
  const foundStep = Object.keys(HomeQuoteStepIds)[Object.values(HomeQuoteStepIds).indexOf(editStep)];

  switch (foundStep) {
    case HomeQuoteStepIds.personalDetails.toString():
      return 0;
  }
  return undefined;
};

export const getEditLinkForQuote = (quote: IQuote, editStep: string) => {
  let editLink;

  if (!editStep || usesGoToFunction.findIndex((x) => x == editStep) > -1) return '';

  switch (quote.quoteType) {
    case InsuranceQuoteType.HomeAndContents:
    case InsuranceQuoteType.Home:
      editLink = appendReference(homeContentsQuoteBase, quote.id);
      editLink = appendStep(editLink, editStep);
      break;
  }

  return editLink ? editLink : '';
};

const appendReference = (editLink: string, reference: string) => {
  return `${editLink}?ref=${reference}`;
};

const appendStep = (editLink: string, step: string) => {
  const foundStep = Object.keys(HomeQuoteStepIds)[Object.values(HomeQuoteStepIds).indexOf(step)];
  return `${editLink}&step=${foundStep}`;
};

export const getInsuranceTypeDisplayName = (dictionary: any, quoteType: string) => {
  switch (quoteType.toString()) {
    case QuoteTypes[QuoteTypes.HC]:
      return dictionary.IT_HC;
    case QuoteTypes[QuoteTypes.HH]:
      return dictionary.IT_HH;
    case QuoteTypes[QuoteTypes.HHCOMB]:
      return dictionary.IT_HHCOMB;
    case QuoteTypes[QuoteTypes.MC]:
      return dictionary.IT_MC;
    case QuoteTypes[QuoteTypes.MF]:
      return dictionary.IT_MF;
    case QuoteTypes[QuoteTypes.MT]:
      return dictionary.IT_MT;
    case QuoteTypes[QuoteTypes.PET]:
      return dictionary.IT_PET;
  }

  return '';
};

export const getInsuranceQuoteTypeDisplayName = (dictionary: any, quoteType: InsuranceQuoteType) => {
  switch (quoteType) {
    case InsuranceQuoteType.Contents:
      return dictionary.IT_HC;
    case InsuranceQuoteType.Home:
      return dictionary.IT_HH;
    case InsuranceQuoteType.HomeAndContents:
      return dictionary.IT_HHCOMB;
    case InsuranceQuoteType.MotorComprehensive:
      return dictionary.IT_MC;
    case InsuranceQuoteType.MotorFireTheftAndThirdPartyLiability:
      return dictionary.IT_MF;
    case InsuranceQuoteType.MotorThirdPartyLiability:
      return dictionary.IT_MT;
    case InsuranceQuoteType.PetComp:
      return dictionary.IT_PET;
  }

  return '';
};
const iconMap = {
  homeIcon: require('@/assets/icons/home-icon.svg'),
  carIcon: require('@/assets/icons/car.svg'),
  petIcon: require('@/assets/icons/pet-paw.svg'),
};

export const getInsuranceTypeIcon = (quoteType: string) => {
  switch (quoteType.toString()) {
    case QuoteTypes[QuoteTypes.HC]:
    case QuoteTypes[QuoteTypes.HH]:
    case QuoteTypes[QuoteTypes.HHCOMB]:
      return iconMap.homeIcon;
    case QuoteTypes[QuoteTypes.MC]:
    case QuoteTypes[QuoteTypes.MF]:
    case QuoteTypes[QuoteTypes.MT]:
      return iconMap.carIcon;
    case QuoteTypes[QuoteTypes.PET]:
      return iconMap.petIcon;
    default:
      return iconMap.carIcon;
  }

  return '';
};
