import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]

import { ref, onBeforeMount } from 'vue';
import { useReferenceDataStore } from '@/store';
import { IDropdownItem } from '@/interfaces';
import { FormKit } from '@formkit/vue';
import { cloneDeep } from 'lodash-es';


export default /*@__PURE__*/_defineComponent({
  __name: 'TitleNameInput',
  props: /*@__PURE__*/_mergeModels({
    bindings: {},
    titleRequiredMessage: {},
    firstnameRequiredErrorMessage: {},
    firstnameRegex: {},
    firstnameRegexErrorMessage: {},
    lastnameRequiredErrorMessage: {},
    lastnameRegex: {},
    lastnameRegexErrorMessage: {}
  }, {
    "modelValue": { required: false },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props;

const value = _useModel<{
  title: string;
  firstName: string;
  lastName: string;
}>(__props, "modelValue");
const refDataStore = useReferenceDataStore();
const titles = ref<IDropdownItem[]>([]);
const placeholders = ref<string[]>([]);
const titleRef = ref();
const firstNameRef = ref();
const lastNameRef = ref();
const messagesLoaded = ref(false);

const titleValidationMessage = ref<Record<string, string>>(cloneDeep(props.bindings.validationMessages) || {});
const firstnameValidationMessage = ref<Record<string, string>>(cloneDeep(props.bindings.validationMessages) || {});
const firstnameValidation = ref<any[]>(props.bindings.validation || []);
const lastnameValidationMessage = ref<Record<string, string>>(cloneDeep(props.bindings.validationMessages) || {});
const lastnameValidation = ref<any[]>(props.bindings.validation || []);

onBeforeMount(async () => {
  const res = await refDataStore.getPersonTitles();
  titles.value = res.map((item) => ({
    label: item.name,
    value: item.value,
  }));
  if (props?.bindings?.placeholder) {
    placeholders.value = props.bindings.placeholder.split(',');
  }

  if (props.titleRequiredMessage) {
    titleValidationMessage.value.required = props.titleRequiredMessage;
  }

  if (props.firstnameRegexErrorMessage) {
    firstnameValidationMessage.value.matches = props.firstnameRegexErrorMessage;
  }

  if (props.firstnameRequiredErrorMessage) {
    firstnameValidationMessage.value.required = props.firstnameRequiredErrorMessage;
  }

  if (props.lastnameRegexErrorMessage) {
    lastnameValidationMessage.value.matches = props.lastnameRegexErrorMessage;
  }

  if (props.lastnameRequiredErrorMessage) {
    lastnameValidationMessage.value.required = props.lastnameRequiredErrorMessage;
  }

  if (props.firstnameRegex) {
    firstnameValidation.value = [...firstnameValidation.value, ['matches', new RegExp(props.firstnameRegex)]];
  }

  if (props.lastnameRegex) {
    lastnameValidation.value = [...lastnameValidation.value, ['matches', new RegExp(props.lastnameRegex)]];
  }

  messagesLoaded.value = true;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(FormKit), {
    modelValue: value.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
    type: "group",
    name: _ctx.bindings.name
  }, {
    default: _withCtx(() => [
      (messagesLoaded.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            id: _ctx.bindings.id + '__outer',
            class: _normalizeClass(_ctx.bindings.outerClass)
          }, [
            _createVNode(_unref(FormKit), _mergeProps(_ctx.bindings, {
              ref_key: "titleRef",
              ref: titleRef,
              label: placeholders.value[0] || 'Title',
              validationLabel: placeholders.value[0] || 'Title',
              placeholder: "Please select",
              type: "dropdown",
              options: titles.value,
              name: "title",
              outerClass: "pb-0 input-spacing",
              placeholderClass: "pr-1",
              optionClass: "pr-1",
              innerClass: _ctx.bindings.innerClass || 'input-xs',
              validation: _ctx.bindings.validation,
              validationMessages: titleValidationMessage.value,
              "aria-label": _ctx.bindings.name
            }), null, 16, ["label", "validationLabel", "options", "innerClass", "validation", "validationMessages", "aria-label"]),
            _createVNode(_unref(FormKit), {
              ref_key: "firstNameRef",
              ref: firstNameRef,
              label: placeholders.value[1] || 'First name',
              validationLabel: placeholders.value[1] || 'First name',
              type: "text",
              name: "firstName",
              outerClass: "pb-0 input-spacing",
              innerClass: _ctx.bindings.innerClass || 'input-md',
              validation: firstnameValidation.value,
              validationMessages: firstnameValidationMessage.value,
              "aria-label": _ctx.bindings.name
            }, null, 8, ["label", "validationLabel", "innerClass", "validation", "validationMessages", "aria-label"]),
            _createVNode(_unref(FormKit), {
              ref_key: "lastNameRef",
              ref: lastNameRef,
              label: placeholders.value[2] || 'Last name',
              validationLabel: placeholders.value[2] || 'Last name',
              type: "text",
              name: "lastName",
              outerClass: "pb-0",
              innerClass: _ctx.bindings.innerClass || 'input-md',
              validation: lastnameValidation.value,
              validationMessages: lastnameValidationMessage.value,
              "aria-label": _ctx.bindings.name
            }, null, 8, ["label", "validationLabel", "innerClass", "validation", "validationMessages", "aria-label"])
          ], 10, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "name"]))
}
}

})