import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withKeys as _withKeys, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

import { ref, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';

const escapeKey: string = 'Escape';
const keydown = 'keydown' as const;


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalDialog',
  props: {
    showXCloseButton: { type: Boolean },
    closeOnEsc: { type: Boolean },
    modelValue: { type: Boolean },
    dialogClass: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const emitUpdateModelValue = (emitValue: boolean) => {
  emit('update:modelValue', emitValue);
};

const dialog = ref<HTMLDialogElement | null>(null);

const handleEscapeKey = (event: KeyboardEvent) => {
  if (event.key === escapeKey && !props.closeOnEsc) {
    event.preventDefault();
  }
};

const open = async () => {
  if (dialog.value) {
    scroll_lock();
    dialog.value.showModal();
    if (!props.closeOnEsc) {
      dialog.value.addEventListener(keydown, handleEscapeKey);
    }
  }
};

const close = () => {
  if (dialog.value) {
    scroll_lock(false);
    dialog.value.removeEventListener(keydown, handleEscapeKey);
    dialog.value.close();
    emitUpdateModelValue(false);
  }
};

const handleClose = () => {
  emitUpdateModelValue(false);
};

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      open();
    } else {
      close();
    }
  },
  { immediate: true }
);

onMounted(() => {
  if (props.modelValue) {
    open();
  }
});

onBeforeUnmount(() => {
  if (dialog.value) {
    close(); // call to this so the body top, left, right are back to normal
    dialog.value.removeEventListener(keydown, handleEscapeKey);
  }
});

/**
 * Lock or unlock the body scroll.
 *
 * Source: https://github.com/whatwg/html/issues/7732#issuecomment-2330373381
 * @param {boolean} lock Whether to lock or unlock the body scroll. Default = true.
 */
const scroll_lock = (lock = true) => {
    const { documentElement, body } = document;

  // RTL <body> scrollbar
  const documentLeft = documentElement.getBoundingClientRect().left;
  const scrollbarX = Math.round(documentLeft) + documentElement.scrollLeft;
  const paddingProp = scrollbarX ? 'paddingLeft' : 'paddingRight';

  if (lock) {
    body.style[paddingProp] = `${window.innerWidth - documentElement.clientWidth}px`;
    body.style.top = `-${window.scrollY}px`;
    body.style.left = `-${window.scrollX}px`;
    body.style.right = '0';
    body.style.position = 'fixed';

    body.classList.add('scroll-locked');
  } else {
    if (!body.classList.contains('scroll-locked')) {
      return;
    }

    const currentScrollY = parseInt(body.style.top || '0') * -1;
    const currentScrollX = parseInt(body.style.left || '0') * -1;

    body.style[paddingProp] = '';
    body.style.position = '';
    body.style.top = '';
    body.style.left = '';
    body.style.right = '';

    body.classList.remove('scroll-locked');

    window.scrollTo(currentScrollX, currentScrollY);
  }
};

__expose({ close, open });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("dialog", {
    ref_key: "dialog",
    ref: dialog,
    class: _normalizeClass(
      _ctx.dialogClass ??
      'absolute mt-auto md:mt-[130px] mx-0 md:mx-auto mb-0 md:mb-auto p-10 border-none rounded-t-lg md:rounded-lg w-full max-w-full md:racq-max-w-xl max-h-fit h-fit focus:outline-none drop-shadow-2xl backdrop-opacity-64'
    ),
    onClose: handleClose
  }, [
    (_ctx.showXCloseButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "close-button absolute top-5 right-5 h-6 w-6 focus:outline-dotted focus:outline-offset-2 focus:outline-2 focus:outline-racq-navy focus:rounded-sm focus:border-none",
          "aria-label": "Close",
          onClick: _withModifiers(close, ["stop","prevent"]),
          onKeydown: [
            _withKeys(close, ["enter"]),
            _withKeys(close, ["space"])
          ]
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            width: "24",
            height: "25",
            viewBox: "0 0 24 25",
            fill: "none"
          }, [
            _createElementVNode("rect", {
              width: "24",
              height: "24",
              transform: "translate(0 0.5)",
              fill: "white"
            }),
            _createElementVNode("path", {
              d: "M13.2699 12.5L18.7366 7.0423C19.0878 6.69106 19.0878 6.12367 18.7366 5.77243C18.3853 5.4212 17.8179 5.4212 17.4667 5.77243L12.009 11.2301L6.5423 5.76343C6.19106 5.41219 5.61467 5.41219 5.26343 5.76343C4.91219 6.11467 4.91219 6.69106 5.26343 7.0423L10.7211 12.5L5.26343 17.9577C4.91219 18.3089 4.91219 18.8763 5.26343 19.2276C5.44355 19.4077 5.6687 19.4887 5.90286 19.4887C6.13702 19.4887 6.36217 19.3987 6.5423 19.2276L12 13.7699L17.4577 19.2276C17.6378 19.4077 17.863 19.4887 18.0971 19.4887C18.3313 19.4887 18.5564 19.3987 18.7366 19.2276C19.0878 18.8763 19.0878 18.3089 18.7366 17.9577L13.2789 12.5H13.2699Z",
              fill: "#B1B1B1"
            })
          ], -1)
        ]), 32))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _cache[1] || (_cache[1] = _createTextVNode("[Placeholder slot to fill in the modal dialog contents]"))
    ])
  ], 34))
}
}

})